<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: ['data'],
  data: () => {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title: function (tooltipItems, data) {
              const { index } = tooltipItems[0]
              const { labels } = data

              return ` ${labels[index]}`
            },
            label: function (tooltipItem, data) {
              const { index, datasetIndex } = tooltipItem
              const { datasets } = data
              const value = datasets[datasetIndex].data[index]

              return ` ${value}%`
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value, index, values) => value + '%'
            }
          }],
          xAxes: [{
            ticks: {
              autoSkip: false
            },
            stacked: true,
            barThickness: 45,
            gridLines: { display: false }
          }]
        },
        legend: {
          display: false
        }
      },
      chartData: {
        labels: null,
        datasets: [
          {
            label: 'Audience Representation',
            backgroundColor: '#18d7aa',
            data: null
          }
        ]
      }
    }
  },
  mounted () {
    // Data received has format: data = [{target, percentageFromMean}, {}, ...]

    if (this.$vuetify.breakpoint.xsOnly) {
      this.options.scales.xAxes[0].barThickness = 28
    }

    this.chartData.datasets[0].data = this.data.map(x => x.percentageFromMean)
    this.chartData.labels = this.data.map(x => x.target)
    this.renderChart(this.chartData, this.options)
  }
}

</script>
