<template lang='pug'>
v-expansion-panels(v-if='advertiser && creatives.length' flat :tile='$vuetify.breakpoint.xsOnly')
  v-expansion-panel
    v-expansion-panel-header(class='d-flex')
      h3.text-subtitle-1.d-inline-block
        strong {{ this.totalItems }}
        |  creatives available for {{ this.advertiser.name }}
    v-expansion-panel-content(key='0')
      v-card(flat)
        v-data-table(
          :loading='loading'
          :headers='headers'
          :items='creatives'
          :server-items-length='totalItems'
          :options.sync='pagination'
          :footer-props='{itemsPerPageOptions: rowsPerPageItems}'
          :hide-default-header='$vuetify.breakpoint.xsOnly'
          no-data-text='No creative available'
          must-sort
          item-key='id'
          color='grey'
        )

          //- Mobile
          template(v-slot:item='{ item }' v-if='$vuetify.breakpoint.xsOnly')
            v-card.ma-3.px-3.py-1
              v-layout.align-center
                v-flex.text-left.d-flex(xs8)
                v-flex.text-right(xs4)
                  v-tooltip(top dark color='secondary')
                    template(v-slot:activator='{ on }')
                      v-btn.mr-0(icon text small color='success' v-on='on' @click='addCreative(item)' :disabled='creativeAlreadyAssigned(item.id)')
                        v-icon(color='success') mdi-plus-box
                    span(v-if='creativeAlreadyAssigned(item.id)') Creative already assigned to Line
                    span(v-if='!creativeAlreadyAssigned(item.id)') Assign this creative
              v-layout.align-start
                v-flex(xs4)
                  creativeImage(:creative='item' :parent='parent')
                v-flex.pl-3(xs8)
                  div.text-body-2(style='word-break: break-word;') {{ item.name }}
                  .d-flex
                    v-chip.mr-2.creative-format(small)
                      | {{ item.creativeFormat.minWidth }} x {{ item.creativeFormat.minHeight }}
                    v-chip.creative-duration(small v-if="item.type === 'Video'")
                      v-icon(small)
                        | mdi-clock-outline
                      .text-caption {{ durationDisplay(item) }}

          //- Desktop
          template(v-slot:item.preview='{ item }')
            creativeImage(:creative='item' :parent='parent')
          template(v-slot:item.name='{ item }')
            div.ml-0.mt-1(style='word-break: break-word; max-width:250px; overflow:hidden;') {{ item.name }}
            div.mb-1
              v-layout.text-caption(row wrap)
                v-flex(xs12)
                  .d-flex
                    v-chip.mr-2.creative-language(small )
                      | {{ item.language }}
                    v-chip.mr-2.creative-format(small )
                      | {{ item.creativeFormat.minWidth }} x {{ item.creativeFormat.minHeight }}
                    v-chip.creative-duration(small v-if="item.type === 'Video'")
                      v-icon(small)
                        | mdi-clock-outline
                      .text-caption {{ durationDisplay(item) }}

          template.text-right(v-slot:item.action='{ item }')
            v-tooltip(top dark color='secondary')
              template(v-slot:activator='{ on }')
                v-btn.mr-0(icon text color='success' v-on='on' @click='addCreative(item)' :disabled='creativeAlreadyAssigned(item.id)')
                  v-icon(color='success') mdi-plus-box
              span(v-if='creativeAlreadyAssigned(item.id)') Creative already assigned to Line
              span(v-if='!creativeAlreadyAssigned(item.id)') Assign this creative
</template>

<style scoped>
.v-expansion-panels {
  border: 1px solid rgba(0,0,0,0.12);
}
>>> .v-expansion-panel-content__wrap {
  padding-left: 0px;
  padding-right: 0px;
}
</style>

<script>
import _ from 'lodash'
import creativesApi from '@/services/creatives.api'
import creativeImage from '@/components/creativeImage.vue'

export default {
  created: function () {
    // get data on created, even if panel is not yet expanded, so we can get the "totalItems" number to display in panel's header
    this.getDataFromApi()
  },
  components: {
    creativeImage
  },
  props: ['advertiser', 'exchange', 'parent'],
  data: function () {
    return {
      loading: true,
      search: '',
      lastSearch: '',
      selected: [],
      totalItems: 0,
      rowsPerPageItems: [10, 25, 50],
      pagination: {
        page: 1,
        sortBy: ['uploadDate'],
        sortDesc: [true],
        itemsPerPage: 10
      },
      headers: [
        { text: 'Preview', align: 'left', value: 'preview', width: '130px', sortable: false },
        { text: 'Information', align: 'left', value: 'name' },
        // { text: 'Date', align: 'left', value: 'uploadDate', width: '150px' },
        // { text: 'Language', align: 'left', value: 'language', width: '50px' },
        // { text: 'Type', align: 'left', value: 'type', width: '50px' },
        // { text: 'Size', align: 'left', value: 'creativeFormatKey', width: '130px', sortable: false },
        // { text: 'Duration', align: 'left', value: 'duration', width: '50px' },
        { text: 'Action', align: 'right', value: 'action', width: '30px', sortable: false }

      ],
      creatives: []
    }
  },
  watch: {
    search: _.debounce(function () {
      this.getDataFromApi()
    }, 450),
    pagination: function () {
      this.getDataFromApi()
    },
    advertiser: function (newVal) {
      if (newVal) this.getDataFromApi()
    },
    campaignCreatives: function (newVal) {
      this.totalItems = 0
      this.getDataFromApi()
    }
  },
  computed: {
    campaignCreatives () {
      return this.$store.getters['createCampaign/getCreatives']
    },
    assignedCreativesIds () {
      return this.campaignCreatives.map(c => c.id)
    }
  },
  methods: {
    creativeAlreadyAssigned (creativeId) {
      return this.assignedCreativesIds.includes(creativeId)
    },
    addCreative (creative) {
      this.$emit('addCreative', creative)
    },
    getDataFromApi () {
      if (!this.advertiser) return

      this.loading = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
      // console.log(sortBy, sortDesc, page, itemsPerPage)

      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'
      // console.log('page', page, '[' + skip.toString() + ', ' + take.toString() + ']', sortBy, sort, this.search)

      // Filters
      var filters = [
        { name: 'adQualityStatus', value: 'Rejected', operator: 'ne' },
        { name: 'status', keyName: 'status', value: 'Archived', operator: 'ne' },
        { name: 'processStatus', value: 'Completed' }
      ]

      // Advertiser
      if (this.advertiser.id) {
        filters.push({ name: 'accountId', value: this.advertiser.id })
      }

      // Search
      if (this.search) {
        filters.push({ name: 'search', value: this.search })
      }

      // already assigned creatives
      if (this.assignedCreativesIds.length) {
        filters.push({ name: 'creativeIds', value: this.assignedCreativesIds, operator: 'ne' })
      }

      // get total count only if NEW search
      if (!this.totalItems || this.lastSearch !== this.search) {
        this.lastSearch = this.search

        creativesApi.getCreativesCount(filters)
          .then(count => {
            this.totalItems = count
          })
      }

      creativesApi.getCreatives(skip, take, sortBy[0], sort, filters)
        .then(creatives => {
          this.loading = false
          this.creatives = creatives
        })
    },
    durationDisplay (creative) {
      return creative.type === 'Video' ? Math.round(creative.duration) : 'N/A'
    }
  }
}
</script>
