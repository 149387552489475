<template lang="pug">
  v-simple-table(dense)
    template(v-slot:default='')
      thead
        tr
          th Deal ID
          th Name
          th CPM
      tbody
        tr(v-for='deal in deals' :key='deal.code')
          td {{ deal.code }}
          td
            .text-overline(v-if='deal.deal') {{ deal.deal.source.key }}
            .text-caption.grey--text(v-if='!deal.deal') N/A
            div(style="max-width: 330px;")
              .text-truncate(v-if='deal.deal' :title="deal.deal.name") {{ deal.deal.name }}
          td
            span(v-if='deal.deal') {{ deal.deal.floorCPM | currency }}
            .text-caption.grey--text(v-if='!deal.deal') N/A
          //- td(v-if='!deal.deal' colspan='3')
            .text-caption.grey--text This deal has not been recognized by Broadsign Ads but could still be a valid deal ID.

</template>

<script>
export default {
  props: ['deals']
}
</script>
