<template lang="pug">
div
  creativePage(v-if="$route.params.creativeId !== 'add'")
  addCreativesPage(v-if="$route.params.creativeId === 'add'")
</template>

<script>
import creativePage from '@/views/creative.vue'
import addCreativesPage from '@/views/addCreatives.vue'

export default {
  components: {
    creativePage,
    addCreativesPage
  }
}
</script>
