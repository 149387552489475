<template lang="pug">
div
  v-row.mx-0.mt-4.mb-3(align='center' justify="space-between")
    v-col.py-0(cols=12)
      v-text-field(
        prepend-inner-icon='mdi-magnify'
        hide-details
        single-line
        dense
        rounded
        filled
        clearable
        v-model="tableSearch"
        :label="$vuetify.breakpoint.smAndUp? 'Find venues' : 'Venues'"
        :style='textfieldStyle'
        )
        template(v-slot:prepend='' v-if='showTotalItems')
          .d-flex
            v-icon(color='primary') mdi-map-marker
            span.text-body-1.font-weight-bold.text--secondary {{ totalItems }}
        template.my-0(v-slot:append-outer='')
          .d-flex.align-center
            v-menu(offset-y left)
              template(v-slot:activator='{ on: onMenu }')
                v-tooltip(top dark color='secondary' max-width='300')
                  template(v-slot:activator='{ on: onTooltip }')
                    v-btn.download-inventory(v-on='{ ...onMenu, ...onTooltip }' icon small :disabled='downloadingInventory || !totalItems' :loading='downloadingInventory'
                     @click='sendExportInventoryAnalytics')
                      v-icon mdi-download
                  | Export Inventory in CSV
              v-list
                v-list-item.download-screens(@click="downloadInventory('faces')")
                  v-icon mdi-television
                  div.ml-3.font-weight-medium Export Screens
                v-list-item.download-venues(@click="downloadInventory('venues')")
                  v-icon mdi-map-marker
                  div.ml-3.font-weight-medium Export Venues
            v-btn.mx-2(icon small :disabled='loading || pagination.page < 2' @click="updatePagination('previousPage')")
              v-icon(large) mdi-chevron-left
            v-btn.mx-2(icon small :disabled='loading || !hasMoreItems' @click="updatePagination('nextPage')")
              v-icon(large) mdi-chevron-right

  loadingDialog(ref='loading-dialog' :is-loading='downloadingInventory' label='Limited to the first 3,000 entries')

  v-data-table.mt-2(
    :dense='$vuetify.breakpoint.smAndUp'
    :items='items'
    :loading='loading'
    hide-default-footer
    hide-default-header
    :style="tableStyle"
    :height='tableHeight'
    )
    template.text-left(v-slot:item='{ item }')
      v-card.py-2.inventory-item(tile elevation=0 :style="item.isIncluded || !updateVenueInclusionEnabled ? '' : 'opacity:0.6'")
        .d-flex.ml-4
          venueThumbnail(:venueId="item.id" width="284" :class="{'ml-2': !updateVenueInclusionEnabled}")
          .ml-4.mr-2(style='width:100%;')
            .d-flex.justify-space-between
              div
                v-tooltip(v-if="item.status === 'RecentlyUnavailable'" top color='secondary' max-width='300' style='position:absolute; bottom:0px;')
                  template(v-slot:activator='{ on }')
                    v-chip.currently-unavailable-chip(color='warning'  x-small v-on='on')
                      span.text-uppercase() Currently Unavailable
                  div.text-caption This venue hasn't sent any ad request in the past week, suggesting it's either currently sold out or experiencing technical issues.
                .text-overline.venue-environment {{ getReadableEnv(item) }}
                .text-subtitle-1.mb-1.name(style='line-height:20px;') {{ item.name || 'No name' }}
                .text-caption.grey--text.full-address {{ getCompleteCivicAddress(item) }}

              .d-flex.pr-2.align-top(style='width:44px;' v-if='updateVenueInclusionEnabled')
                v-tooltip(top dark color='secondary' max-width='300')
                  template(v-slot:activator='{on}')
                    v-btn.mr-0(icon v-on='on' @click="updateVenueInclusion(item)")
                      v-icon(:color='item.isIncluded ? "primary" : "grey"')
                        | {{ item.isIncluded ? 'mdi-map-marker' : 'mdi-map-marker-off' }}
                  .text-caption
                    | {{ `This venue is  ${item.isIncluded ? 'included' : 'excluded'}. Click to ${!item.isIncluded ? 'include' : 'exclude'}.` }}
            screensDetails.mt-2(:venue="item")
      v-divider

</template>

<script>
import venueThumbnail from '@/components/venueThumbnail'
import screensDetails from '@/components/screensDetails'
import loadingDialog from '@/components/loadingDialog.vue'

import tracking from '@/services/tracking'
import audienceService from '@/services/audience.service'
import helpers from '@/services/helpers.service'

import _ from 'lodash'

export default {
  components: {
    venueThumbnail,
    screensDetails,
    loadingDialog
  },
  props: [
    'loading',
    'updateVenueInclusionEnabled',
    'items',
    'showTotalItems',
    'totalItems',
    'page',
    'hasMoreItems',
    'tableStyle',
    'textfieldStyle',
    'defaultTableHeight',
    'tab',
    'parent',
    'downloadingInventory'
  ],
  data () {
    return {
      tableSearch: '',
      pagination: {
        sortBy: ['environment'],
        sortDesc: [false],
        page: this.page || 1,
        query: null
      }
    }
  },
  computed: {
    tableHeight () {
      return this.$vuetify.breakpoint.xsOnly
        ? undefined
        : this.defaultTableHeight || undefined
    },
    noDataText () {
      if (!this.items.length && this.tableSearch.length) {
        return 'No venues found'
      } else {
        return ''
      }
    }
  },
  watch: {
    tableSearch: _.debounce(function (q) {
      this.pagination.query = q
      this.pagination.page = 1
      this.$emit('inventoryListChanged', this.pagination)
    }, 450),

    // watch Tab in case it's toggle from outside
    tab (newV) {
      const inventoryTab = this.parent === 'audience' ? 0 : 1
      if (newV !== inventoryTab) this.tableSearch = ''
    },
    // watch Page in case it's reset from outside
    page (newV) {
      this.pagination.page = newV
    }
  },
  methods: {
    updateVenueInclusion (venue) {
      this.$emit('updateVenueInclusion', venue)
      this.$emit('inventoryListChanged', { ...this.pagination, page: 1, skip: 0 })
    },

    updatePagination: _.debounce(function (val) {
      const increments = {
        nextPage: 1,
        previousPage: -1
      }
      const newPagination = Object.assign({}, this.pagination, { page: this.pagination.page += increments[val] })
      this.$emit('inventoryListChanged', newPagination)
    }, 450),

    sendExportInventoryAnalytics () {
      tracking.sendEvent(['ga'], 'exportInventoryCsv')
    },

    downloadInventory (scope) {
      this.$emit('downloadInventory', scope)
      const eventName = `export${helpers.capitalizeString(scope)}Csv`
      tracking.sendEvent(['ga'], eventName)
    },

    getReadableEnv (venue) {
      const val = audienceService.buildTextBreadcrumbs(venue.venueTypeLabels)
      return !val ? venue.environment : val
    },

    getCompleteCivicAddress (venue) {
      return audienceService.buildVenueFullAddress(venue)
    }
  }
}
</script>

<style scoped>
>>> .v-data-table__progress .v-progress-linear {
  position: absolute
}
</style>
