<template lang="pug">
div
  .primary--text(v-if='!lineAssignments.length' @click="$emit('openEditLineDialog')" style="cursor: pointer;") {{ assignedNumberLabel() }}
  .primary--text(
    v-if="$vuetify.breakpoint.xsOnly && lineAssignments.length"
    @click="$emit('openEditLineDialog')"
    style="cursor: pointer;"
    ) {{ assignedNumberLabel() }}

  v-menu(v-if='lineAssignments.length && $vuetify.breakpoint.smAndUp' v-model='menu' :close-on-content-click='false' :close-on-click='false' offset-y top open-on-hover open-delay='500' width=500)
    template(v-slot:activator='{on}')
      span.primary--text(v-on='on' @click="$emit('openEditLineDialog')" style="cursor: pointer;") {{ assignedNumberLabel() }}
    v-card.elevation-24(width=500)
      v-card-text.pt-0.pl-0.pr-0.mb-8(style='height:400px; overflow:scroll;')
        v-layout(justify-space-between column fill-height)
          assignedCreativesTable(
            @removeCreative='removeCreative'
            parent='reportCreativesHover'
            :exchange='exchange'
          )

      v-card-actions(style='background-color:white; position:fixed; bottom:0px; width:100%;')
        v-btn(text color='primary' block @click="$emit('openEditLineDialog')") Add and Manage Creatives
</template>

<script>
import assignedCreativesTable from '@/components/assignedCreativesTable.vue'
import creativeInfos from '@/components/creativeFooterInfos.vue'

import _ from 'lodash'

export default {
  components: {
    assignedCreativesTable,
    creativeInfos
  },
  props: ['account', 'campaignId', 'line', 'lineAssignments'],
  watch: {
    menu: function (newVal, oldVal) {
      if (newVal) {
        const assigned = this.$store.getters['campaignReport/getAssignedCreatives']
        const diff = assigned.length !== this.lineAssignments.length ||
        _.difference(this.lineAssignments.map(x => x.creativeId), assigned.map(x => x.id)).length

        if (!assigned.length || diff) {
          this.$store.dispatch('campaignReport/getAssignedCreatives', this.lineAssignments)
        }
      }
    }
  },
  computed: {
    exchange () {
      const exchanges = this.$store.getters['general/getExchanges']
      return exchanges.find(exchange => exchange.id === this.line.exchangeId)
    },
    accountId () {
      return this.account.id
    }
  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    removeCreative (creative) {
      const options = {
        accountId: this.accountId,
        orderId: parseInt(this.campaignId),
        lineId: parseInt(this.line.id),
        creativeId: creative.id,
        assignmentId: this.lineAssignments.find(a => a.creativeId === creative.id).id
      }

      this.$store.dispatch('campaignReport/unassignCreative', options)
        .then(success => {
          if (success) {
            // After un-assigning, Store will reload Lines, so "effectiveStatus" are refreshed.
            // BUT, since this component is a Dialog, no route change will be triggered when closing it, hence no Table Data refresh.
            // So, we need to reload Table Data here, so new "effectiveStatus" are re-attached to Table Data.
            this.$store.dispatch('campaignReport/getTableData')

            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: 'Creative unassigned'
            })
          }
        })
        .catch(error => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${error}`
          })
        })
    },

    assignedNumberLabel () {
      var assignedNumber = this.lineAssignments.length
      if (assignedNumber) {
        var label = assignedNumber + ' Creative'
        if (assignedNumber > 1) { label += 's' }
        return label
      } else {
        return 'Add Creatives'
      }
    }
  }
}
</script>
