<template lang="pug">
div
  div(v-if='isLoading' style="min-height:270px;")
    v-row.my-0(no-gutters)
      v-col.pt-0(:class="{'px-4': $vuetify.breakpoint.mdAndUp}")
        v-skeleton-loader.mt-2(type='text' width='80%')
    v-row.my-0.pt-0(align='start' :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
      v-col.pt-0.pb-0(cols='12' lg='7')
        v-skeleton-loader.mt-1.mb-3(type='image' height=42 width='80%')
        v-skeleton-loader.mb-1(type='divider' )
        v-skeleton-loader.mb-1.d-flex.mr-2(type='chip@3' )
        v-skeleton-loader.mb-1(type='divider' )
        v-row.mb-0.mt-2()
          v-col(cols='12' md='2')
            v-skeleton-loader(type='image' height=60)
          v-col(cols='12' md='2')
            v-skeleton-loader(type='image' height=60)
          v-col(cols='12' md='5')
            v-skeleton-loader(type='image' height=60)
      v-col.pt-2.pb-0(cols='12' lg='5' )
        v-skeleton-loader(type='image' height=234 style="min-height:234px;")

  div(v-if='!isLoading')
    v-row.my-0(no-gutters)
      v-col.pt-0(style="min-height:36px;" :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
        breadcrumbs(instance-type='screen')

    v-row.my-0.pt-0(align='stretch'  style="min-height:234px;" :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
      v-col.pt-0.pb-0(cols='12' lg='7')
        .text-h4.hidden-sm-and-down(:class="{'': $vuetify.breakpoint.mdAndUp}" style="word-break: break-all;") {{ usableData.name }}
        v-toolbar.my-2.pl-0(flat dense color='transparent' style='border-top: 1px solid rgba(0,0,0,.12) !important; border-bottom: 1px solid rgba(0,0,0,.12) !important;')
          v-switch.xs-small.py-0.my-0.ml-0(:input-value="isInstanceStatusActive" color='primary' hide-details :disabled="isLoading" @change="updateStatus()")
          v-chip.text-uppercase.mr-4(:color='statusColor' small outlined)
            v-icon.mr-1.ml-0(:color='statusColor'  small) mdi-circle
            small.text--primary(style='white-space: nowrap;') {{ statusLabel }}
        v-row.mb-0.mt-2()
            v-col(cols='12' md='2')
              v-card(flat height='100%' style='background-color:transparent;')
                .text-overline Environment
                .text-body-1.font-weight-bold {{ usableData.venueType }}
            v-col(cols='12' md='4')
              v-card.mr-4(flat  height='100%' style='background-color:transparent;')
                .text-overline.mb-1 Venue
                .d-flex.align-start
                  v-icon.text--secondary.mr-1.mt-1(small)  mdi-map-marker
                  .text-body-1 {{ usableData.venueName }}
            v-col(cols='12' md='5')
              v-card(flat  height='100%' style='background-color:transparent;')
                .text-overline Address
                a.info--text.text-caption.d-inline-block(:href="streetViewUrl" target="_blank" style='line-height:20px;')
                  |   {{ assembleAddress }}
                  v-icon.ml-1(small color='info')  mdi-google-maps

      v-col.pt-0.pb-0(cols='12' lg='5' )
        v-card(
        v-if="!isLoading"
        light
        :style="`background-image: url(${venueData.ImageUrl? venueData.ImageUrl: venueData.SupplierImageUrl}); background-repeat: no-repeat; background-size: cover; min-height:200px; height:100%; background-position:center center;`")

</template>

<style scoped>

>>> .v-skeleton-loader__chip {
  margin-right: 4px;
}
>>> .v-toolbar__content {
  padding-left: 0px;
}
>>> .v-skeleton-loader__image {
  min-height: 234px;
}

>>> .v-skeleton-loader .v-skeleton-loader__card-heading {
  background-color: transparent !important;
}
>>> .v-skeleton-loader .v-skeleton-loader__heading{
  margin-left: 0px !important;
}
</style>

<script>
import breadcrumbs from '@/components/reportBreadcrumbs.vue'
import geoService from '@/services/geo.service'
import reportingService from '@/services/reporting.service'

export default {
  components: {
    breadcrumbs
  },
  props: ['screenData', 'venueData', 'lineData', 'isLoading', 'instanceType'],
  created: function () {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.isPanelExpanded = 0
    }
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    isInstanceStatusActive () {
      if (this.lineData && this.screenData) {
        return reportingService.getStatusFromGeography(this.lineData.geography, 'screen', this.screenData.id, this.marketVals.distanceUnit)
      } else {
        return true
      }
    },
    statusColor () {
      return this.isInstanceStatusActive ? 'success' : 'grey'
    },
    statusLabel () {
      return this.isInstanceStatusActive ? 'Active' : 'Inactive'
    },
    usableData () {
      const out = { ...this.screenData }

      out.name = this.screenData.name
      out.venueName = this.venueData.name
      out.venueType = this.venueData.network

      out.city = this.venueData.city
      out.streetNumber = this.venueData.streetNumber
      out.province = this.venueData.province
      out.street = this.venueData.street
      out.latitude = this.venueData.latitude
      out.longitude = this.venueData.longitude
      out.ImageUrl = this.venueData.ImageUrl
      out.SupplierImageUrl = this.venueData.SupplierImageUrl

      return out
    },
    streetViewUrl () {
      if (this.usableData) {
        return 'http://maps.google.com/maps?q=&layer=c&cbll=' + this.usableData.latitude + ',' + this.usableData.longitude
      } else {
        return 'http://maps.google.com'
      }
    },
    assembleAddress () {
      const { streetNumber, street, city, province } = this.usableData
      return `${streetNumber} ${street}, ${city}, ${province}`
    }
  },
  data () {
    return {
      isPanelExpanded: null
    }
  },
  methods: {
    panelExpandCtrl () {
      this.isPanelExpanded === 0 ? this.isPanelExpanded = null : this.isPanelExpanded = 0
    },

    updateStatus () {
      const toStatus = this.isInstanceStatusActive ? 'exclude' : 'include'
      const geo = geoService.reconstructGeography(this.lineData.geography, this.screenData, { toStatus: toStatus, type: this.instanceType }, this.marketVals.distanceUnit)
      this.$emit('screenUpdated', { id: this.lineData.id, geography: geo })
    }
  }
}
</script>
