<template lang="pug">
.home
  vue-headful(:title="componentConfig.branding.title('Campaigns Overview')")
  .header.account-alerts(v-if='gotAlert')
    v-container(fluid grid-list-lg text-left )

      //- Suspended Account
      v-layout.suspended-account-alert(row wrap fill-height v-if='isSuspended')
        v-flex(xs12 sm12 md12)
          v-alert.mb-0(:value='true' type='error' icon='mdi-alert' dense outlined)
            .text-body-2(class='text--primary')
              strong Account Suspended
            .text-body-2(class='text--primary')
              | Your account is temporarily suspended and your ads have stopped running. Please
              router-link.info--text(to='/billing')  pay your account balance
              |  so you may continue using Broadsign Ads.
              a.info--text(href='https://intercom.help/broadsign-ads/en/articles/6037505-why-is-my-account-suspended' target='_blank')
                |  Learn more
              |  or
              a.info--text(href='mailto:info_ads@broadsign.com')
                |  contact us.

      alertDisabledAccount(
        ref='alertDisabledAccountRef'
        :isDisabled='isDisabled'
        :cards='cards'
        :paymentMethods='paymentMethods'
        :organization='organization'
        :permissions="$store.getters['user/permissions']('invoice')"
        @closeAndRefresh='closeAndRefresh'
      )

  v-card.pt-3(color='transparent' flat)
    v-toolbar.elevation-0(color='transparent')
      .text-h5.hidden-sm-and-down Campaigns
      v-text-field(label='Find Campaigns' v-model='search' hide-details prepend-inner-icon='mdi-magnify' solo clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")
      //-v-tooltip(bottom color='secondary')
        template(v-slot:activator='{on}')
          v-btn.mt-0.hidden-sm-and-down(medium fab color='primary' router-link to='/create-campaign' v-on='on')
            v-icon(dark large) mdi-plus
        span.text-caption Create Campaign

      v-btn#create-campaign-btn.mt-0.hidden-sm-and-down.cta1-create-campaign(large rounded color='primary' router-link to='/create-campaign' v-if="can('campaign').create.default || can('proposal').create.default")
        v-icon(left) mdi-plus
        span New {{ can('campaign').create.default ? 'Campaign' : 'Proposal' }}
      template(v-if='tabs.length > 0' v-slot:extension='')
        v-tabs(color='primary' background-color='transparent' slider-color='primary' :value='currentTabIdx')
          v-tab(ripple color='primary lighten-4' v-for='(tab, i) in tabs' :key='i' @change='changeTab(i)' :id='tab')
            | {{ tab }}
    v-divider

    v-scroll-y-reverse-transition
      v-toolbar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selected.length < campaigns.length') {{ selected.length }} campaigns selected
          v-toolbar-title.text-subtitle-1(v-if='selected.length === campaigns.length && campaigns.length < totalItems && !selectAll')
            | The {{ campaigns.length }} campaigns on this page are selected
          v-toolbar-title.text-subtitle-1(v-if='selected.length === totalItems || selectAll') All {{ totalItems }} campaigns are selected

        span.hidden-md-and-up
          v-chip.ma-1(v-if='selected.length < totalItems && !selectAll' dark outlined color='white' small)
            | {{ selected.length }}
          v-chip.ma-1(v-if='selected.length === totalItems || selectAll' dark color='white' class='secondary--text' small)
            | {{ totalItems }}

        v-btn.ml-2(text outlined small dark v-if='selected.length === campaigns.length && campaigns.length < totalItems && !selectAll' @click='selectAll = true') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ totalItems }}&nbsp;
            | campaigns

        v-toolbar-items.ml-3
          v-btn(:icon='$vuetify.breakpoint.smAndDown' :text='$vuetify.breakpoint.mdAndUp' @click='exportCampaignsToCsv()')
            v-icon(:small='$vuetify.breakpoint.mdAndUp') mdi-download
            span.ml-2.hidden-sm-and-down Export CSV
          confirmationDialog(@confirmAction='duplicateCampaigns' type='campaign' action='duplicate' :numberOfEntries='selected.length')
          v-menu(v-if='$vuetify.breakpoint.xsOnly' :close-on-content-click='false' v-model='mobileMenuOpen')
            template(v-slot:activator='{ on }')
              v-btn(icon v-on='on' offset-y)
                v-icon mdi-dots-vertical
            v-list
              v-list-item
                confirmationDialog(
                  @confirmAction='archiveCampaigns'
                  @closed='mobileMenuOpen = false'
                  type="campaign"
                  :action="currentTab === 'archived' ? 'restore' : 'archive'"
                  :numberOfEntries='selected.length'
                  :textButtonOnly='true'
                  )
          confirmationDialog(
            v-if='$vuetify.breakpoint.smAndUp'
            @confirmAction='archiveCampaigns'
            type="campaign"
            :action="currentTab === 'archived' ? 'restore' : 'archive'"
            :numberOfEntries='selected.length'
            )

        v-spacer
        v-btn.ml-3(icon @click='selected = []')
            v-icon mdi-close

    v-data-table.cs-data-table(
      v-if='tabs.length > 0'
      :headers='headers'
      :items='campaigns'
      :loading='isTableLoading'
      :options.sync='pagination'
      :page='pagination.page'
      v-model='selected'
      :footer-props='{itemsPerPageOptions: [50, 100, 250]}'
      show-select
      :dense='$vuetify.breakpoint.xsOnly'
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      :server-items-length='totalItems'
    )
      template(v-slot:no-data='')
        div#campaign-overview-no-data.my-12(v-if='!totalCampaigns && !search')
          .text-h5 Plan your first campaign
          .text-body-2 Start reaching your audience today. Let us guide you through the process.
          v-btn(text color='primary' router-link to='/create-campaign')
            v-icon mdi-plus
            | Campaign
        .my-12(v-if='totalCampaigns || search')
          .text-body-2 {{ noDataText }}

      template.text-left(v-slot:item='{ item, isSelected, select }' v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center
            v-flex.d-flex(:xs3="item.status !== 'Proposal'" :xs2="item.status === 'Proposal'")
              v-checkbox.mt-0.pt-0(@click.stop='isSelected ? select(false) : select(true)' :value='isSelected' hide-details color='primary')
              v-switch.mt-0.pt-0(
                v-if="item.status !== 'Proposal'"
                :disabled="item.status === 'Archived'"
                :input-value="item.status !== 'Paused'"
                hide-details
                @change='pausingFunction(item.id, item.accountId, item.status)'
              )
            v-flex(xs5)
              div.my-2
                div.text-caption(v-if="item.status !== 'Proposal'") {{ terminology[item.timeStatus] }}
                div.text-caption(v-if="item.status === 'Proposal'") Proposed
                div.text-caption(class='grey--text' v-if="item.status === 'Paused'") Paused
                .text-caption.d-block.text-truncate(
                  class='error--text'
                  v-if="item.creativeStatus === 'MissingCreatives' && !['Paused', 'Proposal'].includes(item.status)"
                  title='Missing Creatives'
                ) Missing Creatives
            v-flex.text-right(:xs4="item.status !== 'Proposal'" :xs5="item.status === 'Proposal'")
              div.text-caption.grey--text  {{ item.startDate | date('MMM Do, YYYY') }}
                 div.text-caption.grey--text {{ item.endDate | date('MMM Do, YYYY') }}
          div
            div.mb-1(:title='item.account')
              .text-overline {{ item.account }}
              .text-caption(class='grey--text' v-if="can('campaign').read.orgInfo" :title='organizationLabel(item)') {{ organizationLabel(item) }}
          div
            router-link.info--text(:to="item.status === 'Proposal'? '/proposals/' + item.id : '/campaigns/' + item.id")
              span.text-body-1(style='word-break: break-word;') {{ item.name }}
          spentVsBudgetProgressBar.my-2(
            :item='item'
            :min-width='220'
            warning-msg='At least one Line Order in this Campaign requires your attention'
          )
          v-layout.my-2
            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-account-multiple
              span.text-body-2  {{ item.impressions | numberWithCommas }}

            v-flex(xs6)
              v-icon.text--secondary(medium) mdi-play-circle
              span.text-body-2  {{ item.adsServed | numberWithCommas }}

      template(v-slot:item.statusSwitch='{ item }')
        v-switch.mt-0.pt-0(
          v-if="item.status !== 'Proposal'"
          :disabled="item.status === 'Archived'"
          :input-value="item.status !== 'Paused'"
          hide-details
          @change='pausingFunction(item.id, item.accountId, item.status)'
        )
      template.text-left(v-slot:item.status='{ item }')
        div.campaign-status-data(style='min-width: 80px;')
          .d-block {{ item.status === "Proposal"? "Proposed" : terminology[item.timeStatus] }}
          .text-caption(class='grey--text' v-if="item.status === 'Paused'") Paused
          .text-caption.d-block.text-truncate(
            class='error--text'
            v-if="item.creativeStatus === 'MissingCreatives' && !['Paused', 'Proposal'].includes(item.status)"
            title='Missing Creatives'
          ) Missing Creatives

      template.text-left.widths(v-slot:item.account='{ item }')
        div.campaign-advertiser-data(style='max-width: 200px;')
          .d-block.text-truncate(:title='item.account') {{ item.account }}
          .text-caption.d-block.text-truncate(class='grey--text' v-if="can('campaign').read.orgInfo" :title='organizationLabel(item)') {{ organizationLabel(item) }}

      template.text-left(v-slot:item.name='{ item }')
        div.campaign-name-data(style='min-width:200px; word-break: break-word;')
          router-link.info--text(:to="item.status === 'Proposal'? '/proposals/' + item.id : '/campaigns/' + item.id")
            | {{ item.name }}

      template(v-slot:item.spent='{ item }')
        spentVsBudgetProgressBar(
          :item='item'
          :min-width='200'
          warning-msg='At least one Line Order in this Campaign requires your attention'
        )

      template.text-right(v-slot:item.impressions='{ item }')
        div.campaign-impressions-data(style='min-width:75px;')
          | {{ item.impressions | numberWithCommas }}
      template.text-right(v-slot:item.adsServed='{ item }')
        div.campaign-adsServed-data(style='min-width:80px;')
          | {{ item.adsServed | numberWithCommas }}
      template.text-right(v-slot:item.startDate='{ item }')
        div.campaign-start-date-data(style='min-width:75px;')
          | {{ item.startDate | date('MMM Do, YYYY') }}
      template.text-right(v-slot:item.endDate='{ item }')
        div.campaign-end-date-data(style='min-width:75px;')
          | {{ item.endDate | date('MMM Do, YYYY') }}
    v-divider
  v-btn#create-campaign-mobile-btn.hidden-md-and-up(v-if="can('campaign').create.default || can('proposal').create.default" medium fab color='primary' router-link to='/create-campaign' fixed bottom right v-show='!selected.length')
    v-icon(dark large) mdi-plus
</template>

<script>
import campaignsApi from '@/services/campaigns.api'
import userApi from '@/services/user.api'

import csvService from '@/services/csv.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import componentConfigService from '@/services/componentConfig'

import _ from 'lodash'
import moment from 'moment'

import confirmationDialog from '@/components/actionConfirmationDialog.vue'
import convertToCampaignDialog from '@/components/transferProposalDialog.vue'
import spentVsBudgetProgressBar from '@/components/spentVsBudgetProgressBar.vue'
import alertDisabledAccount from '@/components/alertDisabledAccount.vue'

export default {
  components: {
    confirmationDialog,
    spentVsBudgetProgressBar,
    convertToCampaignDialog,
    alertDisabledAccount
  },
  created: function () {
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()

    this.$store.dispatch('billing/getActivePaymentMethods', this.organizationId)

    // extract only supported values from query string, to avoid breaking pagination
    var { sortBy, sortDesc, search } = this.$route.query
    if (sortBy) { this.pagination.sortBy = [sortBy] }

    if (sortDesc) {
      // convert query string to boolean
      this.pagination.sortDesc = [sortDesc === 'true']
    }

    if (search) {
      this.searchSetFromQueryString = true
      this.search = search
    }

    this.initializeTabs()
    this.setCurrentTabFromUrl()

    // empty states
    campaignsApi.getTotalCampaignsCount().then(res => {
      this.totalCampaigns = res
    })
  },
  watch: {
    search: _.debounce(function (newVal, oldVal) {
      // bypass initial set from URL
      if (!this.searchSetFromQueryString) {
        // keep "sort"
        var { sortBy, sortDesc } = this.$route.query
        const newQuery = { sortBy, sortDesc }

        // set "search", if any
        if (newVal) { newQuery.search = newVal }

        // update query string
        this.$router.push({ query: newQuery })
        this.getCampaigns()
      } else {
        this.searchSetFromQueryString = false
      }
    }, 450),

    currentTab: function (newVal, oldVal) {
      this.changeTabURL(newVal, oldVal)

      // bypass initial set from URL
      if (oldVal !== '') {
        // reset Paging
        this.$set(this.pagination, 'page', 1)

        this.getCampaigns()
      }
    },

    pagination: function (newVal, oldVal) {
      // set "sort"
      var { sortBy, sortDesc } = newVal

      const newQuery = { sortBy, sortDesc }

      // keep any query params other than "sort" (ex: tracking vars "utm_")
      const queryParams = Object.keys(this.$route.query)

      const sortParams = ['sortBy', 'sortDesc']
      queryParams
        .filter(param => !sortParams.includes(param))
        .map(param => {
          newQuery[param] = this.$route.query[param]
        })

      if (this.$route.query.sortBy && this.$route.query.sortDesc) {
        // update route params only if they changed
        if (oldVal.sortBy !== sortBy || oldVal.sortDesc !== sortDesc) {
          this.$router.push({ query: newQuery })
        }
      } else {
        // add default params in route
        this.$router.replace({ query: newQuery })
      }

      this.getCampaigns()
    },

    selected: function () {
      if (this.selected < this.campaigns) {
        this.selectAll = false
      }
    }
  },
  data () {
    return {
      totalCampaigns: 1,
      totalItems: 0,
      selectAll: false,
      mobileMenuOpen: false,
      terminology: {
        Proposal: 'Proposed',
        Upcoming: 'Scheduled',
        Ongoing: 'Delivering',
        Over: 'Completed'
      },
      currentTab: '',
      currentTabIdx: -1,
      tabs: [],
      selected: [],
      search: '',
      searchSetFromQueryString: false,
      campaigns: [],
      isTableLoading: true,

      supportDefinitions: null,
      colsWithDefinitions: {
        Campaigns: 'Campaign',
        Spent: 'Amount Spent',
        Impressions: 'Impression',
        eCPM: 'eCPM'
        // AdsServed: 'Ad Served'
      },
      pagination: {
        sortBy: ['startDate'],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
        totalItems: 0
      },
      headers: [
        { text: '', align: 'left', value: 'statusSwitch', sortable: false, width: '66px' },
        { text: 'Status', align: 'left', value: 'status', width: '110px', class: 'campaign-status' },
        { text: 'Advertiser', align: 'left', value: 'account', width: '200px', class: 'campaign-advertiser' },
        { text: 'Campaigns', align: 'left', value: 'name', class: 'campaign-name' },
        { text: 'Spent', align: 'left', value: 'spent', class: 'campaign-budget-spent' },
        { text: 'Impressions', align: 'right', value: 'impressions', width: '117px', class: 'campaign-impressions-delivered' },
        // { text: 'eCPM', align: 'right', value: 'averageCpm' },
        // { text: 'Ads Served', align: 'right', value: 'adsServed' },
        { text: 'Start Date', align: 'right', value: 'startDate', width: '110px', class: 'campaign-start-date' },
        { text: 'End Date', align: 'right', value: 'endDate', width: '110px', class: 'campaign-end-date' }
      ],
      canSeeNewUi: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters['user/getOrganization']
    },
    organizationId () {
      return this.organization.id
    },
    paymentStatus () {
      return this.$store.getters['user/getPaymentStatus']
    },
    paymentMethods () {
      return this.$store.getters['billing/paymentMethods']
    },
    cards () {
      return this.$store.getters['billing/cards']
    },
    hasPaymentMethods () {
      return this.paymentMethods ? this.paymentMethods.length : true
    },
    noDataText () {
      let adjs = ['', 'proposed ', 'scheduled ', 'delivering ', 'completed ', 'archived ']
      if (this.canSeeNewUi) {
        adjs = adjs.filter(adj => adj !== 'proposed')
      }
      if (!this.campaigns.length && this.search && this.search.length) {
        return 'No campaign matches your search'
      } else {
        return 'No ' + adjs[this.currentTabIdx] + 'campaign found'
      }
    },
    isDisabled () {
      return this.paymentStatus === 'Unspecified' && !this.hasPaymentMethods
    },
    isSuspended () {
      // MAX: paymentStatus === "suspended" can happen in two cases:
      //   CASE #1 : I deleted my last ccard
      //   CASE #2 : attempt to bill existing ccard failed
      //     the message will still say "Please pay your account balance so you may continue using Broadsign Ads."
      //     which is NOT TRUE in case #1
      return this.paymentStatus === 'Suspended' && this.organization.paymentType === 'Automatic'
    },
    gotAlert () {
      return this.isSuspended || this.isDisabled
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    can (entity) {
      return this.$store.getters['user/permissions'](entity)
    },

    initializeTabs () {
      const tabs = []

      if (this.can('proposal').read.default && !this.canSeeNewUi) {
        tabs.push('proposed')
      }

      if (this.can('campaign').read.default) {
        tabs.unshift('all')
        tabs.push('scheduled', 'delivering', 'completed', 'archived')
      }

      this.tabs = tabs
    },

    setCurrentTabFromUrl () {
      if (this.tabs.length > 0) {
        const urlSegments = this.$route.path.split('/')
        const tabFromUrl = urlSegments[urlSegments.length - 1]
        const tabIndex = this.tabs.findIndex(tab => tab === tabFromUrl)

        this.changeTab(tabIndex === -1 ? 0 : tabIndex)
      }
    },

    getCampaigns () {
      this.isTableLoading = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'

      var filters = this.buildFiltersList()

      campaignsApi.getCampaignsCount(filters).then(count => {
        this.totalItems = count
      })

      campaignsApi.getCampaigns(skip, take, sortBy[0], sort, filters)
        .then(campaigns => {
          this.campaigns = campaigns
          this.isTableLoading = false
          if (this.selectAll) {
            this.selected = campaigns
          }
        })
        .catch(error => {
          let msg = error.data.errors[0].errorCode
          if (msg === 'unexpected') {
            msg = 'Unexpected: ' + error.data.errors[0].id
          }

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    archiveCampaigns () {
      // Used to archive and unarchive a campaign
      if (this.selected.length === this.campaigns.length || this.selectAll) {
        this.bulkPatchActions(this.currentTab !== 'archived' ? 'archive' : 'restore')
      } else {
        const isPlural = this.selected.length > 1

        this.selected.forEach(campaign => {
          const action = this.currentTab !== 'archived' ? 'archived' : 'restored'
          const modifiedFields = {
            id: campaign.id,
            status: this.currentTab !== 'archived' ? 'Archived' : 'Active'
          }
          campaignsApi.editCampaign(campaign.accountId, campaign.id, modifiedFields).then(updatedCampaign => {
            const index = this.campaigns.findIndex(x => x.id === updatedCampaign.id)
            this.campaigns.splice(index, 1)
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: isPlural ? 'Campaigns successfully ' + action : updatedCampaign.name + ' successfully ' + action
            })
          })
            .catch(error => {
              let msg = error.data.errors[0].errorCode
              if (msg === 'unexpected') {
                msg = 'Unexpected: ' + error.data.errors[0].id
              }

              this.$store.commit('snackbar/setSnackbar', {
                type: 'error',
                msg: `${msg}`
              })
            })
        })
        this.selected = []
      }
    },

    duplicateCampaigns ({ keepCreatives }) {
      const threshold = 10
      const picked = this.selected.length > threshold ? this.selected.slice(0, threshold) : this.selected

      const isPlural = this.selected.length > 1

      picked.forEach(campaign => {
        campaignsApi.duplicateCampaign(campaign.accountId, campaign.id, keepCreatives).then(updatedCampaign => {
          const index = this.campaigns.findIndex(x => x.id === campaign.id)
          this.campaigns.splice(index, 0, updatedCampaign)
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: isPlural ? 'Campaigns successfully duplicated' : updatedCampaign.name + ' successfully duplicated'
          })
        })
          .catch(error => {
            let msg = error.data.errors[0].errorCode
            if (msg === 'unexpected') {
              msg = 'Unexpected: ' + error.data.errors[0].id
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
      })
      this.selected = []
    },

    exportCampaignsToCsv () {
      function formatter (x) {
        return {
          Campaign: x.name,
          Advertiser: x.account,
          'Amount Spent': x.spent,
          'Amount Budgeted': x.budget,
          Impressions: x.impressions,
          eCPM: x.averageCpm,
          'Ads Served': x.adsServed,
          'Satrt Date': moment(x.startDate).format('MMMM Do, YYYY'),
          'End Date': moment(x.endDate).format('MMMM Do, YYYY')
        }
      }

      if (this.selectAll) {
        campaignsApi.getCampaignsListForCSV(this.buildFiltersList()).then(res => {
          csvService.csvExport(res.map(x => formatter(x)), 'campaigns')
        })
      } else {
        csvService.csvExport(this.selected.map(x => formatter(x)), 'campaigns')
      }
      this.selected = []
    },

    pausingFunction (campaignId, accountId, status) {
      // Used to pause or resume a campaign
      const modifiedFields = {
        id: campaignId,
        status: status !== 'Paused' ? 'Paused' : 'Active'
      }
      campaignsApi.editCampaign(accountId, campaignId, modifiedFields).then(updatedCampaign => {
        const index = this.campaigns.findIndex(x => x.id === updatedCampaign.id)
        this.campaigns.splice(index, 1, updatedCampaign)
        this.$store.commit('snackbar/setSnackbar', {
          type: 'success',
          msg: `${updatedCampaign.name} has been ${updatedCampaign.status === 'Paused' ? 'paused' : 'unpaused'}`
        })
      })
        .catch(error => {
          let msg = error.data.errors[0].errorCode
          if (msg === 'unexpected') {
            msg = 'Unexpected: ' + error.data.errors[0].id
          }

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    bulkPatchActions (action) {
      const configs = {
        archive: { status: 'Archived' },
        restore: { status: 'Active' },
        convert: { status: 'Active' }
      }
      const verbs = {
        archive: 'archived',
        restore: 'restored',
        convert: 'converted'
      }

      const { page, itemsPerPage } = this.pagination
      const params = {
        filters: this.buildFiltersList()
      }
      if (!this.selectAll) {
        params.skip = (page - 1) * itemsPerPage
        params.take = itemsPerPage
      }

      campaignsApi.bulkAction(params, configs[action]).then(res => {
        this.$store.commit('snackbar/setSnackbar', {
          type: 'success',
          msg: 'The selected campaigns have been successfully ' + verbs[action]
        })
        this.getCampaigns()
      })
        .catch(error => {
          let msg = error.data.errors[0].errorCode
          if (msg === 'unexpected') {
            msg = 'Unexpected: ' + error.data.errors[0].id
          }

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
      this.selected = []
    },

    // ================
    // HELPER FUNCTIONS
    // ================

    buildFiltersList () {
      var filters = []

      filters.push({ name: 'isForAdServer', value: this.$store.getters['user/isForAdServer'] })

      if (this.search) {
        filters.push({ name: 'search', value: this.search })
      }
      if (!['proposed', 'archived'].includes(this.currentTab)) {
        switch (this.currentTab) {
          case 'all':
            filters.push({ name: 'mainStatus', keyName: 'status', value: this.canSeeNewUi ? 'Active' : 'All' })
            if (this.canSeeNewUi) {
              filters.push({ name: 'secondaryStatus', keyName: 'timeStatus', value: 'All' })
            }
            break
          case 'scheduled':
            filters.push({ name: 'mainStatus', keyName: 'status', value: 'Active' })
            filters.push({ name: 'secondaryStatus', keyName: 'timeStatus', value: 'Upcoming' })
            break
          case 'delivering':
            filters.push({ name: 'mainStatus', keyName: 'status', value: 'Active' })
            filters.push({ name: 'secondaryStatus', keyName: 'timeStatus', value: 'Ongoing' })
            break
          case 'completed':
            filters.push({ name: 'mainStatus', keyName: 'status', value: 'Active' })
            filters.push({ name: 'secondaryStatus', keyName: 'timeStatus', value: 'Over' })
        }
      } else {
        const val = this.currentTab === 'proposed' ? 'Proposal' : 'Archived'
        filters.push({ name: 'mainStatus', keyName: 'status', value: val })
      }
      return filters
    },

    organizationLabel (item) {
      const country = defaultExchangeValues.getDefaultValuesByCurrency(item.currency).countryCode
      return `${item.owner.firstName} ${item.owner.lastName} from ${country} - ${item.owner.organizationName}`
    },

    changeTab (newVal) {
      this.currentTab = this.tabs[newVal]
      this.currentTabIdx = newVal
    },

    changeTabURL (newVal, oldVal) {
      const oldTab = this.tabs.find(tab => tab === oldVal)
      const newTab = this.tabs.find(tab => tab === newVal)

      var path = this.$route.path
      var pathPieces = path.split('/')
      var urlTab = pathPieces[pathPieces.length - 1]
      if (urlTab !== newTab) {
        var newLocation
        var index = path.lastIndexOf(oldTab)
        var locationPieces = path.split('')

        // route's beforeEnter() has not finished executing and User clicks on a tab
        if (index === -1) {
          newLocation = locationPieces.join('') + '/' + newTab
        } else {
          locationPieces.splice(index, index + oldTab.length, newTab)
          newLocation = locationPieces.join('')
        }

        this.$router.push({ path: newLocation, query: this.$route.query })
      }
    },

    closeAndRefresh () {
      this.reloadOrganization()
      this.$store.dispatch('billing/getActivePaymentMethods', this.organizationId)
    },
    reloadOrganization () {
      return userApi.getOrganization(this.organizationId)
        .then(organization => {
          this.$store.commit('user/setOrganization', organization)
        })
    }
  }
}
</script>
