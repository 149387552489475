<template lang="pug">
  v-navigation-drawer(v-model='localDrawer' temporary app right)
    v-list
      v-list-item.user-profile-btn(
        router-link :to='componentConfig.userSettings.isUserProfileShown ? userProfileLink : ""'
        v-on='componentConfig.userSettings.isUserProfileShown ? { click: closeDrawer } : {}')
        v-list-item-icon.my-3.mr-3
          v-icon()
            | mdi-account-circle
        v-list-item-content
          v-list-item-title.user-display-name
            | {{ userDisplayName }}
          v-list-item-subtitle.user-org
            | {{ userOrganizationName }}

      switchOrganization(v-if='componentConfig.userSettings.isSwitchOrganizationShown')
        template(v-slot:customActivator='')
          v-list-item.switch-org-btn
            v-list-item-icon.my-3.mr-3
              v-icon mdi-account-switch
            v-list-item-title Switch Organization

    v-divider
    v-list
      v-list-item.dashboard(v-if='componentConfig.isReportsShown' router-link to='/dashboard' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-view-dashboard
        v-list-item-content
          v-list-item-title Dashboard

      v-list-item.campaigns(router-link to='/campaigns' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-book-open
        v-list-item-content
          v-list-item-title Campaigns

      v-list-item.reports(v-if='componentConfig.isReportsShown' router-link to='/reports' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-finance
        v-list-item-content
          v-list-item-title Reports

      v-list-item.marketplace(router-link to='/marketplace' @click='closeDrawer' v-if="canSeeMarketplace")
        v-list-item-icon.my-3.mr-3
          v-icon mdi-shopping
        v-list-item-content
          v-list-item-title Marketplace

      v-list-item.audiences(v-if='componentConfig.audiences.canSaveLoadAudience' router-link to='/audiences' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-account-multiple
        v-list-item-content
          v-list-item-title Audiences

      v-list-item.creatives(v-if="can('creative').read.default" router-link to='/creatives' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-image-size-select-actual
        v-list-item-content
          v-list-item-title Creatives

      v-list-item.billing(v-if='componentConfig.isBillingShown' router-link to='/billing' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-credit-card
        v-list-item-content
          v-list-item-title Billing
      v-list-item.auction-packages(v-if='canSeeAuctionPackage' router-link to='/auction-packages' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-monitor
        v-list-item-content
          v-list-item-title Auction Packages

    v-divider(v-if="componentConfig.userSettings.isSettingsShown || can('user').read.allUsers || can('organization').read.allOrgs")
    v-list
      v-list-item.settings-btn(v-if='componentConfig.userSettings.isSettingsShown' router-link :to='organizationSettingsLink' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-cog
        v-list-item-title Settings

      v-list-item.manage-users(v-if="can('user').read.allUsers" router-link to='/users' exact @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-account-group
        v-list-item-title Manage Users
      v-list-item.manage-orgs(v-if="can('organization').read.allOrgs" router-link to='/organizations' exact @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-account-box-multiple
        v-list-item-title Manage Organizations

    v-divider
    v-list
      v-list-item(router-link to='/logout' @click='closeDrawer')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-logout-variant
        v-list-item-content
          v-list-item-title Log Out

    v-divider
    div.py-4.px-2
      a.info--text.mx-1.text-caption(href='https://broadsign.com/programmatic-privacy-policy/' target="_blank")
        | Platform Policy
      a.info--text.mx-1.text-caption(href='https://broadsign.com/privacy-policy/' target="_blank")
        | Privacy Policy
      a.info--text.mx-1.text-caption(href='/terms-conditions' target="_blank")
        | Terms
      div.copyright.mx-1.text-caption(v-if='componentConfig.branding.isCopyrightShown')
        | {{ "© Campsite Project Inc. " + year }}
</template>

<script>
import switchOrganization from '@/components/switchOrganization.vue'
import componentConfigService from '@/services/componentConfig'
import { OrganizationTypeEnum } from '@/enums'

export default {
  components: {
    switchOrganization
  },
  props: ['drawer', 'userDisplayName', 'userProfileLink', 'userOrganization'],
  data () {
    return {
      // we receive the Drawer "model" (bool open/close) as props, since controlled by Parent component
      // so, make a local copy to avoid mutating props
      localDrawer: this.drawer || false
    }
  },
  watch: {
    drawer: function (newVal, oldVal) {
      // when Drawer is toggled by Parent component, update local copy
      this.localDrawer = this.drawer
    },
    localDrawer: function (newVal, oldVal) {
      // when Drawer is closed by outside click, emit event to Parent,
      // so it can update the Drawer "model"
      if (!newVal) {
        this.$emit('drawerClosed')
      }
    }
  },
  computed: {
    userOrganizationName () {
      return this.userOrganization.name || ''
    },
    canSeeAuctionPackage () {
      return this.$store.getters['general/isAuctionPackageVisible'] && this.$store.getters['general/isAuctionPackageMenuVisible']
    },
    organizationSettingsLink () {
      return '/organizationsSettings/' + this.userOrganization.id
    },
    canSeeMarketplace () {
      return Boolean(this.userOrganization.seatName || this.userOrganization.type === OrganizationTypeEnum.PARTNER_DSP)
    },
    year () {
      const date = new Date()
      return date.getFullYear()
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    can (entity) {
      return this.$store.getters['user/permissions'](entity)
    },
    // called when clicking on links inside Drawer
    closeDrawer () {
      this.localDrawer = false
    }
  }
}
</script>
