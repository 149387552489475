<template lang="pug">
div.d-flex.align-center(:class="cntr")
  forecast-impressions#forecast-impressions-summary(
    v-if='showImpressions'
    elevated
    compact
    :loading="isForecastLoading"
    :total-impressions="totalImpressions"
    :targeted-impressions="targetedImpressions"
    :primary-alignment="$vuetify.breakpoint.xsOnly ? 'left' : 'right'"
    :style="`width: 100%`"
    :class='{ "mt-3 mr-3" : !$vuetify.breakpoint.xsOnly }'
  )
  forecast-inventory-summary.ml-3.mr-5.mt-3#forecast-inventory-summary(
    v-if="$vuetify.breakpoint.smAndUp"
    :loading='loading'
    :numberOfVenues='numberOfVenues'
    :numberOfScreens='numberOfScreens'
    :buttonLabel="inventoryToggleBtnLabel"
    @clickButton="$emit('seeDetails')"
    show-button
    )
</template>

<script>
import { ForecastImpressions, ForecastInventorySummary } from '@ayudasystems/campaign-targeting'

export default {
  props: ['parent', 'loading', 'numberOfVenues', 'numberOfScreens', 'showImpressions', 'isFullscreen', 'showFullscreenMapDrawer'],
  components: {
    ForecastImpressions,
    ForecastInventorySummary
  },
  computed: {
    cntr () {
      return (this.parent === 'proposal' || this.isFullscreen) && this.$vuetify.breakpoint.smAndUp
        ? 'floating-right-proposal'
        : this.$vuetify.breakpoint.xsOnly ? 'sticky-top' : 'floating-right'
    },
    inventoryToggleBtnLabel () {
      return this.isFullscreen && this.showFullscreenMapDrawer ? 'Hide Details' : 'See Details'
    },
    isForecastLoading () {
      return this.$store.getters['audience/forecastLoading']
    },
    isForecastImpressionsAvailable () {
      if (this.$store.getters['createCampaign/hasMoment'] ||
      (this.$store.getters['createCampaign/deals'].length > 0 && !this.$flags.canSeeAuctionPackagePrivateDealsTargetedImpressions.isEnabled())) return false

      const forecast = this.$store.getters['audience/forecast']
      return forecast && forecast.impressions && forecast.impressions.total !== 0
    },
    targetedImpressions () {
      if (!this.$store.getters['createCampaign/getMaxCpm']) return null

      const forecast = this.$store.getters['audience/forecast']
      return this.$store.getters['createCampaign/getBudget'] > 0 && !!forecast.budget ? forecast.budget.impressions.value : null
    },
    totalImpressions () {
      if (!this.isForecastImpressionsAvailable) return null

      const forecast = this.$store.getters['audience/forecast']
      return forecast.impressions.value
    }
  }
}
</script>

<style scoped>
.floating-right {
  position: fixed;
  z-index: 10;
  top: 48px;
  right: 0px;
}

.floating-right-proposal {
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0px;
}

.sticky-top {
  width: 100vw;
  position: fixed;
  top: 48px;
  z-index: 4;
}
</style>
