<template lang="pug">
  v-menu(v-if='menuItems.length > 0' offset-y :attach="$vuetify.breakpoint.mdAndUp" nudge-bottom='6')
    template(v-slot:activator='{ on }')
      v-btn.add-menu-opener(text icon medium v-on='on')
        v-icon(medium) mdi-plus-circle
    v-list
      v-list-item(v-for='menuItem in menuItems' :key='menuItem.buttonId' :class='menuItem.buttonId' router-link :to='menuItem.path')
        v-list-item-title
          v-icon mdi-plus
          |  {{ menuItem.label }}
</template>

<script>
import componentConfigService from '@/services/componentConfig'

export default {
  computed: {
    canSeeNewUi () {
      return this.$flags.canSeeUiRebrand.isEnabled()
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    menuItems () {
      const items = [
        {
          label: 'Plan',
          buttonId: 'create-plan-btn',
          path: '/plans/create',
          isShown: this.can('proposal') && this.canSeeNewUi
        },
        {
          label: 'Proposal',
          buttonId: 'create-proposal-btn',
          path: '/create-campaign',
          isShown: this.can('proposal') && !this.can('campaign') && !this.canSeeNewUi
        },
        {
          label: 'Campaign',
          buttonId: 'create-campaign-btn',
          path: '/create-campaign',
          isShown: this.can('campaign')
        },
        {
          label: 'Report',
          buttonId: 'create-report-btn',
          path: '/reports/create',
          isShown: this.componentConfig.isReportsShown
        },
        {
          label: 'Audience',
          buttonId: 'create-audience-btn',
          path: '/audiences/add',
          isShown: this.can('audience') && this.componentConfig.audiences.canSaveLoadAudience
        },
        {
          label: 'Creative',
          buttonId: 'create-creative-btn',
          path: '/creatives/add',
          isShown: this.can('creative')
        }
      ]

      return items.filter(item => item.isShown)
    }
  },
  methods: {
    can (entity) {
      const perms = this.$store.getters['user/permissions'](entity)
      return perms ? perms.create.default : false
    }
  }
}
</script>
