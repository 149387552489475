<template lang="pug">
div
  vue-headful(:title="componentConfig.branding.title(pageTitle)")
  v-container
    v-row.my-0
      v-col(md='8')
        v-card.mb-4
          div.pa-4.pb-0
            .headline.mb-2 General Settings
            v-text-field#report-name(label='Report Name' :value='reportName' @input='nameUpdated' clearable :rules='[rules.required]')
            div.pb-4
              .body-1.font-weight-bold Date range
              .caption.grey--text.text--darken-2 Select "Dynamic" to export a date range relative to the report generation date or "Fixed" to export a specific period in time.

              v-row.my-0(no-gutters :class="{ 'justify-center': $vuetify.breakpoint.xsOnly }")
                v-chip-group(v-model='range' active-class='primary' column mandatory)
                  v-chip.range-options(small value='dynamic') Dynamic
                  v-chip.range-options(small value='fixed') Fixed

                .range-option-dynamic(v-show='range === "dynamic"')
                  v-menu(offset-y)
                    template(v-slot:activator="{on}")
                      v-btn.dynamic-picker-value.mt-1.mb-2(v-on="on" :class="{ 'mx-4': $vuetify.breakpoint.xsOnly }" text color='primary')
                        v-icon.mr-1(left) mdi-calendar-range
                        | {{ dynamicRange }}
                        v-icon.mr-0() mdi-menu-down
                    v-list(dense)
                      v-list-item-group(v-model='dynamicRange' mandatory)
                        v-list-item.dynamic-picker-option(v-for='(option, index) in dynamicRangeOptions' :key='option.index' :value='option' @click='')
                          v-list-item-title {{ option }}

                .range-option-fixed(v-show='range === "fixed"')
                  date-range(:full-dates='dates' @updated='dateRangeUpdated')
                    template(v-slot:customActivator='{}')
                      v-btn.mt-1.mb-2(text color='primary')
                        v-icon.mr-1(left) mdi-calendar-range
                        | {{ dateRangeText }}

            div.pb-4
              .body-1.font-weight-bold Filter
              .caption.grey--text.text--darken-2 Scope the report to specific criteria.

              .mb-2.mt-1
                filtersView.mb-2.filters-view(
                  :addedFilters='addedFilters'
                  @updatedFilter='updateFilter'
                  @deletedFilter='deleteFilter'
                  :toLabelMethod='filterToLabel'
                  :searchMethod='filterSearch'
                  )
                filterSet.d-block.filter-set(
                  :filterOptions='filtersAvailable'
                  @addedFilter='addFilter'
                  :searchMethod='filterSearch'
                  )
                  template(v-slot:opener="{}")
                    v-btn.mb-2.ml-n2(color='primary' text)
                      v-icon.mr-1(left) mdi-filter
                      | Add Filter

            div.pb-4
              .body-1.font-weight-bold Time Breakdown
              .caption.grey--text.text--darken-2 Generate one row for every selected period
              v-chip-group(v-model='timeBreakdown' active-class='primary' mandatory)
                v-chip(v-for='breakdown in timeBreakdownOptions' :key='breakdown.key' small) {{ breakdown.label }}

        v-card.mb-4
          .pa-4
            .headline.mb-2 Dimensions
            .caption.grey--text.text--darken-2 Select at least one or multiple dimensions to report on
          v-divider
          treeMultipleSelection(
            v-if='dimensionsAvailable.length'
            :items='dimensionsAvailable'
            :selected='dimensions'
            label='dimension'
            @selectionUpdated='dimensionsUpdated'
          )

        v-card
          .pa-4
            .headline.mb-2 Metrics
            .caption.grey--text.text--darken-2 Select at least one or multiple metrics to report on
          v-divider
          treeMultipleSelection(
            v-if='metricsAvailable.length'
            :items='metricsAvailable'
            :selected='metrics'
            label='metric'
            @selectionUpdated='metricsUpdated'
          )

        .text-right(style='width: 100%;' v-if='isCreateInstance')
          v-spacer
            div.my-4.mx-3.mx-md-0
              v-btn.mr-0.create-report(
                large rounded color='primary'
                :disabled='!canSaveReport'
                :loading='isSaving'
                @click='saveReport'
                ) Create Report

      v-col(md='4').pt-0.mt-n3
        .sticky-column.pt-6
          schedule.mb-4(
            v-if='showSchedule'
            :schedule='schedule'
            @scheduleChanged='scheduleUpdated'
            )
          v-card.email-recipients(v-if='showEmails')
            .pa-4
              span.body-1.font-weight-bold
                | Delivery
                span.caption.grey--text.text--darken-2 (optional)
              .caption.grey--text.text--darken-2 Add people to receive this scheduled report
              v-combobox.mb-2.email-combobox(
                  multiple
                  small-chips
                  clearable
                  deletable-chips
                  hide-details
                  label="Enter recipients emails"
                  :value="emailRecipients"
                  @change="emailRecipientsUpdated"
                  ref='emailRecipientsInput'
                  )
              .caption.grey--text.text--darken-2 Once generated, the report will be sent by email to the recipients above.
              .caption.grey--text.text--darken-2.max-file-size-info The max file size for the attachment is 10MB.
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { mapState } from 'vuex'

import reportsApi from '@/services/reports.api'
import reportsService from '@/services/reports'
import tracking from '@/services/tracking'
import componentConfigService from '@/services/componentConfig'

import dateRange from '@/components/customReport.dateRange.vue'
import schedule from '@/components/customReport.schedule.vue'
import filterSet from '@/components/filterSet.vue'
import filtersView from '@/components/filtersView.vue'
import treeMultipleSelection from '@/components/treeMultipleSelection.vue'

export default {
  components: {
    dateRange,
    schedule,
    filterSet,
    filtersView,
    treeMultipleSelection
  },
  props: ['report', 'template'],
  async created () {
    await this.$store.dispatch('reports/getMetricsAndDimensions')

    if (this.isCreateInstance) {
      if (this.templateToUse) {
        const template = reportsService.getTemplate(this.templateToUse)
        await this.prefillTemplateReport(template)
      } else {
        this.prefillNewReport()
      }
    }

    if (!this.isCreateInstance && this.report) {
      this.prefillEditReport()
    }

    if (!this.isCreateInstance && !this.report && this.template) {
      const template = reportsService.decodeTemplate(this.template)
      await this.prefillTemplateReport(template)
    }
  },
  data () {
    return {
      rules: {
        required: value => !!value || 'Required.'
      },
      isDataLoaded: false,
      countriesAndStates: [],

      savingReport: false,
      reportName: null,

      timeBreakdownOptions: reportsService.getItems('timeBreakdown'),
      timeBreakdown: 0,

      schedule: null,
      emailRecipients: [],

      dimensions: [],
      metrics: [],
      addedFilters: [],

      range: 'dynamic',
      dynamicRangeOptions: reportsService.getItems('dynamicRangeOptions').map(r => r.label),
      dynamicRange: null,
      dates: [moment().subtract(6, 'days'), moment()]
    }
  },
  computed: {
    ...mapState('reports', {
      isSaving: state => state.savingReport
    }),
    dimensionsAvailable () {
      const availableDimensions = this.$store.getters['reports/dimensions']
      if (!this.canSeeAuctionPackage) {
        return availableDimensions.filter(dimension => dimension.id !== 'AuctionPackage-g')
      }
      return availableDimensions
    },
    metricsAvailable () {
      const availableMetrics = this.$store.getters['reports/metrics']

      if (!this.canSeeAuctionPackage && availableMetrics?.[0]?.children?.length) {
        const metricsIdsToFilterOut = [
          'mediaFee1Amount',
          'mediaFee2Amount',
          'mediaFee3Amount',
          'mediaFee4Amount',
          'mediaFee5Amount'
        ]
        availableMetrics[0].children = availableMetrics[0].children.filter(metric => !metricsIdsToFilterOut.includes(metric.id))
      }

      return availableMetrics
    },
    isCreateInstance () {
      return this.$route.name === 'Create Report' && !this.report
    },
    pageTitle () {
      return this.isCreateInstance ? 'Create' : 'Edit'
    },
    templateToUse () {
      return this.$route.query.template
    },
    showSchedule () {
      // if (this.template) return true
      // if (this.isCreateInstance && !this.templateToUse) return true
      return !!this.schedule
    },
    showEmails () {
      return this.schedule && this.schedule.frequency.key !== 'OnDemand'
    },
    dateRangeText () {
      return this.dates
        .map(d => moment(d).format('MMM Do YYYY'))
        .join(' - ')
    },
    canSaveReport () {
      return !!(this.reportName && this.dimensions.length && this.metrics.length)
    },
    defaultValues () {
      return this.$store.getters['general/marketDefaultValues']
    },
    filtersAvailable () {
      const permissions = this.$store.getters['user/permissions']('report')
      return reportsService.getPartialItems('filters', { seeAll: permissions ? permissions.read.all : false })
    },
    isOnDemand () {
      return this.schedule && this.schedule.frequency && this.schedule.frequency.key === 'OnDemand'
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    canSeeAuctionPackage () {
      return this.$store.getters['general/isAuctionPackageVisible']
    }
  },
  watch: {
    range (newV, oldV) {
      if (oldV && this.isDataLoaded) {
        tracking.sendEvent(['ga'], 'customReportChangedDateRangeType', { label: newV })
        this.$emit('changedReport', this.reportPayload())
      }
    },
    dateRangeText (newV, oldV) {
      if (oldV && this.range === 'fixed' && this.isDataLoaded) {
        tracking.sendEvent(['ga'], 'customReportSelectedFixedDateRange', { label: newV })
        this.$emit('changedReport', this.reportPayload())
      }
    },
    dynamicRange (newV, oldV) {
      if (oldV && this.range === 'dynamic' && this.isDataLoaded) {
        tracking.sendEvent(['ga'], 'customReportSelectedDynamicDateRange', { label: `selection option: ${newV}` })
        this.$emit('changedReport', this.reportPayload())
      }
    },
    timeBreakdown (newV, oldV) {
      if (oldV >= 0 && this.isDataLoaded) {
        tracking.sendEvent(['ga'], 'customReportSelectedTimebreakdown', { label: this.timeBreakdownOptions[newV].label })
        this.$emit('changedReport', this.reportPayload())
      }
    },
    canSaveReport (newV) {
      this.$emit('canSaveReportChanged', newV)
    }
  },
  methods: {
    nameUpdated: _.debounce(function (val) {
      if (val !== null) {
        this.reportName = val
        tracking.sendEvent(['ga'], 'customReportChangedName', { label: `type text: ${val}` })
        this.$emit('changedReport', this.reportPayload())
      }
    }, 300),
    dateRangeUpdated (dateRange) {
      this.dates = [moment(dateRange[0]), moment(dateRange[1])]
      this.$emit('changedReport', this.reportPayload())
    },
    filterToLabel (filter) {
      return reportsService.extractFilterLabel(filter)
    },
    filterSearch (resourceType, searchVal) {
      return reportsApi.searchCampsiteResources(resourceType, searchVal)
        .then(res => {
          return res.map(v => reportsService.buildFilterLabel(v))
        })
    },
    addFilter (filter) {
      this.addedFilters.push({ ...filter })
      tracking.sendEvent(['ga'], 'customReportAddedFilter', { label: `${filter.column.name} ${filter.operator} ${filter.value}` })
      this.$emit('changedReport', this.reportPayload())
    },
    deleteFilter (index) {
      const filterToDelete = this.addedFilters[index]
      tracking.sendEvent(['ga'], 'customReportDeletedFilter', { label: `${filterToDelete.column.name} ${filterToDelete.operator} ${filterToDelete.value}` })
      this.addedFilters.splice(index, 1)
      this.$emit('changedReport', this.reportPayload())
    },
    updateFilter (obj) {
      const { filter, index } = obj
      const copy = [...this.addedFilters]
      copy[index] = { ...filter }
      this.addedFilters = copy
      tracking.sendEvent(['ga'], 'customReportEditedFilter', { label: `${filter.column.name} ${filter.operator} ${filter.value}` })
      this.$emit('changedReport', this.reportPayload())
    },
    dimensionsUpdated (dimensions) {
      const gaEvents = reportsService.extractAnalyticsEventsForTree(this.dimensions, dimensions, !this.isCreateInstance, 'Dimension')
      if (!_.isEqual(this.dimensions, dimensions)) {
        for (const e of gaEvents) tracking.sendEvent(['ga'], e.event, e.details)
        this.dimensions = JSON.parse(JSON.stringify(dimensions))
        this.$emit('changedReport', this.reportPayload())
      }
    },
    metricsUpdated (metrics) {
      const gaEvents = reportsService.extractAnalyticsEventsForTree(this.metrics, metrics, !this.isCreateInstance, 'Metric')
      if (!_.isEqual(this.metrics, metrics)) {
        for (const e of gaEvents) tracking.sendEvent(['ga'], e.event, e.details)
        this.metrics = JSON.parse(JSON.stringify(metrics))
        this.$emit('changedReport', this.reportPayload())
      }
    },
    scheduleUpdated (newValue) {
      const gaEvents = reportsService.extractAnalyticsEventsForSchedule(this.schedule, newValue, !this.isCreateInstance)
      if (gaEvents.length) {
        for (const e of gaEvents) tracking.sendEvent(['ga'], e.event, e.details)
      }

      this.schedule = JSON.parse(JSON.stringify(newValue))
      this.$emit('changedReport', this.reportPayload())
    },
    emailRecipientsUpdated (emails) {
      emails = emails.map(e => typeof e === 'string' ? e.trim() : e)
      const gaEvents = reportsService.extractAnalyticsEventsForEmails(this.emailRecipients, emails)
      if (gaEvents.length) {
        for (const e of gaEvents) tracking.sendEvent(['ga'], e.event)
      }

      this.emailRecipients = emails
      this.$emit('changedReport', this.reportPayload())
    },
    saveReport () {
      tracking.sendEvent(['ga'], 'customReportCreateReport')
      this.$store.dispatch('reports/createReport', this.reportPayload()).then((report) => {
        if (this.isOnDemand) this.$store.dispatch('reports/generateReport', report.id)
        this.$router.push({ name: 'Report', params: { id: report.id } })
      })
    },
    prefillNewReport () {
      this.dynamicRange = this.dynamicRangeOptions[0]
      this.schedule = reportsService.getItems('schedule')
    },
    async prefillEditReport () {
      this.reportName = reportsService.decodeName(this.report)
      this.schedule = reportsService.decodeSchedule(this.report).schedule
      this.emailRecipients = reportsService.decodeSchedule(this.report).emails
      this.addedFilters = await reportsService.decodeTags(this.report)
      this.metrics = reportsService.decodeMetricsPayload(this.report, this.metricsAvailable)
      this.dimensions = reportsService.decodeGroupByPayload(this.report, this.dimensionsAvailable).dimensions

      const tbd = reportsService.decodeGroupByPayload(this.report).timeBreakdown
      this.timeBreakdown = this.timeBreakdownOptions.findIndex(t => t.key === tbd.key)

      const dateRange = reportsService.decodeDateRangePayload(this.report)
      if (dateRange) {
        this.range = dateRange.range
        this.dynamicRange = dateRange.dynamicRange ?? this.dynamicRangeOptions[0]
        if (dateRange.dates.length) this.dates = dateRange.dates
      }
      this.$nextTick(() => {
        this.isDataLoaded = true
      })
    },
    async prefillTemplateReport (template) {
      if (template) {
        if (template.reportName) this.reportName = template.reportName
        if (template.emailRecipients) this.emailRecipients = template.emailRecipients
        if (template.filters?.tags) this.addedFilters = await reportsService.buildFiltersTags(template.filters.tags)
        if (template.metrics) this.metrics = template.metrics
        if (template.dimensions) this.dimensions = template.dimensions
        if (template.timeBreakdown) this.timeBreakdown = this.timeBreakdownOptions.findIndex(t => t.key === template.timeBreakdown.key)
        if (template.schedule) {
          this.schedule = template.schedule
        } else {
          this.schedule = reportsService.getItems('schedule')
        }

        if (template.dateRange) {
          this.range = template.dateRange.range
          this.dynamicRange = template.dateRange.dynamicRange
          if (template.dateRange.dates) this.dates = template.dateRange.dates
        }

        this.$emit('changedReport', this.reportPayload())
      }
    },
    reportPayload () {
      return reportsService.bundleReportPayload({
        timezone: this.defaultValues.timezone,
        filters: this.addedFilters,
        metrics: this.metrics,
        dimensions: this.dimensions,
        timeBreakdown: this.timeBreakdownOptions[this.timeBreakdown],
        name: this.reportName,
        schedule: this.schedule,
        emails: this.emailRecipients,
        dateRange: {
          range: this.range,
          dynamicRange: this.dynamicRange,
          dates: this.dates
        }
      })
    }
  }
}
</script>
