<template lang="pug">
  v-container(grid-list-lg fluid style='height:100%;')
    vue-headful(:title="componentConfig.branding.title('Access denied')")
    v-layout.align-center(fill-height)
      v-flex
        .text-center
          .text-h3 Access Denied
          .text-body-1.mt-2 You are not authorized to access this page
          v-btn.mt-8(outlined color="primary" to="/") go back to campaigns
</template>

<script>
import componentConfigService from '@/services/componentConfig'

export default {
  computed: {
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  }
}
</script>
