<template lang="pug">
div
  .pl-0.pt-1.pb-3
    .text-body-2 Limit venues and screens selection to these environments
    a.info--text(v-if="componentConfig.links.exploreEnvironments"
                :href="componentConfig.links.exploreEnvironments" target='_blank')
      small Explore environments
      v-icon(small color='info') mdi-launch

  #environments-tree
    v-layout.py-3(row wrap v-if='!canLoadEnvs')
      v-flex(text-md-left)
        v-skeleton-loader.my-3(max-width='100' height='10' type='text')
        v-skeleton-loader.my-3(max-width='300' type='heading')
        v-skeleton-loader.my-3(max-width='200' type='heading')
        v-skeleton-loader.my-3(max-width='300' type='heading')
        v-skeleton-loader.my-3(max-width='450' type='heading')
        v-skeleton-loader.my-3(max-width='300' type='heading')
        v-skeleton-loader.my-3(max-width='100' height='12' type='text')
        v-skeleton-loader.my-3(max-width='200' type='heading')
        v-skeleton-loader.my-3(max-width='300' type='heading')
        v-skeleton-loader.my-3(max-width='450' type='heading')
        v-skeleton-loader.my-3(max-width='200' type='heading')
        v-skeleton-loader.my-3(max-width='300' type='heading')

    v-treeview(
      v-if='canLoadEnvs'
      :key='key'
      item-children='children'
      item-key='uniqueKey'
      item-text='label'
      v-model='selectedElements'
      return-object
      :items='items'
      :open.sync= 'openedBranches'
      selected-color='primary'
      open-on-click
      selectable
      dense
      class='body-2 pa-0'
      expand-icon='mdi-chevron-down'
      @input='selectedBranchesDebounced')

      template(v-slot:label="{item}")
        div(:class="item.uniqueKey.toLowerCase().replace(/\\s/g, '_')") {{ item.label }}
</template>

<style scoped>
>>> .v-treeview-node__root{
  min-height: 34px;
}
</style>

<script>
import _ from 'lodash'
import audienceService from '@/services/audience.service'
import tracking from '@/services/tracking'
import componentConfigService from '@/services/componentConfig'

export default {
  props: ['parent'],
  created: function () {
    if (this.segmentsFromStore.id) {
      this.setTreeview(this.segmentsLeafs(this.segmentsFromStore))
    } else if (this.parent === 'createCampaign') {
      this.setTreeview(this.taxonomy)
      this.$store.commit('audience/updateEnvironments', this.buildApiFormat())
    } else {
      this.setTreeview()
    }
  },
  watch: {
    segmentsFromStore: function (newVal) {
      if (this.segmentsFromStore.id) {
        this.setTreeview(this.segmentsLeafs(this.segmentsFromStore))
      }
    },
    exchange: function (newVal, oldVal) {
      if (newVal.key !== oldVal.key) {
        this.setTreeview(this.taxonomy)
        this.$store.commit('audience/updateEnvironments', this.buildApiFormat())
        this.$emit('storeUpdated')
        this.key += 1
      }
    },
    selectedElements (newV, oldV) {
      if (this.ignoredInitialCheckAll) {
        const beforeList = []
        const afterList = []
        for (const v of oldV) beforeList.push(...audienceService.extractLowestChildrenPerEnv(v))
        for (const v of newV) afterList.push(...audienceService.extractLowestChildrenPerEnv(v))

        const diffs = audienceService.findChangedEnvs(
          beforeList.map(x => audienceService.getLabelFromKey(x, this.envKeyToValueMapping)),
          afterList.map(x => audienceService.getLabelFromKey(x, this.envKeyToValueMapping))
        )

        for (const checkedEnv of diffs.checked) tracking.sendEvent(['ga'], 'checkedEnvironment', { label: checkedEnv })
        for (const uncheckedEnv of diffs.unchecked) tracking.sendEvent(['ga'], 'uncheckedEnvironment', { label: uncheckedEnv })
      }
      if (!oldV.length && newV.length && !this.ignoredInitialCheckAll) {
        this.ignoredInitialCheckAll = true
      }
    }
  },
  computed: {
    canLoadEnvs () {
      return true
    },
    exchange () {
      return this.$store.getters['general/currentPageExchange']
    },
    segmentsFromStore () {
      return this.$store.getters['audience/getEnvironmentsApiFormat']
    },
    taxonomies () {
      return this.$store.getters['general/allTaxonomies']
    },
    taxonomy () {
      const exchangeKey = this.exchange.key
      return this.taxonomies[exchangeKey]
        .map(audienceService.generateEnvUniqueKeys)
    },
    items () {
      return [{
        key: 'all',
        uniqueKey: 'all',
        label: 'All',
        children: this.sortNodesAndChildren(this.taxonomy)
      }]
    },
    envKeyToValueMapping () {
      const list = []
      for (const env of this.items[0].children) {
        list.push(...audienceService.getKeyLabelOfLowestChildrenPerEnv(env))
      }
      return list
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  data () {
    return {
      selectedElements: [],
      openedBranches: [],
      ignoredInitialCheckAll: false,
      key: 0
    }
  },
  methods: {
    setTreeview (items = []) {
      this.selectedElements = items

      this.openedBranches = [{
        uniqueKey: 'all'
      }]
    },

    sortNodesAndChildren (nodes) {
      nodes.sort(this.sortByLabel)
      nodes.map(node => {
        if (node.children) {
          node.children = this.sortNodesAndChildren(node.children)
        }
      })
      return nodes
    },

    sortByLabel (a, b) {
      const aLabel = a.label.toLowerCase()
      const bLabel = b.label.toLowerCase()

      if (aLabel < bLabel) {
        return -1
      } else if (aLabel > bLabel) {
        return 1
      } else {
        return 0
      }
    },

    // method since doesn't need to be constantly re-evaluated (computed would do so)
    segmentsLeafs (segments) {
      return audienceService.buildEnvSegmentLeaves(segments, this.taxonomy)
    },

    selectedBranchesDebounced: _.debounce(function () {
      // 1- Update store
      this.$store.commit('audience/updateEnvironments', this.buildApiFormat())

      // 2- Let parent component know about it
      this.$emit('storeUpdated')
    }, 400),

    buildApiFormat () {
      // (!) v-treeview only returns leafs (!)
      // one "group" per parent
      const groups = []

      // extract "keys", easier to search
      const selectedKeys = this.selectedElements.map(el => el.uniqueKey)

      // consider every "leaf" as a "child" for now
      let selectedChildKeys = [...selectedKeys]

      // array to contain "child" selected because all "grandchild" are selected
      // we need to do this since v-treeview only returns "leaf"
      const selectedChildsByGrandchilds = []

      // parse "grandchild"
      const grandchilds = this.selectedElements.filter(el => el.parentKey && el.parentKey.indexOf('.') > -1)
      grandchilds.map(gc => {
        const [parentKey] = gc.parentKey.split('.')

        const parent = this.taxonomy.find(t => t.uniqueKey === parentKey)
        const child = parent.children.find(c => c.uniqueKey === gc.parentKey)

        const allSiblingsSelected = child.children.every(c => selectedKeys.includes(c.uniqueKey))
        if (allSiblingsSelected) {
          // all "siblings" of this "granchild" are also selected
          if (!selectedChildsByGrandchilds.find(c => c.uniqueKey === child.uniqueKey)) {
            // so consider "child" as selected
            selectedChildsByGrandchilds.push(child)

            // add "child"'s key to list since v-treeview doesn't include it
            selectedChildKeys.push(child.uniqueKey)
          }
        } else {
          groups.push(this.buildGroup([parent, child, gc]))
        }

        // filter out "grandchilds" from "childs"
        selectedChildKeys = selectedChildKeys.filter(rk => rk !== gc.uniqueKey)
      })

      const parents = []
      const selectedChilds = this.selectedElements.filter(el => selectedChildKeys.includes(el.uniqueKey))
      const childs = selectedChilds.concat(selectedChildsByGrandchilds)
      childs.map(c => {
        // singleton
        if (!c.parentKey) {
          groups.push(this.buildGroup([c]))
        } else {
          const parent = this.taxonomy.find(t => t.uniqueKey === c.parentKey)
          const allSiblingsSelected = parent.children.every(c => selectedChildKeys.includes(c.uniqueKey))
          if (allSiblingsSelected) {
            if (!parents.find(p => p.uniqueKey === c.parentKey)) {
              parents.push(parent)
            }
          } else {
            groups.push(this.buildGroup([parent, c]))
          }
        }
      })

      parents.map(p => {
        groups.push(this.buildGroup([p]))
      })

      return groups.length
        ? {
          operation: 'or',
          groups: groups
        }
        : {}
    },

    buildGroup (filters) {
      return {
        operation: 'and',
        filters: filters.map(f => {
          return { label: f.label, value: f.key, selectionRule: 'include' }
        })
      }
    }
  }
}
</script>
