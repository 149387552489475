import { gtag, install } from 'ga-gtag'

var isGaBooted = false

function getAnalyticsId (baseUrl) {
  return baseUrl === 'https://buy.broadsign.com' || baseUrl === 'https://campaigns.buy.broadsign.com'
    ? 'G-X7PXCWBWYZ'
    : 'G-G0RP6NVRHZ'
}

function assembleUserInfo (trackingData) {
  if (!trackingData) return null
  return {
    userId: trackingData.userId,
    organizationId: trackingData.organizationId,
    organizationName: trackingData.organizationName,
    orgPaymentStatus: trackingData.organizationPaymentStatus,
    currencyCode: trackingData.currencyCode,
    userRoles: trackingData.userRoles,
    organizationPaymentType: trackingData.organizationPaymentType,
    isBroadsignUser: trackingData.userEmail.endsWith('@broadsign.com'),
    isForAdServer: trackingData.isForAdServer
  }
}

function bootAnalytics (trackingData) {
  if (!isGaBooted) {
    const info = assembleUserInfo(trackingData)
    if (info) {
      install(
        getAnalyticsId(window.location.origin),
        {
          user_properties: info
        }
      )
    }
    isGaBooted = true
  }
}

function sendEvent (type, details) {
  const e = buildEventObj(type, eventsDictionary, details)
  if (e) {
    gtag('event', e.eventAction, {
      event_category: e.eventCategory,
      event_label: e.eventLabel,
      value: e.eventValue
    })
  } else {
    console.log('GA event not found', type, details)
  }
}

function buildEventObj (e, taxo, details = null) {
  if (!taxo || !e || !taxo[e] || !taxo[e].action || !taxo[e].category) return

  const obj = Object.assign({}, taxo[e], details)

  if (!obj.action || !obj.category || !obj.label) return

  const formatted = {
    eventCategory: obj.category.toLowerCase(),
    eventAction: obj.action.toLowerCase(),
    eventLabel: obj.label.toLowerCase(),
    eventValue: obj.value || 0
  }
  return formatted
}

const eventsDictionary = {
  createProposal: { action: 'created proposal', category: 'proposal', label: 'click button: save as proposal', value: 30 },
  addedLineToProposal: { action: 'added line order to proposal', category: 'proposal', label: 'click button: add line', value: 30 },
  convertedProposalToCampaign: { action: 'converted proposal to campaign', category: 'proposal', label: 'click button: convert to campaign', value: 50 },
  openedShareProposalDialog: { action: 'opened share proposal dialog', category: 'proposal', label: 'click button: share', value: 20 },
  copiedShareProposalLink: { action: 'copied share proposal link', category: 'proposal', label: 'click icon button: copy link', value: 30 },

  openedEditProposalDialog: { action: 'opened edit proposal dialog', category: 'proposal', label: 'click button: edit', value: 10 },
  changedProposalName: { action: 'changed proposal name', category: 'proposal', label: null, value: 20 },
  changedAdvertiser: { action: 'changed advertiser', category: 'proposal', label: null, value: 20 },
  savedProposal: { action: 'saved proposal', category: 'proposal', label: 'click button: save', value: 30 },

  openedBulkUploadDialog: { action: 'opened bulk upload dialog', category: 'audience panel', label: 'click icon button: bulk upload', value: 10 },
  checkedDemographic: { action: 'checked demographic', category: 'audience panel', label: null, value: 20 },
  uncheckedDemographic: { action: 'unchecked demographic', category: 'audience panel', label: null, value: 10 },
  checkedEnvironment: { action: 'checked environment', category: 'audience panel', label: null, value: 10 },
  uncheckedEnvironment: { action: 'unchecked environment', category: 'audience panel', label: null, value: 10 },
  locationAdded: { action: 'added location from search', category: 'audience panel', label: null, value: 20 },
  locationDeleted: { action: 'removed location', category: 'audience panel', label: null, value: 10 },
  locationExcluded: { action: 'excluded location', category: 'audience panel', label: null, value: 10 },
  locationBulkAdded: { action: 'added location from bulk', category: 'audience panel', label: null, value: 10 },
  addedPublisher: { action: 'added media owner', category: 'audience panel', label: null, value: 30 },
  removedPublisher: { action: 'removed media owner', category: 'audience panel', label: null, value: 10 },
  addedCreativeFormat: { action: 'added creative format', category: 'audience panel', label: null, value: 30 },
  removedCreativeFormat: { action: 'removed creative format', category: 'audience panel', label: null, value: 10 },
  addedCreativeSize: { action: 'added creative size', category: 'audience panel', label: null, value: 30 },
  removedCreativeSize: { action: 'removed creative size', category: 'audience panel', label: null, value: 10 },
  addedMeasurementPartner: { action: 'added measurement partner', category: 'audience panel', label: null, value: 30 },
  removedMeasurementPartner: { action: 'removed measurement partner', category: 'audience panel', label: null, value: 10 },

  exportVenuesCsv: { action: 'exported venues in csv', category: 'inventory tab', label: 'click button: export venues in csv', value: 50 },
  exportFacesCsv: { action: 'exported screens in csv', category: 'inventory tab', label: 'click button: export screens in csv', value: 50 },
  exportInventoryCsv: { action: 'opened export as csv', category: 'inventory tab', label: 'click button: export as csv', value: 10 },

  createdCampaign: { action: 'created campaign', category: 'campaign', label: 'click button: create campaign', value: 30 },

  openedAdvancedCsvExportDialog: { action: 'opened advanced csv export dialog', category: 'report: campaign', label: 'click button: export csv', value: 0 },
  exportedToCsv: { action: 'exported to csv', category: 'report: campaign', label: 'click button: export', value: 0 },

  addedCreditCard: { action: 'added credit card', category: 'payment method', label: 'click button: add credit card', value: 100 },
  updatedPaymentMethod: { action: 'updated payment method', category: 'payment method', label: null, value: 100 },

  addedCreative: { action: 'added creative', category: 'creative panel', label: 'click button: add creative', value: 80 },

  userSession: { action: 'started session', category: 'session', label: 'visited campsite', value: 10 },
  navigationReportsTabClicked: { action: 'clicked reports', category: 'navigation', label: 'click tab: reports section', value: 10 },

  createdOrganization: { action: 'created organization', category: 'organization', label: 'click button: add organization', value: 10 },
  invitedUser: { action: 'invited user', category: 'organization', label: 'click button: invite user', value: 50 },
  openedInviteUser: { action: 'opened invite user dialog', category: 'organization', label: 'click button: add user', value: 10 },
  termsAccepted: { action: 'terms accepted', category: 'organization', label: 'click button: accept terms', value: 10 },

  openedColorPicker: { action: 'opened brand color picker', category: 'organization', label: 'click textfield: brand color', value: 10 },
  changeThemeColor: { action: 'changed brand color', category: 'organization', label: null, value: 50 },
  resetTheme: { action: 'reset theme', category: 'organization', label: 'click button: reset to default', value: 10 },
  savedTheme: { action: 'saved theme', category: 'organization', label: 'click button: save changes', value: 100 },
  uploadedThemeLogo: { action: 'uploaded theme logo image', category: 'organization', label: 'click file input: upload your logo', value: 50 },
  enabledApiAccess: { action: 'enabled API access', category: 'organization', label: 'toggle switch: on', value: 100 },
  disabledApiAccess: { action: 'disabled API access', category: 'organization', label: 'toggle switch: off', value: 10 },

  searchedDeal: { action: 'searched deal', category: 'organization / inventory', label: null, value: 10 },
  addedDeal: { action: 'added deal', category: 'organization / inventory', label: null, value: 50 },
  removedDeal: { action: 'removed deal', category: 'organization / inventory', label: null, value: 10 },
  savedDeals: { action: 'saved deals', category: 'organization / inventory', label: 'click button: save changes', value: 100 },

  momentsPanelOpened: { action: 'opened moments panel', category: 'moments panel', label: 'click panel: moments', value: 10 },
  momentsPanelClosed: { action: 'closed moments panel', category: 'moments panel', label: 'click panel: moments', value: 10 },

  momentsWeatherOpened: { action: 'opened weather card', category: 'moments panel / weather / condition', label: 'click button: + weather condition', value: 30 },
  momentsWeatherClosed: { action: 'closed weather card', category: 'moments panel / weather / condition', label: 'click button: close weather condition', value: 10 },
  momentsWeatherLocationOptionChanged: { action: 'targeted weather location', category: 'moments panel / weather / condition', label: null, value: 10 },
  momentsWeatherLocationSelected: { action: 'set destination weather', category: 'moments panel / weather / condition', label: null, value: 30 },
  momentsWeatherTemperatureRangeChanged: { action: 'set temperature', category: 'moments panel / weather / condition', label: null, value: 30 },
  momentsWeatherSkyConditionSelected: { action: 'checked sky condition', category: 'moments panel / weather / condition', label: null, value: 30 },
  momentsWeatherSkyConditionUnselected: { action: 'unchecked sky condition', category: 'moments panel / weather / condition', label: null, value: 30 },

  momentsFinanceOpened: { action: 'opened card', category: 'moments panel / finance / [financeType]', label: 'click button: + [financeType]', value: 30 },
  momentsFinanceClosed: { action: 'closed card', category: 'moments panel / finance / [financeType]', label: 'click button: close [financeType]', value: 10 },
  momentsFinanceSelected: { action: 'selected', category: 'moments panel / finance / [financeType]', label: null, value: 30 },
  momentsFinanceUnselected: { action: 'removed', category: 'moments panel / finance / [financeType]', label: null, value: 30 },
  momentsFinanceLookupPeriodChanged: { action: 'set lookup period', category: 'moments panel / finance / [financeType]', label: null, value: 10 },
  momentsFinanceConditionTypeChanged: { action: 'set metric operator', category: 'moments panel / finance / [financeType]', label: null, value: 10 },
  momentsFinanceComparisonTypeChanged: { action: 'set condition operator', category: 'moments panel / finance / [financeType]', label: null, value: 10 },
  momentsFinanceTargetValueChanged: { action: 'set condition value', category: 'moments panel / finance / [financeType]', label: null, value: 10 },

  customReportChangedName: { action: 'entered report name', category: 'report form', label: 'type text: [value]', value: 10 },
  customReportChangedDateRangeType: { action: 'changed date range type', category: 'report form', label: '[value]', value: 10 },
  customReportSelectedFixedDateRange: { action: 'selected fixed date range', category: 'report form', label: '[value]', value: 10 },
  customReportSelectedDynamicDateRange: { action: 'selected dynamic date range', category: 'report form', label: 'selection option: [value]', value: 10 },
  customReportSelectedTimebreakdown: { action: 'selected time breakdown', category: 'report form', label: '[value]', value: 10 },
  customReportAddedFilter: { action: 'added filter', category: 'report form', label: '[filter name] [operator] [value]', value: 30 },
  customReportEditedFilter: { action: 'edited filter', category: 'report form', label: '[filter name] [operator] [value]', value: 10 },
  customReportDeletedFilter: { action: 'deleted filter', category: 'report form', label: '[filter name] [operator] [value]', value: 10 },
  customReportAddedDimension: { action: 'added dimension', category: 'report form', label: '[value]', value: 30 },
  customReportDeletedDimension: { action: 'deleted dimension', category: 'report form', label: '[value]', value: 10 },
  customReportAddedMetric: { action: 'added metric', category: 'report form', label: '[value]', value: 30 },
  customReportDeletedMetric: { action: 'deleted metric', category: 'report form', label: '[value]', value: 10 },
  customReportSelectedScheduleFrequency: { action: 'selected schedule frequency', category: 'report form', label: 'select option: [value]', value: 100 },
  customReportChangedScheduleStartDate: { action: 'changed schedule start date', category: 'report form', label: '[value]', value: 10 },
  customReportChangedScheduleEndDate: { action: 'changed schedule end date', category: 'report form', label: '[value]', value: 10 },
  customReportChangedDaysOfTheWeekSelection: { action: 'changed days of the week selection', category: 'report form', label: '[value]', value: 10 },
  customReportAddedEmail: { action: 'added email recipient', category: 'report form', label: 'type email', value: 50 },
  customReportDeletedEmail: { action: 'deleted email recipient', category: 'report form', label: 'click icon button: remove', value: 10 },
  customReportCreateReport: { action: 'created report', category: 'report form', label: 'click button: save', value: 100 },
  customReportSaveEditReport: { action: 'saved edit report', category: 'edit report', label: 'click button: save', value: 30 },
  customReportCancelEditReport: { action: 'canceled edit report', category: 'edit report', label: 'click button: cancel', value: 10 },
  customReportUsedTemplate: { action: 'used template', category: 'reports overview', label: 'click button: use template [value]', value: 30 },
  customReportRanReportReportsOverview: { action: 'ran report', category: 'reports overview', label: 'click button: run report', value: 50 },
  customReportDownloadedReportExportReportsOverview: { action: 'downloaded report export', category: 'reports overview', label: 'click icon button: download', value: 100 },
  customReportDownloadedReportExportReportPage: { action: 'downloaded report export', category: 'report page', label: 'click button: export results', value: 100 },
  customReportRanReportReportPage: { action: 'ran report', category: 'report page', label: 'click button: run report', value: 50 },
  customReportOpenedEditReportDialog: { action: 'opened edit report dialog', category: 'report page', label: 'click button: edit', value: 10 },
  customReportDeletedReportReportPage: { action: 'deleted report', category: 'report page', label: 'click button: delete report', value: 10 },
  customReportDeletedReportReportsOverview: { action: 'deleted report', category: 'reports overview', label: 'click button: delete report', value: 10 }
}

export default {
  getAnalyticsId,
  assembleUserInfo,
  bootAnalytics,
  buildEventObj,
  sendEvent
}
