<template lang="pug">
  v-list-item-avatar(:color="color")
    router-link.initials(:to="{ name: 'user', params: { userId: userId }}") {{ initials }}
</template>

<script>
import helpersService from '@/services/helpers.service'

export default {
  props: ['user'],
  computed: {
    userId () {
      return this.user.id || this.user.userId
    },
    initials () {
      return helpersService.initials(this.user)
    },
    color () {
      return helpersService.avatarBackgroundColor(this.user)
    }
  }
}
</script>

<style scoped lang="stylus">
.initials {
  color: white
}
</style>
