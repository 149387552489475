<template lang="pug">
  v-app-bar(app color='white' fixed dense clipped-left elevate-on-scroll)
    v-app-bar-nav-icon.drawer-opener-btn(v-if='appReady' @click.stop='drawerClicked')
    v-btn.logo.px-1.mr-4(v-if='appReady  && (customLogo || componentConfig.branding.defaultLogo)' color='primary' text router-link to='/' style='min-width: unset')
      img(v-if='customLogo' :src='customLogo' height='33')
      img(v-else :src='componentConfig.branding.defaultLogo' height='29')
    v-spacer
    //- v-text-field.global-search(
      v-if='appReady'
      v-model='search'
      :class="{'mx-4': $vuetify.breakpoint.smAndUp }"
      label='Search anything'
      style="max-width:450px;"
      prepend-inner-icon='mdi-magnify'
      single-line flat solo-inverted
      hide-details dense
      light clearable
      )
    v-toolbar-items(v-if='appReady')
      v-btn(icon :href="componentConfig.links.navbarHelp" target="_blank")
        v-icon
          | mdi-help-circle
      menuAddContent(v-if='!$vuetify.breakpoint.smAndDown')
      menuNotifications(v-if='isTokenSet')
      menuProfile(
        v-if='!$vuetify.breakpoint.smAndDown'
        :user-display-name='userDisplayName'
        :user-profile-link='userProfileLink'
        :user-organization='userOrganization'
      )
</template>

<script>
import menuProfile from '@/components/menuProfile.vue'
import menuAddContent from '@/components/menuAddContent.vue'
import menuNotifications from '@/components/menuNotifications.vue'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    menuProfile,
    menuAddContent,
    menuNotifications
  },
  props: ['appReady', 'userDisplayName', 'userProfileLink', 'userOrganization'],
  computed: {
    isTokenSet () {
      return this.$store.getters['user/getToken']
    },
    customLogo () {
      return this.$store.getters['general/getOrganizationLogo']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    drawerClicked () {
      this.$emit('toggleDrawer')
    }
  }
}
</script>

<style scoped>
  .v-bar--underline{
    border-width: 0 0 thin;
    border-style: solid;
    border-bottom-color: rgba(0,0,0,.12)!important;
  }
</style>
