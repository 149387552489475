<template lang="pug">
.home
  vue-headful(:title="componentConfig.branding.title('Plans Overview')")
  .header.account-alerts(v-if='gotAlert')
    v-container(fluid grid-list-lg text-left )

      //- Suspended Account
      v-layout.suspended-account-alert(row wrap fill-height v-if='isSuspended')
        v-flex(xs12 sm12 md12)
          v-alert.mb-0(:value='true' type='error' icon='mdi-alert' dense outlined)
            .text-body-2(class='text--primary')
              strong Account Suspended
            .text-body-2(class='text--primary')
              | Your account is temporarily suspended and your ads have stopped running. Please
              router-link.info--text(to='/billing')  pay your account balance
              |  so you may continue using Broadsign Ads.
              a.info--text(href='https://intercom.help/broadsign-ads/en/articles/6037505-why-is-my-account-suspended' target='_blank')
                |  Learn more
              |  or
              a.info--text(href='mailto:info_ads@broadsign.com')
                |  contact us.

      alertDisabledAccount(
        ref='alertDisabledAccountRef'
        :isDisabled='isDisabled'
        :cards='cards'
        :paymentMethods='paymentMethods'
        :organization='organization'
        :permissions="$store.getters['user/permissions']('invoice')"
        @closeAndRefresh='closeAndRefresh'
        )

  v-card.pt-3(color='transparent' flat)
    v-toolbar.elevation-0(color='transparent')
      .text-h5.hidden-sm-and-down Plans
      v-text-field(label='Find Plans' v-model='search' hide-details prepend-inner-icon='mdi-magnify' solo clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")
      v-btn#create-plan-btn.mt-0.hidden-sm-and-down.cta1-create-plan(large rounded color='primary' router-link to='/plans/create' v-if="can.create.default")
        v-icon(left) mdi-plus
        span New Plan
      template(v-slot:extension='')
        v-tabs(color='primary' background-color='transparent' slider-color='primary' :value='currentTabIdx')
          v-tab.tab-class(ripple color='primary lighten-4' v-for='(tab, i) in tabs' :key='i' @change='changeTab(i)' :id='tab')
            | {{ tab }}
    v-divider

    v-scroll-y-reverse-transition
      v-toolbar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selected.length < plans.length') {{ selected.length }} plans selected
          v-toolbar-title.text-subtitle-1(v-if='selected.length === plans.length && plans.length < totalItems && !selectAll')
            | The {{ plans.length }} plans on this page are selected
          v-toolbar-title.text-subtitle-1(v-if='selected.length === totalItems || selectAll') All {{ totalItems }} plans are selected

        span.hidden-md-and-up
          v-chip.ma-1(v-if='selected.length < totalItems && !selectAll' dark outlined color='white' small)
            | {{ selected.length }}
          v-chip.ma-1(v-if='selected.length === totalItems || selectAll' dark color='white' class='secondary--text' small)
            | {{ totalItems }}

        v-btn.ml-2(text outlined small dark v-if='selected.length === plans.length && plans.length < totalItems && !selectAll' @click='selectAll = true') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ totalItems }}&nbsp;
            | plans

        v-toolbar-items.ml-3
          v-btn(:icon='$vuetify.breakpoint.smAndDown' :text='$vuetify.breakpoint.mdAndUp' @click='exportPlansToCsv()')
            v-icon(:small='$vuetify.breakpoint.mdAndUp') mdi-download
            span.ml-2.hidden-sm-and-down Export CSV
          confirmationDialog(@confirmAction='duplicatePlans' type='plan' action='duplicate' :numberOfEntries='selected.length')
          //- v-menu(v-if='$vuetify.breakpoint.xsOnly' :close-on-content-click='false' v-model='mobileMenuOpen')
            template(v-slot:activator='{ on }')
              v-btn(icon v-on='on' offset-y)
                v-icon mdi-dots-vertical
            v-list
              v-list-item
                confirmationDialog(
                  @confirmAction='archivePlans'
                  @closed='mobileMenuOpen = false'
                  type="plan"
                  :action="currentTab === 'archived' ? 'restore' : 'archive'"
                  :numberOfEntries='selected.length'
                  :textButtonOnly='true'
                  )
          //- confirmationDialog(
            v-if='$vuetify.breakpoint.smAndUp'
            @confirmAction='archivePlans'
            type="plan"
            :action="currentTab === 'archived' ? 'restore' : 'archive'"
            :numberOfEntries='selected.length'
            )

        v-spacer
        v-btn.ml-3(icon @click='selected = []')
            v-icon mdi-close

    v-data-table.cs-data-table(
      :headers='headers'
      :items='plans'
      :loading='isTableLoading'
      :options.sync='pagination'
      :page='pagination.page'
      v-model='selected'
      :footer-props='{itemsPerPageOptions: [50, 100, 250]}'
      show-select
      :dense='$vuetify.breakpoint.xsOnly'
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      :server-items-length='totalItems'
      )
      template(v-slot:no-data='')
        div#plan-overview-no-data.my-12(v-if='!totalPlans && !search')
          .text-h5 Create your first plan
          .text-body-2 Start reaching your audience today. Let us guide you through the process.
          v-btn(text color='primary' router-link to='/plans/create')
            v-icon mdi-plus
            | Plan
        .my-12(v-if='totalPlans || search')
          .text-body-2 {{ noDataText }}

      template.text-left.widths(v-slot:item.account='{ item }')
        div.plan-advertiser-data(style='max-width: 200px;')
          .d-block.text-truncate(:title='item.account') {{ item.account }}
          .text-caption.d-block.text-truncate(class='grey--text' v-if="can.read.orgInfo" :title='organizationLabel(item)') {{ organizationLabel(item) }}

      template.text-left(v-slot:item.name='{ item }')
        div.plan-name-data(style='min-width:200px; word-break: break-word;')
          router-link.info--text(:to="'/plans/' + item.id")
            | {{ item.name }}

      template(v-slot:item.budget='{ item }')
        div.plan-budget-data(style='min-width:75px;')
          | {{ item.budget | currency }}
      template.text-right(v-slot:item.startDate='{ item }')
        div.plan-start-date-data(style='min-width:75px;')
          | {{ item.startDate | date('MMM Do, YYYY') }}
      template.text-right(v-slot:item.endDate='{ item }')
        div.plan-end-date-data(style='min-width:75px;')
          | {{ item.endDate | date('MMM Do, YYYY') }}

      //- Mobile version
      template.text-left(v-slot:item='{ item, isSelected, select }' v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-row.my-0(align='center' justify='space-between')
            v-col(cols=3)
              v-checkbox.mt-0.pt-0(@click.stop='isSelected ? select(false) : select(true)' :value='isSelected' hide-details color='primary')
            v-col(cols='auto')
              div.text-caption.grey--text  {{ item.startDate | date('MMM Do, YYYY') }} - {{ item.endDate | date('MMM Do, YYYY') }}
          div
            div.mb-1(:title='item.account')
              .text-overline {{ item.account }}
              .text-caption(class='grey--text' v-if="can.read.orgInfo" :title='organizationLabel(item)') {{ organizationLabel(item) }}
          div
            router-link.info--text(:to="'/plans/' + item.id")
              span.text-body-1(style='word-break: break-word;') {{ item.name }}
    v-divider
  v-btn#create-plan-mobile-btn.hidden-md-and-up(v-if="can.create.default" medium fab color='primary' router-link to='/plans/create' fixed bottom right v-show='!selected.length')
    v-icon(dark large) mdi-plus
</template>

<script>
import campaignsApi from '@/services/campaigns.api'
import userApi from '@/services/user.api'

import csvService from '@/services/csv.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import componentConfigService from '@/services/componentConfig'

import _ from 'lodash'
import moment from 'moment'

import confirmationDialog from '@/components/actionConfirmationDialog.vue'
import convertToCampaignDialog from '@/components/transferProposalDialog.vue'
import spentVsBudgetProgressBar from '@/components/spentVsBudgetProgressBar.vue'
import alertDisabledAccount from '@/components/alertDisabledAccount.vue'

export default {
  components: {
    confirmationDialog,
    spentVsBudgetProgressBar,
    convertToCampaignDialog,
    alertDisabledAccount
  },
  created: function () {
    if (!this.$flags.canSeeUiRebrand.isEnabled()) {
      this.$router.push({ name: 'Campaigns Overview' })
      return
    }

    this.canSeeNewUi = true
    this.$store.dispatch('billing/getActivePaymentMethods', this.organizationId)

    // extract only supported values from query string, to avoid breaking pagination
    var { sortBy, sortDesc, search } = this.$route.query
    if (sortBy) { this.pagination.sortBy = [sortBy] }

    if (sortDesc) {
      // convert query string to boolean
      this.pagination.sortDesc = [sortDesc === 'true']
    }

    if (search) {
      this.searchSetFromQueryString = true
      this.search = search
    }

    // set "tab" from URL
    var location = this.$route.path
    var locationPieces = location.split('/')

    const tabName = locationPieces[locationPieces.length - 1]
    const tabIndex = this.tabs.findIndex(tab => tab === tabName)
    this.currentTab = tabName
    this.currentTabIdx = tabIndex

    // empty states
    campaignsApi.getTotalCampaignsCount().then(res => {
      this.totalPlans = res
    })
  },
  watch: {
    search: _.debounce(function (newVal, oldVal) {
      // bypass initial set from URL
      if (!this.searchSetFromQueryString) {
        // keep "sort"
        var { sortBy, sortDesc } = this.$route.query
        const newQuery = { sortBy, sortDesc }

        // set "search", if any
        if (newVal) { newQuery.search = newVal }

        // update query string
        this.$router.push({ query: newQuery })
        this.getPlans()
      } else {
        this.searchSetFromQueryString = false
      }
    }, 450),

    currentTab: function (newVal, oldVal) {
      this.changeTabURL(newVal, oldVal)

      // bypass initial set from URL
      if (oldVal !== '') {
        // reset Paging
        this.$set(this.pagination, 'page', 1)

        this.getPlans()
      }
    },

    pagination: function (newVal, oldVal) {
      // set "sort"
      var { sortBy, sortDesc } = newVal

      const newQuery = { sortBy, sortDesc }

      // keep any query params other than "sort" (ex: tracking vars "utm_")
      const queryParams = Object.keys(this.$route.query)

      const sortParams = ['sortBy', 'sortDesc']
      queryParams
        .filter(param => !sortParams.includes(param))
        .map(param => {
          newQuery[param] = this.$route.query[param]
        })

      if (this.$route.query.sortBy && this.$route.query.sortDesc) {
        // update route params only if they changed
        if (oldVal.sortBy !== sortBy || oldVal.sortDesc !== sortDesc) {
          this.$router.push({ query: newQuery })
        }
      } else {
        // add default params in route
        this.$router.replace({ query: newQuery })
      }

      this.getPlans()
    },

    selected: function () {
      if (this.selected < this.plans) {
        this.selectAll = false
      }
    }
  },
  data () {
    return {
      totalPlans: 1,
      totalItems: 0,
      selectAll: false,
      mobileMenuOpen: false,
      currentTab: '',
      currentTabIdx: -1,
      tabs: ['all'],
      selected: [],
      search: '',
      searchSetFromQueryString: false,
      plans: [],
      isTableLoading: true,

      supportDefinitions: null,
      colsWithDefinitions: {
        Plans: 'Plan',
        Spent: 'Amount Spent',
        Impressions: 'Impression',
        eCPM: 'eCPM'
        // AdsServed: 'Ad Served'
      },
      pagination: {
        sortBy: ['startDate'],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
        totalItems: 0
      },
      headers: [
        { text: 'Advertiser', align: 'left', value: 'account', width: '200px', class: 'plan-advertiser' },
        { text: 'Plans', align: 'left', value: 'name', class: 'plan-name' },
        { text: 'Total Media Cost', align: 'left', value: 'budget', class: 'plan-budget' },
        { text: 'Start Date', align: 'right', value: 'startDate', width: '110px', class: 'plan-start-date' },
        { text: 'End Date', align: 'right', value: 'endDate', width: '110px', class: 'plan-end-date' }
      ],
      canSeeNewUi: false
    }
  },
  computed: {
    can () {
      return this.$store.getters['user/permissions']('proposal')
    },
    organization () {
      return this.$store.getters['user/getOrganization']
    },
    organizationId () {
      return this.organization.id
    },
    paymentStatus () {
      return this.$store.getters['user/getPaymentStatus']
    },
    paymentMethods () {
      return this.$store.getters['billing/paymentMethods']
    },
    cards () {
      return this.$store.getters['billing/cards']
    },
    hasPaymentMethods () {
      return this.paymentMethods ? this.paymentMethods.length : true
    },
    noDataText () {
      return !this.plans.length && this.search && this.search.length
        ? 'No plan matches your search'
        : 'No plan found'
    },
    isDisabled () {
      return this.paymentStatus === 'Unspecified' && !this.hasPaymentMethods
    },
    isSuspended () {
      // MAX: paymentStatus === "suspended" can happen in two cases:
      //   CASE #1 : I deleted my last ccard
      //   CASE #2 : attempt to bill existing ccard failed
      //     the message will still say "Please pay your account balance so you may continue using Broadsign Ads."
      //     which is NOT TRUE in case #1
      return this.paymentStatus === 'Suspended' && this.organization.paymentType === 'Automatic'
    },
    gotAlert () {
      return this.isSuspended || this.isDisabled
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    getPlans () {
      this.isTableLoading = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'

      var filters = this.buildFiltersList()

      campaignsApi.getCampaignsCount(filters).then(count => {
        this.totalItems = count
      })

      campaignsApi.getCampaigns(skip, take, sortBy[0], sort, filters)
        .then(plans => {
          this.plans = plans
          this.isTableLoading = false
          if (this.selectAll) {
            this.selected = plans
          }
        })
        .catch(error => {
          let msg = error.data.errors[0].errorCode
          if (msg === 'unexpected') {
            msg = 'Unexpected: ' + error.data.errors[0].id
          }

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    archivePlans () {
      // Used to archive and unarchive a plan
      if (this.selected.length === this.plans.length || this.selectAll) {
        this.bulkPatchActions(this.currentTab !== 'archived' ? 'archive' : 'restore')
      } else {
        const isPlural = this.selected.length > 1

        this.selected.forEach(plan => {
          const action = this.currentTab !== 'archived' ? 'archived' : 'restored'
          const modifiedFields = {
            id: plan.id,
            status: this.currentTab !== 'archived' ? 'Archived' : 'Active'
          }
          campaignsApi.editCampaign(plan.accountId, plan.id, modifiedFields).then(updatedPlan => {
            const index = this.plans.findIndex(x => x.id === updatedPlan.id)
            this.plans.splice(index, 1)
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: isPlural ? 'Plans successfully ' + action : updatedPlan.name + ' successfully ' + action
            })
          })
            .catch(error => {
              let msg = error.data.errors[0].errorCode
              if (msg === 'unexpected') {
                msg = 'Unexpected: ' + error.data.errors[0].id
              }

              this.$store.commit('snackbar/setSnackbar', {
                type: 'error',
                msg: `${msg}`
              })
            })
        })
        this.selected = []
      }
    },

    duplicatePlans () {
      const threshold = 10
      const picked = this.selected.length > threshold ? this.selected.slice(0, threshold) : this.selected

      const isPlural = this.selected.length > 1

      picked.forEach(plan => {
        campaignsApi.duplicateCampaign(plan.accountId, plan.id, false).then(updatedPlan => {
          const index = this.plans.findIndex(x => x.id === plan.id)
          this.plans.splice(index, 0, updatedPlan)
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: isPlural ? 'Plans successfully duplicated' : updatedPlan.name + ' successfully duplicated'
          })
        })
          .catch(error => {
            let msg = error.data.errors[0].errorCode
            if (msg === 'unexpected') {
              msg = 'Unexpected: ' + error.data.errors[0].id
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
      })
      this.selected = []
    },

    exportPlansToCsv () {
      function formatter (x) {
        return {
          plan: x.name,
          Advertiser: x.account,
          'Total Media Cost': x.budget,
          'Satrt Date': moment(x.startDate).format('MMMM Do, YYYY'),
          'End Date': moment(x.endDate).format('MMMM Do, YYYY')
        }
      }

      if (this.selectAll) {
        campaignsApi.getCampaignsListForCSV(this.buildFiltersList()).then(res => {
          csvService.csvExport(res.map(x => formatter(x)), 'plans')
        })
      } else {
        csvService.csvExport(this.selected.map(x => formatter(x)), 'plans')
      }
      this.selected = []
    },

    bulkPatchActions (action) {
      const configs = {
        archive: { status: 'Archived' },
        restore: { status: 'Active' },
        convert: { status: 'Active' }
      }
      const verbs = {
        archive: 'archived',
        restore: 'restored',
        convert: 'converted'
      }

      const { page, itemsPerPage } = this.pagination
      const params = {
        filters: this.buildFiltersList()
      }
      if (!this.selectAll) {
        params.skip = (page - 1) * itemsPerPage
        params.take = itemsPerPage
      }

      campaignsApi.bulkAction(params, configs[action]).then(res => {
        this.$store.commit('snackbar/setSnackbar', {
          type: 'success',
          msg: 'The selected plans have been successfully ' + verbs[action]
        })
        this.getPlans()
      })
        .catch(error => {
          let msg = error.data.errors[0].errorCode
          if (msg === 'unexpected') {
            msg = 'Unexpected: ' + error.data.errors[0].id
          }

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
      this.selected = []
    },

    // ================
    // HELPER FUNCTIONS
    // ================

    buildFiltersList () {
      var filters = []
      filters.push({ name: 'isForAdServer', value: this.$store.getters['user/isForAdServer'] })
      filters.push({ name: 'mainStatus', keyName: 'status', value: 'Proposal' })

      if (this.search) filters.push({ name: 'search', value: this.search })

      return filters
    },

    organizationLabel (item) {
      const country = defaultExchangeValues.getDefaultValuesByCurrency(item.currency).countryCode
      return `${item.owner.firstName} ${item.owner.lastName} from ${country} - ${item.owner.organizationName}`
    },

    changeTab (newVal) {
      this.currentTab = this.tabs[newVal]
      this.currentTabIdx = newVal
    },

    changeTabURL (newVal, oldVal) {
      const oldTab = this.tabs.find(tab => tab === oldVal)
      const newTab = this.tabs.find(tab => tab === newVal)

      var path = this.$route.path
      var pathPieces = path.split('/')
      var urlTab = pathPieces[pathPieces.length - 1]
      if (urlTab !== newTab) {
        var newLocation
        var index = path.lastIndexOf(oldTab)
        var locationPieces = path.split('')

        // route's beforeEnter() has not finished executing and User clicks on a tab
        if (index === -1) {
          newLocation = locationPieces.join('') + '/' + newTab
        } else {
          locationPieces.splice(index, index + oldTab.length, newTab)
          newLocation = locationPieces.join('')
        }

        this.$router.push({ path: newLocation, query: this.$route.query })
      }
    },

    closeAndRefresh () {
      this.reloadOrganization()
      this.$store.dispatch('billing/getActivePaymentMethods', this.organizationId)
    },
    reloadOrganization () {
      return userApi.getOrganization(this.organizationId)
        .then(organization => {
          this.$store.commit('user/setOrganization', organization)
        })
    }
  }
}
</script>
