<template lang="pug">
  v-list(dense max-height=128 style='overflow-x:hidden; overflow-y:auto;')
    savedCreditCard(v-for='(card, i) in cards' :card='card' :index='i' :key='i' :disabled='disabled' @closeAndRefresh="$emit('closeAndRefresh', 'update')")
</template>

<script>
import savedCreditCard from '@/components/savedCreditCard.vue'

export default {
  components: {
    savedCreditCard
  },
  props: ['cards', 'disabled']
}
</script>
