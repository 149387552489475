<template lang="pug">
  div
    form(enctype='multipart/form-data' novalidate)
      v-hover(v-slot:default="{ hover }")
        .file-wrapper
          input.input-file(
            ref='fileBrowser'
            type='file'
            multiple
            :accept='acceptedFileTypes'
            :name='uploadFieldName'
            :disabled='isSaving'
            @change='filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length')

          v-btn.mt-0(
            large
            text
            :outlined="hover"
            block
            color='primary') Add Creatives
</template>
<script>
import campsiteConfig from '@/config/campsite.config'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  props: ['format', 'initialCount', 'uploadData'],
  data () {
    return {
      fileCount: 0,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos'
    }
  },
  computed: {
    extraUploadData () {
      return this.uploadData || null
    },
    count () {
      return this.initialCount || 0
    },
    requiredFormat () {
      return this.format || null
    },
    badgeColor () {
      return this.count > 0 ? 'success' : 'error'
    },
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    },
    uploadedFiles () {
      return this.$store.getters['creatives/getUploadingCreatives']
    },
    acceptedFileFormats () {
      var exchange = this.$store.getters['createCampaign/getExchange']
      return campsiteConfig.creatives.acceptedCreativeFormats[exchange.key]
    },
    acceptedFileTypes () {
      var exchange = this.$store.getters['createCampaign/getExchange']
      return campsiteConfig.creatives.acceptedFileTypes[exchange.key]
    }
  },
  methods: {
    openFileBrowser ($event) {
      const elem = this.$refs.fileBrowser
      elem.click()
    },
    reset () {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadError = null
    },
    save (formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING

      // dispatch action to (creative) Store, which will call (creative) API
      this.$store.dispatch('creatives/uploadCreative', formData)
        .then(uploadedCreative => {
          this.currentStatus = uploadedCreative.id ? STATUS_SUCCESS : STATUS_FAILED

          if (uploadedCreative.id) {
            this.$emit('creativeUploaded', uploadedCreative)
          }
        })
    },
    filesChange (fieldName, fileList) {
      if (!fileList.length) return

      // process ONE file only for now
      // formData.append('fileId', this.generateFileId())
      // formData.append('file', fileList[0], fileList[0].name)

      // append ALL files to FormData
      for (let i = 0; i < fileList.length; i++) {
        // handle file changes
        const formData = new FormData()

        if (this.extraUploadData) {
          this.extraUploadData.map(data => {
            formData.append(data.name, data.value)
          })
        }

        formData.append('fileId', this.generateFileId())
        formData.append('file', fileList[i], fileList[i].name)

        // save it
        this.save(formData)
      }
      this.$refs.fileBrowser.value = ''
    },
    generateFileId () {
      return 'creativeFile-' + Math.random().toString(36).substr(2, 16)
    }
  },
  mounted () {
    this.reset()
  }
}
</script>
<style scoped lang="stylus">
.file-wrapper
  position: relative
  cursor: pointer
  height: 44px

.input-file
  opacity: 0
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  z-index: 10
  cursor: pointer
</style>
