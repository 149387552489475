<template lang="pug">
div
  v-img(:src="img" width="142" min-height="94" @click="showLargeImg")
    template(v-slot:placeholder='')
      v-row.my-0(
        class="fill-height ma-0"
        align="center"
        justify="center"

      )
        v-progress-circular(indeterminate color="grey lighten-1")

  v-dialog(v-model='isDialogOpen')
    v-card(dark color='black')
      v-btn(icon fab dark color='white' @click='isDialogOpen = false' style='position:absolute; right:8px; top:8px; z-index:100;')
        v-icon(dark medium color='grey') mdi-close
      v-img(
        contain
        max-height='600'
        min-height="500"
        :src='largeImg'
        )
        template(v-slot:placeholder='')
          v-row.my-0(
            class="fill-height ma-0"
            align="center"
            justify="center"
          )
            v-progress-circular(indeterminate color="grey lighten-1")
</template>

<script>
import venuesApi from '@/services/venues.api'

export default {
  props: ['width', 'venueId'],
  data () {
    return {
      isDialogOpen: false,
      img: '',
      largeImg: ''
    }
  },
  created () {
    this.fetchImg(this.width).then(res => {
      this.img = res
    })
  },
  watch: {
    venueId () {
      this.img = ''
      this.largeImg = ''
      this.fetchImg(this.width).then(res => {
        this.img = res
      })
    }
  },
  methods: {
    fetchImg (width) {
      return venuesApi.getVenueImage(this.venueId, `${width}x${Math.floor(width / 1.5)}`)
    },
    showLargeImg () {
      this.isDialogOpen = true
      if (this.largeImg) return

      const w = Math.ceil(screen.width * 0.90)
      this.fetchImg(w).then(res => {
        this.largeImg = res
      })
    }
  }
}
</script>
