<template lang="pug">
  v-container(grid-list-lg fluid style='height:100%; background:linear-gradient(-45deg, #011866, #17d1a8);')
    vue-headful(:title="componentConfig.branding.title('Accept Invitation')")
    v-layout.align-center(fill-height)
      v-flex
        div.mx-auto.text-center(style='height: 32px;')
          v-avatar.elevation-0(size=60 color='white' style='z-index:10;')
            img(src='img/icons/logo-192x192.png' alt='avatar' style='padding: 4px 0 0 4px')
        v-card.elevation-16.secondary--text(light max-width='400' height='354' class='mx-auto')
          v-window
            v-window-item
              v-card-title.text-center.d-block
                .text-h6.mt-6 Welcome to Broadsign Ads

              v-container
                v-layout(row wrap)
                  v-flex.text-center(xs12)
                    v-form(ref='setPasswordFrom' @submit.prevent='acceptInvitation')
                      .text-body-1.mb-6.text-left Set your password to log in.
                      v-text-field(
                        v-model='newPassword'
                        :rules='[rules.required, rules.min]'
                        counter
                        type='password'
                        label='Password'
                        hint='At least 8 characters'
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append='showPassword = !showPassword'
                      )
                      div
                      v-btn.mt-6(
                        rounded
                        large
                        color='primary'
                        @click='acceptInvitation()'
                        :loading='loading'
                        :disabled='loading'
                        min-width='200'
                      ) Set password
</template>

<script>
import userApi from '@/services/user.api'
import userService from '@/services/user'
import componentConfigService from '@/services/componentConfig'
import { mapMutations } from 'vuex'

export default {
  created: function () {
    if (this.$store.getters['user/getProfile']) this.$router.replace({ name: 'Campaigns Overview' })
  },
  data () {
    return {
      loading: false,
      showPassword: false,
      newPassword: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  computed: {
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    ...mapMutations('snackbar', ['setSnackbar']),
    acceptInvitation () {
      if (this.$refs.setPasswordFrom && this.$refs.setPasswordFrom.validate()) {
        const { email, orgId, token } = this.$route.query

        this.loading = true

        const accepted = {
          email: email,
          organizationId: orgId,
          password: this.newPassword,
          token: token
        }

        userApi.acceptInvitation(accepted)
          .then(response => {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: 'Invitation successfully accepted'
            })
            this.login(email, this.newPassword)
          })
          .catch(error => {
            const snackbarObj = {
              msg: 'An error occured while processing your invitation',
              type: 'error'
            }
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length && error.response.data.errors[0].message) {
              snackbarObj.msg = error.response.data.errors[0].message

              if (error.response.data.errors[0].errorCode === 'InvitationAlreadyAccepted') {
                snackbarObj.timeout = 12000
                this.$router.replace({ name: 'login' })
              }
            }

            this.$store.commit('snackbar/setSnackbar', snackbarObj)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    login (username, password) {
      var payload = {
        username: username.toLowerCase(),
        password: password
      }

      this.$store.dispatch('user/login', payload)
        .then(profile => {
          this.setSnackbar({
            type: 'success',
            msg: `Successfully signed in user ${username}`
          })

          // send User infos to Hubspot
          userService.createNewHubspotContact({
            user: profile,
            orgInfo: this.$store.getters['user/getOrganization'],
            allowCommercialCommunication: false,
            routeQuery: this.$route.query
          }, document.cookie).finally(() => {
            // redirect
            var goTo = this.$route.query.redirect ? this.$route.query.redirect : '/'
            this.$router.push(goTo)
          })
        })
        .catch(res => {
          // Cognito returns different messages for "UserNotFoundException" and "NotAuthorizedException",
          // but we don't want to differentiate the 2 cases,
          // so return same message
          if (res.code === 'UserNotFoundException') {
            res.message = 'Incorrect username or password.'
          }

          this.setSnackbar({
            type: 'error',
            msg: res.message
          })
        })
    }
  }
}
</script>
