<template lang="pug">
  v-card(:tile='$vuetify.breakpoint.xsOnly')
    v-card-title(:class="{'mb-12': $vuetify.breakpoint.xsOnly}")
      .text-h5.text-capitalize {{ action }} credit card
    v-card-text
      v-form(ref='cardForm')
        v-container
          v-layout(row wrap)
            v-flex(sm12 v-if="action === 'add'")
              .stripe-container.pb-2.mb-3
                card(
                  class='stripe-card'
                  :stripe='stripeKey'
                  :options='{style: stripeStyling}'
                  @change='stripeErrorHandling'
                )
              .error--text.text-caption.mt-1(v-if='stripeErrorMsg') {{ stripeErrorMsg }}
            v-flex(xs12 md6 v-if="action === 'update'")
              v-text-field(
                prepend-inner-icon='mdi-credit-card'
                readonly
                v-model='cardNumber'
                :class="{'pr-2': $vuetify.breakpoint.mdAndUp}"
                )
            v-flex(xs6 md3 v-if="action === 'update'")
              v-text-field.pr-2(
                type='number'
                label='Expiry Month'
                placeholder='MM'
                min=1
                max=12
                :class="{'pl-2': $vuetify.breakpoint.mdAndUp}"
                v-model='expiryMonth'
                :rules='[rules.required, rules.validMonth]'
                )
            v-flex(xs6 md3 v-if="action === 'update'")
              v-text-field.pl-2(
                type='number'
                label='Expiry Year'
                placeholder='YY'
                v-model='expiryYear'
                :rules='[rules.required, rules.validYear]'
                @input='cardYearChanged'
                )
            v-flex(xs12 md6 v-if='showCountryAndState')
                v-select(
                  v-model='countryPicked'
                  label='Country'
                  return-object
                  :items='countriesAndStates'
                  item-text='name'
                  :rules='[rules.required]'
                  :class="{'pr-2': $vuetify.breakpoint.mdAndUp}"
                )
            v-flex(xs12 md6 v-if='showCountryAndState')
                v-select(
                  :disabled='!countryPicked'
                  v-model='statePicked'
                  label='State'
                  return-object
                  :items='availableStates'
                  item-text='name'
                  :rules='[rules.required]'
                  :class="{'pl-2': $vuetify.breakpoint.mdAndUp}"
                )
            v-flex.mt-4(xs12)
              v-img(:src="require('@/assets/powered_by_stripe.png')" width=119 height=26)
    v-card-actions
      v-spacer
      v-btn(text @click='closeDialog()') cancel
      v-btn(text color='primary' @click='processCard()' :loading='isBtnLoading' :disabled='disabledBtn') {{ action }} card

</template>

<script>
import geoService from '@/services/geo.service'
import billingService from '@/services/billing.service'
import tracking from '@/services/tracking'
import { Card, createToken } from 'vue-stripe-elements-plus'

import moment from 'moment'

export default {
  components: {
    Card
  },
  props: ['action', 'formControl', 'organization', 'card', 'cards', 'paymentMethods'],
  data () {
    return {
      isBtnLoading: false,
      rules: {
        required: value => !!value || 'Required.',
        validYear: val => val >= 2019 || 'Year cannot be in the past',
        validMonth: val => (val >= 1 && val <= 12) || 'Month cannot be in the past'
      },
      countriesAndStates: [],
      imgs: [
        // "powered_by_stripe.png",
        'visa-logo.png',
        'mastercard-logo.svg',
        'amex-logo.png'
      ],

      // Stripe related
      cardInfoCompleted: false,
      stripeErrorMsg: null,
      stripeStyling: {
        base: {
          color: '#32325d',
          fontFamily: 'Open Sans, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: 'error',
          iconColor: 'error'
        }
      },

      countryPicked: null,
      statePicked: null,
      expiryMonth: null,
      expiryYear: null,
      cardNumber: null

    }
  },
  created () {
    this.rules.validYear = this.validYear
    this.rules.validMonth = this.validMonth

    if (this.action === 'update' && this.card) {
      this.cardNumber = '**** **** **** ' + this.card.last4
      this.expiryMonth = this.card.exp_month
      this.expiryYear = this.card.exp_year
    }

    geoService.getCountriesAndStates().then(res => {
      this.countriesAndStates = res
    })
  },
  watch: {
    formControl: function () {
      if (this.formControl === false) {
        this.$refs.cardForm.reset()
        if (this.action === 'add') this.$refs.cardForm.$children[0].clear()
      }
      if (this.formControl === true && this.action === 'update' && this.card) {
        this.cardNumber = '**** **** **** ' + this.card.last4
        this.expiryMonth = this.card.exp_month
        this.expiryYear = this.card.exp_year
      }
    }
  },
  computed: {
    availableStates () {
      return this.countryPicked ? this.countryPicked.states : []
    },
    showCountryAndState () {
      return this.action === 'add' && !this.organization.address.state
    },
    disabledBtn () {
      const updateBool = this.action === 'update' && (this.expiryMonth === this.card.exp_month && this.expiryYear === this.card.exp_year)
      const addBool = this.action === 'add' && !this.cardInfoCompleted
      return updateBool || addBool
    },
    hasActiveStripePaymentMethod () {
      return this.paymentMethods.some(pm => ['stripe', 'stripe-manual'].indexOf(pm.paymentMethod.key) > -1)
    },
    stripeKey () {
      const keyName = 'VUE_APP_STRIPE_KEY'
      return process.env[keyName]
    }
  },
  methods: {
    stripeErrorHandling (event) {
      this.cardInfoCompleted = event.complete
      this.stripeErrorMsg = event.error ? event.error.message : null
    },

    closeDialog () {
      this.$emit('closeDialog')
      if (this.action === 'add') this.$refs.cardForm.$children[0].clear()
    },

    closeAndRefresh (action) {
      this.$emit('closeAndRefresh', action)
    },

    validYear (year) {
      return year >= moment().weekYear() || 'Year cannot be in the past'
    },

    validMonth (month) {
      const inRange = month >= 1 && month <= 12
      const currentYear = moment().weekYear()
      const currentMonth = moment().month() + 1

      var expiryYear = parseInt(this.expiryYear)

      return ((expiryYear > currentYear && inRange) || (expiryYear === currentYear && month > currentMonth && month <= 12)) || 'Invalid month'
    },

    cardYearChanged () {
      this.$refs.cardForm.validate()
    },

    processCard () {
      if (this.$refs.cardForm.validate()) {
        if (this.action === 'add' && this.organization) { this.addCardPaymentMethod() }
        if (this.action === 'update') { this.editPaymentMethod() }
      }
    },

    addCardPaymentMethod () {
      const organizationId = this.organization.id
      this.isBtnLoading = true

      if (this.showCountryAndState) {
        const data = {
          id: this.organization.id,
          address: {
            state: this.statePicked,
            country: this.countryPicked
          }
        }
        this.$store.dispatch('user/editOrganization', data)
      }

      createToken()
        .then(result => {
          if (result.error) {
            const msg = 'error'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          }

          const { token } = result

          // add credit card in Stripe
          return billingService.addCard(token.id)
            .then(cardResp => {
              console.log(cardResp)
              if (!cardResp) return

              // handle addCard() errors
              if (cardResp.type && cardResp.statusCode) {
                const msg = cardResp.message
                this.$store.commit('snackbar/setSnackbar', {
                  type: 'error',
                  msg: `${msg}`
                })
              }

              var card = cardResp.data

              // success
              if (card.customer && card.id && !this.hasActiveStripePaymentMethod) {
                // add payment method in API upon success
                return billingService.addPaymentMethod('stripe', organizationId, card.customer)
                  .then(paymentMethodResp => {
                    // handle addPaymentMethod() errors
                    if (paymentMethodResp.errors) {
                      const msg = paymentMethodResp.errors[0].message
                      this.$store.commit('snackbar/setSnackbar', {
                        type: 'error',
                        msg: `${msg}`
                      })
                    } else {
                      this.successSnackbar()
                    }
                  })
              } else {
                this.successSnackbar()
              }
            }).catch(err => {
              this.isBtnLoading = false

              var message = 'Something went wrong while adding your card. Please try again.'

              if (err.response.data.message) {
                message = err.response.data.message
              }

              this.$store.commit('snackbar/setSnackbar', {
                type: 'error',
                msg: `${message}`
              })
            })
        })
    },

    editPaymentMethod () {
      this.isBtnLoading = true
      const cardToAdd = { ...this.card }
      cardToAdd.exp_month = String(this.expiryMonth)
      cardToAdd.exp_year = String(this.expiryYear)

      billingService.editCard(cardToAdd)
        .then(res => {
          const msg = 'Credit card successfully updated'
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: `${msg}`
          })
          this.isBtnLoading = false
          this.closeAndRefresh('update')
        })
        .catch(err => {
          this.isBtnLoading = false
          err = err.response.data.errors[0]
          var message
          if (typeof err === 'object') {
            message = err.message
          }
          if (typeof err === 'string') {
            message = err
          }
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${message}`
          })
        })
    },

    successSnackbar () {
      const msg = 'Credit card successfully added'
      this.$store.commit('snackbar/setSnackbar', {
        type: 'success',
        msg: `${msg}`
      })
      this.isBtnLoading = false
      tracking.sendEvent(['ga'], 'addedCreditCard')
      this.closeAndRefresh('add')
    }
  }
}
</script>

<style scoped>
  .stripe-container {
    border-bottom: 1px solid rgba(0,0,0,0.42);
  }
  .stripe-container:hover {
    border-bottom: 1px solid rgba(0,0,0,0.87);
  }

</style>
