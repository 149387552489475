import reportsApi from '@/services/reports.api'

function getTotals (options, authToken) {
  return reportsApi.liveQueries(options, authToken)
}

function getChartData (options, authToken) {
  return reportsApi.liveQueries(options, authToken)
}

function getTableData (options, authToken) {
  return reportsApi.liveQueries(options, authToken)
}

function getSparklinesData (options, authToken) {
  return reportsApi.liveQueries(options, authToken)
}

export default {
  getTotals,
  getChartData,
  getTableData,
  getSparklinesData
}
