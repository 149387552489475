import axios from 'axios'
import moment from 'moment'

import userApi from '@/services/user.api'
import campaignsApi from '@/services/campaigns.api'

var { VUE_APP_CAMPSITE_API_URL, VUE_APP_CLIENTUTILS_URL } = process.env

const INTEREST_RATE_ANNUM = 0.18
const DAYSINYEAR = moment().isLeapYear() ? 366 : 365

export default {
  payInvoice,
  makePayment,
  setInvoicePayment,
  addCard,
  editCard,
  removeCard,
  setPrimaryCard,
  getPaymentTriggers,
  getPaymentTerms,
  getPaymentMethods,
  getActivePaymentMethods,
  addPaymentMethod,
  removePaymentMethod,
  getStripeCards,
  getInvoices,
  getInvoicesTotalCount,
  getInvoice,
  getPendingInvoiceItems,
  downloadPDF,
  getInvoicesCsv
}

function payInvoice (invoice, cardId, token) {
  const url = VUE_APP_CLIENTUTILS_URL + '/charges'
  return axios.post(url, { invoice, cardId, token })
}

function makePayment () {
  const url = VUE_APP_CLIENTUTILS_URL + '/payments'
  return axios.post(url)
}

function setInvoicePayment (invoice, refNumber) {
  var url = VUE_APP_CAMPSITE_API_URL + '/invoices/' + invoice.id + '/payments'

  var payment = {
    key: 'manual',
    refNumber: refNumber,
    paymentdate: moment().format() // "2017-11-30 12:00:00-05:00"
  }

  return axios.post(url, payment)
}

function getInvoice (invoiceId) {
  let invoice

  return getInvoiceAPI(invoiceId)
    .then(invoiceRes => {
      invoice = invoiceRes

      // Interests Invoice?
      var interestsInvoiceItems = invoice.invoiceItems.filter(invoiceItem => invoiceItem.feeType === 'Late')
      invoice.isInterestsInvoice = interestsInvoiceItems.length > 0
      if (invoice.isInterestsInvoice) {
        // get Invoices on which interests were applied
        const promises = interestsInvoiceItems.map(invoiceItem => getInvoice(invoiceItem.appliedToInvoiceId))
        return Promise.all(promises)
          .then(appliedToInvoicesRes => {
            appliedToInvoicesRes.forEach(appliedToInvoice => {
              // NOTE: the use of appliedToInvoice[x] instead of appliedToInvoice.x
              // https://stackoverflow.com/questions/38324949/error-ts2339-property-x-does-not-exist-on-type-y
              const appliedToInvoiceItem = invoice.invoiceItems.find(invoiceItem => invoiceItem.appliedToInvoiceId === appliedToInvoice.id)

              appliedToInvoiceItem.appliedToInvoiceAmount = appliedToInvoice.amountExcludingTaxes
              appliedToInvoiceItem.appliedToInvoiceNumber = appliedToInvoice.invoiceNumber

              const daysInMonth = moment(invoice.startDate, 'YYYY-MM').daysInMonth()
              appliedToInvoiceItem.daysInMonth = daysInMonth
              appliedToInvoiceItem.appliedToInvoiceRate = INTEREST_RATE_ANNUM * daysInMonth / DAYSINYEAR * 100
            })

            return invoice
          })
      } else {
        return getInvoiceItems(invoiceId)
          .then(invoiceItems => {
            const orders = []

            // GROUP items by "order"
            if (invoiceItems) {
              invoiceItems.forEach(item => {
                let order

                order = orders.find(o => o.id === item.order.id)
                if (!order) {
                  order = Object.assign({}, item.order, { lines: [] })
                  orders.push(order)
                }

                const line = {
                  id: item.line.id,
                  name: item.line.name,
                  exchange: {
                    name: item.exchange.name
                  },
                  amount: item.amount,
                  amountBeforeCredit: item.amountBeforeCredit,
                  quantity: item.quantity,
                  mediaCost: item.mediaCost || 0,
                  fees: item.fees.length ? item.fees.map(f => f.amount).reduce((a, b) => a + b) : 0
                }

                order.lines.push(line)
              })
            }

            // SUM each Line's metrics, for each Invoice's "orders"
            invoice.orders = orders.map(o => Object.assign(o, {
              amount: o.lines.map(l => l.amount).reduce((a, b) => a + b),
              amountBeforeCredit: o.lines.map(l => l.amountBeforeCredit).reduce((a, b) => a + b),
              quantity: o.lines.map(l => l.quantity).reduce((a, b) => a + b),
              mediaCost: o.lines.map(l => l.mediaCost).reduce((a, b) => a + b),
              fees: o.lines.map(l => l.fees).reduce((a, b) => a + b)
            }))

            // Invoice metrics
            invoice.credits = invoice.amountBeforeCredit - invoice.amountExcludingTaxes
            invoice.totalMediaCost = invoice.orders.map(o => o.mediaCost).reduce((a, b) => a + b)
            invoice.totalFees = invoice.orders.map(o => o.fees).reduce((a, b) => a + b)

            return completeInvoiceData(invoice)
          })
      }
    })
}

function getPaymentMethodLabel (paymentMethodKey, paidRefNumber) {
  switch (paymentMethodKey) {
    case 'stripe':
    case 'stripe-manual':
      try {
        const source = JSON.parse(paidRefNumber)
        return source.name
      } catch (err) {
        return 'Unknown'
      }
    case 'manual':
      return 'Manual Payment'
    default:
      return 'Unknown'
  }
}

function getInvoiceAPI (invoiceId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/invoices?$filter=id eq ${invoiceId}`
  return axios.get(url).then(res => (res.data[0] || null))
}

function getInvoiceItems (invoiceId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/invoices/${invoiceId}/items`

  return axios.get(url).then(res => res.data)
}

function getPaymentTriggers (organizationId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${organizationId}/paymenttriggers`

  return axios.get(url)
    .then(res => {
      if (res.data.Message) {
        return []
      } else {
        return res.data
          .map(item => item.paymentTrigger)
          .map(paymentTrigger => {
            paymentTrigger.value = JSON.parse(paymentTrigger.value)
            return paymentTrigger
          })
      }
    })
}

function getPaymentTerms () {
  const url = VUE_APP_CAMPSITE_API_URL + '/paymentTerms'
  return axios.get(url).then(res => res.data)
}

function addCard (token) {
  const url = `${VUE_APP_CLIENTUTILS_URL}/cards`
  return axios.post(url, { token })
}

function editCard (card) {
  const url = `${VUE_APP_CLIENTUTILS_URL}/cards/${card.id}`

  return axios.patch(url, card).then(res => res.data)
}

function removeCard (card) {
  const url = `${VUE_APP_CLIENTUTILS_URL}/cards/${card.id}`

  return axios.delete(url).then(res => res.data)
}

function setPrimaryCard (card) {
  const url = `${VUE_APP_CLIENTUTILS_URL}/cards/${card.id}/primary`

  return axios.put(url).then(res => res.data.map(mapImageToCreditCard))
}

function getPaymentMethods (organizationId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${organizationId}/paymentmethods`
  return axios.get(url).then(res => res.data)
}

function getActivePaymentMethods (organizationId) {
  const url = `${VUE_APP_CAMPSITE_API_URL}/organizations/${organizationId}/paymentmethods`
  return axios.get(url).then(res => res.data.filter(pm => pm.status ? pm.status === 'Active' : true))
}

function addPaymentMethod (method, organizationId, refId = null) {
  const url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/paymentmethods'

  // validate that payment method is supported
  // const supportedMethods = ['stripe']
  // if (supportedMethods.indexOf(method) === -1) {
  //   return Promise.reject(new Error('Invalid payment method.'))
  // }

  let body = {}
  if (method === 'stripe') {
    body = {
      organizationReferenceNumber: refId,
      paymentMethod: {
        id: 1,
        key: 'stripe'
      }
    }
  } else {
    body = {
      paymentMethod: {
        id: method === 'manual' ? 2 : 4,
        key: method
      }
    }
  }
  return axios.post(url, body).then(res => res.data)
}

function removePaymentMethod (method, organizationId) {
  // validate that payment method is supported
  const supportedMethods = ['stripe']
  if (supportedMethods.indexOf(method) === -1) {
    return Promise.reject(new Error('Invalid payment method.'))
  }

  const url = VUE_APP_CAMPSITE_API_URL + '/organizations/' + organizationId + '/paymentmethods/' + 1

  return axios.delete(url).then(res => res.data)
}

function getStripeCards () {
  const url = `${VUE_APP_CLIENTUTILS_URL}/cards`
  return axios.get(url)
    .then(res => res.data.map(mapImageToCreditCard))
    .catch(() => [])
}

function mapImageToCreditCard (card) {
  card.exp_month = String(card.exp_month)
  card.exp_year = String(card.exp_year)
  // card.img = {
  //   'Visa': 'https://s3.amazonaws.com/campsite-assets/img/png/Visa-icon.png',
  //   'MasterCard': 'https://s3.amazonaws.com/campsite-assets/img/png/Master-Card-icon.png'
  // }[card.brand]
  return card
}

function buildInvoicesUrl (params, isHead = false, isCsv = false) {
  var url = VUE_APP_CAMPSITE_API_URL + '/invoices'
  if (isCsv) url += '/export/'

  let filters; let take; let sort; let skip = null

  if (!isHead) {
    if (params.take) { take = 'take=' + params.take }
    if (params.skip) { skip = '$skip=' + params.skip }
  }

  var filtersArr = params.filters ? params.filters : []

  if (params.organizationId) {
    filtersArr.push('organization/id eq ' + params.organizationId)
  }

  if (isCsv) {
    filtersArr = filtersArr.map(x => x
      .replace('paymentFailures/any(f: f/paymentMethod', 'paymentFailures|any(f: f|paymentMethod')
      .replace(/\//g, '_')
      .replace('paymentFailures|any(f: f|paymentMethod', 'paymentFailures/any(f: f/paymentMethod')
    )
  }
  if (filtersArr.length) {
    filters = '$filter=' + filtersArr.join(' and ')
  }

  if (params.sort) { sort = '$orderby=' + params.sort }

  url += '?' + [filters, take, sort, skip].filter(x => !!x).join('&')
  return url
}

function getInvoices (params) {
  const url = buildInvoicesUrl(params)
  return axios.get(url)
    .then(res => {
      return res.data.map(completeInvoiceData)
    })
}

function getInvoicesCsv (params) {
  if (params.sort) params.sort = params.sort.replace(/\//g, '_')

  const url = buildInvoicesUrl(params, false, true)
  const config = {
    Accept: 'text/csv',
    'Content-Type': 'application/json'
  }

  return axios({ method: 'get', url: url, headers: config })
    .then(res => res.data)
}

function getInvoicesTotalCount (params) {
  const url = buildInvoicesUrl(params, true)
  return axios.head(url).then(response => parseInt(response.headers['x-count']) || 0)
}

function completeInvoiceData (invoice) {
  // FLAG (manual) invoice if "due date" is reached
  if (invoice.paymentMethod && invoice.paymentMethod.key === 'manual') {
    if (invoice.dueDate) {
      var dueMoment = moment(invoice.dueDate)

      invoice.dueDateReached = dueMoment.isBefore(moment())
      invoice.dueDateFormatted = dueMoment.format()
    }

    if (invoice.status === 'Resolved') {
      invoice.paymentMethodLabel = 'Manual Payment'
    }
  }

  if (invoice.status === 'Paid') {
    if (invoice.paidPaymentMethod) {
      invoice.paymentMethodLabel = getPaymentMethodLabel(
        invoice.paidPaymentMethod.key, invoice.paidRefNumber
      )
    } else {
      // paid in full by credit
      invoice.paymentMethodLabel = 'Free Credit'
    }
  }

  if (invoice.status === 'Failed') {
    if (invoice.paymentFailures.length) {
      const { paymentMethod, occuredOn, refNumber } = invoice.paymentFailures[0]

      // TO VALIDATE : a failed charge
      invoice.paidOn = occuredOn

      invoice.paymentMethodLabel = getPaymentMethodLabel(
        paymentMethod, refNumber
      )
    } else {
      invoice.paymentMethodLabel = 'N/A'
    }
  }

  if (invoice.status === 'Pending') {
    invoice.paymentMethodLabel = 'N/A'
  }

  return invoice
}

function getPendingInvoiceItems () {
  const url = `${VUE_APP_CAMPSITE_API_URL}/invoices/pendingitems`
  return axios.get(url).then(res => res.data)
}

function downloadPDF (transaction) {
  const url = `${VUE_APP_CLIENTUTILS_URL}/pdf/invoice`
  const body = {
    transaction,
    // define properties that will be overwritten below to avoid Typescript error
    organization: {
      name: '',
      billingAddress: {
        name: ''
      }
    }
  }

  // Interests Invoices don't have "orders" attached to them
  if (!transaction.orders) { transaction.orders = [] }

  const promises = transaction.orders.map(o => campaignsApi.getCampaignById(o.id))
  return Promise.all(promises)
    .then(res => {
      res.forEach(order => {
        // NOTE: the use of appliedToInvoice[x] instead of appliedToInvoice.x
        // https://stackoverflow.com/questions/38324949/error-ts2339-property-x-does-not-exist-on-type-y
        const transactionOrder = transaction.orders.find(o => o.id === order.id)
        transactionOrder.accountId = order.accountId
      })
      const promises = transaction.orders.map(o => userApi.getAccountById(o.accountId))
      return Promise.all(promises)
    })
    .then(res => {
      transaction.orders.forEach(order => {
        // NOTE: the use of appliedToInvoice[x] instead of appliedToInvoice.x
        // https://stackoverflow.com/questions/38324949/error-ts2339-property-x-does-not-exist-on-type-y
        const account = res.find(a => a.id === order.accountId)
        order.advertiserName = account.name
      })
      return userApi.getOrganization(transaction.organization.id)
    })
    .then(res => {
      body.organization = res

      // billingAddress can be manually overwritten at the Invoice level,
      // so keep this one in the Organization details
      body.organization.billingAddress = transaction.billingAddress

      // Overwrite "Name" from "billingAddress" when available
      if (body.organization.billingAddress.name) {
        body.organization.name = body.organization.billingAddress.name
      }

      return axios.post(url, body, { responseType: 'arraybuffer' })
    })
    .then(res => {
      const file = new Blob([res.data], { type: 'application/pdf' })
      const url = URL.createObjectURL(file)
      const filename = 'campsite-invoice_' + transaction.id + '.pdf'

      // set optimal download method
      if (navigator.msSaveBlob) {
        // IE11+ : (has Blob, but not a[download])
        navigator.msSaveBlob(file, filename)
      } else if (navigator.msSaveOrOpenBlob) {
        // IE10+ : (has Blob, but not a[download])
        navigator.msSaveOrOpenBlob(file, filename)
      } else {
        // A-download
        const a = document.createElement('a')

        a.setAttribute('href', url)
        a.setAttribute('download', filename)

        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    })
    .catch(err => {
      console.error(err)
    })
}
