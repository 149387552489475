<template lang='pug'>
  .dayparting
    v-radio-group.mt-0.dayparting-option-group(v-model='daypartingOption' @change='bundleAndUpdate' hide-details)
      v-radio.dayparting-option(label='Run ads all the time' value='always' color='primary')
      v-radio.dayparting-option(label='Run ads on a schedule' value='schedule' color='primary')
    v-card.d-block.mt-3(v-if="daypartingOption === 'schedule'" color='grey lighten-4')
      v-card-text
        v-layout
          v-flex
            div
              v-checkbox.weekpart-option.mt-0(label='Monday' value='d-1' hide-details color='primary' height='16' v-model='weekpart' @change='weekpartChanged')
              v-checkbox.weekpart-option(label='Tuesday' value='d-2' hide-details color='primary' height='16' v-model='weekpart' @change='weekpartChanged')
              v-checkbox.weekpart-option(label='Wednesday' value='d-3' hide-details color='primary' height='16' v-model='weekpart' @change='weekpartChanged')
              v-checkbox.weekpart-option(label='Thursday' value='d-4' hide-details color='primary' height='16' v-model='weekpart' @change='weekpartChanged')
              v-checkbox.weekpart-option(label='Friday' value='d-5' hide-details color='primary' height='16' v-model='weekpart' @change='weekpartChanged')
              v-checkbox.weekpart-option(label='Saturday' value='d-6' hide-details color='primary' height='16' v-model='weekpart' @change='weekpartChanged')
              v-checkbox.weekpart-option(label='Sunday' value='d-0' hide-details color='primary' height='16' v-model='weekpart' @change='weekpartChanged')
          v-flex
            time-picker(ref="start-time-picker" label='Start Time' @timeSelected='startTimeSelected' :default-value='startTime')
            time-picker(ref="end-time-picker" label='End Time' @timeSelected='endTimeSelected' :default-value='endTime')
</template>
<script>
import timePicker from '@/components/timePicker.vue'

export default {
  name: 'Dayparting',
  components: {
    timePicker
  },
  props: [
    'reinit', // special flag to trigger the init() function that resets the "initial values"
    'initialDaypartValue', // ['0', '1', ..., '23']
    'initialWeekpartValue' // ['d-0', 'd-1', 'd-2', 'd-3', 'd-4', 'd-5', 'd-6']
  ],
  data: function () {
    return {
      // options
      weekpartOptions: [
        { value: 'd-0', label: 'Sunday' },
        { value: 'd-1', label: 'Monday' },
        { value: 'd-2', label: 'Tuesday' },
        { value: 'd-3', label: 'Wednesday' },
        { value: 'd-4', label: 'Thursday' },
        { value: 'd-5', label: 'Friday' },
        { value: 'd-6', label: 'Saturday' }
      ],
      daypartOptions: [
        { value: '0', label: '12 AM' },
        { value: '1', label: '1 AM' },
        { value: '2', label: '2 AM' },
        { value: '3', label: '3 AM' },
        { value: '4', label: '4 AM' },
        { value: '5', label: '5 AM' },
        { value: '6', label: '6 AM' },
        { value: '7', label: '7 AM' },
        { value: '8', label: '8 AM' },
        { value: '9', label: '9 AM' },
        { value: '10', label: '10 AM' },
        { value: '11', label: '11 AM' },
        { value: '12', label: '12 PM' },
        { value: '13', label: '1 PM' },
        { value: '14', label: '2 PM' },
        { value: '15', label: '3 PM' },
        { value: '16', label: '4 PM' },
        { value: '17', label: '5 PM' },
        { value: '18', label: '6 PM' },
        { value: '19', label: '7 PM' },
        { value: '20', label: '8 PM' },
        { value: '21', label: '9 PM' },
        { value: '22', label: '10 PM' },
        { value: '23', label: '11 PM' }
      ],
      // models
      daypartingOption: 'always',
      startTime: '12 AM',
      endTime: '12 AM',
      daypart: [],
      weekpart: [],
      initializing: true
    }
  },
  created: function () {
    this.init()
  },
  watch: {
    initialDaypartValue: function (newVal, oldVal) {
      this.init()
    },
    initialWeekpartValue: function (newVal, oldVal) {
      this.init()
    },
    reinit: function (newVal, oldVal) {
      if (newVal && !this.initializing) {
        this.init()
      }
    }
  },
  computed: {
    daypartDefaultValue: function () {
      return this.daypartOptions.map(o => o.value)
    },
    weekpartDefaultValue: function () {
      return this.weekpartOptions.map(o => o.value)
    }
  },
  methods: {
    init () {
      this.initializing = true

      // open widget if any initial values
      this.daypartingOption = this.initialDaypartValue || this.initialWeekpartValue ? 'schedule' : 'always'

      // set timePicker models from initial array of values
      if (this.initialDaypartValue) {
        // make a local copy so we can sort it...
        var initialDaypartValue = JSON.parse(JSON.stringify(this.initialDaypartValue))

        // ...first by ascending order [0, ..., 23]
        initialDaypartValue.sort((a, b) => parseInt(a) - parseInt(b))

        // ...then "continuously", i.e.
        //    ex "10pm to 3am"
        //    ascending [0, 1, 2, 22, 23]
        //    continuously [22, 23, 0, 1, 2]
        var orderedHours = []
        for (let i = 0; i < initialDaypartValue.length; i++) {
          if (parseInt(initialDaypartValue[i]) + 1 !== parseInt(initialDaypartValue[i + 1])) {
            orderedHours = [...initialDaypartValue.splice(i + 1), ...initialDaypartValue.slice(0, i + 1)]
            break
          }
        }

        // start
        var startValue = orderedHours[0]
        var daypartStartOption = this.daypartOptions.find(v => v.value === startValue)
        this.startTime = daypartStartOption.label

        // end
        var endValue = orderedHours[orderedHours.length - 1]
        var daypartEndIndex
        if (endValue === '23') {
          daypartEndIndex = -1
        } else {
          daypartEndIndex = this.daypartOptions.findIndex(v => v.value === endValue)
        }

        this.endTime = this.daypartOptions[daypartEndIndex + 1].label
      } else {
        this.startTime = this.daypartOptions[0].label
        this.endTime = this.daypartOptions[0].label
      }

      this.daypart = this.initialDaypartValue ? this.initialDaypartValue : this.daypartDefaultValue
      this.weekpart = this.initialWeekpartValue ? this.initialWeekpartValue : this.weekpartDefaultValue

      this.initializing = false
    },
    startTimeSelected (t) {
      this.startTime = t
      this.bundleAndUpdate()
    },
    endTimeSelected (t) {
      this.endTime = t
      this.bundleAndUpdate()
    },
    weekpartChanged (weekpart) {
      this.bundleAndUpdate()
    },
    bundleAndUpdate () {
      var daypartStartIndex = this.daypartOptions.findIndex(v => v.label === this.startTime)
      var daypartEndIndex = this.daypartOptions.findIndex(v => v.label === this.endTime)

      if (daypartEndIndex === 0) { daypartEndIndex = 24 }

      // (!) picking only one "hour" (ex: 12am - 12am) is the only way to "remove" daypart
      //     so, reset to all values
      if (daypartStartIndex === daypartEndIndex) {
        this.daypart = this.daypartDefaultValue
      } else {
        if (daypartStartIndex < daypartEndIndex) {
          this.daypart = this.daypartDefaultValue.slice(daypartStartIndex, daypartEndIndex)
        } else {
          var a = this.daypartDefaultValue.slice(daypartStartIndex)
          var b = this.daypartDefaultValue.slice(0, daypartEndIndex)
          this.daypart = a.concat(b)
        }
      }

      this.$emit('onUpdate', {
        option: this.daypartingOption,
        daypart: this.daypartingOption === 'always' ? [] : this.daypart,
        weekpart: this.daypartingOption === 'always' ? [] : this.weekpart
      })
    }
  }
}
</script>
