<template lang="pug">
v-expansion-panels(v-if="venue && venue.numberOfScreens" flat dense v-model="openPanel")
  v-expansion-panel
    v-expansion-panel-header.v-btn.v-size--small.primary--text.pl-1.details-title(
      style="margin-left:inherit; min-height:inherit;"
      @click="getScreensDetails"
      )
      div.d-flex.align-center
        v-icon.mr-1(small color='primary') mdi-television
        | {{ `${venue.numberOfScreens > 1 ? venue.numberOfScreens + ' Screens' : 'Screen'} Details` }}
      template(v-slot:actions='')

    v-expansion-panel-content
      v-simple-table.mb-2.loading(v-if="loading" dense)
        template(v-slot:default='')
          tbody
            tr(v-for="i in 11" :key="i")
              td.pl-1(style="width:170px;")
                v-skeleton-loader(type='table-cell@1' height=32)
              td.pl-0
                v-skeleton-loader(type='table-cell@1' height=32)

      div(v-else)
        .unavailable-details.pt-1.pl-3.secondary--text(v-if="areDetailsUnavailable") Screens details are not available
        div(v-else v-for="(screen, i) in formattedScreens" :key="i")
          v-simple-table.screen(dense)
            template(v-slot:default='')
              tbody
                tr(v-for="row in screen")
                  td.pl-1(style="width:170px;")
                    strong.detail-label {{ row.key }}
                  td.pl-0.detail-value {{ row.value }}
          v-divider.my-4(v-if="i !== formattedScreens.length - 1")
</template>

<script>
import venueApi from '@/services/venues.api'
import helpers from '@/services/helpers.service'

export default {
  components: {

  },
  props: ['venue'],
  data () {
    return {
      loading: true,
      screens: [],
      openPanel: null,
      areDetailsUnavailable: false
    }
  },
  watch: {
    venue () {
      this.openPanel = null
      this.screens = []
    }
  },
  computed: {
    formattedScreens () {
      return this.screens.map(screen => {
        const details = [
          { key: 'Publisher', value: screen.supplier },
          { key: 'Screen Name', value: screen.name },
          { key: 'Device ID', value: screen.deviceId },
          { key: 'Category', value: 'N/A' },
          { key: 'Lat / Long', value: '' },
          { key: 'Screen Dimensions', value: 'N/A' },
          { key: 'Diagonal Length', value: 'N/A' },
          { key: 'Creative Resolution', value: `${screen.creativeResolution.width} x ${screen.creativeResolution.height}` },
          {
            key: 'Weekly Available Imps.',
            value: screen.weeklyTraffic
              ? helpers.numberWithCommasFormatting(screen.weeklyTraffic)
              : 'N/A'
          },
          {
            key: 'Measurement Partner',
            value: screen.trafficSources && screen.trafficSources.length && screen.trafficSources[0]
              ? screen.trafficSources.join(', ')
              : 'N/A'
          }
        ]

        if (screen.locationTypes && screen.locationTypes.length) {
          details[3].value = screen.locationTypes[0].key
        }

        if (screen.longitude && screen.latitude) {
          details[4].value = `${screen.latitude.toFixed(6)} / ${screen.longitude.toFixed(6)}`
        }
        if (screen.screenDimensions.diagonalInInches > 0) {
          details[5].value = `${this.formatLength(screen.screenDimensions.widthInInches)} x ${this.formatLength(screen.screenDimensions.heightInInches)}`
          details[6].value = this.formatLength(screen.screenDimensions.diagonalInInches)
        }

        return details
      })
    }
  },
  methods: {
    getScreensDetails () {
      if (this.screens.length) return
      this.loading = true
      venueApi.getScreens(this.venue.faces.map(f => f.id)).then(res => {
        this.screens = res
        this.loading = false
        this.areDetailsUnavailable = !res.length
      })
    },
    formatLength (inches) {
      return inches > 100 ? (inches / 12).toFixed(0) + "'" : inches.toFixed(0) + '"'
    }
  }
}
</script>

<style scoped>
>>> .v-skeleton-loader__table-cell {
  height: 32px;
}
>>> .v-expansion-panel-content__wrap {
  padding: 0px;
}
.detail-value {
  word-break: break-word;
}
</style>
