<template lang="pug">
v-card-actions.mt-2.pa-0
  v-layout(align-end row wrap fill-height)
    v-flex.grey--text(xs12 sm12)
      p.text-caption(v-if='showCroppingInfo')
        span#cropping-disclaimer(v-if='componentConfig.creatives.isCroppingDisclaimerShown')
          | Creatives can be slightly cropped to accommodate suppliers’ screen specifications.&nbsp;
        span
          | We suggest to avoid positioning logos and text close to the edges. For best results, leave a 5% margin on each side.
          a#creative-specs-link.info--text(v-if='componentConfig.links.creativeSpecs' :href='componentConfig.links.creativeSpecs' target='_blank' style='text-decoration:none;')  See the complete creative specs

      p.text-caption(v-if='componentConfig.links.platformPolicy')
        span
          | Please review our
          a#platform-policy-link.info--text(:href='componentConfig.links.platformPolicy' target='_blank' style='text-decoration:none;')  platform policy
          |  before submitting
</template>

<script>
import componentConfigService from '@/services/componentConfig'

export default {
  computed: {
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  props: {
    showCroppingInfo: {
      type: Boolean,
      default: () => true
    }
  }
}
</script>
