import axios from 'axios'
import geoApi from '@/services/geo.api'
import { datadogLogs } from '@datadog/browser-logs'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  search,
  batchSearch,
  geoFromApiFormatting,
  radiusFormatting,
  geoTargetsToApiFormatting,
  fetchPois,
  fetchPoiGeoJSON,
  getCountriesAndStates,
  reconstructGeography,
  resolveGeoJson,
  resolveGeoJsonForGeoTarget,
  getGeometryFromGeoJsonFile,
  removeItemFromGeotargets,
  removeGeoJSON,
  addUniqueAddressId,
  setGeoJsonFromGeoJsonFileUrl,

  convertDistanceToLabel,
  getKmValueOfDistanceLabel,

  getCoors
}

function search (q, nbr = 10, locationType = null, exchange = null) {
  var qstr = encodeURIComponent(q)
  let finalUrl = VUE_APP_CAMPSITE_API_URL + '/locations?search=' + qstr + '&take=' + nbr
  if (locationType) {
    finalUrl += '&locationType=' + locationType
  }
  if (exchange) {
    finalUrl += '&exchange=' + exchange
  }

  const cancelTokenSource = axios.CancelToken.source()
  return {
    response: axios.get(finalUrl, { cancelToken: cancelTokenSource.token })
      .then(response => response.data.filter(d => d !== null)),
    cancel () {
      cancelTokenSource.cancel(
        'A newer request has been sent to this endpoint.'
      )
    }
  }
}

function batchSearch (queries, locationType = null, exchangeKey = null) {
  const url = VUE_APP_CAMPSITE_API_URL + '/locations'

  const locations = {}
  queries.forEach(q => {
    locations[q.id] = q.query
  })

  const data = {
    locations,
    locationType,
    exchangeKey
  }

  return axios.post(url, data).then(response => response.data)
}

async function fetchPois () {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/pointsofinterest').then(res => res.data)
}

function fetchPoiGeoJSON (poiId, radius, city) {
  if (!poiId || !radius || !city) {
    // should return empty format that prevent maps from failing
    console.log('Required arg(s) missing')
  }

  const endpoint = VUE_APP_CAMPSITE_API_URL + '/pointsofinterest/' + poiId + '/locations?city=' + city + '&radius=' + radius

  return axios.get(endpoint)
    .then(response => JSON.parse(response.data.geoJSON))
    .catch(error => {
      // should return empty format that prevent maps from failing
      console.log('XHR Failed for search()', error)
    })
}

async function resolveGeoJson (geographyId, geoJSONFileUrl, cancelToken = null) {
  try {
    if (geoJSONFileUrl) {
      return await geoApi.fetchGeoJSONFromURL(geoJSONFileUrl, cancelToken)
    } else if (geographyId) {
      return await geoApi.fetchGeoJSON(geographyId, cancelToken)
    }
  } catch (error) {
    datadogLogs.logger.error(`Error fetching GeoJSON from URL or ID: ${geoJSONFileUrl ?? geographyId}`)
  }
  return null
}

async function resolveGeoJsonForGeoTarget (geoTarget) {
  const geoTargetCopy = JSON.parse(JSON.stringify(geoTarget))
  if (geoTargetCopy.geography) geoTargetCopy.geography.geoJSON = !geoTargetCopy?.geoJSON && geoTargetCopy?.geoJSONFileUrl ? await resolveGeoJson(null, geoTargetCopy.geoJSONFileUrl) : geoTargetCopy.geoJSON
  return geoTargetCopy
}

function getGeometryFromGeoJsonFile (jsonString) {
  const jsonObject = JSON.parse(jsonString)
  if (jsonObject.geometry) {
    return JSON.stringify(jsonObject.geometry)
  }
  const values = Object.values(jsonObject)
  if (values && values.length > 0) {
    const value = values[0]
    if (value.GeoJson) return value.GeoJson
  }
  return jsonString
}

async function setGeoJsonFromGeoJsonFileUrl (geography) {
  if (geography.filters && geography.filters.length) {
    await Promise.all(
      geography.filters.map(async (g) => {
        if (g.geoJSONFileUrl != null) {
          var geoJsonFile = await resolveGeoJson(null, g.geoJSONFileUrl)
          g.geoJSON = getGeometryFromGeoJsonFile(geoJsonFile)
        }
      })
    )
  }

  if (geography.groups) {
    await geography.groups.map(async (group) => {
      await setGeoJsonFromGeoJsonFileUrl(group)
    })
  }
}

function geoTargetsToApiFormatting (list) {
  // Pre-formatting
  const listCopy = []
  list.forEach(element => {
    listCopy.push(JSON.parse(JSON.stringify(element)))
  })

  if (listCopy && listCopy.length > 0) {
    listCopy.forEach(geoTarget => {
      if (geoTarget.radius) {
        geoTarget.radiusInKm = radiusFormatting(geoTarget.radius, 'toNumber')
        delete geoTarget.radius
      }
      if (geoTarget.isIncluded === true) {
        geoTarget.selectionRule = 'include'
        delete geoTarget.isIncluded
      }
      if (geoTarget.isIncluded === false) {
        geoTarget.selectionRule = 'exclude'
        delete geoTarget.isIncluded
      }
    })
  }
  // First is most inclusive (lowest priority)
  const priorities = [
    { layer: 'country', operation: 'or' },
    { layer: 'state', operation: 'or' },
    { layer: 'city', operation: 'or' },
    { layer: 'postal_code', operation: 'or' },
    { layer: 'address', operation: 'or' },
    { layer: 'venue', operation: 'or' },
    { layer: 'face', operation: 'or' },
    { layer: 'point_of_interest', operation: 'and' }
  ]
  const availableTypes = ([...new Set(list.map(x => x.type))])
  const values = {}
  availableTypes.forEach(element => {
    var typedElement = element.toString()
    values[typedElement] = listCopy.filter(x => x.type === element)
  })
  var expression = null
  for (var i in priorities) {
    if (values[priorities[i].layer]) {
      expression = processLayer(values[priorities[i].layer], expression, priorities[i].operation)
    }
  }
  return expression
}

function processLayer (filterList, expression, operation) {
  var includeFilters = []
  var excludeFilters = []
  var includeGroups = []
  var excludeGroups = []
  for (var j in filterList) {
    var geo = preprocessGeoToApi(geoTypeFormatter(filterList[j]))
    if (filterList[j].selectionRule === 'include') {
      includeFilters.push(geo.geo)
    } else {
      excludeFilters.push(geo.geo)
    }
  }
  var includeGroup = null
  var excludeGroup = null
  if (includeGroups.length > 0) {
    includeGroup = {
      operation: 'or',
      groups: includeGroups
    }
  } else if (includeFilters.length > 0) {
    includeGroup = {
      operation: 'or',
      filters: includeFilters
    }
  }
  if (excludeGroups.length > 0) {
    excludeGroup = {
      operation: 'and',
      groups: excludeGroups
    }
  } else if (excludeFilters.length > 0) {
    excludeGroup = {
      operation: 'and',
      filters: excludeFilters
    }
  }
  // include
  if (includeGroup != null) {
    if (expression == null) { expression = includeGroup } else {
      const forceAnd = expression.filters && expression.filters.every(x => x.selectionRule === 'exclude')
      expression = {
        operation: forceAnd ? 'and' : operation,
        groups: [includeGroup, expression]
      }
    }
  }
  // exclude
  if (excludeGroup != null) {
    if (expression == null) { expression = excludeGroup } else {
      expression = {
        operation: 'and',
        groups: [excludeGroup, expression]
      }
    }
  }

  return expression
}

function geoFromApiFormatting (geographyDict, unit = 'metric') {
  const finalList = []
  let listOfFilters = []
  // 1- Extracting filters
  listOfFilters = extractFiltersFromGeography(geographyDict)

  // 2- Formatting
  if (listOfFilters && listOfFilters.length > 0) {
    listOfFilters.forEach(element => {
      const filterCopy = JSON.parse(JSON.stringify(element))

      if (filterCopy.type === 'point_of_interest') {
        const pois = []
        if (typeof filterCopy.value === 'object' && filterCopy.value.length > 1) {
          pois.push(...filterCopy.value.map(x => {
            return {
              type: x.type,
              isIncluded: x.selectionRule === 'include',
              data: {
                radius: radiusFormatting(x.radiusInKm, 'toString', unit),
                type: { id: x.value, name: x.label }
              }
            }
          }))
        } else {
          pois.push({
            type: filterCopy.type,
            isIncluded: filterCopy.selectionRule === 'include',
            data: {
              radius: radiusFormatting(filterCopy.radiusInKm, 'toString', unit),
              type: { id: filterCopy.value, name: filterCopy.label }
            }
          })
        }

        finalList.push(...pois)
      } else {
        if (!filterCopy.radiusInKm && (['venue', 'city'].includes(filterCopy.type))) {
          filterCopy.radius = filterCopy.type.charAt(0).toUpperCase() + filterCopy.type.slice(1) + ' Only'
        }
        if (filterCopy.radiusInKm) {
          filterCopy.radius = radiusFormatting(filterCopy.radiusInKm, 'toString', unit)
          delete filterCopy.radiusInKm
        }
        if (filterCopy.selectionRule === 'include') {
          filterCopy.isIncluded = true
          delete filterCopy.selectionRule
        }
        if (filterCopy.selectionRule === 'exclude') {
          filterCopy.isIncluded = false
          delete filterCopy.selectionRule
        }
        if (filterCopy.latitude || filterCopy.longitude || filterCopy.geoJSON) {
          filterCopy.geography = {}
          if (filterCopy.latitude) {
            filterCopy.geography.latitude = filterCopy.latitude
            delete filterCopy.latitude
          }
          if (filterCopy.longitude) {
            filterCopy.geography.longitude = filterCopy.longitude
            delete filterCopy.longitude
          }
          if (filterCopy.geoJSON) {
            filterCopy.geography.geoJSON = filterCopy.geoJSON
            delete filterCopy.geoJSON
          }
        }

        finalList.push(filterCopy)
      }
    })
  }

  return finalList
    .map(x => addUniqueAddressId(x))
}

// ================
// Helper functions
// ================

function addUniqueAddressId (x) {
  if (x.type === 'address') return { ...x, value: x.label + x.geography.latitude + x.geography.longitude }
  else return x
}

function radiusFormatting (value, direction, unit = 'metric') {
  if (!unit) unit = 'metric'

  if (value) {
    if (direction === 'toString') return convertDistanceToLabel(value, unit)
    if (direction === 'toNumber') return getKmValueOfDistanceLabel(value)
  }
}

function convertDistanceToLabel (val, unit) {
  if (!unit) unit = 'metric'
  if (unit !== 'metric') {
    const valInMiles = val / 1.609344497892563
    if ((valInMiles).toFixed(2) < 0.25) {
      const valInYards = Math.round(valInMiles * 1760)
      return String(valInYards) + ' yd'
    } else {
      if (parseFloat((valInMiles).toFixed(2)) === 0.25) return '¼ mi'
      else if (parseFloat((valInMiles).toFixed(2)) === 0.5) return '½ mi'
      else return String((valInMiles).toFixed(0)) + ' mi'
    }
  } else {
    if (val < 1) {
      return String(val * 1000) + ' m'
    } else {
      return String(val) + ' km'
    }
  }
}

function getKmValueOfDistanceLabel (str) {
  const valueUnitList = str.split(' ')
  if (valueUnitList[1] === 'Only') return null

  let val = null
  if (valueUnitList[0] === '¼') val = 0.25
  else if (valueUnitList[0] === '½') val = 0.5
  else val = parseInt(valueUnitList[0])
  switch (valueUnitList[1]) {
    case 'm': return val / 1000
    case 'km': return val
    case 'yd': return (val / 1760) * 1.609344497892563
    case 'mi': return val * 1.609344497892563
  }
}

function geoTypeFormatter (obj) {
  if (obj.type !== 'point_of_interest') {
    const output = {}
    output.type = obj.type
    output.selectionRule = obj.selectionRule
    output.value = obj.value
    output.label = obj.label
    output.geoJSONFileUrl = obj.geoJSONFileUrl
    if (obj.radiusInKm) { output.radiusInKm = obj.radiusInKm }
    if (obj.geography && obj.geography.latitude) { output.latitude = obj.geography.latitude }
    if (obj.geography && obj.geography.longitude) { output.longitude = obj.geography.longitude }
    if (obj.geography && obj.geography.geoJSON) { output.geoJSON = obj.geography.geoJSON }
    return output
  } else {
    const poiAndCity = {
      type: obj.type,
      selectionRule: obj.selectionRule,
      value: obj.data.type.id,
      radiusInKm: radiusFormatting(obj.data.radius, 'toNumber'),
      label: obj.data.type.name
    }
    return poiAndCity
  }
}

function preprocessGeoToApi (f) {
  return {
    type: 'filter',
    geo: f
  }
}

function extractFiltersFromGeography (geographyDict) {
  var listOfFilters = []
  if (geographyDict.groups && geographyDict.groups.length) {
    geographyDict.groups.map(group => {
      var childs = extractFiltersFromGeography(group)
      if (childs.length) {
        listOfFilters.push(...childs)
      }
    })
  } else {
    if (geographyDict.filters && geographyDict.filters.length) {
      if (geographyDict.filters.length === 2 && geographyDict.filters.map(x => x.type).includes('point_of_interest')) {
        listOfFilters.push({ type: 'point_of_interest', value: geographyDict.filters })
      } else {
        listOfFilters.push(...geographyDict.filters)
      }
    }
  }

  return listOfFilters
}

function getCountriesAndStates () {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/countries').then(res => res.data)
}

function reconstructGeography (geography, item, info, unit = 'metric') {
  // geography object in api format
  // item: venue or screen in api format
  // info = { toStatus: "exclude or include" , type: "venue or screen" }

  let out = null

  if (!geography && info.toStatus === 'include') { out = [] }
  if (!geography && info.toStatus === 'exclude') { out = addItemToGeotargets([], item, info.type) }
  if (geography) {
    const geotargets = geoFromApiFormatting(geography, unit)
    if (info.toStatus === 'include') { out = removeItemFromGeotargets(geotargets, item, info.type) }
    if (info.toStatus === 'exclude') { out = addItemToGeotargets(geotargets, item, info.type) }
  }

  return out.length ? geoTargetsToApiFormatting(out) : null
}

function addItemToGeotargets (geotargets, item, type) {
  // geography object in api format
  // item: venue or screen in api format

  let itemObj = null

  if (type === 'venue') {
    itemObj = {
      geoJSON: null,
      latitude: item.latitude,
      longitude: item.longitude,
      label: item.name,
      radius: 'Venue Only',
      type: 'venue',
      value: item.id,
      isIncluded: false
    }
  }
  if (type === 'screen') {
    itemObj = {
      label: item.name,
      radius: 'Venue Only',
      type: 'face',
      value: item.id,
      isIncluded: false
    }
  }

  // Remove old version of item if already in geotargets
  const geos = removeItemFromGeotargets(geotargets, itemObj, type)

  geos.push(itemObj)
  return geos
}

function removeItemFromGeotargets (geotargets, item, type) {
  // geography object in api format
  // item: venue or screen in api format

  type = type === 'screen' ? 'face' : type

  const geos = [...geotargets]
  const indx = geos.findIndex(geotarget => parseInt(geotarget.value) === parseInt(item.id) && geotarget.type === type)
  if (indx >= 0) { geos.splice(indx, 1) }

  return geos
}

function removeGeoJSON (selectedGeoTargets) {
  return selectedGeoTargets.map(geoTarget => {
    var { data, geography, isIncluded, label, radius, type, value } = geoTarget

    var latitude = geography ? geography.latitude : geoTarget.latitude
    var longitude = geography ? geography.longitude : geoTarget.longitude

    var newGeoTarget = { geography: { latitude, longitude }, isIncluded, label, radius, type, value }

    // POIs have an extra property "data"
    if (type === 'point_of_interest') {
      newGeoTarget.data = data
    }

    return newGeoTarget
  })
}

// ========
// Lat Long
// ========

function latLongRegEx (separator) {
  switch (separator) {
    case 'comma': return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g
    case 'space': return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s+[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g
    case 'tab': return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\t[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g
    case 'none': return /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g
  }
}

function getCoors (strRaw) {
  if (!strRaw || !strRaw.length) return null
  const str = strRaw.trim()
  const separators = {
    comma: /\s*,\s*/g,
    space: /\s+/g,
    tab: /\t/g,
    none: '-'
  }
  let coors = null
  Object.keys(separators).forEach(separatorLabel => {
    const matched = str.match(latLongRegEx(separatorLabel))
    if (matched) {
      if (separatorLabel === 'none') {
        const signMatches = str.match(/-|\+/g)
        const onlyLongHasSign = signMatches && signMatches.length === 1 && !str[0].match(/-|\+/g)
        const twoDistinctSigns = signMatches && signMatches.length === 2 && signMatches[0] !== signMatches[1]
        if (onlyLongHasSign || twoDistinctSigns) {
          const splitVals = str.split(signMatches[signMatches.length - 1])
          coors = [splitVals[0], signMatches[signMatches.length - 1] + splitVals[1]]
        }
        if (signMatches && signMatches.length === 2 && signMatches[0] === signMatches[1]) {
          const splitVals = str.split(signMatches[0])
          coors = [signMatches[0] + splitVals[1], signMatches[0] + splitVals[2]]
        }
      } else coors = str.split(separators[separatorLabel])
    }
  })
  return !coors ? null : {
    latitude: parseFloat(coors[0]),
    longitude: parseFloat(coors[1])
  }
}
