<template lang="pug">
  v-form(ref="campaign" v-model='editCampaignFormValid')
    v-row.my-0(row wrap justify="end")

      v-col.pt-0
        v-combobox.mt-0#advertiser-field(
          v-model='enteredData.advertiser'
          :rules="[rules.required]"
          :disabled='disableAdvertiserSelect'
          :items='advertisers'
          item-text='name'
          return-object
          label='Advertiser'
          ref='advertiserRef'
          flat
          @update:search-input='searchCallback'
          @input='advertiserSelected')
          template(slot='no-data')
            v-list-item
              v-list-item-title This advertiser will be created

      v-col.pt-0(cols=12 sm=6 lg=4)
        v-text-field.mt-0#advertiser-external-id-field(
          label='External ID'
          v-model='enteredData.advertiserExternalId'
          :loading='fetchingAdvertiserOrg')

    v-row.my-0(row wrap justify="end")
      v-col.py-0
        v-text-field.mt-0#campaign-name-field(
          :label="campaignType + ' Name'"
          v-model='enteredData.campaignName'
          counter='100'
          :rules="[rules.required, rules.counter]")

      v-col.py-0(cols=12 sm=6 lg=4)
        v-text-field.mt-0#campaign-external-id-field(
          label='External ID'
          v-model='enteredData.campaignExternalId')
</template>

<script>
import userApi from '@/services/user.api'
import helpers from '@/services/helpers.service'

export default {
  props: {
    campaignType: {
      type: String,
      default: () => 'campaign'
    },
    campaignId: {
      type: Number,
      default: () => 0
    },
    campaignName: {
      type: String,
      default: () => ''
    },
    campaignExternalId: {
      type: String,
      default: () => ''
    },
    advertisers: {
      type: Array,
      default: () => []
    },
    advertiserId: {
      type: Number,
      default: () => 0
    },
    advertiserExternalId: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      editCampaignFormValid: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 100 || 'Too long.'
      },
      enteredData: {
        advertiser: null,
        advertiserExternalId: '',
        industry: '',
        campaignName: this.campaignName,
        campaignExternalId: this.campaignExternalId
      },
      fetchingAdvertiserOrg: false
    }
  },
  watch: {
    advertisers () {
      if (this.advertiserId) {
        this.enteredData.advertiser = this.advertisers.find(adv => adv.id === this.advertiserId)
        this.advertiserSelected()
      }
    },
    enteredData: {
      immediate: true,
      handler () {
        this.$store.commit('createCampaign/storeCampaignDetails', this.enteredData)
        const inputMissing = !this.enteredData.advertiser || !this.enteredData.campaignName
        this.$emit('inputMissing', inputMissing)
      },
      deep: true
    }
  },
  mounted () {
    this.$root.$on('validateCampaignForm', () => {
      this.$emit('formValidationUpdated', this.$refs.campaign?.validate())
    })
  },
  computed: {
    disableAdvertiserSelect () {
      return this.campaignType === 'Campaign' && !!this.campaignId
    },
    industries () {
      return this.$store.getters['general/getIndustries']
    }
  },
  methods: {
    searchCallback (searchStr) {
      if (this.advertiser) {
        if (this.advertiser.name !== searchStr) {
          this.$emit('advertiserSelected', { id: null, name: searchStr })
        }
      }
    },
    checkDuplicateAdvertiser () {
      const advertiserFromList = helpers.matchCaseInsensitiveStringInList(this.enteredData.advertiser, this.advertisers)
      if (advertiserFromList) this.enteredData.advertiser = advertiserFromList
    },
    advertiserSelected () {
      this.checkDuplicateAdvertiser()

      if (this.enteredData.advertiser && this.enteredData.advertiser.advertiserId) {
        this.fetchingAdvertiserOrg = true

        userApi.getOrganization(this.enteredData.advertiser.advertiserId)
          .then(advertiserOrganization => {
            const { id, externalId } = advertiserOrganization
            this.$store.commit('createCampaign/setAdvertiserOrganization', { id, externalId })

            this.updateIndustry(advertiserOrganization.industry)
            this.updateAdvertiserExternalId(advertiserOrganization.externalId)

            this.fetchingAdvertiserOrg = false
          })
      }

      this.$emit('advertiserSelected', this.enteredData.advertiser)
    },
    updateIndustry (industry) {
      const filteredIndustry = this.industries.find(i => i.name === industry)
      if (filteredIndustry) {
        this.enteredData.industry = filteredIndustry
      }
    },
    updateAdvertiserExternalId (externalId) {
      this.enteredData.advertiserExternalId = externalId
    }
  }
}
</script>
