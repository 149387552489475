<template lang="pug">
  v-dialog(max-width=500 v-model='localIsOpen' @click:outside='cancel' :fullscreen='$vuetify.breakpoint.smAndDown')
    v-card
      v-card-title {{ actionLabel }}
      v-card-text
        .text-subtitle-1
          | {{ userlabel }}
          b  {{ user.email }}
          span(v-if='user.organization')  {{ organizationlabel }}
            b  {{ user.organization.name }}
          | ?
          br
          | This action cannot be undone.
      v-card-actions
        v-spacer
        v-btn(text @click='cancel') cancel
        v-btn(text color='primary' @click='confirm') {{ actionLabel }}
</template>

<script>
export default {
  props: ['dialogOpen', 'user', 'action'],
  data () {
    return {
      localIsOpen: this.dialogOpen
    }
  },
  watch: {
    dialogOpen (newVal) {
      this.localIsOpen = newVal
    }
  },
  computed: {
    actionLabel () {
      switch (this.action) {
        case 'reinvite' :
          return 'Resend Invitation'
        case 'cancelInvite' :
          return 'Cancel Invitation'
        case 'block' :
          return 'Block User'
        case 'unblock' :
          return 'Unblock User'
        default :
          return 'Confirm'
      }
    },
    userlabel () {
      switch (this.action) {
        case 'reinvite' :
          return 'Resend email invitation to'
        case 'cancelInvite' :
          return 'Cancel invitation sent to'
        case 'block' :
          return 'Revoke access for'
        case 'unblock' :
          return 'Grant access to'
        default :
          return ''
      }
    },
    organizationlabel () {
      switch (this.action) {
        case 'reinvite' :
        case 'cancelInvite' :
          return 'to be part of'
        case 'unblock' :
          return ''
        default :
          return 'to'
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
      this.localIsOpen = false
    },
    confirm () {
      this.$emit('confirm')
      this.localIsOpen = false
    }
  }
}
</script>
