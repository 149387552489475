<template lang="pug">
div(:style="$vuetify.breakpoint.xsOnly && showImpressions ? 'padding-top: 44px;' : ''")
  vue-headful(:title="componentConfig.branding.title(tabTitle)")
  v-container.pa-0(fluid grid-list-lg text-left)
    v-scroll-y-transition
      v-btn#toggle-map-btn.primary--text.mx-auto.mb-4(
        style='position: fixed; bottom:0; left: 0; right: 0; z-index: 2;'
        color='white'
        :width="!showMapOnly? 95 : 150"
        rounded
        @click='toggleView'
        v-if='$vuetify.breakpoint.xsOnly'
        elevation=12
      )
        v-icon.mr-2(color='primary') {{ showMapOnly? 'mdi-menu' : 'mdi-map-outline' }}
        | {{ showMapOnly? 'targeting' : 'map' }}

    v-row.ma-0
      v-col.pa-0.pa-sm-3(
        v-show="!showMapOnly || $vuetify.breakpoint.smAndUp"
        cols=12 md=6 lg=5 xl=5
        style='min-height: calc(100vh - 48px);'
      )

        .text-h5.mt-3.mb-1.page-title {{ pageTitle }}
        v-expansion-panels(v-model='panelsCampaignModel')
          v-expansion-panel#campaign-panel(ref='panelCampaignRef')
            v-expansion-panel-header.panel-header-title
              template(v-slot:default='{ open }')
                v-row.my-0(no-gutters align="center")
                  v-col.body-1(cols='5') {{ instanceLabel }} Settings
                  v-col.text--secondary(cols='7')
                    v-fade-transition(leave-absolute)
                      span(v-if='!open' key='0')
                        | {{ campaignName }}
            v-expansion-panel-content
              v-card(flat color="transparent")
                v-card-title.pa-0.pb-2(v-if='!isOnAuctionPackagePage')
                  div.text-body-2
                    a.info--text#first-campaign-guide-link(:href="componentConfig.links.firstCampaignStepByStep" target='_blank')
                      span(style="break-word: break-word;") Step by step guide for creating your first {{ instanceLabel.toLowerCase() }}
                      v-icon(small color='info') mdi-launch
                v-card-text.pa-0
                  campaign-form(
                    v-if='!isOnAuctionPackagePage'
                    :campaign-type='instanceLabel'
                    :advertisers='advertisers'
                    @industrySelected='updateForecast'
                    @formValidationUpdated='updateValidationStatus'
                  )
                  auction-package-form(
                    v-if='isOnAuctionPackagePage'
                    parent='createCampaign'
                    @updateForecast='updateForecast'
                  )

        div(v-if='panelsConfig.isLineOrderShown').add-first-line
          .text-h5.mt-6.mb-1 Add first line order

        panelLineOrderName(
          v-if='panelsConfig.isLineOrderShown'
          @formValidationUpdated='updateValidationStatus'
        )

        v-expansion-panels.mt-4(multiple v-model='panelsStateController')
          panelInventorySource(
            instance= 'createCampaign'
            v-if="componentConfig.campaigns.isDealsShown && panelsConfig.isInventorySourceShown"
            @updateForecast='updateForecast'
            :canSeeDspPartnerFields='isUserOrgDspPartner'
            :isOnAuctionPackagePage='isOnAuctionPackagePage'
            )

          panelAudience#audience-panel(
            v-if='panelsConfig.isAudienceShown'
            @updateForecast='updateForecast'
            parent='createCampaign'
            :isOnAuctionPackagePage='isOnAuctionPackagePage'
            :parentLoading='!allTargetsReady'
            :downloadingInventory='downloadingInventory'
            :tab='audiencePanelTab'
            @switchTab='switchPanelAudienceTab'
            @inventoryListChanged="updateTableToMapSync"
            @downloadInventory='downloadInventory'
            )

          panelMoments#moment-panel(
            v-if='panelsConfig.isMomentShown'
            @momentCreated='placeOrder'
            @momentsError='momentsError'
            )

          panelBudgetAndScheduleWithGoals(
            v-if="componentConfig.campaigns.isGoalsShown && panelsConfig.isBudgetShown"
            parent='createCampaign'
            @updateForecast='updateForecast'
            @formValidationUpdated='updateValidationStatus'
            )

          panelBudgetAndSchedule(
            v-else-if='panelsConfig.isBudgetShown'
            :isDspPartnerCampaign='isUserOrgDspPartner'
            parent='createCampaign'
            @updateForecast='updateForecast'
            @formValidationUpdated='updateValidationStatus'
            )

          panelLineGoal(
            v-if="componentConfig.campaigns.isGoalsShown"
            @formValidationUpdated='updateValidationStatus'
            )

          panelLinePriority(
            v-if="componentConfig.campaigns.isLinePriorityShown"
            @formValidationUpdated='updateValidationStatus'
            )

          panelCost#ap-cost-panel(v-if='panelsConfig.isCostShown')

          panelCreatives(
            v-if='!isCreatePlanPage && panelsConfig.isCreativeShown'
            @updateForecast='updateForecast'
            :isOnAuctionPackagePage='isOnAuctionPackagePage'
            :isDspPartnerCampaign='isUserOrgDspPartner'
            )

          .text-right(style='width: 100%;')
            v-spacer
              .mt-4.mx-3.mx-md-0.mb-16.mb-md-4
                v-btn#save-as-proposal-desktop(
                  v-if='showCreateProposal'
                  :text='showCreateCampaign'
                  rounded large color='primary'
                  :block='$vuetify.breakpoint.smAndDown'
                  :disabled='isCreatingCampaign'
                  :loading='isCreatingProposal'
                  @click='saveAsProposal')
                  | {{ saveProposalLabel }}
                v-btn.my-4.mr-0.ml-md-2#create-campaign-save-btn(
                  v-if='showCreateCampaign'
                  rounded large color='primary'
                  :block='$vuetify.breakpoint.smAndDown'
                  :disabled='isCreatingProposal'
                  :loading='isCreatingCampaign || isCreatingAuctionPackage'
                  @click='placeOrder()')
                  span {{ panelsConfig.createBtnText }}

                v-dialog(v-model='saveAsProposalConfirmationOpen' max-width=400)
                  v-card
                    v-card-text.pt-3.px-4.confirmation-msg
                      | The uploaded creatives will be discarded if you save as {{ this.proposalLabel.toLowerCase() }}. Are you sure you want to continue?
                    v-divider
                    v-card-actions
                      v-spacer
                      v-btn(text @click='saveAsProposalConfirmationOpen = false') Cancel
                      v-btn.confirmation-save-btn(text color='primary' @click="saveAsProposalConfirmationOpen = false ; placeOrder('proposal')")
                        | save as {{ this.proposalLabel.toLowerCase() }}

      //- Map & Forecast
      v-col.ma-0.pa-0(cols=12 md=6 lg=7 xl=7 order='first' order-md='last')
        div.sticky-column
          InventoryMap(
            v-if='mapExists'
            v-show='showMapOnly || $vuetify.breakpoint.smAndUp'
            :bound-initial-forecast='componentConfig.campaigns.boundInitialForecastCall'
            :compact-popups='$vuetify.breakpoint.xsOnly'
            :default-latitude='marketValues.mapCenter[1]'
            :default-longitude='marketValues.mapCenter[0]'
            :default-zoom='marketValues.mapZoom'
            :filter-on-move='filterOnMove'
            :forecast-inventory='forecastInventory'
            :forecast-inventory-loading='isForecastInventoryLoading'
            :forecast-loading='forecastLoading'
            :geo-targets='mapGeoTargets'
            :get-venue-details-func='getVenueDetails'
            :height='mapHeight'
            :highlighted-geo-target='highlightedGeoTargetForMap'
            :impressions='{ total: totalImpressions, targeted: targetedImpressions }'
            :map-box-token='mapboxToken'
            ref='mapComponent'
            :show-details-button='$vuetify.breakpoint.smAndUp'
            :show-fullscreen-button='$vuetify.breakpoint.smAndUp'
            :show-impressions='$vuetify.breakpoint.smAndUp && showImpressions'
            :show-inventory-summary='$vuetify.breakpoint.smAndUp'
            show-venue-popup
            :targeted-screens='targetedScreens'
            :targeted-venues='targetedVenues'
            :unit='marketValues.distanceUnit'
            :zoom-on-updated-forecast='pagination.filtering'
            :disable-recentering='disableRecentering'
            :resolve-geo-json-func='resolveGeoJson'
            @filterOnMoveChecked='filterOnMoveChecked'
            @mapBoundsUpdated='mapBoundsUpdated'
            @hideDetails='hideDetails'
            @seeDetails='seeDetails'
            @geoTargetSelected='geoTargetSelected'
            @geoTargetUnselected='geoTargetUnselected'
            @geoTargetAdded='geoTargetAdded'
            @geoTargetUpdated='geoTargetUpdated'
            @geoTargetRemoved='geoTargetRemoved'
          )
            template(v-slot:fullscreen-drawer-content='')
              forecast-inventory(
                :loading='isForecastVenuesLoading'
                :downloading-inventory='downloadingInventory'
                :items='forecastVenues'
                :total-items='targetedVenues'
                :has-more-items='pagination.hasMorePages'
                :page='pagination.page'
                :update-venue-inclusion-enabled='true'
                @updateVenueInclusion='updateVenueInclusion'
                @inventoryListChanged='updateTableToMapSync'
                @downloadInventory='downloadInventory'
              )

        forecastSummary(
          ref='mobileForecastSummary'
          v-if="$vuetify.breakpoint.xsOnly"
          parent='createCampaign'
          :showImpressions='showImpressions')
  loadDealsDialog(:openDialogEvent='showDealsDialog' @updateForecast='updateForecast')
</template>
<script>
import campaignForm from '@/components/campaignForm.vue'
import panelLineOrderName from '@/components/panelLineOrderName.vue'
import auctionPackageForm from '@/components/auctionPackageForm.vue'
import panelLineGoal from '@/components/panelLineGoal.vue'
import panelLinePriority from '@/components/panelLinePriority.vue'
import panelInventorySource from '@/components/panelInventorySource.vue'
import panelAudience from '@/components/panelAudience.vue'
import panelMoments from '@/components/panelMoments.vue'
import panelBudgetAndSchedule from '@/components/panelBudgetAndSchedule.vue'
import panelBudgetAndScheduleWithGoals from '@/components/panelBudgetAndScheduleWithGoals.vue'
import panelCreatives from '@/components/panelCreatives.vue'
import panelCost from '@/components/panelCost.vue'
import forecastSummary from '@/components/forecast.summary.vue'
import forecastInventory from '@/components/forecast.inventory.vue'
import loadDealsDialog from '@/views/loadDealsDialog.vue'
import { InventoryMap } from '@ayudasystems/campaign-targeting'

import _ from 'lodash'

import audienceApi from '@/services/audience.api'
import venuesAPI from '@/services/venues.api'
import csvService from '@/services/csv.service'
import geoBoundingBoxService from '@/services/geo.boundingbox.service'
import geoService from '@/services/geo.service'

import helperService from '@/services/helpers.service'
import tracking from '@/services/tracking'
import defaultExchangeValues from '@/services/defaultExchangeValues'

import componentConfigService from '@/services/componentConfig'
import campaignValidationService from '@/services/campaignValidation.service'

import campsiteConfig from '@/config/campsite.config'
import { OrganizationTypeEnum } from '@/enums'

export default {
  components: {
    campaignForm,
    panelLineOrderName,
    auctionPackageForm,
    panelLineGoal,
    panelLinePriority,
    panelInventorySource,
    panelAudience,
    panelMoments,
    panelBudgetAndSchedule,
    panelBudgetAndScheduleWithGoals,
    panelCreatives,
    panelCost,
    InventoryMap,
    forecastInventory,
    forecastSummary,
    loadDealsDialog
  },
  created: function () {
    if (!this.$flags.canSeeUiRebrand.isEnabled() && this.isCreatePlanPage) this.$router.push({ name: 'create-campaign' })

    const userMarket = this.isOnAuctionPackagePage || this.isUserOrgDspPartner
      ? 'united-state'
      : defaultExchangeValues.getDefaultValuesByCurrency(this.userOrganization.currency ? this.userOrganization.currency.code : 'USD').market
    const initialExchange = this.exchanges.find(e => e.key === userMarket)
    this.$store.commit('audience/resetAudience')
    this.$store.commit('createCampaign/resetCreateCampaign')
    this.$store.commit('createCampaign/storeExchangeDetails', { exchange: initialExchange })
    if (this.$store.getters['user/isForAdServer']) this.$store.commit('createCampaign/setCanOverwriteDealMaxBid', false)

    this.$store.dispatch('audience/addDigitalFaceTypeTarget')

    this.$root.$on('toggleLoadDealsDialog', () => {
      this.showDealsDialog = !this.showDealsDialog
    })

    if (this.isOnAuctionPackagePage) {
      this.$store.commit('auctionPackage/storeExchangeDetails', { exchange: initialExchange })
      this.$store.commit('auctionPackage/setScheduleOption', 'always')
    }

    const dealsPromise = this.initializeDeals()
    const targetingPromise = this.$store.dispatch('general/getExchangesTargeting')

    Promise.all([dealsPromise, targetingPromise]).then(() => {
      this.loading = false
      this.updateForecast()
    })

    this.openDefaultPanels()
    this.geoTargetsWithGeoJson = this.selectedGeoTargets
  },
  data () {
    return {
      panelsCampaignModel: 0,
      panelsStateController: [],
      loading: true,
      forecastOptionsLastVersion: null,
      isCreatingCampaign: false,
      isCreatingProposal: false,
      isCreatingAuctionPackage: false,
      saveAsProposalConfirmationOpen: false,
      audiencePanelTab: 0,
      showMapOnly: false,
      mapExists: true,
      downloadingInventory: false,
      showDealsDialog: false,
      validationStatus: {
        isValid: true,
        field: ''
      },
      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      isFilterOnMoveVisible: false,
      updateGeoTargetsJsonCT: null,
      geoTargetsWithGeoJson: [],
      disableRecentering: false,
      highlightedGeoTargetForMap: null,
      geoTargetUpdatedFromMap: false
    }
  },
  computed: {
    advertisers () {
      return this.$store.getters['general/getAdvertisers']
    },
    exchanges () {
      return this.$store.getters['general/getExchanges']
    },
    exchange () {
      return this.isOnAuctionPackagePage ? this.$store.getters['auctionPackage/getExchange'] : this.$store.getters['createCampaign/getExchange']
    },
    userOrganization () {
      return this.$store.getters['user/getOrganization']
    },
    dealIdsFromUrl () {
      return this.$route.query.dealIds
        ? this.$route.query.dealIds.split(',')
        : []
    },
    canUpdateForecast () {
      return Boolean(this.allTargetsReady && !this.loading && (!this.$store.getters['user/isForAdServer'] || this.validDeals.length))
    },
    isForecastInventoryLoading () {
      return this.$store.getters['audience/forecastInventoryLoading']
    },
    forecastInventory () {
      return this.$store.getters['audience/forecastInventory']
    },
    selectedGeoTargets () {
      return this.$store.getters['audience/selectedGeoTargets']
    },
    validDeals () {
      return this.$store.getters['createCampaign/validDeals']
    },
    allTargetsReady () {
      return this.$store.getters['general/appReady4Real'] && !this.loading
    },

    forecast () {
      return this.$store.getters['audience/forecast']
    },
    forecastLoading () {
      return this.$store.getters['audience/forecastLoading']
    },
    targetedVenues () {
      return this.forecast.inventory.numberOfVenues
    },
    targetedScreens () {
      return this.forecast.inventory.numberOfScreens
    },
    targetedImpressions () {
      const isTargetedImpressionsAvailable =
        this.$store.getters['createCampaign/getMaxCpm'] &&
        this.$store.getters['createCampaign/getBudget'] &&
        !!this.forecast.budget

      return isTargetedImpressionsAvailable ? this.forecast.budget.impressions.value : null
    },
    totalImpressions () {
      const isForecastImpressionsAvailable =
        !this.$store.getters['createCampaign/hasMoment'] &&
        (this.$store.getters['createCampaign/deals'].length === 0 || this.$flags.canSeeAuctionPackagePrivateDealsTargetedImpressions.isEnabled())

      return isForecastImpressionsAvailable ? this.forecast.impressions.value : null
    },
    forecastVenues () {
      var forecastVenues = this.$store.getters['audience/forecastVenues']
      if (!forecastVenues) return []

      return forecastVenues.venues.map(venue => {
        const matchedGeoTarget = this.selectedGeoTargets.find(geoTarget => geoTarget.value === venue.id.toString())
        const isIncluded = !matchedGeoTarget || matchedGeoTarget.isIncluded
        return { ...venue, isIncluded }
      })
    },
    isForecastVenuesLoading () {
      return this.$store.getters['audience/forecastVenuesLoading']
    },
    pagination () {
      return this.$store.getters['audience/getPagination']
    },
    isScheduleValidated () {
      return this.$store.getters['createCampaign/isScheduleValidated']
    },
    filterAsImove () {
      return this.$store.getters['audience/filterAsImove']
    },
    canOverwrite () {
      return this.$store.getters['createCampaign/canOverwriteWithDeal']
    },
    isUserOrgDspPartner () {
      return this.$store.getters['user/getOrganization'].type === OrganizationTypeEnum.PARTNER_DSP
    },
    getCommonForecastOptions () {
      if (this.isOnAuctionPackagePage) {
        return this.$store.getters['auctionPackage/getCommonForecastOptions']
      } else {
        var options = this.$store.getters['createCampaign/getCommonForecastOptions']
        if (this.isUserOrgDspPartner) {
          options.percentiles = [1, 100]
        }
        return options
      }
    },
    campaignName () {
      return this.$store.getters['createCampaign/getCampaignName']
    },
    isCreatePlanPage () {
      return this.$route.path === '/plans/create'
    },
    isOnAuctionPackagePage () {
      return this.$route.path.includes('auction-package') && this.$store.getters['general/isAuctionPackageVisible']
    },
    panelsConfig () {
      const createCampaignVue = {
        auctionPackage: {
          isInventorySourceShown: true,
          isLineOrderShown: false,
          isAdvertiserShown: false,
          isBudgetShown: false,
          isCreativeShown: true,
          isMomentShown: true,
          isAudienceShown: true,
          isSaveProposalShown: false,
          isCostShown: this.userPermissions('auctionPackage')?.update?.fees,
          createBtnText: 'Create Auction Package',
          label: 'Auction Package'
        },
        campaign: {
          isInventorySourceShown: true,
          isLineOrderShown: true,
          isAdvertiserShown: true,
          isBudgetShown: true,
          isCreativeShown: this.userPermissions('creative').read.default,
          isMomentShown: true,
          isAudienceShown: true,
          isSaveProposalShown: true,
          createBtnText: 'Create Campaign',
          label: 'Campaign'
        }
      }

      return this.isOnAuctionPackagePage
        ? createCampaignVue.auctionPackage
        : createCampaignVue.campaign
    },
    proposalLabel () {
      return this.$flags.canSeeUiRebrand.isEnabled() ? 'Plan' : 'Proposal'
    },
    instanceLabel () {
      return this.isCreatePlanPage ? this.proposalLabel : this.panelsConfig.label
    },
    pageTitle () {
      return this.isOnAuctionPackagePage ? 'Create an Auction Package' : 'Create a ' + this.instanceLabel.toLowerCase()
    },
    saveProposalLabel () {
      return this.isCreatePlanPage || this.isUserOrgDspPartner ? `Create ${this.proposalLabel}` : `Save as ${this.proposalLabel}`
    },
    tabTitle () {
      return `Create ${this.instanceLabel}`
    },
    allFormsForAuctionPackageAreValid () {
      const auctionPackageName = this.$store.getters['auctionPackage/getName']
      this.$root.$emit('validateSchedule')
      return Boolean(auctionPackageName && this.isScheduleValidated)
    },
    panelsList () {
      const panels = [
        {
          name: 'inventorySource',
          isShown: !this.$store.getters['user/isForAdServer']
        },
        {
          name: 'audience',
          isShown: true
        },
        {
          name: 'moments',
          isShown: true
        },
        {
          name: 'budget',
          isShown: true
        },
        {
          name: 'lineGoal',
          isShown: this.$store.getters['user/isForAdServer']
        },
        {
          name: 'linePriority',
          isShown: this.$store.getters['user/isForAdServer']
        },
        {
          name: 'creatives',
          isShown: this.userPermissions('creative').read.default
        }
      ]

      return panels.filter(panel => panel.isShown).map(panel => panel.name)
    },
    panelsToValidate () {
      return [
        'campaignForm',
        ...this.$store.getters['user/isForAdServer'] ? ['lineOrderName'] : [],
        'budgetAndSchedule',
        'lineGoal',
        'linePriority'
      ]
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    userPermissions () {
      return this.$store.getters['user/permissions']
    },
    showImpressions () {
      return this.componentConfig.campaigns.impressions.useEstimated
    },
    showCreateCampaign () {
      return !this.isCreatePlanPage && this.userPermissions('campaign').create.default && !(this.isUserOrgDspPartner && !this.isOnAuctionPackagePage)
    },
    showCreateProposal () {
      return this.panelsConfig.isSaveProposalShown && this.userPermissions('proposal').create.default
    },
    marketValues () {
      return this.$store.getters['general/marketValueFromExchange'](this.exchange.key)
    },
    mapHeight () {
      const offsetHeight = this.$vuetify.breakpoint.xsOnly && this.showImpressions
        ? campsiteConfig.barsHeight.appNavbar + campsiteConfig.barsHeight.forecastBar
        : campsiteConfig.barsHeight.appNavbar
      return 'calc(100vh - ' + offsetHeight + 'px)'
    },
    mapGeoTargets () {
      return this.geoTargetsWithGeoJson
        .filter(geoTarget => geoTarget.type !== 'point_of_interest')
        .map(geoTarget => this.convertGeoTargetToMapFormat(geoTarget))
    },
    focusedGeoInfo () {
      return this.$store.getters['audience/getFocusedGeoInfo']
    },
    highlightedGeoTarget () {
      const focusedGeoTargetInfo = this.focusedGeoInfo
      return focusedGeoTargetInfo.index === -1 ? null : { geoTarget: this.selectedGeoTargets[focusedGeoTargetInfo.index], centerMap: focusedGeoTargetInfo.centerMap }
    },
    filterOnMove () {
      return {
        isVisible: this.isFilterOnMoveVisible,
        value: this.filterAsImove
      }
    },
    mapViewPort () {
      return this.$store.getters['audience/getMapViewPort']
    },
    mapBounds () {
      return this.$store.getters['audience/getMapBounds']
    }
  },

  watch: {
    mapBounds (newVal, oldVal) {
      if (oldVal === null) {
        this.updateForecast()
      }
      if (newVal) {
        this.updateForecastInventoryAndVenues()
      }
    },
    validDeals (newV, oldV) {
      if (newV.length) {
        if (this.canOverwrite.maxBid) {
          const sortedFloorCPMs = newV.map(x => x.floorCPM).sort()
          const largestDealFloorCPM = sortedFloorCPMs[sortedFloorCPMs.length - 1]
          if (this.$store.getters['createCampaign/getMaxCpm'] < largestDealFloorCPM && !this.$store.getters['audience/getUsePublicExchange']) {
            this.$store.commit('createCampaign/setMaxCpm', largestDealFloorCPM)
          }
        }
        if (this.canOverwrite.dates) {
          const dates = helperService.prefillWithDealDates(newV)
          if (dates) {
            this.$store.commit('createCampaign/setStartDate', dates.from)
            this.$store.commit('createCampaign/setEndDate', dates.to)
            if (dates.fromTime) this.$store.commit('createCampaign/setStartTime', dates.fromTime)
            if (dates.toTime) this.$store.commit('createCampaign/setEndTime', dates.toTime)
          }
        }
      }
    },
    async selectedGeoTargets () {
      await this.updateGeoTargetsJson(this.geoTargetUpdatedFromMap)
      this.geoTargetUpdatedFromMap = false
    },
    highlightedGeoTarget: {
      immediate: true,
      async handler (newValue) {
        let geoTargetForMap = null
        let disableRecentering = true
        if (newValue?.geoTarget) {
          const geoTargetWithGeojson = await geoService.resolveGeoJsonForGeoTarget(newValue.geoTarget)
          geoTargetForMap = this.convertGeoTargetToMapFormat(geoTargetWithGeojson)
          disableRecentering = !newValue.centerMap
        }
        this.disableRecentering = disableRecentering
        this.highlightedGeoTargetForMap = geoTargetForMap
      }
    }
  },
  methods: {
    async updateGeoTargetsJson (disableRecentering = false) {
      if (this.updateGeoTargetsJsonCT) {
        this.updateGeoTargetsJsonCT.cancel('Request canceled by a newer request.')
        this.updateGeoTargetsJsonCT = null
      }

      const { getUpdatedGeoTargets, cancelTokenSource } = geoBoundingBoxService.getGeoTargetsJsonUpdater(this.selectedGeoTargets, this.mapViewPort, disableRecentering)
      if (!getUpdatedGeoTargets) return

      this.updateGeoTargetsJsonCT = cancelTokenSource
      try {
        this.geoTargetsWithGeoJson = await getUpdatedGeoTargets()
        this.disableRecentering = disableRecentering
      } catch (e) {} finally {
        this.updateGeoTargetsJsonCT = null
      }
    },
    updateValidationStatus (isValid = true, field) {
      this.validationStatus = {
        isValid: isValid,
        field: field
      }
    },
    handlePanelError (panelInfo) {
      this.openPanel(panelInfo.panelName)
      this.scrollToElement(panelInfo.panelSelector)
      this.focusElement(panelInfo.fields?.[this.validationStatus.field])
      this.updateValidationStatus()

      this.isCreatingCampaign = false
      this.isCreatingProposal = false
      this.isCreatingAuctionPackage = false
    },
    scrollToElement (selector) {
      const element = document.querySelector(selector)
      if (element) {
        element.scrollIntoView()
      }
    },
    focusElement (selector) {
      const elementToFocus = document.querySelector(selector)
      if (elementToFocus) {
        elementToFocus.focus()
      }
    },
    async initializeDeals () {
      const dealPromises = this.dealIdsFromUrl.length
        ? this.dealIdsFromUrl.map(d => this.getDeal(d))
        : [
          this.$store.dispatch('createCampaign/loadOrganizationDeals', this.$store.getters['user/getProfile'].organizationId)
        ]

      return Promise.all(dealPromises).then(resp => {
        if (resp.length) {
          const dealsList = Array.isArray(resp[0])
            ? resp[0]
            : resp

          this.$store.commit('createCampaign/setDeals', dealsList)
        }
      })
    },
    getDeal (dealCode) {
      const organizationId = this.$store.getters['user/getProfile'].organizationId
      return audienceApi.getDeal(dealCode, organizationId)
        .then(deal => deal)
        .catch(err => {
          if (err.response.status === 404) {
            return helperService.generateUnknownDealObj(dealCode)
          }
        })
    },
    updateForecast: _.debounce(function (impressionsOnly = false) {
      if (this.canUpdateForecast) {
        this.updateForecastImpressions()
        if (!impressionsOnly) this.updateForecastInventoryAndVenues()
      }
    }, 300),
    updateForecastImpressions () {
      // TARGETED - update only if targeting has changed
      const currentForecastOptions = this.getCommonForecastOptions
      const isTargetingEqual = this.forecastOptionsLastVersion
        ? _.isEqual(this.forecastOptionsLastVersion, currentForecastOptions)
        : false

      if (!isTargetingEqual) {
        this.$store.dispatch('audience/updateForecast', currentForecastOptions)
        this.forecastOptionsLastVersion = JSON.parse(JSON.stringify(currentForecastOptions))
      }
    },
    updateForecastInventoryAndVenues () {
      if (this.canUpdateForecast) {
        this.updateForecastInventory()
        if (this.audiencePanelTab === 1 && this.filterAsImove) this.updateForecastVenues()
      }
    },
    updateForecastInventory () {
      this.$store.dispatch('audience/updateForecastInventory', this.getCommonForecastOptions)
    },

    updateForecastVenues () {
      this.$store.dispatch('audience/updateForecastVenues', this.getCommonForecastOptions)
    },

    isSameGeoTarget (geoTargetFromStore, geoTargetFromMap) {
      return geoTargetFromStore.value === geoTargetFromMap.geolocation.value &&
        geoTargetFromStore.label === geoTargetFromMap.geolocation.label &&
        geoTargetFromStore.radius === geoTargetFromMap.radius &&
        geoTargetFromStore.isIncluded === geoTargetFromMap.isIncluded
    },
    geoTargetSelected (geoTarget) {
      const index = this.selectedGeoTargets.findIndex(g => this.isSameGeoTarget(g, geoTarget))
      this.$store.commit('audience/setFocusedGeoInfo', { index: index, centerMap: false })
    },
    geoTargetUnselected () {
      this.$store.commit('audience/setFocusedGeoInfo', { index: -1, centerMap: false })
    },
    geoTargetAdded (geoTarget) {
      this.geoTargetUpdatedFromMap = true
      this.$store.dispatch('audience/updateGeoTargetsAction', [...this.selectedGeoTargets, this.convertGeoTargetToStoreFormat(geoTarget)])
      this.updateForecast()
    },
    geoTargetUpdated (updatedGeoTarget, mapIndex) {
      this.geoTargetUpdatedFromMap = true

      const initialUpdatedGeoTarget = this.mapGeoTargets[mapIndex]
      const storeIndex = this.selectedGeoTargets.findIndex(g => this.isSameGeoTarget(g, initialUpdatedGeoTarget))

      const updatedGeoTargets = this.selectedGeoTargets
      updatedGeoTargets[storeIndex] = this.convertGeoTargetToStoreFormat(updatedGeoTarget)

      this.$store.dispatch('audience/updateGeoTargetsAction', updatedGeoTargets)
      this.updateForecast()
    },
    geoTargetRemoved (geoTarget) {
      this.geoTargetUpdatedFromMap = true

      const updatedGeoTargets = this.selectedGeoTargets.filter(g => !this.isSameGeoTarget(g, geoTarget))

      this.$store.dispatch('audience/updateGeoTargetsAction', updatedGeoTargets)
      this.updateForecast()
    },
    convertGeoTargetToStoreFormat (geoTarget) {
      return {
        label: geoTarget.geolocation.label,
        type: geoTarget.geolocation.type,
        value: geoTarget.geolocation.value,
        geography: {
          latitude: geoTarget.geolocation.geography.latitude,
          longitude: geoTarget.geolocation.geography.longitude,
          geoJSON: geoTarget.geolocation.geography.geoJSON
        },
        radius: geoTarget.radius,
        isIncluded: geoTarget.isIncluded
      }
    },
    convertGeoTargetToMapFormat (geoTarget) {
      return {
        isIncluded: geoTarget.isIncluded,
        radius: geoTarget.radius,
        isUnrendered: geoTarget.isOutsideOfViewPort,
        geolocation: {
          label: geoTarget.label,
          value: geoTarget.value,
          type: geoTarget.type,
          geography: {
            latitude: geoTarget.geography?.latitude,
            longitude: geoTarget.geography?.longitude,
            geoJSON: geoTarget.geography?.geoJSON
          }
        }
      }
    },

    async mapBoundsUpdated (mapApiPoco) {
      this.$store.commit('audience/setMapBounds', mapApiPoco)
      this.$store.commit('audience/setMapViewPort', mapApiPoco)
      await this.updateGeoTargetsJson(true)

      const pagination = {}
      if (this.filterAsImove) {
        pagination.page = 1
      }
      if (this.pagination.filtering) {
        pagination.filtering = false
      }
      if (Object.keys(pagination).length) {
        this.$store.commit('audience/setPagination', pagination)
      }
    },
    addAuctionPackage () {
      this.$store.dispatch('auctionPackage/createAuctionPackage', this.$store.getters['createCampaign/hasMoment'])
        .then(data => {
          if (data) {
            const message = 'Auction package has been created'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: message
            })
            this.$router.push({ path: '' + data.id })
          }
        })
        .catch(error => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${error}`
          })
        })
        .finally(() => {
          this.isCreatingAuctionPackage = false
        })
    },
    async saveAsProposal () {
      if (this.$store.getters['createCampaign/getCreatives'].length) {
        this.saveAsProposalConfirmationOpen = true
      } else {
        await this.placeOrder('proposal')
      }
    },
    async placeOrder (type) {
      if (this.isOnAuctionPackagePage) {
        this.isCreatingAuctionPackage = true
        if (!this.allFormsForAuctionPackageAreValid) {
          this.openPanel('campaign')
          this.$root.$emit('validateAuctionPackageForm')
          window.scrollTo(0, this.$vuetify.breakpoint.xsOnly ? 375 : 0)
          this.isCreatingAuctionPackage = false
          return
        }
        if (this.$store.getters['createCampaign/hasMoment']) {
          if (!this.$store.getters['createCampaign/isMomentValid']) {
            this.scrollBackToWeatherMoment()
            return
          } else if (!this.$store.getters['createCampaign/momentCreated']) {
            this.$root.$emit('createMoment')
            return
          }
        }
        this.addAuctionPackage()
        return
      }

      if (!type) {
        type = this.isCreatingProposal ? 'proposal' : 'campaign'
      }

      type === 'proposal'
        ? this.isCreatingProposal = true
        : this.isCreatingCampaign = true

      if (this.isCreatingProposal) {
        this.$store.commit('creatives/resetCreatives')
        this.$store.commit('createCampaign/resetCreatives')
      }

      for (const panel of this.panelsToValidate) {
        const panelInfo = campaignValidationService.getCampaignPanelInfo(panel)
        this.$root.$emit(panelInfo.validationEmit)
        if (!this.validationStatus.isValid) {
          this.handlePanelError(panelInfo)
          return
        }
      }

      if (this.$store.getters['createCampaign/hasMoment']) {
        if (!this.$store.getters['createCampaign/isMomentValid']) {
          this.scrollBackToWeatherMoment()
          return
        } else if (!this.$store.getters['createCampaign/momentCreated']) {
          this.$root.$emit('createMoment')
          return
        }
      }
      // do a final check on "start Date & Time"
      // covers edge case where form is left open for a while around top of the hour
      this.$root.$emit('validateSchedule')
      if (!this.isScheduleValidated) {
        this.isCreatingCampaign = false
        this.isCreatingProposal = false
        return
      }

      this.$store.dispatch('createCampaign/createCampaign', type)
        .then(id => {
          if (id) {
            const message = `${this.isCreatingProposal ? this.proposalLabel : 'Campaign'} ${this.$store.getters['createCampaign/getCampaignName']} has been created`
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: message
            })

            if (this.isCreatingProposal) {
              const obj = {
                proposalId: id,
                budgeted: this.$store.getters['audience/forecastBudgeted']
              }
              this.$store.commit('proposals/saveImpressions', obj)
              this.$router.push({ name: this.proposalLabel, params: { id } })
            } else {
              this.$router.push({
                name: 'report-campaign',
                params: { campaignId: this.$store.getters['createCampaign/getOrderId'] }
              })
            }
          }
          tracking.sendEvent(['ga'], this.isCreatingProposal ? 'createProposal' : 'createdCampaign')
        })
        .catch(error => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${error}`
          })
        })
        .finally(() => {
          this.isCreatingProposal
            ? this.isCreatingProposal = false
            : this.isCreatingCampaign = false
        })
    },

    hideDetails () {
      this.isFilterOnMoveVisible = false
    },
    seeDetails () {
      this.openPanel('audience')
      this.$el.querySelector('#audience-panel').scrollIntoView()

      if (this.audiencePanelTab !== 1) {
        this.switchPanelAudienceTab(1)
      } else {
        this.isFilterOnMoveVisible = true
      }
    },
    filterOnMoveChecked (isChecked) {
      this.$store.commit('audience/setFilterAsImove', isChecked)
    },
    updateTableToMapSync (pagination) {
      // check if "filtering" BEFORE changing value in Store
      pagination.filtering = this.pagination.query !== pagination.query

      this.$store.commit('audience/setPagination', pagination)
      this.updateForecastVenues()

      if (this.pagination.filtering && !this.$vuetify.breakpoint.xsOnly) {
        this.updateForecastInventory()
      }
    },
    updateVenueInclusion (venue) {
      this.$store.dispatch('audience/updateVenueInclusion', venue)
    },

    switchPanelAudienceTab (tab) {
      this.isFilterOnMoveVisible = tab === 1
      this.audiencePanelTab = tab
      this.updateForecastVenues()
    },

    toggleView () {
      this.mapExists = false
      setTimeout(() => {
        this.mapExists = true
        this.showMapOnly = !this.showMapOnly
      }, 70)
    },

    getDownloadFileName (scope) {
      const lineName = this.$store.getters['createCampaign/getLineName']
      if (lineName) {
        return lineName
      }

      if (this.$store.getters['user/isForAdServer']) {
        switch (scope) {
          case 'faces':
            return 'ScreensExport'
          case 'venues':
            return 'VenuesExport'
        }
      }

      return 'line-inventory'
    },

    downloadInventory (scope) {
      this.downloadingInventory = true

      const options = this.getCommonForecastOptions
      audienceApi.getListTargetedInventory(options, scope)
        .then(res => {
          const name = this.getDownloadFileName(scope)
          csvService.validateAndExportCsv(res, name)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.downloadingInventory = false
        })
    },

    getVenueDetails (venueId) {
      return venuesAPI.getVenueDetails(venueId)
    },

    scrollBackToWeatherMoment () {
      this.openPanel('moments')

      const panelElement = document.querySelector('#moment-panel')
      const weatherElement = document.querySelector('#weather-moment')
      if (panelElement && weatherElement) {
        panelElement.scrollIntoView()
        this.$root.$emit('validateMoment')
      }

      this.isCreatingCampaign = false
      this.isCreatingProposal = false
      this.isCreatingAuctionPackage = false
    },

    momentsError (err) {
      let msg = 'Moments: '

      if (err && err.title) {
        msg += err.title
      } else {
        msg += 'Unknown error'
      }

      this.$store.commit('snackbar/setSnackbar', { type: 'error', msg })

      this.isCreatingCampaign = false
      this.isCreatingProposal = false
    },
    isPanelOpened (panelName) {
      const i = this.panelsList.indexOf(panelName)
      return i > -1
        ? this.panelsStateController.includes(i)
        : false
    },
    openPanel (panelName) {
      if (panelName === 'campaign') {
        this.panelsCampaignModel = 0
      }

      if (!this.isPanelOpened(panelName)) {
        const i = this.panelsList.indexOf(panelName)
        if (i > -1) {
          this.panelsStateController.push(i)
        }
      }
    },
    openDefaultPanels () {
      if (this.isOnAuctionPackagePage) {
        this.panelsStateController.push(0)
      }

      const openedByDefaultNames = [
        ...this.componentConfig.campaigns.isDealsShown && this.dealIdsFromUrl.length ? ['inventorySource'] : [],
        ...this.componentConfig.campaigns.isGoalsShown ? ['lineGoal'] : [],
        ...this.componentConfig.campaigns.isLinePriorityShown ? ['linePriority'] : [],
        'audience',
        'moments',
        'budget',
        ...!this.isOnAuctionPackagePage && !this.isUserOrgDspPartner ? [] : ['creatives']
      ]

      openedByDefaultNames.map(panelName => {
        this.openPanel(panelName)
      })
    },
    async resolveGeoJson (geographyId, geoJSONFileUrl) {
      return await geoService.resolveGeoJson(geographyId, geoJSONFileUrl)
    }
  }
}

</script>

<style scoped lang="stylus">
.paddingXless >>> .v-expansion-panel-content__wrap
  padding-left: 0px
  padding-right: 0px
</style>
