<template lang="pug">
  div(style='width:112px;')
    a.d-block.pa-0.text-center(@click='dialog = true' :class="{'my-2': $vuetify.breakpoint.mdAndUp}")
      video(
        v-if='this.creativeInfos.file && isVideo'
        :src='getFullURL'
        style="min-width: 63px; max-width: 112px; max-height: 112px; min-height: 63px;"
      ) Your browser does not support the video tag.
      v-img(
        v-else
        contain
        min-width='63'
        max-width='112'
        max-height='112'
        min-height='63'
        :src='getThumbURL'
      )
        v-layout(slot='placeholder' fill-height align-center justify-center ma-0)
          v-progress-circular(indeterminate color='grey lighten-2')

    v-dialog(v-if="!(parent && parent === 'reportCreativesHover')" v-model='dialog' max-width='1200' :fullscreen='$vuetify.breakpoint.smAndDown')
      v-card(dark color='black')
        v-btn(icon fab dark color='white' @click='dialog = false' style='position:absolute; right:8px; top:8px; z-index:100;')
          v-icon(dark medium color='grey') mdi-close

        video(
          id='modalVideoPlayer'
          ref='modalVideoPlayer'
          v-if='isVideo'
          :src='getFullURL'
          controls
          height='600'
          autoplay
          loop
        ) Your browser does not support the video tag.
        v-img(
          v-if='!isVideo'
          contain
          max-height='600'
          :src='getFullURL'
        )
          v-layout(slot='placeholder' fill-height align-center justify-center ma-0)
            v-progress-circular(indeterminate color='grey lighten-5')
</template>
<script>
import creativeApi from '@/services/creatives.api'

export default {
  props: ['creative', 'parent'],
  data () {
    return {
      dialog: false,
      imageLoading: true,
      thumbURL: null,
      fullURL: null,
      creativeInfos: {}
    }
  },
  computed: {
    getThumbURL () {
      return this.thumbURL || ''
    },
    getFullURL () {
      return this.fullURL || ''
    },
    isVideo () {
      return this.creativeInfos.type === 'Video'
    }
  },
  created: function () {
    this.creativeInfos = this.creative

    this.init()
  },
  methods: {
    init () {
      // uploaded Creatives will have the "file" attached, use it for preview
      if (this.creative.file) {
        var tmpURL = URL.createObjectURL(this.creative.file)
        this.thumbURL = tmpURL
        this.fullURL = tmpURL

        this.imageLoading = false
      } else {
        // do we need to get Creative's infos?
        // i.e. in Campaign Report's Data Table, we only receive creative's ID (key)
        var creativePromise = this.creative.key
          ? creativeApi.getCreative(parseInt(this.creative.key))
          : new Promise((resolve, reject) => {
            resolve(this.creative)
          })

        creativePromise.then(creative => {
          this.creativeInfos = creative
          this.fullURL = creative.creativeURL

          if (creative.creativeFormat) {
            const isLandscape = creative.creativeFormat.minWidth > creative.creativeFormat.minHeight
            const dimensions = isLandscape ? '112x0' : '0x112'

            this.$store.dispatch('creatives/getCreativeThumbnail', [creative.id, dimensions])
              .then(thumbURL => {
                if (thumbURL) {
                  this.thumbURL = thumbURL
                  this.imageLoading = false
                }
              })
          }
        })
      }
    }
  },
  watch: {
    dialog: function (opened) {
      if (!opened && this.$refs.modalVideoPlayer) {
        this.$refs.modalVideoPlayer.pause()
      }
    },
    creative: function (newVal, oldVal) {
      if (oldVal.id !== newVal.id) {
        this.init()
      }
    }
  },
  beforeDestroy: function () {
    // remove uploaded Creatives TMP image
    if (this.creative.file && this.thumbURL) {
      URL.revokeObjectURL(this.thumbURL)
    }
  }
}
</script>
<style scoped lang="stylus">
#modalVideoPlayer
  width: 100%
</style>
