<template lang="pug">
  v-expansion-panel#budget-panel
    v-expansion-panel-header.text-h6 Line Budget &amp; Schedule
    v-expansion-panel-content(ref='budget-panel-content')
      .pt-0
        scheduleSection(
          @storeUpdated='$emit("updateForecast")'
          v-bind:host="{ name: 'createCampaign' }"
          :parent='parent'
          :isProposalLine='isProposalLine'
          :instance='instance'
        )
        v-layout.py-0(row wrap)
          v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
            .text-body-1.font-weight-bold.mr-3 Budget
          v-flex(xs12 lg9 xl8 text-md-left)
            v-radio-group.mt-0(v-model='selectedBudgetOption' @change='' hide-details)
              v-radio#radio-option-budget(label='Specify CPM by budget' :value='budgetOptions.budget' color='primary')
              v-radio#radio-option-cpm(label='Specify budget by CPM' :value='budgetOptions.cpm' color='primary')
        v-layout.py-3(row wrap)
          v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
          v-flex(xs12 lg9 xl8 text-md-left)
            v-form(ref='budgetForm' onSubmit='return false;')
              formattedNumberInput.pb-2.pt-0.mt-0#budget-field(
                label= 'Budget'
                hide-details
                style='max-width:350px'
                type='noDecimals'
                valueType='number'
                :disabled='selectedBudgetOption == budgetOptions.cpm'
                :prefix='marketVals.currencySymbolString'
                :rules='[rules.required, rules.strictPositive, rules.validCpm]'
                :value='budget'
                @input='onBudgetChange'
              )
              .text-caption.d-block#budget-caption(:class = "budgetInputCaptionColor(isCpmGreaterThanLimit(budget))")
                | {{ budgetInputCaption }}
        v-layout.py-0(row wrap)
          v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
          v-flex(xs12 lg9 xl8 text-md-left)
            v-form(ref='cpmForm' onSubmit='return false;')
              formattedNumberInput.pb-2.pt-0.mt-0#cpm-field(
                ref='cpm-formatted-number-input'
                :label= 'cpmInputLabel'
                hide-details
                style='max-width:350px'
                type='currency'
                valueType='number'
                :disabled='selectedBudgetOption == budgetOptions.budget'
                :prefix='marketVals.currencySymbolString'
                :rules='[rules.required, rules.strictPositive]'
                :value='cpmForInput'
                @input='onCPMChange'
              )
              .text-caption.d-block
                | {{ cpmInputCaption }}
</template>

<script>
import scheduleSection from '@/components/audience.schedule.vue'
import formattedNumberInput from '@/components/formattedNumberInput.vue'

export default {
  components: {
    formattedNumberInput,
    scheduleSection
  },
  props: ['parent', 'isProposalLine', 'instance'],
  mounted () {
    this.$root.$on('validateBudgetAndSchedule', () => {
      const fieldRef = this.selectedBudgetOption === this.budgetOptions.budget
        ? this.$refs.budgetForm
        : this.$refs.cpmForm

      this.$emit('formValidationUpdated', fieldRef?.validate(), this.selectedBudgetOption)
    })
  },
  data () {
    return {
      budgetOptions: Object.freeze({
        budget: 'budget',
        cpm: 'cpm'
      }),
      rules: {
        required: v => !!v || v === 0 || 'Required',
        strictPositive: v => v > 0 || 'Positive numbers only',
        validCpm: v => this.isCpmGreaterThanLimit(v)
      },
      selectedBudgetOption: 'budget'
    }
  },
  computed: {
    budget () {
      return this.$store.getters['createCampaign/getBudget']
    },
    cpm () {
      return this.$store.getters['createCampaign/getMaxCpm']
    },
    cpmTruncated () {
      return parseFloat((this.cpm ?? 0).toFixed(2))
    },
    cpmForInput () {
      if (this.cpm === null) return
      return this.cpmTruncated
    },
    budgetInputCaption () {
      switch (this.selectedBudgetOption) {
        case this.budgetOptions.budget:
          if (this.isCpmGreaterThanLimit(this.budget)) {
            return 'Total budget will determine fixed CPM value'
          } else {
            return 'Budget is too low. It must correspond to a CPM greater than or equal to 0.01'
          }
        case this.budgetOptions.cpm:
          return 'Total budget = (CPM * targeted impressions) / 1000'
        default:
          return ''
      }
    },
    cpmInputCaption () {
      switch (this.selectedBudgetOption) {
        case this.budgetOptions.budget:
          return 'Fixed CPM = (total budget / targeted impressions) * 1000'
        case this.budgetOptions.cpm:
          return 'CPM value will determine total budget'
        default:
          return ''
      }
    },
    cpmInputLabel () {
      return this.selectedBudgetOption === this.budgetOptions.budget ? 'Fixed CPM' : 'CPM'
    },
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    targetedImpressions () {
      return this.$store.getters['createCampaign/getTargetNumberOfImpressions']
    }
  },
  watch: {
    targetedImpressions () {
      if (this.selectedBudgetOption === this.budgetOptions.budget) {
        const budget = this.$store.getters['createCampaign/getBudget']
        if (this.isBudgetValid(budget) && this.isTargetedImpressionsValid()) {
          this.calculateAndSetMaxCpm(budget)

          if (this.$refs.budgetForm) {
            this.$refs.budgetForm.validate()
          }
        } else {
          this.resetMaxCpm()
        }
      } else if (this.selectedBudgetOption === this.budgetOptions.cpm) {
        const cpm = this.$store.getters['createCampaign/getMaxCpm']
        if (this.isCPMValid(cpm) && this.isTargetedImpressionsValid()) {
          this.calculateAndSetBudget(cpm)
        } else {
          this.resetBudget()
        }
      }
    }
  },
  methods: {
    isCpmGreaterThanLimit (budget) {
      if (this.isBudgetValid(budget) && this.isTargetedImpressionsValid() && this.selectedBudgetOption === this.budgetOptions.budget) {
        const cpm = (budget / this.targetedImpressions * 1000)
        const cpmTruncated = cpm.toFixed(2)
        return cpmTruncated >= 0.01 || !budget
      }
      return true
    },
    budgetInputCaptionColor (isValidCpm) {
      return isValidCpm ? 'text-caption' : 'error--text'
    },
    onBudgetChange: function (budget) {
      this.$store.commit('createCampaign/setBudget', budget)
      if (this.isBudgetValid(budget) && this.isTargetedImpressionsValid()) {
        this.calculateAndSetMaxCpm(budget)
      } else {
        this.resetMaxCpm()
      }
    },
    onCPMChange: function (cpm = null) {
      this.$store.commit('createCampaign/setMaxCpm', cpm)
      if (this.isCPMValid(cpm) && this.isTargetedImpressionsValid()) {
        this.calculateAndSetBudget(cpm)
      } else {
        this.resetBudget()
      }
    },
    calculateAndSetMaxCpm (budget) {
      const cpm = budget / this.targetedImpressions * 1000
      const roundedCpm = parseFloat(cpm.toFixed(2))
      this.$store.commit('createCampaign/setMaxCpm', roundedCpm)
    },
    calculateAndSetBudget (cpm) {
      const budget = cpm * this.targetedImpressions / 1000
      this.$store.commit('createCampaign/setBudget', budget)
    },
    isBudgetValid (budget) {
      return budget >= 0
    },
    isCPMValid (cpm) {
      return cpm >= 0
    },
    isTargetedImpressionsValid () {
      return this.targetedImpressions && this.targetedImpressions > 0
    },
    resetBudget () {
      this.$store.commit('createCampaign/setBudget', 0)
    },
    resetMaxCpm () {
      this.$store.commit('createCampaign/setMaxCpm', 0)
    }
  }
}
</script>
