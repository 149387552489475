import accounting from 'accounting-js'
import notificationsAPI from '@/services/notifications.api'
import moment from 'moment'
import notificationsConfig from '@/config/notifications.config'

export default {
  namespaced: true,
  state: {
    loading: false,
    listening: false,
    notifications: [],
    unseenNotifications: 0,
    support: notificationsConfig.supportedNotificationTypes
  },
  getters: {
    isLoading: state => {
      return state.loading
    },
    isListening: state => {
      return state.listening
    },
    getNotifications: state => {
      return state.notifications
    },
    getSupport: state => {
      return state.support
    },
    getNumUnseenNotifications: state => {
      return state.unseenNotifications
    }
  },
  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading
    },
    setListening: (state, listening) => {
      state.listening = listening
    },
    setNotifications: (state, notifications) => {
      state.notifications = notifications
    },
    increaseUnseen: (state) => {
      state.unseenNotifications += 1
    },
    setUnseen: (state, num) => {
      state.unseenNotifications = num
    }
  },
  actions: {
    setSeenNotifications: ({ dispatch, commit, getters }) => {
      const seenNotifications = getters.getNotifications.map(notification => {
        return {
          id: notification.id,
          seen: true
        }
      })

      return notificationsAPI.setSeenNotifications(seenNotifications).then(resp => {
        commit('setUnseen', 0)
      })
    },

    listenForNotifications: ({ dispatch, commit, getters }) => {
      commit('setListening', true)

      setTimeout(() => {
        dispatch('getNotifications', 10)
        dispatch('listenForNotifications')
      }, 60000) // each minute
    },

    getNotifications ({ dispatch, commit, getters }, num) {
      commit('setLoading', true)

      return notificationsAPI.getNotifications(num).then(notifications => {
        commit('setNotifications', formatNotifications(notifications))
        commit('setLoading', false)

        if (!getters.isListening) {
          dispatch('listenForNotifications')
        }
      })

      function formatNotifications (notifications) {
        const supportDatas = getters.getSupport
        const currentNotifications = getters.getNotifications

        return notifications
          .map(notification => {
            // load object from json string
            notification.data = JSON.parse(notification.json)

            // attach Support data
            const supportData = supportDatas.find(supportNotification => supportNotification.key === notification.type)

            if (supportData) {
              // notification.body = notificationsService.replace_tokens(supportData.body, notification)
              notification.body = replaceTokens(supportData.body, notification)
            }

            // count only new notifications
            if (!notification.seen && !currentNotifications.find(n => n.id === notification.id)) {
              commit('increaseUnseen')
            }

            return notification
          })
      }
    },

    getHistory ({ getters }, notifications) {
      const supportData = getters.getSupport
      return formatNotifs(notifications, supportData)
    }
  }
}

function formatNotifs (notifications, supportData) {
  return notifications
    .map(notification => {
      // load object from json string
      notification.data = JSON.parse(notification.json)

      // attach Support data
      const sprtData = supportData.find(supportNotification => supportNotification.key === notification.type)
      if (sprtData) {
        notification.body = replaceTokens(sprtData.body, notification)
      }

      return notification
    })
}

function formatBudget (value) {
  if (value === null) return ''
  return accounting.formatMoney(value)
}

function replaceTokens (str, notification) {
  const data = notification.data

  let tokens = {}

  switch (notification.type) {
    case 'OrderCreated':
    case 'CampaignCreated':
    case 'OrderStatusChanged':
    case 'OrderNameChanged':
      tokens = {
        '[campaignName]': data.object.name,
        '[campaignStatus]': data.object.status,
        '[campaignNameOld]': data.providerData.oldValue
      }
      break

    case 'LineTargetingChanged':
    case 'LineCreated':
    case 'LineNameChanged':
    case 'LineStarts':
    case 'LineEnds':
      var line
      if (data.object.order && data.object.order.lines) {
        line = data.object.order.lines[0]
      } else {
        line = data.object.lines[0]
      }

      tokens = {
        '[campaignName]': data.object.name,
        '[lineOrderName]': line.name,
        '[lineOrderNameOld]': data.providerData.oldValue,
        '[lineOrderStatus]': line.status
      }
      break

    case 'LineBudgetChanged':
      tokens = {
        '[lineOrderName]': data.object.lines[0].name,
        '[lineOrderBudget]': formatBudget(data.object.lines[0].budget),
        '[lineOrderBudgetOld]': formatBudget(parseInt(data.providerData.oldValue))
      }
      break

    case 'LineEndDateChanged':
      tokens = {
        '[lineOrderName]': data.object.lines[0].name,
        '[lineOrderEndDate]': moment(data.object.lines[0].endDate).format('MMM Do, YYYY'),
        '[lineOrderEndDateOld]': moment(data.providerData.oldValue, 'M/DD/YYYY h:mm:ss A ±hh:mm').format('MMM Do, YYYY') // 4/18/2017 4:00:00 PM +00:00
      }
      break

    case 'LineStartDateChanged':
      tokens = {
        '[lineOrderName]': data.object.lines[0].name,
        '[lineOrderStartDate]': moment(data.object.lines[0].startDate).format('MMM Do, YYYY'),
        '[lineOrderStartDateOld]': moment(data.providerData.oldValue, 'M/DD/YYYY h:mm:ss A ±hh:mm').format('MMM Do, YYYY')
      }
      break

    case 'LineStatusChanged':
      tokens = {
        '[lineOrderName]': data.object.lines[0].name,
        '[lineOrderStatus]': data.object.lines[0].status
      }
      break

    case 'CreativeApprovalRequired':
    case 'CreativeStatusChanged':
    case 'CreativeUploaded':
      tokens = {
        '[creativeName]': data.providerData.creativeName,
        '[creativeStatus]': data.object.status,
        '[advertiserName]': data.providerData.advertiserName
      }
      break

    case 'CreativeAssigned':
      tokens = {
        '[creativeName]': data.providerData.creativeName,
        '[advertiserName]': data.providerData.advertiserName,
        '[lineOrderName]': data.object.lines[0].name
      }
      break

    case 'AudienceCreated':
    case 'AudienceTargetingChanged':
      tokens = {
        '[audienceName]': data.object.name
      }
      break

    case 'SendUserInvitation':
      tokens = {
        '[userName]': data.object.firstName + ' ' + data.object.lastName,
        '[userRole]': data.providerData.userRoles.join(','),
        '[userEmail]': data.object.email,
        '[organizationName]': data.providerData.organizationName
      }
      break

    case 'UserInvitationAccepted':
      tokens = {
        '[userName]': data.object.firstName + ' ' + data.object.lastName,
        '[organizationName]': data.providerData.organizationName
      }
      break

    case 'UserRoleUpdated':
      tokens = {
        '[userName]': data.object.firstName + ' ' + data.object.lastName,
        '[userRole]': data.object.roles.join(',')
      }
      break

    case 'OrganizationInfosChanged':
    case 'OrganizationContactChanged':
      tokens = {
        '[organizationName]': data.organizationName
      }
      break

    case 'InvoiceCreated':

      tokens = {
        '[invoiceId]': data.object.invoice ? (data.object.invoice.invoiceNumber ? data.object.invoice.invoiceNumber : data.object.invoice.id) : data.object.id,
        '[organizationName]': data.organizationName
      }
      break

    case 'InvoicePaymentFailed':
    case 'InvoicePaymentSucceeded':
    case 'InvoicePaymentDueInSevenDays':
    case 'InvoicePaymentPastDue':
      tokens = {
        '[invoiceId]': data.object.invoice ? (data.object.invoice.invoiceNumber ? data.object.invoice.invoiceNumber : data.object.invoice.id) : data.object.id
      }
      break

    case 'CreativeDeliveringInSupplierNetwork':
      tokens = {
        '[creativeName]': data.object.creativeName,
        '[supplierName]': data.organizationName,
        '[supplierNetworkName]': data.object.supplierNetworkName
      }
      break

    case 'AuctionPackageStartDateChanged':
      tokens = {
        '[auctionPackageName]': data.object.name,
        '[auctionPackageStartDate]': moment(data.object.startDate).format('MMM Do, YYYY'),
        '[auctionPackageStartDateOld]': moment(data.providerData.oldValue, 'M/DD/YYYY h:mm:ss A ±hh:mm').format('MMM Do, YYYY')
      }
      break

    case 'AuctionPackageEndDateChanged':
      tokens = {
        '[auctionPackageName]': data.object.name,
        '[auctionPackageEndDate]': moment(data.object.endDate).format('MMM Do, YYYY'),
        '[auctionPackageEndDateOld]': moment(data.providerData.oldValue, 'M/DD/YYYY h:mm:ss A ±hh:mm').format('MMM Do, YYYY')
      }
      break

    case 'AuctionPackageSetToAlwaysOn':
      tokens = { '[auctionPackageName]': data.object.name }
      break

    case 'LinePriorityChanged':
      tokens = {
        '[lineOrderName]': data.object.lines[0].name,
        '[updatedPriority]': data.object.lines[0].priority?.name
      }
      break
  }

  var links = {
    '[campaignName]': {
      href: (data.object.status === 'Proposal' ? '/proposals/' : '/campaigns/') + (data.object.orderId ? data.object.orderId : data.object.id),
      title: `View ${data.object.status === 'Proposal' ? 'proposal' : 'campaign'} report`
    },
    '[audienceName]': {
      href: '/audiences/' + data.object.audienceId,
      title: 'View audience'
    },
    '[creativeName]': {
      title: 'View creative'
    },
    '[invoiceId]': {
      href: '/billing/' + (data.object.invoice ? data.object.invoice.id : data.object.id),
      title: 'View Invoice'
    }
  }

  // Organization Name :: link to Organization Edit page
  if (notification.type === 'OrganizationInfosChanged') {
    links['[organizationName]'] = {
      href: '/organizations/' + data.object.id,
      title: 'View Organization infos'
    }
  }

  // Creative ID
  if (data.object.creativeId || data.providerData.creativeId) {
    links['[creativeName]'].href = '/creatives/' + (data.object.creativeId || data.providerData.creativeId)
  }
  if (data.object.creatives && data.object.creatives[0].creativeId) {
    links['[creativeName]'].href = '/creatives/' + data.object.creatives[0].creativeId
  }

  // Line Order
  if (data.object.lines) {
    links['[lineOrderName]'] = {
      href: data.object.status === 'Proposal' ? '/proposals/' + data.object.orderId : '/campaigns/' + data.object.orderId + '/lines/' + data.object.lines[0].lineId,
      title: 'View Line Report'
    }
  }

  // Line Order
  if (data.object.order && data.object.order.lines) {
    links['[lineOrderName]'] = {
      href: data.object.order.status === 'Proposal' ? '/proposals/' + data.object.order.orderId : '/campaigns/' + data.object.order.orderId + '/lines/' + data.object.order.lines[0].lineId,
      title: 'View Line Report'
    }
  }

  // User
  if (data.object.userId) {
    links['[userName]'] = {
      href: '/users/' + data.object.userId,
      title: 'View user profile'
    }
  }

  if (data.object.status === 'Proposal' || (data.object.order && data.object.order.status === 'Proposal')) {
    str = str.replace('Campaign ', 'Proposal ').replace(' campaign ', ' proposal ')
  }

  return str.replace(/\[(.*?)\]/g, (token, innerText) => {
    const tokenized = tokens[token] === undefined ? '<span style="color:red">' + innerText + '</span>' : tokens[token]
    const linked = links[token] === undefined ? '<b>' + tokenized + '</b>' : '<a href="' + links[token].href + '" title="' + links[token].title + '">' + tokenized + '</a>'
    return linked
  })
}
