<template lang="pug">
  v-container.mt-3(grid-list-lg text-left)
    vue-headful(:title="componentConfig.branding.title('Add Creatives')")
    v-layout(row wrap)
      v-flex(xs12 md12 lg10 xl8 offset-lg1 offset-xl2)
        v-card(light)
          v-card-title
            div
              div.text-h5 Add Creatives
              div.text-body-2
                a.info--text.upload-creatives-link(:href='componentConfig.links.uploadCreatives' target='_blank')
                  span Learn more about creative upload, specifications and best practices
                  v-icon(small color='info') mdi-launch

          v-container(grid-list-lg)
            v-layout(row wrap)
              v-flex(sm12 md8 lg8 offset-md2)

                v-layout(row wrap justify-start)

                  v-flex(sm12 md4 lg4)
                    v-combobox(
                      v-model='requiredInfos.advertiser'
                      :items="$store.getters['general/getAdvertisers']"
                      item-text='name'
                      return-object
                      label='Advertiser'
                      flat
                      dense
                      @input='prefillIndustry'
                      :menu-props='{maxHeight: 400}'
                      )
                      template(slot='no-data')
                        v-list-item
                          v-list-item-title This advertiser will be created

                  v-flex(sm12 md4 lg4)
                    v-autocomplete(
                      v-model='requiredInfos.industry'
                      :items="$store.getters['general/getIndustries']"
                      item-text='name'
                      return-object
                      label='Industry'
                      flat
                      dense
                      :menu-props='{maxHeight: 400}'
                    )
                      template(slot='no-data')
                        v-list-item
                          v-list-item-title No industry found

                  v-flex(sm12 md4 lg4)
                    v-combobox(
                      label='Default Creative\'s Language'
                      dense
                      v-model='requiredInfos.language'
                      :items='availableLanguages'
                      @input='changeLanguageForAll()'
                    )

                //- Upload Creatives Box
                creativesUploader.mt-2(:upload-data='languageSymbol')

          //- Uploaded Creatives Table
          assignedCreativesTable(
            parent='addCreatives'
            :full-table='true'
            @removeCreative='removeCreative'
          )

          //- Help & Info Section
          creativeInfos.mx-4

        .text-md-right
          v-spacer
          v-btn.my-3(id='add-creatives-confirm-button' rounded large color='primary' :loading='btnLoading' :disabled='disableButton' @click='submit') {{componentConfig.creatives.submitButtonText}}
</template>

<style scoped>
.draganddrop {
  border: 3px dashed rgba(0,0,0,0.24);
  border-radius: 3px;
  text-align:center;
}
</style>

<script>
import creativesUploader from '@/components/creativesUploader.vue'
import assignedCreativesTable from '@/components/assignedCreativesTable.vue'
import creativeInfos from '@/components/creativeFooterInfos.vue'
import userApi from '@/services/user.api'
import componentConfigService from '@/services/componentConfig'

export default {
  name: 'AddCreatives',
  props: {
    msg: String
  },
  components: {
    creativesUploader,
    assignedCreativesTable,
    creativeInfos
  },
  beforeDestroy: function () {
    this.$store.commit('creatives/resetCreatives')
  },
  computed: {
    disableButton () {
      const minCondition = !this.requiredInfos.advertiser || !this.requiredInfos.industry || !this.addedCreatives.length
      const allCreativesInvalid = !this.addedCreatives.map(x => !x.error).includes(true)
      if (minCondition || allCreativesInvalid) {
        return true
      } else {
        return false
      }
    },
    addedCreatives () {
      return this.$store.getters['creatives/AddedCreatives']
    },
    languageSymbol () {
      switch (this.requiredInfos.language) {
        case 'French': return [{ name: 'language', value: 'FR' }]
        default: return [{ name: 'language', value: 'EN' }]
      }
    },
    organizationId () {
      const organization = this.$store.getters['user/getOrganization']
      return organization ? organization.id : null
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  data () {
    return {
      availableLanguages: ['English', 'French'],
      requiredInfos: {
        advertiser: null,
        industry: null,
        language: 'English'
      },
      btnLoading: false
    }
  },
  methods: {
    prefillIndustry () {
      this.checkDuplicateAdvertiser()
      if (this.requiredInfos.advertiser && this.requiredInfos.advertiser.advertiserId) {
        userApi.getOrganization(this.requiredInfos.advertiser.advertiserId).then(res => {
          const filteredIndustry = this.$store.getters['general/getIndustries'].find(x => x.name === res.industry)
          if (filteredIndustry) {
            this.$set(this.requiredInfos, 'industry', filteredIndustry)
          }
        })
      } else {
        this.$set(this.requiredInfos, 'industry', null)
      }
    },
    checkDuplicateAdvertiser () {
      if (this.requiredInfos.advertiser && typeof this.requiredInfos.advertiser === 'string') {
        const advertiserFromList = this.$store.getters['general/getAdvertisers'].find(x => x.name === this.requiredInfos.advertiser)
        if (advertiserFromList) this.requiredInfos.advertiser = advertiserFromList
      }
    },
    changeLanguageForAll () {
      if (this.addedCreatives.length) {
        this.addedCreatives.map(creative => {
          const creativePatch = {
            id: creative.id,
            language: this.languageSymbol[0].value
          }
          this.$store.commit('creatives/setCreative', creativePatch)
        })
      }
    },
    submit () {
      this.btnLoading = true
      this.$store.dispatch('creatives/saveAddedCreatives', this.requiredInfos).then(res => {
        this.btnLoading = false
        this.$router.push('/creatives')
        this.$store.commit('creatives/resetCreatives')
      })
    },
    removeCreative (creative) {
      this.$store.commit('creatives/removeCreative', creative.id)
    }
  }
}
</script>
