<template lang="pug">
  div
    v-layout#weather-moment(row wrap)
      v-flex(xs12 lg3 xl4 text-xl-right)
        .text-body-1.font-weight-bold.mr-3 Weather
      v-flex(xs12 lg9 xl8 text-md-left)
        .subtitle.pl-0.pt-1.pb-3
          .text-body-2 Sync the line delivery with weather conditions
        weather(
          :temperature-scale='temperatureScale'
          :weather='moment.weather'
          :authToken='authToken'
          @onEvent='onEvent'
          :postEvent='postEvent'
          )

    v-layout#finance-moment(row wrap)
      v-flex(xs12 lg3 xl4 text-xl-right)
        .text-body-1.font-weight-bold.mr-3 Finance
      v-flex(xs12 lg9 xl8 text-md-left)
        finance(
          @onEvent='onEvent'
          :authToken='authToken'
          :finance='moment.finance'
          )
</template>
<script>
import weather from './weather.vue'
import finance from './finance.vue'

import services from './services'

export default {
  name: 'Moments',
  components: {
    weather,
    finance
  },
  props: {
    temperatureScale: {
      type: String,
      default: 'celsius'
    },
    postEvent: {
      type: Object
    },
    momentId: {
      type: String
    },
    buyerId: {
      type: Number
    },
    authToken: {
      type: String
    }
  },
  data () {
    return {
      guid: null,
      isMomentFetched: false,
      moment: {},
      modifiedMoment: {},
      isInvalid: null
    }
  },
  async mounted () {
    if (this.momentId) {
      await this.getMoment()
    } else {
      this.guid = services.generateGuid()
    }

    this.$emit('onEvent', {
      action: 'loaded',
      value: this.bundleMoment(this.moment)
    })
  },
  watch: {
    postEvent (newEvent) {
      if (newEvent) {
        if (newEvent.name === 'create moment') {
          this.createMoment()
        } else if (newEvent.name === 'update moment') {
          this.updateMoment()
        }
      }
    },
    momentId (newId) {
      if (newId) {
        this.getMoment()
      }
    }
  },
  methods: {
    getMoment () {
      this.isMomentFetched = false
      return services.getMoment(this.momentId, this.authToken)
        .then(moment => {
          if (moment && moment.definition) this.moment = moment.definition
          return moment
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isMomentFetched = true
        })
    },

    bundleMoment (mmt = null) {
      return {
        id: this.momentId || this.guid,
        definition: mmt || this.modifiedMoment
      }
    },

    createMoment () {
      const data = this.bundleMoment()
      if (this.buyerId) data.organisationId = this.buyerId.toString()

      services.createMoment(data, this.authToken)
        .then(resp => {
          if (resp.status === 200) {
            this.$emit('onEvent', { action: 'created' })
          }
        })
        .catch(err => {
          this.$emit('onEvent', { action: 'error', value: err.response.data })
        })
    },

    updateMoment () {
      const data = this.bundleMoment()

      services.updateMoment(data, this.authToken)
        .then(resp => {
          if (resp.status === 200) {
            this.$emit('onEvent', { action: 'updated' })
          }
        })
        .catch(err => {
          this.$emit('onEvent', { action: 'error', value: err.response.data })
        })
    },

    updateValidity (event) {
      if (event.isInvalid) {
        if (this.isInvalid) this.isInvalid[event.category] = event.isInvalid
        else {
          this.isInvalid = {}
          this.isInvalid[event.category] = event.isInvalid
        }
      } else {
        if (this.isInvalid && this.isInvalid[event.category]) delete this.isInvalid[event.category]
      }
      if (this.isInvalid && !Object.keys(this.isInvalid).length) this.isInvalid = null
    },

    onEvent (event) {
      if (event && event.action === 'dataChanged') {
        this.modifiedMoment[event.category] = event.value
        this.updateValidity(event)
        this.$emit('onEvent', {
          action: 'dataChanged',
          category: event.category,
          subCategory: event.subCategory,
          moment: this.bundleMoment(),
          isInvalid: this.isInvalid
        })
      }
      if (event && ['opened', 'closed'].includes(event.action)) {
        this.$emit('onEvent', event)
      }
    }
  }
}
</script>
