const venuePopover = {
  width: 800,
  height: 400
}

const organizationIdBroadsignPublicCatalog = 9540

const creatives = {
  acceptedCreativeFormats: {
    indoor: 'image/*, video/*',
    'united-state': 'image/*, video/*'
  },
  acceptedFileTypes: {
    indoor: '.mp4,.webm,.wmv,.mov,.jpg,.jpeg,.png',
    'united-state': '.mp4,.webm,.wmv,.mov,.jpg,.jpeg,.png'
  },
  durationOptions: [10, 15, 30],
  defaultDuration: {
    image: 15
  }
}

const barsHeight = {
  appNavbar: 48,
  forecastBar: 44
}

const rejectedEmailDomains = [
  'aol.com',
  'gmail.com',
  'hotmail.com',
  'icloud.com',
  'live.ca',
  'live.com',
  'mail.ru',
  'me.com',
  'msn.com',
  'outlook.com',
  'qq.com',
  'test.com',
  'yahoo.ca',
  'yahoo.com',
  'ymail.com',
  'yopmail.com'
]

export default {
  venuePopover,
  creatives,
  barsHeight,
  rejectedEmailDomains,
  organizationIdBroadsignPublicCatalog
}
