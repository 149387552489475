<template lang="pug">
.home
  vue-headful(:title="componentConfig.branding.title('Manage Users')")
  organizationUsers(
    :organization-id='organizationIdToRestrictUsersTo'
    items-per-page='25'
    full-table='true'
    :can-update='can.update.default')
</template>

<script>
import organizationUsers from '@/components/organizationUsers'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    organizationUsers
  },
  computed: {
    profile () {
      return this.$store.getters['user/getProfile']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    can () {
      return this.$store.getters['user/permissions']('user')
    },
    organizationIdToRestrictUsersTo () {
      return this.can.read.allUsers ? 0 : this.profile.organizationId
    }
  }
}
</script>
