<template lang='pug'>
  div(style='color: rgba(0, 0, 0, 0.87)')
    v-layout.py-0.px-6(row wrap)
      v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
        .text-body-1.font-weight-bold.market-label Private Deals
      v-flex(xs12 lg9 xl8 text-md-left)
        v-combobox#search-deals(
          clearable
          multiple
          no-filter
          ref='searchDeals'
          label='Enter one or multiple Deal ID'
          item-text='name'
          :items='searchItems'
          :loading='searching || dealsLoading'
          :search-input.sync='search'
          :value='selectedDeals'
          :attach='true'
          :menu-props='{ contentClass: "search-deals-results", minWidth: "min-content" }'
          @click:clear='clearSearch'
          @blur='clearSearch'
          @change='dealAdded')
        a#browse-deals-btn.text-uppercase.font-weight-bold(@click='toggleLoadDealsDialog(!isDealDialogOpen)') Browse deal
    inventoryDealsTable(:deals='deals' :dealsLoading='dealsLoading' :parent='"editLine"' @dealRemoved='dealRemoved')
</template>

<script>
import audienceApi from '@/services/audience.api'
import inventoryDealsTable from '@/components/inventoryDealsTable'
import trackingService from '@/services/tracking'

import { debounce } from 'lodash'

export default {
  components: {
    inventoryDealsTable
  },
  props: {
    deals: {
      type: Array,
      default: () => []
    },
    dealsLoading: {
      type: Boolean,
      default: () => true
    },
    parentOrganizationId: {
      type: Number,
      default: () => undefined
    }
  },
  data () {
    return {
      search: null,
      searching: false,
      searchResults: [],
      selectedDeals: [],
      dealHovered: null,
      isDealDialogOpen: false,
      canSeeNewUi: false
    }
  },
  created: function () {
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
  },
  watch: {
    search: debounce(function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const multiple = newVal.includes(',') || newVal.includes(' ') || newVal.includes('\t')
        if (!multiple) {
          this.searchDeals(newVal)
          trackingService.sendEvent(['ga'], 'searchedDeal', { label: newVal })
        }
      }
    }, 300)
  },
  computed: {
    searchItems () {
      return this.searchResults.filter(r => this.deals.findIndex(d => d.code === r.code) === -1)
    },
    organizationId () {
      return this.parentOrganizationId || this.$store.getters['user/getProfile']?.organizationId
    }
  },
  methods: {
    searchDeals (q) {
      this.searching = true

      const pagination = {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['name'],
        sortDesc: [false]
      }

      return audienceApi.getDeals(pagination, { search: q, organizationId: this.organizationId })
        .then(res => {
          this.searchResults = res
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.searching = false
        })
    },
    dealAdded (deals) {
      this.searching = true

      const dealPromises = deals
        .map(deal => {
          return typeof deal === 'string'
            ? deal.split(/\r|;\s*|,\s*|\n|\s+/g)
            : deal
        })
        .flat()
        .filter(deal => !!deal)
        .map(deal => {
          return typeof deal === 'string'
            ? this.getDeal(deal)
            : new Promise(resolve => {
              resolve(deal)
            })
        })

      Promise.all(dealPromises)
        .then(fullDeals => {
          fullDeals.filter(deal => deal !== null).map(fullDeal => {
            this.$emit('dealAdded', fullDeal)
            trackingService.sendEvent(['ga'], 'addedDeal', { label: fullDeal.code })
          })

          this.selectedDeals = []
        })
        .catch(e => {
          console.log(e)
          this.selectedDeals = []
        })
        .finally(() => {
          this.searching = false
          this.search = null
        })
    },
    dealRemoved (deal) {
      this.$emit('dealRemoved', deal)
      trackingService.sendEvent(['ga'], 'removedDeal', { label: deal.code })
    },
    toggleLoadDealsDialog () {
      this.$root.$emit('toggleLoadDealsDialog')
    },
    getDeal (dealCode) {
      return audienceApi.getDeal(dealCode, this.organizationId)
        .then(deal => deal)
        .catch(err => {
          if (err.response.status === 404) {
            return null
          }
        })
    },
    clearSearch () {
      this.search = null
      this.searchResults = []
    }
  }
}
</script>
