<template lang="pug">
  v-menu.notifications(offset-y max-width="100%" right nudge-bottom="0" allow-overflow )
    template(v-slot:activator="{ on }")
      v-btn(icon v-on="on" @click="updateSeenNotifications")
        v-badge(color="error" overlap v-model="gotUnseenNotifications")
          span(slot="badge")
            small {{numUnseenNotifications}}
          v-icon mdi-bell

    v-list(style="max-height:500px; max-width:550px; overflow-y: auto; " v-show="!isLoading")
      v-subheader
        | ACTIVITIES
        div.text-right(style="flex:auto;")
          v-btn(text color="primary" small router-link to='/history' ) See All

      v-list-item(v-for="(item, index) in notifications" :key="item.id" three-line)
        initialsAvatar(:user="item.data.user")
        v-list-item-content
          v-list-item-title
            router-link.info--text(:to="{ name: 'user', params: { userId: item.data.user.userId }}") {{ item.data.user.firstName }} {{ item.data.user.lastName }}
            span.text-caption.grey--text &nbsp;{{ item.data.date | date('calendar') }}
          v-list-item-subtitle.text--primary(v-html="item.body")

</template>

<script>
import initialsAvatar from '@/components/initialsAvatar.vue'

export default {
  components: {
    initialsAvatar
  },

  created () {
    this.$store.dispatch('notifications/getNotifications', 10)
  },

  computed: {
    isLoading () {
      return this.$store.getters['notifications/isLoading']
    },
    notifications () {
      return this.$store.getters['notifications/getNotifications']
    },
    gotUnseenNotifications () {
      return this.$store.getters['notifications/getNumUnseenNotifications'] > 0
    },
    numUnseenNotifications () {
      return this.$store.getters['notifications/getNumUnseenNotifications']
    }
  },

  methods: {
    updateSeenNotifications () {
      this.$store.dispatch('notifications/setSeenNotifications')
    }
  }
}
</script>

<style scoped>
a {
  color: green !important;
  /* color: --v-info-base; */
}
</style>
