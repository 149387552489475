<template lang="pug">
  //- .wrap(class="lighten-4 grey")
  v-container.py-0.px-0(grid-list-lg, text-left, fluid)
    //- Placeholder while loading is true
    //-v-layout(row wrap justify-start align-center v-if="isLoading" )
      v-flex(xs12)
        .wrap
          v-btn.text-h6.ml-0.mr-2(fab small color='white' disabled v-if="$vuetify.breakpoint.smAndDown || instanceType === 'campaign'")
            v-icon(medium) mdi-arrow-left
          .text-h6.d-inline-block(class='secondary--text') {{ instanceType + " loading..." | capitalize }}

    //- Navigation on mobile or if instance = campaign
    v-layout(row wrap justify-start align-center v-if="!isLoading && ($vuetify.breakpoint.smAndDown || instanceType === 'campaign')" )
      v-flex(xs12)
        .wrap()
          router-link(:to="goBack()")
            v-btn.text-h6.ml-0.mr-2(fab small color='white')
              v-icon(medium) mdi-arrow-left
          .text-h6.d-inline-block.text-truncate(v-if="!showLinesDropdown(currentInstanceObj)" class='secondary--text') {{ currentInstanceObj.name }}
          v-menu.inner(v-if="showLinesDropdown(currentInstanceObj)" transition="slide-y-transition" offset-y right min-width=300)
            template(v-slot:activator="{on}")
              v-btn.single-breadcrumb-wrap.text-h6.mx-0.mt-2.mb-1(v-on="on" text style="max-width:300px;")
                .text-truncate {{ currentInstanceObj.name }}
                v-icon.ml-1(medium) mdi-menu-down
            v-list.pr-3(style="max-height:300px; overflow-y: auto;")
              v-list-item(v-for="line in lines" :key="line.id" @click="changeLine(line)")
                .text-truncate {{ line.name }}
            .text-center.white.pb-2(v-if="allLinesfetched")
              router-link(:to="buildRoute('campaign')")
                v-btn(rounded color="primary") See All Lines

    //- Navigation as breadcrumbs on desktop and instance different than campaign
    v-layout(row wrap justify-start align-center v-if="!isLoading && !$vuetify.breakpoint.smAndDown && currentInstanceObj.type !== 'campaign'"  )
      v-flex.pt-1(xs12)
        .wrap()
          .inner
            v-breadcrumbs.pa-0(:items="items")
              template(v-slot:divider='')
                v-icon.ma-0.pa-0(small) mdi-chevron-right
              template(slot='item' slot-scope='props')
                v-menu(v-if="showLinesDropdown(props.item)" transition="slide-y-transition" offset-y right min-width=300)
                  template(v-slot:activator="{on}")
                    v-btn.single-breadcrumb-wrap.mx-0.mt-2.mb-1(v-on="on" text small)
                      .text-truncate {{ props.item.name }}
                      v-icon.ml-1(small) mdi-menu-down
                  v-list(style="max-height:300px; overflow-y: auto;" dense)
                    v-list-item(v-for="line in lines" :key="line.id" @click="changeLine(line)")
                      .text-truncate {{ line.name }}
                  .text-center.white.pb-2(v-if="allLinesfetched")
                    router-link(:to="buildRoute('campaign')")
                      v-btn(rounded color="primary") See All Lines
                router-link(:to="props.item.route" v-if="!showLinesDropdown(props.item)")
                  v-btn.single-breadcrumb-wrap.mt-2.mb-1(text small)
                    .text-truncate {{ props.item.name }}

</template>

<script>
import reportingService from '@/services/reporting.service'

export default {
  props: ['instanceType'],
  computed: {
    isLoading () {
      const boolsList = []
      const lvls = this.upToLevelsList(this.instanceType)
      lvls.forEach(lvl => boolsList.push(this.$store.getters[reportingService.assembleLoadingGetter(lvl)]))
      return boolsList.some(x => x === true)
    },
    campaign () {
      return this.$store.getters['campaignReport/getCampaign']
    },
    line () {
      return this.$store.getters['campaignReport/getLine']
    },
    venue () {
      return this.$store.getters['campaignReport/getVenue']
    },
    screen () {
      return this.$store.getters['campaignReport/getScreen']
    },
    items () {
      return this.assembleLevels()
    },
    lines () {
      return this.$store.getters['campaignReport/getLines']
        .filter(line => line.bookingStatus !== 'Archived')
        .map(line => {
          return {
            name: line.name,
            id: line.id,
            type: 'line',
            route: { name: 'report-line', params: { campaignId: this.campaign.id, lineId: line.id } }
          }
        })
    },
    allLinesfetched () {
      return this.$store.getters['campaignReport/getfetchedAllLinesBool']
    },
    currentInstanceObj () {
      return this.items[this.items.length - 1]
    }
  },
  data: () => ({
    treeOrder: ['campaign', 'line', 'venue', 'screen'],
    linesFetched: false
  }),
  methods: {
    formSingleCrumb (instance) {
      return {
        name: this[instance].name,
        id: this[instance].id,
        type: instance,
        route: this.buildRoute(instance)
      }
    },

    buildRoute (instance) {
      const routeObj = { name: '', params: {} }
      routeObj.name = 'report-' + instance
      this.upToLevelsList(instance).forEach(inst => {
        routeObj.params[inst + 'Id'] = this[inst].id
      })
      return routeObj
    },

    upToLevelsList (instance) {
      const i = this.treeOrder.findIndex(x => x === instance)
      return this.treeOrder.slice(0, i + 1)
    },

    assembleLevels () {
      const effectiveLevels = this.upToLevelsList(this.instanceType)
      return effectiveLevels.map(lvl => this.formSingleCrumb(lvl))
    },

    showLinesDropdown (item) {
      return item.type === 'line' && this.items.findIndex(x => x.id === item.id && x.name === item.name) === this.items.length - 1 && this.lines.length > 1
    },

    changeLine (newLine) {
      var { params, path, query } = this.$router.currentRoute
      var newPath = path.replace(params.lineId, newLine.id)
      this.$router.push({ path: newPath, query: query })
    },

    goBack () {
      if (this.currentInstanceObj.type === 'campaign') {
        return '/campaigns'
      } else {
        return this.items[this.items.length - 2].route
      }
    }
  }
}
</script>

<style scoped>
.wrap {
  display: flex;
  align-items: center;
}
.wrap li {
  padding: 0px;
}
.inner {
  overflow: hidden;
  width: fit-content;
}
.v-breadcrumbs {
    flex-wrap: nowrap;
}
.single-breadcrumb-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 auto;
  flex-shrink: 1000;
  display: inline-block;
  position: relative;
  padding-left: 4px !important;
  padding-right: 4px !important;
  opacity: 0.5;
  text-transform: none;
  /*max-width:300px;*/
}
.single-breadcrumb-wrap:hover {
  opacity: 1;
}
.single-breadcrumb-wrap:last-child {
  flex: 1 0 auto !important;
  opacity: 1;
}
.wrap >>> .v-breadcrumbs__divider {
  padding:3px 0px 0px 0px;
}
>>> .v-breadcrumbs li .v-icon{
  font-size:24px;
}
.text-h6 {
  text-transform: none;
}
</style>
