<template lang='pug'>
  v-card.my-6(:loading='pageLoading')
    v-card-title.text-h5 Billing Options

    v-form(ref='billingForm' v-model='formValidated')

      //- MANUAL
      v-card-text.secondary--text(v-if='isManual')
        v-layout
          v-flex(md9)
            .text-body-1 Organization approved for manual invoicing.
            //- v-checkbox.mt-2(label='Use same payment terms as workspace' v-model='paymentTermWorkspace' single-line hide-details color='primary')
            v-scroll-y-transition(leave-absolute)
              .text-caption.mb-2.pl-8.pt-2(v-if='paymentTermWorkspace') Payment Terms
                .text-body-1
                  strong {{ paymentTerm }}

            v-scroll-y-transition(leave-absolute hide-on-leave)
              .pl-8.pb-2(v-if='!paymentTermWorkspace')
                v-select.my-3(
                  label='Payment Terms'
                  v-model='paymentTerm'
                  :items='paymentTerms'
                  item-value='id'
                  item-text='label'
                  return-object
                  :disabled='!can.update.paymentTerm'
                  hide-details style='width:300px;')

            ul.py-1
              li.text-body-1.my-2 Invoices are generated on the first day of every month.
              li.text-body-1.my-2 Payment is due on the first day of the following month.
              li.text-body-1.my-2 A reminder will be sent 7 days before the due date.
            .text-caption.py-1
              strong A payment failure will automatically stop all campaigns and suspend the organization. Organization will be reactivated when the payment is received.

      //- v-divider(v-if='isManual')
      //- v-card-text.secondary--text(v-if='isManual')
        .text-body-1
          strong Invoicing split options:
        v-layout(row wrap)
          v-flex(sm12 md12)
            div.text-body-1
              | One the first day of the month:
            v-radio-group.mt-0.mb-2(v-model='invoiceOption' color='primary' hide-details)
              v-radio(key='1' color='primary' label='Generate one invoice grouped by campaigns')
              v-radio(key='2' color='primary' label='Generate multiple invoices, one for each advertisers')
              v-radio(key='3' color='primary' label='Generate multiple invoices, one for each campaigns')

      //- AUTOMATIC
      v-card-text.secondary--text(v-if='!isManual')
        v-layout
          v-flex(md9)
            .text-body-1 Credit card will be automatically charged after reaching a spending threshold.
            v-select.my-3(
              disabled
              :items='transactionThresholdOptions'
              label='Current Transaction Threshold'
              hide-details
              v-model='transactionThreshold'
              style='width:300px;')
              template(v-slot:item='{ item }')
                span {{ item | currency }}
              template(v-slot:selection='{ item }')
                span {{ item | currency }}

            ul.py-1
              li.text-body-1.my-2 Every successul transactions will increase the spending threshold
              li.text-body-1.my-2 The spending threshold scale is:
                ul
                  li(v-for='option in transactionThresholdOptions')
                    span {{ option | currency }}
            .text-caption
              strong A transaction failure will automatically stop all campaigns and suspend the organization. Organization will be automatically reactivated when the balance is paid.

      v-divider
      v-card-text.secondary--text
        .text-body-1
          strong {{ invoiceTypeLabel | capitalize }} information:

          v-layout(row wrap)
            v-flex(sm12 md12)
              //- DISABLED for now, as no endpoint to add/edit "organization.billingContact" (without "s")
              //- Used to be filled by Chris manually as a workaround for Yvonne's requests of renaming invoices,
              //- meaning "Attention of" on Invoices already uses "organization.billingContact", which is an Object in API, not a simple textfield
              v-checkbox.mt-0(label='Use same billing contact as organization primary contact' v-model='sameBillingContact' disabled single-line hide-details color='primary')

              v-scroll-y-reverse-transition(leave-absolute)
                div.d-block.text-caption.mb-2.pl-8.pt-2(v-if='sameBillingContact')
                  | {{ invoiceTypeLabel | capitalize }}s will be addressed to:
                  .text-body-2
                    strong {{ primaryContactName }}
              v-scroll-y-reverse-transition(leave-absolute hide-on-leave)
                div.pl-8.pb-2(v-if='!sameBillingContact')
                  v-layout(row wrap)
                    v-flex(sm12)
                      v-text-field.mt-3(label='Invoices will be addressed to' v-model='billingContact' disabled :rules='[rules.billingContactRequired]')

          v-layout(row wrap)
            v-flex(sm12 md12)
              v-checkbox.mt-0(
                label='Use same billing name as organization name'
                v-model='sameBillingName'
                @change='autofocusBillingNameField'
                color='primary'
                single-line hide-details)

              v-scroll-y-transition(leave-absolute)
                div.text-caption.mb-2.pl-8.pt-2(v-if='sameBillingName')
                  | Business name used on the {{ invoiceTypeLabel }}s
                  .text-body-2
                    strong {{ organization.name }}
              v-scroll-y-transition(leave-absolute hide-on-leave)
                div.pl-8.pb-2(v-if='!sameBillingName')
                  v-layout(row wrap)
                    v-flex(sm12)
                      v-text-field.mt-3(label='Business name used on the invoice' :autofocus='billingNameAutofocus' v-model='billingAddress.name' :rules='[rules.billingNameRequired]')

          v-layout(row wrap)
            v-flex(sm12 md12)
              v-checkbox.mt-0(
                label='Use same billing address as organization address'
                v-model='sameBillingAddress'
                @change='autofocusBillingAddressField'
                color='primary'
                single-line hide-details)

              v-scroll-y-transition(leave-absolute)
                .text-body-1.pl-8.pt-2(v-if='sameBillingAddress')
                  .text-caption.mb-2
                    | Address that will appear on {{ invoiceTypeLabel }}s:
                    .text-body-2
                      strong.d-block {{ organization.address.addressLine1 }}
                        span(v-if='organization.address.addressLine2') , {{ organization.address.addressLine2 }}
                      strong.d-block {{ organization.address.city }}
                        span(v-if='organization.address.postalCode') , {{ organization.address.postalCode }}
                      strong.d-block(v-if='organization.address.state') {{ organization.address.state.name }}
                        span(v-if='organization.address.country') , {{ organization.address.country.name }}
              v-scroll-y-transition(leave-absolute hide-on-leave)
                div.pl-8.pt-2(v-if='!sameBillingAddress')
                  v-layout(row wrap)
                    v-flex(sm12 md9)
                      v-text-field(label='Address Line 1' v-model='billingAddress.addressLine1' :autofocus='billingAddressAutofocus' :rules='[rules.billingAddressRequired]')
                    v-flex(sm12 md3)
                      v-text-field.suite-input(label='Suite / Office Number' v-model='billingAddress.addressLine2')

                  v-layout(row wrap)
                    v-flex(sm12 md6)
                      v-text-field(label='City' v-model='billingAddress.city' :rules='[rules.billingAddressRequired]')
                    v-flex(sm12 md6)
                      v-text-field(:label='marketVals.postcodeLabel' v-model='billingAddress.postalCode' :rules='[rules.billingAddressRequired]')

                  v-layout(row wrap)
                    v-flex(sm12 md6)
                      v-autocomplete(label='Country' v-model='billingAddress.country' return-object :items='countriesAndStates' item-text='name' autocomplete='nope' :rules='[rules.billingAddressRequired]')
                    v-flex(sm12 md6)
                      v-autocomplete(:label='marketVals.stateLabel' v-model='billingAddress.state' return-object :items='countryStates' item-text='name' autocomplete='nope' :rules='[rules.billingAddressRequired]' :disabled='!billingAddress.country')
      v-divider
      v-card-text.secondary--text
        .text-body-1
          strong Send {{ invoiceTypeLabel }}s to:
        .text-body-2 {{ invoiceTypeLabel | capitalize }}s will be sent by email automatically to the organization's owner and administrators.
        .text-body-2 If additional recipients are needed, enter their emails below:
        //-v-checkbox(label='Use same email list as workspace email list' v-model='uniqueEmailList' color='primary'  single-line hide-details)
        emailsRecipients(
          :initialRecipientsList='billingContacts'
          @listUpdated='updateEmailsRecipients'
        )

      v-card-actions
        //-.text-caption.my-3.grey--text.ml-3
          | Any invoicing changes will take effect starting next month
        v-spacer
        v-btn.save-changes-btn(
          v-if='can.update.default'
          text color='primary'
          :disabled='!formChangedAndValidated'
          :loading='parentFormProcessing'
          @click='save') Save Changes
</template>
<script>
import emailsRecipients from '@/components/emailsRecipients'

import billingService from '@/services/billing.service'

export default {
  components: {
    emailsRecipients
  },
  props: ['pageLoading', 'parentFormProcessing', 'organization', 'countriesAndStates', 'billingInfosUpdated', 'can'],
  data () {
    return {
      formValidated: true,
      saving: false,
      invoiceOption: 0,
      uniqueEmailList: true,

      billingNameAutofocus: false,
      sameBillingName: !this.organization.billingAddress || !this.organization.billingAddress.name,

      sameBillingContact: !this.organization.billingContact,
      billingContact: this.organization.billingContact
        ? this.organization.billingContact.firstName + ' ' + this.organization.billingContact.lastName
        : '',

      billingAddressAutofocus: false,
      sameBillingAddress: !this.organization.billingAddress || (this.organization.billingAddress.name && !this.organization.billingAddress.addressLine1),
      billingAddress: this.organization.billingAddress
        ? JSON.parse(JSON.stringify(this.organization.billingAddress))
        : {
          addressLine1: '',
          addressLine2: '',
          city: '',
          postalCode: '',
          country: '',
          state: '',
          name: ''
        },

      billingContacts: JSON.parse(JSON.stringify(this.organization.billingContacts)),

      paymentTerms: [],
      paymentTerm: null,
      paymentTermWorkspace: false,

      transactionThresholdOptions: [
        100,
        500,
        1000,
        5000,
        10000,
        25000
      ],
      transactionThreshold: 100,
      curentAmountTrigger: {},

      rules: {
        required: value => !!value || 'Required',
        billingNameRequired: val => {
          if (!this.sameBillingName && !val) { return 'Required field' } else { return true }
        },
        billingContactRequired: val => {
          if (!this.sameBillingContact && !val) { return 'Required field' } else { return true }
        },
        billingAddressRequired: val => {
          if (!this.sameBillingAddress && !val) { return 'Required field' } else { return true }
        }
      }
    }
  },
  watch: {
    organization (newVal, oldVal) {
      if (newVal) {
        this.init()
      }
    }
  },
  created () {
    this.init()
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    isManual () {
      return this.organization.paymentType === 'Manual'
    },
    invoiceTypeLabel () {
      return this.isManual ? 'invoice' : 'transaction receipt'
    },
    primaryContactName () {
      if (this.organization.contacts && this.organization.contacts.length) {
        var primaryContact = this.organization.contacts[0]
        return primaryContact.firstName + ' ' + primaryContact.lastName
      } else {
        return ''
      }
    },
    countryStates () {
      return this.billingAddress.country && this.countriesAndStates.length
        ? this.countriesAndStates.find(c => c.id === this.billingAddress.country.id).states
        : []
    },

    paymentTermsChanged () {
      return this.isManual && this.organization && this.paymentTerm && this.organization.paymentTerm.id !== this.paymentTerm.id
    },
    billingNameChanged () {
      if (!this.organization.billingAddress || !this.organization.billingAddress.name) {
        return !this.sameBillingName
      }

      if (this.organization.billingAddress && this.organization.billingAddress.name) {
        return this.sameBillingName || (this.organization.billingAddress.name !== this.billingAddress.name)
      }

      return false
    },
    billingAddressChanged () {
      if (!this.organization.billingAddress ||
         (this.organization.billingAddress.name &&
          !this.organization.billingAddress.addressLine1 &&
          !this.organization.billingAddress.addressLine2 &&
          !this.organization.billingAddress.city &&
          !this.organization.billingAddress.postalCode &&
          !this.organization.billingAddress.state &&
          !this.organization.billingAddress.country)) {
        return !this.sameBillingAddress
      } else {
        const stateChanged = this.billingAddress.state && (!this.organization.billingAddress.state || this.organization.billingAddress.state.id !== this.billingAddress.state.id)
        const countryChanged = this.billingAddress.country && (!this.organization.billingAddress.country || this.organization.billingAddress.country.id !== this.billingAddress.country.id)

        return this.sameBillingAddress ||
          this.billingAddress.addressLine1 !== this.organization.billingAddress.addressLine1 ||
          this.billingAddress.addressLine2 !== this.organization.billingAddress.addressLine2 ||
          this.billingAddress.city !== this.organization.billingAddress.city ||
          this.billingAddress.postalCode !== this.organization.billingAddress.postalCode ||
          stateChanged || countryChanged
      }
    },
    billingContactsChanged () {
      return this.billingContacts.length !== this.organization.billingContacts.length ||
        this.billingContacts.some(n => this.organization.billingContacts.findIndex(c => c.email === n.email) === -1) ||
        this.organization.billingContacts.some(c => this.billingContacts.findIndex(n => n.email === c.email) === -1)
    },
    formChangedAndValidated () {
      return this.formValidated && !this.parentFormProcessing && (this.paymentTermsChanged || this.billingNameChanged || this.billingAddressChanged || this.billingContactsChanged)
    }
  },
  methods: {
    init () {
      if (this.isManual) {
        this.getPaymentTerms()
      } else {
        this.getPaymentTriggers()
      }
    },
    getPaymentTriggers () {
      billingService.getPaymentTriggers(this.organization.id)
        .then(triggers => {
          this.curentAmountTrigger = triggers.find(t => t.type === 'amount')
          if (this.curentAmountTrigger) {
            this.transactionThreshold = this.curentAmountTrigger.value.amount
          }
        })
    },
    getPaymentTerms () {
      billingService.getPaymentTerms()
        .then(paymentTerms => {
          this.paymentTerms = paymentTerms.map(pt => {
            pt.label = pt.name.replace('-', ' ') + ' Days'
            return pt
          })

          const currentPaymentTerms = this.organization.paymentTerm || this.paymentTerms[0]
          this.paymentTerm = { ...this.paymentTerms.find(pt => pt.id === currentPaymentTerms.id) }
        })
    },
    updateEmailsRecipients (newEmailList) {
      this.billingContacts = newEmailList
    },
    save () {
      var billingInfos = {}

      if (this.paymentTermsChanged) {
        billingInfos.paymentTerm = this.paymentTerm
      }

      if (this.billingAddressChanged || this.billingNameChanged) {
        if (this.sameBillingAddress && this.sameBillingName) { billingInfos.billingAddress = null }
        if (this.sameBillingAddress && !this.sameBillingName) {
          billingInfos.billingAddress = {
            name: this.billingAddress.name
          }
        }

        if (!this.sameBillingAddress && this.sameBillingName) {
          billingInfos.billingAddress = this.billingAddress
          billingInfos.billingAddress.name = ''
        }

        if (!this.sameBillingAddress && !this.sameBillingName) {
          billingInfos.billingAddress = this.billingAddress
        }
      }

      if (this.billingContactsChanged) {
        billingInfos.billingContacts = this.billingContacts
      }

      // disabled for now
      if (!this.sameBillingContact) {
        var billingContactPieces = this.billingContact.split(' ')
        var firstName = billingContactPieces[0]
        billingContactPieces.shift()
        var lastName = billingContactPieces.join(' ')

        billingInfos.billingContact = {
          id: this.organization.billingContact ? this.organization.billingContact.id : 0,
          firstName,
          lastName
        }
      }

      this.$emit('billingInfosUpdated', billingInfos)
    },

    /**
     * Focus functions
     * called on-demand (aka checkboxes controlling display of inputs)
     * avoids scrolling to autofocused input on page load
     */
    autofocusBillingNameField () {
      this.billingNameAutofocus = !this.sameBillingName
    },
    autofocusBillingAddressField () {
      this.billingAddressAutofocus = !this.sameBillingAddress
    }
  }
}
</script>
