<template lang="pug">
div
  .body-2.no-date-range(v-if='!dateRange') None
  div(v-else)
    .overline.date-range-type {{ rangeType }}
    .caption.date-range-value {{ rangeValue }}
</template>

<script>
import reportsService from '../services/reports'

export default {
  props: ['report'],
  data () {
    return {
      dateFormat: 'MMM DD, YYYY'
    }
  },
  computed: {
    dateRange () {
      return reportsService.decodeDateRangePayload(this.report)
    },
    rangeType () {
      return this.dateRange && this.dateRange.range
    },
    rangeValue () {
      if (!this.dateRange) return null
      return this.dateRange.range === 'dynamic'
        ? this.dateRange.dynamicRange
        : `${this.dateRange.dates[0].format(this.dateFormat)} to ${this.dateRange.dates[1].format(this.dateFormat)}`
    }
  }
}
</script>
