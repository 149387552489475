<template lang="pug">
v-layout.disabled-account-alert(row wrap fill-height v-if='isDisabled')
  v-flex(xs12 sm12 md12)
    v-alert.mb-0(:value='true' color='primary' type='info' dense outlined)
      v-row.my-0(align='center' no-gutters)
        v-col.grow
          .text-body-2(class='text--primary')
            strong Account not yet activated
          .text-body-2.alert-body(class='text--primary')
            | {{ alertBody }}
            a.info--text(href='https://intercom.help/broadsign-ads/en/articles/6037524-why-is-my-account-disabled' target='_blank')
              |  Learn more
            |  or
            a.info--text(href='mailto:info_ads@broadsign.com')
              |  contact us.
        v-col.shrink.add-credit-card-section(v-if='canAddCreditCard')
          v-dialog(:fullscreen='$vuetify.breakpoint.xsOnly' max-width=600 v-model='isCreditCardDialogOpen')
            template(v-slot:activator='{ on }')
              v-btn.add-credit-card-button.ma-0.pl-2(text color='primary' v-on='on')
                v-icon.mr-2(small) mdi-plus
                | credit card
            addCardDialog(
              ref='addCardDialogRef'
              action='add'
              :form-control='isCreditCardDialogOpen'
              @closeDialog='isCreditCardDialogOpen = false'
              @closeAndRefresh='closeAndRefresh'
              :cards='cards'
              :paymentMethods='paymentMethods'
              :organization='organization'
              )
</template>

<script>
import addCardDialog from '@/components/addCardDialog.vue'

export default {
  components: {
    addCardDialog
  },
  props: ['isDisabled', 'cards', 'paymentMethods', 'organization', 'permissions'],
  data () {
    return {
      isCreditCardDialogOpen: false
    }
  },
  computed: {
    canAddCreditCard () {
      return this.permissions && this.permissions.update && this.permissions.update.paidPaymentMethod
    },
    alertBody () {
      return this.canAddCreditCard
        ? 'To activate your account and start running campaigns, please add a valid credit card.'
        : 'To activate your account and start running campaigns, ask the account owner or administrator to add a valid credit card.'
    }
  },
  methods: {
    closeAndRefresh (action) {
      if (action === 'add') this.isCreditCardDialogOpen = false
      this.$emit('closeAndRefresh')
    }
  }
}
</script>
