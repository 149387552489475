<template lang='pug'>
  div
    v-scroll-y-transition(leave-absolute hide-on-leave)
      v-combobox.my-2(
        multiple
        small-chips
        clearable
        label='Email Recipients'
        v-model='recipientsList'
        @change='addRecipient'
        @click:clear='clearRecipients')
        template(v-slot:selection='data')
          v-chip(
            v-bind='data.attrs'
            :input-value='data.selected'
            :disabled='data.disabled'
            @click.stop='openEditDialog(data.item)'
            @click:close='removeRecipient(data.item)'
            small
            close
            label)
            v-icon.mr-1(small :color="validateEmail(data.item.email) ? 'primary' : 'error'")
              | {{ validateEmail(data.item.email) ? 'mdi-check-bold' : 'mdi-alert-circle' }}
            |  {{ data.item.email }}

    v-dialog(v-model='editDialogOpen' max-width=400)
      v-form(ref='editForm' v-model='editFormValidated' @submit.prevent='saveEditDialog')
        v-card
          v-card-title Edit
          v-card-text
            v-text-field(
              v-model='editEmail'
              :rules='[rules.required, rules.validEmail]'
              autofocus)
          v-card-actions
            v-spacer
            v-btn(text @click='editDialogOpen = false') cancel
            v-btn(text color='primary' :disabled='!editFormValidated' @click='saveEditDialog') save
</template>
<script>
export default {
  props: ['initialRecipientsList'],
  data () {
    return {
      recipientsList: this.initialRecipientsList,
      editDialogOpen: false,
      editRecipient: null,
      editEmail: '',
      editFormValidated: false,
      rules: {
        required: value => !!value || 'Required',
        validEmail: v => this.validateEmail(v) || 'Email must be valid'
      }
    }
  },
  watch: {
    initialRecipientsList (newVal, oldVal) {
      this.recipientsList = this.initialRecipientsList
    }
  },
  methods: {
    validateEmail (email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    },
    addRecipient () {
      const entries = this.recipientsList
        .map(entry => {
          return typeof entry === 'string'
            ? entry.split(/\r|;\s*|,\s*|\n/g).map(x => { return { id: 'tempid-' + x, email: x.trim() } })
            : entry
        })
        .flat()

      this.recipientsList = entries.reduce((unique, x) => {
        return typeof x.id !== 'string' || !unique.map(x => x.email).includes(x.email) ? [...unique, x] : unique
      }, [])

      this.$emit('listUpdated', this.recipientsList)
    },
    openEditDialog (recipient) {
      this.editRecipient = recipient
      this.editEmail = JSON.parse(JSON.stringify(this.editRecipient)).email
      this.editDialogOpen = true
    },
    saveEditDialog () {
      this.editRecipient.email = this.editEmail
      this.editDialogOpen = false
      this.$emit('listUpdated', this.recipientsList)
    },
    removeRecipient (recipient) {
      var i = this.recipientsList.findIndex(r => r.id === recipient.id)
      this.recipientsList.splice(i, 1)
      this.$emit('listUpdated', this.recipientsList)
    },
    clearRecipients () {
      this.recipientsList = []
      this.$emit('listUpdated', [])
    }
  }
}
</script>
