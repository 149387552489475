<template lang="pug">
.troubleshoot-rtb-metric-breakdown
  v-row.mb-0.mt-4.align-top(no-gutters)
    v-col.text-left(cols='5')
      v-card.left-card(outlined :class='{ "no-breakdown" : !metric.breakdown }')
        v-card-text.text--primary
          .d-flex.justify-space-between.mb-2
            .subtitle-1.left-side-metric
              | {{ metric.name }}:
              span.font-weight-bold(v-if='displayRules.isLeftSideMetricNa')  N/A
              v-scroll-y-reverse-transition(v-else)
                strong(v-if='!valuesLoading')  {{ metric.total | statsFormat }}
            v-icon.text--primary(v-if='metric.breakdown') mdi-arrow-right
          .caption.grey--text(style="line-height:1.4em;") {{ metric.description }}

    v-col(cols='7')
      v-card.right-card(outlined v-if='metric.breakdown')
        v-card-text.text--primary
          .subtitle-1.right-side-metric
            | {{ currentTotal.name }}:
            span.font-weight-bold(v-if='displayRules.isRightSideMetricTotalNotNumber')  {{ displayRules.rightSideMetricLabel }}
            v-scroll-y-reverse-transition(v-else)
              strong(v-if='!valuesLoading')  {{ currentTotal.value | statsFormat }}
          v-simple-table(dense)
            thead
              tr
                th.text-left(width='400')
                  | Reasons
                th.text-right
                  | Requests
                th.text-right
                  | %
            tbody
              tr(v-for='d in metric.breakdown.data' :key='d.key')
                td {{ d.name }}
                td.text-right
                  span(v-if='!valuesLoading') {{ d.value | statsFormat }}
                td.text-right
                  span(v-if='!valuesLoading') {{ d.percent | percent }}

              tr.total-current
                td.text-right(style='border-bottom:none')
                  strong Total {{ currentTotal.name }}
                td.text-right(style='border-bottom:none')
                  strong(v-if='displayRules.isRightSideBreakdownNa') N/A
                  strong(v-if='!valuesLoading && !displayRules.isRightSideBreakdownNa') {{ currentTotal.value | statsFormat }}
                td.text-right(style='border-bottom:none')
                  strong(v-if='displayRules.isRightSideBreakdownNa') N/A
                  strong(v-if='!valuesLoading && !displayRules.isRightSideBreakdownNa') {{ currentTotal.percent | percent }}

              //-tr.total-next
                td.text-right
                  strong Total {{ nextTotal.name }}
                td.text-right
                  strong(v-if='!valuesLoading') {{ nextTotal.value | statsFormat }}
                td.text-right
                  strong(v-if='!valuesLoading') {{ nextTotal.percent | percent }}

    v-row.my-0.justify-center.arrow-connector(v-if='showConnector' style="margin-top:-24px;")
      v-col.d-flex.text-center.pt-0(cols='8' offset='3')
        div.line-left(outlined)
          v-icon.line-left-arrow mdi-menu-down
        div.line-right(outlined)
</template>

<style lang="stylus" scoped>
.left-card
  z-index: 100
  margin-left: 2px

.left-card:not(.no-breakdown)
  border-right: none
  border-top-right-radius: 0px
  border-bottom-right-radius: 0px

.right-card
  margin-left: -2px
  border-top-left-radius: 0px

.line
  border-right: 1px solid black
  width: 50%
  height: 20px

.line-left
  height: 30px
  width: 50%
  margin-bottom: 20px
  top:39px
  position: relative
  border-right: none
  border-bottom: none
  border-left: 1px solid
  border-top: 1px solid
  border-bottom-left-radius: 0px
  border-top-right-radius: 0px
  border-top-color: rgba(0, 0, 0, 0.2)
  border-left-color: rgba(0, 0, 0, 0.2)
  background-color: transparent

.line-right
  height: 40px
  width: 50%
  border-left: none
  border-top: none
  border-right: 1px solid
  border-bottom: 1px solid
  border-top-right-radius: 0px
  border-bottom-left-radius: 0px
  border-bottom-color: rgba(0, 0, 0, 0.2)
  border-right-color: rgba(0, 0, 0, 0.2)
  background-color: transparent

.line-left-arrow
  position: absolute
  bottom: -14px
  left: -13px
</style>

<script>
export default {
  props: {
    metric: {
      type: Object,
      required: true
    },
    showConnector: {
      type: Boolean,
      default: true,
      required: false
    },
    valuesLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    isAuctionPackage: {
      type: Boolean,
      required: false
    }
  },
  filters: {
    statsFormat (value) {
      if (!parseFloat(value) && value !== 0) return '-'

      if (value >= 100000) {
        return (value / 1000000).toFixed(1) + 'M'
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K'
      } else {
        return value
      }
    },
    percent (value) {
      if (!parseFloat(value) && value !== 0) return '-'
      return (value * 100).toFixed(1) + '%'
    }
  },
  computed: {
    currentTotal () {
      return this.metric.breakdown
        ? this.metric.breakdown.totals.find(t => t.key === 'current')
        : null
    },
    nextTotal () {
      return this.metric.breakdown
        ? this.metric.breakdown.totals.find(t => t.key === 'next')
        : null
    },
    displayRules () {
      return {
        isLeftSideMetricNa: this.isAuctionPackage && this.metric.key === 'wonImpressions',
        isRightSideMetricTotalNotNumber: this.isAuctionPackage && ['eligibleImpressions', 'bidSubmitted', 'wonImpressions'].includes(this.metric.key),
        rightSideMetricLabel: this.metric.key === 'eligibleImpressions' ? "Please refer to your DSP's troubleshooting tool" : 'N/A',
        isRightSideBreakdownNa: this.isAuctionPackage && ['bidSubmitted', 'wonImpressions'].includes(this.metric.key)
      }
    }
  },
  created () {
    // console.log('metric-breakdown', this.metric)
  },
  methods: {
  }
}
</script>
