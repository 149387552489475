import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from './authGuard'
import routes from './routes'
import tracking from '../services/tracking'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 48 }
      }
    }
    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach(authGuard)

router.afterEach((to, from) => {
  // If is necessary to bypass Campaigns Overview initial pageview
  if (from.path !== '/' || to.name !== 'Campaigns Overview') {
    tracking.updatePageview('all', { title: to.name, page: to.fullPath })
  }
})

export default router
