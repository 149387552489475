<template lang='pug'>
component(
  :is="$vuetify.breakpoint.xsOnly? 'v-dialog' : 'v-menu'"
  v-model='isOpen'
  offset-y
  :close-on-content-click='false'
  :fullscreen='$vuetify.breakpoint.xsOnly'
  min-width='500px'
  max-width='500px'
)
  template(v-slot:activator='{on}')
    .date-range-value(v-on='on')
      slot(name='customActivator')

  v-card.date-range-picker
    v-card-text
      v-row.my-0(wrap)
        v-col(xs='6')
          v-text-field(
            label='Start Date'
            ref='startDateRef'
            placeholder='YYYY-MM-DD'
            autofocus
            @focus='setPickerDateCurrent("start")'
            v-model='startDateFromInput'
            :rules='[dateValidation]')
        v-col(xs='6')
          v-text-field(
            label='End Date'
            ref='endDateRef'
            placeholder='YYYY-MM-DD'
            @focus='setPickerDateCurrent("end")'
            v-model='endDateFromInput'
            :rules='[dateValidation]')

      v-date-picker(
        ref='vDatePickerRef'
        v-if='isOpen'
        multiple
        no-title
        full-width
        color='primary'
        :show-current='false'
        :value='pickerDates'
        :min='pickerMinDate'
        :max='pickerMaxDate'
        :events='isDateInRange'
        event-color='#a0c4ff'
        @input='datePickerHandler')

    v-divider
    v-card-actions
      v-spacer
      v-btn.my-0.cancel(text @click='closeModal') Cancel
      v-btn.my-0.apply(color='primary' text :disabled='disableApplyBtn' @click='applyChange') Apply
</template>

<script>
import moment from 'moment'

// dynamic Vuetify components used with <component :is="v-component"> must be registered locally
import { VDialog, VMenu } from 'vuetify/lib'

export default {
  name: 'dateRange',
  components: {
    VDialog,
    VMenu
  },
  props: {
    fullDates: {
      type: Array,
      default: () => []
    },
    minDate: {
      type: String,
      default: () => null
    },
    maxDate: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      isOpen: false,
      pickerDates: [],
      pickerDateCurrent: 'start',
      startDateFromInput: null,
      endDateFromInput: null,
      dateValidation: value => {
        const validation = this.dateInputValidator(value)
        return validation[0] || validation[1]
      }
    }
  },
  mounted () {
    this.initializeComponent()
  },
  watch: {
    isOpen () {
      this.initializeComponent()
    },
    startDateFromInput (newVal, oldVal) {
      if (oldVal && this.dateValidation(newVal) === true) {
        this.pickerDates.splice(0, 1, newVal)
      }
    },
    endDateFromInput (newVal, oldVal) {
      if (oldVal && this.dateValidation(newVal) === true) {
        this.pickerDates.splice(1, 1, newVal)
      }
    }
  },
  computed: {
    rangeDates () {
      const dates = []

      const start = moment(this.pickerDates[0])
      const end = moment(this.pickerDates[this.pickerDates.length - 1])
      const diff = moment.duration(end.diff(start)).asDays()

      for (let i = 0; i <= diff; i++) {
        dates.push(start.clone().add(i, 'days').format('YYYY-MM-DD'))
      }

      return dates
    },
    disableApplyBtn () {
      return !this.dateInputValidator(this.startDateFromInput)[0] || !this.dateInputValidator(this.endDateFromInput)[0]
    },
    pickerMinDate () {
      return this.pickerDateCurrent === 'end'
        ? this.pickerDates[0]
        : this.minDate
          ? moment(this.minDate).format('YYYY-MM-DD')
          : null
    },
    pickerMaxDate () {
      const maxDate = this.maxDate
        ? moment(this.maxDate)
        : moment()

      return maxDate.format('YYYY-MM-DD')
    }
  },
  methods: {
    dateInputValidator (date) {
      if (!date) {
        return [false, 'This field is required']
      }

      if (!date.match(/^\d{4}-\d{2}-\d{2}$/g)) {
        return [false, 'Please enter a valid format']
      }

      if (!moment(date).isValid()) {
        return [false, 'Please enter a valid date']
      }

      if (this.pickerMinDate && moment(date).isBefore(moment(this.pickerMinDate))) {
        return [false, 'Picked date cannot be before min date']
      }

      if (moment(date).isAfter(moment(this.pickerMaxDate))) {
        return [false, 'Picked date cannot be after max date']
      }

      return [true]
    },
    isDateInRange (d) {
      return this.rangeDates.indexOf(d) > -1
    },
    datePickerHandler (dates) {
      const numDates = dates.length
      if (numDates) {
        let d0 = dates[numDates - 1]

        if (numDates === 1) {
          const clikedDates = this.pickerDates.filter(d => d !== d0)
          d0 = clikedDates[0]
        }

        const newDates = this.pickerDates

        if (this.pickerDateCurrent === 'start') {
          if (numDates > 1 && moment(d0).isAfter(moment(this.pickerDates[1]))) {
            newDates[1] = d0
          } else {
            newDates[0] = d0
          }
        } else {
          newDates[1] = d0
        }

        this.setDatesOnPickerAndInput(newDates)
      }

      if (this.pickerDateCurrent === 'start') {
        this.pickerDateCurrent = 'end'
        this.$refs.endDateRef.focus()
      } else {
        this.pickerDateCurrent = 'start'
        this.$refs.startDateRef.focus()
      }
    },
    setPickerDateCurrent (to) {
      this.pickerDateCurrent = to
    },
    setDatesOnPickerAndInput (dates) {
      this.pickerDates = dates.map(d => moment(d).format('YYYY-MM-DD'))
      this.startDateFromInput = this.pickerDates[0]
      this.endDateFromInput = this.pickerDates[this.pickerDates.length - 1]
    },
    initializeComponent () {
      this.pickerDateCurrent = 'start'
      this.setDatesOnPickerAndInput(this.fullDates)

      // can't get following code to work
      // if (this.isOpen) {
      //   this.$nextTick(() => {
      //     console.log(this.$refs)
      //     this.$refs.startDateRef.focus()
      //   })
      // }
    },
    closeModal () {
      this.isOpen = false
    },
    applyChange () {
      this.isOpen = false
      this.$emit('updated', this.pickerDates)
    }
  }
}
</script>

<style scoped lang="stylus">
// .v-btn {
//   min-width: 0px;
// }
// .v-date-range__input-field >>> input {
//   text-align: center;
// }

.date-range-picker {
  >>> .v-date-picker-table {
    .v-btn {
      border-radius: 0;
    }
  }
  >>> .v-date-picker-table table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
    & th, & td {
      height: 32px;
    }
    & td {
      width: 14.28%;
      .v-btn {
        width: 100%;
        &.v-btn--outline {
          border: none;
          box-shadow: 0 0 0 1px currentColor inset;
        }
        &.v-btn--active::before {
          background-color: transparent !important;
        }
      }
    }
  }
  >>> .v-date-picker-table__events {
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
  }
  >>> .v-date-picker-table__events > div {
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 0;
    opacity: 0.35
  }
  // >>> .v-date-range__in-range {
  //   height: 100%;
  //   width: 100%;
  //   margin: 0;
  //   border-radius: 0;
  // }
}
</style>
