<template lang="pug">
    v-dialog(v-model='isDialogOpen', scrollable, width='600px' :fullscreen="$vuetify.breakpoint.xsOnly")
      template(v-slot:activator="{on}")
        a.info--text(v-on="on") Terms and Conditions
      v-card(:tile="$vuetify.breakpoint.xsOnly")
        v-toolbar(flat color="transparent")
          v-toolbar-title
            .text-h5 Campsite's Terms &amp; Conditions
        v-card-text
          div(v-html="termsAsHtml")
        v-divider
        v-card-actions
          v-spacer
          v-btn(text, @click="isDialogOpen = false" block) Close
          v-spacer

</template>

<script>
import supportApi from '@/services/support.api'
export default {
  data () {
    return {
      isDialogOpen: false,
      termsAsHtml: ''
    }
  },
  created () {
    supportApi.getLegalTerms().then(x => {
      this.termsAsHtml = x.body
    })
  }
}
</script>
