<template lang="pug">
  v-app-bar(v-if='!themeLoaded' app color='white' dark fixed dense clipped elevate-on-scroll)
  v-app-bar(v-else app color='white' dark fixed dense clipped elevate-on-scroll)
    v-btn.logo.px-2(v-if='customLogo' dark text router-link to='/')
      img(:src='customLogo' height='27')
    div(v-else)
      v-btn.logo.px-2(v-if='componentConfig.branding.defaultLogo' dark text router-link to='/')
        img(:src='componentConfig.branding.defaultLogo' height='29')
</template>

<script>
import componentConfigService from '@/services/componentConfig'

export default {
  computed: {
    themeLoaded () {
      return this.$store.getters['general/getIsThemeLoaded']
    },
    customLogo () {
      return this.$store.getters['general/getOrganizationLogo']
    },
    componentConfig () {
      return componentConfigService()
    }
  }
}
</script>
