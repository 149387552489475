<template lang="pug">
.home
  vue-headful(:title="componentConfig.branding.title('Billing')")
  .header
    v-container
      v-row.my-0
        v-col.pb-0(cols='12' sm='12' xl='10' offset-xl='1')
          .text-h4.text-truncate Billing Summary
          v-divider.my-3

      v-row.my-0
        v-col(cols='12' sm='6' lg='4' xl='3' offset-xl='1')
          v-skeleton-loader(type='image' height=88 v-if='summmaryCardsLoading')
          v-card(flat height='100%' style='background-color:transparent;' v-if='!summmaryCardsLoading')
            .text-overline Billing Information
            .text-h6 {{ organization.name }}
            .text-body-2.d-block(v-if="organization.address")
              span(v-if="organization.address.addressLine1") {{ organization.address.addressLine1 }}
              span(v-if='organization.address.addressLine2 && organization.address.addressLine2.length') , {{ organization.address.addressLine2 }} <br>
              span(v-if="organization.address.city && organization.address.state && organization.address.postalCode") {{ organization.address.city + ', ' + organization.address.state.name + ', ' + organization.address.postalCode }}
              span(v-if="organization.address.country") ,  {{ organization.address.country.name }}

        v-col(cols='12' sm='6' lg='4' xl='4')
          v-skeleton-loader(type='image' height=88 v-if='summmaryCardsLoading')
          v-card(flat height='100%' style='background-color:transparent;' v-if='!summmaryCardsLoading')
            .text-overline Payment Methods
            .text-body-2(v-if="!cards || !cards.length") No credit card has been added.

            savedCreditCards(
              v-if='cards && cards.length'
              :cards="cards"
              :disabled='!can.update.invoice.paidPaymentMethod'
              @closeAndRefresh="closeAndRefresh"
              style='background-color:transparent;')

            v-dialog(:fullscreen="$vuetify.breakpoint.xsOnly" max-width=600 v-model="isCreditCardDialogOpen")
              template(v-slot:activator="{ on }")
                v-btn.ma-0.pl-2(text color='primary' v-on="on" :disabled='!can.update.invoice.paidPaymentMethod')
                  v-icon.mr-2(small) mdi-plus
                  | credit card
              addCardDialog(
                action="add"
                :form-control="isCreditCardDialogOpen"
                @closeDialog="isCreditCardDialogOpen = false"
                @closeAndRefresh="closeAndRefresh"
                :cards="cards"
                :paymentMethods="paymentMethods"
                :organization="organization"
                )

        v-col(cols='6' sm='6' lg='2' xl='2' )
          v-skeleton-loader(type='image' height=88 v-if='summmaryCardsLoading')
          v-card(flat height='100%' style='background-color:transparent;' v-if='!summmaryCardsLoading')
            .text-overline Current Balance in {{ organization.currency.code }}
            .text-h6.font-weight-regular {{ pendingAmount | currency }}

        v-col(cols='6' sm='6' lg='2' xl='2')
          v-skeleton-loader(type='image' height=88 v-if='summmaryCardsLoading')
          v-card(flat height='100%' style='background-color:transparent;' v-if='!summmaryCardsLoading')
            .text-overline Next Bill
            .text-h6.font-weight-regular {{ nextBill | date('MMM Do, YYYY') }}

  //- Transacions
  div(v-if='showTransactionsTable')
    v-card.pt-3(color='transparent' flat)
      v-toolbar(color='transparent' flat tabs)
        v-toolbar-title.text-h5.hidden-sm-and-down Transactions
        v-text-field.ml-md-3(label='Find transactions' hide-details clearable v-model='transactionsSearch' prepend-inner-icon='mdi-magnify' solo)
        v-tabs(background-color='transparent' color='primary' slider-color='primary' slot='extension' :value='transactionsCurrentTab' @change='changeTransactionTab')
          v-tab(ripple color='primary lighten-4')
            | All
          v-tab(ripple)
            | Paid
          v-tab(ripple)
            | Pending
          v-tab(ripple)
            | Failed
      v-divider
    v-scroll-y-reverse-transition
      v-toolbar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selectedTransactions.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selectedTransactions.length < transactions.length') {{ assembleActionToolbarText(selectedTransactions.length, "transaction") }}
          v-toolbar-title.text-subtitle-1(v-if='selectedTransactions.length === transactions.length && transactions.length < transactionsTotalCount && !selectAllTransactions')
            | The {{ transactions.length }} transactions on this page are selected
          v-toolbar-title.text-subtitle-1(v-if='selectedTransactions.length === transactionsTotalCount || selectAllTransactions') All {{ transactionsTotalCount }} transactions are selected

        span.hidden-md-and-up
          v-chip.ma-1(v-if='selectedTransactions.length < transactionsTotalCount && !selectAllTransactions' dark outlined color='white' small)
            | {{ selectedTransactions.length }}
          v-chip.ma-1(v-if='selectedTransactions.length === transactionsTotalCount || selectAllTransactions' dark color='white' class='secondary--text' small)
            | {{ transactionsTotalCount }}

        v-btn.ml-2(text outlined small dark v-if='selectedTransactions.length === transactions.length && transactions.length < transactionsTotalCount && !selectAllTransactions' @click='selectAllTransactions = true') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ transactionsTotalCount }}&nbsp;
            | transactions

        v-toolbar-items.ml-3
          v-btn(:icon='$vuetify.breakpoint.smAndDown' :text='$vuetify.breakpoint.mdAndUp' @click='downloadCSV("transactions")')
            v-icon(:small='$vuetify.breakpoint.mdAndUp') mdi-download
            span.ml-2.hidden-sm-and-down Export CSV

        v-spacer
        v-btn.ml-3(icon @click='selectedTransactions = []')
            v-icon mdi-close

    v-data-table.cs-data-table(
      :server-items-length='transactionsTotalCount'
      :options.sync='paginationTransactions'
      :footer-props='{itemsPerPageOptions: rowsPerPageItems}'
      v-model='selectedTransactions'
      show-select
      :dense='$vuetify.breakpoint.xsOnly'
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      no-results-text=''
      :headers='transactionsHeaders'
      :items='transactions'
      :loading='fetchingTransactions'
      )

      template.text-left(v-slot:item="{ item, select, isSelected }" v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center
            v-flex.text-left.d-flex(xs8)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              v-chip.ma-1.mt-0.text-uppercase.xs-small(:color='statusColor(item.status)' small outlined)
                v-icon.mr-1.ml-0(:color='statusColor(item.status)' small) mdi-circle
                small(class='text--primary' style='white-space: nowrap;') {{ item.status }}

            v-flex.text-right(xs6)
              .text-caption.grey--text
                | {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}
          v-layout.mt-2.align-start
            v-flex(xs12)
              div.text-overline {{ item.organization.name }}
          v-layout.mt-0.mb-2.align-start
            v-flex(xs6)
                router-link.info--text(:to='{ name: "Invoice Page", params: { invoiceId: item.id } }') {{ item.invoiceNumber }}
            v-flex.text-right(xs6)
              .text-body-1
                | {{ item.amountIncludingTaxes | currency }}

      template(v-slot:item.createdOn='{ item }')
        | {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}

      template(v-slot:item.status='{ item }')
        v-chip.ma-1.text-uppercase.xs-small(:color='statusColor(item.status)' small outlined)
          v-icon.mr-1.ml-0(:color='statusColor(item.status)' small) mdi-circle
          small(class='text--primary' style='white-space: nowrap;') {{ item.status }}

      template(v-slot:item.organizationName='{ item }')
        | {{ item.organization.name }}

      template(v-slot:item.invoiceNumber='{ item }')
        router-link.info--text(:to='{ name: "Invoice Page", params: { invoiceId: item.id } }') {{ item.invoiceNumber }}

      template(v-slot:item.amountIncludingTaxes='{ item }')
        | {{ item.amountIncludingTaxes | currency }}

      template(v-slot:item.download='{ item }')
        .text-right
          v-btn(small text icon color='grey' @click='downloadPDF(item)' :loading="pdfLoadingButton.id === item.id && pdfLoadingButton.value")
            v-icon mdi-file-pdf-box
    v-divider
  //- Invoices
  div(v-if='showInvoicesTable')
    v-card.pt-3(color='transparent' flat)
      v-toolbar(color='transparent' flat tabs)
        v-toolbar-title.text-h5.hidden-sm-and-down Invoices
        v-text-field.ml-md-3(label='Find invoices' hide-details v-model='invoicesSearch' clearable prepend-inner-icon='mdi-magnify' solo)
        v-tabs(background-color='transparent' color='primary' slider-color='primary' slot='extension' :value='invoicesCurrentTab' @change='changeInvoiceTab')
          v-tab(ripple color='primary lighten-4')
            | All
          v-tab(ripple)
            | Paid
          v-tab(ripple)
            | To be paid
          v-tab(ripple)
            | Resolved
      v-divider
    v-scroll-y-reverse-transition
      v-toolbar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selectedInvoices.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selectedInvoices.length < invoices.length') {{ assembleActionToolbarText(selectedInvoices.length, "invoice") }}
          v-toolbar-title.text-subtitle-1(v-if='selectedInvoices.length === invoices.length && invoices.length < invoicesTotalCount && !selectAllInvoices')
            | The {{ invoices.length }} invoices on this page are selected
          v-toolbar-title.text-subtitle-1(v-if='selectedInvoices.length === invoicesTotalCount || selectAllInvoices') All {{ invoicesTotalCount }} invoices are selected

        span.hidden-md-and-up
          v-chip.ma-1(v-if='selectedInvoices.length < invoicesTotalCount && !selectAllInvoices' dark outlined color='white' small)
            | {{ selectedInvoices.length }}
          v-chip.ma-1(v-if='selectedInvoices.length === invoicesTotalCount || selectAllInvoices' dark color='white' class='secondary--text' small)
            | {{ invoicesTotalCount }}

        v-btn.ml-2(text outlined small dark v-if='selectedInvoices.length === invoices.length && invoices.length < invoicesTotalCount && !selectAllInvoices' @click='selectAllInvoices = true') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ invoicesTotalCount }}&nbsp;
            | invoices

        v-toolbar-items.ml-3
          v-btn(:icon='$vuetify.breakpoint.smAndDown' :text='$vuetify.breakpoint.mdAndUp' @click='downloadCSV("invoices")')
            v-icon(:small='$vuetify.breakpoint.mdAndUp') mdi-download
            span.ml-2.hidden-sm-and-down Export CSV

        v-spacer
        v-btn.ml-3(icon @click='selectedInvoices = []')
            v-icon mdi-close

    v-data-table.cs-data-table(
      :server-items-length='invoicesTotalCount'
      :options.sync='paginationInvoices'
      :footer-props='{itemsPerPageOptions: rowsPerPageItems}'
      v-model='selectedInvoices'
      show-select
      :dense='$vuetify.breakpoint.xsOnly'
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      no-results-text=''
      :headers='invoicesHeaders'
      :items='invoices'
      :loading='fetchingInvoices'
      )

      template.text-left(v-slot:item="{ item, select, isSelected }" v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center
            v-flex.text-left.d-flex(xs8)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              v-chip.ma-1.mt-0.text-uppercase.xs-small(:color='statusColor(item.status)' small outlined)
                v-icon.mr-1.ml-0(:color='statusColor(item.status)' small) mdi-circle
                small(class='text--primary' style='white-space: nowrap;') {{ item.status === 'Pending' ? 'To be paid' : item.status }}

            v-flex.text-right(xs6)
              .text-caption.grey--text
                | {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}
          v-layout.mt-2.align-start
            v-flex(xs12)
              div.text-overline {{ item.organization.name }}
          v-layout.mt-0.mb-2.align-start
            v-flex(xs6)
                router-link.info--text(:to='{ name: "Invoice Page", params: { invoiceId: item.id } }') {{ item.invoiceNumber }}
            v-flex.text-right(xs6)
              .text-body-1
                | {{ item.amountIncludingTaxes | currency }}

      template(v-slot:item.createdOn='{ item }')
        | {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}
      template(v-slot:item.dueDate='{ item }')
        span(:class="{'error--text': dueDateFormatted(item)}") {{ item.dueDate | date('MMM Do, YYYY') || 'N/A' }}
      template(v-slot:item.paidOn='{ item }')
        | {{ item.paidOn | date('MMM Do, YYYY hh:mm a') || 'N/A' }}

      template(v-slot:item.status='{ item }')
        v-chip.ma-1.text-uppercase.xs-small(:color='statusColor(item.status)' small outlined)
          v-icon.mr-1.ml-0(:color='statusColor(item.status)' small) mdi-circle
          small(class='text--primary' style='white-space: nowrap;') {{ item.status === 'Pending' ? 'To be paid' : item.status }}

      template(v-slot:item.organizationName='{ item }')
        div(style="min-width: 180px;")
          | {{ item.organization.name }}

      template(v-slot:item.invoiceNumber='{ item }')
        router-link.info--text(:to='{ name: "Invoice Page", params: { invoiceId: item.id } }') {{ item.invoiceNumber }}

      template(v-slot:item.amountIncludingTaxes='{ item }')
        | {{ item.amountIncludingTaxes | currency }}

      template(v-slot:item.paymentMethod='{ item }')
        v-btn(small text color='primary' v-if='!item.paidOn && item.status !== "Resolved"' :disabled='!can.update.invoice.paidPaymentMethod' @click='makePayment(item)') make payment
        span.text-center(v-if='item.paidOn && item.status !== "Resolved"') Manual Payment
        span.text-center(v-if='item.status === "Resolved"') N/A

      template(v-slot:item.download='{ item }')
        .text-right
          v-btn(small text icon color='grey' @click='downloadPDF(item)' :loading="pdfLoadingButton.id === item.id && pdfLoadingButton.value")
            v-icon(large) mdi-file-pdf-box
    v-divider

  v-dialog(:fullscreen='$vuetify.breakpoint.xsOnly' max-width=600 v-model='isMakePaymentDialogOpen')
    makePaymentModal(
      :invoice='makePaymentInvoice'
      @closeDialog='closeMakePaymentDialog')
</template>

<script>
import addCardDialog from '@/components/addCardDialog.vue'
import savedCreditCards from '@/components/savedCreditCards.vue'
import makePaymentModal from '@/components/makePaymentModal.vue'

import billingService from '@/services/billing.service'
import csvService from '@/services/csv.service'
import utils from '@/services/helpers.service'
import componentConfigService from '@/services/componentConfig'

import _ from 'lodash'

export default {
  components: {
    addCardDialog,
    savedCreditCards,
    makePaymentModal
  },
  data () {
    return {
      rowsPerPageItems: [10, 25, 50],
      pdfLoadingButton: { id: 0, value: false },
      isCreditCardDialogOpen: false,

      makePaymentInvoice: null,
      isMakePaymentDialogOpen: false,

      // Transactions related
      paginationTransactions: {
        sortBy: ['createdOn'],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
        totalItems: 0
      },
      transactionsSearch: '',
      selectedTransactions: [],
      selectAllTransactions: false,

      // Invoices related
      paginationInvoices: {
        sortBy: ['createdOn'],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
        totalItems: 0
      },
      invoicesSearch: '',
      selectedInvoices: [],
      selectAllInvoices: false
    }
  },
  computed: {
    profile () {
      return this.$store.getters['user/getProfile']
    },
    userRoles () {
      return this.$store.getters['user/getUserRoles']
    },
    organization () {
      return this.$store.getters['user/getOrganization']
    },
    can () {
      const invoicePerms = this.$store.getters['user/permissions']('invoice')
      const orgPerms = this.$store.getters['user/permissions']('organization')

      return {
        read: {
          allOrgs: orgPerms.read.allOrgs
        },
        update: {
          invoice: invoicePerms.update
        }
      }
    },

    // =========
    // Summaries
    // =========
    summmaryCardsLoading () {
      // Returns true when loading
      return !this.organization || this.summariesLoading || !this.profile
    },
    pendingAmount () {
      return this.$store.getters['billing/pendingAmount']
    },
    lastBill () {
      return this.$store.getters['billing/lastBill']
    },
    lastBillDate () {
      return this.lastBill ? this.lastBill.createdOn : ''
    },
    nextBill () {
      return this.$store.getters['billing/nextBill']
    },
    fetchingPaymentMethods () {
      return this.$store.getters['billing/fetchingPaymentMethods']
    },
    summariesLoading () {
      return this.$store.getters['billing/summariesLoading']
    },
    cards () {
      return this.$store.getters['billing/cards']
    },
    paymentMethods () {
      return this.$store.getters['billing/paymentMethods']
    },

    // ==================
    // Transactions Table
    // ==================
    showTransactionsTable () {
      return this.can.read.allOrgs || (this.organization && this.organization.paymentType === 'Automatic')
    },
    transactionsHeaders () {
      const coreList = [
        { text: 'Date', align: 'left', value: 'createdOn', width: '190px' },
        { text: 'Status', align: 'left', value: 'status' },
        { text: 'Transaction ID', align: 'left', value: 'invoiceNumber' },
        { text: 'Payment Method', align: 'left', value: 'paymentMethodLabel', sortable: false },
        { text: 'Amount Billed', align: 'right', value: 'amountIncludingTaxes' },
        { text: 'Download', align: 'right', value: 'download', sortable: false }
      ]
      if (this.can.read.allOrgs) {
        coreList.splice(2, 0, { text: 'Organization', align: 'left', value: 'organizationName' })
      }
      return coreList
    },
    fetchingTransactions () {
      return this.$store.getters['billing/fetchingTransactions']
    },
    transactions () {
      return this.$store.getters['billing/transactions']
    },
    transactionsTotalCount () {
      return this.$store.getters['billing/transactionsTotalCount']
    },
    transactionsCurrentTab () {
      return this.$store.getters['billing/transactionsCurrentTab']
    },

    // ==============
    // Invoices Table
    // ==============
    showInvoicesTable () {
      return this.can.read.allOrgs || (this.organization && this.organization.paymentType === 'Manual')
    },
    invoicesHeaders () {
      const coreList = [
        { text: 'Date', align: 'left', value: 'createdOn', width: '190px' },
        { text: 'Status', align: 'left', value: 'status' },
        { text: 'Invoice ID', align: 'left', value: 'invoiceNumber' },
        { text: 'Due Date', align: 'left', value: 'dueDate', width: '150px' },
        { text: 'Paid On', align: 'left', value: 'paidOn', width: '190px' },
        { text: 'Amount', align: 'right', value: 'amountIncludingTaxes' },
        { text: 'Payment Method', align: 'center', value: 'paymentMethod', sortable: false },
        { text: 'Download', align: 'right', value: 'download', sortable: false }

      ]
      if (this.can.read.allOrgs) {
        coreList.splice(2, 0, { text: 'Organization', align: 'left', value: 'organizationName' })
      }
      return coreList
    },
    fetchingInvoices () {
      return this.$store.getters['billing/fetchingInvoices']
    },
    invoices () {
      return this.$store.getters['billing/invoices']
    },
    invoicesTotalCount () {
      return this.$store.getters['billing/invoicesTotalCount']
    },
    invoicesCurrentTab () {
      return this.$store.getters['billing/invoicesCurrentTab']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  created () {
    if (this.userRoles.length) {
      this.getSummariesDetails()
    }
  },
  watch: {
    paginationTransactions: function (newVal, oldVal) {
      if (this.userRoles.length && !this.fetchingTransactions) {
        this.getTransactions()
      }
    },

    transactionsSearch: _.debounce(function (newVal, oldVal) {
      this.getTransactions()
    }, 450),

    paginationInvoices: function (newVal, oldVal) {
      if (this.userRoles.length && !this.fetchingInvoices) {
        this.getInvoices()
      }
    },

    invoicesSearch: _.debounce(function (newVal, oldVal) {
      this.getInvoices()
    }, 450),

    selectedTransactions: function () {
      if (this.selectedTransactions.length < this.transactions.length) {
        this.selectAllTransactions = false
      }
    }
  },

  methods: {
    getSummariesDetails () {
      const obj = {
        organizationId: this.profile.organizationId
      }
      this.$store.dispatch('billing/getSummaryDetails', obj)
    },

    statusIcon (status) {
      if (status === 'Paid') { return 'mdi-check-circle' }
      if (status === 'Resolved') { return 'mdi-check-circle' }
      if (status === 'Pending') { return 'mdi-alert-circle' }
      if (status === 'Failed') { return 'mdi-close-circle' }
    },
    statusColor (status) {
      if (status === 'Paid') { return 'success' }
      if (status === 'Resolved') { return 'success' }
      if (status === 'Pending') { return 'warning' }
      if (status === 'Failed') { return 'error' }
    },
    getTransactions () {
      const { sortBy, sortDesc, page, itemsPerPage, totalItems } = this.paginationTransactions
      // console.log(sortBy[0], sortDesc[0], page, itemsPerPage, totalItems)

      const obj = {
        organizationId: this.can.read.allOrgs ? 0 : this.profile.organizationId,
        pagination: {
          sortBy: sortBy[0] === 'organizationName' ? ['organization/name'] : sortBy,
          sortDesc,
          page,
          itemsPerPage,
          totalItems
        },
        search: this.transactionsSearch
      }

      this.$store.dispatch('billing/getTransactions', obj)
    },
    getInvoices () {
      const { sortBy, sortDesc, page, itemsPerPage, totalItems } = this.paginationInvoices
      // console.log(sortBy[0], sortDesc[0], page, itemsPerPage, totalItems)

      const obj = {
        organizationId: this.can.read.allOrgs ? 0 : this.profile.organizationId,
        pagination: {
          sortBy: sortBy[0] === 'organizationName' ? ['organization/name'] : sortBy,
          sortDesc,
          page,
          itemsPerPage,
          totalItems
        },
        search: this.invoicesSearch
      }

      this.$store.dispatch('billing/getInvoices', obj)
    },

    getTablesData () {
      if (this.showTransactionsTable) { this.getTransactions() }
      if (this.showInvoicesTable) { this.getInvoices() }
    },

    changeInvoiceTab (index) {
      this.$store.commit('billing/setInvoicesCurrentTab', index)
      this.getInvoices()
    },

    changeTransactionTab (index) {
      this.$store.commit('billing/setTransactionsCurrentTab', index)
      this.getTransactions()
    },

    assembleActionToolbarText (nbr, type) {
      // Builds the sentence similar to "3 campaigns selected"
      let adjustedInstance = null
      if (nbr > 1) { adjustedInstance = type + 's' } else { adjustedInstance = type }
      return `${nbr} ${adjustedInstance} selected`
    },

    downloadPDF (transaction) {
      this.pdfLoadingButton = { id: transaction.id, value: true }

      billingService.getInvoice(transaction.id)
        .then(invoice => {
          return billingService.downloadPDF(invoice)
        })
        .then(() => {
          this.pdfLoadingButton = { id: transaction.id, value: false }
        })
    },

    downloadCSV (type) {
      const obj = {
        organizationId: this.can.read.allOrgs ? 0 : this.profile.organizationId,
        search: this[type + 'Search'],
        type: type
      }

      if (this['selectAll' + utils.capitalizeString(type)]) {
        obj.pagination = {
          sortBy: this['pagination' + utils.capitalizeString(type)].sortBy[0] === 'organizationName' ? ['organization/name'] : this['pagination' + utils.capitalizeString(type)].sortBy,
          sortDesc: this['pagination' + utils.capitalizeString(type)].sortDesc,
          itemsPerPage: 0,
          page: 1
        }
      } else {
        if (this['selected' + utils.capitalizeString(type)].length === this[type].length) {
          obj.pagination = this['pagination' + utils.capitalizeString(type)]
        } else if (this['selected' + utils.capitalizeString(type)].length <= 15) {
          obj.filters = ['(' + this['selected' + utils.capitalizeString(type)].map(x => 'id eq ' + x.id).join(' or ') + ')']
          obj.pagination = this['pagination' + utils.capitalizeString(type)]
        } else {
          obj.filters = ['(' + this[type].filter(y => !this['selected' + utils.capitalizeString(type)].map(w => w.id).includes(y.id)).map(x => 'id ne ' + x.id).join(' and ') + ')']
          obj.pagination = this['pagination' + utils.capitalizeString(type)]
          obj.pagination.itemsPerPage = this['selected' + utils.capitalizeString(type)].length
        }
        if (obj.pagination.sortBy[0] === 'organizationName') obj.pagination.sortBy = ['organization/name']
      }
      this.$store.dispatch('billing/downloadCsv', obj).then(res => {
        csvService.csvExport(res, type)
      })
    },

    dueDateFormatted (invoice) {
      return invoice.dueDateReached && invoice.status === 'Pending'
    },

    closeAndRefresh (action) {
      if (action === 'add') { this.isCreditCardDialogOpen = false }
      this.$store.dispatch('billing/getActivePaymentMethods', this.organization.id)
    },

    makePayment (invoice) {
      this.makePaymentInvoice = invoice
      this.isMakePaymentDialogOpen = true
    },

    closeMakePaymentDialog () {
      this.isMakePaymentDialogOpen = false
      // this.makePaymentInvoice = null
    }
  }
}

</script>
