<template lang="pug">
  v-layout(wrap row align-center justify-space-between)
    //- STATS
    v-flex.py-1.text-center.text-md-left(xs12 sm4 )
      div(style="min-height:32px;")

          div.d-flex(:class="{'justify-center': $vuetify.breakpoint.xsOnly}")
            v-tooltip.d-inline-block.mr-3.venues-icon(top dark color='secondary' max-width='300'
                v-if="componentConfig.campaigns.isVenuesShown")
              template(v-slot:activator="{on}")
                h3.text-h5.d-flex(v-on="on" style='min-width:85px')
                  v-icon.mr-1(medium style='height:32px;') mdi-map-marker
                  v-scroll-y-reverse-transition
                    div(v-if='!totalsLoading') {{ inventory.numberOfVenues | numberWithCommas }}
              span
                .text-caption Venues with stats during the selected period

            v-tooltip.d-inline-block(top dark color='secondary' max-width='300')
              template(v-slot:activator="{on}")
                h3.text-h5.d-flex(v-on="on" style='min-width:85px')
                  v-icon.mr-1(medium style='height:32px;') mdi-television
                  v-scroll-y-reverse-transition
                    div(v-if='!totalsLoading') {{ inventory.numberOfScreens | numberWithCommas }}
              span
                .text-caption Screens with stats during the selected period

    //- DATE RANGE PICKER
    v-flex.text-left.text-sm-right.text-md-right(xs12 sm8 md5 style="min-height:40px;")
      v-layout(row wrap justify-end)
        v-flex.my-0.justify-end
          v-scroll-y-reverse-transition
            div(v-if='!datesLoading')

              //- DESKTOP
              v-menu(
                v-if='$vuetify.breakpoint.smAndUp && !datesLoading'
                v-model='isDateMenuOpen'
                :close-on-click='true'
                :close-on-content-click='false'
                :disabled='areDatesInTheFuture || chartLoading'
                offset-y
              )
                template(v-slot:activator="{on}")
                  v-btn.my-0(text color='primary' v-on="on" :disabled='areDatesInTheFuture || chartLoading')
                    v-icon.mr-2(left small) mdi-calendar-range
                    | {{ fromToDates[0] | date('MMM Do, YYYY') }} - {{ fromToDates[1] | date('MMM Do, YYYY') }}

                dateRangePicker(
                  :report-dates='fromToDates'
                  :instance-dates='instanceDates'
                  :isOpen='isDateMenuOpen'
                  @startEndDatesChange='startEndDatesChange'
                  @closeModal='closeDatePicker'
                )

              //- MOBILE
              v-dialog.d-block(
                v-if='$vuetify.breakpoint.xsOnly && !datesLoading'
                v-model='isDateMenuOpen'
                fullscreen
              )
                template(v-slot:activator="{on}")
                  v-btn.my-0(v-if='!areDatesInTheFuture' text :block='$vuetify.breakpoint.smAndDown' color='primary' v-on="on" :disabled='chartLoading')
                    v-icon.mr-1(left small) mdi-calendar-range
                    | {{ fromToDates[0] | date('MMM Do, YYYY') }} - {{ fromToDates[1] | date('MMM Do, YYYY') }}

                dateRangePicker(
                  :report-dates='fromToDates'
                  :instance-dates='instanceDates'
                  :isOpen='isDateMenuOpen'
                  @startEndDatesChange='startEndDatesChange'
                  @closeModal='closeDatePicker'
                )

    //- TIME VIEW OPTIONS
    v-flex.d-shrink(xs12 sm6 md3 :class="{'py-3': $vuetify.breakpoint.xsOnly}")
      v-item-group
        v-layout.justify-end(wrap row :class="{'justify-center': $vuetify.breakpoint.xsOnly}")
          v-flex.px-0.shrink(v-for='(option, index) in timeCtrlOptions' :key='option.key')
            v-item
              v-chip.elevation-0.mx-1(
                slot-scope='{ active, toggle }'
                :color="activeTimeUnit === index ? 'primary' : 'blue-grey lighten-5'"
                :dark="activeTimeUnit === index"
                :ripple="{ class: 'primary--text' }"
                @click='updateTimeUnit(index)'
                :disabled='chartLoading'
                small
                :ref='option.key'
              )
                div.text-uppercase {{ option.label }}
</template>

<script>
import dateRangePicker from '@/components/dateRangePicker.vue'

import helpersService from '@/services/helpers.service'
import reportingService from '@/services/reporting.service'

import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    dateRangePicker
  },
  props: ['chartLoading', 'totalsLoading', 'inventory', 'timeUnit', 'datesLoading', 'fromToDates', 'instanceDates'],
  computed: {
    activeTimeUnit () {
      return this.timeCtrlOptions.findIndex(x => x.key === this.timeUnit)
    },
    areDatesInTheFuture () {
      const location = reportingService.whereIsInstanceInTime(this.instanceDates[0], this.instanceDates[1])
      return location === 'future'
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  data () {
    return {
      isDateMenuOpen: false,

      timeCtrlOptions: [
        { key: 'hour', label: 'Hourly' },
        { key: 'day', label: 'daily' },
        { key: 'week', label: 'weekly' },
        { key: 'month', label: 'monthly' }
      ]
    }
  },
  methods: {
    formatting (val, outputFormat) {
      return helpersService.dataDisplayFormatting(val, outputFormat)
    },

    updateTimeUnit (index) {
      if (!this.chartLoading) {
        this.$emit('timeUnitUpdated', this.timeCtrlOptions[index].key)
      }
    },

    closeDatePicker () {
      this.isDateMenuOpen = false
    },

    startEndDatesChange (dates) {
      this.$emit('startEndDatesChange', dates)
      this.isDateMenuOpen = false
    }
  }
}
</script>
