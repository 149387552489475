<template lang='pug'>
div
  vue-headful(:title="componentConfig.branding.title(pageHeader)")
  v-toolbar.pt-3.elevation-0(color='transparent')
    v-container.py-2(grid-list-lg text-left fluid)
      v-layout(row wrap justify-start align-center  style='min-height: 68px;')
        v-flex(xs12)
          .d-flex.align-center
            v-btn.text-h6.ml-0.mr-2(fab small color='white' router-link :to="{name: 'Manage Organizations'}")
              v-icon(medium) mdi-arrow-left
            .text-h6.d-inline-block.text-truncate(class='secondary--text') {{ pageHeader }}

  v-form(ref='orgForm' v-model='formValidated')
    v-container(grid-list-lg text-xs-left)
      v-layout(row wrap)
        v-flex(xs12 md8 xl6 offset-md2 offset-xl3)
          v-card(:loading='pageLoading')
            v-container(grid-list-lg)
              v-layout(row wrap)
                v-flex(xs12)
                  .text-h5 Organization Information
              v-layout(row wrap)
                v-flex(sm12 md12)
                  v-text-field(label='Organization or Business Name' v-model='orgInfo.name' :rules='[rules.required]')

              //- v-layout
                v-flex
                  .text-subtitle-1 Where do you want to run your campaigns?
              v-layout(row wrap)
                v-flex(sm12 md6)
                  v-select(v-model='orgInfo.currency' :items='currencies' label='Currency' item-text='label' item-value='code' :rules='[rules.required]' :disabled='isEditPage' persistent-hint hint='Cannot be changed later.' return-object)
                v-flex(sm12 md6)
                  v-select(v-model='orgInfo.timeZone' :items='timeZones' label='Time Zone' item-text='label' item-value='value' :rules='[rules.required]' :disabled='isEditPage' persistent-hint hint='Cannot be changed later.')

            v-container(grid-list-lg)
              v-layout
                v-flex
                  .text-h5 Address

              v-layout(row wrap)
                v-flex(sm12 md9)
                  v-text-field(label='Address Line 1' v-model='orgAddress.addressLine1')
                v-flex(sm12 md3)
                  v-text-field(label='Suite / Office Number' v-model='orgAddress.addressLine2')

              v-layout(row wrap)
                v-flex(sm12 md6)
                  v-text-field(label='City' v-model='orgAddress.city')
                v-flex(sm12 md6)
                  v-text-field(:label='marketVals.postcodeLabel' v-model='orgAddress.postalCode')

              v-layout(row wrap)
                v-flex(sm12 md6)
                  v-autocomplete(
                    label='Country'
                    v-model='orgAddress.country'
                    return-object
                    :items='countriesAndStates'
                    item-text='name'
                    autocomplete='nope'
                    :rules='[rules.required]')
                v-flex(sm12 md6)
                  v-autocomplete(
                    :label='marketVals.stateLabel'
                    v-model='orgAddress.state'
                    return-object
                    :items='availableStatesOrg'
                    item-text='name'
                    autocomplete='nope'
                    :rules='[rules.required]')

              v-layout(row wrap)
                v-flex(sm12 md12)
                  v-checkbox(label='Use same billing information as organization address' v-model='uniqueAddress' color='primary')

              v-expand-transition
                div(v-if='!uniqueAddress')
                  v-layout(row wrap)
                    v-flex(sm12)
                      .text-h5 Billing Information
                  v-layout(row wrap)
                    v-flex(sm12)
                      v-text-field(label='Billing Organization Name' v-model='billingName')
                  v-layout(row wrap)
                    v-flex(sm12 md9)
                      v-text-field(label='Address Line 1' v-model='orgBillingAddress.addressLine1')
                    v-flex(sm12 md3)
                      v-text-field(label='Suite / Office Number' v-model='orgBillingAddress.addressLine2')

                  v-layout(row wrap)
                    v-flex(sm12 md6)
                      v-text-field(label='City' v-model='orgBillingAddress.city')
                    v-flex(sm12 md6)
                      v-text-field(:label='marketVals.postcodeLabel' v-model='orgBillingAddress.postalCode')

                  v-layout(row wrap)
                    v-flex(sm12 md6)
                      v-autocomplete(label='Country' v-model='orgBillingAddress.country' return-object :items='countriesAndStates' item-text='name' autocomplete='nope' :rules='[rules.billingAddressRequired]')
                    v-flex(sm12 md6)
                      v-autocomplete(:label='marketVals.stateLabel' v-model='orgBillingAddress.state' return-object :items='availableStatesBilling' item-text='name' autocomplete='nope' :rules='[rules.billingAddressRequired]' :disabled='!orgBillingAddress.country')

                  v-layout(row wrap)
                    v-flex(sm12)
                      //- v-text-field(label='Invoice Email Recipients'
                        )
                      v-combobox(
                        multiple
                        chips
                        clearable
                        label="Invoice Email Recipients"
                        v-model="emailList"
                        v-on:keyup.enter="addEmails()"
                        v-on:keyup.tab="addEmails()"
                        )
                        template(v-slot:selection='data')
                          v-chip(
                            v-bind='data.attrs'
                            :input-value='data.selected'
                            :disabled='data.disabled'
                            @click.stop="openEditBillingDialog(data.item)"
                            @click:close='data.parent.selectItem(data.item)'
                            close
                            label
                            )
                            v-icon.mr-1(small :color="!billingEmailsValid[data.item.id] ? 'error' : 'primary'")
                              | {{ !billingEmailsValid[data.item.id] ? 'mdi-alert-circle' : 'mdi-check-bold' }}
                            |  {{ data.item.email }}

                    v-dialog(v-model="editBillingEmailDialogOpen" max-width=400)
                      v-card
                        v-card-title Edit
                        v-card-text
                          v-text-field(
                            v-model="temporayLocalEmail.email"
                            autofocus
                            )
                        v-card-actions
                          v-spacer
                          v-btn(text @click="editBillingEmailDialogOpen = false") cancel
                          v-btn(text color="primary" @click="updateBillingEmail()") save

            v-container(grid-list-lg)
              v-layout(row wrap)
                v-flex(sm12 md12)
                  .text-h5 Primary Contact

              v-layout.mb-1(row wrap)
                v-flex(sm12 md6)
                  v-text-field(label='First Name' v-model='primaryContact.firstName')
                v-flex(sm12 md6)
                  v-text-field(label='Last Name' v-model='primaryContact.lastName')

              v-layout(row wrap)
                v-flex(sm12 md6)
                  v-text-field(label='Email' v-model='email' prepend-inner-icon='mdi-email' :rules='rules.validEmailIfProvided')
                v-flex(sm12 md6)
                  v-text-field(label='Phone Number' v-model='primaryContact.phone' prepend-inner-icon='mdi-phone')
          .text-md-right
            v-spacer
            v-btn.my-3.mx-0(rounded large color='primary' :block='$vuetify.breakpoint.xsOnly' :loading='saveBtnLoading' :disabled='!formValidated' @click='save()') {{ isEditPage? 'save changes' : 'add organization'}}
</template>

<script>
import geoService from '@/services/geo.service'
import helpers from '@/services/helpers.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import campaignsAPI from '@/services/campaigns.api'
import userApi from '@/services/user.api'
import tracking from '@/services/tracking'
import componentConfigService from '@/services/componentConfig'

export default {
  data () {
    return {
      pageLoading: false,
      saveBtnLoading: false,
      rawOrg: null,
      uniqueAddress: true,
      rules: {
        required: value => !!value || 'Required',
        validEmail: v => /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(v) || 'E-mail must be valid',
        validEmailIfProvided: [],
        billingAddressRequired: val => val
      },
      formValidated: true,

      // Static lists
      // timeZones: [],
      currencies: [],
      countriesAndStates: [],

      // ===================
      // New account objects
      // ===================
      orgInfo: {
        name: '',
        timeZone: '',
        currency: null,
        industry: null
      },

      orgAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: '',
        country: null,
        state: null
      },

      billingName: '',

      orgBillingAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: '',
        country: '',
        state: ''
      },

      primaryContact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },

      email: '',

      // Billing emails related
      editBillingEmailDialogOpen: false,
      indexOfEmailEdited: null,
      emailList: [], // [{id: 1, email: 'abc@gmail.com'}, {id: 2, email: "xyz@gmail.com"}],
      temporayLocalEmail: ''
    }
  },
  created () {
    if (!this.canUpdate) this.$router.push({ name: 'Access Denied' })
    else {
      this.pageLoading = true
      geoService.getCountriesAndStates()
        .then(res => {
          this.countriesAndStates = res

          userApi.getCurrencies().then(currencies => {
            const isGlobalAdmin = this.$store.getters['user/getUserRoles'].includes('GlobalAdmin')
            this.currencies = currencies
              .filter(x => defaultExchangeValues.getDefaultValuesByCurrency(x.code) && (isGlobalAdmin || defaultExchangeValues.getDefaultValuesByCurrency(x.code).isPublic))
              .map(c => {
                // build prettier label
                c.label = c.name + ' (' + c.code + ')'
                return c
              })

            if (!this.isEditPage) {
              this.orgInfo.currency = this.currencies.find(x => x.code === this.userOrganization.currency.code)
            }
          })

          if (this.isEditPage) {
            // for Editing, force the use of "Organization Settings" page,
            // rather than this simple form
            this.$router.push({ name: 'Organization Settings', organizationId: this.$route.params.organizationId })
          } else {
            this.orgInfo.timeZone = this.userOrganization.timezone
            this.pageLoading = false
          }
        })

      this.rules.billingAddressRequired = v => {
        if (!this.uniqueAddress && !v) { return 'Required field' } else { return true }
      }
    }
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    userOrganization () {
      return this.$store.getters['user/getOrganization']
    },
    timeZones () {
      let countryCode = 'US'

      // format Timezones from User's currency by default
      const currency = this.orgInfo.currency || this.currencies.find(x => x.code === this.userOrganization.currency.code)
      if (currency) {
        countryCode = currency.code.slice(0, -1)
      }

      const zones = [countryCode]
      return helpers.generateTimezones(zones)
    },
    isEditPage () {
      return this.$route.params.organizationId !== 'add'
    },
    pageHeader () {
      if (this.isEditPage) return this.orgInfo.name.length ? 'Edit Organization: ' + this.orgInfo.name : 'Edit Organization'
      return 'Add Organization'
    },
    canUpdate () {
      var orgPerms = this.$store.getters['user/permissions']('organization')
      return orgPerms.update.default
    },
    availableStatesOrg () {
      return this.orgAddress.country ? this.countriesAndStates.find(c => c.id === this.orgAddress.country.id).states : []
    },
    availableStatesBilling () {
      return this.orgBillingAddress.country ? this.countriesAndStates.find(c => c.id === this.orgBillingAddress.country.id).states : []
    },
    billingEmailsValid () {
      const obj = {}

      this.emailList.forEach(element => {
        obj[element.id] = this.validateEmail(element.email)
      })
      return obj
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  watch: {
    // add email validation when value provided
    email (newVal, oldVal) {
      if (newVal.length) {
        this.rules.validEmailIfProvided.push(this.rules.validEmail)
      } else {
        this.rules.validEmailIfProvided = []
      }
    },

    emailList (newVal, oldVal) {
      if (newVal.length > oldVal.length) this.addEmails()
    }
  },
  methods: {
    prefillAddress (nameLocal, nameApi) {
      this[nameLocal].addressLine1 = this.rawOrg[nameApi].addressLine1
      this[nameLocal].addressLine2 = this.rawOrg[nameApi].addressLine2 ? this.rawOrg[nameApi].addressLine2 : ''
      this[nameLocal].city = this.rawOrg[nameApi].city
      this.$set(this[nameLocal], 'country', this.rawOrg[nameApi].country)
      this.$set(this[nameLocal], 'state', this.rawOrg[nameApi].state)
      this[nameLocal].postalCode = this.rawOrg[nameApi].postalCode
    },

    prefillOrg () {
      this.orgInfo.name = this.rawOrg.name
      this.timeZones = helpers.formatTimezones([this.rawOrg.timezone])
      this.orgInfo.timeZone = this.rawOrg.timezone
      this.orgInfo.currency = this.rawOrg.currency

      this.prefillAddress('orgAddress', 'address')
      if (this.rawOrg.billingAddress) {
        this.uniqueAddress = false
        this.prefillAddress('orgBillingAddress', 'billingAddress')
        this.billingName = this.rawOrg.billingAddress.name
      }

      if (this.rawOrg.contacts.length) {
        this.primaryContact = this.rawOrg.contacts[0]
        this.email = this.rawOrg.contacts[0].email
      }
    },

    save () {
      if (this.$refs.orgForm && this.$refs.orgForm.validate()) {
        this.saveBtnLoading = true

        const data = {
          name: this.orgInfo.name,
          address: {
            addressLine1: this.orgAddress.addressLine1,
            addressLine2: this.orgAddress.addressLine2,
            city: this.orgAddress.city,
            country: this.orgAddress.country,
            state: this.orgAddress.state,
            postalCode: this.orgAddress.postalCode
          }
        }

        // don't erase Country / State
        if (!data.address.country && this.rawOrg && this.rawOrg.address.country) { data.address.country = this.rawOrg.address.country }
        if (!data.address.state && this.rawOrg && this.rawOrg.address.state) { data.address.state = this.rawOrg.address.state }

        // add/edit
        if (this.isEditPage) {
          data.id = this.$route.params.organizationId
        } else {
          data.currency = this.currencies.find(x => x.code === this.orgInfo.currency.code)
          data.timezone = this.orgInfo.timeZone
        }

        // Billing address
        if (!this.uniqueAddress) {
          data.billingAddress = {
            addressLine1: this.orgBillingAddress.addressLine1,
            addressLine2: this.orgBillingAddress.addressLine2,
            city: this.orgBillingAddress.city,
            country: this.orgBillingAddress.country,
            state: this.orgBillingAddress.state,
            postalCode: this.orgBillingAddress.postalCode,
            name: this.billingName
          }
        }

        const orgPromise = this.isEditPage ? userApi.editOrganization(data) : userApi.createOrganization(data)
        orgPromise
          .then(res => {
            if (this.isEditPage) {
              // update Store if editing own Organization
              if (this.userOrganization.id === res.id) {
                this.$store.commit('user/setOrganization', res)
              }

              this.processContacts(res.id)
                .then(contacts => {
                  this.success()
                })
            } else {
              // create Account of type "Buyer/Agency" (advertiserId = buyerId) when adding new Organization, so it appears in "Manage Organizations"
              var newAccount = {
                advertiserId: res.id,
                buyerId: res.id,
                name: res.name
              }

              campaignsAPI.createAccount(newAccount)
                .then(resp => {
                  this.processContacts(res.id)
                    .then(contacts => {
                      this.success()
                    })
                })
            }

            if (res) {
              this.saveBillingEmails(res.id)
            }
          })
          .catch(error => {
            this.saveBtnLoading = false

            let msg = error.response.data.errors[0].errorCode
            if (msg === 'unexpected') {
              msg = 'Unexpected: ' + error.response.data.errors[0].id
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
      }
    },
    processContacts (organizationId) {
      const contact = this.primaryContact
      contact.email = this.email

      // Contact fields are NOT required, make sure we have at least one
      if (contact.firstName || contact.lastName || contact.phone || contact.email) {
        return contact.id
          ? userApi.editOrganizationContact(organizationId, contact)
          : userApi.createOrganizationContact(organizationId, contact)
      } else {
        return new Promise((resolve, reject) => {
          resolve()
        })
      }
    },
    success () {
      this.saveBtnLoading = false

      this.$store.commit('snackbar/setSnackbar', {
        type: 'success',
        msg: 'Organization successfully ' + (this.isEditPage ? 'updated' : 'added')
      })

      if (!this.isEditPage) {
        tracking.sendEvent(['ga'], 'createdOrganization')
      }

      this.$router.push('/organizations')
    },

    openEditBillingDialog (contact) {
      this.temporayLocalEmail = JSON.parse(JSON.stringify(contact))
      this.editBillingEmailDialogOpen = true
    },
    updateBillingEmail () {
      const newContact = JSON.parse(JSON.stringify(this.temporayLocalEmail))
      const index = this.emailList.findIndex(x => x.id === newContact.id)

      if (index >= 0) {
        this.emailList.splice(index, 1)
        this.emailList.splice(index, 0, newContact)
        this.editBillingEmailDialogOpen = false
      }
    },
    addEmails () {
      const entries = this.emailList
        .map(entry => {
          return typeof entry === 'string'
            ? entry.split(/\r|;\s*|,\s*|\n/g).map(x => { return { id: 'tempid-' + x, email: x.trim() } })
            : entry
        })
        .flat()

      this.emailList = entries.reduce((unique, x) => {
        return typeof x.id !== 'string' || !unique.map(x => x.email).includes(x.email) ? [...unique, x] : unique
      }, [])
    },
    validateEmail (email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    },
    saveBillingEmails (orgId) {
      const originalList = this.rawOrg && this.rawOrg.billingContacts
        ? this.rawOrg.billingContacts
        : []
      const emailsCopy = [...this.emailList]

      emailsCopy.forEach(item => {
        if (!originalList.map(x => x.id).includes(item.id) && this.validateEmail(item.email)) {
          // Add
          userApi.addBillingContact(item.email, orgId)
        } else if (this.validateEmail(item.email) && originalList.find(x => x.id === item.id && x.email !== item.email)) {
          // patch
          userApi.deleteBillingContact(item.id, orgId)
          userApi.addBillingContact(item.email, orgId)
        }
      })

      originalList.forEach(item => {
        if (!emailsCopy.map(x => x.id).includes(item.id)) {
          // remove
          userApi.deleteBillingContact(item.id, orgId)
        }
      })
    }
  }
}
</script>
