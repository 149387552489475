import page404 from '../views/404.vue'
import accessDenied from '../views/accessDenied.vue'

import login from '../views/login.vue'
import logout from '../views/logout.vue'
import signup from '../views/signup.vue'
import supportPages from '../views/supportPages.vue'

import createCampaign from '../views/createCampaign.vue'

import audiencesContainer from '../views/audiencesContainer.vue'
import audiencesOverview from '../views/audiencesOverview.vue'
import audiencePage from '../views/audience.vue'
import addAudience from '../views/addAudience.vue'

import auctionPackagesOverview from '../views/auctionPackagesOverview.vue'
import auctionPackagesContainer from '../views/auctionPackagesContainer.vue'

import proposalPage from '../views/proposal.vue'
import dealsOverview from '../views/dealsOverview.vue'

import campaignsOverview from '../views/campaignsOverview.vue'
import campaignReport from '../views/campaignReport.vue'
import plansOverview from '../views/plansOverview.vue'

import creativesOverview from '../views/creativesOverview.vue'
import creativesContainer from '../views/creativesContainer.vue'

import billing from '../views/billing.vue'
import invoice from '../views/invoice.vue'

import manageOrganizations from '../views/manageOrganizations.vue'
import organizationSettings from '../views/organizationSettings.vue'
import organizationForm from '../views/organizationForm.vue'

import manageUsers from '../views/manageUsers.vue'
import userPage from '../views/user.vue'
import acceptInvitation from '../views/acceptInvitation.vue'

import notifications from '../views/notifications.vue'

import dashboard from '../views/dashboard.vue'
import reportEditor from '../views/reportEditor.vue'
import report from '../views/report.vue'
import reports from '../views/reports.vue'
import generalEditor from '../views/generalEditor.vue'

import store from '../store'
import flags from '@/plugins/rox/flags'

function can (entity) {
  return store.getters['user/permissions'](entity)
}

const routes = [
  // Wild card
  {
    path: '*',
    beforeEnter (to, from, next) {
      next({ name: '404' })
    },
    meta: { protected: true }
  },
  {
    path: '/',
    name: 'Home',
    beforeEnter (to, from, next) {
      const canSeeNewUi = flags.canSeeUiRebrand.isEnabled()
      const canOnlyReadProposals = !can('campaign').read.default && can('proposal').read.default

      const mainRoute = canSeeNewUi && canOnlyReadProposals ? 'plans' : 'campaigns'
      const subRoute = !canSeeNewUi && canOnlyReadProposals ? 'proposed' : 'all'

      next({ path: `/${mainRoute}/${subRoute}` })
    },
    meta: { protected: true }
  },
  {
    path: '/404',
    name: '404',
    component: page404,
    meta: { protected: true }
  },
  {
    path: '/access-denied',
    name: 'Access Denied',
    component: accessDenied,
    meta: { protected: true, isPublic: true }
  },
  {
    path: '/reports/campaigns*',
    beforeEnter () {
      var url = window.location.toString()
      window.location = url.replace('reports/campaigns', 'campaigns')
    },
    meta: { protected: true }
  },

  /**
   * AUTH
   */
  {
    path: '/login',
    alias: '/forgot',
    name: 'login',
    component: login
  },
  {
    path: '/logout',
    name: 'logout',
    component: logout
  },
  {
    path: '/signup',
    name: 'signup',
    component: signup
  },
  {
    path: '/terms-conditions',
    name: 'Terms & Conditions',
    component: supportPages,
    meta: { page: 'termsconditions', isPublic: true }
  },
  {
    path: '/auth',
    alias: '/auth-callback',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth.vue')
  },

  /**
   * CREATE CAMPAIGN
   */
  {
    path: '/create-campaign',
    name: 'create-campaign',
    alias: ['/campaigns/create', '/plans/create'],
    component: createCampaign,
    meta: { protected: true }
  },

  /**
   * NEW GENERAL EDITOR
   */
  {
    path: '/temp/campaigns/create',
    alias: [
      '/temp/lines/create',
      '/temp/audiences/create',
      '/temp/plans/create',
      '/temp/auction-packages/create'
    ],
    name: 'General Editor',
    component: generalEditor,
    meta: { protected: true }
  },

  /**
  * AUCTION PACKAGES
  */
  {
    path: '/auction-packages',
    component: auctionPackagesContainer,
    meta: { protected: true },
    children: [
      {
        path: '',
        name: 'Auction Packages',
        component: auctionPackagesOverview
      },
      {
        path: 'create',
        name: 'Create Auction Package',
        component: createCampaign
      },
      {
        path: ':itemId',
        name: 'Auction Package Page',
        component: audiencePage,
        meta: { isPublic: true }
      }
    ]
  },
  /**
   * AUDIENCES
   */
  {
    path: '/audiences',
    alias: ['/packages'],
    component: audiencesContainer,
    meta: { protected: true },
    children: [
      {
        path: '',
        name: 'Audiences',
        component: audiencesOverview
      },
      {
        path: 'add',
        name: 'Add Audience',
        component: addAudience
      },
      {
        path: ':itemId',
        name: 'Audience Page',
        component: audiencePage
      },
      {
        path: ':itemId/edit',
        name: 'Edit Audience',
        beforeEnter (to, from, next) {
          // redirect to Audience Page, to use "editAudienceDialog"
          next({ name: 'Audience Page', params: { itemId: to.params.itemId } })
        }
      }
    ]
  },

  /**
   * Plans
   */
  {
    name: 'Plans Overview',
    path: '/plans',
    alias: [
      '/plans/all'
      // '/plans/archived'
    ],
    meta: { protected: true },
    component: plansOverview,

    beforeEnter (to, from, next) {
      // default sub-route
      var pathPieces = to.path.split('/')
      if (pathPieces.length === 2) {
        next('/plans/all')
      } else {
        next()
      }
    }
  },
  {
    path: '/plans/:id',
    name: 'Plan',
    alias: ['/proposals/:id'],
    component: proposalPage,
    meta: { isPublic: true }
  },

  /**
   * PROPOSALS
   */
  {
    path: '/proposals/:id',
    name: 'Proposal',
    component: proposalPage,
    meta: { isPublic: true }
  },

  /**
   * DEALS
   */
  {
    path: '/marketplace',
    name: 'Marketplace',
    alias: [
      '/deals'
    ],
    component: dealsOverview,
    meta: { protected: true }
  },

  /**
   * CAMPAIGNS OVERVIEW
   */
  {
    name: 'Campaigns Overview',
    path: '/campaigns',
    alias: [
      '/campaigns/all',
      '/campaigns/proposed',
      '/campaigns/delivering',
      '/campaigns/scheduled',
      '/campaigns/completed',
      '/campaigns/archived'
    ],
    meta: { protected: true },
    component: campaignsOverview,

    beforeEnter (to, from, next) {
      // default sub-route
      var pathPieces = to.path.split('/')
      if (pathPieces.length === 2) {
        const subRoute = !can('campaign').read.default && can('proposal').read.default
          ? 'proposed'
          : 'all'
        next(`/campaigns/${subRoute}`)
      } else {
        next()
      }
    }
  },
  {
    // CAMPAIGN REPORT
    name: 'report-campaign',
    meta: { protected: true },
    component: campaignReport,
    path: '/campaigns/:campaignId',
    alias: [
      '/campaigns/:campaignId/lines',
      '/campaigns/:campaignId/creatives',
      '/campaigns/:campaignId/geolocations',
      '/campaigns/:campaignId/demographics',
      '/campaigns/:campaignId/environments'
    ],
    // default sub-route
    beforeEnter (to, from, next) {
      if (!can('campaign').read.default) {
        next({ name: 'Access Denied' })
        return
      }

      var pathPieces = to.path.split('/')
      if (pathPieces.length === 3) {
        next('/campaigns/' + to.params.campaignId + '/lines')
      } else {
        next()
      }
    }
  },
  /**
  * REPORTS
  */
  {
    path: '/campaigns/',
    name: 'Campaign Reports',
    meta: { protected: true },
    // default route doesn't exists, redirect to CAMPAIGNS OVERVIEW instead
    beforeEnter (to, from, next) {
      next('/campaigns/all')
    }
  },
  {
    // LINE REPORT
    name: 'report-line',
    meta: { protected: true },
    component: campaignReport,
    path: '/campaigns/:campaignId/lines/:lineId',
    alias: [
      '/campaigns/:campaignId/lines/:lineId/screens',
      '/campaigns/:campaignId/lines/:lineId/venues',
      '/campaigns/:campaignId/lines/:lineId/creatives',
      '/campaigns/:campaignId/lines/:lineId/geolocations',
      '/campaigns/:campaignId/lines/:lineId/demographics',
      '/campaigns/:campaignId/lines/:lineId/environments'
    ],
    // default sub-route
    beforeEnter (to, from, next) {
      if (!can('campaign').read.default) {
        next({ name: 'Access Denied' })
        return
      }

      var pathPieces = to.path.split('/')
      if (pathPieces.length === 5) {
        const firstTabLabel = to.params.isForAdServer ? '/screens' : '/venues'
        next('/campaigns/' + to.params.campaignId + '/lines/' + to.params.lineId + firstTabLabel)
      } else {
        next()
      }
    }
  },
  {
    // VENUE REPORT
    name: 'report-venue',
    meta: { protected: true },
    component: campaignReport,
    path: '/campaigns/:campaignId/lines/:lineId/venues/:venueId',
    alias: [
      '/campaigns/:campaignId/lines/:lineId/venues/:venueId/screens',
      '/campaigns/:campaignId/lines/:lineId/venues/:venueId/creatives',
      '/campaigns/:campaignId/lines/:lineId/venues/:venueId/demographics'
    ],
    beforeEnter (to, from, next) {
      if (!can('campaign').read.default) {
        next({ name: 'Access Denied' })
        return
      }

      // default sub-route
      var pathPieces = to.path.split('/')
      if (pathPieces.length === 7) {
        next('/campaigns/' + to.params.campaignId + '/lines/' + to.params.lineId + '/venues/' + to.params.venueId + '/screens')
      } else {
        next()
      }
    }
  },
  {
    // SCREEN REPORT
    name: 'report-screen',
    meta: { protected: true },
    component: campaignReport,
    path: '/campaigns/:campaignId/lines/:lineId/venues/:venueId/screens/:screenId',
    alias: [
      '/campaigns/:campaignId/lines/:lineId/venues/:venueId/screens/:screenId/creatives'
    ],
    beforeEnter (to, from, next) {
      if (!can('campaign').read.default) {
        next({ name: 'Access Denied' })
      } else if (to.path.split('/').length === 9) {
        // default sub-route
        next('/campaigns/' + to.params.campaignId + '/lines/' + to.params.lineId + '/venues/' + to.params.venueId + '/screens/' + to.params.screenId + '/creatives')
      } else {
        next()
      }
    }
  },
  {
    // DASHBOARD
    name: 'Dashboard',
    meta: { protected: true },
    component: dashboard,
    path: '/dashboard'
  },

  /**
   * CREATIVES
   */
  {
    path: '/creatives',
    name: 'Creatives Overview',
    alias: [
      '/creatives/all',
      '/creatives/approved',
      '/creatives/pending',
      '/creatives/rejected',
      '/creatives/archived'
    ],
    meta: { protected: true },
    component: creativesOverview,
    beforeEnter (to, from, next) {
      if (!can('creative').read.default) {
        next({ name: 'Access Denied' })
      } else if (to.path.split('/').length === 2) {
        // default sub-route
        next('/creatives/all')
      } else {
        next()
      }
    }
  },
  {
    path: '/creatives/:creativeId',
    name: 'Creative Page',
    meta: { protected: true },
    component: creativesContainer,
    beforeEnter (to, from, next) {
      if (!can('creative').read.default) {
        next({ name: 'Access Denied' })
      } else {
        next()
      }
    }
  },

  /**
   * BILLING
   */
  {
    path: '/billing',
    name: 'Billing',
    meta: { protected: true },
    component: billing
  },
  {
    path: '/billing/:invoiceId',
    name: 'Invoice Page',
    meta: { protected: true },
    component: invoice
  },

  /**
   * USERS
   */
  {
    path: '/users',
    name: 'users',
    meta: { protected: true },
    component: manageUsers
  },
  {
    path: '/users/:userId',
    name: 'user',
    meta: { protected: true },
    component: userPage
  },
  {
    path: '/invitation',
    component: acceptInvitation,
    meta: { isPublic: true }
  },

  /**
   * ORGANIZATIONS
   */
  {
    path: '/organizations',
    name: 'Manage Organizations',
    meta: { protected: true },
    component: manageOrganizations
  },
  {
    path: '/organizations/:organizationId',
    name: 'Edit Organization',
    meta: { protected: true },
    component: organizationForm
  },
  {
    path: '/organizationsSettings/:organizationId?',
    name: 'Organization Settings',
    meta: { protected: true },
    component: organizationSettings
  },

  /**
   * HISTORY
   */
  {
    path: '/history',
    name: 'History',
    meta: { protected: true },
    component: notifications
  },

  /**
   * Custom Reports
   */
  {
    path: '/reports',
    name: 'Reports',
    component: reports,
    meta: { protected: true }
  },
  {
    path: '/reports/create',
    name: 'Create Report',
    component: reportEditor,
    meta: { protected: true }
  },
  {
    path: '/reports/:id',
    name: 'Report',
    component: report,
    meta: { protected: true }
  }
]

export default routes
