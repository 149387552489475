
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

function setupAxios (store, router, rejectedRequestInterceptor = null) {
  /**
   * Axios interceptors
   */
  axios.interceptors.request.use(
    config => {
      const isCampsiteApi = config.url.indexOf(process.env.VUE_APP_CAMPSITE_API_URL) > -1
      const isCampsiteClientApi = config.url.indexOf(process.env.VUE_APP_CLIENTUTILS_URL) > -1
      const token = store.getters['user/getToken']
      if ((isCampsiteApi || isCampsiteClientApi) && token) {
        config.headers.Authorization = token
      }
      if (['Proposal', 'Plan', 'Auction Package Page'].includes(router.app.$route.name) && router.app.$route.query.token) {
        config.headers['scoped-token'] = router.app.$route.query.token
      }

      return config
    },
    error => {
      console.log('axios.interceptors.request.error', error)
      return Promise.reject(error)
    })

  if (rejectedRequestInterceptor) axios.interceptors.response.use(undefined, rejectedRequestInterceptor)

  // Function that will be called to refresh authorization
  const refreshAuthLogic = failedRequest => store.dispatch('auth/fetchSession').then(accessToken => {
    store.commit('user/setToken', accessToken)

    failedRequest.response.config.headers.Authorization = accessToken
    return Promise.resolve()
  })

  // Instantiate the interceptor (you can chain it as it returns the axios instance)
  createAuthRefreshInterceptor(axios, refreshAuthLogic)

  return axios
}

export default setupAxios
