<template lang='pug'>
v-expansion-panels(v-model='panelsLineModel')
  v-expansion-panel#line-order-name-panel
    v-expansion-panel-content(eager)
      v-form(ref='lineOrderName')
        v-card(flat color="transparent")
          v-card-text.pa-0
            v-row.my-0(row wrap justify="end")
              v-col.pb-0(cols=12 sm=6 lg=8 text-md-left)
                v-text-field#line-order-name-field(
                  label='Line Order Name'
                  :value='lineName'
                  :rules='$store.getters["user/isForAdServer"] ? [rules.required] : []'
                  @input='lineOrderNameChanged')
              v-col.pb-0(cols=12 sm=6 lg=4 text-md-left)
                v-text-field#line-order-external-id-field(
                  label='External ID'
                  :value='externalId'
                  @input='externalIdChanged')

</template>

<script>
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
  },
  data () {
    return {
      panelsLineModel: 0,
      rules: {
        required: (value) => !!value || 'Required.'
      },
      lineName: '',
      externalId: ''
    }
  },
  created () {
    this.initialize()
  },
  mounted () {
    this.$root.$on('validateLineOrderName', () => {
      this.$emit('formValidationUpdated', this.$refs.lineOrderName?.validate(), 'lineOrderName')
    })
  },
  computed: {
    namePlaceholder () {
      return this.$store.getters['general/marketDefaultValues'].lineName || ''
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    initialize () {
      if (this.componentConfig.campaigns.isLineOrderNamePrefilled) {
        this.lineOrderNameChanged(this.namePlaceholder)
      } else {
        this.lineOrderNameChanged(this.$store.getters['createCampaign/getLineName'])
      }
    },
    lineOrderNameChanged (lineName) {
      this.$store.commit('createCampaign/setLineName', lineName)
      this.lineName = lineName
    },
    externalIdChanged (externalId) {
      this.$store.commit('createCampaign/setLineExternalId', externalId)
      this.externalId = externalId
    }
  }
}
</script>
