export default {
  namespaced: true,
  state: {
    snackbar: {}
  },
  mutations: {
    setSnackbar: (state, payload) => {
      state.snackbar = payload
    }
  }
}
