import axios from 'axios'

var { VUE_APP_REPORTING_SERVICE_URL } = process.env
const baseUrl = VUE_APP_REPORTING_SERVICE_URL + '/scopes/campsite'

async function createReport (payload, authToken) {
  const url = `${baseUrl}/reports`
  return axiosWrapper({
    url,
    authToken,
    data: payload,
    method: 'post'
  })
}

async function updateReport (id, payload, authToken) {
  const url = `${baseUrl}/reports/${id}`
  return axiosWrapper({
    url,
    authToken,
    data: payload,
    method: 'put'
  })
}

async function getReport (id, authToken) {
  const url = `${baseUrl}/reports/${id}`
  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  })
}

async function deleteReport (id, authToken) {
  const url = `${baseUrl}/reports/${id}`
  return axiosWrapper({
    url,
    authToken,
    method: 'delete'
  })
}

async function getReports (authToken) {
  const url = `${baseUrl}/reports/`
  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(res => res.data.result)
}

async function getReportRuns (reportId, authToken) {
  const url = `${baseUrl}/reports/${reportId}/exports?returnAllStatuses=true`
  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(res => res.data.result)
}

async function getRunFile (reportId, runId, authToken) {
  const url = `${baseUrl}/reports/${reportId}/exports/${runId}/file`
  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(res => res.data)
}

/**
 * @param {{reportId: number, runId: number, cursor?: string, pageSize?: number }} options
 * @param {string} authToken A user auth token
 */
async function getRunData (options, authToken) {
  const { reportId, runId, cursor, pageSize } = options
  const params = {}
  if (cursor) params.cursor = cursor
  if (pageSize) params.pageSize = pageSize
  const query = new URLSearchParams(params).toString()
  const url = `${baseUrl}/reports/${reportId}/exports/${runId}/data${query ? '?' + query : ''}`

  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(res => {
    if (res.status === 204) return { data: [], nextPage: null }
    return res.data
  })
}

async function liveQueries (options, authToken) {
  const url = `${baseUrl}/queries`

  return axiosWrapper({
    url,
    authToken,
    method: 'post',
    data: options
  }).then(res => res.data)
}

async function getMetrics (authToken) {
  const url = `${baseUrl}/metrics`

  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(res => res.data)
}

async function getDimensions (authToken) {
  const url = `${baseUrl}/tags`

  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(res => res.data)
}

async function generateReport (reportId, authToken) {
  const url = `${baseUrl}/reports/${reportId}/generate`

  return axiosWrapper({
    url,
    authToken,
    method: 'post'
  })
}

/**
 * @param {string} type a type of resource (organization, campaign, ...)
 * @param {string} searchQuery A string to match
 * @param {number} take A string to match
 * @return {Promise{name: string, id: number, externalId?: string}[]}
 */
async function searchCampsiteResources (type, searchQuery, take = 10) {
  if (!type || typeof type !== 'string' || !searchQuery) return []
  if (!take) take = 10
  type = type.toLowerCase()

  const endpoint = process.env.VUE_APP_CAMPSITE_API_URL + `/search?type=${type}&search=${searchQuery}&take=${take}`
  return axios.get(endpoint).then(response => response.data)
}

/**
 * @param {string} type a type of resource (organization, campaign, ...)
 * @param {number | string} id A numeric or string id
 * @param {boolean} isExact A flag to indicate if we can find the resource directly by ID
 * @return {{name: string, id: number, externalId?: string}}
 */
async function getCampsiteResourceById (type, id, isExact) {
  if (!type || !id) return null
  if (isExact) {
    const endpoint = process.env.VUE_APP_CAMPSITE_API_URL + `/search/${type}/${id}`
    return axios.get(endpoint).then(response => response.data)
  } else {
    const results = await searchCampsiteResources(type, id)
    return results && results.length ? results[0] : null
  }
}

// async function getTempToken () {
//   const endpoint = process.env.VUE_APP_CAMPSITE_API_URL + '/users/oauth/token'
//   return axios.get(endpoint).then(response => response.data)
// }

async function axiosWrapper (options) {
  // const { url, data, method } = options
  // const authToken = await getTempToken()

  const { url, data, method, authToken } = options
  const config = { url, method, headers: { Authorization: `bearer ${authToken}` } }
  if (['post', 'put', 'patch'].includes(method)) config.data = data
  return axios.request(config)
}

export default {
  createReport,
  updateReport,
  getReport,
  deleteReport,
  getReports,
  getReportRuns,
  getRunFile,
  getRunData,
  liveQueries,
  getMetrics,
  getDimensions,
  generateReport,

  searchCampsiteResources,
  getCampsiteResourceById
}
