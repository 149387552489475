<template lang="pug">
  .treeview-container
    v-treeview(
      item-children='groups'
      item-key='key'
      v-model='selectedItems'
      :items='dataFromStore'
      :open="openBranches"
      activatable
      active-class='grey lighten-4'
      selected-color='primary'
      open-on-click
      selectable
      transition
      dense
      class='body-2 pa-0'
      expand-icon="mdi-chevron-down"
      @input='onChange()')

      template(v-slot:label="{ item, selected }")
        .treeview-item(
          v-if="!targetDescription(item.key)"
          :class='itemClass(item, selected)'
          ) {{ item.name }}
        v-menu(v-else right offset-x open-on-hover nudge-top='10' nudge-right='-50' max-width='450' open-delay='100')
          template(v-slot:activator="{ on }")
            .treeview-item(v-on="on" :class='itemClass(item, selected)') {{ item.name }}
          v-card
            v-card-text.text--primary.caption(style='line-height:1rem;')
              div {{ targetDescription(item.key) }}
</template>

<style>
.v-treeview-node__content{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline;
  flex-shrink: unset;
}
</style>

<script>
import _ from 'lodash'
import helpers from '@/services/helpers.service'
import localizationService from '@/services/localization'

export default {
  created: function () {
    this.dataFromStore = this.group.groups
    this.dataFromStore.forEach(element => {
      this.branchesList(element)
      this.tempHolder = []
    })

    this.selectedItemsHandling()
  },
  watch: {
    selected: function () {
      this.selectedItemsHandling()
    }
  },
  computed: {
    finalList () {
      if (this.selectedItems.length) {
        return this.allBranches.map(x => {
          if (this.selectedItems.includes(x[x.length - 1].key)) {
            return x[x.length - 1].key
          }
        }).filter(Boolean)
      } else {
        return []
      }
    }
  },
  data: () => ({
    dataFromStore: null, // list
    selectedItems: [],
    allBranches: [],
    treeviewHasBeenInitialized: false,
    openBranches: [],
    userInducedChange: false,

    // "tempHolder" is placeholder needed for method "branchesList",
    tempHolder: []

  }),
  props: ['group', 'selected'],
  methods: {
    branchesList (element) {
      this.tempHolder.push({ name: element.name, key: element.key })
      if (element.groups && element.groups.length) {
        element.groups.map(child => {
          this.branchesList(child)
        })
      } else {
        this.allBranches.push(this.tempHolder.slice())
        this.tempHolder.splice(-1, 1)
      }
    },

    openBranchesFunction () {
      if (this.allBranches.length) {
        const extracted = this.allBranches
          .filter(x => this.selectedItems.includes(x[x.length - 1].key))
          .map(y => { return y.map(item => item.key) })
          // .flat()
        const flatten = helpers.arrayFlat(extracted)
        return [...new Set(flatten)]
      } else {
        return []
      }
    },

    selectedItemsHandling () {
      if (this.userInducedChange) this.userInducedChange = false
      else {
        if (this.selected && this.selected.length && _.difference(this.selected, this.finalList).length) {
          this.selectedItems = this.selected
          this.openBranches = this.openBranchesFunction()
        }
      }
    },

    onChange: _.debounce(function () {
      this.userInducedChange = true
      this.$emit('updateList', this.finalList)
    }, 400),

    targetDescription (key) {
      return localizationService.getTooltipDescription(key, this.$store.getters['general/getLocalText']('targetDescriptions'))
    },

    itemClass (item, selected) {
      let classesStr = item.key.toLowerCase().replace(/\\s/g, '_')
      if (selected) {
        classesStr += ' treeview-item-selected'
      }
      return classesStr
    }
  }
}
</script>
