import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import Vue from 'vue'

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
    region: process.env.VUE_APP_REGION
  }
})

export default {
  namespaced: true,
  state: {
    provider: 'aws',
    session: {},
    user: {}
  },
  getters: {
    isLoggedIn (state) {
      if (state.provider === 'auth0') {
        return !!state.session && Object.keys(state.session).length > 0
      }

      const session = state.session
      if (!session) return false
      const accessToken = session.accessToken
      if (!accessToken) return false
      const hasToken = accessToken.jwtToken
      const isActive = new Date(accessToken.payload.exp * 1000) > new Date()
      const isMe = accessToken.payload.username === state.user.username
      return hasToken && isActive && isMe
    },
    idToken: state => {
      return state.session && state.session.idToken
        ? state.session.idToken.jwtToken
        : null
    },
    idTokenPayload: state => {
      return state.session && state.session.idToken
        ? state.session.idToken.payload
        : null
    },
    provider: state => {
      return state.provider
    },
    idTokenPlatformClaims: (state, getters) => {
      let platformClaims = {}

      if (getters.provider === 'auth0') {
        const idTokenPayload = getters.idTokenPayload
        const tokenClaimsUrl = 'https://auth.broadsign.com/user/login'
        platformClaims = idTokenPayload[tokenClaimsUrl]
      }

      return platformClaims
    }
  },
  mutations: {
    setProvider: (state, provider) => {
      const supportedProviders = ['aws', 'auth0']
      if (supportedProviders.includes(provider)) {
        state.provider = provider
      }
    },
    setSession: (state, session) => {
      state.session = { ...session }
    },
    setUser: (state, user) => {
      state.user = { ...user }

      if (state.provider === 'aws') {
        state.session = state.user.signInUserSession
      }
    }
  },
  actions: {
    refreshToken () {
      return Auth.currentAuthenticatedUser({ bypassCache: true })
    },
    getAuth0TokenSilently () {
      return Vue.prototype.$auth.getTokenSilently()
    },
    getAuth0User () {
      return Vue.prototype.$auth.getUser()
    },
    logoutAuth0 () {
      return Vue.prototype.$auth.logout({ returnTo: window.location.origin })
    },
    fetchSession: ({ dispatch, getters, commit }) => {
      if (getters.provider === 'auth0') {
        return dispatch('getAuth0TokenSilently')
          .then(token => {
            return dispatch('getAuth0User')
              .then(user => {
                commit('setUser', user)
                return token
              })
          })
      }

      return Auth.currentSession().then(session => {
        return Auth.currentUserPoolUser()
          .then(user => {
            commit('setUser', user)
            return session.accessToken.jwtToken
          })
      })
    },
    signInUser ({ commit }, credentials) {
      return Auth.signIn(credentials.username, credentials.password).then((user) => {
        commit('setUser', user)
        return user
      })
    },
    forgotPassword (context, data) {
      return Auth.forgotPassword(data.username)
    },
    changePassword (context, data) {
      return Auth.forgotPasswordSubmit(
        data.username,
        data.code,
        data.newPassword
      )
    },
    updatePassword (context, data) {
      return Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, data.oldPassword, data.newPassword))
    },
    signOut ({ dispatch, commit, getters }) {
      if (!getters.isLoggedIn) throw new Error('User not logged in.')

      if (getters.provider === 'auth0') {
        return dispatch('logoutAuth0')
      }

      return Auth.signOut().then(result => {
        commit('setUser', {})
        if (localStorage) localStorage.removeItem('USER')
        return result
      })
    }
  }
}
