<template lang='pug'>
  v-expansion-panel#line-goal-panel
    v-expansion-panel-header.text-h6 Line Goal
    v-expansion-panel-content(eager)
      v-form(ref="lineGoal")
        v-row.my-0(row, wrap, justify='end')
          v-col.pt-0
            v-select#linegoaltype-field.mt-0(
              v-model='lineGoalTypes[0]',
              v-if="lineGoalTypes.length > 1",
              label='Line Goal Type',
              :items='lineGoalTypes',
              @input='lineGoalTypeChanged',
              flat
            )
            formattedNumberInput#targetnumberofimpressions-field.mt-0.mb-0(
              label='Target Number of Impressions',
              type='noDecimals'
              valueType='number',
              :rules='[rules.required, rules.positive]',
              :value='targetedImpressions'
              @input='targetNumberOfImpressionsChanged'
            )
</template>

<script>
import formattedNumberInput from '@/components/formattedNumberInput.vue'

export default {
  components: {
    formattedNumberInput
  },
  data () {
    return {
      rules: {
        required: (value) => !!value || value === 0 || 'Required.',
        positive: (value) => value > 0 || 'Positive numbers only.'
      },
      lineGoalTypes: ['Impressions'],
      targetedImpressions: ''
    }
  },
  props: ['loadingLine'],
  created () {
    if (!this.loadingLine) {
      this.calculateTargetedImpressions()
    } else {
      this.$watch('loadingLine', (loadingLine) => {
        if (!loadingLine) {
          this.calculateTargetedImpressions()
        }
      })
    }
  },
  mounted () {
    this.$root.$on('validateLineGoal', () => {
      this.$emit('formValidationUpdated', this.$refs.lineGoal?.validate(), 'targetNumberOfImpressions')
    })
  },
  methods: {
    lineGoalTypeChanged (lineGoalType) {
      this.$store.commit('createCampaign/setLineGoalType', lineGoalType)
      this.$emit('updateLineGoalType')
    },
    targetNumberOfImpressionsChanged (targetNumberOfImpressions) {
      this.$store.commit('createCampaign/setTargetNumberOfImpressions', targetNumberOfImpressions)
      this.$emit('updateTargetNumberOfImpressions', targetNumberOfImpressions)
    },
    calculateTargetedImpressions () {
      const budget = this.$store.getters['createCampaign/getBudget']
      const cpm = this.$store.getters['createCampaign/getMaxCpm']
      if (budget && cpm) {
        this.targetedImpressions = Math.floor(budget / cpm * 1000)
        this.$store.commit('createCampaign/setTargetNumberOfImpressions', this.targetedImpressions)
      }
    }
  }
}
</script>
