import { render, staticRenderFns } from "./panelInventorySource.vue?vue&type=template&id=24521500&scoped=true&lang=pug"
import script from "./panelInventorySource.vue?vue&type=script&lang=js"
export * from "./panelInventorySource.vue?vue&type=script&lang=js"
import style0 from "./panelInventorySource.vue?vue&type=style&index=0&id=24521500&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24521500",
  null
  
)

export default component.exports