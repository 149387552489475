<template lang="pug">
v-card(:width='width' v-if='info')
  v-card-title.py-2
    .text-overline {{ info.date | date(dateFormat) }}
  v-divider
  v-card-text.pa-3(:style='textStyle')
    .d-flex.justify-space-between(v-for='metric in info.metrics' :key='metric.key')
      .text-body-2.d-flex
        //- v-icon.mr-2(small :color='metric.color') mdi-circle
        i.v-icon.mdi.mdi-circle.mr-2(:style="'font-size: 16px; color: ' + metric.color")
        .text-capitalize {{ metric.name }}
      .text-body-2.justify-end {{ metric.value }}
</template>

<script>
export default {
  name: 'custom-tooltip',
  props: ['info', 'dateFormat'],
  data () {
    return {
      textColor: '#000000',
      width: 250
    }
  },
  computed: {
    textStyle () {
      const color = this.textColor
      return { color }
    }
  }
}
</script>
