<template lang="pug">
  v-navigation-drawer(
    v-model='isDialogOpen'
    fixed right temporary stateless
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '75%'"
    @keydown.esc='preClose'
    )
    v-container.px-0.pb-0.dialog-content(
      v-click-outside="preCloseFromClickOutside"
      fluid grid-list-lg text-left
      v-if='isDialogOpen'
      )
      .sticky-action-bar.sticky-top.mt-n3
        v-row.ma-0(align='center')
          v-col(cols='auto')
            v-btn.close-edit-report-icon-btn(icon @click='preClose')
              v-icon mdi-close
          v-col(cols='auto')
            .text-h6.d-inline-block(v-if='isDialogOpen')
              | {{ reportName }}

      v-card(color='#f8f9fa')
        reportEditor(
          :report='report'
          @canSaveReportChanged='updateCanSaveReport'
          @changedReport='saveNewVersionOfReport'
          )

      .sticky-action-bar.sticky-bottom.pa-3
        v-btn.save-edit-report-btn(
          @click='saveWrapper()'
          :loading='saving'
          :disabled='!canSaveReport'
          color='primary' depressed
          ) {{ isOnDemand ? 'Save and Run' : 'Save' }}
        v-btn.ml-3.close-edit-report-btn(text @click='preClose') cancel

    v-dialog(v-model="isConfirmationOpen" maxWidth=380)
      v-card.confirmation-dialog
        v-card-title Discard unsaved changes?
        v-card-actions
          v-spacer
          v-btn.confirmation-dialog-cancel(text @click='isConfirmationOpen = false') Cancel
          v-btn.confirmation-dialog-save(text v-if='canSaveReport' @click='saveWrapper()' :loading="saving") save
          v-btn.confirmation-dialog-discard(text color='primary' @click="close()") discard
</template>

<script>
import { mapState } from 'vuex'
import reportsService from '../services/reports'
import tracking from '@/services/tracking'

import reportEditor from '@/views/reportEditor'

export default {
  props: ['report', 'openDialogEvent'],
  components: {
    reportEditor
  },
  data () {
    return {
      dateFormat: 'MMM DD, YYYY',
      isConfirmationOpen: false,
      modifiedReport: null,
      isDialogOpen: false,
      canSaveReport: true
    }
  },
  computed: {
    ...mapState('reports', {
      saving: state => state.savingReport
    }),
    reportName () {
      return reportsService.decodeName(this.report)
    },
    isOnDemand () {
      return this.modifiedReport?.schedule?.frequency === 'OnDemand'
    }
  },
  watch: {
    openDialogEvent () {
      this.isDialogOpen = true
    }
  },
  methods: {
    preClose () {
      tracking.sendEvent(['ga'], 'customReportCancelEditReport')
      if (this.modifiedReport) {
        this.isConfirmationOpen = true
      } else {
        this.close()
      }
    },
    preCloseFromClickOutside (p) {
      if (p?.target?.className === 'v-overlay__scrim') this.preClose()
    },
    close () {
      this.isDialogOpen = false
      this.isConfirmationOpen = false
      this.modifiedReport = null
      this.$emit('dialogClosed')
    },
    saveWrapper () {
      tracking.sendEvent(['ga'], 'customReportSaveEditReport')
      if (!this.modifiedReport) {
        this.close()
        return
      }
      this.$store.dispatch('reports/updateReport', { payload: this.modifiedReport, id: this.report.id }).then(async () => {
        if (this.isOnDemand) {
          await this.$store.dispatch('reports/generateReport', this.report.id)
          this.$emit('refreshRuns')
        }
        this.close()
      })
    },
    saveNewVersionOfReport (obj) {
      this.modifiedReport = obj
    },
    updateCanSaveReport (bool) {
      this.canSaveReport = bool
    }
  }
}
</script>

<style scoped>
.sticky-action-bar {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.sticky-top {
  top: 0;
  border-bottom: 1px solid lightgray;
  z-index: 1;
}

.sticky-bottom {
  bottom: 0;
  border-top: 1px solid lightgray;
}
</style>
