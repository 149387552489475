<template lang="pug">
div
  .body-2.no-period(v-if='!period') None
  .period(v-else)
    .body-2.period-type {{ period.type | capitalize}}
    .caption.grey--text.period-dates {{ period.dates}}
</template>

<script>
import reportsService from '@/services/reports'

export default {
  props: ['report'],
  data () {
    return {
      dateFormat: 'MMM Do, YYYY'
    }
  },
  computed: {
    period () {
      return this.report && reportsService.generateDateRangeLabel(this.report)
    }
  }
}
</script>
