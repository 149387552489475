<template lang="pug">
div
  .body-2.schedule-frequency {{ frequency }}
  .caption.grey--text.next-run(v-if='nextRun') Next scheduled run: {{ nextRun }}
</template>

<script>
import reportsService from '../services/reports'

export default {
  props: ['report', 'isReportPage'],
  computed: {
    schedule () {
      return reportsService.decodeSchedule(this.report).schedule
    },
    frequency () {
      return this.schedule?.frequency?.label === 'Unscheduled' ? '-' : this.schedule?.frequency?.label
    },
    nextRun () {
      return reportsService.getNextRun(this.schedule)
    }
  }
}
</script>
