<template lang="pug">
    v-card(:loading="loading").pb-3
      v-card-title.text-h5 Notifications
      v-card-text
        v-container.px-0(fluid)
          v-row.my-0
            v-col(cols=12 v-if="notifications")
              p Send me email notifications when:
              v-checkbox.pa-0.mt-0.mb-2(
                v-for="item in notifications"
                :label="notificationLabels[item.type]"
                color="primary"
                v-model="item.receiveEmail"
                :key="item.id"
                :disabled="loading"
                hide-details
                height="24"
                @change="updateEmailNotifications()"
                )
</template>

<script>
import userApi from '@/services/user.api'

export default {
  data () {
    return {
      loading: false,
      notificationLabels: {
        CreativeStatusChanged: 'A creative is approved or rejected',
        LineStarts: 'A line order starts',
        LineEnds: 'A line order ends'
      },
      notifications: null

    }
  },
  created () {
    this.loading = true
    userApi.getUserNotificationsConfig().then(res => {
      this.notifications = res.filter(x => ['CreativeStatusChanged', 'LineStarts', 'LineEnds'].includes(x.type))
      this.loading = false
    })
  },
  methods: {
    updateEmailNotifications () {
      this.loading = true
      userApi.setUserNotificationsConfig(this.notifications).then(res => {
        this.loading = false
        this.$store.commit('snackbar/setSnackbar', {
          type: 'success',
          msg: 'Successfully updated'
        })
      })
    }
  }
}
</script>
