<template lang="pug">
v-menu(
  v-model='menuOpened'
  internal-activator
  :close-on-content-click='false'
)
  template(v-slot:activator='{ on }')
    .d-flex.edit-name-activator(v-on='on' style='cursor:pointer')
      div(style='word-break: break-word; overflow:hidden;') {{ creative.name }}
      v-icon.mx-1(color='grey' small) mdi-pencil

  v-card.edit-name-menu(width='500')
    v-card-title.pb-0 Update Filename
    v-card-text.pb-0
      v-form(v-model='editNameFormValid' @submit.prevent='save')
        v-text-field.name-input(
          v-model='model'
          label='Edit Filename'
          single-line
          autofocus
          :rules='[rules.required]'
        )
    v-card-actions
      v-spacer
      v-btn.cancel-btn(color='secondary' text @click='closeMenu') Cancel
      v-btn.save-btn(text color='primary' :loading='saving' :disabled='!editNameFormValid' @click='save') save
</template>

<style scoped lang="stylus">
</style>

<script>
export default {
  props: ['creative'],
  data () {
    return {
      menuOpened: false,
      editNameFormValid: false,
      model: this.creative.name || '',
      rules: {
        required: value => !!value || 'Required.'
      },
      saving: false
    }
  },
  watch: {
    menuOpened (newVal, oldVal) {
      if (oldVal && !newVal) {
        this.model = this.creative.name
      }
    }
  },
  methods: {
    closeMenu () {
      this.menuOpened = false
      this.saving = false
      this.model = this.creative.name
    },
    save () {
      if (this.editNameFormValid) {
        this.saving = true

        const creativeNamePatch = {
          id: this.creative.id,
          fileId: this.creative.fileId,
          name: this.model
        }
        this.$emit('creativeNameUpdated', creativeNamePatch)
        this.closeMenu()
      }
    }
  }
}
</script>
<style>
</style>
