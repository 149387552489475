<template lang="pug">
  v-expansion-panel#moments-panel(ref='momentsPanel' @change='momentsPanelChange')
    v-expansion-panel-header.text-h6 Moments
    v-expansion-panel-content.pb-4(eager)
      moments(
        :temperature-scale='temperatureScale'
        :moment-id='momentId'
        :buyerId='buyerId'
        :postEvent='postEvent'
        :authToken="$store.getters['user/getToken']"
        @onEvent='onEvent'
      )
</template>

<script>
import tracking from '@/services/tracking'
import moments from '@/web-components/moments/moments.vue'

export default {
  components: {
    moments
  },
  props: {
    momentId: {
      type: String
    },
    buyerId: {
      type: Number
    }
  },
  data () {
    return {
      postEvent: null,
      momentDefinition: null
    }
  },
  mounted () {
    this.$root.$on('validateMoment', () => {
      this.postEvent = { name: 'validate moment' }
    })
    this.$root.$on('createMoment', () => {
      this.postEvent = { name: 'create moment' }
    })
    this.$root.$on('updateMoment', () => {
      this.postEvent = { name: 'update moment' }
    })
  },
  computed: {
    temperatureScale () {
      const marketDefaultValues = this.$store.getters['general/marketDefaultValues']
      return marketDefaultValues.temperatureScale
    },
    orgId () {
      return this.$store.getters['user/getOrganization']?.id
    }
  },
  methods: {
    momentsPanelChange () {
      const label = this.$refs.momentsPanel.isActive ? 'momentsPanelClosed' : 'momentsPanelOpened'
      tracking.sendEvent(['ga'], label)
    },
    onEvent (event) {
      this.detectAndTrackMomentsEvents(event)
      if (event.action === 'dataChanged') {
        this.$store.commit('createCampaign/setMomentId', event.moment.id)
        this.$store.commit('createCampaign/setMomentConditions', event.moment.definition)
        this.$store.commit('createCampaign/setIsMomentValid', !event.isInvalid)
      }

      if (event.action === 'created') {
        this.$store.commit('createCampaign/setMomentCreated', true)
        this.$emit('momentCreated')
      }

      if (event.action === 'error') {
        this.$store.commit('createCampaign/setMomentCreated', false)
        this.$emit('momentsError', event.value)
      }

      if (event.action === 'updated') {
        this.$emit('momentUpdated')
      }

      if (event.action === 'loaded') {
        this.$emit('momentLoaded', event.value)
        this.momentDefinition = event.value.definition
      }
    },

    detectAndTrackMomentsEvents (event) {
      const ucFirst = s => s.charAt(0).toUpperCase() + s.slice(1)

      let trackingKey, trackingLabel, trackingCategory

      if (event.category && event.subCategory) {
        trackingCategory = 'moments panel / ' + event.category + ' / ' + event.subCategory
      }

      if (['opened', 'closed'].includes(event.action)) {
        trackingKey = 'moments' + ucFirst(event.category) + ucFirst(event.action)
        trackingLabel = 'click button:' + (event.action === 'opened' ? ' + ' : ' close ') + event.subCategory

        tracking.sendEvent(['ga'], trackingKey, { label: trackingLabel, category: trackingCategory })
      }

      if (event.moment) {
        const oldMomentDefinition = this.momentDefinition
        const newMomentDefinition = event.moment.definition
        const type = event.subCategory

        // console.dir(oldMomentDefinition)
        // console.dir(newMomentDefinition)

        if (oldMomentDefinition) {
          // Finance
          if (oldMomentDefinition.finance && newMomentDefinition.finance) {
            const oldType = oldMomentDefinition.finance[type]
            const newType = newMomentDefinition.finance[type]

            if (oldType && newType) {
              if (oldType.symbol !== newType.symbol) {
                trackingLabel = oldType.symbol + ' ' + oldType.name
                tracking.sendEvent(['ga'], 'momentsFinanceUnselected', { label: trackingLabel, category: trackingCategory })

                trackingLabel = newType.symbol + ' ' + newType.name
                tracking.sendEvent(['ga'], 'momentsFinanceSelected', { label: trackingLabel, category: trackingCategory })
              } else {
                const oldEvaluation = oldType.financeEvaluationCondition
                const newEvaluation = newType.financeEvaluationCondition

                const evaluationFields = ['comparisonType', 'conditionType', 'lookupPeriod', 'targetValue']
                evaluationFields.map(f => {
                  if (oldEvaluation[f] !== newEvaluation[f]) {
                    const trackingKey = 'momentsFinance' + ucFirst(f) + 'Changed'
                    trackingLabel = newEvaluation[f].toString()
                    tracking.sendEvent(['ga'], trackingKey, { label: trackingLabel, category: trackingCategory })
                  }
                })
              }
            } else {
              if (oldType) {
                trackingLabel = oldType.symbol + ' ' + oldType.name
                tracking.sendEvent(['ga'], 'momentsFinanceUnselected', { label: trackingLabel, category: trackingCategory })
              }

              if (newType) {
                trackingLabel = newType.symbol + ' ' + newType.name
                tracking.sendEvent(['ga'], 'momentsFinanceSelected', { label: trackingLabel, category: trackingCategory })
              }
            }
          } else {
            if (oldMomentDefinition.finance && !Object.values(oldMomentDefinition.finance).every(x => x === null)) {
              trackingLabel = oldMomentDefinition.finance[type].symbol + ' ' + oldMomentDefinition.finance[type].name
              tracking.sendEvent(['ga'], 'momentsFinanceUnselected', { label: trackingLabel, category: trackingCategory })
            }

            if (newMomentDefinition.finance) {
              trackingLabel = newMomentDefinition.finance[type].symbol + ' ' + newMomentDefinition.finance[type].name
              tracking.sendEvent(['ga'], 'momentsFinanceSelected', { label: trackingLabel, category: trackingCategory })
            }
          }

          // Weather
          if (oldMomentDefinition.weather && newMomentDefinition.weather) {
            const skyConditionsAdded = newMomentDefinition.weather.skyConditions.filter(currSkyCondition => !oldMomentDefinition.weather.skyConditions.includes(currSkyCondition))
            if (skyConditionsAdded.length) {
              tracking.sendEvent(['ga'], 'momentsWeatherSkyConditionSelected', { label: skyConditionsAdded[0] })
            }

            const skyConditionsRemoved = oldMomentDefinition.weather.skyConditions.filter(currSkyCondition => !newMomentDefinition.weather.skyConditions.includes(currSkyCondition))
            if (skyConditionsRemoved.length) {
              tracking.sendEvent(['ga'], 'momentsWeatherSkyConditionUnselected', { label: skyConditionsRemoved[0] })
            }

            // Location
            if (!oldMomentDefinition.weather.location && newMomentDefinition.weather.location) {
              tracking.sendEvent(['ga'], 'momentsWeatherLocationSelected', { label: newMomentDefinition.weather.location.label })
            }

            if (oldMomentDefinition.weather.location && !newMomentDefinition.weather.location) {
              tracking.sendEvent(['ga'], 'momentsWeatherLocationOptionChanged', { label: 'local' })
            }

            if (!oldMomentDefinition.weather.location && !newMomentDefinition.weather.location && event.isInvalid) {
              tracking.sendEvent(['ga'], 'momentsWeatherLocationOptionChanged', { label: 'destination' })
            }

            // Temperature
            const currTemperature = oldMomentDefinition.weather.temperature
            const newTemperature = newMomentDefinition.weather.temperature

            if (currTemperature.min !== newTemperature.min || currTemperature.max !== newTemperature.max) {
              tracking.sendEvent(['ga'], 'momentsWeatherTemperatureRangeChanged', { label: [newTemperature.min, newTemperature.max].join(', ') })
            }
          }

          this.momentDefinition = JSON.parse(JSON.stringify(newMomentDefinition))
        }
      }
    }
  }
}
</script>
