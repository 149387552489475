<template lang="pug">
  v-card.pt-3(color='transparent' flat)
    vue-headful(:title="componentConfig.branding.title('Creatives Overview')")
    v-toolbar.elevation-0(color='transparent')
      .text-h5.hidden-sm-and-down Creatives
      v-text-field(label='Find Creative' prepend-inner-icon='mdi-magnify' solo hide-details v-model='search' clearable :class="{'mx-3': $vuetify.breakpoint.smAndUp }")
      v-btn.mt-0.hidden-sm-and-down.add-creative-btn(large rounded color='primary' router-link to='/creatives/add' v-if='can.create.default')
        v-icon(left) mdi-plus
        span Upload Creatives

      template(v-slot:extension='')

        v-tabs(background-color='transparent' slider-color='primary' slot='extension' v-model='currentTab')
          v-tab#all(ripple color='primary lighten-4')
            | All
          v-tab#to-be-reviewed(ripple v-if='componentConfig.creatives.isCreativeApprovalNeeded')
            | To Be Reviewed
          v-tab#rejected(ripple v-if='componentConfig.creatives.isCreativeApprovalNeeded')
            | Rejected
          v-tab#archived(ripple)
            | Archived
    v-divider

    v-scroll-y-reverse-transition
      v-toolbar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selected.length < creatives.length') {{ selected.length }} creatives selected
          v-toolbar-title.text-subtitle-1(v-if='!exportAll && selected.length === creatives.length && creatives.length < totalItems') The {{ creatives.length }} creatives on this page are selected.

        span.hidden-md-and-up
          v-chip.ma-1(v-if='creatives.length < totalItems && !exportAll' outlined color='white' small)
            | {{ selected.length }}
          v-chip.ma-1(v-if='selected.length === totalItems || exportAll' dark color='white' class='secondary--text' small)
            | {{ totalItems }}

        v-toolbar-items.ml-3
          v-btn.mx-2(:icon='$vuetify.breakpoint.smAndDown' :text='$vuetify.breakpoint.mdAndUp' @click='exportCsv()')
            v-icon(:small='$vuetify.breakpoint.mdAndUp') mdi-download
            span.ml-2.hidden-sm-and-down Export CSV

          confirmationDialog(@confirmAction='archiveCreatives' type='creative' :action="currentTab === 3 ? 'restore' : 'archive'" :numberOfEntries='selected.length')

        v-spacer
        v-btn.ml-3(icon @click='selected = []')
            v-icon mdi-close

    v-data-table.cs-data-table(
      :loading='tableLoading'
      :headers='headers'
      :items='creatives'
      :server-items-length='totalItems'
      :options.sync='pagination'
      :footer-props='{itemsPerPageOptions: rowsPerPageItems}'
      v-model='selected'
      show-select
      :dense='$vuetify.breakpoint.xsOnly'
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      :no-data-text='noDataText'
    )
      //- Mobile
      template.text-left(v-slot:item="{ item, select, isSelected }" v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center
            v-flex.text-left.d-flex(xs8)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              v-chip.text-uppercase(v-if="canShowStatus(item)" small outlined :color='statusColor(item)')
                v-icon(:color='statusColor(item)' small) mdi-circle
                span(class='text--primary') &nbsp;{{ statusLabel(item) }}

            v-flex.text-right(xs4)
              .text-caption.grey--text
                | {{ item.uploadDate | date('MMM Do, YYYY') }}

          v-layout.my-2.align-start
            v-flex(xs4)
              router-link.info--text(:to="'/creatives/' + item.id")
                creativeImage(:creative='item')
            v-flex.pl-3(xs8)
              .d-block(v-if='item.advertiser' :title='item.advertiser.name')
                div.text-overline
                    span {{ item.advertiser.name }}
                    .d-block(v-if='can.read.orgInfo && item.buyer' :title='item.buyer.name')
                      |  by {{ item.buyer.name }}
                div.text-caption(class='grey--text' :title="item.industry? item.industry : 'N/A'") {{ item.industry? item.industry : 'N/A'  }}
              div.d-block(style='word-break: break-word;')
                router-link.info--text(:to="'/creatives/' + item.id" )
                  .text-body-2.my-2(style='word-break: break-word;') {{ item.name }}
              v-chip-group
                v-chip(small)
                  v-icon(small) mdi-clock-outline
                  span {{ item.duration && item.type !== 'Image' ? Math.round(item.duration) : 'N/A' }}
                v-chip(small) {{ creativeSizeLabel(item.creativeFormat) }}

      template(v-slot:item.preview='{ item }')
        creativeImage(:creative='item')

      template(v-slot:item.name='{ item }')
        v-chip.mb-1.text-uppercase.d-block(v-if="canShowStatus(item)" :color='statusColor(item)' small outlined style="width: fit-content;")
         v-icon.mr-1.ml-0(:color='statusColor(item)' small) mdi-circle
         small(class='text--primary' style='white-space: nowrap;') {{ statusLabel(item) }}
        router-link.info--text(:to="'/creatives/' + item.id" style='word-break: break-word;') {{ item.name }}

      template(v-slot:item.advertiser='{ item }')
        div(style='max-width:180px;')
          .d-block(v-if='item.advertiser' :title='item.advertiser.name') {{ item.advertiser.name }}
            .text-caption.d-block.text-truncate(class='grey--text' :title="item.industry? item.industry : 'N/A'") {{ item.industry? item.industry : 'N/A'  }}
            .d-block.text-caption(v-if='can.read.orgInfo && item.buyer' :title='item.buyer.name')
              | by {{ item.buyer.name }}

      template(v-slot:item.size='{ item }')

          | {{ creativeSizeLabel(item.creativeFormat) }}

      template(v-slot:item.duration='{ item }')
        v-chip(small color='')
          v-icon.mr-1(small) mdi-clock-outline
          | {{ item.duration && item.type !== 'Image' ? Math.round(item.duration) : 'N/A' }}

      template(v-slot:item.uploadDate='{ item }')
        | {{ item.uploadDate | date('MMM Do, YYYY') }}
    v-divider
    v-btn.hidden-md-and-up.add-creative-btn(medium fab color='primary' router-link to='/creatives/add' fixed bottom right v-if='can.create.default' v-show='!selected.length')
      v-icon(dark large) mdi-plus
</template>

<script>
import creativeImage from '@/components/creativeImage.vue'
import confirmationDialog from '@/components/actionConfirmationDialog.vue'

import _ from 'lodash'
import creativesApi from '@/services/creatives.api'
import csv from '@/services/csv.service'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    creativeImage,
    confirmationDialog
  },
  created: function () {
    // set "tab" from URL
    var location = this.$route.path
    var locationPieces = location.split('/')

    const tabName = locationPieces[locationPieces.length - 1]
    const tabIndex = this.tabs.findIndex(tab => tab === tabName)
    this.currentTab = tabIndex
  },
  computed: {
    tableLoading () {
      return this.fetchingCreatives
    },
    noDataText () {
      if (!this.creatives.length && this.search.length) {
        return 'No creative matches your search'
      } else {
        return 'No creative found'
      }
    },
    can () {
      const permissions = this.$store.getters['user/permissions']('creative')
      return permissions
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  data () {
    return {
      pagination: {
        sortBy: ['uploadDate'],
        sortDesc: [true],
        rowsPerPage: 50,
        page: 1,
        totalItems: 0
      },
      headers: [
        { text: 'Preview', align: 'left', width: '112px', value: 'preview', sortable: false },
        { text: 'Filename', align: 'left', value: 'name', width: '260px' },
        { text: 'Advertiser', align: 'left', value: 'advertiser', width: '190px' },
        { text: 'Language', align: 'left', value: 'language', width: '91px' },
        { text: 'Type', align: 'left', value: 'type', width: '80px' },
        { text: 'Size', align: 'left', value: 'size', width: '130px', sortable: false },
        { text: 'Duration', align: 'left', value: 'duration', width: '90px' },
        { text: 'Created On', align: 'left', value: 'uploadDate', width: '100px' }
      ],
      creatives: [],
      selected: [],
      search: '',
      lastSearch: '',
      currentTab: 0,
      tabs: ['all', 'pending', 'rejected', 'archived'],
      totalItems: 0,
      rowsPerPageItems: [50, 100, 250, 500],
      fetchingCreatives: true,
      exportAll: false
    }
  },
  watch: {
    search: _.debounce(function () {
      this.getCreatives()
    }, 450),
    currentTab: function (newVal, oldVal) {
      // bypass initial set from URL
      if (newVal !== oldVal) {
        this.changeTabURL(newVal, oldVal)
        this.getCreatives()
      }
    },
    selected: function (newVal, oldVal) {
      if (newVal.length !== this.creatives.length) {
        this.exportAll = false
      }
    },
    pagination: function (newVal, oldVal) {
      this.getCreatives()
    }
  },
  methods: {
    getCreatives () {
      this.fetchingCreatives = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
      // console.log(sortBy, sortDesc, page, itemsPerPage)

      // SortBy
      var sortByAPI = sortBy
      if (sortBy[0] === 'advertiser') {
        sortByAPI = 'advertiser/name'
      }

      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'
      // console.log('page', page, '[' + skip.toString() + ', ' + take.toString() + ']', sortBy, sort, this.search)

      // Filters
      var filters = []

      if (this.search) {
        filters.push({ name: 'search', value: this.search })
      }

      switch (this.currentTab) {
        case 0:
          filters.push({ name: 'status', keyName: 'status', value: 'Active' })
          filters.push({ name: 'status', keyName: 'processStatus ', operator: 'ne', value: 'New' })
          break
        case 1:
          filters.push({ name: 'status', keyName: 'status', value: 'Active' })
          filters.push({ name: 'status', keyName: 'adQualityStatus', value: 'Pending' })
          filters.push({ name: 'status', keyName: 'processStatus ', operator: 'ne', value: 'New' })
          break
        case 2:
          filters.push({ name: 'status', keyName: 'status', value: 'Active' })
          filters.push({ name: 'status', keyName: 'adQualityStatus', value: 'Rejected' })
          filters.push({ name: 'status', keyName: 'processStatus ', operator: 'ne', value: 'New' })
          break
        case 3:
          filters.push({ name: 'status', keyName: 'status', value: 'Archived' })
          break
      }

      this.lastSearch = this.search

      creativesApi.getCreativesCount(filters)
        .then(count => {
          this.totalItems = count
        })

      creativesApi.getCreatives(skip, take, sortByAPI, sort, filters)
        .then(creatives => {
          this.fetchingCreatives = false
          this.creatives = creatives

          if (this.exportAll) {
            this.selected = creatives
          }
        })
    },
    archiveCreatives () {
      this.selected.map(creative => {
        const creativePatch = {
          id: creative.id,
          status: this.currentTab === 3 ? 'Active' : 'Archived'
        }

        creativesApi.editCreative(creativePatch)
          .then(resp => {
            let msg = ''
            const verb = this.currentTab === 3 ? 'restored' : 'archived'
            if (this.selected.length === 1) {
              msg = 'Creative ' + creative.name + ' has been ' + verb
            } else {
              msg = 'The selected creatives have been ' + verb
            }
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            this.selected = []
            this.getCreatives()
          })
          .catch(error => {
            let msg = error.data.errors[0].errorCode
            if (msg === 'unexpected') {
              msg = 'Unexpected: ' + error.data.errors[0].id
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
      })
    },

    exportCsv () {
      const formattedList = this.selected.map(x => {
        return {
          Creative: x.name,
          // Status: this.statusLabel(x),
          Advertiser: x.advertiser.name,
          Language: x.language,
          Type: x.type,
          Size: this.creativeSizeLabel(x.creativeFormat),
          Duration: x.type === 'Video' ? Math.round(x.duration) : 'N/A',
          'Created On': this.$options.filters.date(x.uploadDate, 'MMMM Do, YYYY')
        }
      })
      csv.csvExport(formattedList, 'creatives')
    },

    canShowStatus (creative) {
      return creative.adQualityStatus !== 'Approved'
    },

    statusLabel (creative) {
      return creative.status === 'Archived' ? creative.status : (creative.adQualityStatus === 'Pending' ? 'To Be Reviewed' : creative.adQualityStatus)
    },

    statusColor (creative) {
      if (creative.status === 'Archived') { return 'grey' }
      switch (creative.adQualityStatus) {
        case 'Approved': return 'success'
        case 'Pending': return 'warning'
        case 'Rejected': return 'error'
      }
    },

    creativeSizeLabel (creativeFormat) {
      let sizeLabel = 'N/A'
      if (creativeFormat) {
        var { minWidth, minHeight } = creativeFormat
        if (minWidth && minHeight) {
          sizeLabel = minWidth + ' x ' + minHeight
        }
      }
      return sizeLabel
    },

    changeTabURL (newVal, oldVal) {
      const oldTab = this.tabs[oldVal]
      const newTab = this.tabs[newVal]

      var path = this.$route.path
      var pathPieces = path.split('/')
      var urlTab = pathPieces[pathPieces.length - 1]

      if (urlTab !== newTab) {
        var newLocation
        var index = path.lastIndexOf(oldTab)
        var locationPieces = path.split('')

        // route's beforeEnter() has not finished executing and User clicks on a tab
        if (index === -1) {
          newLocation = locationPieces.join('') + '/' + newTab
        } else {
          locationPieces.splice(index, index + oldTab.length, newTab)
          newLocation = locationPieces.join('')
        }

        this.$router.push({ path: newLocation, query: this.$route.query })
      }
    }
  }
}
</script>
