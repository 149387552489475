function pacingDelta (line, deliveringPercentage) {
  const diff = Math.round(line.pacing * 100 - deliveringPercentage)
  if (line.status === 'Proposal') return '#9e9e9e99'
  if (!isActive(line)) return 'grey'
  if (diff > 20) { return 'error' } else if (diff >= 10) { return 'warning' } else { return 'success' }
}

function isActive (line) {
  return (line.status === 'Active' || line.bookingStatus === 'Active' || line.status === 'Archived') &&
    (line.effectiveStatus !== 'Paused' && line.effectiveStatus !== 'OrderPaused') &&
    (line.timeStatus === 'Ongoing' || line.timeStatus === 'Over' || !line.timeStatus) &&
    line.startDate <= new Date().toISOString() && line.budget > 0
}

export default {
  pacingDelta
}
