<template lang="pug">
div.w-100
  filterSet.filter-set(
    v-for='(filter, i) in addedFilters'
    :key='i'
    :filterToEdit='filter'
    @updatedFilter='updateFilter(i, ...arguments)'
    :searchMethod='searchMethod'
    )
    template(v-slot:opener="{}")
      v-chip.mr-2.mb-2.filter-item-label(
        close
        small
        @click:close='deleteFilter(i)'
        ) {{ toLabelMethod(filter) }}
</template>

<script>
import filterSet from '@/components/filterSet.vue'

export default {
  components: {
    filterSet
  },
  props: {
    addedFilters: {
      type: Array,
      default: () => []
    },
    toLabelMethod: {
      type: Function,
      default: () => ''
    },
    searchMethod: {
      type: Function,
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    updateFilter (index, filter) {
      this.$emit('updatedFilter', { index, filter })
    },
    deleteFilter (i) {
      this.$emit('deletedFilter', i)
    }
  }
}
</script>
<style lang="stylus" scoped>
.w-100 >>> div {
  max-width: 100%;
}
</style>
