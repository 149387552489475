<template lang="pug">
  v-container(grid-list-lg fluid style='height:100%; background:linear-gradient(-45deg, #011866, #17d1a8);')
    v-layout.align-center(fill-height)
      v-flex
        div.mx-auto.text-center(style='height: 32px;')
          v-avatar.elevation-0(size=60 color='white' style='z-index:10;')
            img(src='img/icons/logo-192x192.png' alt='avatar' style='padding: 4px 0 0 4px')
        v-card.elevation-16.secondary--text(light max-width='400' min-height='384' class='mx-auto')
          v-window(v-model='step')
            v-window-item(:value='1')

              v-card-title.text-center.d-block
                .body-2.mt-4.text-uppercase We've changed our name!
                .text-h6 Campsite is now Broadsign Ads
                .body-1.mt-6(data-testid='login-title')
                  strong  Log in to Broadsign Ads
                div.text-caption.grey--text Need a Broadsign Ads account?
                  a.info--text(href='http://broadsign.com/broadsign-ads/#overview' target='_blank')  Request an account
              v-container
                v-layout(row wrap)
                  v-flex.text-center(xs12)
                    v-form(ref='loginForm' @submit.prevent='login')
                      #login-field-wrapper-email
                        v-text-field#login-field-email(
                          v-model='email'
                          name='email'
                          color='csblue'
                          placeholder='Email address'
                          :rules='[rules.required, rules.validEmail]'
                        )
                      #password-field-wrapper-email
                        v-text-field#login-field-password(
                          v-model='password'
                          color='csblue'
                          name='password'
                          placeholder='Password'
                          single-line
                          :rules='[rules.required, rules.min]'
                          :type="showPassword ? 'text' : 'password'"
                        )
                      v-btn#login-btn-submit.my-3(
                        rounded
                        large
                        type='submit'
                        @click='login'
                        :dark='!loading'
                        color='csblue'
                        :loading='loading'
                        :disabled='loading'
                        min-width='200'
                      ) Log In
                    div.text-center
                      a.text-caption.info--text(@click='step++') Forgot your password?

            v-window-item(:value='2')
              v-card-title.text-center.d-block
                .text-h6.mt-6 Forgot your password?

              v-container
                v-layout(row wrap)
                  v-flex.text-center(xs12)
                    v-form(ref='forgotForm')
                      .text-body-1.mb-6.text-left Enter the email address used to sign up.
                      v-text-field(
                        v-model='email'
                        color='csblue'
                        type='email'
                        name='email'
                        label='Email address'
                        :rules='[rules.required, rules.validEmail]'
                      )
                      div()
                      v-btn.my-3(
                        rounded
                        large
                        color='csblue'
                        :dark='!loading'
                        @click='forgotPassword'
                        :loading='loading'
                        :disabled='loading'
                        min-width='200'
                      ) Send Verification Email
                    div.mt-3
                      a.info--text.text-caption(@click='step--') Nevermind

            v-window-item(:value='3')
              v-card-title.text-center.d-block
                .text-h6.mt-6 Reset Password

              v-container
                v-layout(row wrap)
                  v-flex.text-center(xs12)

                    v-form(ref='resetForm' @submit.prevent='resetPassword' autocomplete='off')
                      .text-body-1.mb-6.text-left A reset code has been sent to you by email at this address:
                        i  {{ email.toLowerCase() }}
                      v-text-field(name='code' type='text' value='nope' style='opacity:0; position:absolute;')
                      v-text-field(
                        color='csblue'
                        v-model='code'
                        name='code'
                        label='Reset code'
                        :rules='[rules.required]'
                      )
                      v-text-field(
                        v-model='newPassword'
                        name='newPassword'
                        color='csblue'
                        label='New password'
                        :rules='[rules.required, rules.min]'
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="showPassword ? 'text' : 'password'"
                        hint='At least 8 characters'
                        counter=''
                        @click:append='showPassword = !showPassword'
                      )
                      v-btn.my-3(
                        rounded
                        large
                        type='submit'
                        :dark='!loading'
                        color='csblue'
                        @click='resetPassword'
                        :loading='loading'
                        :disabled='loading'
                        min-width='200'
                      ) Reset password
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      step: this.$route.path === '/forgot' ? 2 : 1,
      loading: false,
      showPassword: false,
      fake: 'nope',
      email: '',
      password: '',
      code: '',
      newPassword: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match'),
        validEmail: v => /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i.test(v) || 'Email must be valid'
      }
    }
  },
  methods: {
    ...mapMutations('snackbar', ['setSnackbar']),
    login () {
      this.loading = true

      if (this.$refs.loginForm.validate()) {
        var payload = {
          username: this.email.toLowerCase(),
          password: this.password,
          dontSetAppReady: true
        }

        this.$store.dispatch('user/login', payload)
          .then(anything => {
            this.setSnackbar({
              type: 'success',
              msg: `Successfully signed in user ${this.email}`
            })

            this.$store.dispatch('general/initializeApp')
              .then(r => {
                this.$router.replace({
                  path: this.$route.query.redirect || '/campaigns/all'
                })

                this.$store.commit('user/setAppReady', true)
              })
          })
          .catch(res => {
            // Cognito returns different messages for "UserNotFoundException" and "NotAuthorizedException",
            // but we don't want to differentiate the 2 cases,
            // so return same message
            if (res.code === 'UserNotFoundException') {
              res.message = 'Incorrect username or password.'
            }

            this.$logger.info('login.methods.login', {
              err: res,
              email: this.email
            })

            this.setSnackbar({
              type: 'error',
              msg: res.message
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    forgotPassword () {
      this.loading = true

      if (this.$refs.forgotForm.validate()) {
        this.$store.dispatch('auth/forgotPassword', { username: this.email.toLowerCase() })
          .then(res => {
            if (res.CodeDeliveryDetails) {
              this.step = 3
            }
          })
          .catch(error => {
            // (!) do NOT pop error if email is not found,
            // cause we don't want people guessing if an email as a Broadsign Ads account (!)
            if (error.code === 'UserNotFoundException') {
              this.step = 3
            } else {
              // in case of technical error
              this.setSnackbar({
                type: 'error',
                msg: error.message
              })
            }
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    },
    resetPassword () {
      this.loading = true

      if (this.$refs.resetForm.validate()) {
        this.$store.dispatch('auth/changePassword', { username: this.email.toLowerCase(), code: this.code, newPassword: this.newPassword })
          .then(res => {
            this.step = 1

            this.setSnackbar({
              type: 'success',
              msg: 'Your password has been reset successfully'
            })
          })
          .catch(error => {
            this.setSnackbar({
              type: 'error',
              msg: error
            })
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
      }
    }
  }
}
</script>
