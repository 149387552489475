<template lang="pug">
div
  div(v-for='itemType in itemTypes' :key='itemType')
    .fm.pb-2(v-if='summary(itemType)' :class='itemType')
      .text-overline {{ itemType }} Condition
      .text-body-2 {{ summary(itemType) }}
</template>

<script>
import services from './services'

export default {
  name: 'FinanceMomentRead',
  props: {
    financeMoment: {
      type: Object
    }
  },
  data () {
    return {
      itemTypes: services.financeTypes
    }
  },
  methods: {
    summary (itemType) {
      return this.financeMoment && this.financeMoment[itemType]
        ? services.buildSummaryForFinanceItemMoment(this.financeMoment[itemType])
        : null
    }
  }
}
</script>
