<template lang="pug">
div
  div(v-if='loading')
    .text-overline Moments
    v-skeleton-loader(max-width='350' type='text')

  div(v-else)
    .text-body-2(v-if='!moment') Moment not found.
    div(v-else)
      weatherRead.momentSummary.weather.mb-2(
        v-if='weatherCondition'
        :weatherMoment='weatherCondition'
        :temperatureScale='temperatureScale'
        )
      financeRead.momentSummary.finance.mb-2(
        v-if='financeCondition'
        :financeMoment='financeCondition'
        )
</template>

<script>
import services from './services'
import weatherRead from './weatherRead.vue'
import financeRead from './financeRead.vue'

export default {
  name: 'MomentRead',
  components: {
    weatherRead,
    financeRead
  },
  props: {
    temperatureScale: {
      type: String,
      default: 'celsius'
    },
    postEvent: {
      type: Object
    },
    momentId: {
      type: String
    },
    authToken: {
      type: String
    }
  },
  data () {
    return {
      loading: true,
      moment: null
    }
  },
  mounted: function () {
    if (this.momentId) {
      this.getMoment()
    }
  },
  watch: {
    postEvent (newVal) {
      if (newVal) {
        if (newVal.name === 'refresh moment') {
          this.getMoment()
        }
      }
    }
  },
  computed: {
    weatherCondition () {
      return this.moment && this.moment.definition.weather
    },
    financeCondition () {
      return this.moment && this.moment.definition.finance
    }
  },
  methods: {
    getMoment () {
      this.loading = true

      services.getMoment(this.momentId, this.authToken)
        .then(moment => {
          this.moment = moment
          this.$emit('momentLoaded', moment)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
