export default {
  unformatLocaleString,
  getSupportedLocale
}

function unformatLocaleString (formattedString, locale) {
  if (!formattedString) return NaN

  const separators = (12345.6).toLocaleString(locale).match(/(\D+)/g)
  return parseFloat(formattedString.replaceAll(separators[0], '').replaceAll(separators[1], '.'))
}

function getSupportedLocale (preferredLocales) {
  const supportedLocales = [
    'en',
    'en-US',
    'en-GB',
    'en-AU',
    'en-CA',
    'nl-NL',
    'es',
    'es-ES',
    'fr-FR',
    'fr-CA',
    'de',
    'de-DE',
    'zh-CN',
    'ru-RU'
  ]

  const locales = Array.isArray(preferredLocales) ? preferredLocales : [preferredLocales]
  for (const locale of locales) {
    if (supportedLocales.includes(locale)) {
      return locale
    }
  }

  return 'en-US'
}
