import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
    region: process.env.VUE_APP_REGION
  }
})

export default {
  namespaced: true,
  state: {
    session: {},
    user: {}
  },
  getters: {
    isLoggedIn: (state = {}) => {
      const session = state.session
      if (!session) return false
      const accessToken = session.accessToken
      if (!accessToken) return false
      const hasToken = accessToken.jwtToken
      const isActive = new Date(accessToken.payload.exp * 1000) > new Date()
      const isMe = accessToken.payload.username === state.user.username
      return hasToken && isActive && isMe
    },
    session: (state = {}) => 'session' in state &&
      Object.keys(state.session).length !== 0
      ? state.session
      : false,
    userSub: (state = {}) => {
      if (state.user && state.user.attributes) {
        return state.user.attributes.sub
      } else if (state.user && state.user.userSub) {
        return state.user.userSub
      } else {
        return false
      }
    },
    username: (state = {}) => state.user &&
      state.user.user
      ? state.user.user.username
      : false,
    userAttributes: (state = {}) => state.user &&
      state.user.attributes
      ? state.user.attributes
      : false,
    userGroups: (state = {}) => state.session &&
      state.session.accessToken &&
      state.session.accessToken.payload &&
      state.session.accessToken.payload['cognito:groups']
      ? state.session.accessToken.payload['cognito:groups']
      : false,
    idToken: state => {
      return state.session && state.session.idToken
        ? state.session.idToken.jwtToken
        : null
    }
  },
  mutations: {
    setUser: (state, user) => {
      state.user = { ...user }
      state.session = state.user.signInUserSession
    }
  },
  actions: {
    fetchCurrentAuthenticatedUser ({ commit }) {
      return Auth.currentAuthenticatedUser()
        .then((user) => {
          commit('setUser', user)
          return user
        })
    },
    refreshToken () {
      return Auth.currentAuthenticatedUser({ bypassCache: true })
    },
    fetchSession ({ commit }) {
      return Auth.currentSession().then(session => {
        return Auth.currentUserPoolUser()
          .then(user => {
            commit('setUser', user)
            return session
          })
      })
    },
    fetchJwtToken () {
      return Auth.currentSession().then(session => {
        return session.getAccessToken().getJwtToken()
      })
    },
    signInUser ({ commit }, credentials) {
      return Auth.signIn(credentials.username, credentials.password).then((user) => {
        commit('setUser', user)
        return user
      })
    },
    answerCustomChallenge ({ commit }, credentials) {
      return Auth.sendCustomChallengeAnswer(credentials.user, credentials.answer).then((user) => {
        commit('setUser', user)
        return user
      })
    },
    registerUser ({ commit }, credentials) {
      return Auth.signUp({
        username: credentials.username,
        password: credentials.password,
        attributes: credentials.attributes
      }).then(user => {
        commit('setUser', user)
        return user
      })
    },
    confirmUser (context, data) {
      return Auth.confirmSignUp(data.username, data.code)
    },
    resendConfirmation (context, data) {
      return Auth.resendSignUp(data.username)
    },
    forgotPassword (context, data) {
      return Auth.forgotPassword(data.username)
    },
    changePassword (context, data) {
      return Auth.forgotPasswordSubmit(
        data.username,
        data.code,
        data.newPassword
      )
    },
    updatePassword (context, data) {
      return Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, data.oldPassword, data.newPassword))
    },
    async signOut ({ commit, getters }) {
      if (!getters.isLoggedIn) throw new Error('User not logged in.')

      return Auth.signOut().then(result => {
        commit('setUser', {})
        if (localStorage) localStorage.removeItem('USER')
        return result
      })
    }
  }
}
