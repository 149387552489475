<template lang="pug">
v-card
  v-card-title.text-h5 Change Password
  v-card-text
    v-form(ref='password' v-model='formIsValid')
      v-container.px-0(fluid)
        v-row.my-0
          v-col#current-password-wrapper(cols=12 md=6)
            v-text-field#current-password(
              v-model='currentPassword'
              :rules='[rules.required]'
              counter
              type='password'
              label='Current Password'
              :append-icon="showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showCurrentPassword ? 'text' : 'password'"
              @click:append='showCurrentPassword = !showCurrentPassword'
              )
          v-col#new-password-wrapper(cols=12 md=6)
            v-text-field#new-password(
              v-model='newPassword'
              :rules='[rules.required, rules.min]'
              counter
              type='password'
              label='New Password'
              hint='At least 8 characters'
              :append-icon="showNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append='showNewPassword = !showNewPassword'
              )
  v-card-actions.px-5.pb-5
    v-spacer
    v-btn.px-3#password-btn-submit(rounded color='primary' :block='$vuetify.breakpoint.xsOnly' :disabled='!formIsValid' :loading='changeBtnLoading' @click='changePassword()') Update Password
</template>

<script>

export default {
  data () {
    return {
      formIsValid: false,
      changeBtnLoading: false,
      showCurrentPassword: false,
      showNewPassword: false,
      currentPassword: '',
      newPassword: '',
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  methods: {
    changePassword () {
      if (this.$refs.password && this.$refs.password.validate()) {
        this.changeBtnLoading = true

        var params = {
          oldPassword: this.currentPassword,
          newPassword: this.newPassword
        }

        this.$store.dispatch('auth/updatePassword', params)
          .then(authResp => {
            if (authResp === 'SUCCESS') {
              this.$store.commit('snackbar/setSnackbar', {
                type: 'success',
                msg: 'Successfully updated'
              })
            }
          })
          .catch(err => {
            var msg = 'Something went wrong, please try again.'

            if (err && err.code) {
              if (err.code === 'NotAuthorizedException') {
                msg = 'Invalid current password'
                this.currentPassword = ''
              } else {
                if (err.message) {
                  msg = err.message
                }
              }
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: msg
            })
          })
          .finally(() => {
            this.changeBtnLoading = false
          })
      }
    }
  }
}
</script>
