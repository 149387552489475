<template lang="pug">
  .d-block(style="width: 100%;")
    .d-inline-block(style="width: 100%;")
      v-tooltip(top dark max-width='300' color='secondary')
        template(v-slot:activator="{on}")
          div#progress-container(:style="'position:relative; min-width:' + width + 'px;'" v-on="on")
            .progress-mark(v-if="!isProposal" :style='"left: " + pacingPercentage + "%;"')
            v-progress-linear.mt-1.mb-1(:value='progressPercentage' height='8' :color='color')
        span#progress-pacing-caption.text-caption {{ 'Pacing: ' + pacingPercentage + '% - Delivered: ' + progressPercentage + '%' }}
      div.d-flex
        span#progress-actual.flex-grow-1.text-caption.text-left(v-if="!isProposal") {{ formatNumber(actual) }} {{ actualAmountLabel }}
        span#progress-target.flex-grow-1.text-caption.text-right {{ formatNumber(target) }} {{ targetAmountLabel }}
        .d-inline-block(v-if='warningMsg')
          v-tooltip#progress-alert(top color='secondary')
            template(v-slot:activator="{on}")
              v-icon.ml-1.mb-2(color='error' small v-on="on") mdi-alert
            | {{ warningMsg }}
</template>

<script>
import helpers from '@/services/helpers.service'
import { NumberFormattingEnum } from '@/enums'

export default {
  props: ['isProposal', 'color', 'progressPercentage', 'pacingPercentage', 'target', 'actual', 'formatting', 'actualAmountLabel', 'targetAmountLabel', 'minWidth', 'warningMsg'],
  data () {
    return {
      width: this.minWidth ? this.minWidth : 0
    }
  },
  methods: {
    formatNumber (number) {
      if (this.formatting === NumberFormattingEnum.NUMBER) {
        return number && !isNaN(number) ? helpers.shortenNumber(Math.floor(number)) : 0
      } else if (this.formatting === NumberFormattingEnum.CURRENCY) {
        var numberToDisplay = (number && !isNaN(number)) ? number : 0
        return helpers.formatMoney(numberToDisplay, 2, this.$store.getters['general/marketDefaultValues'].currencySymbolString)
      }
    }
  }
}
</script>

<style scoped>
.progress-mark{
  position: absolute;
  border-right: 2px solid blue;
  height: 100%;
  width: 1px;
  display: block;
  top: 0;
  z-index: 1;
}
</style>
