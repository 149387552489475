<template lang="pug">
div
  vue-headful(:title="componentConfig.branding.title(headTitle)")
  v-container.loading-state(v-if='isFetchingReport' style='min-height:240px;' fluid grid-list-lg)
    v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
      v-col.pt-0.pb-0(cols='12' xl='8' offset-xl='2')
        v-skeleton-loader.mt-1.mb-3(type='image' height=42 width='80%')
        v-skeleton-loader.mt-2(type='text' width='20%')
        v-skeleton-loader.mt-2.mb-1.d-flex.mr-2(type='chip@3')
      v-col.pt-2.pb-0(cols='12' lg='5')
    v-row.mb-0.mt-2()
      v-col(cols='12' sm='6' lg='2' xl='2' offset-xl='2')
        v-skeleton-loader(type='image' height=60)
      v-col(cols='12' sm='6' lg='2' xl='2')
        v-skeleton-loader(type='image' height=60)
      v-col(cols='6' sm='6' lg='2' xl='1')
        v-skeleton-loader(type='image' height=60)
      v-col(cols='6' sm='6' lg='2' xl='1')
        v-skeleton-loader(type='image' height=60)
      v-col(cols='6' sm='6' lg='2' xl='1')
        v-skeleton-loader(type='image' height=60)
      v-col(cols='6' sm='6' lg='2' xl='1')
        v-skeleton-loader(type='image' height=60)
  v-container.report.pt-3.px-6(v-else fluid)
    v-row.my-0(:dense='$vuetify.breakpoint.xsOnly' row wrap)
      v-col.pb-0(cols='12' xl='8' offset-xl='2')
        //- .text-overline {{ organizationName }}
        .text-h4.report-name {{ reportName }}
        //- .text-caption.text--secondary Report created by Maxime Chouinard

        v-toolbar.my-2(flat dense color='transparent' style='border-top: 1px solid rgba(0,0,0,.12) !important; border-bottom: 1px solid rgba(0,0,0,.12) !important;')
          div.mr-2
            strong Results:
          .no-runs.mr-4(v-if='!pickedRun') none
          v-menu(v-else)
            template(v-slot:activator="{ on, attrs }")
              v-btn.current-run(text v-bind='attrs' v-on='on')
                | {{ pickedRun.label }}
                v-icon(right) mdi-menu-down
            v-list
              v-list-item(v-for='(item, index) in runs' :key='index' @click='pickRun(item)')
                v-list-item-title {{ item.label }}

          v-btn.export-btn.mx-2(text color='primary' :loading='isDownloadingCsv' :disabled='!pickedRun || isDownloadingCsv' @click='downloadCsv()')
            v-icon(left) mdi-download
            | Export results
            template(v-slot:loader='')
              .download-state
                v-progress-circular.mr-2(:size='23' :width='2' indeterminate)
                | Downloading...
          v-btn.run-report(text color='primary' :disabled='isRunReportBtnDisabled' @click='runReport()')
            v-icon(left) mdi-refresh
            | Run Report
          v-btn.edit-report-btn(text color='primary' @click='openEditDialog()')
            v-icon(left) mdi-pencil
            | Edit
          v-menu(offset-y eager)
            template(v-slot:activator='{ on, attrs }')
              v-btn.action-menu(icon='' v-on='on' v-bind='attrs')
                v-icon mdi-dots-vertical
            v-card.px-2.py-3
              v-btn.d-block.mb-2(text disabled)
                v-icon.mr-2 mdi-content-copy
                | Duplicate
              confirmationDialog(v-if='!deletingReport' @confirmAction='deleteReport' type='report' action='delete' :numberOfEntries='1')

        v-row.my-0
          v-col(cols='12' sm='12' lg='8' xl='6')
            v-card(flat height='100%' style='background-color:transparent;')
              .text-overline Filter
              filtersSummary(v-if='report' :report='report')
          v-col(cols='12' sm='6' lg='2' xl='2')
            v-card(flat height='100%' style='background-color:transparent;')
              //-.text-overline Date Range
              dateRangeSummary(v-if='report' :report='report')
          v-col(cols='12' sm='6' lg='2' xl='2')
            v-card(flat height='100%' style='background-color:transparent;')
              .text-overline Scheduled
              scheduleSummary(v-if='report' :report='report')
          v-col(cols='12' sm='6' lg='3' xl='2')
            v-card(flat height='100%' style='background-color:transparent;')
              .overline Recipient Emails
              recipientsSummary(v-if='report' :report='report')
  v-divider

  v-row.mb-0.mx-0.mt-3.generating-report-msg(justify='center' v-if='isCurrentlyProcessing')
    v-col(cols='auto')
      .grey--text Generating report. This might take a few moments...

  v-row.mb-0.mx-0.mt-3.no-runs-yet-msg(justify='center' v-if='showNextRunSchedule')
    v-col(cols='auto')
      .grey--text No results yet. Next scheduled run: {{ nextRun }}

  v-row.mb-0.mx-0.mt-3.failed-run-msg(justify='center' v-if='hasPickedRunFailed')
    v-col(cols='auto')
      .grey--text Report export has failed. Please re-run the report.

  v-data-table#sheduledReport(
    v-if='pickedRun && !hasPickedRunFailed'
    :headers='tableHeaders'
    :items='runData'
    :loading='isFetchingRunData'
    :items-per-page='50'
    dense fixed-header
    hide-default-footer
    disable-sort
    )
  v-card.pt-3(v-if='pickedRun && !hasPickedRunFailed' :disabled='isFetchingRunData' flat)
    v-divider
    v-row.ma-0.py-2.pr-3(justify='end' no-gutters)
      v-col(cols='auto')
        v-btn.mx-2.prev-page(icon small :disabled='previousPageDisabled' @click='goToPreviousPage')
          v-icon mdi-chevron-left
      v-col(cols='auto')
        v-btn.mx-2.next-page(icon small :disabled='isNextPageDisabled' @click='goToNextPage')
          v-icon mdi-chevron-right

  reportEditDialog(:report='report' :openDialogEvent="editDialogEvent" @dialogClosed='onEditDialogClosed' @refreshRuns='refreshRuns')
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import reportsService from '@/services/reports'
import helpers from '@/services/helpers.service'
import csv from '@/services/csv.service'
import tracking from '@/services/tracking'
import componentConfigService from '@/services/componentConfig'

import reportEditDialog from '@/views/reportEditDialog.vue'
import filtersSummary from '@/components/report.filtersSummary.vue'
import periodSummary from '@/components/report.periodSummary.vue'
import dateRangeSummary from '@/components/report.dateRangeSummary.vue'
import scheduleSummary from '@/components/report.scheduleSummary.vue'
import recipientsSummary from '@/components/report.recipientsSummary.vue'
import confirmationDialog from '@/components/actionConfirmationDialog.vue'

export default {
  components: {
    reportEditDialog,
    filtersSummary,
    periodSummary,
    dateRangeSummary,
    scheduleSummary,
    recipientsSummary,
    confirmationDialog
  },
  created () {
    this.getReport()
    this.getRuns()
  },
  data () {
    return {
      editDialogEvent: null,
      pickedRun: null,
      isDownloadingCsv: false,
      isFetchingRunData: false,
      pagination: {
        pageNumber: 1,
        pageSize: 25
      },
      cursorMapping: { page1: null },
      runData: [],
      deletingReport: false
    }
  },
  computed: {
    ...mapState('reports', {
      isFetchingReport: state => state.fetchingReport,
      isFetchingRuns: state => state.fetchingRuns,
      reports: state => state.reports,
      reportRuns: state => state.reportRuns,
      expectedNumberOfRunsFlag: state => state.expectedNumberOfRunsFlag
    }),
    reportId () {
      return parseInt(this.$route.params.id)
    },
    report () {
      return this.reports.find(r => r.id === this.reportId)
    },
    isOnDemandReport () {
      return this.report?.schedule?.frequency === 'OnDemand'
    },
    runs () {
      return this.reportRuns && this.reportRuns.id === this.reportId
        ? this.reportRuns.runs
        : []
    },
    reportName () {
      return reportsService.decodeName(this.report)
    },
    headTitle () {
      return this.reportName || 'Report'
    },
    tableHeaders () {
      return this.runData.length
        ? Object.keys(this.runData[0]).map(d => { return { text: d, value: d } })
        : []
    },
    previousPageDisabled () {
      return this.pagination.pageNumber <= 1
    },
    isCurrentlyProcessing () {
      return (!!this.runs.length && this.runs[0].status === 'Processing') || (this.isOnDemandReport && !this.runs.length)
    },
    hasPickedRunFailed () {
      return this.pickedRun?.status === 'Failed'
    },
    isRunReportBtnDisabled () {
      return !this.isOnDemandReport || this.isCurrentlyProcessing
    },
    showNextRunSchedule () {
      return !this.isOnDemandReport && !this.isCurrentlyProcessing && !this.runs.length && !this.isFetchingRuns
    },
    nextRun () {
      return this.report ? reportsService.getNextRun(reportsService.decodeSchedule(this.report).schedule) : ''
    },
    isNextPageDisabled () {
      return this.cursorMapping['page' + (this.pagination.pageNumber + 1)] === null
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  watch: {
    pickedRun (newV) {
      this.resetPaging()
      if (newV && !this.hasPickedRunFailed) this.getTableData()
    }
  },
  methods: {
    getReport () {
      this.$store.dispatch('reports/getReport', this.reportId)
    },
    onEditDialogClosed () {
      this.getReport()
    },
    openEditDialog () {
      this.editDialogEvent = Math.random()
      tracking.sendEvent(['ga'], 'customReportOpenedEditReportDialog')
    },
    pickLatestRun () {
      if (this.runs.length) this.pickedRun = { ...this.runs[0] }
    },
    pickRun (run) {
      this.pickedRun = { ...run }
    },
    downloadCsv () {
      this.isDownloadingCsv = true
      this.$store.dispatch('reports/getRunFile', { reportId: this.reportId, runId: this.pickedRun.id }).then((data) => {
        tracking.sendEvent(['ga'], 'customReportDownloadedReportExportReportPage')
        csv.csvExport(data, `${this.reportName} - ${this.pickedRun.label}`)
      }).finally(() => { this.isDownloadingCsv = false })
    },
    saveNextCursor (nextPageUrl) {
      let val = null
      if (nextPageUrl) {
        const params = new URLSearchParams(nextPageUrl.split('?')[1])
        val = params.get('cursor')
      }
      Vue.set(this.cursorMapping, `page${this.pagination.pageNumber + 1}`, val)
    },
    getTableData () {
      const options = {
        reportId: this.reportId,
        runId: this.pickedRun.id,
        cursor: this.cursorMapping['page' + this.pagination.pageNumber],
        ...this.pagination
      }

      this.isFetchingRunData = true
      this.$store.dispatch('reports/getRunData', options).then((data) => {
        this.runData = data.data
        this.saveNextCursor(data.nextPage)
        this.isFetchingRunData = false
      })
    },
    goToPreviousPage () {
      this.pagination.pageNumber--
      this.getTableData()
    },
    goToNextPage () {
      this.pagination.pageNumber++
      this.getTableData()
    },
    resetPaging () {
      this.pagination.pageNumber = 1
      this.pagination.pageSize = 25
      this.cursorMapping = { page1: null }
    },
    async getRuns () {
      if (this.deletingReport) return
      await this.$store.dispatch('reports/getRuns', this.reportId)
      if (this.isCurrentlyProcessing) {
        await helpers.timeout(2)
        this.getRuns()
      } else {
        this.pickLatestRun()
      }
    },
    runReport () {
      this.$store.dispatch('reports/generateReport', this.reportId)
      tracking.sendEvent(['ga'], 'customReportRanReportReportPage')
      this.pickedRun = null
      this.getRuns()
    },
    refreshRuns () {
      this.pickedRun = null
      this.getRuns()
    },
    deleteReport () {
      this.deletingReport = true
      this.$store.dispatch('reports/deleteReport', this.reportId).then(() => {
        tracking.sendEvent(['ga'], 'customReportDeletedReportReportPage')
        this.deletingReport = false
        this.$router.push({ name: 'Reports' })
      })
    }
  },
  beforeDestroy () {
    this.$store.commit('reports/clearReport')
  }
}
</script>

<style scoped>
#sheduledReport table{
  overflow-x: scroll;
}
#sheduledReport >>> td,
#sheduledReport >>> th {
  padding-left: 24px !important;
  min-width: 120px;
}
#sheduledReport >>> th span{
  white-space: nowrap;
}
>>> .v-toolbar__content {
  padding-left:0px;
}
</style>
