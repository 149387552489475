<template lang="pug">
v-card.pt-3
  v-toolbar.elevation-0(color='transparent')
    .text-h5.hidden-sm-and-down Users
    v-text-field(label='Search users' v-model='search' hide-details prepend-inner-icon='mdi-magnify' solo clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")

    edit-user-dialog(v-if='$vuetify.breakpoint.mdAndUp && canUpdate' :organizationId='organizationId' @userUpdated='updatedUser')

    template(v-slot:extension='')
      v-tabs(color='primary' background-color='transparent' slider-color='primary' :value='currentTab')
        v-tab(ripple color='primary lighten-4' v-for='tab, i in tabs' :key='i' @change='changeTab(i)')
          | {{ tab }}
  v-divider

  v-scroll-y-reverse-transition
    v-toolbar.action-bar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
      span.hidden-sm-and-down
        v-toolbar-title.text-subtitle-1(v-if='selected.length < users.length') {{ selected.length }} {{ selected.length === 1? "user" : "users"}} selected
        v-toolbar-title.text-subtitle-1(v-if='selected.length === users.length && users.length < totalItems && !selectAll')
          | The {{ users.length }} users on this page are selected
        v-toolbar-title.text-subtitle-1(v-if='selected.length === totalItems || selectAll') All {{ totalItems }} users are selected

      span.hidden-md-and-up
        v-chip.ma-1(v-if='selected.length < totalItems && !selectAll' dark outlined color='white' small)
          | {{ selected.length }}
        v-chip.ma-1(v-if='selected.length === totalItems || selectAll' dark color='white' class='secondary--text' small)
          | {{ totalItems }}

      v-btn.ml-2(text outlined small dark v-if='selected.length === users.length && users.length < totalItems && !selectAll' @click='selectAll = true') Select all
        span.hidden-sm-and-down
          b &nbsp;{{ totalItems }}&nbsp;
          | users

      v-toolbar-items.ml-3
        v-btn(:icon='$vuetify.breakpoint.smAndDown' :text='$vuetify.breakpoint.mdAndUp' :loading='csvBtnLoading' @click='exportUsersToCsv()')
          v-icon(:small='$vuetify.breakpoint.mdAndUp') mdi-download
          span.ml-2.hidden-sm-and-down Export CSV

      v-spacer
      v-btn.ml-3(icon @click='selected = []')
          v-icon mdi-close

  v-data-table.cs-data-table(
    :headers='headers'
    :items='users'
    :loading='isTableLoading'
    :options.sync='pagination'
    :page='pagination.page'
    v-model='selected'
    :footer-props='{itemsPerPageOptions: [10, 25, 50]}'
    show-select
    :dense='$vuetify.breakpoint.xsOnly'
    :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
    must-sort
    :no-data-text='noDataText'
    :server-items-length='totalItems')

    //- MOBILE
    //- class "py-0" added to "v-flex", since this component is wrapped in class ".container.grid-list-lg"
    //- in "organizationSettings" page, which adds padding
    template.text-left(v-slot:item='{ item, select, isSelected }' v-if='$vuetify.breakpoint.xsOnly')
      v-card.ma-3.px-3.py-1
        v-layout.my-2.align-center
          v-flex.text-left.d-flex.py-0(xs6)
            v-checkbox.mt-0.pt-0(@click.stop='isSelected? select(false) : select(true)' :value='isSelected' hide-details color='primary')
            v-chip.ma-1.mt-0.text-uppercase.xs-small(:color='statusColor(item)' small outlined)
              v-icon.mr-1.ml-0(:color='statusColor(item)' small) mdi-circle
              small(class='text--primary' style='white-space: nowrap;') {{ statusLabel(item) }}

          v-flex.text-right.py-0(xs6 style='max-width: 160px;')
            .text-caption.grey--text.d-block.text-truncate {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}

        v-layout.mt-0.mb-2.align-start
          v-flex.py-0(xs12)

            v-list.py-0(two-line max-width='275')
              v-list-item.px-0
                v-list-item-avatar
                  v-icon.my-0(large) mdi-account-circle
                v-list-item-content
                  v-list-item-title {{ item.firstName }} {{ item.lastName }}
                  v-list-item-subtitle
                    a.info--text(icon small :href="'mailto:' + item.email" :title='item.email')
                      v-icon(small) mdi-email
                      |  {{ item.email }}

        v-layout.mt-0.mb-2.align-start
          v-flex.py-0(xs6)
            div.my-2
              v-chip.mr-3(small)
                | Role(s): {{ rolesLabel(item) }}
          v-flex.py-0.text-right(xs6)
            edit-user-dialog(v-if='canUpdate' :user='item' :organizationId='organizationId' @userUpdated='updatedUser')

    //- DESKTOP
    template(v-slot:item.status='{ item }')
      v-chip.ma-1.text-uppercase.xs-small(:color='statusColor(item)' small outlined)
        v-icon.mr-1.ml-0(:color='statusColor(item)' small) mdi-circle
        small(class='text--primary' style='white-space: nowrap;') {{ statusLabel(item) }}

    template.text-left.widths(v-slot:item.organizationId='{ item }')
      router-link.info--text(v-if='item.organizationId' :to='"/organizationsSettings/" + item.organizationId') {{ organizationName(item) }}
      span(v-if='!item.organizationId') {{ organizationName(item) }}

    template(v-slot:item.email='{ item }')
      div(v-if='!fullTable') {{ item.firstName }} {{ item.lastName }}
      a.info--text.text-caption(icon small :href="'mailto:' + item.email" :title='item.email')
        |  {{ item.email }}

    template.text-left.widths(v-slot:item.createdOn='{ item }')
      div(style='max-width: 160px;')
        .d-block.text-truncate {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}

    template(v-slot:item.roles='{ item }')
      | {{ rolesLabel(item) }}

    template.text-left.widths(v-slot:item.edit='{ item }')
      edit-user-dialog(v-if='canUpdate' :organizationId='organizationId' :user='item' @userUpdated='updatedUser')

    template.text-left(v-slot:item.actionBtns='{ item }')
      v-menu(offset-y :close-on-content-click='false' v-if='(canUpdate && item.organizationId)')
        template(v-slot:activator='{ on }')
          v-btn(icon v-on='on' @click.stop='')
            v-icon mdi-dots-vertical
        v-list(dense)
          v-list-item(v-if="item.organizationId && item.status==='Inactive'" @click='confirmUserAction(item, "reinvite")')
            v-list-item-icon.mr-4
              v-icon mdi-send
            v-list-item-title Resend

          v-list-item(v-if="item.organizationId && item.status==='Inactive'" @click='confirmUserAction(item, "cancelInvite")')
            v-list-item-icon.mr-4
              v-icon mdi-cancel
            v-list-item-title Cancel

          v-list-item(v-if='item.organizationId && item.status==="Active" && profile.id != item.id' @click='confirmUserAction(item, "block")')
            v-list-item-icon.mr-4
              v-icon mdi-delete
            v-list-item-title Block

          //- v-list-item(v-if='!item.organizationId' @click='confirmUserAction(item, "unblock")')
          //-   v-list-item-icon.mr-4
          //-     v-icon mdi-lock-reset
          //-   v-list-item-title Un-Block

  userConfirmAction(
    v-if='confirmUser'
    :dialogOpen='confirmDialogOpen'
    :user='confirmUser'
    :action='confirmAction'
    @confirm='userActionConfirmed'
    @close='userActionClosed')
</template>

<script>
import csvService from '@/services/csv.service'
import userApi from '@/services/user.api'
import { debounce } from 'lodash'
import userConfirmAction from '@/components/userConfirmAction'
import editUserDialog from '@/components/editUserDialog.vue'

export default {
  components: {
    userConfirmAction,
    editUserDialog
  },
  props: ['organizationId', 'itemsPerPage', 'fullTable', 'canUpdate'],
  watch: {
    search: debounce(function (newVal, oldVal) {
      this.getUsers()
    }, 450),

    currentTab: function (newVal, oldVal) {
      // bypass initial set from URL
      if (oldVal > -1) {
        this.$set(this.pagination, 'page', 1)
        this.getUsers()
      }
    },

    pagination: function (newVal, oldVal) {
      this.getUsers()
    },

    selected: function () {
      if (this.selected < this.users) {
        this.selectAll = false
      }
    },

    organizationId: function (newVal, oldVal) {
      this.getUsers()
    }
  },

  data () {
    return {
      totalItems: 0,
      selectAll: false,

      currentTab: 0,
      tabs: ['All', 'Pending', 'Active', 'Blocked'],
      selected: [],
      search: '',
      users: [],
      isTableLoading: false,
      csvBtnLoading: false,

      pagination: {
        sortBy: ['createdOn'],
        sortDesc: [true],
        itemsPerPage: this.itemsPerPage || 10,
        page: 1,
        totalItems: 0
      },

      confirmDialogOpen: false,
      confirmUser: null,
      confirmAction: ''
    }
  },

  computed: {
    profile () {
      return this.$store.getters['user/getProfile']
    },

    noDataText () {
      const adjs = [...this.tabs.map(x => x.toLowerCase())]
      adjs[0] = ''

      if (!this.users.length && this.search && this.search.length) {
        return 'No user matches your search'
      } else {
        return 'No ' + adjs[this.currentTab] + ' user found'
      }
    },

    headers () {
      let hdrs = this.fullTable
        ? [
          { text: 'ID', align: 'left', value: 'id' },
          { text: 'First Name', align: 'left', value: 'firstName' },
          { text: 'Last Name', align: 'left', value: 'lastName' },
          { text: 'Email', align: 'left', value: 'email' },
          { text: 'Role(s)', align: 'left', value: 'roles', sortable: false },
          { text: 'Created On', align: 'left', value: 'createdOn' },
          { text: '', align: 'right', value: 'edit', sortable: false },
          { text: '', align: 'right', value: 'actionBtns', sortable: false }
        ]
        : [
          { text: 'Email', align: 'left', value: 'email' },
          { text: 'Role(s)', align: 'left', value: 'roles', sortable: false },
          { text: '', align: 'right', value: 'edit', sortable: false },
          { text: '', align: 'right', value: 'actionBtns', sortable: false }
        ]

      if (this.fullTable && !this.organizationId) {
        // add "Organization" column in "full" mode, if not limited to one organization
        const indx = hdrs.findIndex(x => x.text === 'ID')
        hdrs.splice(indx + 1, 0, { text: 'Organization', align: 'left', value: 'organizationId' })
      }

      // "All" tab => add "Status" column
      if (this.currentTab === 0) {
        hdrs.unshift({ text: 'Status', align: 'left', value: 'status', sortable: false })
      }

      // "Pending" tab => add "Created On"
      if (this.currentTab === 1) {
        const indx = hdrs.findIndex(x => x.value === 'roles')
        hdrs.splice(indx + 1, 0, { text: 'Created On', align: 'left', value: 'createdOn' })
      }

      // "Blocked" tab => remove "Organization" and "Roles" columns
      if (this.currentTab === 3) {
        hdrs = hdrs.filter(h => !['organizationId', 'roles'].includes(h.value))
      }

      return hdrs
    }
  },

  methods: {
    statusColor (item) {
      if (!item.organizationId) { return 'grey' } else if (item.status === 'Active') { return 'success' } else if (item.status === 'Inactive') { return 'warning' }
    },

    statusLabel (item) {
      if (!item.organizationId) { return 'Blocked' } else if (item.status === 'Active') { return 'Active' } else if (item.status === 'Inactive') { return 'Pending' }
    },

    getUsers () {
      this.isTableLoading = true
      this.selected = []
      this.selectAll = false

      var filters = this.formatRequestParams()

      userApi.getUsersCount(filters).then(count => {
        this.totalItems = count
      })

      userApi.getUsers(filters)
        .then(users => {
          this.users = users
          this.isTableLoading = false
        })
        .catch(() => {
          const msg = 'Something went wrong...'
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    exportUsersToCsv () {
      this.csvBtnLoading = true
      const obj = this.formatRequestParams(true)

      if (this.selectAll) {
        obj.selectAll = true
      }

      userApi.getUsersCsv(obj).then(res => {
        this.csvBtnLoading = false
        const date = new Date().toDateString().toLowerCase().split(' ')
        date.splice(0, 1)
        csvService.csvExport(res, 'BroadsignAds-users-' + date.join('-'))
        this.selected = []
      })
        .catch(() => {
          this.csvBtnLoading = false
          const msg = 'CSV file could not be downloaded'
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    confirmUserAction (user, action) {
      this.confirmUser = action === 'block' && user.organizations.find(x => x.id === this.organizationId)
        ? { ...user, organization: user.organizations.find(x => x.id === this.organizationId) }
        : user
      this.confirmAction = action
      this.confirmDialogOpen = true
    },

    userActionConfirmed () {
      switch (this.confirmAction) {
        case 'reinvite' :
          this.reinviteUser(this.confirmUser)
          break
        case 'cancelInvite' :
        case 'block' :
          this.revokeAccess(this.confirmUser)
          break
        case 'unblock' :
          break
      }

      this.userActionClosed()
    },

    userActionClosed () {
      this.confirmUser = null
      this.confirmAction = ''
      this.confirmDialogOpen = false
    },

    revokeAccess (user) {
      userApi.removeUserFromOrganization(user.id, this.organizationId !== 0 ? this.organizationId : user.organizationId).then(res => {
        this.$store.commit('snackbar/setSnackbar', {
          type: 'success',
          msg: `${user.email} access was revoked`
        })
        this.getUsers()
      })
        .catch(() => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Something went wrong...'
          })
        })
    },

    reinviteUser (user) {
      userApi.reinviteUser({ email: user.email, organizationId: user.organizationId }).then(() => {
        this.$store.commit('snackbar/setSnackbar', {
          type: 'success',
          msg: 'Invitation successfully resent'
        })
      })
        .catch(() => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Could not resend the invitation. Please try again'
          })
        })
    },

    updatedUser (user) {
      this.getUsers()
    },

    // ================
    // HELPER FUNCTIONS
    // ================
    formatRequestParams (isCsv = false) {
      const params = {
        filters: []
      }
      if (isCsv && this.selected.length && this.selected.length < this.pagination.itemsPerPage) {
        if (this.selected.length <= 15) {
          params.filters.push('(' + this.selected.map(x => 'id eq ' + x.id).join(' or ') + ')')
        } else {
          params.filters.push('(' + this.users.filter(y => !this.selected.map(w => w.id).includes(y.id)).map(x => 'id ne ' + x.id).join(' and ') + ')')
        }
      }
      // organizationId should be changed to organization/id later
      if (this.currentTab === 1) { params.filters.push("(organizationId ne null and status eq 'Inactive')") }
      if (this.currentTab === 2) { params.filters.push("(organizationId ne null and status eq 'Active')") }
      if (this.currentTab === 3) { params.filters.push('organizationId eq null') }
      if (this.pagination.itemsPerPage > 0) { params.take = this.pagination.itemsPerPage }

      params.skip = (this.pagination.page - 1) * this.pagination.itemsPerPage
      params.sort = this.pagination.sortBy[0] === 'organization' ? 'organization/name' : this.pagination.sortBy[0]
      params.sort += this.pagination.sortDesc[0] ? ' desc' : ' asc'

      if (this.search) {
        const q = encodeURIComponent(this.search)
        params.filters.push(`(contains(firstName,'${q}') or contains(lastName,'${q}') or organizations/any(org: contains(org/name,'${q}')) or contains(email,'${q}'))`)
      }

      if (this.organizationId) {
        params.filters.push('organizations/any(o: o/id eq ' + this.organizationId + ')')
      }

      return params
    },

    changeTab (newVal) {
      this.currentTab = newVal
    },

    organizationName (user) {
      var orgId = this.organizationId || user.organizationId
      var currentOrg = user.organizations.find(o => o.id === orgId)
      if (currentOrg) {
        return currentOrg.name
      } else {
        return 'N/A'
      }
    },

    rolesLabel (user) {
      var orgId = this.organizationId || user.organizationId
      var currentOrg = user.organizations.find(o => o.id === orgId)
      if (currentOrg) {
        return currentOrg.roles.map(x => x.name).sort().join(', ')
      } else {
        return 'N/A'
      }
    }
  }
}
</script>
