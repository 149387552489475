<template lang="pug">
  v-dialog(v-model='isDialogOpen' width="500"  style="height: inherit;")
    template(v-slot:activator="{ on }")
      v-btn(
        v-on="on"
        :class="`${action}-${type.replace(' ','-')}-btn-confirmation`"
        :icon="!textButtonOnly && $vuetify.breakpoint.smAndDown"
        :text="textButtonOnly || $vuetify.breakpoint.mdAndUp"
        )
        v-icon(v-if="!textButtonOnly" :small="$vuetify.breakpoint.mdAndUp") {{ icons[action] }}
        span.ml-2(v-if="textButtonOnly || $vuetify.breakpoint.mdAndUp") {{ actionRenamed }}
    v-card
      v-card-title.text-h5.text-capitalize {{ action + ' ' + type }} {{ type === 'proposal'? ' to campaign' : '' }}
      v-card-text
        div(v-if="!isOverDuplicateLimit") {{ `Are you sure you want to ${action} ${numberOfEntries} ${numberOfEntries > 1? type + 's' : type}?` }}
        span(v-if="isOverDuplicateLimit")
          v-icon.mr-2(color="error") mdi-alert
          .error--text.d-inline {{ 'You cannot duplicate more than ' + duplicateLimit +' ' + type + 's'}}

        v-checkbox.creatives-checkbox(v-if="isDuplicateWithCreatives" v-model="keepCreatives" label="Keep assigned creatives?" color="primary")
        orgPicker.mt-4(
          ref='orgPicker'
          v-if='isDuplicateAuctionPackage'
          :onChange='onOrgPickerChange'
          sameOrgLabel='Keep in the same organization'
          diffOrgLabel='Duplicate in a different organization'
        )
      v-divider
      v-card-actions
        v-spacer
        v-btn#confirmation-cancel-btn(text @click="close") Cancel
        v-btn#confirmation-action-btn(v-if="!isOverDuplicateLimit" :disabled='confirmDisabled' text color='primary' @click="confirmAction()") {{ `${action} ${numberOfEntries} ${numberOfEntries > 1? type + 's' : type}` }}
        v-btn(v-if="isOverDuplicateLimit" text color='primary' :disabled='confirmDisabled' @click="confirmAction()") {{ `${action} first ${duplicateLimit} ${type}s` }}

</template>

<script>
import orgPicker from '@/components/organizationPicker'

export default {
  components: {
    orgPicker
  },
  props: ['type', 'action', 'numberOfEntries', 'textButtonOnly'],
  data () {
    return {
      isDialogOpen: false,
      keepCreatives: true,
      confirmDisabled: false,

      icons: {
        duplicate: 'mdi-content-copy',
        archive: 'mdi-delete',
        delete: 'mdi-delete',
        restore: 'mdi-package-up',
        convert: 'mdi-arrow-right-thick',
        revert: 'mdi-restore'
      }
    }
  },
  computed: {
    duplicateLimit () {
      return this.type !== 'auction package' ? 10 : 50
    },
    isOverDuplicateLimit () {
      return this.action === 'duplicate' && this.numberOfEntries > this.duplicateLimit
    },
    isDuplicateWithCreatives () {
      return this.action === 'duplicate' && this.type !== 'auction package'
    },
    isDuplicateAuctionPackage () {
      return this.action === 'duplicate' && this.type === 'auction package'
    },
    actionRenamed () {
      if (this.action === 'revert') return 'revert to proposal'
      else if (this.action === 'convert') return 'convert to campaign'
      else return this.action
    }
  },
  methods: {
    confirmAction () {
      const params = {}
      if (this.isDuplicateWithCreatives) params.keepCreatives = this.keepCreatives
      if (this.isDuplicateAuctionPackage) params.organizationId = this.$refs.orgPicker.organizationId
      this.$emit('confirmAction', params)
      this.isDialogOpen = false
      if (this.$vuetify.breakpoint.smAndDown) this.$emit('closed')
    },
    close () {
      this.keepCreatives = true
      this.isDialogOpen = false
      if (this.$vuetify.breakpoint.smAndDown) this.$emit('closed')
    },
    onOrgPickerChange (isValid) {
      this.confirmDisabled = !isValid
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-dialog__container, >>> .v-dialog__activator {
  display: flex;
  height: inherit;
}
</style>
