import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'
import store from '@/store'
import router from '@/router'

import { datadogLogs } from '@datadog/browser-logs'

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

/** Returns the current instance of the SDK */
export const getAuth0Instance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data () {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created () {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        redirect_uri: redirectUri,
        useRefreshTokens: true
      })

      let targetUrl

      try {
        // If the user is returning to the app after authentication...
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()
          targetUrl = appState.targetUrl
        }
      } catch (e) {
        datadogLogs.logger.error('auth0.created', { auth0msg: e.toString() })
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        if (!this.isAuthenticated) {
          try {
            this.token = await this.auth0Client.getTokenSilently()
            this.isAuthenticated = await this.auth0Client.isAuthenticated()
          } catch (error) {
            // if there is an error, then the user will remain un-authenticated
          }
        }
        if (this.isAuthenticated) {
          datadogLogs.logger.info('auth0 user authenticated')

          store.commit('auth/setProvider', 'auth0')

          this.user = await this.auth0Client.getUser()
          store.commit('auth/setUser', this.user)

          this.token = await this.auth0Client.getTokenSilently()
          store.commit('user/setToken', this.token)

          const claims = await this.auth0Client.getIdTokenClaims()
          const session = {
            idToken: {
              payload: claims,
              jwtToken: claims.__raw
            }
          }
          store.commit('auth/setSession', session)

          const platformClaims = store.getters['auth/idTokenPlatformClaims']
          const platformId = platformClaims.organizationId

          try {
            await store.dispatch('user/activeOrganizationCheckForAuth0User', platformId)
            if (targetUrl) {
              router.push(targetUrl)
            }
          } catch (e) {
            const userEmail = platformClaims.userEmail
            const errMsg = 'Could not set active BAds organization from platformId for Auth0 user'
            datadogLogs.logger.error(errMsg, { platformId, userEmail, auth0msg: e })
            router.push({ name: 'Access Denied' })
          }
        }

        this.loading = false
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup (o) {
        this.popupOpen = true

        try {
          await this.auth0Client.loginWithPopup(o)
        } catch (e) {
          datadogLogs.logger.error('auth0.methods.loginWithPopup', { auth0msg: e.toString() })
        } finally {
          this.popupOpen = false
        }

        this.user = await this.auth0Client.getUser()
        this.isAuthenticated = true
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback () {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
        } catch (e) {
          datadogLogs.logger.error('auth0.methods.handleRedirectCallback', { auth0msg: e.toString(), user: this.user })
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect (o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims (o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently (o) {
        return this.auth0Client.getTokenSilently(o)
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup (o) {
        return this.auth0Client.getTokenWithPopup(o)
      },
      getUser () {
        return this.auth0Client.getUser()
      },
      /** Logs the user out and removes their session on the authorization server */
      logout (o) {
        return this.auth0Client.logout(o)
      }
    }
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
