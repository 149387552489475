<template lang="pug">
  v-expansion-panel#cost-panel
    v-expansion-panel-header
      template(v-slot:default='{ open }')
        v-row.my-0(no-gutters align='center')
          v-col.text-h6(cols='4') Cost
    v-expansion-panel-content(eager ref='cost-panel-content')
      v-row.my-0.fee(v-for='fee in mediaFees' :key='fee.layoutOrder' align='center' :class='fee.cssClass')
        v-col.fee-label(cols='auto') {{ fee.secondaryLabel }}
        v-col.fee-value(cols=4 md=3 lg=4)
          v-text-field.fee-value-field(
            :key='refreshKey'
            dense single-line filled rounded hide-details
            placeholder='0.00'
            type='number'
            step='.01'
            suffix='%'
            :rules='[rules.positive]'
            :value='fee.displayedValue'
            @blur='updateValue(fee.layoutOrder, ...arguments)'
            )
        v-col.fee-custom-label(cols='auto')
          v-text-field(
            v-if='fee.isLabelFixed'
            dense single-line hide-details disabled
            append-outer-icon='mdi-lock'
            :value='fee.label'
            )
          v-text-field(
            v-else
            placeholder='Custom Label'
            dense single-line hide-details
            append-outer-icon='mdi-close'
            :value='fee.label'
            @blur='updateLabel(fee.layoutOrder, ...arguments)'
            @click:append-outer='deleteFee(fee.layoutOrder)'
            )

      v-btn.mt-3.add-media-fee-btn(text color='primary' @click="addFee('Percentage')" :disabled='mediaFees.length >= 5') + Add Media Fee
</template>

<script>
import helpers from '@/services/helpers.service'

export default {
  props: {
    existingFees: {
      type: Array
    }
  },
  data () {
    return {
      rules: {
        positive: v => v >= 0 || 'Positive numbers only'
      },
      defaultFees: [{
        type: 'Percentage',
        label: 'Broadsign Ads Fee',
        layoutOrder: 1,
        value: 5
      }],
      fees: [],
      refreshKey: 1
    }
  },
  created () {
    this.fees = !this.existingFees
      ? JSON.parse(JSON.stringify(this.defaultFees))
      : JSON.parse(JSON.stringify(this.existingFees.map(ef => { return { ...ef, value: 100 * ef.value } })))

    this.saveFeesInStore()
  },
  computed: {
    mediaFees () {
      // eslint-disable-next-line no-unused-expressions
      this.refreshKey
      return this.fees
        .filter(f => f.type === 'Percentage')
        .sort((a, b) => a.layoutOrder - b.layoutOrder)
        .map(f => {
          return {
            ...f,
            displayedValue: f.value || null,
            secondaryLabel: `Media Fee ${f.layoutOrder}`,
            isLabelFixed: f.layoutOrder === 1,
            cssClass: `media-fee-${f.layoutOrder}`
          }
        })
    }
  },
  methods: {
    saveFeesInStore () {
      const formattedFees = this.formatFeesForApi(this.fees.filter(f => f.value == null || f.value >= 0))
      this.$store.commit('auctionPackage/setFees', formattedFees)
    },
    updateValue (position, e) {
      const value = e.target.value >= 0 ? e.target.value : 0
      const index = this.fees.findIndex(f => f.layoutOrder === position)
      this.fees[index].value = helpers.trimNumber(value, 2)
      this.saveFeesInStore()
      this.refreshKey++
    },
    updateLabel (position, e) {
      const index = this.fees.findIndex(f => f.layoutOrder === position)
      const label = e.target.value

      this.fees[index].label = label
      this.saveFeesInStore()
    },
    addFee (type) {
      if (this.mediaFees?.length >= 5) return

      if (!this.mediaFees?.length) {
        this.fees.push(...JSON.parse(JSON.stringify(this.defaultFees)))
        this.saveFeesInStore()
        return
      }

      this.fees.push({
        type,
        label: '',
        layoutOrder: this.deduceNextFeePosition(this.mediaFees),
        value: null
      })
    },
    deleteFee (position) {
      const index = this.fees.findIndex(f => f.layoutOrder === position)
      this.fees.splice(index, 1)
      this.saveFeesInStore()
    },
    formatFeesForApi (fees) {
      return fees.map(f => {
        return {
          layoutOrder: f.layoutOrder,
          type: f.type,
          label: f.label,
          value: f.value ? helpers.trimNumber(f.value / 100, 4) : 0
        }
      })
    },
    deduceNextFeePosition (fees) {
      for (let i = 0; i < fees.length; i++) {
        if ((i + 1) !== fees[i].layoutOrder) return i + 1
      }

      return fees[fees.length - 1].layoutOrder + 1
    }
  }
}
</script>
