import geohelper from '@/services/geoHelpers/geo.helper.js'
import geoApi from '@/services/geo.api'
import geoService from '../geo.service'
import pLimit from 'p-limit'

let previousBatch = []

export default {
  fetchGeosWithJson,
  fetchGeoInBoundingBox,
  clearPreviousBatch
}

async function fetchGeoInBoundingBox (geoTargets, currentMapViewPort, cancelToken) {
  geoTargets = geohelper.formatCurrentGeoTargets(geoTargets)
  if (!geoTargets.length) return []
  const content = createContent(geoTargets, currentMapViewPort)
  const geosInViewPort = await geoApi.fetchGeoJSONInBoundingBox(content, cancelToken)

  return filterGeoTargets(geoTargets, geosInViewPort)
}

async function fetchGeosWithJson (geosToLoad, cancelToken, concurrencyLimit = 200) {
  const geosToRequestJsonFor = geosToLoad.filter(geo => !existsInArray(previousBatch, geo))
  const previousGeosInMemory = previousBatch.filter(pgeo => existsInArray(geosToLoad, pgeo))

  const loadedGeos = await fetchGeosWithJsonConcurrent(geosToRequestJsonFor, cancelToken, concurrencyLimit)

  const geosToShowOnMap = [...loadedGeos, ...previousGeosInMemory]
  previousBatch = geosToShowOnMap
  return geosToShowOnMap
}

function fetchGeosWithJsonConcurrent (geosToRequestJsonFor, cancelToken, concurrencyLimit) {
  const limitFunc = pLimit(concurrencyLimit)
  const requests = geosToRequestJsonFor.map(geo => limitFunc(() => fetchSingleGeoWithJson(geo, cancelToken)))
  return Promise.all(requests)
}

async function fetchSingleGeoWithJson (geo, cancelToken) {
  let geoJson = extractGeoJson(geo)
  if (!geoJson && geo.geoJSONFileUrl) {
    geoJson = await fetchGeoJsonFromUrl(geo.geoJSONFileUrl, cancelToken)
  }
  return { geoJson, index: geo.index, value: geo.value, label: geo.label }
}

async function fetchGeoJsonFromUrl (url, cancelToken) {
  const result = await geoService.resolveGeoJson(null, url, cancelToken)
  return JSON.parse(result)
}

function extractGeoJson (geo) {
  if (geo.geography) {
    return geo.geography.geoJSON
  } else if (geo.geoJSON) {
    return geo.geoJSON
  }
}

function createContent (geoTargets, currentMapViewPort) {
  return {
    southWest: { lat: currentMapViewPort.minLatitude, long: currentMapViewPort.minLongitude },
    northEast: { lat: currentMapViewPort.maxLatitude, long: currentMapViewPort.maxLongitude },
    coordinates: geoTargets.map(geo => { return { id: geo.id, lat: geo.lat, long: geo.long, radius: geo.radius ?? 50 } })
  }
}

function filterGeoTargets (geoTargets, geosInViewPort) {
  if (!geosInViewPort.length) return []
  return geoTargets.filter(geo => geosInViewPort.includes(geo.index))
}

function clearPreviousBatch () {
  previousBatch = []
}

function existsInArray (array, element) {
  return array.some(e => e.value === element.value && e.label === element.label)
}
