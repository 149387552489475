<template lang="pug">
v-layout.py-0(row wrap)
  v-flex.pb-0(xs12 lg3 xl4 text-xl-right mt-3)
    .text-body-1.font-weight-bold.mr-3.currency-label Currencies
  v-flex(xs12 lg9 xl8 text-md-left)
    v-chip-group.currency-option-group(v-model='selectedCurrency' active-class='primary' column mandatory)
      v-chip.currency-option.mx-1(v-for='market in uniqueCurrencies' :key="market.currency" :value='market.currency') {{ market.currency }}
</template>
<script>

import defaultCurrencyValues from '@/services/defaultCurrencyValues'
import audienceService from '@/services/audience.service'

export default {
  data () {
    return {
      selectedCurrency: 'USD'
    }
  },
  mounted: function () {
    this.updateSelectedCurrency(this.storedSelectedCurrency)
    this.selectedCurrency = this.storedSelectedCurrency
  },
  computed: {
    uniqueCurrencies () {
      return Object.values(defaultCurrencyValues())
        .filter(currency => currency.currency !== 'GBP')
        .sort((x, y) => x.displayOrder - y.displayOrder)
    },
    storedSelectedCurrency () {
      return this.$store.getters['audience/selectedCurrency']
    }
  },
  watch: {
    selectedCurrency (newV, oldV) {
      if (newV !== oldV) {
        this.updateSelectedCurrency(newV)
        this.$emit('storeUpdated')
      }
    }
  },
  methods: {
    updateSelectedCurrency (currency) {
      const obj = audienceService.convertToApiFormatScreenTarget('Currency', [currency])
      if (obj) this.$store.commit('audience/updateTargetingTarget', obj)
    }
  }
}
</script>
