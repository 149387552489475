<template lang="pug">
div
  v-dialog(v-model='isDialogOpen' max-width='700' :fullscreen='$vuetify.breakpoint.smAndDown')
    v-card(flat :tile='$vuetify.breakpoint.smAndDown')
      v-card-title.pb-0
        .text-h5 Export to CSV
        v-spacer
        v-btn(small icon @click='isDialogOpen = false')
          v-icon(color='grey') mdi-close
      v-card-text(style='min-height: 540px;')
        v-container(grid-list-md)
          v-layout(row wrap)
            v-flex(xs12 sm4)
              .text-h6 Group dataset by
              v-radio-group(v-model='groupBy' column)
                v-radio.text-capitalize(v-for='option in groupByOptions' :key='option' :label='option' color='primary' :value='option' hide-details)
            v-flex(xs12 sm4)
              .text-h6 Time Breakdown
              v-radio-group(v-model='timeBreakdown' column)
                v-radio(v-for='option in timeBreakdownOptions' :key='option' :label='option' color='primary' :value='option' hide-details)
            v-flex(xs12 sm4)
              .text-h6 Columns
              div.mt-4
                v-checkbox.pa-0.mt-0.mb-2(
                  v-for='col in columns'
                  :key='col.label'
                  v-model='selectedColumns'
                  :label='col.label'
                  color='primary'
                  :value='col'
                  hide-details
                  height='24'
                )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color='secondary' text @click='isDialogOpen = false') Cancel
        v-btn(color='primary' text @click='exportCsv()') Export
</template>

<script>
import { colsAvailable } from '@/services/csvColumnOptions'

export default {
  props: ['instanceType', 'currentDimension', 'currentDimensions'],
  data () {
    return {
      isDialogOpen: false,
      // impressions, avgCpm, adsServed, locationType, city, latitude, longitude, geoJSON, status, name, spent, numberOfVenues, "numberOfScreens", "date"
      linesColumns: colsAvailable('lines'),
      creativesColumns: colsAvailable('creatives'),
      countriesColumns: colsAvailable('countries'),
      statesColumns: colsAvailable('states'),
      citiesColumns: colsAvailable('cities'),
      environmentsColumns: colsAvailable('environments'),
      timeBreakdownOptions: ['None', 'Month', 'Week', 'Day', 'Hour'],

      timeBreakdown: 'None',
      selectedColumns: [],
      groupByOptions: ['Lines', 'Venues', 'Screens'],
      groupBy: 'Lines'
    }
  },
  watch: {
    isDialogOpen: function () {
      if (this.isDialogOpen === false) { this.clear() } else {
        this.initialize()
      }
    },
    columns (newVal) {
      this.selectedColumns = [...newVal]
    }
  },
  mounted () {
    this.$root.$on('openAdvancedCsvDialog', () => {
      this.isDialogOpen = true
    })
    this.$root.$on('closeAdvancedCsvDialog', () => {
      this.isDialogOpen = false
    })
  },
  computed: {
    labels () {
      const marketVals = this.$store.getters['general/marketDefaultValues']
      return [marketVals.stateLabel, marketVals.postcodeLabel]
    },
    venuesColumns () {
      return colsAvailable('venues', this.labels[0], this.labels[1])
    },
    screensColumns () {
      return colsAvailable('screens', this.labels[0], this.labels[1])
    },
    columns () {
      let finalList = [...this[this.groupBy.toLowerCase() + 'Columns']]
      if (this.timeBreakdown !== 'None' && this.groupBy !== 'lines') { finalList = finalList.filter(x => !['Polygon', 'Latitude & Longitude'].includes(x.label)) }
      if (this.timeBreakdown === 'None') { finalList = finalList.filter(x => !['Date'].includes(x.label)) }
      return finalList
    }
  },
  methods: {
    clear () {
      this.timeBreakdown = 'None'
    },

    initialize () {
      if (this.currentDimension && this.currentDimensions) {
        const currentDimensionIndex = this.currentDimensions.findIndex(x => x.id === this.currentDimension.id)
        this.groupByOptions = [...this.currentDimensions.map(x => x.name)].slice(currentDimensionIndex)
        this.groupBy = this.currentDimension.name
      }
      this.selectedColumns = [...this.columns]
    },

    exportCsv () {
      const filters = {
        groupBy: this.groupBy,
        timeBreakdown: this.timeBreakdown,
        columns: this.selectedColumns.map(x => x.keys).flat()
      }
      this.$emit('selectedCsvFilters', filters)
    }
  }
}
</script>
