<template lang="pug">
v-card.d-block(color='grey lighten-4')
  v-card-title.pt-2 {{ group.name }} {{ isCustomSlider ? 'Range' : '' }}
    v-spacer
    v-btn.close-btn(icon small @click='close()')
      v-icon(color='grey') mdi-close
  v-card-subtitle(v-if="isCustomSlider")
    .text-caption This target is deprecated.
    .text-caption Please use the new and more granular {{ group.name }} target.

  v-card-text.pt-0
    //- (1) Custom Tics Slider
    v-range-slider.px-4.pt-0.pb-4(
      v-if='isCustomSlider'
      :tick-labels='customTicsLabels'
      :value='customTicsSelected'
      min='0'
      :max='customTicsLabels.length - 1'
      color='primary'
      hide-details
      :class='customSliderClass[group.key]'
      @change='updateCustomTics'
    )

    //- (2) Treeview + [1-10] slider
    div(v-if='!isCustomSlider')
      targetTreeview(
        @updateList='updateTreeview'
        :selected='sliderWithTree.selected'
        :group='group'
      )

      v-range-slider.px-4.pt-0.pb-4(
        :tick-labels='[1,2,3,4,5,6,7,8,9,10]'
        :value='sliderWithTree.values'
        min='1'
        max='10'
        color='primary'
        hide-details
        @change='updateTargetingPrecision'
        ref='rangeSliderTarget'
      )

      //- Optional footer
      v-layout.justify-space-between.row
        v-flex
          small.text-caption.text-uppercase Larger Audience
          br
          small.text-caption.text-uppercase Less Targeted
        v-flex.text-right
          small.text-caption.text-uppercase Smaller Audience
          br
          small.text-caption.text-uppercase Highly Targeted
</template>

<script>
import audienceService from '@/services/audience.service'
import targetTreeview from '@/components/targetTreeview.vue'

export default {
  components: {
    targetTreeview
  },
  props: ['group', 'selected', 'isCustomSlider'],
  watch: {
    selected: function () {
      this.init()
    }
  },
  created: function () {
    this.init()
  },
  data: () => ({
    customSliderClass: {
      'Age-range': 'SliderAge',
      'Income-range': 'SliderHHI'
    },

    customTicsLabels: [],
    customTicsValues: [],
    customTicsSelected: [],

    sliderWithTree: { values: [4, 10], selected: [] }
  }),
  methods: {
    init () {
      if (this.isCustomSlider) {
        // reset
        this.customTicsLabels = []
        this.customTicsValues = []
        this.customTicsSelected = []

        // populate ALL possible labels & values
        const options = this.group.targets.find(x => x.key === this.group.key.toLowerCase()).values.filter(targetValue => !targetValue.key.match(/-/g))
        options.forEach(option => {
          this.customTicsLabels.push(option.name)
          this.customTicsValues.push(option.key)
        })

        // populate SELECTED values
        if (this.selected.length) {
          this.customTicsSelected = audienceService.customTicsFormattingToSlider(this.selected, this.customTicsValues, this.group.key)
        } else {
          this.customTicsSelected = [0, this.customTicsValues.length - 1]
        }
      } else {
        if (this.selected.length) {
          this.sliderWithTree.selected = audienceService.removingEndDigitMobileTarget(this.selected)
          this.sliderWithTree.values = audienceService.getExtremumFromTreeList(this.selected)
        }
      }
    },

    close () {
      this.$emit('close', this.group.key, 0)
    },

    updateCustomTics (value) {
      // update Model
      this.customTicsSelected = value

      let res = []
      for (let i = value[0]; i <= value[1]; i++) {
        res.push(this.customTicsValues[i])
      }

      if (res.length === this.customTicsValues.length) {
        this.sendUpdateToParent([])
      } else {
        res = res.join('-')
        this.sendUpdateToParent([res])
      }
    },

    updateTreeview (list) {
      this.sliderWithTree.selected = list
      const res = []
      this.sliderWithTree.selected.forEach(element => {
        for (let i = this.sliderWithTree.values[0]; i <= this.sliderWithTree.values[1]; i++) {
          res.push(element + '-' + i)
        }
      })
      this.sendUpdateToParent(res)
    },

    updateTargetingPrecision (value) {
      this.sliderWithTree.values = value
      const res = []
      this.sliderWithTree.selected.forEach(element => {
        for (let i = this.sliderWithTree.values[0]; i <= this.sliderWithTree.values[1]; i++) {
          res.push(element + '-' + i)
        }
      })
      this.sendUpdateToParent(res)
    },

    sendUpdateToParent (list) {
      this.$emit('update', this.group.key, list)
    }
  }
}
</script>
<style scoped>
.SliderAge{
  padding-top: 16px !important;
  margin-top: 16px !important;
}
.SliderAge >>> .v-slider__tick{
  display:block;
  position: absolute;
  border:none;
}
.SliderAge >>> .v-slider__tick::before{
  width: 38px;
  height: 27px;
  display: block;
  top: -42px;
  left: -15px;
  position: relative;

}
.SliderAge >>> .v-slider__tick:nth-child(1)::before{
  left:0px;
  content: url(https://s3.amazonaws.com/campsite-assets/icons/ic-age-18.svg);
}
.SliderAge >>>.v-slider__tick:nth-child(2)::before{
  content: url(https://s3.amazonaws.com/campsite-assets/icons/ic-age-18-24.svg);
}
.SliderAge >>> .v-slider__tick:nth-child(3)::before{
  content: url(https://s3.amazonaws.com/campsite-assets/icons/ic-age-25-34.svg);
}
.SliderAge >>> .v-slider__tick:nth-child(4)::before{
  content: url(https://s3.amazonaws.com/campsite-assets/icons/ic-age-35-49.svg);
}
.SliderAge >>> .v-slider__tick:nth-child(5)::before{
  left:-32px;
  content: url(https://s3.amazonaws.com/campsite-assets/icons/ic-age-50.svg);
}

.SliderHHI {
  margin-bottom:16px;
}

.SliderHHI >>> .v-slider__tick>div{
  font-size:12px;
  display: block;
  white-space: unset;
}

.SliderScreen >>> .v-slider__tick>div{
  font-size:14px;
}
</style>
