import RoxBrowser from 'rox-browser'

const Rox = {
  async install (Vue, { options, apiKey, flags }) {
    RoxBrowser.register('', flags)
    await RoxBrowser.setup(apiKey, options)

    // Uncomment locally
    // RoxBrowser.showOverrides()

    Vue.prototype.$rox = RoxBrowser
    Vue.prototype.$flags = flags
    Vue.prototype.$flagsPlugin.loaded = true

    window.dispatchEvent(new Event('roxIsReady'))
  }
}

export default Rox
