import moment from 'moment'
import geoService from '@/services/geo.service'
import componentConfigService from './componentConfig'

export default {
  getStatusFromGeography,
  assembleLoadingGetter,
  metricsInfos,
  whereIsInstanceInTime,
  lineStatusLabel,
  lineStatusColor,
  lineStatusIcon,
  lineStatusTooltip,
  isLineOnPace,
  paginateList
}

function getStatusFromGeography (geography, instanceType, id, unit = 'metric') {
  if (!geography) {
    return true
  } else {
    const filtered = geoService.geoFromApiFormatting(geography, unit).find(geo => typeEquality(geo.type, instanceType) && !geo.isIncluded && parseInt(geo.value) === parseInt(id))
    return !filtered
  }
}

function typeEquality (typeFromGeography, instanceType) {
  const adjustedType = typeFromGeography === 'face' ? 'screen' : typeFromGeography
  return adjustedType === instanceType
}

function assembleLoadingGetter (instance) {
  let getterName = 'campaignReport/get'
  getterName += instance[0].toUpperCase() + instance.slice(1) + 'Loading'
  return getterName
}

function metricsInfos (metric, currency = '$', componentConfig) {
  let config = [
    { key: 'impressions', label: 'Impressions', format: 'number', tooltipLabel: 'Impressions' },
    { key: 'avgCPM', label: 'ECPM', format: 'currency', tooltipLabel: `eCPM (${currency})` },
    { key: 'spent', label: 'Spent', format: 'currency', tooltipLabel: `Spent (${currency})` },
    { key: 'adsServed', label: 'Ads Served', format: 'number', tooltipLabel: 'Ads Served' }
  ]
  if (!(componentConfig || componentConfigService()).campaigns.isECPMShown) {
    config = config.filter(({ key }) => key !== 'avgCPM')
  }
  if (!metric) { return config }
  return config.find(x => x.key === metric)
}

function whereIsInstanceInTime (startDate, endDate) {
  const today = moment().format('YYYY-MM-DD')
  const startDateBeforeToday = moment(startDate).isBefore(today)
  const endDateBeforeToday = moment(endDate).isBefore(today)

  if (startDateBeforeToday && endDateBeforeToday) {
    return 'past'
  } else if (!startDateBeforeToday && !endDateBeforeToday) {
    return 'future'
  } else {
    return 'present'
  }
}

function lineStatusLabel (line) {
  switch (line.effectiveStatus) {
    case 'OrganizationInactive':
      return 'Account Inactive'
    case 'OrganizationSuspended':
      return 'Account Suspended'
    case 'MissingCreative':
      return 'Missing Creatives'
    case 'OrderArchived':
      return 'Order Archived'
    case 'OrderPaused':
      return 'Order Paused'
    case 'PendingReview':
      return 'Pending Approval'
    case 'ReadyToDeliver':
      return 'Ready To Deliver'
    case 'Delivering':
      return isLineImpeded(line) ? 'Limited Delivery' : line.effectiveStatus

    default:
      return line.effectiveStatus
  }
}

function lineStatusColor (line) {
  if (isLineDelayed(line)) { return 'warning' }

  if (isLineImpeded(line)) {
    return line.deliveryImpediments.includes('CreativeRejected') ? 'error' : 'warning'
  }

  switch (line.effectiveStatus) {
    // GREEN
    case 'Completed':
    case 'Delivering':
    case 'ReadyToDeliver':
      return 'success'
    // ORANGE
    case 'PendingReview':
      return 'warning'
    // RED
    case 'MissingCreative':
    case 'OrganizationSuspended':
      return 'error'
    // GREY
    case 'Paused':
    case 'OrderPaused':
    case 'OrderArchived':
    case 'OrganizationInactive':
      return 'grey'
  }
}

function lineStatusIcon (line) {
  if (isLineDelayed(line)) { return 'mdi-clock-alert-outline' }
  if (isLineImpeded(line)) {
    return line.deliveryImpediments.includes('CreativeRejected') ? 'mdi-alert' : 'mdi-clock-alert'
  }
  return 'mdi-circle'
}

function lineStatusTooltip (line) {
  if (isLineDelayed(line)) {
    return 'Ad creative delivery may be delayed due to additional approval by media owners. No action is required.'
  }
  if (isLineImpeded(line)) {
    const creativePending = line.deliveryImpediments.includes('CreativePendingApproval')
    const creativeRejected = line.deliveryImpediments.includes('CreativeRejected')
    if (creativeRejected) {
      if (creativePending) {
        return 'An ad creative has been rejected and others require additional approval by media owners. Delivery will be impacted.'
      } else {
        return 'An ad creative has been rejected by media owners. Delivery will be impacted.'
      }
    } else {
      return 'Delivery may be impacted due to additional approval by media owners. No action is required.'
    }
  }
  return ''
}

function isLineImpeded (line) {
  return line.deliveryImpediments && line.deliveryImpediments.length && ['Delivering', 'ReadyToDeliver', 'Completed'].includes(line.effectiveStatus)
}

function isLineDelayed (line) {
  return line.isDelayed && line.effectiveStatus === 'Delivering' && !isLineOnPace(line)
}

function isLineOnPace (line) {
  var pacingPercentage = Math.round(line.pacing * 100)
  var deliveringPercentage = Math.round((line.spent / line.budget) * 100)
  var diffPercentage = pacingPercentage - deliveringPercentage
  return diffPercentage < 10
}

/**
 * @param {Array} list
 * @param {number} take
 * @param {number} skip
 */
function paginateList (list = [], take, skip) {
  if (!take || typeof take !== 'number') take = 10
  if (!skip || typeof skip !== 'number') skip = 0
  if (!list) list = []
  return list.filter((l, i) => i >= skip && i < (skip + take))
}
