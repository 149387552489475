import axios from 'axios'

export default {
  fetchContent,
  getTooltipDescription
}

// const availableSections = [
//   'targetDescriptions'
// ]

function fetchContent (section, lang = 'en') {
  return axios.get(`https://campsite-assets.s3.amazonaws.com/fe-copy/${section}/${lang}.json`)
    .then(res => res.data)
}

function getTooltipDescription (targetKey, descriptions = []) {
  if (!descriptions) descriptions = []
  const target = descriptions.find(x => x.key === targetKey)
  return target ? target.value : null
}
