<template lang="pug">
  v-card.text-center.grey.lighten-4
    v-card-text
      //- UPLOAD
      form(enctype='multipart/form-data' novalidate)
        .file-wrapper(:class="{ 'file-wrapper-hover-drag' : dragging }" @dragenter='dragging=true' @dragleave='dragging=false')
          input.input-file(
            ref='fileBrowser'
            type='file'
            multiple
            name='creativesUploader'
            :accept='acceptedFileTypes'
            :disabled='isSaving'
            @change='filesChange')

          .dropbox
            p.text-subtitle-1.mb-1.pt-3 Drag and drop files here
            p or

          v-btn.mb-4(medium fab color='primary' @click='openFileBrowser')
            v-icon(dark large) mdi-cloud-upload
</template>
<script>
import campsiteConfig from '@/config/campsite.config'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  props: ['uploadData'],
  data () {
    return {
      uploadError: null,
      currentStatus: null,
      dragging: false
    }
  },
  computed: {
    extraUploadData () {
      return this.uploadData || null
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    uploadedFiles () {
      return this.$store.getters['creatives/getUploadingCreatives']
    },
    acceptedFileFormats () {
      var exchange = this.$store.getters['createCampaign/getExchange']
      return campsiteConfig.creatives.acceptedCreativeFormats[exchange.key]
    },
    acceptedFileTypes () {
      var exchange = this.$store.getters['createCampaign/getExchange']
      return campsiteConfig.creatives.acceptedFileTypes[exchange.key]
    }
  },
  methods: {
    openFileBrowser ($event) {
      const elem = this.$refs.fileBrowser
      elem.click()
    },
    reset () {
      this.currentStatus = STATUS_INITIAL
      this.uploadError = null
    },
    save (formData) {
      this.currentStatus = STATUS_SAVING

      this.$store.dispatch('creatives/uploadCreative', formData)
        .then(uploadedCreative => {
          this.currentStatus = uploadedCreative.id ? STATUS_SUCCESS : STATUS_FAILED

          if (uploadedCreative.id) {
            this.$emit('creativeUploaded', uploadedCreative)
          }
        })
    },
    filesChange (event) {
      const fileList = event.target.files

      if (!fileList.length) return

      for (let i = 0; i < fileList.length; i++) {
        const formData = new FormData()

        if (this.extraUploadData) {
          this.extraUploadData.map(data => {
            formData.append(data.name, data.value)
          })
        }

        formData.append('fileId', this.generateFileId())
        formData.append('file', fileList[i], fileList[i].name)

        // save it
        this.save(formData)
      }
      this.$refs.fileBrowser.value = ''
    },
    generateFileId () {
      return 'creativeFile-' + Math.random().toString(36).substr(2, 16)
    }
  },
  mounted () {
    this.reset()
  }
}
</script>
<style scoped lang="stylus">
.file-wrapper
  position: relative
  cursor: pointer
  margin: 3px

.file-wrapper:hover,
.file-wrapper-hover-drag
  margin: 0px
  border: 3px dashed lightgray

.input-file
  opacity: 0
  width: 100%
  height: 100%
  position: absolute
  top: 0
  left: 0
  cursor: pointer
</style>
