<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['obj', 'isLoading'],
  data: () => {
    return {
      initialized: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'right',
          fullWidth: false,
          onClick: null,
          labels: {
            fontSize: 11,
            usePointStyle: true
          }
        },
        tooltips: {
          bodyFontSize: 14,
          xPadding: 8,
          yPadding: 8,
          callbacks: {
            label: function (tooltipItem, data) {
              const val = data.datasets[0].data[tooltipItem.index]
              return ` ${data.labels[tooltipItem.index]}: ${val && parseFloat(val) ? ' ' + parseFloat(val) + '%' : val}`
            }
          }
        }
      },
      colors: ['#76d7d6', '#004fff', '#d64161', '#ff7b25', '#b2e0ae']
    }
  },
  watch: {
    isLoading (newVal, oldVal) {
      if (this.initialized && !this.options.animation) this.options.animation = { duration: 500 }
      if (!newVal && oldVal) this.renderChart(this.obj.chartdata, this.options)
    }
  },
  mounted () {
    this.renderChart(this.obj.chartdata, this.options)
    this.initialized = true
  }
}

</script>
