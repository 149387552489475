<template lang="pug">
  .d-block(style="width: 100%;")
    .d-inline-block(style="width: 100%;")
      campaignProgressBar.impressions-progress-bar(
        ref='impressions-progress-bar'
        :target="targetedImpressions"
        :actual="totalNumberOfDeliveredImpressions"
        :progressPercentage="deliveringPercentage"
        :pacingPercentage="pacingPercentage"
        :color="getProgressColor()"
        :formatting= "'number'"
        :actualAmountLabel = "'Delivered'"
        :targetAmountLabel = "'Target'"
        :min-width="170"
        :isProposal="isProposal"
      )
</template>

<script>
import campaignProgressBar from './campaignProgressBar.vue'
import lineStatusService from '../services/lineStatus.service'

export default {
  components: {
    campaignProgressBar
  },
  props: ['item', 'totalsData', 'minWidth'],
  data () {
    return {
      width: this.minWidth ? this.minWidth : 0
    }
  },
  computed: {
    totalNumberOfDeliveredImpressions () {
      return this.totalsData.impressions ?? 0
    },
    pacingPercentage () {
      if (this.item) {
        return Math.round(this.item.pacing * 100)
      }
      return 0
    },
    targetedImpressions () {
      if (this.item) {
        return (this.item.budget / this.item.maxCpm) * 1000
      }
      return 0
    },
    deliveringPercentage () {
      if (this.item && this.totalsData && this.targetedImpressions) {
        return Math.round((this.totalsData.impressions / this.targetedImpressions) * 100)
      }
      return 0
    },
    isProposal () {
      return this.item.status === 'Proposal'
    }
  },
  methods: {
    getProgressColor () {
      return lineStatusService.pacingDelta(this.item, this.deliveringPercentage)
    }
  }
}
</script>

<style scoped>
.progress-mark{
  position: absolute;
  border-right: 2px solid blue;
  height: 100%;
  width: 1px;
  display: block;
  top: 0;
  z-index: 1;
}
</style>
