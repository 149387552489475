<template lang="pug">
v-sparkline(
  height='36'
  padding='4'
  auto-draw
  :auto-draw-duration=1000
  :value='data')
</template>

<script>
export default {
  name: 'sparkline',
  props: {
    itemKey: {
      type: String,
      default: () => ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.$emit('getSparklineData', this.itemKey)
  }
}
</script>
