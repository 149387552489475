<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['configObj'],
  data: () => {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: function labelCallback (tooltipItem, data) {
              const { index, datasetIndex } = tooltipItem
              const { datasets, labels } = data
              return ` ${labels[index]}: ${datasets[datasetIndex].data[index] * 100}%`
            }
          }
        },
        scales: {
          xAxes: [{ display: false }],
          yAxes: [{ display: false }]
        },
        legend: {
          position: 'bottom',
          onClick: null,
          labels: {
            fontSize: 11,
            usePointStyle: true
          }
        }
      },
      chartdata: {
        datacollection: null
      }
    }
  },
  mounted () {
    this.chartdata.datacollection = this.configObj
    this.renderChart(this.chartdata.datacollection, this.options)
  }
}

</script>
