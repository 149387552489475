import { getAuth0Instance } from '@/plugins/auth0'
import store from '../store'

var routeIsProtected = record => record.meta.protected
var routeIsPublic = record => record.meta.isPublic

export const authGuard = (to, from, next) => {
  if (to.matched.some(routeIsProtected) || to.matched.some(routeIsPublic)) {
    loadAuthAndRefreshSession()
  } else {
    if (to.name === 'login' && !to.query.t) {
      loadAuthAndRefreshSession()
    }

    store.commit('user/setAppReady', true)
    next()
  }

  function loadAuthAndRefreshSession () {
    if (!(store.getters['auth/provider'] === 'auth0' && getAuth0Instance().loading)) {
      tryRefreshingSession()
    } else {
      getAuth0Instance().$watch('loading', (loading) => {
        if (!loading) {
          tryRefreshingSession()
        }
      })
    }
  }

  function tryRefreshingSession () {
    store.dispatch('auth/fetchSession')
      .then(accessToken => {
        store.dispatch('general/initializeApp')

        store.commit('user/setToken', accessToken)

        var profile = store.getters['user/getProfile']
        if (!profile) {
          store.dispatch('user/getUserProfile')
            .then(() => {
              if (to.name === 'login') {
                next({
                  path: to.query.redirect || '/campaigns/all'
                })
              } else {
                next()
              }
            })
        } else {
          next()
        }
      })
      .catch(() => {
        const proposalWithToken = to.matched.some(routeIsPublic) && to.query.token
        const notProposalButPublic = to.matched.some(routeIsPublic) && !['Proposal', 'Plan'].includes(to.name)
        if (notProposalButPublic || proposalWithToken) {
          if (proposalWithToken) {
            store.dispatch('general/initializeApp')
            store.dispatch('general/getExchanges', {}).then(() => store.commit('user/setAppReady', true))
          }
          next()
        } else {
          switch (store.getters['auth/provider']) {
            case 'auth0':
              next({
                path: '/auth',
                query: {
                  redirect: to.name === 'login' ? '/campaigns/all' : to.fullPath
                }
              })
              break
            case 'aws':
              next({
                path: '/login',
                query: {
                  redirect: to.name === 'login' ? '/campaigns/all' : to.fullPath,
                  t: 1
                }
              })
              break
          }
        }
      })
  }
}
