<template lang="pug">
  v-select(
    :items='items'
    :value='selectedItem'
    menu-props='auto'
    prepend-icon='mdi-clock-outline'
    :error-messages='errorMessages'
    :label='label'
    :disabled="disabled"
    @change='onChange')
</template>
<script>
export default {
  props: ['label', 'defaultValue', 'errorMessages', 'disabled'],
  data: () => ({
    items: ['12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM']
  }),
  computed: {
    selectedItem: function () {
      return this.defaultValue || ''
    }
  },
  methods: {
    onChange (v) {
      this.$emit('timeSelected', v)
    }
  }
}
</script>
