import snackbarStore from '@/stores/snackbar.store'

export default {
  validateAndExportCsv,
  csvExport
}

function validateAndExportCsv (data, filename, errorMessage) {
  if (data) {
    csvExport(data, filename)
  } else {
    snackbarStore.mutations.setSnackbar(snackbarStore.state, {
      type: 'error',
      msg: errorMessage || 'An error occurred while processing your request'
    })
  }
}

function csvExport (data, filename) {
  let csvContent

  if (typeof data === 'string') {
    // data is already "double-quoted", so we can't use specialCharacterEscaping(),
    // but we still need to remove (all occurrences of) special character "#"
    csvContent = data.replace(/#/g, '')
  } else {
    csvContent = [
      Object.keys(data[0]).map(x => specialCharacterEscaping(x)).join(','),
      ...data.map(item => Object.values(item).map(x => specialCharacterEscaping(x)).join(','))
    ]
      .join('\n')
      .replace(/(^\[)|(\]$)/gm, '')
  }

  var blob
  if (navigator.msSaveBlob) { // IE 10+
    blob = new Blob([csvContent], { type: csvContent })
    navigator.msSaveBlob(blob, filename + '.csv')
  } else {
    blob = new Blob([csvContent], { type: csvContent })
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.setAttribute('href', href)
    link.setAttribute('download', filename + '.csv')
    link.click()
  }
}

function specialCharacterEscaping (str) {
  if (str) {
    str = '"' + str.toString().replace(/"/g, '""') + '"'
    return str.toString().replace(/#/g, '')
  } else {
    return str
  }
}
