import Vue from 'vue'
import { datadogLogs } from '@datadog/browser-logs'
import { version } from '../../package'

datadogLogs.init({
  clientToken: 'pube700b57ff1fa654488702c036d6b92d5',
  site: 'datadoghq.com',
  service: 'campsite-dsp-fe',
  env: process.env.VUE_APP_ENV,
  version,
  forwardErrorsToLogs: true,
  sampleRate: 100,
  beforeSend: (log) => {
    // discard canceled Forecast requests
    if (log.http && log.http.status_code === 0 && log.http.url.indexOf('/forecasts') > -1) {
      return false
    }
  }
})

Vue.prototype.$logger = datadogLogs.logger
