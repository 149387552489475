<template lang="pug">
  div
    .overline(
        :class="['group-by-parents-label', labelClass]"
        :style='labelStyle') {{ groupByParentsForDisplay }}

    .body-2(
        :class="['group-by-label', labelClass]"
        :style='labelStyle') {{ groupByValue }}
</template>

<script>
export default {
  name: 'groupByLabel',
  props: ['groupByValue', 'groupByParents', 'labelClass', 'labelStyle'],
  computed: {
    groupByParentsForDisplay () {
      return this.groupByParents?.join(' > ')
    }
  }

}
</script>
