<template lang='pug'>
div
  v-row.my-0(no-gutters)
    v-col.pr-0.py-0
      v-text-field.pt-1.mt-1(
        v-model='search'
        :class='textFieldCssClass'
        placeholder='Search dimensions'
        hide-details
        clearable
      )
        template(v-slot:prepend-inner='')
          v-icon.ml-3() mdi-magnify

      .treeview-container.overflowed
        v-treeview.mt-2(
          :items='items'
          :open='openItems'
          :search='search'
          :value='selection'
          selectable
          hoverable
          selected-color='primary'
          selection-type='leaf'
          return-object
          active-class='treeview-item-selected'
          ref='treeviewRef'
          @input='selectionUpdated'
        )
          template(v-slot:label='{ item, selected }')
            div(v-if='!description(item.id)' :class='getCssClasses(item.name, selected)') {{ item.name }}
            v-menu(v-else right offset-x open-on-hover nudge-top='10' nudge-right='-50' max-width='450' open-delay='100')
              template(v-slot:activator="{ on }")
                div(v-on="on" :class='getCssClasses(item.name, selected)') {{ item.name }}
              v-card
                v-card-text.text--primary.caption(style='line-height:1rem;')
                  div {{ description(item.id) }}

    v-divider.mt-0(vertical)

    v-col.pl-0.pt-4.selected-items(cols='6')
      .px-4.mt-0
        span(v-if='!selection.length') No {{ label }} selected.
        span(v-else='') {{ selection.length }} {{ label }}
          span(v-if='selection.length > 1') s
          |  selected.

      v-list.overflowed
        draggable(v-model='selection' handle='.handle')
          v-list-item.selection-item.elevation-2.rounded-sm(v-for='node in selection' :key='node.id' style="margin:4px 8px;")
            v-list-item-action.my-1.handle
              v-icon() mdi-drag-horizontal-variant
            v-list-item-content.my-1.py-1
              v-list-item-title {{ node.name }}
            v-list-item-action.my-0
              v-btn.remove(icon small @click='removeSelection(node)')
                v-icon(color="grey lighten-1") mdi-close
</template>

<style scoped>

.selected-items >>> .v-list{
  background-color: transparent;
}
>>> .v-treeview-node__root{
  min-height: 34px;
}
>>> .v-list-item{
  height:34px;
  min-height: 34px;
}
.overflowed {
  height:350px;
  overflow-y: auto;
}
.handle {
  cursor: move;
}
.selection-item {
  background-color: white;
}
</style>

<script>
import draggable from 'vuedraggable'
import treeSelectionService from '@/services/treeSelection.service'
import localizationService from '@/services/localization'

export default {
  name: 'treeMultipleSelection',
  components: {
    draggable
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    selected: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: () => 'item',
      required: true
    }
  },
  data () {
    return {
      search: '',
      selection: this.selected || []
    }
  },
  created () {
    if (this.label === 'metric') this.$store.dispatch('general/fetchLocalText', `${this.label}Descriptions`)
    if (this.selected && this.selected.length) this.selection = this.selected
  },
  watch: {
    selected (newVal) {
      if (newVal.length) {
        this.selection = newVal
      }
    }
  },
  computed: {
    openItems () {
      return this.items.map(item => item)
    },
    textFieldCssClass () {
      return [this.label, 'search-treeview'].filter(x => !!x).join('-').toLowerCase()
    }
  },
  methods: {
    removeSelection (selection) {
      const selectionIndex = this.selection.findIndex(s => s.id === selection.id)
      if (selectionIndex > -1) {
        this.selection.splice(selectionIndex, 1)
      }
    },
    selectionUpdated (newSelection) {
      this.selection = newSelection
      this.$emit('selectionUpdated', newSelection)
    },
    getCssClasses (itemName, isSelected) {
      return treeSelectionService.buildTreeItemCssClasses(itemName, isSelected, this.label)
    },
    description (key) {
      return localizationService.getTooltipDescription(key, this.$store.getters['general/getLocalText']('metricDescriptions'))
    }
  }
}
</script>
