<template lang="pug">
div
  .text-overline Weather
  .text-body-2 {{ temperatureRangeString }}
    span(v-if='weatherMoment.skyConditions.length')  while {{ skyConditionsString }}
    span(v-if='weatherMoment.location')  in {{ weatherMoment.location.label }}
</template>

<script>
import config from './config'
import services from './services'

export default {
  name: 'WeatherMomentRead',
  components: {
  },
  props: {
    weatherMoment: {
      type: Object
    },
    temperatureScale: {
      type: String,
      default: 'celsius'
    }
  },
  computed: {
    temperatureScaleSymbol () {
      return this.temperatureScale.charAt(0).toUpperCase()
    },
    minRange () {
      return this.weatherMoment
        ? Math.round(services.convertTemperaturesFromKelvins(this.weatherMoment.temperature, this.temperatureScale)[0])
        : 0
    },
    maxRange () {
      return this.weatherMoment
        ? Math.round(services.convertTemperaturesFromKelvins(this.weatherMoment.temperature, this.temperatureScale)[1])
        : 0
    },
    temperatureRangeString () {
      const [minRangeValue, maxRangeValue] = config.temperatureRanges[this.temperatureScale]

      if (this.minRange === minRangeValue) {
        return this.maxRange === maxRangeValue
          ? 'Any'
          : this.degreesString(this.maxRange) + ' and below'
      }

      return this.maxRange === maxRangeValue
        ? this.degreesString(this.minRange) + ' and over'
        : 'Between ' + this.degreesString(this.minRange) + ' and ' + this.degreesString(this.maxRange)
    },
    skyConditionsString () {
      const skyConditions = [...this.weatherMoment.skyConditions]
        .map(s => {
          const def = config.skyConditions.find(cs => cs.key === s)
          return def
            ? def.label
            : s
        })

      const last = skyConditions.pop()
      return skyConditions.length
        ? skyConditions.join(', ') + ' or ' + last
        : last
    }
  },
  methods: {
    degreesString (degrees = 0) {
      return degrees + '°' + this.temperatureScaleSymbol
    }
  }
}
</script>
