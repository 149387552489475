<template lang="pug">
  div
    v-layout(wrap row)
      v-flex
        v-item-group
          v-container(pa-0 fluid grid-list-md)
            v-layout(wrap row)
              v-flex(v-for="(metricType, index) in labelToKeyMapper" :key='metricType.key' :sm=buttonSizeForSM :xs=6)
                v-item
                  v-card.text-center.align-center.justify-center.py-2.elevation-0(
                    :color="metricIndex === index ? 'primary' : 'white'"
                    :dark="metricIndex === index ? true : false"
                    :ripple="{ class: 'primary--text' }"
                    slot-scope="{ active, toggle }"
                    @click="changeMetric(index)"
                    text-color='primary'
                    style='min-height:64px;'
                  )
                    div.text-overline {{ metricType.label }}
                    v-scroll-y-reverse-transition
                      div.text-h5(v-if="!isLoading") {{ formatting(data[metricType.key], metricType.format) }}
                    //-v-progress-circular.mt-1.mb-2(indeterminate :width=2 size='20' :color="metricIndex === index ? '#fff' : '#212121'" v-if='isLoading')
</template>

<script>
import reportingService from '@/services/reporting.service'
import helpersService from '@/services/helpers.service'
import componentConfigService from '@/services/componentConfig'

export default {
  props: ['isLoading', 'data', 'currentMetric'],
  data () {
    return {}
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    labelToKeyMapper () {
      return reportingService.metricsInfos(null, this.marketVals.currencySymbolString, this.componentConfig)
    },
    metricIndex () {
      return this.labelToKeyMapper.findIndex(x => x.key === this.currentMetric)
    },
    buttonSizeForSM () {
      return this.labelToKeyMapper.length === 4 ? 3 : 4
    }
  },
  methods: {
    formatting (val, outputFormat) {
      return helpersService.dataDisplayFormatting(val, outputFormat, this.marketVals.currencySymbolString)
    },

    changeMetric (indx) {
      this.$emit('metricChanged', this.labelToKeyMapper[indx].key)
    }
  }
}
</script>
