<template lang="pug">
  v-dialog.progress-dialog(v-model="isLoading" width="300" persistent)
    v-card
      v-card-text.pt-3
        .text-subtitle-2.text--primary Processing
        small.text--disabled(v-if='label') {{ label }}
        v-progress-linear.mb-0.mt-2(:indeterminate="!progress" :value="progress")
</template>

<script>
export default {
  props: ['isLoading', 'progress', 'label']
}
</script>
