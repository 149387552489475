<template lang="pug">
  v-dialog(v-model='isDialogOpen' max-width="500" style='height: inherit;')
    template(v-slot:activator="{on}")
      slot(name="customActivator")
    v-card
      v-card-title.text-h5.text-capitalize {{ details.title | capitalize }}
      v-card-text
        | {{ details.msg }}
        template
          slot(name="customOptions")
      v-divider
      v-card-actions
        v-spacer
        v-btn#cancel-action-btn(text @click="closeDialog('canceled')") Cancel
        v-btn#confirmation-action-btn(text color='primary' @click="closeDialog('confirmed')" :disabled='confirmDisabled') {{ details.btnLabel }}
</template>

<script>

export default {
  props: ['details', 'confirmDisabled'],
  data () {
    return {
      isDialogOpen: false
    }
  },
  mounted () {
    this.$root.$on('openConfirmDialog', (title) => {
      if (this.details.title === title) this.isDialogOpen = true
    })
  },
  methods: {
    closeDialog (action) {
      if (action === 'confirmed') {
        this.$emit(action)
      }
      this.isDialogOpen = false
    }
  }
}
</script>
