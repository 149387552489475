<template lang="pug">
  div(:style="$vuetify.breakpoint.xsOnly ? 'padding-top: 44px;' : ''")
    vue-headful(:title="pageTitle")
    v-container.pa-0(fluid grid-list-lg text-left)

      //- Mobile "map" vs "targeting" toggle button
      v-scroll-y-transition
        v-btn#toggle-map-btn.primary--text.mx-auto.mb-4(
          style='position: fixed; bottom:0; left: 0; right: 0; z-index: 2;'
          color='white'
          :width="!showMapOnly? 95 : 150"
          rounded
          @click='toggleView'
          v-if='$vuetify.breakpoint.xsOnly'
          elevation=12
        )
          v-icon.mr-2(color='primary') {{ showMapOnly? 'mdi-menu' : 'mdi-map-outline' }}
          | {{ showMapOnly? 'targeting' : 'map' }}

      v-row.ma-0
        v-col.pa-0.pa-sm-3(
          ref='targetingComponent'
          v-show="!showMapOnly || $vuetify.breakpoint.smAndUp"
          cols=12 md=6 lg=5 xl=5
          style='min-height: calc(100vh - 48px);'
          )

          .text-h5.mt-3.mb-1.tab-title {{ tabTitle }}
          v-expansion-panels(v-model='panelsOpened' multiple)
            v-expansion-panel#instance-settings(v-if='panelsConfig.settings')
              v-expansion-panel-header.text-h6 {{ instanceName }} Settings
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#line-name(v-if='panelsConfig.lineName')
              v-expansion-panel-header.text-h6 Add first line order
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#line-goal(v-if='panelsConfig.lineGoal')
              v-expansion-panel-header.text-h6 Line Goal
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#line-priority(v-if='panelsConfig.linePriority')
              v-expansion-panel-header.text-h6 Line Priority
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#deals(v-if='panelsConfig.deals')
              v-expansion-panel-header.text-h6 Deals
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#targets(v-if='panelsConfig.targets')
              v-expansion-panel-header.text-h6 Targets/Audience
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#moments(v-if='panelsConfig.moments')
              v-expansion-panel-header.text-h6 Moments
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#cost(v-if='panelsConfig.cost')
              v-expansion-panel-header.text-h6 Cost
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#budget-and-schedule(v-if='panelsConfig.budgetAndSchedule')
              v-expansion-panel-header.text-h6 Budget & Schedule
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#budget-and-schedule-with-goals(v-if='panelsConfig.budgetAndScheduleWithGoals')
              v-expansion-panel-header.text-h6 Budget & Schedule
              v-expansion-panel-content
                .panel-content Some content

            v-expansion-panel#creatives(v-if='panelsConfig.creatives')
              v-expansion-panel-header.text-h6 Creatives
              v-expansion-panel-content
                .panel-content Some content

        //- Map & Forecast
        v-col.ma-0.pa-0(cols=12 md=6 lg=7 xl=7 order='first' order-md='last')
          .sticky-column
            div(
              ref='mapComponent'
              v-if="mapExists"
              v-show="showMapOnly || $vuetify.breakpoint.smAndUp"
              style='background-color: cadetblue; height: calc(100vh - 48px);'
              )
</template>

<script>
import componentConfigService from '@/services/componentConfig'
import helpers from '@/services/helpers.service'

export default {
  // TODO: replace "instance" by "resource" ?
  props: ['instanceData', 'panelNameToOpen'],
  created () {
    if (!this.$store.getters['general/isTestEnv']) this.$router.push({ name: 'create-campaign' })

    if (this.panelNameToOpen && this.panelIndex(this.panelNameToOpen) > -1) {
      this.openPanel(this.panelNameToOpen)
    } else {
      this.openDefaultPanels()
    }
  },
  data () {
    return {
      mapExists: true,
      showMapOnly: false,
      panelsOpened: []
    }
  },
  computed: {
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    instance () {
      const routePieces = this.$route.path.split('/')

      if (routePieces[1] === 'temp') {
        routePieces.shift()
      }
      return routePieces[1] === 'create-campaign'
        ? 'campaigns'
        : routePieces[1]
    },
    instanceSingular () {
      return this.instance.substring(0, this.instance.length - 1)
    },
    instanceName () {
      return this.instanceSingular
        .split('-')
        .map(word => helpers.capitalizeString(word))
        .join(' ')
    },
    exitingInstance () {
      return Object.keys(this.$route.params).length
    },
    tabTitle () {
      const article = ['a', 'e', 'i', 'o', 'u'].includes(this.instance[0]) ? 'an' : 'a'

      let verb = 'Create ' + article

      if (this.exitingInstance) {
        const isLine = ['campaigns', 'plans'].includes(this.instance)

        if (this.instanceData) {
          verb = 'Edit' + (isLine ? ' Line of' : '')
        } else {
          verb = 'Add' + (isLine ? ' Line to' : '')
        }
      }

      return `${verb} ${this.instanceName}`
    },
    pageTitle () {
      return this.componentConfig.branding.title(this.tabTitle)
    },
    panelsConfig () {
      return {
        settings: ['campaigns', 'plans', 'auction-packages'].includes(this.instance) && (!this.exitingInstance || this.instance === 'auction-packages'),
        lineName: ['campaigns', 'plans'].includes(this.instance),
        deals: this.componentConfig.campaigns.isDealsShown && ['campaigns', 'plans'].includes(this.instance),
        lineGoal: this.componentConfig.campaigns.isGoalsShown && ['campaigns', 'plans'].includes(this.instance),
        linePriority: this.componentConfig.campaigns.isLinePriorityShown && ['campaigns', 'plans'].includes(this.instance),
        targets: true,
        moments: !['audiences'].includes(this.instance),
        cost: ['auction-packages'].includes(this.instance) && this.$store.getters['user/permissions']('auctionPackage')?.update?.fees,
        budgetAndSchedule: !this.componentConfig.campaigns.isGoalsShown && !['audiences', 'auction-packages'].includes(this.instance),
        budgetAndScheduleWithGoals: this.componentConfig.campaigns.isGoalsShown && !['audiences', 'auction-packages'].includes(this.instance),
        creatives: ['campaigns'].includes(this.instance)
      }
    },
    panelsAvailable () {
      return Object.keys(this.panelsConfig).filter(p => !!this.panelsConfig[p])
    },
    dealIdsFromUrl () {
      return this.$route.query.dealIds
        ? this.$route.query.dealIds.split(',')
        : []
    }
  },
  methods: {
    toggleView () {
      this.mapExists = false
      setTimeout(() => {
        this.mapExists = true
        this.showMapOnly = !this.showMapOnly
      }, 70)
    },
    panelIndex (panelName) {
      return this.panelsAvailable.indexOf(panelName)
    },
    isPanelOpened (panelName) {
      const i = this.panelIndex(panelName)
      return i > -1
        ? this.panelsOpened.includes(i)
        : false
    },
    openPanel (panelName) {
      if (!this.isPanelOpened(panelName)) {
        const i = this.panelIndex(panelName)
        if (i > -1) {
          this.panelsOpened.push(i)
        }
      }
    },
    openDefaultPanels () {
      const openedByDefaultNames = [
        'settings',
        'lineName',
        ...this.componentConfig.campaigns.isDealsShown && this.dealIdsFromUrl.length ? ['deals'] : [],
        ...this.componentConfig.campaigns.isGoalsShown ? ['lineGoal'] : [],
        ...this.componentConfig.campaigns.isLinePriorityShown ? ['linePriority'] : [],
        'targets',
        'moments',
        'cost',
        'budgetAndSchedule',
        'budgetAndScheduleWithGoals'
      ]

      openedByDefaultNames.map(panelName => {
        this.openPanel(panelName)
      })
    }
  }
}
</script>
