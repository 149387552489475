<template lang="pug">
  v-list-item.px-0
    v-list-item-avatar.my-0.mr-3(v-if='$vuetify.breakpoint.smAndUp')
      v-icon mdi-credit-card
    v-list-item-content
      v-list-item-title {{ cardName }}
      v-list-item-subtitle
        .d-flex.align-center
          .mr-1 Expires on {{ card.exp_month + '/' + card.exp_year }}
          .font-weight-bold(v-if='index === 0') - Primary
          a.set-primary-btn(
            v-if='index !== 0 && !disabled'
            @click='setPrimaryPaymentMethod(card)'
            style="cursor: pointer"
            ) - Set as Primary

    //- Edit button
    v-dialog(:fullscreen='$vuetify.breakpoint.xsOnly' max-width=600 v-model='updateDialogOpen')
      template(v-slot:activator='{ on }')
        v-btn(icon small :disabled='disabled' v-on='on')
          v-icon mdi-pencil
      addCardDialog(
        action='update'
        :form-control='updateDialogOpen'
        @closeDialog='updateDialogOpen = false'
        :card='card'
        @closeAndRefresh='closeAndRefresh'
        :paymentMethods='paymentMethods'
        )

    //- Delete button
    v-dialog(max-width=600 v-model='deleteDialogOpen')
      template(v-slot:activator='{on}')
        v-btn(icon small :disabled='disabled' v-on='on')
          v-icon mdi-delete
      v-card
        v-card-title Remove credit card
        v-card-text.text-subtitle-1
          | This will remove the payment method
          span.font-weight-bold  {{ cardName }}
          span .
          div Are you sure you wish to continue?
        v-card-actions.mt-3
          v-spacer
          v-btn(text @click='deleteDialogOpen = false') no, keep it
          v-btn(text color='primary' :loading='deleteBtnLoading' @click='deletePaymentMethod(card)') yes, remove it
</template>

<script>
import addCardDialog from '@/components/addCardDialog.vue'
import billingService from '@/services/billing.service'

export default {
  components: {
    addCardDialog
  },
  props: ['card', 'index', 'disabled'],
  data () {
    return {
      updateDialogOpen: false,
      deleteDialogOpen: false,
      deleteBtnLoading: false,
      setPrimaryLoading: false
    }
  },
  computed: {
    cardName () {
      return this.card.brand + ' *' + this.card.last4
    },
    organizationId () {
      const organization = this.$store.getters['user/getOrganization']
      return organization ? organization.id : 0
    },
    paymentMethods () {
      return this.$store.getters['billing/paymentMethods']
    }
  },
  methods: {
    closeAndRefresh (action) {
      if (action) this[action + 'DialogOpen'] = false
      this.$emit('closeAndRefresh')
    },

    setPrimaryPaymentMethod (card) {
      this.$store.commit('billing/setFetchingPaymentMethods', true)
      billingService.setPrimaryCard(card)
        .then(paymentMethods => {
          this.setPrimaryLoading = false
          this.closeAndRefresh()
          const msg = 'Credit card successfully set as primary'
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: `${msg}`
          })
        })
        .catch(err => {
          this.setPrimaryLoading = false
          err = err.response.data.errors[0]
          var message
          if (typeof err === 'object') {
            message = err.message
          }
          if (typeof err === 'string') {
            message = err
          }
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${message}`
          })
        })
    },

    deletePaymentMethod (card) {
      this.deleteBtnLoading = true

      billingService.removeCard(card)
        .then(paymentMethods => {
          if (!paymentMethods.length) {
            return billingService.removePaymentMethod('stripe', this.organizationId)
              .then(resp => {
                this.$store.dispatch('billing/getStripeCards')

                this.onSuccessSnackbar()
              })
          } else { this.onSuccessSnackbar() }
        })
        .catch(err => {
          this.deleteBtnLoading = false
          err = err.response.data.errors[0]
          var message
          if (typeof err === 'object') {
            message = err.message
          }
          if (typeof err === 'string') {
            message = err
          }
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${message}`
          })
        })
    },

    onSuccessSnackbar (x) {
      this.deleteBtnLoading = false
      this.closeAndRefresh('delete')
      const msg = 'Credit card successfully deleted'
      this.$store.commit('snackbar/setSnackbar', {
        type: 'success',
        msg: `${msg}`
      })
    }
  }
}
</script>
