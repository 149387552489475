export default {
  getCampaignPanelInfo
}

const panels = {
  campaignForm: {
    validationEmit: 'validateCampaignForm',
    panelName: 'campaign',
    panelSelector: '#campaign-panel'
  },
  lineOrderName: {
    validationEmit: 'validateLineOrderName',
    panelName: 'lineOrderName',
    panelSelector: '#line-order-name-panel',
    fields: {
      lineOrderName: '#line-order-name-field'
    }
  },
  budgetAndSchedule: {
    validationEmit: 'validateBudgetAndSchedule',
    panelName: 'budget',
    panelSelector: '#budget-panel',
    fields: {
      budget: '#budget-field',
      maxBid: '#max-bid-field',
      cpm: '#cpm-field'
    }
  },
  lineGoal: {
    validationEmit: 'validateLineGoal',
    panelName: 'lineGoal',
    panelSelector: '#line-goal-panel',
    fields: {
      targetNumberOfImpressions: '#targetnumberofimpressions-field'
    }
  },
  linePriority: {
    validationEmit: 'validateLinePriority',
    panelName: 'linePriority',
    panelSelector: '#line-priority-panel',
    fields: {
      linePriority: '#linepriority-field'
    }
  }
}

function getCampaignPanelInfo (panel) {
  return panels[panel]
}
