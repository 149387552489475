import axios from 'axios'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  getHistory,
  getNotifications,
  setSeenNotifications
}

function getHistory (organizationId = 0, limit = 100, offset = 0, type = '', search = '') {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/notifications/events?skip=' + offset + '&take=' + limit

  const filters = []
  if (type.length) {
    filters.push('type=' + type)
  }
  if (organizationId > 0) {
    filters.push('organizationId=' + organizationId)
  }
  if (search.length) {
    filters.push('search=' + encodeURIComponent(search))
  }
  if (filters.length) { endpoint += '&' + filters.join('&') }

  return axios.get(endpoint).then(response => response.data)
}

function getNotifications (num = 10) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/notifications/' + num
  return axios.get(endpoint).then(response => response.data)
}

function setSeenNotifications (notifications) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/notifications'
  return axios.patch(endpoint, notifications).then(response => response.data)
}
