export default defaultCurrencyValues

function defaultCurrencyValues (currency = null) {
  const options = {
    CAD: {
      currency: 'CAD',
      currencySymbolString: '$',
      displayOrder: 2
    },
    USD: {
      currency: 'USD',
      currencySymbolString: '$',
      displayOrder: 1
    },
    AUD: {
      currency: 'AUD',
      currencySymbolString: '$',
      displayOrder: 3
    },
    EUR: {
      currency: 'EUR',
      currencySymbolString: '€',
      displayOrder: 4
    },
    GBP: {
      currency: 'GBP',
      currencySymbolString: '£',
      displayOrder: 5
    }
  }
  return !currency ? options : options[currency]
}
