<template lang="pug">
.troubleshooting-tool
  v-toolbar(dense flat)
    v-toolbar-title.toolbar-title Troubleshoot: {{ line.name }}
  v-tabs(v-model='tabs' background-color='transparent')
    v-tab.troubleshooting-tab(key='0')
      | Delivery Tool
    v-tab.troubleshooting-tab(:key='1' disabled)
      | Change History
    v-tab.troubleshooting-tab(:key='2' disabled v-if='!isProposal')
      | Creatives Status

  v-divider
  v-tabs-items(v-model='tabs')
    v-tab-item(key='0')
      v-card(flat)
        v-card-text.text--primary.px-8

          .d-flex.align-center
            v-select.mr-4(label='Inventory' v-model='inventorySource' :items="inventorySources" style='max-width:350px;' attach)

            v-menu.offset-y(attach)
              template(v-slot:activator='{ on, attrs }')
                v-btn.mb-1.periods-btn(text color='primary' v-bind='attrs' v-on='on') {{ period.text }}
                  v-icon mdi-menu-down
              v-list(style='background-color: white;')
                v-list-item-group(color='primary' v-model='periodIndex' mandatory)
                  v-list-item.period-list-item(v-for='(p, index) in periods' :key='index')
                    v-list-item-title.period-list-item-title {{ p.text }}

          v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
            v-col.metric-total(v-for='metric in metricsTotals' :key='metric.key' cols='12' sm='6' md='4' lg='2')
              .text-overline.metric-name {{ metric.shortName }}
              .text-h6.font-weight-regular.metric-total-value(style='min-height: 32px;')
                .font-weight-bold(v-if='showNaTotal(metric.key)') N/A
                v-scroll-y-reverse-transition(v-else)
                  .text-truncate(v-if='!lineStatsLoading && !loadingFaces') {{ metric.total | roundup }}

      v-divider
      .py-4.px-6(style='background-color: #f8f9fa;')
        v-expansion-panels(v-model='inventoryPanelOpened' dense outlined)
          v-expansion-panel
            v-expansion-panel-header.py-0
              .d-flex.align-center
                //- .mr-4.d-flex.align-center.text-h6.font-weight-regular
                  v-icon.mr-1 mdi-map-marker
                  | 94
                .mr-4.d-flex.align-center.text-h6.font-weight-regular(style="min-width: 75px; display: inline-block;")
                  v-icon.mr-1 mdi-television
                  v-scroll-y-reverse-transition
                    span.faces-total(v-if='!lineStatsLoading && !loadingFaces') {{ facesTotal | numberWithCommas }}
                | Targeted Inventory Details

            v-expansion-panel-content
              v-container
                v-row.my-0(align='center' justify="space-between")
                  v-col.py-0(cols=12)
                    v-text-field#search-faces(
                      prepend-inner-icon='mdi-magnify'
                      hide-details
                      single-line
                      dense
                      rounded
                      filled
                      clearable
                      label='Find Screens'
                      v-model='searchStr'
                      )
                      template.my-0(v-slot:append-outer='')
                        .d-flex.align-center
                          v-tooltip(top dark color='secondary' max-width='300')
                            template(v-slot:activator='{ on }')
                              v-btn.download-inventory(v-on='on' icon small :disabled='csvExportDisabled' @click='exportScreenCsv')
                                v-icon mdi-download
                            div.text-caption Export Screens in CSV
                          v-btn.mx-2.prev-page(icon small :disabled='previousPageDisabled' @click='previousPage')
                            v-icon(large) mdi-chevron-left
                          v-btn.mx-2.next-page(icon small :disabled='nextPageDisabled' @click='nextPage')
                            v-icon(large) mdi-chevron-right

              v-data-table.inventory-table(
                dense
                hide-default-footer
                :headers='facesHeaders'
                :loading='loadingFaces'
                :items='faces'
                item-key='id'
                must-sort
                sort-by='numberOfRequests'
                :sort-desc='false'
                :page='tablePagination.pageNumber'
                :items-per-page='tablePagination.pageSize'
                @update:sort-by='onSortUpdate'
                @update:sort-desc='onSortUpdate'
                )
                template(v-slot:item.code='{ item }')
                  span.face-id {{ item.code }}
                template(v-slot:item.publisherName='{ item }')
                  span.publisher-name {{ item.publisherName }}
                template(v-slot:item.name='{ item }')
                  span.face-name {{ item.name }}
                //-template(v-slot:item.ssp='{ item }')
                  span.ssp-name {{ item.ssp }}
                template(v-slot:item.numberOfRequests='{ item }')
                  .text-right
                    span.face-requests {{ item.numberOfRequests | numberWithCommas }}

        div(v-for='(metric, index) in troubleshootingMetricDefinitions' :key='metric.key')
          troubleshoot-metric-breakdown(
            v-if='!metric.hideBreakdown'
            :isAuctionPackage='isAuctionPackage'
            :metric='metric'
            :show-connector='index < troubleshootingMetricDefinitions.filter(m => !m.hideBreakdown).length - 1'
            )

    v-tab-item(key='1')
      v-card(flat)
        v-card-text.text--primary.px-8
          | Change History
</template>

<style lang="stylus" scoped>
>>> .v-expansion-panel-content__wrap
  padding: 0px
</style>

<script>
import troubleshootMetricBreakdown from '@/components/troubleshootMetricBreakdown.vue'
import troubleshootingApi from '@/services/troubleshooting.api'

import { debounce } from 'lodash'

export default {
  components: {
    troubleshootMetricBreakdown
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    campaignStatus: {
      type: String,
      default: ''
    },
    isAuctionPackage: {
      type: Boolean
    }
  },
  filters: {
    roundup (value) {
      if (!parseFloat(value) && value !== 0) return '-'

      if (value >= 100000) {
        return (value / 1000000).toFixed(1) + 'M'
      } else if (value >= 1000) {
        return (value / 1000).toFixed(1) + 'K'
      } else {
        return value
      }
    },
    percent (value) {
      if (!parseFloat(value) && value !== 0) return ''
      return (value * 100).toFixed(1) + '%'
    }
  },
  data () {
    return {
      tabs: 0,
      inventorySource: 'Targeted Inventory',
      // inventorySources: ["Targeted Inventory", 'Deal 1', 'Deal 2', 'Deal 3'],
      inventorySources: ['Targeted Inventory'],
      periodIndex: 2,
      periods: [
        { text: 'Last Hour', value: 1 },
        { text: 'Last 4 Hours', value: 4 },
        { text: 'Last 24 Hours', value: 24 },
        { text: 'Last 7 Days', value: 168 }
      ],

      lineStatsApiData: {},
      lineStatsLoading: false,

      inventoryPanelOpened: false,
      loadingFaces: true,
      faces: [],
      facesHeaders: [
        { text: 'Face ID', value: 'code' },
        { text: 'Publisher', value: 'publisherName' },
        { text: 'Name', value: 'name' },
        // { text: 'SSP', value: 'ssp' },
        {
          text: 'Requests',
          value: 'numberOfRequests',
          align: 'right',
          width: 115,
          sort: (a, b) => parseInt(b) - parseInt(a)
        }
      ],
      facesTotal: null,
      tablePagination: {
        pageNumber: 1,
        pageSize: 20
      },
      apiPagination: {
        pageNumber: 1,
        pageSize: 3000
      },
      apiHasPreviousPage: false,
      apiHasNextPage: false,

      error: null,
      searchStr: null

    }
  },
  computed: {
    csvExportDisabled () {
      return this.faces.length === 0
    },
    tableHasPreviousPage () {
      return this.tablePagination.pageNumber > 1
    },
    tableHasNextPage () {
      return this.faces.length > (this.tablePagination.pageNumber * this.tablePagination.pageSize)
    },
    previousPageDisabled () {
      return this.loadingFaces || !(this.tableHasPreviousPage || this.apiHasPreviousPage)
    },
    nextPageDisabled () {
      return this.loadingFaces || !(this.tableHasNextPage || this.apiHasNextPage)
    },
    troubleshootingMetricDefinitions () {
      return [
        {
          key: 'totalRequests',
          name: 'Total Requests',
          shortName: 'Total Requests',
          description: `Total number of requests sent by targeted screens during the ${this.period.text.toLowerCase()}.`,
          format: 'M',
          total: this.getTotalRequests,
          breakdown: {
            totals: [
              {
                key: 'current',
                name: 'Filtered Requests',
                value: this.getTotalFilteredRequests,
                percent: this.getTotalRequests > 0
                  ? this.getTotalFilteredRequests / this.getTotalRequests
                  : 0
              },
              {
                key: 'next',
                name: 'Eligible Requests',
                value: this.getMetricByKeys('impRequests', 'eligibleRequests'),
                percent: null
              }
            ],
            data: [
              // {
              //   key: 'invalidRequests',
              //   name: 'Invalid Requests',
              //   value: null,
              //   percent: null
              // },
              // {
              //   key: 'filteredTargets',
              //   name: 'Filtered by Targets',
              //   value: null,
              //   percent: null
              // },
              {
                key: 'filteredByDeals',
                name: 'Filtered by deal mismatch',
                value: this.getMetricByKeys('impRequests', 'filteredByDeals'),
                percent: this.getTotalRequests > 0
                  ? this.getMetricByKeys('impRequests', 'filteredByDeals') / this.getTotalRequests
                  : 0
              },
              {
                key: 'filteredDayparting',
                name: 'Filtered by Dayparting',
                value: this.getMetricByKeys('impRequests', 'filteredByTimeParting'),
                percent: this.getTotalRequests > 0
                  ? this.getMetricByKeys('impRequests', 'filteredByTimeParting') / this.getTotalRequests
                  : 0
              }
              // {
              //   key: 'filteredBrandSafety',
              //   name: 'Filtered by Brand Safety',
              //   value: 500000,
              //   percent: 0.1650
              // },
              // {
              //   key: 'filteredMoments',
              //   name: 'Filtered by Moments',
              //   value: null,
              //   percent: null
              // },
              // {
              //   key: 'filteredCreatives',
              //   name: 'Creatives not eligible',
              //   value: this.getMetricByKeys('impRequests', 'filteredByCreatives'),
              //   percent: this.getTotalRequests > 0
              //     ? this.getMetricByKeys('impRequests', 'filteredByCreatives') / this.getTotalRequests
              //     : 0
              // }
            ]
          }
        },
        {
          key: 'eligibleImpressions',
          name: 'Eligible Requests',
          shortName: 'Eligible Requests',
          description: `Total number of requests that met all criterias and that the line could have potentially bids on during the ${this.period.text.toLowerCase()}.`,
          format: 'M',
          total: this.getMetricByKeys('impRequests', 'eligibleRequests'),
          breakdown: {
            totals: [
              {
                key: 'current',
                name: 'Filtered eligible requests',
                value: this.getTotalFilteredEligibleRequests,
                percent: this.getMetricByKeys('impRequests', 'eligibleRequests') > 0
                  ? this.getTotalFilteredEligibleRequests / this.getMetricByKeys('impRequests', 'eligibleRequests')
                  : 0
              },
              {
                key: 'next',
                name: 'Bids Submitted',
                value: this.getMetricByKeys('bids', 'submittedBids'),
                percent: this.getMetricByKeys('impRequests', 'eligibleRequests') > 0
                  ? this.getMetricByKeys('bids', 'submittedBids') / this.getMetricByKeys('impRequests', 'eligibleRequests')
                  : 0
              }
            ],
            data: [
              // {
              //   key: 'pacing',
              //   name: 'Pacing',
              //   value: null,
              //   percent: null
              // }
            ]
          },
          hideBreakdown: this.isProposal
        },
        {
          key: 'bidSubmitted',
          name: 'Bids Submitted',
          shortName: 'Bids Submitted',
          description: `Total number of bids submitted on requests sent by targeted screens during the ${this.period.text.toLowerCase()}.`,
          format: 'M',
          total: this.getMetricByKeys('bids', 'submittedBids'),
          hideTotal: this.isProposal,
          breakdown: {
            totals: [
              {
                key: 'current',
                name: 'Filtered Bids',
                value: this.getTotalFilteredLostRequests,
                percent: this.getMetricByKeys('bids', 'submittedBids') > 0
                  ? this.getTotalFilteredLostRequests / this.getMetricByKeys('bids', 'submittedBids')
                  : 0
              },
              {
                key: 'next',
                name: 'Requests Won',
                value: this.getMetricByKeys('bids', 'bidsWon'),
                percent: this.getMetricByKeys('bids', 'submittedBids') > 0
                  ? this.getMetricByKeys('bids', 'bidsWon') / this.getMetricByKeys('bids', 'submittedBids')
                  : 0
              }
            ],
            data: [
              // {
              //   key: 'belowMinBid',
              //   name: 'Below Minimum Bid',
              //   value: null,
              //   percent: null
              // },
              // {
              //   key: 'auctionLostInternal',
              //   name: 'Auction lost (internal)',
              //   value: null,
              //   percent: null
              // },
              // {
              //   key: 'auctionLostExternal',
              //   name: 'Auction lost (external)',
              //   value: null,
              //   percent: null
              // }
            ]
          },
          hideBreakdown: this.isProposal
        },
        {
          key: 'wonImpressions',
          name: 'Requests Won',
          shortName: 'Requests Won',
          description: `The total number of win notices received by the SSP during the ${this.period.text.toLowerCase()}.`,
          format: 'M',
          total: this.getMetricByKeys('bids', 'bidsWon'),
          hideTotal: this.isProposal,
          breakdown: {
            totals: [
              {
                key: 'current',
                name: 'Filtered by lack of POP',
                value: this.getTotalFilteredLackOfPopRequests,
                percent: this.getMetricByKeys('bids', 'bidsWon') > 0
                  ? this.getTotalFilteredLackOfPopRequests / this.getMetricByKeys('bids', 'bidsWon')
                  : 0
              },
              {
                key: 'next',
                name: 'Requests Won w/ POP',
                value: this.getMetricByKeys('bids', 'bidsPop'),
                percent: this.getMetricByKeys('bids', 'bidsWon') > 0
                  ? this.getMetricByKeys('bids', 'bidsPop') / this.getMetricByKeys('bids', 'bidsWon')
                  : 0
              }
            ],
            data: [
              // {
              //   key: 'popExipred',
              //   name: 'POP received after time limit',
              //   value: null,
              //   percent: null
              // },
              // {
              //   key: 'popPending',
              //   name: 'POP Pending',
              //   value: null,
              //   percent: null
              // }
            ]
          },
          hideBreakdown: this.isProposal
        },
        {
          key: 'popImpressions',
          name: 'POP Requests',
          shortName: 'POP Requests',
          description: 'The number of requests won where we receive a digital confirmation of the ad play called "Proof of Play" (POP) sent by the screen player. This is the final metric surfaced in reports as ads served.',
          format: 'M',
          total: this.getMetricByKeys('bids', 'bidsPop'),
          hideTotal: this.isProposal,
          breakdown: null,
          hideBreakdown: this.isProposal
        },
        {
          key: 'winRate',
          name: 'Win Rate',
          shortName: 'Win Rate',
          format: '%',
          total: this.getMetricByKeys('bids', 'submittedBids') > 0
            ? (this.getMetricByKeys('bids', 'bidsWon') / this.getMetricByKeys('bids', 'submittedBids') * 100).toFixed(1) + '%'
            : 0 + '%',
          hideTotal: this.isProposal,
          hideBreakdown: true
        }
      ]
    },
    metricsTotals () {
      return this.troubleshootingMetricDefinitions.filter(metric => !metric.hideTotal)
    },
    isProposal () {
      return this.campaignStatus === 'Proposal'
    },
    period () {
      return this.periods[this.periodIndex]
    },
    getTotalRequests () {
      var result = 0
      if (!this.faces.length) return '-'
      this.faces.forEach(f => {
        result += f?.numberOfRequests
      })
      return result
    },
    getTotalFilteredEligibleRequests () {
      return Math.max(this.getMetricByKeys('impRequests', 'eligibleRequests') - this.getMetricByKeys('bids', 'submittedBids'), 0)
    },
    getTotalFilteredRequests () {
      return Math.max(this.getTotalRequests - this.getMetricByKeys('impRequests', 'eligibleRequests'), 0)
    },
    getTotalFilteredLackOfPopRequests () {
      return Math.max(this.getMetricByKeys('bids', 'bidsWon') - this.getMetricByKeys('bids', 'bidsPop'), 0)
    },
    getTotalFilteredLostRequests () {
      return Math.max(this.getMetricByKeys('bids', 'submittedBids') - this.getMetricByKeys('bids', 'bidsWon'), 0)
    },
    getMetricByKeys: () => function (metricKey, metricBreakdownKey) {
      return this.lineStatsLoading || Object.keys(this.lineStatsApiData).length === 0
        ? '-'
        : this.lineStatsApiData[metricKey][metricBreakdownKey]
    },
    lineId () {
      return this.isAuctionPackage ? this.line.lineId : this.line.id
    }
  },
  created () {
    this.getLineStats()
    this.getLineFaces()
  },
  watch: {
    line (newVal, oldVal) {
      if (oldVal && oldVal.id !== newVal.id) {
        this.inventoryPanelOpened = false

        this.faces = []
        this.facesTotal = 0
        this.tablePagination.pageNumber = 1
        this.apiPagination.pageNumber = 1
        this.apiHasPreviousPage = false
        this.apiHasNextPage = false

        this.getLineStats()
        this.getLineFaces()
      }
    },
    error (e) {
      console.log(e)
    },
    searchStr: debounce(function () {
      this.tablePagination.pageNumber = 1
      this.apiPagination.pageNumber = 1
      this.getLineFaces()
    }, 450),
    periodIndex (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getLineStats()
        this.getLineFaces()
      }
    }
  },
  methods: {
    getLineFaces () {
      this.loadingFaces = true

      return troubleshootingApi.getLineFaces(this.lineId, this.apiPagination, this.searchStr, this.period.value)
        .then(resp => {
          this.faces = resp.data
          this.facesTotal = resp.totalRecords
          this.apiHasPreviousPage = !!resp.previousPage
          this.apiHasNextPage = !!resp.nextPage
        })
        .catch(error => {
          this.faces = []
          this.facesTotal = 'N/A'
          this.error = error
        })
        .finally(() => {
          this.loadingFaces = false
        })
    },
    exportScreenCsv () {
      troubleshootingApi.getLineFacesCsv(this.lineId, this.searchStr, this.period.value)
    },
    previousPage () {
      if (this.tableHasPreviousPage) {
        this.tablePagination.pageNumber--
      } else if (this.apiHasPreviousPage) {
        this.tablePagination.pageNumber = Math.ceil(this.apiPagination.pageSize / this.tablePagination.pageSize)
        this.apiPagination.pageNumber--
        this.getLineFaces()
      }
    },
    nextPage () {
      if (this.tableHasNextPage) {
        this.tablePagination.pageNumber++
      } else if (this.apiHasNextPage) {
        this.tablePagination.pageNumber = 1
        this.apiPagination.pageNumber++
        this.getLineFaces()
      }
    },
    getLineStats () {
      this.lineStatsLoading = true
      this.lineStatsApiData = {}

      troubleshootingApi.getLineStats(this.lineId, this.period.value)
        .then(data => {
          this.lineStatsApiData = data
        })
        .catch(error => {
          if (error) this.error = error
          this.lineStatsApiData = {}
          var message = 'Stats for troubleshooting tool could not be loaded'

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${message}`
          })
        })
        .finally(() => {
          this.lineStatsLoading = false
        })
    },
    formatRtbMetric (value, format) {
      if (!value || !parseFloat(value)) value = 0
      if (format !== '%') format = 'M'

      const divider = format === '%'
        ? 0.01
        : 1000000

      return (value / divider).toFixed(1) + format
    },
    onSortUpdate () {
      this.tablePagination.pageNumber = 1
    },
    showNaTotal (key) {
      return this.isAuctionPackage && ['wonImpressions', 'winRate'].includes(key)
    }
  }
}
</script>
