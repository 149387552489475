const skyConditions = [
  { key: 'Sunny', label: 'Sunny', icon: 'mdi-weather-sunny' },
  { key: 'PartlySunny', label: 'Partly Sunny', icon: 'mdi-weather-partly-cloudy' },
  { key: 'Cloudy', label: 'Cloudy', icon: 'mdi-weather-cloudy' },
  { key: 'Rainy', label: 'Rainy', icon: 'mdi-weather-pouring' },
  { key: 'Lightning', label: 'Lightning', icon: 'mdi-weather-lightning' },
  { key: 'Snowy', label: 'Snowy', icon: 'mdi-weather-snowy-heavy' }
]

const temperatureRanges = {
  fahrenheit: [-40, 120],
  celsius: [-40, 50]
}

export default {
  skyConditions,
  temperatureRanges
}
