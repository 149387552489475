// TODO: move dependencies from "campsite" to "moments" project
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import accounting from 'accounting-js'

const MOMENTS_API_DOMAIN = process.env.VUE_APP_MOMENTS_API_URL || ''
const MOMENTS_API_DOMAIN_FINANCE = MOMENTS_API_DOMAIN.replace('https://', 'https://finance.')

const MOMENTS_API_URL = MOMENTS_API_DOMAIN + '/moments'

const TEMP_CITY_SEARCH_URL = process.env.VUE_APP_CAMPSITE_API_URL

const financeTypes = ['stock', 'currency', 'crypto', 'commodity', 'index']

const financePopularItems = {
  commodity: [
    { symbol: 'BZUSD', name: 'Brent Crude Oil Last Day Finance', exchange: 'NY Mercantile', currency: 'USD' },
    { symbol: 'KCUSX', name: 'Coffee', exchange: 'NYBOT', currency: 'USX' },
    { symbol: 'NGUSD', name: 'Natural Gas', exchange: 'NY Mercantile', currency: 'USD' },
    { symbol: 'GCUSD', name: 'Gold', exchange: 'COMMODITY', currency: 'USD' },
    { symbol: 'KWUSX', name: 'KC HRW Wheat Futures', exchange: 'CBOT', currency: 'USX' }
  ],
  crypto: [
    { symbol: 'BTCUSD', name: 'Bitcoin USD', exchange: 'CCC', currency: 'USD' },
    { symbol: 'ADAUSD', name: 'Cardano USD', exchange: 'CCC', currency: 'USD' },
    { symbol: 'ETHUSD', name: 'Ethereum USD', exchange: 'CCC', currency: 'USD' },
    { symbol: 'BNBUSD', name: 'Bincance Coin', exchange: 'CCC', currency: 'USD' },
    { symbol: 'DOGEUSD', name: 'Dogecoin USD', exchange: 'CCC', currency: 'USD' }
  ],
  currency: [
    { symbol: 'CAD=X', name: 'USD/CAD', exchange: 'CCC', currency: 'USD' },
    { symbol: 'GBP=X', name: 'USD/GBP', exchange: 'CCC', currency: 'USD' },
    { symbol: 'EUR=X', name: 'USD/EUR', exchange: 'CCC', currency: 'USD' },
    { symbol: 'JPY=X', name: 'USD/JPY', exchange: 'CCC', currency: 'USD' },
    { symbol: 'MXN=X', name: 'USD/MXN', exchange: 'CCC', currency: 'USD' }
  ],
  index: [
    { symbol: '^DJI', name: 'Dow Jones Industrial Average', exchange: 'DJI', currency: 'USD' },
    { symbol: '^GSPC', name: 'S&P 500', exchange: 'SNP', currency: 'USD' },
    { symbol: '^IXIC', name: 'NASDAQ Composite', exchange: 'Nasdaq GIDS', currency: 'USD' },
    { symbol: '^RUT', name: 'Russell 2000', exchange: 'Chicago Options', currency: 'USD' },
    { symbol: '^GSPTSE', name: 'S&P/TSX Composite index', exchange: 'Toronto', currency: 'USD' }
  ],
  stock: [
    { symbol: 'AAPL', name: 'Apple Inc.', exchange: 'NYSE', currency: 'USD' },
    { symbol: 'FB', name: 'Facebook Inc.', exchange: 'NYSE', currency: 'USD' },
    { symbol: 'GOOG', name: 'Alphabet Inc', exchange: 'NYSE', currency: 'USD' },
    { symbol: 'MSFT', name: 'Microsoft Corporation', exchange: 'NYSE', currency: 'USD' },
    { symbol: 'NVDA', name: 'NVIDIA Corporation', exchange: 'NYSE', currency: 'USD' }
  ]
}

export default {
  generateGuid,
  createMoment,
  getMoment,
  updateMoment,
  deleteMoment,
  convertTemperaturesToKelvins,
  convertTemperaturesFromKelvins,
  searchLocation,
  financeTypes,
  searchFinanceItem,
  getFinanceItem,
  financeTypePlural,
  axiosWrapper,
  capitalize,
  getPopular,
  bundleFinanceItemObject,
  getListOfConditionTypes,
  getListOfConditionOperators,
  getListOfLookupPeriods,
  showDurationLabel,
  getFinanceMomentConditionValueInputWidth,
  buildSummaryForFinanceItemMoment,
  cloneMoment
}

function generateGuid () {
  return uuidv4()
}

function createMoment (data, authToken) {
  const url = MOMENTS_API_URL
  return axiosWrapper({
    url,
    authToken,
    data,
    method: 'post'
  })
}

function getMoment (guid, authToken) {
  const url = MOMENTS_API_URL + '/' + guid
  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(resp => {
    return resp.data
  })
}

function updateMoment (data, authToken) {
  const url = MOMENTS_API_URL + '/' + data.id
  return axiosWrapper({
    url,
    authToken,
    data,
    method: 'put'
  })
}

function deleteMoment (guid, authToken) {
  const url = MOMENTS_API_URL + '/' + guid
  return axiosWrapper({
    url,
    authToken,
    method: 'delete'
  })
}

function cloneMoment (guid, authToken) {
  const url = MOMENTS_API_URL + '/' + guid + '/clone'
  return axiosWrapper({
    url,
    authToken,
    method: 'get'
  }).then(resp => {
    return resp.data
  })
}

function axiosWrapper (options) {
  const { url, data, method, authToken } = options
  const config = { url, method, headers: { Authorization: `bearer ${authToken}` } }
  if (['post', 'put', 'patch'].includes(method)) config.data = data
  return axios.request(config)
}

/**
 * Search
 */
async function searchLocation (q, authToken) {
  try {
    return await citySearchApi(q, 5, authToken)
  } catch {
    await timeout(2)
    return citySearchApi(q, 5, authToken)
  }
}

function citySearchApi (q, take = 5, authToken) {
  const qstr = encodeURIComponent(q)
  return axiosWrapper({
    url: `${TEMP_CITY_SEARCH_URL}/locations/cities?search=${qstr}&take=${take}`,
    method: 'get',
    authToken
  }).then(res => res.data)
}

function searchFinanceItem (financeType, q, take = 5, authToken) {
  const url = MOMENTS_API_DOMAIN_FINANCE + '/' + financeTypePlural(financeType) + '?search=' + encodeURIComponent(q) + '&take=' + take
  return axiosWrapper({
    url,
    method: 'get',
    authToken
  }).then(res => res.data)
}

function getPopular (type) {
  return financePopularItems[type] || []
}

function getFinanceItem (financeType, symbol, period, authToken) {
  const url = MOMENTS_API_DOMAIN_FINANCE + '/' + financeTypePlural(financeType) + '/' + encodeURIComponent(symbol) + '?lookupPeriod=' + encodeURIComponent(period)
  return axiosWrapper({
    url,
    method: 'get',
    authToken
  }).then(res => res.data)
}

function financeTypePlural (type) {
  const plurals = {
    commodity: 'commodities',
    crypto: 'cryptos',
    currency: 'currencies',
    index: 'indexes',
    stock: 'stocks'
  }
  return plurals[type]
}

function timeout (secs) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, secs * 1000)
  })
}

/**
 * @param {string[]} range
 * @param {string} scale
 */
function convertTemperaturesToKelvins (range, scale = 'celsius') {
  if (!range || range.length !== 2) range = [0, 0]
  const isF = scale === 'fahrenheit'
  range = range.map(v => {
    const value = parseFloat(v) || 0
    return isF ? fahrenheitToCelsius(value) : value
  })

  const adjustedMin = range[0] ? range[0] - 0.5 : 0
  const adjustedMax = range[1] ? range[1] + 0.49 : 0

  return {
    min: adjustedMin + 273.15,
    max: adjustedMax + 273.15
  }
}

/**
 * @param {{min: number, max: number}} range in Kelvin
 * @param {string} scale
 * @return {string[]} [min, max]
 */
function convertTemperaturesFromKelvins (range, scale = 'celsius') {
  range = (!range || !range.min || !range.max) ? [0, 0] : [range.min, range.max]
  const isF = scale === 'fahrenheit'

  let rangeInCelsius = range.map(v => {
    const d = parseFloat(v) || 273.15
    return d - 273.15
  })

  if (rangeInCelsius[0]) rangeInCelsius[0] += 0.5
  if (rangeInCelsius[1]) rangeInCelsius[1] -= 0.49

  rangeInCelsius = rangeInCelsius
    .map(v => isF ? celsiusToFahrenheit(v) : v)

  return rangeInCelsius
}

function fahrenheitToCelsius (f) {
  return (f - 32) * 5 / 9
}

function celsiusToFahrenheit (c) {
  return (c * 9 / 5) + 32
}

/*
 * Helpers
 */
function capitalize (s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

function bundleFinanceItemObject (item, condition) {
  if (!item || !condition) return null
  const { name, symbol, exchange, currency } = item
  return {
    name,
    symbol,
    exchange,
    currency,
    financeEvaluationCondition: {
      conditionType: condition.metricType,
      comparisonType: condition.operatorValue,
      targetValue: condition.metricValue,
      lookupPeriod: condition.lookupPeriod
    }
  }
}

function getListOfConditionTypes () {
  return [
    { key: 'Price', label: 'Price' },
    { key: 'ChangePercentage', label: 'Change %' },
    { key: 'ChangePrice', label: 'Change Price' }
  ]
}

function getListOfConditionOperators () {
  return ['Over', 'Under']
}

function getListOfLookupPeriods () {
  return [
    { key: 'OneDay', label: '1D', text: '1 Day' },
    { key: 'FiveDays', label: '5D', text: '5 Days' },
    { key: 'OneMonth', label: '1M', text: '1 Month' },
    { key: 'ThreeMonths', label: '3M', text: '3 Months' },
    { key: 'SixMonths', label: '6M', text: '6 Months' }
  ]
}

function showDurationLabel (metricTypeKey) {
  return metricTypeKey !== 'Price'
}

function getFinanceMomentConditionValueInputWidth (metricTypeKey) {
  return metricTypeKey === 'ChangePercentage' ? 60 : 145
}

function buildSummaryForFinanceItemMoment (financeItem) {
  const conditionFullInfo = {
    type: getListOfConditionTypes().find(x => x.key === financeItem.financeEvaluationCondition.conditionType),
    lookupPeriod: getListOfLookupPeriods().find(x => x.key === financeItem.financeEvaluationCondition.lookupPeriod)
  }
  const isPercentage = financeItem.financeEvaluationCondition.conditionType === 'ChangePercentage'
  const isPrice = financeItem.financeEvaluationCondition.conditionType === 'Price'
  const conditionType = isPercentage ? `${conditionFullInfo.type.label.toLowerCase()} value` : conditionFullInfo.type.label.toLowerCase()
  const conditionOperator = financeItem.financeEvaluationCondition.comparisonType.toLowerCase()
  const period = isPrice ? '' : ` for the last ${conditionFullInfo.lookupPeriod.text.toLowerCase()}.`
  const suffix = isPercentage ? '%' : financeItem.currency ? ` ${financeItem.currency}` : ''
  const value = accounting.formatNumber(financeItem.financeEvaluationCondition.targetValue, { precision: 2 })
  return `${financeItem.name} (${financeItem.symbol}) ${conditionType} is ${conditionOperator} ${value}${suffix}${period}`
}
