import axios from 'axios'
import helpers from '@/services/helpers.service'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  getAdvertisers,
  getAdvertiser,
  getAdvertiserInOrganization,
  getIndustries,
  getIndustry,
  createAdvertiser,
  createAccount,
  createOrganization,
  createOrder,
  createLine,
  createAssignment,
  getCampaigns,
  getCampaignsCount,
  getTotalCampaignsCount,
  getCampaignById,
  editCampaign,
  duplicateCampaign,
  bulkAction,
  getCampaignsListForCSV,
  generateToken,
  getCampaignForCsv
}

function getAdvertisers (organizationId = null) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/accounts?$filter=advertiserId ne buyerId'
  if (organizationId) { endpoint += ' and buyerId eq ' + organizationId }
  return axios.get(endpoint)
}

function getAdvertiser (accountId) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId).then(res => res.data)
}

function getAdvertiserInOrganization (advertiserName, organizationId) {
  const escapedName = helpers.replaceSpecialCharacters(advertiserName)
  var endpoint = VUE_APP_CAMPSITE_API_URL + "/accounts?$filter=name eq '" + escapedName + "' and advertiserId ne buyerId and buyerId eq " + organizationId
  return axios.get(endpoint).then(res => res.data)
}

function getIndustries () {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/industries')
}

function getIndustry (id) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/industries')
    // .then(res => res.data.map(category => category.subIndustries).flat().find(x => x.id === id))
    .then(res => helpers.arrayFlat(res.data.map(category => category.subIndustries)).find(x => x.id === id))
}

function createAdvertiser (data) {
  const { name, buyerId } = data
  const organization = {
    name,
    ownerOrganizationId: buyerId
  }

  return createOrganization(organization)
    .then(organizationCreated => {
      const account = {
        advertiserId: organizationCreated.id,
        buyerId,
        name
      }

      return createAccount(account)
        .then(accountCreated => accountCreated)
    })
}

function createAccount (account) {
  return axios.post(VUE_APP_CAMPSITE_API_URL + '/accounts', account)
    .then(response => response.data)
    .catch(error => error)
}

function createOrganization (organization, isOwner = null) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/organizations' + (isOwner ? '?isOwner=true' : '')
  return axios.post(endpoint, organization)
    .then(response => response.data)
    .catch(error => error)
}

function createOrder (order) {
  var { accountId } = order
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders'

  return axios.post(endpoint, order)
    .then(response => response.data)
    .catch(error => error)
}

function createLine (line) {
  var { accountId, orderId } = line
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + orderId + '/lines'

  return axios.post(endpoint, line)
    .then(response => response.data)
    .catch(error => error)
}

function createAssignment (assignment) {
  var { accountId } = assignment
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/assignments'

  return axios.post(endpoint, assignment)
    // no then/catch here and let caller handle errors ..?
    .then(response => response.data)
    .catch(error => error)
}

var getCampaignsRequest
function getCampaigns (skip = 0, take = 5, orderBy = 'id', order = 'desc', filters = []) {
  if (getCampaignsRequest) {
    getCampaignsRequest.cancel('Previous get CAMPAIGNS request canceled.')
  }
  getCampaignsRequest = axios.CancelToken.source()

  var endpoint = VUE_APP_CAMPSITE_API_URL + '/orders?skip=' + skip + '&take=' + take + '&$orderby=' + orderBy + ' ' + order

  var filtersString = buildfiltersString(filters)
  if (filtersString) {
    endpoint += '&' + filtersString
  }

  return axios.get(endpoint, { cancelToken: getCampaignsRequest.token }).then(response => response.data)
}

var countCampaignsRequest
function getCampaignsCount (filters = []) {
  if (countCampaignsRequest) {
    countCampaignsRequest.cancel('Previous count CAMPAIGNS request canceled.')
  }
  countCampaignsRequest = axios.CancelToken.source()

  var endpoint = VUE_APP_CAMPSITE_API_URL + '/orders'

  var filtersString = buildfiltersString(filters)
  if (filtersString) {
    endpoint += '?' + filtersString
  }
  return axios.head(endpoint, { cancelToken: countCampaignsRequest.token }).then(response => parseInt(response.headers['x-count']) || 0)
}

/**
 * getTotalCampaignsCount()
 * @returns total number of campaigns created by (current logged in) user's organization
 * NON-cancelable request VS getCampaignsCount()
 */
function getTotalCampaignsCount () {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/orders'
  return axios.head(endpoint).then(response => parseInt(response.headers['x-count']) || 0)
}

function getCampaignById (orderId) {
  const url = VUE_APP_CAMPSITE_API_URL + '/orders/?$filter=id eq ' + orderId

  return axios.get(url)
    .then(res => res.data.find(campaign => campaign.id === orderId))
}

function editCampaign (accountId, campaignId, modifiedFields) {
  // modifiedFields is an object containing the id of the campaign and all the fields that the user wants to update
  const url = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + campaignId

  return axios.patch(url, modifiedFields).then(res => res.data)
}

function duplicateCampaign (accountId, campaignId, keepCreatives = false) {
  let url = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + campaignId + '/clone'
  if (keepCreatives === true) { url += '?includeCreatives=true' }

  return axios.post(url, {}).then(res => res.data)
}

function bulkAction (params, modifs) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/orders?'
  var filtersString = buildfiltersString(params.filters)

  var urlPieces = []
  if (filtersString) { urlPieces.push(filtersString) }
  if (params.take) { urlPieces.push(`take=${params.take}`) }
  if (params.skip) { urlPieces.push(`skip=${params.skip}`) }

  return axios.patch(endpoint + urlPieces.join('&'), modifs).then(response => response.data)
}

// Helper functions (should be centralized - maybe)

function buildfiltersString (filtersIn = []) {
  var filtersOut = []
  var filtersString = ''

  filtersIn.map(filter => {
    const operator = filter.operator || 'eq'
    const quotedValue = typeof filter.value === 'number' || typeof filter.value === 'boolean' ? filter.value : "'" + filter.value + "'"

    switch (filter.name) {
      // SEARCH
      case 'search': {
        const escapedFilter = helpers.replaceSpecialCharacters(filter.value)
        filtersOut.push("(contains(name, '" + escapedFilter + "') or contains(account, '" + escapedFilter + "') or contains(owner/organizationName, '" + escapedFilter + "'))")
        break
      }
      case 'mainStatus':
        if (filter.value === 'All') { filtersOut.push("(status eq 'Active' or status eq 'Paused' or status eq 'Proposal')") }
        if (filter.value === 'Active') { filtersOut.push("(status eq 'Active' or status eq 'Paused')") }
        if (filter.value === 'Archived') { filtersOut.push(filter.keyName + ' ' + operator + ' ' + "'" + filter.value + "'") }
        if (filter.value === 'Proposal') { filtersOut.push("(status eq 'Proposal')") }
        break
      case 'secondaryStatus':
        if (filter.value === 'All') { filtersOut.push("(timeStatus eq 'Upcoming' or timeStatus eq 'Ongoing' or timeStatus eq 'Over')") } else filtersOut.push(filter.keyName + ' ' + operator + ' ' + "'" + filter.value + "'")
        break
      default: {
        const filterString = filter.name + ' ' + operator + ' ' + quotedValue
        filtersOut.push(filterString)
        break
      }
    }
  })

  if (filtersOut.length) {
    filtersString = '$filter=' + filtersOut.join(' and ')
  }
  return filtersString
}

function getCampaignsListForCSV (filters) {
  const config = {
    responseType: 'text/csv'
  }

  var endpoint = VUE_APP_CAMPSITE_API_URL + '/orders?'

  var filtersString = buildfiltersString(filters)
  if (filtersString) { endpoint += filtersString }

  return axios.get(endpoint, config).then(response => response.data)
}

function generateToken (campaignId, accountId) {
  return axios.post(`${VUE_APP_CAMPSITE_API_URL}/accounts/${accountId}/orders/${campaignId}/tokens`).then(response => response.data)
}

function getCampaignForCsv (campaignId) {
  return axios.get(`${VUE_APP_CAMPSITE_API_URL}/orders/${campaignId}/export`).then(response => response.data)
}
