<template lang="pug">
  v-container(grid-list-lg text-center fill-height)
    v-layout(row wrap align-center fill-height)
      v-flex(class='text-center')
        p Logging out...
</template>
<script>
import multiTabService from '@/services/multiTabNotifications'

export default {
  created () {
    // check if User is already logged in before logging him out
    this.$store.dispatch('auth/fetchSession')
      .then(authResp => {
        this.$store.dispatch('auth/signOut')
          .catch(() => {})
          .finally(() => {
            multiTabService.pushNotification('loggedOut')
            this.$router.push('/')
          })
      })
      .catch(() => {
        this.$router.push('/')
      })
    this.$store.commit('general/resetApp')
  }
}
</script>
