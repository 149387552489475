export {
  colsAvailable
}

function colsAvailable (instance, stateLabel = 'State', postcodeLabel = 'Zip Code') {
  const columns = {
    venues: [
      { keys: ['name'], label: 'Name' },
      { keys: ['date'], label: 'Date' },
      { keys: ['impressions'], label: 'Impressions' },
      { keys: ['avgCPM'], label: 'CPM' },
      { keys: ['adsServed'], label: 'Ads Served' },
      { keys: ['locationType'], label: 'Type' },
      { keys: ['spent'], label: 'Spent' },
      { keys: ['geoJSON'], label: 'Polygon' },
      { keys: ['address'], label: 'Address' },
      { keys: ['postalCode'], label: postcodeLabel },
      { keys: ['city'], label: 'City' },
      { keys: ['province'], label: stateLabel },
      { keys: ['country'], label: 'Country' },
      { keys: ['numberOfScreens'], label: 'Screens' },
      { keys: ['latitude', 'longitude'], label: 'Latitude & Longitude' }
    ],
    screens: [
      { keys: ['name'], label: 'Name' },
      { keys: ['date'], label: 'Date' },
      { keys: ['impressions'], label: 'Impressions' },
      { keys: ['avgCPM'], label: 'CPM' },
      { keys: ['adsServed'], label: 'Ads Served' },
      { keys: ['locationType'], label: 'Type' },
      { keys: ['spent'], label: 'Spent' },
      { keys: ['geoJSON'], label: 'Polygon' },
      { keys: ['address'], label: 'Address' },
      { keys: ['postalCode'], label: postcodeLabel },
      { keys: ['city'], label: 'City' },
      { keys: ['province'], label: stateLabel },
      { keys: ['country'], label: 'Country' },
      { keys: ['latitude', 'longitude'], label: 'Latitude & Longitude' }
    ],
    lines: [
      { keys: ['name'], label: 'Name' },
      { keys: ['date'], label: 'Date' },
      { keys: ['impressions'], label: 'Impressions' },
      { keys: ['avgCPM'], label: 'CPM' },
      { keys: ['adsServed'], label: 'Ads Served' },
      { keys: ['spent'], label: 'Spent' }
    ],
    creatives: [
      { keys: ['name'], label: 'Name' },
      { keys: ['date'], label: 'Date' },
      { keys: ['impressions'], label: 'Impressions' },
      { keys: ['avgCPM'], label: 'CPM' },
      { keys: ['adsServed'], label: 'Ads Served' },
      { keys: ['spent'], label: 'Spent' }
    ],
    geolocations: [
      { keys: ['name'], label: 'Name' },
      { keys: ['date'], label: 'Date' },
      { keys: ['numberOfVenues'], label: 'Venues' },
      { keys: ['numberOfScreens'], label: 'Screens' },
      { keys: ['spent'], label: 'Spent' },
      { keys: ['impressions'], label: 'Impressions' },
      { keys: ['avgCPM'], label: 'CPM' },
      { keys: ['adsServed'], label: 'Ads Served' }
    ]
  }
  if (['countries', 'states', 'cities', 'environments'].includes(instance)) { return columns.geolocations } else { return columns[instance] }
}
