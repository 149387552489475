<template lang='pug'>
v-text-field(
  ref='input'
  :id='id'
  :disabled='disabled'
  :hide-details='hideDetails'
  :label='label'
  :placeholder='placeholder'
  :prefix='prefix'
  :rules='computedRules'
  :suffix='suffix'
  :type='inputType'
  autocomplete='off'
  min='0'
  v-model='displayValue'
  @blur="handleInputState($event)"
  @focus="handleInputState($event)"
)
</template>

<script>
import { debounce } from 'lodash'
import localesService from '@/services/locales.service'

const masks = {
  default: {
    mask (value, locale) {
      return value.toLocaleString(locale, {
        maximumFractionDigits: 10
      })
    }
  },
  currency: {
    mask (value, locale) {
      return value.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  },
  noDecimals: {
    mask (value, locale) {
      return value.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    }
  }
}

export default {
  props: {
    disabled: Boolean,
    hideDetails: Boolean,
    id: String,
    label: String,
    locale: String,
    placeholder: String,
    prefix: String,
    rules: Array,
    suffix: String,
    type: {
      type: String,
      default: 'default'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    valueType: {
      validator (value) {
        return ['string', 'number'].includes(value)
      }
    }
  },
  data () {
    return {
      inputFocused: false,
      hasInitialValue: !!this.value,
      internalValue: this.value || this.value === 0 ? this.value : '',
      inputType: 'text'
    }
  },
  watch: {
    value: function (newVal) {
      this.internalValue = newVal || newVal === 0 ? newVal : ''
    }
  },
  computed: {
    computedLocale () {
      const preferredLocales = this.locale ? [this.locale, ...navigator.languages] : navigator.languages
      return localesService.getSupportedLocale(preferredLocales)
    },
    computedRules () {
      if (!this.rules) return

      return this.rules.map(rule => {
        return (value) => rule(localesService.unformatLocaleString(value, this.computedLocale))
      })
    },
    displayValue: {
      get: function () {
        return this.inputFocused || this.inputType === 'number'
          ? this.internalValue.toString()
          : this.mask(this.internalValue)
      },
      set: debounce(function (modifiedValue) {
        this.internalValue = modifiedValue
        this.$nextTick(() => {
          this.internalValue = this.unmask(modifiedValue)
          this.$emit('input', this.internalValue)
        })
      }, 450)
    },
    returnType () {
      if (this.valueType) return this.valueType
      if (this.hasInitialValue) return typeof this.value
      return 'number'
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    blur () {
      this.$refs.input.blur()
    },
    handleInputState (event) {
      this.inputFocused = event.type === 'focus'
      this.updateInputType()
      this.$emit(event.type, event)
    },
    updateInputType () {
      setTimeout(() => {
        this.inputType = this.inputFocused ? 'number' : 'text'
      }, 0)
    },
    toMaskableNumber (value) {
      return value && typeof value === 'string'
        ? parseFloat(value)
        : value
    },
    cleanInput (value) {
      if (value.startsWith('-')) return ''
      return value.replace(/[^\d.]/g, '')
    },
    mask (value) {
      return masks[this.type].mask(this.toMaskableNumber(value), this.computedLocale)
    },
    unmask (value) {
      let inputValue = this.cleanInput(value)

      if (this.returnType === 'string') return inputValue

      inputValue = parseFloat(inputValue)
      return isNaN(inputValue) ? 0 : inputValue
    }
  }
}
</script>
