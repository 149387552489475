export default {
  extractNotification,
  pushNotification,
  clearNotifications
}

/**
 * @param {{key: string, newValue: string}} e
 * @return {{ name: string, redirect: string } | null}
 */
function extractNotification (e) {
  if (e.key === 'multiTabNotifications') {
    return events[e.newValue] ? events[e.newValue] : null
  }
  return null
}

/**
 * @param {string} key
 */
function pushNotification (key) {
  if (events[key]) {
    localStorage.setItem('multiTabNotifications', key)
  }
}

function clearNotifications () {
  localStorage.removeItem('multiTabNotifications')
}

const events = {
  switchedOrganization: { name: 'switchedOrganization', redirect: 'Campaigns Overview' },
  loggedOut: { name: 'loggedOut', redirect: 'login' }
}
