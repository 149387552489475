import userAPI from '@/services/user.api'
import userService from '@/services/user'
import tracking from '@/services/tracking'
import RoxBrowser from 'rox-browser'

export default {
  namespaced: true,
  state: {
    token: null,
    profile: null,
    organization: {},
    availableOrganizations: [],
    roles: [],
    appReady: false,
    appTermsVersion: '4'
  },
  getters: {
    getToken: state => {
      return state.token
    },
    getProfile: state => {
      return state.profile
    },
    getOrganization: state => {
      return state.organization
    },
    getAvailableOrganizations: state => {
      return state.availableOrganizations
    },
    getUserRoles: state => {
      return state.roles
    },
    isGlobalAdmin: state => {
      return state.roles.includes('GlobalAdmin')
    },
    getUserFirstName: state => {
      return state.profile ? state.profile.firstName : ''
    },
    getUserDisplayName: state => {
      if (!state.profile) return ''

      if (state.profile.firstName) return state.profile.firstName
      if (state.profile.lastName) return state.profile.lastName

      return state.profile.email
    },
    getPaymentStatus: state => {
      return state.organization.paymentStatus
    },
    getAppTermsVersion: state => {
      return state.appTermsVersion
    },
    rolesNumber: state => {
      return state.roles.length
    },
    getAppReady: state => state.appReady,
    termsVersion: state => state.organization.termsVersion,
    termsAccepted: state => {
      return state.organization.termsVersion === state.appTermsVersion || state.organization.isForAdServer === true
    },
    isForAdServer: state => {
      return state.organization.isForAdServer
    },
    permissions: state => resource => {
      return userService.getPermissions(state.roles, resource)
    },
    currency: state => {
      return state.organization && state.organization.currency
        ? state.organization.currency.code
        : 'CAD'
    }
  },
  mutations: {
    setToken: (state, token) => {
      state.token = token
    },
    setProfile: (state, profile) => {
      state.profile = profile
    },
    updateProfile: (state, profile) => {
      const { id, firstName, lastName } = profile
      var editableFields = { id, firstName, lastName }
      state.profile = Object.assign({}, state.profile, editableFields)
    },
    setOrganization: (state, organization) => {
      state.organization = organization
    },
    setAvailableOrganizations: (state, organizations) => {
      state.availableOrganizations = organizations
    },
    setRoles: (state, roles) => {
      state.roles = roles
    },
    setAppReady: (state, val) => {
      state.appReady = val
    }
  },
  actions: {
    login: ({ dispatch, commit, getters }, { username, password, dontSetAppReady = false }) => {
      return dispatch('auth/signInUser', { username, password }, { root: true })
        .then(authResp => {
          var token = authResp.signInUserSession.getAccessToken().getJwtToken() || null

          commit('setToken', token)

          return dispatch('getUserProfile', { dontSetAppReady })
        })
    },
    activeOrganizationCheckForAuth0User: (context, platformId) => {
      return userAPI.getBAdsOrganizationFromPlatformId(platformId)
        .then(badsOrgIds => {
          if (badsOrgIds.length) {
            const badsOrgId = badsOrgIds[0].id
            return userAPI.setUserActiveOrganization(badsOrgId)
              .then(() => badsOrgId)
          } else {
            const errMsg = 'Could not find BAds organizationId from platformId'
            throw new Error(errMsg)
          }
        })
    },
    getUserProfile: async ({ dispatch, commit, getters }, payload = null) => {
      commit('setAppReady', false)

      const dontSetAppReady = payload ? payload.dontSetAppReady : false

      return userAPI.getUser().then(async user => {
        commit('setProfile', user)

        dispatch('general/getAdvertisers', user.organizationId, { root: true })

        return userAPI.getOrganization(user.organizationId).then(async organization => {
          commit('setOrganization', organization)
          tracking.boot(getters.getProfile, getters.getOrganization)
          RoxBrowser.setCustomStringProperty('PlatformId', organization.platformId)

          await dispatch('general/getExchanges', {}, { root: true })

          return userAPI.getUserRoles(user.id).then(roles => {
            commit('setRoles', roles)

            if (!dontSetAppReady) {
              commit('setAppReady', true)
            }

            return getters.getProfile
          })
        })
      })
    },
    getUserAvailableOrganizations: (context) => {
      userAPI.getAvailableOrganization().then(organizations => {
        context.commit('setAvailableOrganizations', organizations)
      })
    },
    setUserActiveOrganization: (context, organizationId) => {
      return userAPI.setUserActiveOrganization(organizationId).then(user => user)
    },
    editOrganization: ({ commit }, data) => {
      return userAPI.editOrganization(data).then(res => {
        commit('setOrganization', res)
      })
    }
  }
}
