<template lang="pug">
  v-list(style='overflow-y:auto; height: 70vh')
    draggable(v-model='items')
      v-list-item.rounded-sm.sortable-item(v-for='(item, index) in items' :key='index')
        v-list-item-action.my-1
          v-checkbox(v-model="items[index].enabled" :id='item.value + "-checkbox"' color="primary" @click='onItemsChange')
        v-list-item-content.my-1.py-1
          v-list-item-title.sortable-item-title {{ item.text }}
        v-list-item-action.my-0
          v-icon() mdi-drag-horizontal-variant

</template>
<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  props: {
    sortableItems: {
      type: Array,
      default: () => []
    },
    onChange: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      items: JSON.parse(JSON.stringify(this.sortableItems))
    }
  },
  watch: {
    items () {
      this.onItemsChange()
    }
  },
  methods: {
    onItemsChange () {
      this.onChange(JSON.parse(JSON.stringify(this.items)))
    }
  }
}
</script>

<style scoped>

.sortable-item {
  margin:0px 8px;
  cursor: grab;
}

.sortable-item:active {
  cursor: grabbing;
}

</style>
