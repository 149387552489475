<template lang="pug">
div
  v-data-table(
    :headers='categoryHeaders'
    :items='details.values'
    item-key='target'
    must-sort
    dense
    hide-default-footer
    :options.sync='tableCtrls'
  )
    //- Mobile Version
    template.text-left(v-slot:item="{ item }" v-if='$vuetify.breakpoint.xsOnly')
      .d-flex.align-center.justify-start
        v-icon(:color='iconColor(item.percentageFromMean)' right large) {{ iconType(item.percentageFromMean) }}
        | {{ displayPercentageWithSign(item.percentageFromMean) }}
        span.ml-2  {{ item.target }}

    //- HEADERS
    template(v-slot:header.percentageFromMean='{ header }')
      v-tooltip(bottom)
        template(v-slot:activator='{on}')
          span(v-on='on') {{ header.text }}
        span {{ header.text }}

    template(v-slot:header.target='{ header }')
      v-tooltip(bottom)
        template(v-slot:activator='{on}')
          span(v-on='on') {{ header.text }}
        span {{ header.text }}

    //- ITEMS
    template(v-slot:item.percentageFromMean='{ item }')
      .d-flex.align-center.justify-start
        v-icon(:color='iconColor(item.percentageFromMean)' right large) {{ iconType(item.percentageFromMean) }}
        | {{ displayPercentageWithSign(item.percentageFromMean) }}

    template(v-slot:item.target='{ item }')
      span {{ item.target }}
      //- v-tooltip(bottom)
        template(v-slot:activator='{on}')
          span(v-on='on') {{ item.target }}
        span Persona Definition from Support

    //- FOOTER
    template(v-slot:footer='')
      .text-center
        //- MODAL
        v-dialog(v-model='isDialogOpen' max-width='500' scrollable :fullscreen='$vuetify.breakpoint.smAndDown')
          template(v-slot:activator='{on}')
            v-btn.mx-0.px-2(v-on='on' text left color='primary') See All

          v-card
            v-toolbar(flat dense color='grey lighten-4')
              v-toolbar-title.text-h5 {{ details.name }}
              v-spacer
              v-btn(small icon @click='isDialogOpen = false')
                v-icon(color='grey') mdi-close

            v-card-text
              v-data-table(
                :headers='categoryHeaders'
                :items='details.values'
                item-key='target'
                must-sort
                dense
                hide-default-footer
                :options.sync='dialogTableCtrls'
              )

                //- HEADERS
                template(v-slot:header.percentageFromMean='{ header }')
                  v-tooltip(bottom)
                    template(v-slot:activator='{on}')
                      span(v-on='on') {{ header.text }}
                    span {{ header.text }}

                template(v-slot:header.target='{ header }')
                  v-tooltip(bottom)
                    template(v-slot:activator='{on}')
                      span(v-on='on') {{ header.text }}
                    span {{ header.text }}

                //- ITEMS
                template(v-slot:item.percentageFromMean='{ item }')
                  .d-flex.align-center.justify-start
                    v-icon(:color='iconColor(item.percentageFromMean)' right large) {{ iconType(item.percentageFromMean) }}
                    | {{ displayPercentageWithSign(item.percentageFromMean) }}

                template(v-slot:item.target='{ item }')
                  span {{ item.target }}
                  //- v-tooltip(bottom)
                    template(v-slot:activator='{on}')
                      span(v-on='on') {{ item.target }}
                    span Persona Definition from Support
</template>

<script>
export default {
  props: ['details', 'comparedTo'],
  data () {
    return {
      categoryHeaders: [
        {
          text: this.comparedTo,
          align: 'left',
          value: 'percentageFromMean',
          width: '140px'
        },
        {
          text: 'Audience',
          align: 'left',
          sortable: false,
          value: 'target'
        }
      ],
      isDialogOpen: false,
      tableCtrls: {
        itemsPerPage: 10,
        sortBy: ['percentageFromMean'],
        sortDesc: [true]
      },
      dialogTableCtrls: {
        itemsPerPage: -1,
        sortBy: ['percentageFromMean'],
        sortDesc: [true]
      }
    }
  },
  methods: {
    displayPercentageWithSign (nbr) {
      return nbr >= 0 ? '+' + nbr + '%' : nbr + '%'
    },
    iconColor (percent) {
      return percent >= 0 ? 'success' : 'error'
    },
    iconType (percent) {
      return percent >= 0 ? 'mdi-menu-up' : 'mdi-menu-down'
    }
  }
}
</script>
