import venuesAPI from '@/services/venues.api'

export default {
  namespaced: true,
  state: {
    venues: []
  },
  getters: {
    getVenue: state => venueId => {
      return state.venues.find(venue => venue.id === venueId)
    }
  },
  mutations: {
    saveVenue: (state, payload) => {
      var i = state.venues.find(venue => venue.id === payload.id)
      if (i) {
        state.venues[i] = payload
      } else {
        state.venues.push(payload)
      }
    }
  },
  actions: {
    getVenue: (context, venueId) => {
      venuesAPI.getVenue(venueId).then(venue => {
        // get image from API
        venuesAPI.getVenueImage(venueId)
          .then(image => {
            venue.image = image
          })
          .catch(() => {
            venue.image = null
          })
          .finally(() => {
            context.commit('saveVenue', venue)
          })
      })
    }
  }
}
