<template lang="pug">
div
  vue-headful(:title="componentConfig.branding.title('History')")

  //- Search bar
  v-container()
    v-row.my-0(no-gutters)
      v-col(cols='12' lg='8' offset-lg='2')
        v-toolbar.elevation-0(color='transparent')
          .text-h5.hidden-sm-and-down History
          v-text-field.mr-0(
            label='Search notifications'
            v-model='search'
            hide-details
            prepend-inner-icon='mdi-magnify'
            solo
            clearable
            :class="{'mx-4': $vuetify.breakpoint.smAndUp }"
            )
            template(v-slot:append='')
              component(:is="$vuetify.breakpoint.xsOnly? 'v-dialog' : 'v-menu'" v-model='searchMenuOpen' fullscreen offset-y left :close-on-content-click='false' keep-alive)
                template(v-slot:activator='{on}')
                  v-btn(icon small v-on='on')
                    v-icon mdi-menu-down
                v-card(:width='$vuetify.breakpoint.xsOnly? undefined : 400' :tile='$vuetify.breakpoint.xsOnly')
                  v-card-title
                    | Filter notifications
                    v-spacer
                    v-btn.hidden-sm-and-up(icon small @click='searchMenuOpen = false')
                      v-icon mdi-close
                  v-card-text
                    v-autocomplete(v-if='can.read.perOrg' v-model='organization' :items='organizations' item-text='name' item-value='id' label='Organization')
                    v-select(:items='support' v-model='type' item-text='name' item-value='key' label='Type')
                  v-card-actions.pr-3.pb-3
                    v-spacer
                    v-btn(text @click='clearFilters()') clear
                    v-btn(color='primary' text @click='filter()') filter

    v-row.my-0(v-if='loadingNotifs' no-gutters)
      v-col.pt-4(cols='12' lg='8' offset-lg='2')
        v-skeleton-loader(max-width="35%" type="heading" style='margin-left: 70px;')
        v-skeleton-loader(max-width="100%" type="list-item-avatar-three-line" )
        v-skeleton-loader(max-width="90%" type="list-item-avatar-three-line" )
        v-skeleton-loader(max-width="85%" type="list-item-avatar-three-line" )
        v-skeleton-loader(max-width="95%" type="list-item-avatar-three-line" )
        v-skeleton-loader(max-width="100%" type="list-item-avatar-three-line" )
        v-skeleton-loader(max-width="92%" type="list-item-avatar-three-line" )
        v-skeleton-loader(max-width="90%" type="list-item-avatar-three-line" )

  //- Timeline
  .text-center.text-h6.font-weight-regular.mt-8(v-if='!notifications.length && !loadingNotifs') No recent activity
  v-container(v-if='notifications.length' )
    v-row.my-0
      v-col( cols='12' lg='8' offset-lg='2')
        v-timeline.pt-0(dense clipped)
          div(v-for='notif in formattedNotifications')
            div(v-if='!notif.notifType')
              v-timeline-item.pb-0(fill-dot :color='avatarBgColor(notif.data.user)')
                template(v-slot:icon='')
                  router-link.white--text(:to="{ name: 'user', params: { userId: notif.data.user.userId }}") {{initials(notif.data.user)}}

                //- Desktop View
                v-row.my-0(v-if='$vuetify.breakpoint.smAndUp' justify='space-between')
                  v-col
                    div.text-overline
                      span(v-if='can.read.perOrg && notif.data.user.userId != 1 && notif.data.organizationName')
                        | {{notif.data.organizationName}}.
                    span
                      router-link.info--text(:to="{ name: 'user', params: { userId: notif.data.user.userId }}") {{ notif.data.user.firstName }} {{ notif.data.user.lastName }}
                      strong.text-caption.grey--text &nbsp;•&nbsp;
                      span.text-caption.grey--text {{ notif.data.date | date('MMM Do, YYYY hh:mm a') }}
                    .text--primary.mt-2(v-html='notif.body')
                    v-divider.mt-4

                //- Mobile View
                div.mt-4(v-if='$vuetify.breakpoint.xsOnly')
                  div.text-overline
                      span(v-if='can.read.perOrg && notif.data.user.userId != 1 && notif.data.organizationName')
                        | {{notif.data.organizationName}}.
                  span
                    router-link.info--text(:to="{ name: 'user', params: { userId: notif.data.user.userId }}") {{ notif.data.user.firstName }} {{ notif.data.user.lastName }}
                    strong.text-caption.grey--text &nbsp;•&nbsp;
                    span.text-caption.grey--text {{ notif.data.date | date('MMM Do, YYYY hh:mm a') }}
                  .text--primary.mt-2(v-html='notif.body')
                  v-divider.mt-4

            div(v-if="notif.notifType && notif.notifType === 'date'")
              v-timeline-item.py-1(hide-dot)
                .text-h4 {{ notif.val }}

  //- Load more button
  .text-center.mt-10.mb-4(v-if='notifications.length || loadingNotifs')
    v-btn(color='primary' dark rounded large :loading='loadingNotifs' @click="getNotifs('loadMore')") load more
</template>

<style scoped>
  >>> .v-toolbar__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  >>> .v-timeline-item__divider{
    min-width: 50px;
    margin-right: 10px;
  }

  >>> .v-timeline--dense .v-timeline-item__body {
    max-width: calc(100%);
  }
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before{
    left: 24px;
  }
  >>> .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line {
    background-color: transparent;
  }
</style>

<script>
import notificationsApi from '@/services/notifications.api'
import helpersService from '@/services/helpers.service'
import componentConfigService from '@/services/componentConfig'
import moment from 'moment'
import userApi from '../services/user.api'
import { debounce } from 'lodash'

// dynamic Vuetify components used with <component :is="v-component"> must be registered locally
import { VDialog, VMenu } from 'vuetify/lib'

export default {
  components: { VDialog, VMenu },
  data () {
    return {
      loadingNotifs: true,
      notifications: [],

      // Filters & search
      search: '',
      searchMenuOpen: false,
      take: 100,
      type: null,
      organizations: [],
      organization: null
    }
  },
  created () {
    this.getNotifs('fetch')

    if (this.can.read.perOrg) {
      userApi.getOrganizationsAdv({ selectAll: true, filters: [] }).then(res => {
        this.organizations = [{ name: 'All organizations', id: null }, ...res].sort(helpersService.sortByName)
      })
    }
  },
  computed: {
    can () {
      const permissions = this.$store.getters['user/permissions']('notification')
      return permissions
    },

    orgId () {
      const org = this.$store.getters['user/getOrganization']
      return org ? org.id : null
    },

    support () {
      return [{ name: 'All types', key: null }, ...this.$store.getters['notifications/getSupport']].sort(helpersService.sortByName)
    },

    formattedNotifications () {
      const newNotifs = []

      for (let i = 0; i <= this.notifications.length - 1; i++) {
        var currDay = moment(this.notifications[i].occuredOn)

        if (i === 0) {
          newNotifs.push({ notifType: 'date', val: currDay.format('MMMM Do, YYYY') })
          newNotifs.push(this.notifications[i])
        } else {
          var prevDay = moment(this.notifications[i - 1].occuredOn)

          if (!currDay.isSame(prevDay, 'day')) {
            newNotifs.push({ notifType: 'date', val: currDay.format('MMMM Do, YYYY') })
          }
          newNotifs.push(this.notifications[i])
        }
      }

      return newNotifs
    },

    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  watch: {
    search: debounce(function (newVal, oldVal) {
      if (newVal === null) { this.search = ''; return }
      this.getNotifs('fetch')
    }, 450)
  },
  methods: {
    initials (user) {
      return helpersService.initials(user)
    },
    avatarBgColor (user) {
      return helpersService.avatarBackgroundColor(user)
    },

    getNotifs (action) {
      this.loadingNotifs = true

      const skip = action === 'loadMore' ? this.notifications.length : 0
      const type = this.type ? this.type : ''
      const orgId = this.organization ? this.organization : (this.can.read.perOrg ? 0 : this.orgId)

      notificationsApi.getHistory(orgId, this.take, skip, type, this.search).then(res => {
        this.$store.dispatch('notifications/getHistory', res).then(notifs => {
          this.loadingNotifs = false

          if (action === 'loadMore') {
            this.notifications.push(...notifs)
          } else {
            this.notifications = notifs
          }
        })
      })
    },

    filter () {
      this.getNotifs('fetch')
      this.searchMenuOpen = false
    },

    clearFilters () {
      this.type = null
      this.organization = null
      this.getNotifs('fetch')
      this.searchMenuOpen = false
    }
  }
}
</script>
