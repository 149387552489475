<template lang="pug">
div(style='color: rgba(0, 0, 0, 0.87)')
  v-layout.py-0(row wrap)
    v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
      .text-body-1.font-weight-bold.mr-3.market-label Markets
    v-flex(xs12 lg9 xl8 text-md-left )
      v-radio-group.mt-0.market-option-group(v-model='marketValue' hide-details :disabled='isDisabled')
        v-radio.market-option(v-for='market in orderedMarkets' :key="market.id" :ref="market.market" :label='market.label' :value='market.market' color='primary')
</template>
<script>

import defaultExchangeValues from '@/services/defaultExchangeValues'
import flags from '@/plugins/rox/flags'

export default {
  props: {
    market: {
      type: String,
      default: () => 'united-state'
    },
    parent: {
      type: String,
      default: () => 'auctionPackage'
    }
  },
  data () {
    return {
      isDisabled: false,
      marketValue: this.market,
      parentStore: this.parent
    }
  },
  mounted: function () {
    this.updateExchangeInStore(this.marketValue)
  },
  computed: {
    forecastLoading () {
      return this.$store.getters['audience/forecastLoading'] && this.$store.getters['audience/forecastInventoryLoading'] && this.$store.getters['audience/forecastVenuesLoading']
    },
    orderedMarkets () {
      return defaultExchangeValues
        .getAllDefaultValues()
        .sort((x, y) => x.displayOrder - y.displayOrder)
        .map(market => {
          if (this.canSeeAuctionPackageCurrencySelection) {
            return {
              ...market,
              label: market.label.replace(/\s?\(.*\)$/, '').trim()
            }
          }
          return market
        })
    },
    canSeeAuctionPackageCurrencySelection () {
      return flags.canSeeAuctionPackageCurrencySelection.isEnabled()
    }
  },
  watch: {
    forecastLoading (newV) {
      this.isDisabled = newV
    },
    marketValue (newV, oldV) {
      if (newV !== oldV) {
        this.$store.dispatch('audience/updateGeoTargetsAction', [])
        this.updateExchangeInStore(newV)
        this.$emit('storeUpdated')
      }
    }
  },
  methods: {
    updateExchangeInStore (newExchange) {
      this.$store.commit(`${this.parentStore}/storeExchangeDetails`, {
        exchange:
          {
            key: newExchange,
            id: this.orderedMarkets.find(m => m.market === newExchange).id
          }
      })
    }
  }
}
</script>
