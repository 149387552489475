<template lang="pug">
  v-expansion-panel#budget-panel
    v-expansion-panel-header.text-h6(ref='budget-panel-title') Line Budget &amp; Schedule
    v-expansion-panel-content(ref='budget-panel-content')
      div
        //- v-btn.ma-0.ml-2(icon small href='https://campsiteproject.zendesk.com/hc/en-us/articles/360019721274#h_628f0f11-b1f7-473c-9c83-c879c2600d9a' target='_blank')
          v-icon(color='grey') mdi-help-circle

      .pt-0
        scheduleSection(
          @storeUpdated='$emit("updateForecast")'
          v-bind:host="{ name: 'createCampaign' }"
          :parent='parent'
          :isProposalLine='isProposalLine'
          :instance='instance'
        )
        v-layout.py-0(row wrap)
          v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
            .text-body-1.font-weight-bold.mr-3 Budget
          v-flex(xs12 lg9 xl8 text-md-left)
            v-form(ref='budgetForm' onSubmit='return false;')
              formattedNumberInput.pb-2.pt-0.mt-0#budget-field(
                label= 'Spend up to'
                hide-details
                placeholder='0'
                style='max-width:350px'
                type='currency'
                valueType='number'
                :prefix='selectedCurrency'
                :rules='[rules.positiveIfProvided]'
                :value='budget'
                @input='budgetChanged'
              )
            .text-caption.d-block.max-budget
              | Maximum estimated budget:
              span.grey--text(v-if='estimatedMaxBudget == null')  N/A
              strong(v-if='estimatedMaxBudget != null' :class="{'red--text text--accent-2': budget > estimatedMaxBudget}")
                | &nbsp;{{ estimatedMaxBudget | currency(this.selectedCurrency) }}
              | .
        v-layout.py-3(row wrap)
          v-flex.pb-0.max-bid-label(xs12 lg3 xl4 text-xl-right)
            .text-body-1.font-weight-bold.mr-3
              | {{ (!deals.length || usePublicExchange) ? 'Max Bid' : 'Net Max Bid' }}
          v-flex(xs12 lg9 xl8 text-md-left)
            .d-flex.align-center
              v-form(ref='max-bid-form' style='max-width:125px' onSubmit='return false;')
                formattedNumberInput.pt-0.mt-0.pb-2#max-bid-field(
                  ref='max-bid-formatted-number-input'
                  label='Bid up to'
                  hide-details
                  placeholder='0'
                  type='currency'
                  valueType='number'
                  :prefix='selectedCurrency'
                  :rules='[rules.required, rules.strictPositive]'
                  :value='maxBidForInput'
                  @input='bidChanged'
                )
              .ml-3 CPM

            .text-caption.d-block.bid-ranges
              span#bid-range-label {{bidRangeLabel}}
              strong(v-if="bidRangeMin")
                | &nbsp;
                span#bid-range-min-label(:class="{'red--text text--accent-2': maxBidForDisplay < parseFloat(bidRangeMin.toFixed(2)) && maxBid }") {{ bidRangeMin | currency(this.selectedCurrency) }}
                span(v-if='bidRangeMaxForDisplay') &nbsp;-&nbsp;
                span#bid-range-max-label(v-if='bidRangeMaxForDisplay' :class="{'red--text text--accent-2': maxBidForDisplay < parseFloat(bidRangeMin.toFixed(2)) && maxBid }") {{ bidRangeMaxForDisplay | currency(this.selectedCurrency) }}
              span(v-if="!bidRangeMin") &nbsp;not available
              | .

            .text-caption.d-block.fees-disclaimer(v-if="deals.length && !usePublicExchange && markup")
              //- | Deal CPM is what the publisher will be paid.
              //- br
              | Standard costs and fees apply. All included max bid CPM is
              strong  {{ maxBidForDisplay * (1 + markup / 100) | currency(this.selectedCurrency) }}
              | .

        v-layout.py-0(row wrap)
          v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
            .text-body-1.font-weight-bold.mr-3 Impressions
          v-flex(xs12 lg9 xl8 text-md-left)
            v-card-text.pl-0.pt-1.secondary--text
              forecast-impressions(
                :loading="isForecastLoading"
                :total-impressions="totalImpressions"
                :targeted-impressions="targetedImpressions"
              )
              v-expansion-panels.mt-2#impressions-distribution(v-if="canSeeImpressionDistribution" flat :value='!canSeeImpressionDistribution ? null : 0')
                v-expansion-panel
                  v-expansion-panel-header.v-btn.v-size--small.pl-1.primary--text(style="margin-left:inherit; min-height:inherit;")
                    div.d-flex.align-center
                      v-icon.mr-1(small color='primary') mdi-chart-pie
                      div Budgeted impressions per venue type
                    template(v-slot:actions='')
                  v-expansion-panel-content
                    impressionsDistribution.my-3(
                      :environments='environments'
                      :initialized="chartInitialized"
                      :loading="isForecastLoading"
                      style='height: 100px; width: 100%;'
                    )
                    .text-caption.d-block.mt-0
                      | Best estimation on how impressions will be delivered across targeted venue types. Delivery is always optimized based on cost efficiency.
</template>

<script>
import audienceService from '@/services/audience.service'

import scheduleSection from '@/components/audience.schedule.vue'
import { ForecastImpressions } from '@ayudasystems/campaign-targeting'
import formattedNumberInput from '@/components/formattedNumberInput.vue'
import impressionsDistribution from '@/components/impressionsDistribution.vue'

import campsiteConfig from '@/config/campsite.config'
import defaultCurrencyValues from '@/services/defaultCurrencyValues'
import flags from '@/plugins/rox/flags'
import helpers from '@/services/helpers.service'

export default {
  components: {
    scheduleSection,
    ForecastImpressions,
    formattedNumberInput,
    impressionsDistribution
  },
  props: ['parent', 'isProposalLine', 'instance', 'buyerMarkup', 'isDspPartnerCampaign'],
  data () {
    return {
      canSeeCurrencySymbol: this.isDspPartnerCampaign && flags.canSeeAuctionPackageCurrencySelection.isEnabled(),
      chartInitialized: false,
      rules: {
        required: value => !!value || value === 0 || 'Required.',
        min: v => v.length >= 3 || 'Min 3 characters',
        positiveIfProvided: v => !v || v >= 0 || 'Positive numbers only',
        strictPositive: v => v > 0 || 'Strict positive numbers only'
      }
    }
  },
  filters: {
    currency (value, currencySymbol) {
      if (!value) return helpers.formatMoney(0, 2, currencySymbol)
      return helpers.formatMoney(value, 2, currencySymbol)
    }
  },
  mounted () {
    this.$root.$on('validateBudgetAndSchedule', () => {
      let field = 'budget'
      let isValid = this.$refs.budgetForm?.validate()

      if (isValid) {
        field = 'maxBid'
        isValid = this.$refs['max-bid-form']?.validate()
      }

      this.$emit('formValidationUpdated', isValid, field)
    })
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    selectedCurrency () {
      return this.canSeeCurrencySymbol
        ? defaultCurrencyValues(this.$store.getters['audience/selectedCurrency']).currencySymbolString
        : this.marketVals.currencySymbolString
    },
    markup () {
      const markup = this.buyerMarkup
        ? this.buyerMarkup
        : this.$store.getters['user/getOrganization'].markup

      return ((markup - 1) * 100).toFixed(2)
    },
    budget () {
      return this.$store.getters['createCampaign/getBudget']
    },
    maxBid () {
      return this.$store.getters['createCampaign/getMaxCpm']
    },
    isForAdServer () {
      return this.$store.getters['user/isForAdServer']
    },
    maxBidForDisplay () {
      return parseFloat((this.maxBid ?? 0).toFixed(2))
    },
    maxBidForInput () {
      if (this.maxBid === null) return
      return this.maxBidForDisplay
    },
    deals () {
      return this.$store.getters['createCampaign/deals']
    },
    usePublicExchange () {
      return this.$store.getters['audience/getUsePublicExchange']
    },
    canOverwrite () {
      return this.$store.getters['createCampaign/canOverwriteWithDeal']
    },
    forecast () {
      return this.$store.getters['audience/forecast']
    },
    isForecastLoading () {
      return this.$store.getters['audience/forecastLoading']
    },
    isForecastImpressionsAvailable () {
      if (this.hasMoment || (this.deals.length > 0 && this.isForAdServer)) return false

      return this.forecast && this.forecast.impressions && this.forecast.impressions.total !== 0
    },
    targetedImpressions () {
      return this.budget > 0 && !!this.forecast.budget ? this.forecast.budget.impressions.value : 0
    },
    totalImpressions () {
      if (!this.isForecastImpressionsAvailable) return null

      return this.forecast.impressions.value
    },
    useBudgeted () {
      return this.budget > 0
    },
    estimatedMaxBudget () {
      return audienceService.getEstimatedMaxBudget(this.forecast, this.hasMoment)
    },
    bidRangeMin () {
      return audienceService.getBidRangeLowerEnd(this.forecast, this.deals) * campsiteConfig.creatives.defaultDuration.image
    },
    bidRangeMax () {
      return audienceService.getBidRangeUpperEnd(this.forecast, this.deals, this.usePublicExchange)
    },
    bidRangeMaxForDisplay () {
      return this.bidRangeMax
        ? parseFloat((this.bidRangeMax * campsiteConfig.creatives.defaultDuration.image).toFixed(2))
        : null
    },
    bidRangeLabel () {
      if (flags.canSeeAuctionPackageCurrencySelection.isEnabled()) return 'The price range is'

      return !this.deals.length || this.usePublicExchange ? 'The effective bid range is' : 'The floor price is'
    },
    hasMoment () {
      return this.$store.getters['createCampaign/hasMoment']
    },
    canSeeImpressionDistribution () {
      const hasBudget = this.budget && parseInt(this.budget) !== 0
      const hasMaxBid = this.maxBid && parseFloat(this.maxBid) !== 0
      return hasBudget && hasMaxBid && (!this.deals.length || this.usePublicExchange) && !this.hasMoment
    },
    environments () {
      return this.useBudgeted && !!this.forecast.budget
        ? this.forecast.budget.environments
        : this.forecast.environments
    }
  },

  watch: {
    isForecastLoading (newV, oldV) {
      if (!this.chartInitialized && oldV === true && newV === false) this.chartInitialized = true
    }
  },

  methods: {
    budgetChanged: function (budget = null) {
      this.$store.commit('createCampaign/setBudget', budget)
      this.$emit('updateForecast')
    },

    bidChanged: function (maxBid) {
      this.$store.commit('createCampaign/setMaxCpm', maxBid)
      this.$store.commit('createCampaign/setCanOverwriteDealMaxBid', false)
      this.$emit('updateForecast')
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> #impressions-distribution .v-expansion-panel-header {
  min-height: 30px;
}

>>> #impressions-distribution .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
