import defaultCurrencyValues from '@/services/defaultCurrencyValues'
import accounting from 'accounting-js'

export default {
  getFloorCPMsLabel
}

function getFloorCPMsLabel (floorCPMs) {
  if (!floorCPMs) return ''
  var label = ''
  floorCPMs.forEach((floorCPM) => {
    if (label !== '') { label += ', ' }
    label += accounting.formatMoney(floorCPM.floorCPM, { symbol: defaultCurrencyValues(floorCPM.currency).currencySymbolString, precision: 2 })
    label += ' ' + floorCPM.currency
  })
  return label
}
