<template lang="pug">
v-card(transparent flat)
  v-card-text.pa-0
    v-container.py-0(fluid)
      v-row.my-0
        v-col.pb-0
          a.info--text(v-if="componentConfig.links.understandingDemographicReport"
                  :href="componentConfig.links.understandingDemographicReport" target='_blank')
            span Understanding the demographic report
            v-icon(small color='info') mdi-launch

      v-row.my-0
        v-col
          v-select.comparator-select(
            v-model='selectedComparator'
            id='comparator-select'
            :items='comparatorsList'
            item-text='name'
            return-object
            label='Compare audience data with'
            placeholder='Baseline Audience'
            flat
            dense
            style='width: 300px;'
            :menu-props='{maxHeight: 400, closeOnContentClick: true}')
            template(v-slot:item='{ item }')
              v-list-item(@click='changeCompareToReference(item)')
                v-list-item-title(v-if="!item.value.includes('audience_')") {{ item.name }}
                v-list-item-title#load-package-btn.primary--text(v-if="item.value.includes('audience_')" @click='customAudienceDialogOpen = true') LOAD {{ packageLabel }}

          loadAudience(
            :noActivator='true'
            :openFromParent='customAudienceDialogOpen'
            @pickedAudience='loadAudience'
            @closedDialog='customAudienceDialogOpen = false')
            template(slot='no-data')
              v-list-item
                v-list-item-title No results

      v-row.my-0(v-if='noData || isLoading')
        v-col
          v-row.my-0(align='center' justify='center' style='height: 50vh;')
            v-progress-circular(v-if='isLoading' :size='50' indeterminate)
            .text-h5(v-if='noData') No data available

      div(v-if='!noData && !isLoading')
        //- mode "cols"
        v-row.my-0(v-if='modeCols' v-for='(item, i) in graphData' :key='item.targetGroup')
          v-col(md=6)
            v-card(flat)
              .text-h6 {{ item.name }}
              barChart.mt-6(style='height: 320px;' :data='item.values')

          v-col(md=6)
            v-card(flat)
              .text-h6 {{ personasData[i].name }}
              personas(:details='personasData[i]' :comparedTo='selectedComparator.name')

        //- mode "rows"
        v-row.my-0(v-if='!modeCols')
          v-col(md=4 v-for='(item, i) in graphData' :key='item.targetGroup')
            v-card(flat)
              .text-h6 {{ item.name }}
              barChart.mt-3(style='height: 320px;' :data='item.values')

        v-row.mb-0.mt-3(v-if='!modeCols')
          v-col(md=4 v-for='(persona, p) in personasData' :key='p')
            v-card(flat)
              .text-h6 {{ persona.name }}
              personas(:details='persona' :comparedTo='selectedComparator.name')

    //- OLD version
    //- v-layout(row wrap justify-start)
    //-   v-flex(xs12 sm12 md4 v-for='item in graphData' :key='item.targetGroup')
    //-     .text-h6 {{ item.name }}
    //-     barChart.mt-3(style='height: 320px;' :data='item.values')

    //- v-layout.mt-3(row wrap justify-start)
    //-   v-flex(xs12 sm12 md4)
    //-     .text-h6 Interest &amp; Activity
    //-     personas(:details="{ categoryName: 'Interests and Activity', data: personasData.find(x => x.name === 'Interest & Activity').values }" :comparedTo='selectedComparator.name')
    //-   v-flex(xs12 sm12 md4)
    //-     .text-h6 Life Stage
    //-     personas(:details="{ categoryName: 'Life Stage', data: personasData.find(x => x.name === 'Life Stage').values }" :comparedTo='selectedComparator.name')
    //-   v-flex(xs12 sm12 md4)
    //-     .text-h6 Intent
    //-     personas(:details="{ categoryName: 'Intent', data: personasData.find(x => x.name === 'Intent').values }" :comparedTo='selectedComparator.name')

  .text-caption.text-right.my-2.pr-4(v-if='!noData && processDate') This demographic report was last processed on {{ processDate | date('YYYY-MM-DD H:m') }}
</template>

<script>
import audienceApi from '@/services/audience.api'
import geoService from '@/services/geo.service'
import helpersService from '@/services/helpers.service'
import componentConfigService from '@/services/componentConfig'
import defaultExchangeValues from '@/services/defaultExchangeValues'

import pieChart from '@/components/chartPie.vue'
import barChart from '@/components/chartBar.vue'
import personas from '@/components/demographicsPersonas.vue'
import loadAudience from '@/components/loadAudience.vue'

const comparatorLabels = {
  indoor: 'Indoor',
  'united-state': 'US Exchange'
}

export default {
  components: {
    pieChart,
    barChart,
    personas,
    loadAudience
  },
  props: ['compareTo', 'startDate', 'endDate', 'processDate', 'mode'],
  data () {
    return {
      isLoading: true,
      noData: false,

      countriesAndStates: [],

      selectedComparator: {
        name: '∆ Broadsign Ads AVG',
        value: 'campsite'
      },

      customAudienceDialogOpen: false,
      graphData: null,
      personasData: [
        { name: 'Interest & Activity', values: [] },
        { name: 'Life Stage', values: [] },
        { name: 'Intent', values: [] }
      ],
      packageLabel: 'AUDIENCE'
    }
  },
  created: function () {
    this.getCountriesAndStates()
    this.getDemographicData()
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
    if (this.canSeeNewUi) {
      this.packageLabel = 'PACKAGE'
    }
  },
  computed: {
    modeCols () {
      return this.mode === 'cols' && this.$vuetify.breakpoint.mdAndUp
    },
    countryId () {
      if (this.$store.getters['general/isOnAuctionPackagePage']) {
        return this.getDefaultCountryIdForExchange(this.$store.getters['general/currentPageExchange'])
      }

      return this.$store.getters['user/getOrganization'].address.country.id
    },
    exchanges () {
      return this.$store.getters['general/getExchanges']
    },
    comparatorListExchanges () {
      return this.exchanges.map(ex => {
        return {
          name: '∆ ' + comparatorLabels[ex.key] + ' AVG',
          value: 'exchange_' + ex.key
        }
      })
    },
    comparatorListEnvironments () {
      const environments = []

      this.exchanges.map(ex => {
        ex.environments.sort(helpersService.sortByName).map(env => {
          environments.push({
            name: '∆ ' + env.name + ' AVG',
            value: 'environment_' + env.value
          })
        })
      })

      return environments
    },
    comparatorListStates () {
      return this.countriesAndStates.length
        ? this.countriesAndStates
          .find(country => country.id === this.countryId)
          .states.map(s => {
            return {
              name: '∆ ' + s.name + ' AVG',
              value: 'state_' + s.key
            }
          })
        : []
    },
    comparatorsList () {
      return [
        { header: 'Broadsign Ads' },
        {
          name: '∆ Broadsign Ads AVG',
          value: 'campsite'
        },

        { divider: true },
        { header: 'Exchange' },
        ...this.comparatorListExchanges,

        { divider: true },
        { header: 'Environment' },
        ...this.comparatorListEnvironments,

        { divider: true },
        { header: 'Regions' },
        ...this.comparatorListStates,

        { divider: true },
        { header: 'Custom Audience' },
        {
          name: 'Custom Audience',
          value: 'audience_'
        }
      ]
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    getCountriesAndStates () {
      geoService.getCountriesAndStates().then(res => {
        this.countriesAndStates = res
      })
    },
    getDemographicData () {
      this.isLoading = true
      const params = {
        compareFrom: this.selectedComparator.value,
        compareTo: this.compareTo
      }

      var dmpApiMethod
      if (!this.startDate && !this.endDate) {
        dmpApiMethod = audienceApi.getDmpHistorical
      } else {
        params.startDate = this.startDate.slice(0, 10)
        params.endDate = this.endDate.slice(0, 10)
        dmpApiMethod = audienceApi.getDmpDistribution
      }

      dmpApiMethod(params).then(res => {
        // received format ex: [{targetGroup: "Age", targetGroups: null, targets: [{percentageFromMean: -0.124, target: : "18-24"}] }]

        if (!res.length) { this.noData = true } else {
          this.noData = false
          const formattedData = this.assembleGraphConfigs(res)
          this.graphData = formattedData.filter(x => ['Gender', 'Age', 'Income'].includes(x.name))
          this.personasData = formattedData.filter(x => !['Gender', 'Age', 'Income'].includes(x.name))
        }
        this.isLoading = false
      })
    },
    assembleGraphConfigs (list) {
      return list.map(seg => {
        if (!seg.targetGroups) {
          const dict = {
            name: seg.targetGroup,
            values: seg.targets.map(x => { return { target: x.target, percentageFromMean: (x.percentageFromMean * 100).toFixed(1) } })
          }
          return dict
        } else {
          const dict = {
            name: seg.targetGroup,
            values: seg.targetGroups
              .map(group => group.targets)
              .flat()
              .map(x => {
                return {
                  target: x.target,
                  percentageFromMean: (x.percentageFromMean * 100).toFixed(1)
                }
              })
              .filter(x => x.percentageFromMean < 500)
          }
          return dict
        }
      })
    },
    loadAudience (data) {
      var customAudienceComparator = this.comparatorsList.find(x => x.value && x.value.includes('audience_'))
      customAudienceComparator.value = 'audience_' + data.id
      customAudienceComparator.name = data.name
      this.selectedComparator = customAudienceComparator
      this.getDemographicData()
    },
    changeCompareToReference (item) {
      if (!item.value.includes('audience_')) {
        this.selectedComparator = item
        this.getDemographicData()
      }
    },
    getDefaultCountryIdForExchange (exchange) {
      return defaultExchangeValues.getAllDefaultValues().find(x => x.market === exchange.key).countryId
    }
  }
}
</script>
