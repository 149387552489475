import axios from 'axios'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  fetchGeoJSON,
  fetchGeoJSONFromURL,
  fetchGeoJSONInBoundingBox
}

function fetchGeoJSON (id, cancelToken = null) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/geographies/' + id + '/geojson', { cancelToken: cancelToken })
    .then(res => JSON.stringify(res.data))
}

function fetchGeoJSONFromURL (URL, cancelToken = null) {
  return axios.get(URL, { cancelToken: cancelToken }).then(res => JSON.stringify(res.data))
}

function fetchGeoJSONInBoundingBox (content, cancelToken = null) {
  return axios.post(VUE_APP_CAMPSITE_API_URL + '/boundingbox', content, { cancelToken: cancelToken })
    .then(async (res) => res.data)
}
