<script>
import Vue from 'vue'
// import Chart from 'chart.js'
import CustomLine from '@/plugins/chartjs/customLine'
import customTooltip from '@/components/reportingMetricsChartTooltip.vue'

import helpers from '@/services/helpers.service'

export default {
  components: { customTooltip },
  extends: CustomLine,
  props: ['data', 'dateFormat'],
  data: () => {
    return {
      tooltip: null,
      tooltipInfo: {
        date: '',
        metrics: []
      },
      gradientFill: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        animation: {
          duration: 0 // general animation time
        },
        hover: {
          mode: 'index',
          intersect: 'false'
        },
        legend: { display: false },
        scales: {
          xAxes: [{ gridLines: { display: false }, ticks: { display: false } }],
          yAxes: [
            {
              id: 'mainMetrics',
              position: 'right',
              gridLines: {
                display: false
              },
              ticks: {
                beginAtZero: true,
                display: false,
                fontColor: 'rgba(0,0,0, 0.2)',
                labelOffset: 12,
                lineHeight: 2.7,
                mirror: true,
                padding: -8
              },
              afterTickToLabelConversion: function (scaleInstance) {
                // set the first and last tick to null so it does not display
                // note, ticks[0] is the last tick and ticks[length - 1] is the first
                scaleInstance.ticks[scaleInstance.ticks.length - 1] = ''
              }
            },
            {
              id: 'backgroundMetric',
              gridLines: {
                drawTicks: false,
                display: true
              },
              ticks: {
                beginAtZero: true,
                callback: null,
                display: true,
                fontColor: 'rgba(0,0,0, 0.2)',
                labelOffset: 12,
                lineHeight: 2.7,
                mirror: true,
                padding: -8
              },
              afterTickToLabelConversion: function (scaleInstance) {
                // set the first and last tick to null so it does not display
                // note, ticks[0] is the last tick and ticks[length - 1] is the first
                scaleInstance.ticks[scaleInstance.ticks.length - 1] = ''
              }
            }
          ]
        },
        tooltips: {
          intersect: false,
          mode: 'index',
          enabled: false,
          custom: null
        },
        layout: {
          padding: {
            left: 0,
            right: -10,
            top: 0,
            bottom: -9
          }
        }
      }
    }
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    }
  },
  mounted () {
    const chartData = {
      labels: this.data.dates,
      datasets: this.data.metrics.map(metric => this.lineChartDetails(metric))
    }
    this.options.tooltips.custom = this.drawCustomTooltip
    this.options.scales.yAxes[1].ticks.callback = this.formatTicks
    this.renderChart(chartData, this.options)
  },
  destroyed () {
    if (this.tooltip) {
      this.tooltip.$destroy()
    }
  },
  methods: {
    lineChartDetails (metric) {
      const obj = {
        borderColor: metric.color,
        lineTension: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        data: metric.data,
        label: metric.key
      }
      if (metric.isBackground) {
        this.createGradient(metric.color)
        obj.backgroundColor = this.gradientFill
        obj.borderWidth = 0.1
        obj.yAxisID = 'backgroundMetric'
      } else {
        const lineThickness = metric.data.length >= 150 ? 1 : 3
        obj.borderWidth = lineThickness
        obj.pointRadius = metric.data.length >= 150 ? 0 : 1.5
        obj.fill = false
        obj.yAxisID = 'mainMetrics'
      }
      return obj
    },
    createGradient (color) {
      this.gradientFill = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 170)
      this.gradientFill.addColorStop(0, helpers.hexToRgba(color, 0.95))
      this.gradientFill.addColorStop(1, helpers.hexToRgba(color, 0.50))
    },
    updateTooltipInfo (index) {
      return {
        date: this.data.dates[index],
        metrics: this.data.metrics.map(x => {
          return {
            key: x.key,
            name: x.name,
            color: x.color,
            value: helpers.dataDisplayFormatting(x.data[index], x.type, this.marketVals.currencySymbolString)
          }
        })
      }
    },
    drawCustomTooltip (tooltipModel) {
      if (tooltipModel.dataPoints && tooltipModel.dataPoints.length) {
        // update Tooltip data
        const newInfo = this.updateTooltipInfo(tooltipModel.dataPoints[0].index)
        this.tooltipInfo.date = newInfo.date
        this.tooltipInfo.metrics = newInfo.metrics
      }

      const tooltipWidth = 250

      // Create element on first render
      if (!this.tooltip) {
        const textColor = this.$vuetify.theme.themes.light.secondary

        var TooltipClass = Vue.extend(customTooltip)
        this.tooltip = new TooltipClass({
          propsData: {
            info: this.tooltipInfo,
            dateFormat: this.dateFormat
          },
          data () {
            return { textColor, width: tooltipWidth }
          }
        })
        this.tooltip.$mount()

        // append to (relative) "chart-wrapper" for absolute positioning
        const chartWrapperId = 'wrapper-' + this.chartId
        var chartWrapper = document.getElementById(chartWrapperId)
        if (chartWrapper) {
          chartWrapper.appendChild(this.tooltip.$el)
        }
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        this.tooltip.$el.style.opacity = 0
        return
      }

      // Display, position, and set styles for font
      this.tooltip.$el.style.opacity = 0.95
      this.tooltip.$el.style.zIndex = 2
      this.tooltip.$el.style.position = 'absolute'
      this.tooltip.$el.style.top = '0px'
      this.tooltip.$el.style.pointerEvents = 'none'

      const canvas = this.$refs.canvas.getBoundingClientRect()
      const canvasWidth = canvas.width
      const position = tooltipModel.caretX
      const padding = 8

      this.tooltip.$el.style.left = position + tooltipWidth + padding < canvasWidth
        ? (position + padding) + 'px'
        : (position - tooltipWidth - padding) + 'px'
    },

    formatTicks (value) {
      let valueShort = value
      let suffix = ''

      if (value > 1000) {
        valueShort = (value / 1000).toFixed(0)
        suffix = 'K'
      }

      if (value > 1000000) {
        valueShort = (value / 1000000).toFixed(1)
        suffix = 'M'
      }

      return this.marketVals.currencySymbolString + valueShort + suffix
    }
  }
}
</script>
<style scoped lang="stylus">
canvas
  width: 100% !important
</style>
