<template lang="pug">
  div()
    v-container(v-if='isLoading' style='min-height:240px;')
      v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
        v-col.pt-0.pb-0(cols='12' xl='8' offset-xl='2')
          v-skeleton-loader.mt-1.mb-3(type='image' height=42 width='80%')
          v-skeleton-loader.mt-2(type='text' width='20%')
          v-skeleton-loader.mt-2.mb-1.d-flex.mr-2(type='chip@3')
        v-col.pt-2.pb-0(cols='12' lg='5')
      v-row.mb-0.mt-2()
        v-col(cols='12' sm='6' lg='2' xl='2' offset-xl='2')
          v-skeleton-loader(type='image' height=60)
        v-col(cols='12' sm='6' lg='2' xl='2')
          v-skeleton-loader(type='image' height=60)
        v-col(cols='6' sm='6' lg='2' xl='1')
          v-skeleton-loader(type='image' height=60)
        v-col(cols='6' sm='6' lg='2' xl='1')
          v-skeleton-loader(type='image' height=60)
        v-col(cols='6' sm='6' lg='2' xl='1')
          v-skeleton-loader(type='image' height=60)
        v-col(cols='6' sm='6' lg='2' xl='1')
          v-skeleton-loader(type='image' height=60)
    v-container(v-if='!isLoading')
      v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
        v-col.pb-0(cols='12' xl='8' offset-xl='2')
          .text-overline
          .text-h4 {{ creativeName }}
          .text-caption.text--secondary
            | {{ creativeDetails.fileName }}
          v-toolbar.my-2.pl-0(v-if='can.update.default' flat dense color='transparent' style='border-top: 1px solid rgba(0,0,0,.12) !important; border-bottom: 1px solid rgba(0,0,0,.12) !important;')
            v-chip.ma-1.text-uppercase.xs-small.mr-3(
              :color='statusColor()' small outlined
              v-if="!can.update.adQualityStatus && creativeDetails.status === 'Active' && creativeDetails.adQualityStatus !== 'Approved'"
              )
              v-icon.mr-1.ml-0(:color='statusColor()' small) mdi-circle
              small(class='text--primary') {{ statusLabel() }}
            div.d-flex.align-center(v-if="can.update.adQualityStatus && creativeDetails && creativeDetails.status === 'Active'")
              v-select(label='Status' v-model='updatedStatus' dense single-line rounded  hide-details :items='statusOptions' style='max-width:190px;')
                template(slot='prepend-inner')
                  v-icon.mr-2(color='success' v-if="updatedStatus === 'Approved'") mdi-circle
                  v-icon.mr-2(color='warning' v-if="updatedStatus === 'Pending'") mdi-circle
                  v-icon.mr-2(color='error' v-if="updatedStatus === 'Rejected'") mdi-circle

              v-btn.ml-2(small color='primary' rounded v-if='creativeDetails.adQualityStatus !== updatedStatus' @click='save()')
                | Save

            v-dialog(v-model='editDialog' max-width='700' v-if='creativeDetails' :fullscreen='$vuetify.breakpoint.smAndDown')
              template(v-slot:activator='{on}')
                v-btn.ma-0.ml-2.edit-creative-open-btn(
                  v-if='can.update.default'
                  color='grey'
                  text
                  :icon="can.update.adQualityStatus || creativeDetails.adQualityStatus !== 'Approved'"
                  v-on='on'
                  @click='openModal'
                )
                  v-icon.edit(style='transform: inherit;') mdi-pencil
                  span.ml-2(v-if="!can.update.adQualityStatus && creativeDetails.adQualityStatus === 'Approved'") Edit information
              v-card(flat)
                v-card-title
                  .text-h5 Edit Creative Information
                  v-spacer
                  v-btn(small text icon  @click='closeModal()')
                    v-icon(color='grey') mdi-close
                v-card-text
                  v-container(grid-list-lg)
                    v-layout.mb-3(row wrap)
                      v-flex(sm12 md6)
                        v-text-field.edit-creative-name-input(
                          autofocus
                          label='Creative Name'
                          v-model='editForm.name'
                          persistent-hint
                          :hint='creativeDetails.fileName'
                          required
                          :rules="[v => !!v || 'Name is required']"
                        )
                      v-flex(sm6 md3 v-if='can.update.language')
                        v-combobox(v-model='editForm.language' :items='availableLanguages' label='Language' text placeholder='Select Creative Language')
                      v-flex(sm6 md3)
                          v-text-field.edit-creative-externalId-input(
                          label='External Id'
                          v-model='editForm.externalId'
                        )
                    v-layout(row wrap v-if='can.update.industry')
                      v-flex(sm12 md6)
                        v-text-field(disabled v-model='editForm.advertiser' label='Advertiser' text dense)
                          template(slot='no-data')
                            v-list-item
                              v-list-item-title This advertiser will be created
                      v-flex.pl-2(sm12 md6)
                        v-autocomplete(v-model='editForm.industry' :items="$store.getters['general/getIndustries']" item-text='name' label='Industry' text dense :menu-props='{maxHeight: 400}' :rules="[v => !!v || 'Industry is required']" required)
                          template(slot='no-data')
                            v-list-item
                              v-list-item-title No industry found
                v-divider
                v-card-actions
                  v-spacer
                  v-btn.edit-creative-close-btn(color='secondary' text @click='closeModal()') Close
                  v-btn.edit-creative-save-btn(color='primary' text :disabled='!isEditFormCompleted' @click='saveModalEdits()' :loading='editModalSaveBtnLoading') Save

      v-row.my-0(:dense='$vuetify.breakpoint.xsOnly' v-if="updatedStatus === 'Rejected'")
        v-col(cols='12' xl='8' offset-xl='2')
          //- If creative rejected, rejection reason box
          v-textarea(name='dada' auto-grow label='Reason' v-model='rejectionReason')
          v-alert.mb-3(:value='true' color='error' icon='mdi-alert' small outlined v-if="creativeDetails && creativeDetails.status === 'Active' && creativeDetails.adQualityStatus === 'Rejected'")
            .text-body-2.font-weight-bold Creative Rejected
            .text-body-1(class='text--primary') {{ creativeDetails.adQualityRejectionReason }}

      v-row.my-0
        v-col(cols='12' xl='8' offset-xl='2')
          v-row.my-0(:dense='$vuetify.breakpoint.xsOnly' justify='space-between')
            v-col(cols='12' sm='6' lg='auto')
              v-card#creative-advertiser-label(flat height='100%' style='background-color:transparent;')
                .text-overline Advertiser
                .font-weight-regular {{creativeDetails.advertiser.name ? creativeDetails.advertiser.name : 'N/A' }}
            v-col(cols='12' sm='6' lg='auto')
              v-card#creative-industry-label(flat height='100%' style='background-color:transparent;')
                .text-overline Industry
                .font-weight-regular {{ creativeDetails.industry }}
            v-col(cols='6' sm='6' lg='auto')
              v-card#creative-type-label(flat height='100%' style='background-color:transparent;')
                .text-overline Type
                .font-weight-regular {{ creativeDetails.type }}
            v-col(cols='6' sm='6' lg='auto')
              v-card#creative-size-label(flat height='100%' style='background-color:transparent;')
                .text-overline Size
                .font-weight-regular {{ creativeSizeLabel() }}
            v-col(cols='6' sm='6' lg='auto')
              v-card#creative-language-label(flat height='100%' style='background-color:transparent;')
                .text-overline Language
                .font-weight-regular {{ creativeDetails.language ? creativeDetails.language : 'N/A' }}
            v-col(cols='6' sm='6' lg='auto')
              v-card#creative-duration-label(flat height='100%' style='background-color:transparent;')
                .text-overline Duration
                .font-weight-regular {{ creativeDurationLabel() }}
            v-col(cols='6' sm='6' lg='auto')
              v-card#creative-external-id-label(flat height='100%' style='background-color:transparent;')
                .text-overline External Id
                .font-weight-regular {{ creativeExternalIdLabel() }}

    v-container(grid-list-lg v-if='!isLoading && creativeDetails.creativeURL')
      v-layout(row wrap)
          v-flex(xs12 style='text-align: center;')
            video(
              id='modalVideoPlayer2'
              ref='modalVideoPlayer2'
              v-if='creativeDetails && creativeDetails.type === "Video"'
              :src='creativeDetails.creativeURL'
              controls
              style='max-height:600px; max-width:100%;'
              loop
            ) Your browser does not support the video tag.
            v-img(:src='creativeDetails.creativeURL' v-if="creativeDetails && creativeDetails.type === 'Image'" max-height='600' contain :max-height='creativeHeight')
              v-layout(slot='placeholder' fill-height align-center justify-center ma-0)
                v-progress-circular(indeterminate color='grey lighten-5')
</template>

<style lang="stylus" scoped>
>>> .v-toolbar__content {
  padding-left: 0px;
}
>>> .v-skeleton-loader__chip {
  margin-right: 4px;
}
>>> .v-skeleton-loader__chip {
  margin-right: 6px;
}
</style>

<script>
import creativesApi from '@/services/creatives.api'
import userApi from '@/services/user.api'

export default {
  created: function () {
    this.isPanelExpanded = 0

    creativesApi.getCreative(this.$route.params.creativeId).then((res) => {
      this.creativeDetails = Object.assign({}, res)
      this.updatedStatus = res.adQualityStatus
      this.rejectionReason = res.adQualityRejectionReason
      this.isLoading = false
    })
      .catch((e) => {
        this.$router.push({ name: 'Access Denied' })
      })
  },
  computed: {
    can () {
      const permissions = this.$store.getters['user/permissions']('creative')
      return permissions
    },
    isEditFormCompleted () {
      if (this.can.update.industry) {
        return this.editForm.name.length && this.editForm.industry
      } else {
        return this.editForm.name.length
      }
    },
    creativeHeight () {
      return this.creativeDetails ? this.creativeDetails.geometry.height : 0
    },
    creativeName () {
      return this.creativeDetails && this.creativeDetails.name ? this.creativeDetails.name : 'Creative'
    }
  },
  data () {
    return {
      isPanelExpanded: 1,
      creativeDetails: null,
      statusOptions: ['Approved', 'Pending', 'Rejected'],
      updatedStatus: '',
      rejectionReason: '',
      isLoading: true,
      editDialog: false,
      availableLanguages: ['English', 'French'],
      editForm: {
        name: '',
        advertiser: '',
        industry: '',
        language: '',
        externalId: ''
      },
      editModalSaveBtnLoading: false
    }
  },
  methods: {
    statusColor () {
      if (this.creativeDetails && this.creativeDetails.status === 'Archived') {
        return 'grey'
      } else {
        switch (this.creativeDetails.adQualityStatus) {
          case 'Approved': return 'success'
          case 'Pending': return 'warning'
          case 'Rejected': return 'error'
        }
      }
    },

    panelExpandCtrl () {
      this.isPanelExpanded === 0 ? this.isPanelExpanded = null : this.isPanelExpanded = 0
    },

    statusLabel () {
      if (this.creativeDetails && this.creativeDetails.status === 'Archived') {
        return 'Archived'
      } else {
        return this.creativeDetails.adQualityStatus === 'Pending' ? 'To Be Reviewed' : this.creativeDetails.adQualityStatus
      }
    },

    creativeSizeLabel () {
      let sizeLabel = 'N/A'
      if (this.creativeDetails.creativeFormat) {
        var { minWidth, minHeight } = this.creativeDetails.creativeFormat
        if (minWidth && minHeight) {
          sizeLabel = minWidth + ' x ' + minHeight
        }
      }
      return sizeLabel
    },

    creativeDurationLabel () {
      return this.creativeDetails.type === 'Video'
        ? Math.round(this.creativeDetails.duration || 0).toString() + ' seconds'
        : 'N/A'
    },

    creativeExternalIdLabel () {
      return this.creativeDetails.externalId !== null
        ? this.creativeDetails.externalId : 'N/A'
    },

    save () {
      if (this.updatedStatus === 'Rejected' && !this.rejectionReason.length) { return }

      const creativeCopy = Object.assign({}, this.creativeDetails)
      creativeCopy.adQualityStatus = this.updatedStatus
      if (this.updatedStatus === 'Rejected' && this.rejectionReason.length) {
        creativeCopy.adQualityRejectionReason = this.rejectionReason
      }
      creativesApi.editCreative(creativeCopy)
        .then(resp => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: 'Creative status has been updated'
          })
          this.creativeDetails.adQualityStatus = resp.adQualityStatus
          this.creativeDetails.adQualityRejectionReason = resp.adQualityRejectionReason
        })
        .catch(error => {
          this.handleCreativeError(error)
        })
    },

    // Edit modal related
    openModal () {
      if (this.creativeDetails) {
        this.editForm.name = this.creativeDetails.name
        this.editForm.language = this.languageFormatting(this.creativeDetails.language, 'full')
        this.editForm.advertiser = this.creativeDetails.advertiser.name ? this.creativeDetails.advertiser.name : 'N/A'
        this.editForm.industry = this.creativeDetails.industry
        this.editForm.externalId = this.creativeDetails.externalId
      }
    },

    prefillIndustry () {
      if (this.editForm.advertiser && this.editForm.advertiser.advertiserId) {
        userApi.getOrganization(this.editForm.advertiser.advertiserId).then(res => {
          const filteredIndustry = this.$store.getters['general/getIndustries'].find(x => x.name === res.industry)
          if (filteredIndustry) {
            this.$set(this.editForm, 'industry', filteredIndustry)
          }
        })
      } else {
        this.$set(this.editForm, 'industry', null)
      }
    },

    languageFormatting (lang, direction) {
      if (direction === 'min') {
        switch (lang) {
          case 'English': return 'EN'
          case 'French': return 'FR'
        }
      }
      if (direction === 'full') {
        switch (lang) {
          case 'EN': return 'English'
          case 'FR': return 'French'
        }
      }
    },

    closeModal () {
      this.editDialog = false
    },

    saveModalEdits () {
      this.editModalSaveBtnLoading = true
      const updatedInfos = {}
      updatedInfos.id = this.creativeDetails.id
      updatedInfos.name = this.editForm.name
      updatedInfos.industry = this.editForm.industry
      updatedInfos.externalId = this.editForm.externalId || null
      updatedInfos.language = this.languageFormatting(this.editForm.language, 'min')
      creativesApi.editCreative(updatedInfos)
        .then(resp => {
          this.editModalSaveBtnLoading = false
          this.editDialog = false
          this.creativeDetails = resp

          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: 'Creative has been updated'
          })
        })
        .catch(error => {
          this.editModalSaveBtnLoading = false
          this.handleCreativeError(error)
        })
    },

    handleCreativeError (error) {
      let msg = 'Something went wrong while updating creative'

      if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.length && error.response.data.errors[0].message) {
        msg = error.response.data.errors[0].message
      }

      this.$store.commit('snackbar/setSnackbar', {
        type: 'error',
        msg: `${msg}`
      })
    }
  }
}
</script>
