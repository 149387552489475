<template lang="pug">
v-card
  .pa-4
    .body-1.font-weight-bold Report Schedule
    .caption.grey--text.text--darken-2.mb-4 Select a frequency to schedule the report
    v-select.frequency-select.pt-0(
      v-model='frequency'
      :items='frequencyOptions'
      item-text='label'
      label='Frequency'
      return-object
      )

    div.week-days(v-if='showDaysOfTheWeek')
      .body-2.mt-2 Day of the week
      v-chip-group(v-model='selectedDays' active-class='primary' column multiple mandatory)
        v-chip.mr-1(v-for='day in weekDays' :key='day.key' small) {{ day.label }}

    div.date-range(v-if='showDateRange')
      .body-2.mt-2 Period
      v-row.my-0
        v-col.pt-1.pb-2(cols='6')
          v-menu.d-flex(
            v-model='isStartDatePickerOpen'
            :close-on-content-click='false'
            min-width='290px'
            transition='scale-transition'
            offset-y
            )
            template(v-slot:activator='{ on, attrs }')
              v-text-field#period-start-date-picker(
                :value='startDate'
                v-on='on'
                v-bind='attrs'
                label='Start Date'
                readonly
                prepend-inner-icon='mdi-calendar'
                )
            v-date-picker(
              v-model='startDate'
              @input='updateStartDate'
              :min='minStartDate'
              color='primary'
              no-title
              )
        v-col.pt-1.pb-2(cols='6')
          v-menu.d-flex(
            v-model='isEndDatePickerOpen'
            :close-on-content-click='false'
            min-width='290px'
            transition='scale-transition'
            offset-y
            )
            template(v-slot:activator='{ on, attrs }')
              v-text-field#period-end-date-picker(
                v-model='endDate'
                v-on='on'
                v-bind='attrs'
                label='End Date'
                readonly
                clearable
                prepend-inner-icon='mdi-calendar'
                persistent-hint
                hint='optional'
                )
            v-date-picker(
              v-model='endDate'
              @input='isEndDatePickerOpen = false'
              :min='startDate'
              color='primary'
              no-title
              )

    .date-range-summary.caption.grey--text.text--darken-2(v-if='showDateRange')
      | The report will be generated
      strong  {{ frequencyReadableSentence }}
      |  starting on
      strong  {{ displayedStartDate | date('MMM Do, YYYY') }}
      | {{ !endDate ? '.' : '' }}
      span(v-if='endDate')
        |  and ending on
        strong  {{ endDate | date }}.
</template>

<script>
import moment from 'moment'
import reportsService from '@/services/reports'

export default {
  props: ['schedule'],
  data () {
    return {
      frequency: null,
      weekDays: reportsService.getItems('weekDays'),
      selectedDays: [],
      startDate: null,
      minStartDate: null,
      isStartDatePickerOpen: false,
      endDate: null,
      isEndDatePickerOpen: false,
      creating: true
    }
  },
  computed: {
    frequencyOptions () {
      return reportsService.getItems('scheduleFrequency')
    },
    showDaysOfTheWeek () {
      return this.frequency.key === 'Weekly'
    },
    showDateRange () {
      return this.frequency.key !== 'OnDemand'
    },
    frequencyReadableSentence () {
      if (this.frequency.key === 'Daily') return 'everyday'
      if (this.frequency.key === 'Monthly') {
        return 'on the first day of every month'
      }
      if (this.frequency.key === 'Weekly') {
        if (!this.selectedDays.length) return 'every Monday'
        else if (this.selectedDays.length === 7) return 'everyday'
        else return `every ${this.selectedDays.map(d => this.weekDays[d].full).join(', ')}`
      } else return ''
    },
    displayedStartDate () {
      let dateToDisplay = this.startDate
      if (this.frequency.key === 'Monthly') {
        const firstDayOfMonth = moment(this.startDate).startOf('month')
        if (firstDayOfMonth.isBefore(this.startDate)) {
          const firstDayOfNextMonth = moment(this.startDate).add(1, 'months').startOf('month')
          dateToDisplay = firstDayOfNextMonth.format('YYYY-MM-DD')
        }
      }
      return dateToDisplay
    },
    scheduleObj () {
      const obj = {
        frequency: this.frequency,
        pickedDaysOfTheWeek: this.selectedDays.map(d => this.weekDays[d]),
        startDate: this.startDate,
        endDate: this.endDate
      }
      return obj
    }
  },
  created () {
    if (this.schedule) {
      this.frequency = this.schedule.frequency
      this.startDate = this.schedule.startDate
      this.endDate = this.schedule.endDate
      this.selectedDays = this.schedule.pickedDaysOfTheWeek.map(d => this.weekDays.findIndex(w => w.key === d.key))
    } else {
      const defaultSchedule = reportsService.getItems('schedule')
      this.frequency = defaultSchedule.frequency
      this.startDate = defaultSchedule.startDate
      this.endDate = defaultSchedule.endDate
      this.selectedDays = defaultSchedule.pickedDaysOfTheWeek
    }
  },
  watch: {
    scheduleObj (newV, oldVal) {
      if (!this.creating) {
        this.$emit('scheduleChanged', newV)
      } else {
        this.creating = false
      }
    }
  },
  methods: {
    updateStartDate (val) {
      this.isStartDatePickerOpen = false
      if (moment(val).isAfter(this.endDate)) {
        this.endDate = null
        this.isEndDatePickerOpen = true
      }
    }
  }
}
</script>
