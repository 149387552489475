<template lang="pug">
div
  .caption.no-recipients(v-if='!recipients.length') None
  .caption.recipient(v-else v-for='(email, i) in recipients' :key='i') {{ email }}
</template>

<script>
import reportsService from '../services/reports'

export default {
  props: ['report'],
  computed: {
    recipients () {
      return reportsService.decodeSchedule(this.report).emails
    }
  }
}
</script>
