<template lang="pug">
v-dialog(v-model='dialog' max-width='600px' :fullscreen='$vuetify.breakpoint.smAndDown')
  template(v-slot:activator='{ on }')

    v-btn(v-if='user' :small='$vuetify.breakpoint.mdAndUp' icon v-on='on')
      v-icon(small) mdi-pencil

    v-btn.mt-0.add-user-btn(v-if='!user' large rounded color='primary' v-on='on' @click='addUserClicked')
      v-icon(left) mdi-plus
      span Add User

  userProfile(:userToBeEdited='user' :organizationId='organizationId' @userUpdated='updatedUser' :isDialogOpen="dialog")
</template>

<script>
import userProfile from '@/components/userProfile.vue'
import tracking from '@/services/tracking'

export default {
  components: {
    userProfile
  },
  props: ['user', 'organizationId', 'userUpdated'],
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    addUserClicked () {
      tracking.sendEvent(['ga'], 'openedInviteUser')
    },
    updatedUser (user) {
      this.$emit('userUpdated', user)
      this.dialog = false
    }
  }
}
</script>
