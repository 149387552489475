<template lang="pug">
  v-layout.py-0(row wrap)
    v-flex.pb-0.pt-3(xs12 lg3 xl4 text-xl-right)
      .text-body-1.font-weight-bold Public Exchange
    v-flex(xs12 lg9 xl8 text-md-left)
      v-switch.public-exchange-switch.mt-0(v-model='state')
      .text-caption.text--disabled.public-exchange-tooltip The Broadsign Public Exchange is an ever growing collection of deals made available for all buyers. If the same screen is also available by one of the configured private deals below, the private deal will be automatically prioritized.
</template>
<script>

export default {
  props: {
    initState: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      state: this.initState
    }
  },
  mounted () {
    this.$store.commit('audience/setUsePublicExchange', this.initState)
  },
  watch: {
    state (newState) {
      this.$store.commit('audience/setUsePublicExchange', newState)
      this.$emit('toggled')
    }
  }
}
</script>
