import subdomainService from '@/services/subdomain.service'

let isIntercomBooted = false

function getAppId (baseUrl) {
  if (subdomainService.isAdServerDomain()) {
    return baseUrl === 'https://campaigns.buy.broadsign.com'
      ? 'v4mgoepq'
      : 'xjllo2fx'
  }

  return baseUrl === 'https://buy.broadsign.com'
    ? 'qc0w1531'
    : 'ye39jnzx'
}

function assembleContactInfo (appId, trackingData) {
  if (!trackingData || !appId) return null

  const contactInfo = {
    app_id: appId,
    email: trackingData.userEmail
  }

  if (!subdomainService.isAdServerDomain()) {
    Object.assign(contactInfo, {
      'Organization name': trackingData.organizationName,
      'Organization status': trackingData.organizationPaymentStatus,
      'Campsite user roles': trackingData.userRoles,
      'Active exchange': trackingData.currencyCode,
      'Organization payment method': trackingData.organizationPaymentType,
      'First name': trackingData.firstName,
      'Last name': trackingData.lastName,
      'Accept Marketing Communication': trackingData.acceptMarketingCommunication
    })
  }

  return contactInfo
}

function bootWidget (trackingData) {
  if (!isIntercomBooted) {
    const info = assembleContactInfo(getAppId(window.location.origin), trackingData)
    if (info) {
      window.Intercom('boot', info)
      isIntercomBooted = true
    }
  }
}

function bootWidgetAnonymous () {
  if (!isIntercomBooted) {
    const info = {
      app_id: getAppId(window.location.origin)
    }
    if (info.app_id) {
      window.Intercom('boot', info)
      isIntercomBooted = true
    }
  }
}

function update () {
  if (isIntercomBooted) {
    window.Intercom('update')
  }
}

export default {
  bootWidget,
  bootWidgetAnonymous,
  assembleContactInfo,
  update,
  getAppId
}
