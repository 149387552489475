<template lang="pug">
  v-card(:min-width='modalMinWidth' class='v-date-range__menu-content' max-width=650 :style="`width: ${hideShortcuts ? '480px' : 'unset'};`")
    v-card-text
      div(:data-days='highlightDates.length' :class="{'v-date-range__pickers': true, 'v-date-range--highlighted': highlightDates.length}")
        v-layout(row wrap class='v-date-range__content')
          v-flex.hidden-xs-only(xs12 sm3 order-sm1 v-if="!hideShortcuts")
            div(v-for='item in calendarShortcuts')
              v-btn.ma-0(text right color='primary' :disabled='disableShortcutItem(item)' @click='applyShortcut(item)') {{ item }}

          v-flex(xs12 :sm9="!hideShortcuts" order-xs2 order-sm2)
            v-layout(row wrap)
              v-flex.px-5(xs6)
                v-text-field(label='Start Date' placeholder='YYYY-MM-DD' v-model='startDateFromInput' :rules='[startDateValidation]')
              v-flex.px-5(xs6)
                v-text-field(label='End Date' placeholder='YYYY-MM-DD' v-model='endDateFromInput' :rules='[endDateValidation]')

            v-date-picker.elevation-0(
              v-if='transitionDates.length'
              class='v-date-range__picker--start v-date-range__picker--end v-date-range__picker'
              :value='transitionDates'
              :show-current='false'
              no-title
              color='primary'
              :events='highlightDates'
              :event-color='highlightClasses'
              multiple
              full-width
              :min='minDate'
              :max='maxDate'
              @input='datePickerHandler'
            )
    v-divider
    v-card-actions
      v-spacer
      v-btn.my-0(text @click='closeModal()') Cancel
      v-btn.my-0.apply-btn(color='primary' text :disabled='disableApplyBtn' @click='applyChange()') Apply
</template>

<script>
import moment from 'moment'
import _ from 'lodash'

import reportingService from '../services/reporting.service'

export default {
  props: [
    'reportDates',
    'instanceDates',
    'isOpen',
    'hideShortcuts'
  ],
  created () {
    this.initializeComponent()
  },
  watch: {
    isOpen: function () {
      this.initializeComponent()
    },
    startDateFromInput: _.debounce(function () {
      if (this.startDateValidation(this.startDateFromInput) === true) {
        this.transitionDates[0] = this.startDateFromInput
      }
      this.highlight()
    }, 400),
    endDateFromInput: _.debounce(function () {
      if (this.endDateValidation(this.endDateFromInput) === true) {
        this.transitionDates[1] = this.endDateFromInput
      }
      this.highlight()
    }, 400)
  },
  computed: {
    disableApplyBtn () {
      return !this.dateInputValidator(this.startDateFromInput, 'start')[0] || !this.dateInputValidator(this.endDateFromInput, 'end')[0]
    },
    modalMinWidth () {
      return this.$vuetify.breakpoint.xsOnly || this.hideShortcuts ? '0' : '650'
    },
    maxDate () {
      return moment().isBefore(this.instanceDates[1]) ? moment().format('YYYY-MM-DD') : moment(this.instanceDates[1]).format('YYYY-MM-DD')
    },
    minDate () {
      return moment(this.instanceDates[0]).format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      calendarShortcuts: ['Line Dates', 'Today', 'Yesterday', 'Current month', 'Last 7 days', 'Last 31 days'],

      startDateValidation: value => {
        const validation = this.dateInputValidator(value, 'start')
        return validation[0] || validation[1]
      },
      endDateValidation: value => {
        const validation = this.dateInputValidator(value, 'end')
        return validation[0] || validation[1]
      },

      // Date range picker related
      highlightDates: [],
      highlightClasses: {},
      transitionDates: [],
      currentDate: 'start',
      startDateFromInput: '',
      endDateFromInput: ''
    }
  },
  methods: {
    applyChange () {
      this.$emit('startEndDatesChange', { start: moment(this.transitionDates[0]).format(), end: moment(this.transitionDates[1]).format() })
    },

    closeModal () {
      this.$emit('closeModal')
    },

    datePickerHandler (value) {
      if (this.currentDate === 'start') {
        this.transitionDates[0] = value[value.length - 1]
        this.currentDate = 'end'
      } else {
        this.transitionDates[1] = value[value.length - 1]
        this.currentDate = 'start'
      }
      const datesCheck = this.orderDates(this.transitionDates)
      this.transitionDates = datesCheck.values
      if (datesCheck.swapped) {
        this.currentDate = this.currentDate === 'start' ? 'end' : 'start'
      }
      this.startDateFromInput = this.transitionDates[0]
      this.endDateFromInput = this.transitionDates[1]
      this.highlight()
    },

    orderDates (dates) {
      if (moment(dates[0]).isAfter(dates[1])) {
        return { values: [dates[1], dates[0]], swapped: true }
      } else {
        return { values: dates, swapped: false }
      }
    },

    highlight () {
      const dates = []
      const classes = {}
      const start = moment(this.transitionDates[0])
      const end = moment(this.transitionDates[this.transitionDates.length - 1])
      const diff = moment.duration(end.diff(start)).asDays()
      for (let i = 0; i <= diff; i++) {
        const date = start.clone()
        date.add(i, 'days')
        const dateObj = date.toObject()
        dates.push(this.dateToString(dateObj))
        const classesArr = []
        classesArr.push('#a0c4ff')
        // classesArr.push(this.highlightColor)
        i === 0 && classesArr.push('v-date-range__range-start')
        i === diff && classesArr.push('v-date-range__range-end')
        classes[this.dateToString(dateObj)] = classesArr.join(' ')
      }

      this.highlightDates = dates
      this.highlightClasses = classes
    },

    dateToString (dateObj) {
      const year = dateObj.years
      let month = dateObj.months + 1
      let day = dateObj.date
      month = month >= 10 ? month : '0' + month
      day = day >= 10 ? day : '0' + day
      return year + '-' + month + '-' + day
    },

    disableShortcutItem (item) {
      const today = moment()
      const start = moment(this.instanceDates[0])
      const end = moment(this.instanceDates[1])

      const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
      const firstOfMonth = moment().date(1)
      const days7Ago = moment().subtract(7, 'days')
      const days31Ago = moment().subtract(31, 'days')

      switch (item) {
        case 'Today':
          return !this.isDateInInstanceInterval(today.format('YYYY-MM-DD'))
        case 'Yesterday':
          return !this.isDateInInstanceInterval(yesterday)
        case 'Current month':
          return !(start.isSameOrBefore(today) && end.isSameOrAfter(firstOfMonth))
        case 'Last 7 days':
          return !(start.isSameOrBefore(today) && end.isSameOrAfter(days7Ago))
        case 'Last 31 days':
          return !(start.isSameOrBefore(today) && end.isSameOrAfter(days31Ago))
        default:
          return false
      }
    },

    applyShortcut (type) {
      const today = moment().format('YYYY-MM-DD')
      const start = moment(this.instanceDates[0]).format('YYYY-MM-DD')
      const end = moment(this.instanceDates[1]).format('YYYY-MM-DD')
      const time = reportingService.whereIsInstanceInTime(start, end)
      const endLimited = time === 'present' ? today : end

      const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
      const firstOfMonth = moment(today).clone().date(1).format('YYYY-MM-DD')
      const days7Ago = moment().subtract(7, 'days').format('YYYY-MM-DD')
      const days31Ago = moment().subtract(31, 'days').format('YYYY-MM-DD')

      switch (type) {
        case 'Line Dates':
          this.setDatesOnPickerAndInput('start', start)
          this.setDatesOnPickerAndInput('end', endLimited)
          break
        case 'Today':
          this.setDatesOnPickerAndInput('start', today)
          this.setDatesOnPickerAndInput('end', today)
          break
        case 'Yesterday':
          this.setDatesOnPickerAndInput('start', yesterday)
          this.setDatesOnPickerAndInput('end', yesterday)
          break
        case 'Current month':
          this.setDatesOnPickerAndInput('start', firstOfMonth)
          this.setDatesOnPickerAndInput('end', endLimited)
          break
        case 'Last 7 days':
          this.setDatesOnPickerAndInput('start', days7Ago)
          this.setDatesOnPickerAndInput('end', endLimited)
          break
        case 'Last 31 days':
          this.setDatesOnPickerAndInput('start', days31Ago)
          this.setDatesOnPickerAndInput('end', endLimited)
          break
      }
      this.applyChange()
    },

    dateInputValidator (date, startOrEnd) {
      if (!date) {
        return [false, 'This field is required']
      } else if (!date.match(/^\d{4}-\d{2}-\d{2}$/g)) {
        return [false, 'Please enter a valid format']
      } else if (startOrEnd === 'start' && moment(date).isBefore(moment(this.instanceDates[0]).format('YYYY-MM-DD'))) {
        return [false, 'Picked date cannot be before the start date']
      } else if (startOrEnd === 'end' && moment(date).isAfter(moment().format('YYYY-MM-DD'))) {
        return [false, 'Picked date cannot be in the future']
      } else {
        return [true]
      }
    },

    isDateInInstanceInterval (date) {
      const start = moment(this.instanceDates[0]).format('YYYY-MM-DD')
      const end = moment().isBefore(this.instanceDates[1]) ? moment().format('YYYY-MM-DD') : moment(this.instanceDates[1]).format('YYYY-MM-DD')
      return moment(date).isSameOrAfter(start) && moment(date).isSameOrBefore(end)
    },

    setDatesOnPickerAndInput (side, value) {
      if (side === 'start') {
        this.transitionDates[0] = value
        this.startDateFromInput = value
      } else {
        this.transitionDates[1] = value
        this.endDateFromInput = value
      }
    },

    initializeComponent () {
      if (this.isOpen) {
        this.setDatesOnPickerAndInput('start', this.reportDates[0])
        this.setDatesOnPickerAndInput('end', this.reportDates[1])
        this.highlight()
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.v-btn {
  min-width: 0px;
}
.v-date-range__input-field >>> input {
  text-align: center;
}
// Menu Content
.v-date-range__content {
  display: flex;
  >>> .v-date-picker-table {
    .v-btn {
      border-radius: 0;
    }
  }
}
.v-date-range__pickers >>> .v-date-picker-table__events {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}
// Date buttons
.v-date-range__pickers >>> .v-date-picker-table table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  & th, & td {
    height: 32px;
  }
  & td {
    width: 14.28%;
    .v-btn {
      width: 100%;
      &.v-btn--outline {
        border: none;
        box-shadow: 0 0 0 1px currentColor inset;
      }
      &.v-btn--active::before {
        background-color: transparent !important;
      }
    }
  }
}
// Highlighting the even bubble dot
.v-date-range__pickers >>> .v-date-range__in-range {
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.v-date-range__pickers >>> .v-date-picker-table__events > div {
  height: 100%;
  width: 100%;
  margin: 0;
  border-radius: 0;
  opacity: 0.35
}
</style>
