import moment from 'moment'
import router from '@/router'

import reportingAPI from '@/services/reporting.api'
import venuesAPI from '@/services/venues.api'
import creativesAPI from '@/services/creatives.api'
import campaignsAPI from '@/services/campaigns.api'
import reportingService from '@/services/reporting.service'
import componentConfig from '@/services/componentConfig'

var campaignReportsStoreDefaults = {
  // instance of Report being viewed (campaign, line, venue or screen)
  instance: '',
  // available "tabs" per "instance"
  tabs: {
    campaign: [
      { id: 0, name: 'lines' },
      { id: 1, name: 'geolocations' },
      { id: 2, name: 'environments' },
      { id: 3, name: 'demographics' }
    ],
    line: [
      { id: 0, name: 'venues' },
      { id: 1, name: 'geolocations' },
      { id: 2, name: 'environments' },
      { id: 3, name: 'demographics' },
      { id: 4, name: 'manage-creatives', hidden: true }
    ],
    venue: [
      { id: 0, name: 'screens' },
      { id: 1, name: 'demographics' }
    ],
    screen: [
      { id: 0, name: 'creatives' }
    ]
  },
  // First "tab" (index = 0) selected by default
  tab: { id: 0, name: '' },

  // from: absoluteTime(moment().subtract(1, 'months')).format('YYYY-MM-DD'),
  // to: absoluteTime(moment()).format('YYYY-MM-DD'),
  from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),

  timeUnit: 'day',
  metric: 'impressions',

  // available "dimensions" per "tab"
  dimensions: {
    lines: [
      { id: 0, name: 'lines' },
      { id: 1, name: 'venues' },
      { id: 2, name: 'screens' },
      { id: 3, name: 'creatives' }
    ],
    creatives: [
      { id: 0, name: 'creatives' }
    ],
    geolocations: [
      { id: 0, name: 'countries' },
      { id: 1, name: 'states' },
      { id: 2, name: 'cities' }
    ],
    demographics: [],
    venues: [
      { id: 0, name: 'venues' },
      { id: 1, name: 'screens' },
      { id: 2, name: 'creatives' }
    ],
    screens: [
      { id: 0, name: 'screens' },
      { id: 1, name: 'creatives' }
    ],
    environments: [
      { id: 0, name: 'environments' }
    ]
  },
  // First "dimension" (index = 0) selected by default
  dimension: { id: 0, name: '' },

  campaign: null,
  line: null,
  venue: null,
  screen: null,
  compareTo: { name: 'Broadsign Ads', value: 'campsite' },

  lines: [],
  linesHaveMoreRows: true,
  linesReportingData: [],

  assignments: [],
  account: null,

  assignedCreatives: [], // one Line's assigned Creatives :: to be used by "Manage Line Creatives"
  campaignCreatives: [], // whole Campaign's Creatives :: to be filled on-demand, to avoid re-querying API for same Creative details
  targetGroups: null,
  targets: null,

  // Line Forecast
  lineForecast: {
    formats: []
  },

  chartData: [],
  tableData: [],
  pagination: {
    sortBy: 'name', // always have a field 'name', regardless of instance/tab/dimension
    descending: true,
    rowsPerPage: 150,
    page: 1,
    totalItems: 0
  },
  totals: {},
  linesFilter: {
    $filter: "bookingStatus ne 'Archived'",
    take: 250
  },
  // Loading variables
  campaignLoading: true,
  lineLoading: true,
  lineForecastLoading: true,
  assignedCreativesLoading: true,
  campaignAssignmentsLoading: true,
  venueLoading: true,
  screenLoading: true,
  chartLoading: true,
  tableLoading: true,
  tablePaging: true,
  totalsLoading: true,
  datesLoading: true,
  accountLoading: true
}

export default {
  namespaced: true,
  state: Object.assign({}, campaignReportsStoreDefaults),
  getters: {
    getInstance: state => {
      return state.instance
    },
    getTab: state => {
      return state.tab
    },
    getTabs: (state, getters, rootState, rootGetters) => {
      if (state.instance) {
        if (state.instance === 'line') {
          state.tabs[state.instance][0].name = componentConfig(rootGetters['user/isForAdServer']).campaigns.isVenuesShown ? 'venues' : 'screens'
        }

        const demographicTab = state.tabs[state.instance].find(tab => tab.name === 'demographics')
        if (demographicTab) demographicTab.hidden = !componentConfig(rootGetters['user/isForAdServer']).campaigns.isDemographicsTabShown

        return state.tabs[state.instance]
      } else {
        return []
      }
    },
    getDimension: state => {
      return state.dimension
    },
    getDimensions: (state, getters, rootState, rootGetters) => {
      if (state.tab) {
        if (state.tab.name === 'lines' && rootGetters['user/isForAdServer']) {
          const linesDimensionsForAdServer = [{ id: 0, name: 'lines' },
            { id: 1, name: 'screens' },
            { id: 2, name: 'creatives' }
          ]
          return linesDimensionsForAdServer
        } else {
          return state.dimensions[state.tab.name]
        }
      } else {
        return []
      }
    },
    getMetric: state => {
      return state.metric
    },
    getTimeUnit: state => {
      return state.timeUnit
    },
    getFromDate: state => {
      return state.from
    },
    getToDate: state => {
      return state.to
    },
    getCampaign: state => {
      return state.campaign
    },
    getLine: state => {
      return state.line
    },
    getVenue: state => {
      return state.venue
    },
    getScreen: state => {
      return state.screen
    },
    getCampaignLoading: state => {
      return state.campaignLoading
    },
    getLineLoading: state => {
      return state.lineLoading
    },
    getVenueLoading: state => {
      return state.venueLoading
    },
    getScreenLoading: state => {
      return state.screenLoading
    },
    getLineForecast: state => {
      return state.lineForecast
    },
    getLineForecastLoading: state => {
      return state.lineForecastLoading
    },
    getAssignedCreatives: state => {
      return state.assignedCreatives
    },
    getCampaignCreatives: state => {
      return state.campaignCreatives
    },
    getAssignedCreativesPerFormat: state => formatKey => {
      return state.assignedCreatives.filter(c => c.creativeFormat.key === formatKey)
    },
    getLineCreativesLoading: state => {
      return state.assignedCreativesLoading
    },
    getSummaryCreatives: state => {
      return { lineForecast: state.lineForecast, assignedCreatives: state.assignedCreatives }
    },
    getTargetGroups: state => {
      return state.targetGroups
    },
    getTargets: state => {
      return state.targets
    },
    getLines: state => {
      return state.lines
    },
    getfetchedAllLinesBool: state => {
      return state.linesHaveMoreRows
    },
    getAssignments: state => {
      return state.assignments
    },
    getCampaignAssignmentsLoading: state => {
      return state.campaignAssignmentsLoading
    },
    getAccount: state => {
      return state.account
    },
    getChartData: state => {
      return state.chartData
    },
    getChartLoading: state => {
      return state.chartLoading
    },
    getTableOptions: state => {
      var { screenId, venueId, lineId, campaignId } = router.currentRoute.params

      // from VueX docs: "a getter's result is cached based on its dependencies"
      // So, we need to include "state.line" in this getter so it re-evaluates when switching Lines.
      // Because the change of "lineId" in "router.currentRoute.params" alone is not enough.
      var stateLineId = state.line ? state.line.id : null

      const options = {
        metric: state.metric,
        groupby: [state.dimension.name || state.tab.name],
        filter: {
          from: state.from,
          to: state.to,
          orders: campaignId ? [parseInt(campaignId)] : [],
          lines: lineId ? [stateLineId || parseInt(lineId)] : [],
          venues: venueId ? [parseInt(venueId)] : [],
          screens: screenId ? [parseInt(screenId)] : []
        }
      }

      // PAGINATION
      options.paging = {
        orderBy: state.pagination.sortBy,
        skip: (state.pagination.page - 1) * state.pagination.rowsPerPage,
        take: state.pagination.rowsPerPage,
        asc: !state.pagination.descending
      }

      return options
    },
    getTableData: state => {
      return state.tableData
    },
    getTableLoading: state => {
      return state.tableLoading
    },
    getTotals: state => {
      return state.totals
    },
    getTotalsLoading: state => {
      return state.totalsLoading
    },
    getDatesLoading: state => {
      return state.datesLoading
    },
    getLinesFilter: state => {
      return state.linesFilter
    }
  },
  mutations: {
    setInstance (state, instance) {
      state.instance = instance
    },
    setCurrentTab (state, tab) {
      state.tab = tab
    },
    setDimension (state, dimension) {
      state.dimension = dimension
    },
    setDimensions (state, dimensions) {
      state.dimensions = dimensions
    },
    setFromDate (state, fromDate) {
      state.from = fromDate
    },
    setToDate (state, toDate) {
      state.to = toDate
    },
    setCampaign (state, campaign) {
      state.campaign = campaign
    },
    setLine (state, line) {
      state.line = Object.assign({}, state.line, line)
    },
    setLineForDatatable (state, line) {
      const updatedLineIndex = state.tableData.findIndex(x => x.id === line.id)
      if (updatedLineIndex >= 0) {
        Object.assign(state.tableData[updatedLineIndex], line)
      }
    },
    setVenue (state, venue) {
      state.venue = venue
    },
    setScreen (state, screen) {
      state.screen = screen
    },
    setLoading (state, obj) {
      state[obj.key + 'Loading'] = obj.value
    },
    setLineForecast (state, forecast) {
      state.lineForecast = forecast
    },
    // Line Creatives
    setAssignedCreatives (state, assignments) {
      state.assignedCreatives = assignments
    },
    addAssignedCreative (state, creative) {
      state.assignedCreatives.push(creative)
    },
    updateAssignedCreative (state, creative) {
      var creativeIndex = state.assignedCreatives.findIndex(c => c.id === creative.id)
      if (creativeIndex > -1) {
        Object.assign(state.assignedCreatives[creativeIndex], creative)
      }
    },
    removeAssignedCreative (state, creativeId) {
      var creativeIndex = state.assignedCreatives.findIndex(c => c.id === creativeId)
      if (creativeIndex > -1) {
        state.assignedCreatives.splice(creativeIndex, 1)
      }
    },
    resetAssignedCreatives (state) {
      state.assignedCreatives = []
    },
    // Campaign Creatives
    addCampaignCreatives (state, creatives) {
      if (creatives.length) {
        state.campaignCreatives = [...state.campaignCreatives, ...creatives]
      }
    },
    removeCampaignCreative (state, creativeId) {
      var creativeIndex = state.campaignCreatives.findIndex(c => c.id === creativeId)
      if (creativeIndex > -1) {
        state.campaignCreatives.splice(creativeIndex, 1)
      }
    },
    // Campaign Assignments
    setCampaignAssignments (state, assignments) {
      state.assignments = assignments
    },
    addCampaignAssignments (state, assignment) {
      state.assignments.push(assignment)
    },
    removeCampaignAssignments (state, assignmentId) {
      var assignmentIndex = state.assignments.findIndex(a => a.id === assignmentId)
      if (assignmentIndex > -1) {
        state.assignments.splice(assignmentIndex, 1)
      }
    },
    setAccount (state, account) {
      state.account = account
    },
    setTargetGroups (state, value) {
      state.targetGroups = value
    },
    setTargets (state, value) {
      state.targets = value
    },
    setLinesDetails (state, details) {
      state.lines = details.data
      state.linesHaveMoreRows = details.hasMoreRows
    },
    setLineDetails (state, details) {
      const updatedLineIndex = state.lines.findIndex(line => line.id === details.id)
      if (updatedLineIndex >= 0) {
        Object.assign(state.lines[updatedLineIndex], details)
      }
    },
    setChartData (state, data) {
      state.chartData = data
    },
    setTableData (state, data) {
      state.tableData = data
    },
    setPagination (state, pagination) {
      state.pagination = pagination
    },
    setTotals (state, data) {
      state.totals = data
    },
    setTimeUnit (state, newUnit) {
      state.timeUnit = newUnit
    },
    setMetric (state, newMetric) {
      state.metric = newMetric
    },
    resetStore (state) {
      state.instance = ''
      state.tab = { id: 0, name: '' }
      state.from = moment().subtract(1, 'months').format('YYYY-MM-DD')
      state.to = moment().format('YYYY-MM-DD')
      state.timeUnit = 'day'
      state.metric = 'impressions'
      state.dimension = { id: 0, name: '' }
      state.campaign = null
      state.line = null
      state.venue = null
      state.screen = null
      state.compareTo = { name: 'Broadsign Ads', value: 'campsite' }

      state.lines = []
      state.linesHaveMoreRows = true

      state.lineForecast = null
      state.assignedCreatives = []
      state.targetGroups = null
      state.targets = null

      state.chartData = []
      state.tableData = []
      state.totals = {}

      state.campaignLoading = true
      state.lineLoading = true
      state.lineForecastLoading = true
      state.assignedCreativesLoading = true
      state.venueLoading = true
      state.screenLoading = true
      state.chartLoading = true
      state.tableLoading = true
      state.totalsLoading = true
      state.datesLoading = true
    },

    updateLineForReport (state, line) {
      state.line = line
    },
    saveLinesReportingData (state, linesReportingData) {
      const newLineIds = linesReportingData.map(lineData => parseInt(lineData.key))

      const existingLineData = state.linesReportingData
        .filter(lineData => newLineIds.indexOf(parseInt(lineData.key)) === -1)

      state.linesReportingData = [...existingLineData, ...linesReportingData]
    }
  },
  actions: {
    getNavigatorData ({ commit, getters, dispatch }) {
      var { screenId, venueId, lineId, campaignId } = router.currentRoute.params

      const processCampaign = campaignId && (!getters.getCampaign || (getters.getCampaign && (getters.getCampaign.id !== parseInt(campaignId))))
      const processLine = lineId && (!getters.getLine || (getters.getLine && getters.getLine.id !== parseInt(lineId)))
      const processVenue = venueId && (!getters.getVenue || (getters.getVenue && getters.getVenue.id !== parseInt(venueId)))
      const processScreen = screenId && (!getters.getScreen || (getters.getScreen && getters.getScreen.id !== parseInt(screenId)))

      // CAMPAIGN
      var campaignPromise = processCampaign
        ? dispatch('getCampaign', parseInt(campaignId))
        : new Promise((resolve, reject) => {
          resolve(getters.getCampaign)
        })

      return campaignPromise.then(campaign => {
        // handle Campaign error
        if (!campaign || typeof campaign === 'string') {
          // commit('snackbar/setSnackbar', {
          //   type: 'error',
          //   msg: campaign
          // }, { root: true })

          return false
        }

        // set Reporting dates from campaign's dates, unless already provided in URL
        var startDate = moment(campaign.startDate).format('YYYY-MM-DD') || moment().format()
        var endDate = moment(campaign.endDate).format('YYYY-MM-DD') || moment().add(1, 'months').format()

        // Start date of report
        if (!router.currentRoute.query.startDate) {
          commit('setFromDate', startDate)
        }

        // End date of report => limit to "now" if campaign has started, but ends in future
        const campaignStarted = moment(startDate).isBefore(moment())
        const campaignEndsFuture = moment().isBefore(moment(endDate))
        if (campaignStarted && campaignEndsFuture) {
          endDate = moment().format('YYYY-MM-DD')
        }

        if (!router.currentRoute.query.endDate) {
          commit('setToDate', endDate)
        }

        commit('setLoading', { key: 'dates', value: false })

        if (!lineId) {
          return true
        }

        // LINE
        var linePromise = processLine
          ? dispatch('getLine', parseInt(lineId))
          : new Promise((resolve, reject) => {
            resolve(getters.getLine)
          })

        return linePromise.then(line => {
          // handle Line error
          if (!line || typeof line === 'string') {
            // commit('snackbar/setSnackbar', {
            //   type: 'error',
            //   msg: line
            // }, { root: true })

            return false
          }

          dispatch('getLineForecast', { lineId, campaignId })
          // dispatch('getAssignedCreatives', { accountId: getters.getCampaign.accountId, lineId: lineId })

          if (!venueId) {
            return true
          }

          // VENUE
          var venuePromise = processVenue
            ? dispatch('getVenue', parseInt(venueId))
            : new Promise((resolve, reject) => {
              resolve(getters.getVenue)
            })

          return venuePromise.then(venue => {
            // handle Venue error
            if (!venue || typeof venue === 'string') {
              // commit('snackbar/setSnackbar', {
              //   type: 'error',
              //   msg: venue
              // }, { root: true })

              return false
            }

            if (!screenId) {
              return true
            }

            // SCREEN
            var screenPromise = processScreen
              ? dispatch('getScreen', parseInt(screenId))
              : new Promise((resolve, reject) => {
                resolve(getters.getScreen)
              })

            return screenPromise.then(screen => {
              // handle Screen error
              if (!screen || typeof screen === 'string') {
                // commit('snackbar/setSnackbar', {
                //   type: 'error',
                //   msg: screen
                // }, { root: true })

                return false
              }

              return true
            })
          })
        })
      })
    },

    getCampaign ({ commit, dispatch }, campaignId) {
      commit('setLoading', { key: 'campaign', value: true })

      return reportingAPI.getOrder(campaignId)
        .then(campaign => {
          // if NOT FOUND, campaign will be undefined (no 404 error to catch)
          // same if User doesn't have access to campaign (no 400 error to catch)
          if (!campaign) {
            router.push({ name: 'Access Denied' })
            return 'Campaign not found.'
          }
          if (campaign.status === 'Proposal') {
            router.push({ name: 'Proposal', params: { id: campaign.id } })
            return 'Is proposal'
          }

          commit('setCampaign', campaign)

          // get campaign's account details, but don't wait
          dispatch('getAccount', campaign.accountId)

          // wait for all Lines
          return dispatch('getCampaignLines')
            .then(lines => {
              if (!lines || !lines.length) {
                return 'No Lines found for this Campaign.'
              }

              commit('setLoading', { key: 'campaign', value: false })
              return campaign
            })
        })
        .catch(() => { router.push({ name: 'Access Denied' }) })
    },

    getCampaignLines ({ commit, getters, dispatch }) {
      const { accountId, id } = getters.getCampaign

      return reportingAPI.getLines({ accountId: accountId, campaignId: id }, getters.getLinesFilter)
        .then(res => {
          // get Assignments for each Line of Campaign, but don't wait for it (async)
          var lineIds = res.data.map(line => line.id)
          dispatch('getCampaignAssignments', { accountId, lineIds })

          // console.log('x-has-more-rows', res.headers)
          commit('setLinesDetails', { data: res.data, hasMoreRows: res.headers['x-has-more-rows'] })
          return res.data
        })
    },

    getCampaignAssignments ({ commit, getters, dispatch }, ids) {
      commit('setLoading', { key: 'campaignAssignments', value: true })
      return reportingAPI.getCampaignAssignments(ids)
        .then(assignments => {
          commit('setCampaignAssignments', assignments)
          commit('setLoading', { key: 'campaignAssignments', value: false })
          return assignments
        })
    },

    getAccount ({ commit }, accountId) {
      commit('setLoading', { key: 'account', value: true })

      return campaignsAPI.getAdvertiser(accountId)
        .then(account => {
          commit('setAccount', account)
          commit('setLoading', { key: 'account', value: false })
        })
    },

    refreshLinesDataTable ({ commit, getters, dispatch }) {
      const { accountId, id } = getters.getCampaign

      return reportingAPI.getLines({ accountId: accountId, campaignId: id }, getters.getLinesFilter)
        .then(res => {
          // get Assignments for each Line of Campaign, but don't wait for it (async)
          var lineIds = res.data.map(line => line.id)
          dispatch('getCampaignAssignments', { accountId, lineIds })

          commit('setLinesDetails', { data: res.data, hasMoreRows: res.headers['x-has-more-rows'] })
          dispatch('getTableData')
        })
    },

    getLine ({ commit, getters, rootGetters }, lineId) {
      commit('setLoading', { key: 'line', value: true })

      const currentCampaign = getters.getCampaign
      const ids = {
        accountId: currentCampaign.accountId,
        campaignId: currentCampaign.id,
        lineId
      }

      return reportingAPI.getLine(ids, true)
        .then(line => {
          // handle Campaign error
          if (!line || typeof line === 'string') {
            router.push({ name: 'Access Denied' })
            return false
          }

          line.geoLoaded = true

          const exchanges = rootGetters['general/getExchanges']
          const exchange = exchanges.find(x => x.id === line.exchangeId)
          commit('setTargetGroups', rootGetters['general/allTargetGroups'][exchange.key])
          commit('setTargets', rootGetters['general/allTargets'][exchange.key])

          commit('setLine', line)
          commit('setLoading', { key: 'line', value: false })

          return line
        })
        .catch(() => { router.push({ name: 'Access Denied' }) })
    },

    getLineForecast ({ commit }, idsObj) {
      commit('setLoading', { key: 'lineForecast', value: true })

      reportingAPI.getLineForecast(idsObj).then(res => {
        commit('setLineForecast', res.data)
        commit('setLoading', { key: 'lineForecast', value: false })
      })
    },

    /**
     * @input array (line's) Assignments
     * @return array Creatives
     */
    getAssignedCreatives ({ commit, getters }, assignments) {
      commit('setLoading', { key: 'assignedCreatives', value: true })

      // extract Creatives' IDs from Assignments
      const creativesIds = assignments ? assignments.map(a => a.creativeId) : []

      // check if some Creatives are already loaded as part of Campaign's Creatives
      var loadedCampaignCreatives = getters.getCampaignCreatives.filter(creative => creativesIds.includes(creative.id))

      // assign the new assignment ids to the loaded creatives
      loadedCampaignCreatives.map(creative => {
        creative.assignmentId = assignments.find(assignment => assignment.creativeId === creative.id).id
      })

      var loadedCampaignCreativesIds = loadedCampaignCreatives.map(c => c.id)

      // query API only for those missing, if any
      var creativesIdsMissing = creativesIds.filter(id => !loadedCampaignCreativesIds.includes(id))
      var creativesPromise = creativesIdsMissing.length
        ? creativesAPI.getCreatives(0, creativesIdsMissing.length, 'id', 'desc', [{ name: 'creativeIds', value: creativesIdsMissing }])
        : new Promise((resolve, reject) => {
          resolve([])
        })

      return creativesPromise.then(missingCreatives => {
        // add newly loaded creatives to list, if any
        if (missingCreatives.length) {
          // attach "assignmentId"
          missingCreatives.map(creative => {
            creative.assignmentId = assignments.find(assignment => assignment.creativeId === creative.id).id
          })

          commit('addCampaignCreatives', missingCreatives)
        }

        const assignedCreatives = [...loadedCampaignCreatives, ...missingCreatives]
        commit('setAssignedCreatives', assignedCreatives)

        commit('setLoading', { key: 'assignedCreatives', value: false })

        return getters.getAssignedCreatives
      })
    },

    getVenue ({ commit, getters }, venueId) {
      commit('setLoading', { key: 'venue', value: true })

      return venuesAPI.getVenue(venueId)
        .then(venue => {
          commit('setVenue', venue)
          commit('setLoading', { key: 'venue', value: false })
          return venue
        })
        .catch(() => { router.push({ name: 'Access Denied' }) })
    },

    getScreen ({ commit, getters }, screenId) {
      commit('setLoading', { key: 'screen', value: true })

      const currentVenue = getters.getVenue
      if (currentVenue.id) {
        return venuesAPI.getScreen(currentVenue.id, screenId)
          .then(screenList => {
            if (screenList.length) {
              const screen = screenList[0]
              commit('setScreen', screen)
              commit('setLoading', { key: 'screen', value: false })
              return screen
            } else {
              router.push({ name: 'Access Denied' })
            }
          })
          .catch(() => { router.push({ name: 'Access Denied' }) })
      }
    },

    getChartData ({ commit, getters }) {
      commit('setLoading', { key: 'chart', value: true })

      var { screenId, venueId, lineId, campaignId } = router.currentRoute.params
      // console.log('getChartData...', screenId, venueId, lineId, campaignId)

      const options = {
        metric: getters.getMetric,
        groupby: [getters.getDimension.name || getters.getTab.name],
        filter: {
          from: getters.getFromDate,
          to: getters.getToDate,
          orders: campaignId ? [parseInt(campaignId)] : [],
          lines: lineId ? [parseInt(lineId)] : [],
          venues: venueId ? [parseInt(venueId)] : [],
          screens: screenId ? [parseInt(screenId)] : []
        }
      }
      // console.log(options)

      reportingAPI.getChartData(getters.getTimeUnit, options)
        .then(resp => {
          commit('setChartData', resp.data.data)
        })
        .catch(error => {
          // TODO: raise flag somewhere to warn User...
          console.log('ERROR getting CHART data', error)
        })
        .finally(() => {
          commit('setLoading', { key: 'chart', value: false })
        })
    },

    getTotals ({ commit, getters, dispatch }, tablePagingOnly = false) {
      commit('setLoading', { key: 'tablePaging', value: true })
      commit('setLoading', { key: 'totals', value: !tablePagingOnly })

      // Totals
      reportingAPI.getTotals(getters.getTableOptions)
        .then(resp => {
          var totals = resp.data

          // update Totals count, as /reporting/totals might not return stats for ALL lines,
          // but we display them still, so paging needs to reflect this
          if (getters.getDimension.name === 'lines') {
            var numLines = getters.getLines.filter(x => x.bookingStatus !== 'Archived').length
            if (numLines > totals.count) {
              totals.count = numLines
            }
          }

          commit('setTotals', resp.data)

          commit('setLoading', { key: 'tablePaging', value: false })
          commit('setLoading', { key: 'totals', value: false })
        })
    },

    getTableData ({ commit, getters, dispatch, state }) {
      commit('setLoading', { key: 'table', value: true })

      var tableOptions = getters.getTableOptions
      var { asc, orderBy, skip, take } = tableOptions.paging

      // Data
      reportingAPI.getTableData(tableOptions)
        .then(resp => {
          // Extra infos per row
          var emptyStats = {
            adsServed: 0,
            avgCPM: 0,
            city: null,
            country: null,
            date: null,
            impressions: 0,
            locationType: null,
            numberOfScreens: 0,
            numberOfVenues: 0,
            postalCode: null,
            province: null,
            spent: 0,
            street: null,
            streetNumber: null,
            suite: null
          }

          var tableData = resp.data
          const dimensionName = getters.getDimension.name

          // display ALL lines, even if no stats
          if (dimensionName === 'lines') {
            commit('saveLinesReportingData', resp.data)

            // Lines are already in store, check if they all have stats
            const completeTableData = getters.getLines
              .filter(x => x.bookingStatus !== 'Archived')
              .map(line => {
                const row = state.linesReportingData.find(row => parseInt(row.key) === line.id) || Object.assign({}, emptyStats, { key: line.id, name: line.name })
                return Object.assign({}, line, row)
              })
              .sort((a, b) => {
                var orderByMapped = orderBy === 'maxbid' ? 'maxCpm' : orderBy
                if (a[orderByMapped] > b[orderByMapped]) { return asc ? 1 : -1 }
                if (a[orderByMapped] < b[orderByMapped]) { return asc ? -1 : 1 }
                return 0
              })

            tableData = reportingService.paginateList(JSON.parse(JSON.stringify(completeTableData)), take, skip)

            // update Totals count, as /reporting/totals might not return stats for ALL lines,
            // but we display them still, so paging needs to reflect this
            var currentTotals = getters.getTotals
            var newTotals = Object.assign({}, currentTotals, { count: getters.getLines.length })
            commit('setTotals', newTotals)
          }

          commit('setTableData', tableData)
          commit('setLoading', { key: 'table', value: false })
        })
        .catch(error => {
          // TODO: raise flag somewhere to warn User...
          console.log('ERROR getting TABLE data', error)

          commit('setLoading', { key: 'table', value: false })
          commit('setLoading', { key: 'totals', value: false })
        })
        // .finally(() => {
        //   commit('setLoading', { key: 'table', value: false })
        // })
    },

    updateCampaign ({ commit, getters }, modifiedFieldsObj) {
      // modifiedFieldsObj : key-value pairs to be updated
      const { id, accountId } = getters.getCampaign
      modifiedFieldsObj.id = id
      return campaignsAPI.editCampaign(accountId, id, modifiedFieldsObj).then(updatedCampaign => {
        commit('setCampaign', updatedCampaign)
        return updatedCampaign
      })
    },

    /**
     * @params linePatch {Object} Object containing :
     *           - key-value pairs of fields to update (via PATCH)
     *           - id @required ID of Line to update
     */
    // updateLine ({commit, getters}, linePatch) {
    //   let ids = {
    //     accountId: getters.getCampaign.accountId,
    //     orderId: getters.getCampaign.id,
    //     lineId: linePatch.id
    //   }

    //   delete linePatch.id

    //   return reportingAPI.editLinePatch(ids, linePatch)
    //     .then(updatedLine => {
    //       commit('setLine', updatedLine.data)
    //       return updatedLine.data
    //     })
    // },

    updateLine ({ commit, getters }, linePatch) {
      var lineId = linePatch.id

      const ids = {
        accountId: getters.getCampaign.accountId,
        orderId: getters.getCampaign.id,
        lineId
      }
      delete linePatch.id
      linePatch.orderId = getters.getCampaign.id

      return reportingAPI.editLinePatch(ids, linePatch)
        .then(resp => {
          // (!) do NOT use "updatedLine" as it doesn't include "geography" when returned from PATCH (!)
          const updatedLine = resp.data
          const update = Object.assign(linePatch, { id: lineId, effectiveStatus: updatedLine.effectiveStatus })

          // update Line in Data Table
          commit('setLineForDatatable', update)

          // update Line in Lines
          commit('setLineDetails', update)

          // update current Line, if any
          if (getters.getLine && getters.getLine.id === lineId) {
            commit('setLine', update)
          }

          return updatedLine.data
        })
    },

    deleteLineTargets ({ commit, getters }, params) {
      var { lineId, targets } = params
      var ids = {
        accountId: getters.getCampaign.accountId,
        orderId: getters.getCampaign.id,
        lineId
      }

      var promises = targets.map(target => {
        return reportingAPI.deleteLineTarget(ids, target)
      })

      return Promise.all(promises)
        .then(deleted => {
          return deleted.every(d => d === true)
        })
    },

    assignCreative ({ dispatch, commit }, data) {
      const assignment = {
        accountId: data.options.accountId,
        lineId: data.options.lineId,
        creativeId: data.creative.id,
        status: 'Active'
      }

      var newCreativePromise
      if (data.creative.processStatus === 'New') {
        newCreativePromise = dispatch('creatives/editCreative', data.creative, { root: true })
      } else {
        newCreativePromise = new Promise((resolve, reject) => {
          resolve(data.creative)
        })
      }

      return newCreativePromise
        .then(creative => {
          return campaignsAPI.createAssignment(assignment)
            .then(newAssignment => {
              if (!newAssignment.id) {
                throw new Error('Creative not assigned')
              }

              commit('addAssignedCreative', data.creative)
              commit('addCampaignAssignments', newAssignment)

              // get Line from API to refresh "effectiveStatus" now that we've assigned Creative(s)
              return dispatch('getCampaignLines')
                .then(refreshedLine => {
                  return true
                })
            })
        })
    },

    unassignCreative ({ dispatch, commit }, data) {
      return reportingAPI.unassignCreative(data)
        .then(unassignment => {
          if (unassignment.status !== 'Inactive') {
            throw new Error('Creative not unassigned')
          }

          commit('removeAssignedCreative', data.creativeId)
          commit('removeCampaignAssignments', data.assignmentId)

          // get Line from API to refresh "effectiveStatus" now that we've un-assigned Creative(s)
          return dispatch('getCampaignLines')
            .then(refreshedLines => {
              return true
            })
        })
    },

    duplicateCampaign ({ getters }, keepCreatives) {
      const { id, accountId } = getters.getCampaign
      return campaignsAPI.duplicateCampaign(accountId, id, keepCreatives)
    }
  }
}
