import axios from 'axios'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  getOrder,
  getLine,
  getLines,
  getLineForecast,
  editLine,
  editLinePatch,
  deleteLineTarget,
  getLineAssignments,
  getCampaignAssignments,
  getAssignedCreatives,
  unassignCreative,
  getChartData,
  getTableData,
  getTotals,
  getAsyncUrl,
  getAsyncProgress,
  getAsyncFinalResponse,
  duplicateLine,
  getCampaign
}

function getOrder (campaignId) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + `/orders/?detail=1&$filter=id%20eq%20${campaignId}`).then(response => response.data[0])
}

function getCampaign (campaignId) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + `/orders/${campaignId}`).then(response => response.data)
}

function getLine (idsObj, includeGeography) {
  var { accountId, campaignId, lineId } = idsObj
  let url = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + campaignId + '/lines/' + lineId
  if (includeGeography) { url += '?$expand=geography' }
  return axios.get(url).then(response => response.data)
}

function getLines (ids, params = {}) {
  const { accountId, campaignId } = ids
  const url = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + campaignId + '/lines'
  const filters = Object.keys(params).map(key => key + '=' + params[key])
  if (filters.length) {
    return axios.get(url + '?' + filters.join('&')).then(res => res)
  } else {
    return axios.get(url).then(response => response)
  }
}

function getLineForecast (idsObj) {
  var { campaignId, lineId } = idsObj
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/orders/' + campaignId + '/lines/' + lineId + '/forecasts')
}

function editLine (line) {
  var { accountId, orderId, id } = line
  return axios.put(VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + orderId + '/lines/' + id, line)
}

function editLinePatch (idsObj, modifiedFields) {
  const { accountId, orderId, lineId } = idsObj
  return axios.patch(VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + orderId + '/lines/' + lineId, modifiedFields)
}

function deleteLineTarget (ids, target) {
  var { accountId, orderId, lineId } = ids
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + orderId + '/lines/' + lineId + '/segment?target=' + target
  return axios.delete(endpoint).then(response => response.status === 204)
}

function getLineAssignments (accountId, lineId) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/assignments?$filter=lineId eq ' + lineId + " and status eq 'Active'").then(res => res.data)
}

function getCampaignAssignments (ids) {
  var { accountId, lineIds } = ids
  var filter = lineIds.map(lineId => lineId).join(',')
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/assignments?$filter=status eq \'Active\' and lineId in (' + filter + ')').then(res => res.data)
}

// TODO: move this to Creatives API and rename getCreativesById()
function getAssignedCreatives (creativeIds) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/creatives?$filter=' + creativeIds.map(c => 'id eq ' + c).join(' or ')).then(res => res.data)
}

function unassignCreative (assignment) {
  var { accountId, orderId, lineId, assignmentId } = assignment
  var url = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + orderId + '/lines/' + lineId + '/assignments/' + assignmentId

  // unassign by updating status to "Inactive"
  assignment.status = 'Inactive'
  return axios.patch(url, assignment)
    .then(response => response.data)
    .catch(error => error)
}

function getChartData (endpoint = 'day', options) {
  var url = VUE_APP_CAMPSITE_API_URL + '/reporting/' + endpoint
  return axios.post(url, options)
}

function getTableData (options) {
  var url = VUE_APP_CAMPSITE_API_URL + '/reporting'
  return axios.post(url, options)
}

function getTotals (options) {
  var url = VUE_APP_CAMPSITE_API_URL + '/reporting/totals'
  return axios.post(url, options)
}

function duplicateLine (idsObj, includeCreatives = false) {
  const { accountId, orderId, lineId } = idsObj
  let url = VUE_APP_CAMPSITE_API_URL + '/accounts/' + accountId + '/orders/' + orderId + '/lines/' + lineId + '/clone'
  if (includeCreatives) { url += '?includeCreatives=true' }
  return axios.post(url, {})
}

// ===================
// ADVANCED CSV EXPORT
// ===================

function getAsyncUrl (options, select = []) {
  const url = VUE_APP_CAMPSITE_API_URL + '/reporting'
  // if (select.length) { url += "?$select=" + select.join(",") }

  const config = {
    Accept: 'text/csv',
    'Content-Type': 'application/json'
  }

  return axios({ method: 'post', url: url, data: options, headers: config })
    .then(res => VUE_APP_CAMPSITE_API_URL + res.headers.location)
}

function getAsyncProgress (url) {
  // used to get status and progress for async temporary calls
  return axios.get(url).then(res => res.data)
}

function getAsyncFinalResponse (url) {
  return axios.get(url + '/result').then(res => res.data)
}
