<template lang="pug">
  v-navigation-drawer(
    v-model='isDialogOpen'
    fixed temporary right
    :width="$vuetify.breakpoint.xsOnly ? '100%' : '75%'"
    @keydown.esc='preClose'
    )
    v-container.px-0.pb-0.dialog-content.min-height(
      v-click-outside="preCloseFromClickOutside"
      fluid grid-list-lg text-left temporary
      v-if='isDialogOpen'
      )
      .sticky-action-bar.sticky-top.mt-n3
        v-row.ma-0(align='center')
          v-col(cols='auto')
            v-btn.close-edit-report-icon-btn(icon @click='preClose')
              v-icon mdi-close
          v-col(cols='auto')
            .text-h6.d-inline-block(v-if='isDialogOpen')
              | Add Deals
      dealsOverviewScreen(:isOverview='false' @selectedDeals='setSelected' :campaignDeals='campaignDeals')

    v-card(color='#f8f9fa')
    .sticky-action-bar.sticky-bottom.pa-3
      v-btn#apply-load-deal-btn(
        @click='applyWrapper()'
        color='primary' depressed
        :disabled='isApplyDisabled'
        ) Apply
      v-btn#close-load-deal-btn.ml-3(text @click='preClose') cancel
</template>

<script>

import dealsOverviewScreen from '@/components/dealsOverviewScreen.vue'

export default {
  props: ['openDialogEvent'],
  components: {
    dealsOverviewScreen
  },
  data () {
    return {
      isDialogOpen: false,
      selectedDeals: []
    }
  },
  watch: {
    openDialogEvent () {
      this.selectedDeals = this.campaignDeals || []
      this.isDialogOpen = true
    }
  },
  computed: {
    campaignDeals () {
      return this.$store.getters['createCampaign/deals']
    },
    isApplyDisabled () {
      return !this.campaignDeals.length && !this.selectedDeals.length
    }
  },
  methods: {
    preClose () {
      this.close()
    },
    setSelected (deals) {
      if (deals) this.selectedDeals = deals
    },
    preCloseFromClickOutside (p) {
      if (p?.target?.className === 'v-overlay__scrim') this.preClose()
    },
    close () {
      this.isDialogOpen = false
    },
    applyWrapper () {
      this.$store.commit('createCampaign/setDeals', this.selectedDeals)
      this.$emit('updateForecast')
      this.close()
    }
  }
}
</script>

<style scoped>
.sticky-action-bar {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}
.sticky-top {
  top: 0;
  border-bottom: 1px solid lightgray;
  z-index: 1;
}
.min-height {
 min-height: calc(100% - 61px)
}
.sticky-bottom {
  bottom: 0;
  border-top: 1px solid lightgray;
}
</style>
