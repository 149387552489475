<template lang="pug">
  div
    v-btn.add-weather-condition.ma-0.d-block(text color='primary' v-if='!conditionMenuOpen' @click='openConditionMenu')
      v-icon mdi-plus
      | weather condition

    v-card.weather-condition-menu(v-if='conditionMenuOpen' color='grey lighten-4')
      v-card-title.pt-2
        | Weather Condition
        v-spacer
        v-btn.close-weather-condition(icon small @click='closeConditionMenu')
          v-icon(color='grey') mdi-close

      v-card-text.pt-0.text--primary
        .pb-4.weather-condition-location
          .body-1.font-weight-bold Weather condition must match:

          v-chip.location-option-local.my-2.mr-2(
            key='local'
            value='local'
            small
            :dark='conditionLocation === "local"'
            :color='conditionLocation === "local" ? "primary" : ""'
            @click="conditionLocationOptionChanged('local')"
          )
            v-icon(left small) mdi-television
            | Local Weather
          v-chip.location-option-destination(
            key='destination'
            value='destination'
            small
            :dark='conditionLocation === "destination"'
            :color='conditionLocation === "destination" ? "primary" : ""'
            @click="conditionLocationOptionChanged('destination')"
          )
            v-icon(left small) mdi-earth
            |  Destination Weather

          v-form(ref='weatherForm')
            v-autocomplete.mb-2.location-search.my-2(
              v-if='conditionLocation === "destination"'
              label='Search for a city'
              :items='locations'
              item-text='label'
              :search-input.sync='locationSearch'
              v-model='location'
              hide-no-data
              return-object
              autocomplete='noped'
              :rules='[rules.required]'
              clearable
              @click:clear='clearLocationSearch'
            )
              template(v-slot:prepend-inner='')
                v-fade-transition.d-block(leave-absolute)
                v-progress-circular.ml-n1.mr-3(v-if='locationsLoading', size='20', width='2', color='primary', indeterminate='')
                v-icon.ml-n1.mr-2(v-else) mdi-earth

              template(v-slot:item='{ item }')
                v-list-item-content
                  v-list-item-title
                    v-icon(color="primary") {{ item.type === 'venue' ? 'mdi-map-marker' : 'mdi-pin' }}
                    |  {{ item.label }}

          .caption(style="line-height: 1rem;")
            span(v-if='conditionLocation === "local"') Targeted screens will look at their own local weather condition before playing the ad.
            span(v-if='conditionLocation === "destination"') Targeted screens will look at the weather condition in the specified city before bidding.

        .pb-4
          .d-flex.justify-space-between
            .body-1.font-weight-bold Temperature °{{ temperatureScaleSymbol }}
            .body-1.font-weight-bold.range-display
              span.range-display-empty(v-show='range[0] === minTick && range[1] === maxTick') Any

              span.range-display-min(v-show='range[0] !== minTick') {{ range[0] }}
                sup °{{ temperatureScaleSymbol }}

              span.range-display-separator(v-show='range[0] !== minTick && range[1] !== maxTick')  to

              span.range-display-max(v-show='range[1] !== maxTick')  {{ range[1] }}
                sup °{{ temperatureScaleSymbol }}

              span.range-display-suffix
                span(v-if='range[0] === minTick && range[1] !== maxTick')  and below
                span(v-if='range[0] !== minTick && range[1] === maxTick')  and over

          v-range-slider.pt-10(
            persistent-hint
            :value='range'
            :max='maxTick'
            :min='minTick'
            :tick-labels='tickLabels'
            ticks=true
            tick-size='0'
            @change='temperatureRangeChanged'
          )
            template(v-slot:thumb-label='{ value }')
              | {{ value }}
              sup °{{ temperatureScaleSymbol }}
            template(v-slot:tick-label='{ value }')
              | {{ value }}
              sup °{{ temperatureScaleSymbol }}

        .pb-4
          .body-1.font-weight-bold Sky Condition
          v-item-group(multiple v-model='selectedSkyConditions')
            v-row.my-0(no-gutters)
              v-col(v-for='condition in skyConditions' :key='condition.key' xs='2' md='4')
                v-item(v-slot="{ active, toggle }" :value='condition.key')
                  v-card.sky-condition.text-center.align-center.justify-center.py-1.elevation-0(
                    :color="active ? 'primary' : 'transparent'"
                    :dark="active"
                    :ripple="{ class: 'primary--text' }"
                    tile
                    @click="toggle()"
                  )
                    v-icon.d-block(large) {{ condition.icon }}
                    .caption.d-block {{ condition.label }}

        .caption.grey--text(style="line-height: 1rem;") Even weatherpeople get it wrong! Because of its unpredictable nature, we cannot provide campaign impression estimates when weather moments are selected.
</template>

<script>
import _ from 'lodash'
import config from './config'
import services from './services'

export default {
  name: 'WeatherMoment',
  components: {
  },
  props: {
    temperatureScale: {
      type: String,
      default: 'celsius'
    },
    postEvent: {
      type: Object
    },
    weather: {
      type: Object
    },
    authToken: {
      type: String
    }
  },
  data () {
    return {
      conditionMenuOpen: false,
      conditionLocation: 'local',
      locations: [],
      locationsLoading: false,
      locationSearch: null,
      location: null,

      range: this.defaultRange(),
      minTick: this.defaultRange()[0],

      selectedSkyConditions: [],
      skyConditions: config.skyConditions,

      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  mounted: function () {
  },
  watch: {
    postEvent (newVal) {
      if (newVal) {
        if (newVal.name === 'validate moment') {
          if (this.$refs.weatherForm) this.$refs.weatherForm.validate()
        }
      }
    },
    weather (newV) {
      if (newV) {
        this.setModels()
      }
    },
    locationSearch: _.debounce(function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (!this.location || (this.location.label !== newVal)) {
          this.searchLocation(newVal)
        }
      }
    }, 400),
    weatherCurrentValues (newV) {
      this.$emit('onEvent', { category: 'weather', subCategory: 'condition', action: 'dataChanged', value: newV, isInvalid: !this.isFormValid })
    }
  },
  computed: {
    temperatureScaleSymbol () {
      return this.temperatureScale.charAt(0).toUpperCase()
    },
    maxTick () {
      return this.defaultRange()[1]
    },
    numberOfTicksTotal () {
      return this.maxTick - this.minTick + 1
    },
    tickLabels () {
      var labels = new Array(this.numberOfTicksTotal)

      // fixed increment per scale
      const stepIncrement = this.temperatureScale === 'fahrenheit' ? 20 : 10

      for (var i = 0; i < this.numberOfTicksTotal; i += stepIncrement) {
        const labelValue = this.minTick + i
        labels[i] = labelValue.toString()
      }

      return labels
    },
    isFormValid () {
      return !(this.conditionLocation === 'destination' && !this.location)
    },
    weatherCurrentValues () {
      if (!this.conditionMenuOpen) return null

      let location = null
      if (this.conditionLocation === 'destination' && this.location) {
        location = {
          latitude: this.location.geography ? this.location.geography.latitude : this.location.latitude,
          longitude: this.location.geography ? this.location.geography.longitude : this.location.longitude,
          label: this.location.label
        }
      }

      const temperature = services.convertTemperaturesToKelvins(this.range, this.temperatureScale)

      const skyConditions = this.selectedSkyConditions

      return {
        location,
        temperature,
        skyConditions
      }
    }
  },
  methods: {
    searchLocation (q) {
      this.locationsLoading = true
      return services.searchLocation(q, this.authToken)
        .then(res => {
          this.locations = res
        })
        .catch(() => {
          this.locations = []
        })
        .finally(() => {
          this.locationsLoading = false
        })
    },

    conditionLocationOptionChanged (conditionLocation) {
      this.conditionLocation = conditionLocation
    },

    clearLocationSearch () {
      this.locationSearch = null
      this.locations = []
      this.location = null
    },

    openConditionMenu () {
      this.conditionMenuOpen = true
      this.$emit('onEvent', { category: 'weather', subCategory: 'condition', action: 'opened' })
    },

    closeConditionMenu () {
      this.conditionMenuOpen = false
      this.resetValues()
      this.$emit('onEvent', { category: 'weather', subCategory: 'condition', action: 'closed' })
    },

    defaultRange () {
      return config.temperatureRanges[this.temperatureScale]
    },

    resetValues () {
      this.conditionLocation = 'local'
      this.locationSearch = null
      this.location = null
      this.range = this.defaultRange()
      this.selectedSkyConditions = []
    },

    setModels () {
      this.conditionMenuOpen = true

      const location = this.weather.location
      if (location) {
        this.conditionLocation = 'destination'
        this.locations = [location]
        this.location = location
        this.locationSearch = location.label
      }

      this.selectedSkyConditions = this.weather.skyConditions

      this.range = services.convertTemperaturesFromKelvins(this.weather.temperature, this.temperatureScale)
        .map(v => Math.round(v))
    },

    temperatureRangeChanged (newRange) {
      this.range = newRange
    }
  }
}
</script>

<style scoped>
>>> .v-slider__tick-label{
  font-size:12px;
}
>>> .v-slide-group__content {
  display:block;
}
sup {
  top: -0.3em;
}
</style>
