<template lang="pug">
  div
    v-btn.add-item.ma-0.d-block(text color='primary' @click='openMenu' v-if='!isMenuOpen')
      v-icon mdi-plus
      | {{ financeType }}

    v-card.item-menu.mb-4(color='grey lighten-4' v-if='isMenuOpen')
      v-card-title.item-title.pt-2 {{ `${financeType} Condition` | capitalize }}
        v-spacer
        v-btn.close-item(icon small @click='closeMenu')
          v-icon(color='grey') mdi-close

      v-card-text.pt-0.text--primary
        div.pb-4
          v-autocomplete.search-autocomplete.mb-4(
            :label='`Search for ${financeTypePlural}`'
            v-model='itemSelected'
            :items='items'
            item-text='name'
            item-value='symbol'
            return-object
            hide-selected
            hide-details
            hide-no-data
            autofocus
            :autocomplete='financeType'
            :search-input.sync='inputSearch'
            clearable
            @click:clear='clearSearch'
            no-filter
            :ref='`${financeType}Autocomplete`'
            :class='`item-search-${financeType}`'
            :attach='`.item-search-${financeType}`'
            :menu-props='{ closeOnContentClick: true }'
          )
            template(v-slot:prepend-inner='')
              v-fade-transition.d-block(leave-absolute)
                v-progress-circular.ml-n1.mr-3(v-if='fetchingItems' size='20' width='2' color='primary' indeterminate='')
                v-icon.ml-n1.mr-2(v-else) mdi-magnify

            template(v-slot:prepend-item='')
              v-subheader(style="height:38px;" v-if='showPopular') Popular Searches

            template(v-slot:item='{ item }')
              v-list-item.search-no-result(v-if='!item.symbol')
                v-list-item-content
                  v-list-item-title No Results Found

              v-list-item.search-result(dense v-else @click='selectItem(item)')
                v-list-item-icon.subtitle-2.symbol.mt-3 {{ item.symbol }}
                v-list-item-content
                  v-list-item-title.search-result-name {{ item.name }}
                  v-list-item-subtitle {{ item.exchangeShortName }}
                //- v-list-item-action
                  v-chip(:color='changeChipColor(item.changePercentage)' small label)
                    v-icon(small left :color='changeIconColor(item.changePercentage)') {{ changeIcon(item.changePercentage) }}
                    span.text--darken-4.font-weight-bold(:class='changeTextClass(item.changePercentage)') {{ item.changePercentage }}%

          v-card.item-card.mt-2.mb-4.px-4.pt-4.pb-2(outlined v-if='itemSelected && itemSelectedPeriod')
            .text-h6
              span.item-name {{ itemSelected.name }}
              span.item-symbol  ({{ itemSelected.symbol }})
            .caption.grey--text
              span.item-date(v-if='itemSelectedPeriod.endDate')  {{ itemSelectedPeriod.endDate | date('MMM D, HH:mm:ss A UTC Z') }}
              |  ·
              span.item-currency  {{ itemSelected.currency || 'USD' }}
              |  ·
              span.item-exchange  {{ itemSelected.exchange }}
            v-row.my-0
              v-col
                .overline Price
                .text-h5
                  span.item-price
                    span(v-if='itemSelectedPeriod.endPrice') {{ itemSelectedPeriod.endPrice | numberWithCommas(2) }}
                    span(v-else) N/A
              v-col
                .overline Change %
                v-chip.mr-2(:color='changeChipColor(itemSelectedPeriod.changePercentage)' label)
                  span.item-change-percent.text--darken-4.font-weight-bold(:class='changeTextClass(itemSelectedPeriod.changePercentage)')
                    span(v-if='itemSelectedPeriod.changePercentage') {{ itemSelectedPeriod.changePercentage | numberWithCommas(2) }}%
                    span(v-else) N/A
              v-col
                .overline Change Price
                span.item-change-price.text-h5.text--darken-4.mr-2(:class='changeTextClass(itemSelectedPeriod.change)')
                  span(v-if='itemSelectedPeriod.change') {{ itemSelectedPeriod.change | numberWithCommas(2) }}
                  span(v-else) N/A

            .overline Period
            v-chip.item-lookup-period.my-2.mr-2(
              v-for='period in lookupPeriods'
              :key='period.key'
              small
              :class='isPeriodSelected(period.key) ? "item-lookup-period-selected" : ""'
              :dark='isPeriodSelected(period.key)'
              :color='periodColor(period.key)'
              @click='selectPeriod(period)'
            )
              | {{ period.label }}

            .caption.grey--text(style='line-height:1.5em;')
              .change-disclaimer(v-if='showChangeDisclaimer')
                | The Change % and Change Price are the measurement between the
                span(v-if='lookupPeriod.key === "OneDay"')  latest
                |  closed value
                span(v-if='lookupPeriod.key !== "OneDay"')  from
                  strong  {{ itemSelectedPeriod.startDate | date('MMMM Do, YYYY') }}
                |  of {{ itemSelectedPeriod.startPrice | numberWithCommas(2) }} and the current value of {{ itemSelectedPeriod.endPrice | numberWithCommas(2) }}.
              .no-data-disclaimer(v-if='getItemError')
                | No historical data available for selected period.

          .body-2.mb-2(v-if='itemSelected')
           | Only bid when this condition is met
           span(v-if="showDurationLabel")
            |  over the last
            strong.item-lookup-period-selected-text  {{ lookupPeriod.text }}

          v-row.my-0.justify-start.align-center.mb-2(v-if='itemSelected')
            v-col.py-0(style='max-width:160px;')
              v-select.mt-0.item-condition-type(
                v-model='metricType'
                :items='metricItems'
                return-object
                item-text='label'
                dense
                style='width:135px;'
                @change='metricTypeChanged')
            v-col.py-0(style='max-width:100px;')
              v-select.item-condition-comparison-type.mt-0(
                v-model='operatorValue'
                :items='operatorItems'
                dense
                style='width:75px;')
            v-col.py-0.item-condition-value(:style="`max-width: ${valInputWidth}px;`")
              v-form
                v-text-field.mt-0(
                  :value='metricValue'
                  @input='metricValueChanged'
                  :rules='minPriceRule'
                  dense
                  :suffix='isChangePercent ? "%" : null')

        div
          a.caption.grey--text(style="line-height: 1rem;" href="https://financialmodelingprep.com/developer/docs/" target='_blank') Data provided by Financial Modeling Prep

</template>

<style scoped>
.symbol {
  width:60px;
}
</style>

<script>
import _ from 'lodash'
import services from './services'

export default {
  name: 'FinanceMomentItem',
  props: {
    financeType: {
      type: String
    },
    item: {
      type: Object
    },
    authToken: {
      type: String
    }
  },
  data () {
    return {
      metricItems: services.getListOfConditionTypes(),
      metricType: {
        key: 'ChangePercentage',
        label: 'Change %'
      },
      operatorItems: services.getListOfConditionOperators(),
      operatorValue: 'Over',
      metricValue: 0,
      lookupPeriods: services.getListOfLookupPeriods(),
      lookupPeriod: { key: 'OneDay', label: '1D', text: '1 Day' },
      isMenuOpen: false,
      itemSelected: null,
      itemSelectedPeriod: null,
      items: [],
      fetchingItems: false,
      inputSearch: null,
      showPopular: true,
      popularItems: services.getPopular(this.financeType),
      getItemError: false
    }
  },
  mounted: function () {
    this.items = this.popularItems
  },
  watch: {
    item (newVal) {
      if (newVal) {
        this.isMenuOpen = true

        this.items = [newVal]

        this.lookupPeriod = this.lookupPeriods.find(p => p.key === newVal.financeEvaluationCondition.lookupPeriod)
        this.metricType = this.metricItems.find(m => m.key === newVal.financeEvaluationCondition.conditionType)
        this.operatorValue = newVal.financeEvaluationCondition.comparisonType
        this.metricValue = newVal.financeEvaluationCondition.targetValue
        // console.log(newVal)
        this.selectItem(newVal)
      }
    },
    inputSearch (newVal, oldVal) {
      // console.log('inputSearch [', oldVal, '] -> [', newVal, ']')

      if (newVal) {
        this.clearPopular()

        if (newVal !== oldVal) {
          if (!this.itemSelected || (this.itemSelected.name !== newVal)) {
            this.searchItem(newVal)
          }
        }
      } else {
        if (typeof newVal === 'string') {
          this.clearSearch()
        }
      }
    },
    itemCurrentValues (newV) {
      this.$emit('onEvent', { category: 'finance', subCategory: this.financeType, action: 'dataChanged', value: newV })
    }
  },
  computed: {
    financeTypePlural () {
      return services.financeTypePlural(this.financeType)
    },
    valInputWidth () {
      return services.getFinanceMomentConditionValueInputWidth(this.metricType.key)
    },
    itemCurrentValues () {
      return services.bundleFinanceItemObject(this.itemSelected, {
        metricType: this.metricType.key,
        operatorValue: this.operatorValue,
        metricValue: this.metricValue,
        lookupPeriod: this.lookupPeriod.key
      })
    },
    showDurationLabel () {
      return services.showDurationLabel(this.metricType.key)
    },
    isChangePercent () {
      return this.metricType.key === 'ChangePercentage'
    },
    showChangeDisclaimer () {
      return this.metricType.key !== 'Price' && !this.getItemError
    },
    minPriceRule () {
      return this.metricType.key === 'Price'
        ? [v => v > 0 || 'must be greater than 0']
        : []
    }
  },
  methods: {
    changeColor (change) {
      if (!change) return 'grey'
      return parseFloat(change) >= 0 ? 'green' : 'red'
    },
    changeChipColor (change) {
      return this.changeColor(change) + ' lighten-4'
    },
    changeTextClass (change) {
      return this.changeColor(change) + '--text'
    },
    changeIconColor (change) {
      return this.changeColor(change) + ' darken-4'
    },
    changeIcon (change) {
      return parseFloat(change) > 0 ? 'mdi-arrow-up' : 'mdi-arrow-down'
    },
    selectItem (item) {
      this.itemSelected = item
      this.getItem(item.symbol)
    },
    getItem (symbol) {
      this.fetchingItem = true

      return services.getFinanceItem(this.financeType, symbol, this.lookupPeriod.key, this.authToken)
        .then(res => {
          // this.itemSelected = Object.assign({}, this.itemSelected, res.financial)
          this.itemSelectedPeriod = res.period
          this.getItemError = false
        })
        .catch(() => {
          this.getItemError = true

          this.itemSelectedPeriod = {
            change: null,
            changePercentage: null,
            endDate: this.itemSelectedPeriod && this.itemSelectedPeriod.endDate ? this.itemSelectedPeriod.endDate : null,
            endPrice: this.itemSelectedPeriod && this.itemSelectedPeriod.endPrice ? this.itemSelectedPeriod.endPrice : null,
            startDate: null,
            startPrice: null
          }
        })
        .finally(() => {
          this.fetchingItem = false
        })
    },
    searchItem: _.debounce(function (q) {
      this.fetchingItems = true

      return services.searchFinanceItem(this.financeType, q, 7, this.authToken)
        .then(res => {
          this.items = res.length
            ? res
            : [{ name: 'no-data' }]
        })
        .catch(() => {
          this.items = []
        })
        .finally(() => {
          this.fetchingItems = false
        })
    }, 500),
    clearSearch () {
      this.inputSearch = null
      this.items = []
      this.itemSelected = null
      this.itemSelectedPeriod = null
    },
    clearPopular (q) {
      if (this.showPopular) {
        this.showPopular = false
      }
    },
    openMenu () {
      this.isMenuOpen = true

      // hack because <v-autocomplete :menu-props='{ openOnFocus: true }'> doesn't work
      this.$nextTick(() => {
        this.$refs[`${this.financeType}Autocomplete`].isMenuActive = true
      })

      this.$emit('onEvent', { category: 'finance', subCategory: this.financeType, action: 'opened' })
    },
    closeMenu () {
      this.isMenuOpen = false

      this.showPopular = true
      this.items = this.popularItems

      this.itemSelected = null
      this.itemSelectedPeriod = null

      this.lookupPeriod = this.lookupPeriods[0]
      this.metricType = this.metricItems[0]
      this.operatorValue = 'Over'
      this.metricValue = 0

      this.$emit('onEvent', { category: 'finance', subCategory: this.financeType, action: 'closed' })
    },
    isPeriodSelected (periodKey) {
      return this.lookupPeriod.key === periodKey
    },
    periodColor (periodKey) {
      return this.isPeriodSelected(periodKey) ? 'primary' : ''
    },
    selectPeriod (period) {
      this.lookupPeriod = period
      this.getItem(this.itemSelected.symbol)
    },
    metricValueChanged: _.debounce(function (metricValue) {
      this.metricValue = metricValue
    }, 500),
    metricTypeChanged (metricType) {
      if (metricType.key === 'Price' && !this.metricValue) {
        this.metricValue = 1
      }

      if (metricType.key !== 'Price' && this.metricValue === 1) {
        this.metricValue = 0
      }
    }
  }
}
</script>
