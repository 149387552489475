<template lang="pug">
  div
    div.pl-0.pt-1.pb-3
      div.text-body-2 Target venues with the highest concentration of these demographic segments
      a.info--text(v-if="componentConfig.links.understandingTargeting"
                   :href="componentConfig.links.understandingTargeting" target='_blank' style='width:100%')
        small Understanding targeting based on mobile data
        v-icon(small color='info') mdi-launch

    //- Deprecated
    div(v-for='group in deprecatedTargetGroups')
      div(v-if="hasDeprecated[group.key]")
        div(v-if='!isMobileTargetsGroupExpanded(group.key)' :id="group.name.replace(/\\s|&/g, '_')")
          v-btn.ma-0(text color='primary' @click="updateTargetStateController(group.key, 1)")
            v-icon mdi-plus
            | {{ group.name + ' Range (deprecated)' }}
        sliderTarget.mb-3(
          v-if='isMobileTargetsGroupExpanded(group.key)'
          :group='group'
          :isCustomSlider="true"
          :selected='selectedMobileTargetValues(group.key, "deprecated")'
          @update='updateMobileTargets'
          @close='updateTargetStateController'
          :id="group.name.replace(/\\s|&/g, '_')"
        )

    .mobile-target-group(v-for='group in activeTargetGroups')
      div(v-if='!isMobileTargetsGroupExpanded(group.key)' :id="group.name.replace(/\\s|&/g, '_')")
        v-btn.ma-0(text color='primary' @click="updateTargetStateController(group.key, 1)")
          v-icon mdi-plus
          | {{ group.name }}

      sliderTarget.mb-3(
        v-if='isMobileTargetsGroupExpanded(group.key)'
        :group='group'
        :isCustomSlider="false"
        :selected='selectedMobileTargetValues(group.key)'
        @update='updateMobileTargets'
        @close='updateTargetStateController'
        :id="group.name.replace(/\\s|&/g, '_')"
      )
</template>

<script>
import sliderTarget from '@/components/sliderTarget.vue'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    sliderTarget
  },
  data () {
    return {
      hasDeprecated: {
        'Age-range': false,
        'Income-range': false
      }
    }
  },
  created () {
    this.$store.dispatch('general/fetchLocalText', 'targetDescriptions')
  },
  mounted () {
    if (this.isMobileTargetsGroupExpanded('Age-range')) this.hasDeprecated['Age-range'] = true
    if (this.isMobileTargetsGroupExpanded('Income-range')) this.hasDeprecated['Income-range'] = true
  },
  computed: {
    activeTargetGroups () {
      return this.$store.getters['audience/activeMobileTargetGroups']
    },
    deprecatedTargetGroups () {
      return this.$store.getters['audience/deprecatedMobileTargetGroups']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },

  methods: {
    isMobileTargetsGroupExpanded (groupKey) {
      return this.$store.getters['audience/targetStateController'](groupKey)
    },

    updateMobileTargets (field, values) {
      this.$store.dispatch('audience/updateMobileTargetsAction', { field, values })
      this.$emit('storeUpdated')
    },

    updateTargetStateController (targetKey, value) {
      this.$store.commit('audience/updateTargetStateController', { targetKey, value })

      // when closing a Mobile Target's widget, clear any values for this target
      if (value === 0) {
        this.$store.dispatch('audience/updateMobileTargetsAction', { field: targetKey, values: [] })
        this.$emit('storeUpdated')
      }
    },

    selectedMobileTargetValues (groupKey, status = null) {
      return this.$store.getters['audience/selectedMobileTargets'](groupKey, status)
    }
  }
}
</script>
