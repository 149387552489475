<template lang="pug">
  v-menu(offset-y fixed :attach='$vuetify.breakpoint.mdAndUp' left)
    template(v-slot:activator='{ on }')
      v-btn.menu-opener-btn(text v-on='on')
        v-icon.mr-4(color='grey darken-1' left large)
          | mdi-account-circle
        .d-block(style='max-width:170px;')
          .text-left.grey--text.text--darken-1.user-display-name(style='height: 14px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 200px;') {{ userDisplayName }}
          .text-left.grey--text.text--darken-1.text-capitalize.user-org()
            small {{ userOrganizationName }}
        v-icon(right) mdi-chevron-down
    v-list
      v-list-item-group()
        v-list-item.user-profile-btn(v-if="componentConfig.userSettings.isUserProfileShown" router-link :to='userProfileLink')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-account-circle
          v-list-item-title My Profile

        switchOrganization(v-if="componentConfig.userSettings.isSwitchOrganizationShown")
          template(v-slot:customActivator='')
            v-list-item.switch-org-btn
              v-list-item-icon.my-3.mr-3
                v-icon mdi-account-switch
              v-list-item-title Switch Organization

        v-divider(v-if="componentConfig.userSettings.isUserProfileShown || componentConfig.userSettings.isSwitchOrganizationShown" )

        .admin-section(v-if='!$flags.canSeeUiRebrand.isEnabled()')
          v-list-item.settings-btn(v-if="componentConfig.userSettings.isSettingsShown" router-link :to='organizationSettingsLink')
            v-list-item-icon.my-3.mr-3
              v-icon mdi-cog
            v-list-item-title Settings

          v-list-item.all-users-btn(v-if='can.read.allUsers' router-link to='/users' exact)
            v-list-item-icon.my-3.mr-3
              v-icon mdi-account-group
            v-list-item-title Manage Users
          v-list-item.all-orgs-btn(v-if='can.read.allOrgs' router-link to='/organizations' exact)
            v-list-item-icon.my-3.mr-3
              v-icon mdi-account-box-multiple
            v-list-item-title Manage Organizations

        v-divider(v-if="!$flags.canSeeUiRebrand.isEnabled() && (componentConfig.userSettings.isSettingsShown || can.read.allUsers || can.read.allOrgs)")

        v-list-item#logout(router-link to='/logout')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-logout
          v-list-item-title Logout
</template>

<script>
import switchOrganization from '@/components/switchOrganization.vue'
import componentConfig from '@/services/componentConfig'

export default {
  components: {
    switchOrganization
  },
  props: ['userDisplayName', 'userProfileLink', 'userOrganization'],
  computed: {
    can () {
      const userPerms = this.$store.getters['user/permissions']('user')
      const orgPerms = this.$store.getters['user/permissions']('organization')
      return {
        read: {
          allUsers: userPerms ? userPerms.read.allUsers : false,
          allOrgs: orgPerms ? orgPerms.read.allOrgs : false
        }
      }
    },
    userOrganizationName () {
      return this.userOrganization.name || ''
    },
    organizationSettingsLink () {
      return '/organizationsSettings/' + this.userOrganization.id
    },
    componentConfig () {
      return componentConfig(this.$store.getters['user/isForAdServer'])
    }
  }
}
</script>
