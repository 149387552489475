import ga from '@/services/tracking.ga'
import intercom from '@/services/tracking.intercom'

const availableServices = ['intercom', 'ga']

function pickServicesToUse (list, availableServices) {
  return !list || typeof list !== 'object' || !list.length ? availableServices : list.filter(e => availableServices.includes(e))
}

function boot (profile, org, services = null) {
  const trackingData = formatTrackingData(profile, org)

  const channels = pickServicesToUse(services, availableServices)
  channels.forEach(c => {
    if (c === 'ga') ga.bootAnalytics(trackingData)
    if (c === 'intercom') intercom.bootWidget(trackingData)
  })
  return 'Done booting'
}

function bootAnonymous () {
  intercom.bootWidgetAnonymous()
  return 'Done booting anonymous'
}

function formatTrackingData (user, org) {
  if (!user || !org) return null
  return {
    userId: user.id,
    userEmail: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    userRoles: user.roles.map(x => x.name).join(', '),
    organizationId: org.id,
    organizationName: org.name,
    organizationPaymentStatus: orgStatusMapper[org.paymentStatus],
    organizationPaymentType: getOrgPaymentMethod(org.paymentType),
    currencyCode: org.currency.code,
    acceptMarketingCommunication: user.allowCommercialCommunication,
    isForAdServer: org.isForAdServer
  }
}

function updatePageview (services = null, obj) {
  const channels = pickServicesToUse(services, availableServices)
  channels.forEach(c => {
    if (c === 'intercom') intercom.update()
  })
}

function sendEvent (services = null, eventType, details = null) {
  const channels = pickServicesToUse(services, availableServices)
  channels.forEach(c => {
    if (c === 'ga') ga.sendEvent(eventType, details)
  })
}

function getOrgPaymentMethod (type) {
  return type === 'Automatic' ? 'Credit Card' : type === 'Manual' ? 'Invoice' : 'None'
}

const orgStatusMapper = {
  Unspecified: 'Inactive',
  Valid: 'Active',
  Suspended: 'Suspended'
}

export default {
  formatTrackingData,
  pickServicesToUse,
  boot,
  bootAnonymous,
  updatePageview,
  sendEvent
}
