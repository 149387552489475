<script>
import { Line } from 'vue-chartjs'

import reportingService from '../services/reporting.service'
import helpersService from '../services/helpers.service'
import moment from 'moment'

export default {
  extends: Line,
  props: ['currentMetric', 'data', 'timeUnit', 'color'],
  data: () => {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 0,
        animation: {
          duration: 0 // general animation time
        },
        hover: {
          mode: 'index',
          intersect: 'false'
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              autoSkip: true,
              autoSkipPadding: 5,
              maxRotation: 0,
              padding: 0,
              z: 10
            },
            afterTickToLabelConversion: function (scaleInstance) {
              // set the first and last tick to null so it does not display
              // note, ticks[0] is the last tick and ticks[length - 1] is the first
              scaleInstance.ticks[0] = ''
              scaleInstance.ticks[scaleInstance.ticks.length - 1] = ''
            }
          }],
          yAxes: [{
            type: 'linear',
            autoSkipPadding: 300,
            gridLines: {
              drawTicks: false,
              zeroLineWidth: 1
            },
            ticks: {
              beginAtZero: true,
              labelOffset: 12,
              mirror: true,
              lineHeight: 2.7,
              padding: -8,
              display: true,
              fontColor: 'rgba(0,0,0, 0.2)',
              z: 10
            },
            afterTickToLabelConversion: function (scaleInstance) {
              // set the first and last tick to null so it does not display
              // note, ticks[0] is the last tick and ticks[length - 1] is the first

              scaleInstance.ticks[scaleInstance.ticks.length - 1] = ''
            }
          }]
        },
        tooltips: {
          intersect: false,
          mode: 'index',
          displayColors: false,
          callbacks: {
            label: null
          }
        }
      }
    }
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    config () {
      return reportingService.metricsInfos(this.currentMetric, this.marketVals.currencySymbolString)
    },
    primaryColor () {
      return this.color ? this.color : this.$vuetify.theme.themes.light.csblue
    }
  },
  mounted () {
    const gradientFill = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 170)

    gradientFill.addColorStop(0, helpersService.hexToRgba(this.primaryColor, 0.1))
    gradientFill.addColorStop(1, helpersService.hexToRgba(this.primaryColor, 0.04))

    const chartData = {
      // keep "whole" Date as the label here, to have different formats in labels vs. tooltips (see "formatting functions" below)
      labels: this.data.map(point => point.date),
      datasets: [{
        borderColor: helpersService.hexToRgba(this.primaryColor, 1),
        backgroundColor: gradientFill,
        lineTension: 0,
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointBackgroundColor: helpersService.hexToRgba(this.primaryColor, 1),
        pointRadius: this.data.length >= 150 ? 0 : 1.5,
        borderWidth: this.data.length >= 150 ? 2 : 3,
        pointHitRadius: 3,
        data: this.data.map(point => {
          const exchangeGroup = point.groups.find(x => x.key === 'Exchange')
          if (exchangeGroup) {
            return exchangeGroup[this.config.key]
          } else {
            return 0
          }
        })
      }]
    }

    const timeUnit = this.timeUnit

    // FORMATTING FUNCTIONS - Tooltip Label
    this.options.tooltips.callbacks.label = this.tooltipLabelCallback

    // FORMATTING FUNCTIONS - Tooltip Title
    function tooltipTitleCallback (tooltipItem, data) {
      var timeUnitFormat = {
        hour: 'ddd, MMM Do h:mm a',
        day: 'ddd, MMM Do YYYY',
        week: 'MMMM Do, YYYY',
        month: 'MMMM YYYY'
      }

      const { index } = tooltipItem[0]
      const { labels } = data
      const val = labels[index]

      if (timeUnit === 'week') {
        var start = helpersService.customDateFormatting(val, timeUnitFormat[timeUnit])
        var endMoment = moment(val).add(6, 'days')
        var end = helpersService.customDateFormatting(endMoment, timeUnitFormat[timeUnit])
        return start + '-' + end
      } else {
        return helpersService.customDateFormatting(val, timeUnitFormat[timeUnit])
      }
    }
    this.options.tooltips.callbacks.title = tooltipTitleCallback

    // FORMATTING FUNCTIONS - y Axis
    this.options.scales.yAxes[0].ticks.callback = this.yTicksLabelCallback

    // FORMATTING FUNCTIONS - x Axis
    function xTicksLabelCallback (val, index) {
      var timeUnitFormat = {
        hour: 'MMM Do h:mm a',
        day: 'MMM Do',
        week: 'MMMM Do, YYYY',
        month: 'MMMM YYYY'
      }
      return helpersService.customDateFormatting(val, timeUnitFormat[timeUnit])
    }
    this.options.scales.xAxes[0].ticks.callback = xTicksLabelCallback

    this.renderChart(chartData, this.options)
  },
  methods: {
    tooltipLabelCallback (tooltipItem, data, text = this.config.tooltipLabel) {
      const { index, datasetIndex } = tooltipItem
      const { datasets } = data

      return `${text || 'Value'}: ${helpersService.dataDisplayFormatting(datasets[datasetIndex].data[index], this.config.format, this.marketVals.currencySymbolString)}`
    },
    yTicksLabelCallback (val) {
      return helpersService.dataDisplayFormatting(val, this.config.format, this.marketVals.currencySymbolString)
    }
  }
}

</script>
