import Vue from 'vue'
import Vuetify from 'vuetify'
import SubdomainService from '@/services/subdomain.service'

Vue.use(Vuetify)

let defaultLight = {
  primary: '#1d3672',
  secondary: '#293d4d',
  accent: '#18d7aa',
  success: '#18d7aa',
  info: '#004fff',
  csblue: '#1d3672',
  preview: '#1d3672'
}

const polarisLight = {
  primary: '#0500A9',
  error: '#B00020',
  info: '#6279FF'
}

if (SubdomainService.isAdServerDomain()) {
  defaultLight = {
    ...defaultLight,
    ...polarisLight
  }
}

export default new Vuetify({
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: defaultLight
    }
  }
})
