<template lang="pug">
  div(:style="$vuetify.breakpoint.xsOnly ? 'padding-top: 44px;' : ''")
    vue-headful(:title="componentConfig.branding.title('Add Audience')")
    v-container.pa-0(fluid grid-list-lg text-left)
      v-row.ma-0
        v-col.pa-0.pa-sm-3(cols=12 md=6 lg=5 xl=5 style="min-height: calc(100vh - 48px);")
          .mb-6.d-flex.align-center
            v-btn#back-to-audience-btn.text-h6.ml-0.mr-2(fab small color='white' router-link :to='"/" + packageLabel + "s"')
              v-icon(medium) mdi-arrow-left
            #back-to-audience-btn-text.text-h6.ml-1.d-inline-block(class='secondary--text') Back to {{ packageLabel | capitalize }} Overview

          v-expansion-panels(multiple v-model='panelsStateController')
            panelAudience#audience-panel(
              @updateForecast='updateForecast'
              parent='addAudience'
              :parentLoading='loading'
              :downloadingInventory='downloadingInventory'
              :tab='audiencePanelTab'
              @switchTab='switchPanelAudienceTab'
              @inventoryListChanged='updateTableToMapSync'
              @downloadInventory='downloadInventory')
            .text-right(style='width: 100%;')
              v-spacer
              v-btn#create-audience-btn.my-4.mr-0(rounded large color='primary' :disabled='isForecastInventoryLoading' :loading='saving' @click='addAudience()')
                | Create {{ packageLabel | capitalize }}

        //- Map & Forecast
        v-col.ma-0.pa-0(cols=12 md=6 lg=7 xl=7 order='first' order-md='last')
          div.sticky-column
            InventoryMap(
              ref='mapComponent'
              :bound-initial-forecast='componentConfig.campaigns.boundInitialForecastCall'
              :compact-popups='$vuetify.breakpoint.xsOnly'
              :default-latitude='marketDefaultValues.mapCenter[1]'
              :default-longitude='marketDefaultValues.mapCenter[0]'
              :default-zoom='marketDefaultValues.mapZoom'
              :filter-on-move="filterOnMove"
              :forecast-loading='forecastLoading'
              :forecast-inventory='forecastInventory'
              :forecast-inventory-loading='isForecastInventoryLoading'
              :geo-targets='mapGeoTargets'
              :get-venue-details-func='getVenueDetails'
              :height='mapHeight'
              :highlighted-geo-target='highlightedGeoTargetForMap'
              :impressions='impressions'
              :map-box-token='mapboxToken'
              :readonly='$vuetify.breakpoint.xsOnly'
              :show-details-button='$vuetify.breakpoint.smAndUp'
              :show-fullscreen-button='$vuetify.breakpoint.smAndUp'
              :show-impressions='$vuetify.breakpoint.smAndUp'
              :show-inventory-summary='$vuetify.breakpoint.smAndUp'
              :show-venue-popup='true'
              :targeted-venues='targetedVenues'
              :targeted-screens='targetedScreens'
              :unit='marketDefaultValues.distanceUnit'
              :zoom-on-updated-forecast='zoomOnUpdatedForecast'
              :disable-recentering='disableRecentering'
              :resolve-geo-json-func='resolveGeoJson'
              @mapBoundsUpdated='mapBoundsUpdated'
              @filterOnMoveChecked='filterOnMoveChecked'
              @geoTargetAdded='geoTargetAdded'
              @geoTargetSelected='setFocusedGeoInfo'
              @geoTargetUnselected='unsetFocusedGeoInfo'
              @geoTargetUpdated='geoTargetUpdated'
              @geoTargetRemoved='geoTargetRemoved'
              @hideDetails='hideDetails'
              @seeDetails='updateMapToTableSync(true)'
            )
              template(v-slot:fullscreen-drawer-content='')
                forecast-inventory(
                  :loading='isForecastVenuesLoading'
                  :downloadingInventory='downloadingInventory'
                  :items='venues'
                  :total-items='targetedVenues'
                  :page='pagination.page'
                  :has-more-items='pagination.hasMorePages'
                  :updateVenueInclusionEnabled='true'
                  @updateVenueInclusion='updateVenueInclusion'
                  @inventoryListChanged="updateTableToMapSync"
                  @downloadInventory='downloadInventory'
                )

            forecastSummary(
              v-if="$vuetify.breakpoint.xsOnly"
              parent='audience'
              :showImpressions='true')
</template>
<script>
import panelAudience from '@/components/panelAudience.vue'
import forecastInventory from '@/components/forecast.inventory.vue'
import forecastSummary from '@/components/forecast.summary.vue'

import _ from 'lodash'

import audienceAPI from '@/services/audience.api'
import csvService from '@/services/csv.service'
import geoService from '@/services/geo.service'
import componentConfigService from '@/services/componentConfig'
import venuesAPI from '@/services/venues.api'

import campsiteConfig from '@/config/campsite.config'
import { InventoryMap } from '@ayudasystems/campaign-targeting'

import geoBoundingBoxService from '@/services/geo.boundingbox.service'

export default {
  components: {
    panelAudience,
    InventoryMap,
    forecastInventory,
    forecastSummary
  },
  created () {
    this.resetEditAudience()

    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
    if (this.canSeeNewUi) {
      this.packageLabel = 'package'
    }
    this.$store.dispatch('general/getExchangesTargeting').then(() => {
      this.loading = false
    })
    this.geoTargetsWithGeoJson = this.selectedGeoTargets
  },
  data () {
    return {
      loading: true,
      saving: false,

      panelsStateController: [0],

      forecastOptionsLastVersion: null,

      audiencePanelTab: 0,
      showMapOnly: false,
      mapExists: true,
      downloadingInventory: false,
      packageLabel: 'audience',
      canSeeNewUi: false,
      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      isFilterOnMoveVisible: false,
      updateGeoTargetsJsonCT: null,
      geoTargetsWithGeoJson: [],
      disableRecentering: false,
      highlightedGeoTargetForMap: null,
      geoTargetUpdatedFromMap: false
    }
  },
  computed: {
    audienceName () {
      return this.$store.getters['createCampaign/getLineName']
    },
    exchanges () {
      return this.$store.getters['general/getExchanges']
    },
    exchange () {
      return this.$store.getters['createCampaign/getExchange'] || { key: null }
    },
    isForecastInventoryLoading () {
      return this.$store.getters['audience/forecastInventoryLoading']
    },
    forecastInventory () {
      return this.$store.getters['audience/forecastInventory']
    },
    selectedGeoTargets () {
      return this.$store.getters['audience/selectedGeoTargets']
    },

    forecast () {
      return this.$store.getters['audience/forecast']
    },
    forecastLoading () {
      return this.$store.getters['audience/forecastLoading']
    },
    targetedVenues () {
      return this.forecast.inventory.numberOfVenues
    },
    targetedScreens () {
      return this.forecast.inventory.numberOfScreens
    },

    pagination () {
      return this.$store.getters['audience/getPagination']
    },
    filterAsImove () {
      return this.$store.getters['audience/filterAsImove']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    marketDefaultValues () {
      return this.$store.getters['general/marketDefaultValues']
    },
    mapHeight () {
      const offsetHeight = this.$vuetify.breakpoint.xsOnly
        ? campsiteConfig.barsHeight.appNavbar + campsiteConfig.barsHeight.forecastBar
        : campsiteConfig.barsHeight.appNavbar
      return 'calc(100vh - ' + offsetHeight + 'px)'
    },
    impressions () {
      return {
        total: this.forecast.impressions.value
      }
    },
    mapGeoTargets () {
      return this.geoTargetsWithGeoJson
        .filter(geoTarget => geoTarget.type !== 'point_of_interest')
        .map(geoTarget => this.convertGeoTargetToMapFormat(geoTarget))
    },
    focusedGeoInfo () {
      return this.$store.getters['audience/getFocusedGeoInfo']
    },
    highlightedGeoTarget () {
      const focusedGeoTargetInfo = this.focusedGeoInfo
      return focusedGeoTargetInfo.index === -1 ? null : { geoTarget: this.selectedGeoTargets[focusedGeoTargetInfo.index], centerMap: focusedGeoTargetInfo.centerMap }
    },
    filterOnMove () {
      return {
        isVisible: this.isFilterOnMoveVisible,
        value: this.filterAsImove
      }
    },
    zoomOnUpdatedForecast () {
      return this.pagination?.filtering || false
    },
    forecastVenues () {
      return this.$store.getters['audience/forecastVenues']?.venues || []
    },
    isForecastVenuesLoading () {
      return this.$store.getters['audience/forecastVenuesLoading']
    },
    venues () {
      return this.forecastVenues.map(x => {
        const venueObjInStore = this.selectedGeoTargets.find(y => y.value === x.id.toString())
        const isIncluded = !venueObjInStore || venueObjInStore.isIncluded
        return { ...x, isIncluded }
      })
    },
    mapViewPort () {
      return this.$store.getters['audience/getMapViewPort']
    }
  },
  watch: {
    async selectedGeoTargets () {
      await this.updateGeoTargetsJson(this.geoTargetUpdatedFromMap)
      this.geoTargetUpdatedFromMap = false
    },
    highlightedGeoTarget: {
      immediate: true,
      async handler (newValue) {
        let geoTargetForMap = null
        let disableRecentering = true
        if (newValue?.geoTarget) {
          const geoTargetWithGeojson = await geoService.resolveGeoJsonForGeoTarget(newValue.geoTarget)
          geoTargetForMap = this.convertGeoTargetToMapFormat(geoTargetWithGeojson)
          disableRecentering = !newValue.centerMap
        }
        this.disableRecentering = disableRecentering
        this.highlightedGeoTargetForMap = geoTargetForMap
      }
    }
  },
  methods: {
    async updateGeoTargetsJson (disableRecentering = false) {
      if (this.updateGeoTargetsJsonCT) {
        this.updateGeoTargetsJsonCT.cancel('Request canceled by a newer request.')
        this.updateGeoTargetsJsonCT = null
      }

      const { getUpdatedGeoTargets, cancelTokenSource } = geoBoundingBoxService.getGeoTargetsJsonUpdater(this.selectedGeoTargets, this.mapViewPort, disableRecentering)
      if (!getUpdatedGeoTargets) return

      this.updateGeoTargetsJsonCT = cancelTokenSource
      try {
        this.geoTargetsWithGeoJson = await getUpdatedGeoTargets()
        this.disableRecentering = disableRecentering
      } catch (e) {} finally {
        this.updateGeoTargetsJsonCT = null
      }
    },
    resetEditAudience () {
      this.$store.commit('audience/resetAudience')
      this.$store.commit('createCampaign/resetCreateCampaign')

      const orgExchange = this.exchanges.find(exchange => exchange.id === this.marketDefaultValues.id)
      this.$store.commit('createCampaign/storeExchangeDetails', { exchange: orgExchange })
      this.$store.commit('createCampaign/setLineName', this.marketDefaultValues.lineName)

      this.$store.dispatch('audience/addDigitalFaceTypeTarget')
    },

    getCommonForecastOptions () {
      // Audiences DO NOT have:
      //   - "advertiser"
      //   - "industry"
      //   - "budget"
      //   - "creatives"
      const options = {
        mapId: 'add-audience',
        exchange: this.exchange.key,
        buyerId: this.$store.getters['user/getOrganization'].id,
        targeting: this.$store.getters['audience/selectedTargets'],
        startDate: this.$store.getters['createCampaign/getFullStartDate'],
        endDate: this.$store.getters['createCampaign/getFullEndDate']
      }

      const geography = this.$store.getters['audience/geography']
      if (geography && Object.keys(geography).length > 0) {
        // do NOT send geoJSON(s) to Forecast for performance
        // easier to go through "selectedGeoTargets" and re-format
        // than trying to go through already formated "geography"
        var selectedGeoTargetsNoGeoJSON = geoService.removeGeoJSON(this.selectedGeoTargets)
        options.geography = geoService.geoTargetsToApiFormatting(selectedGeoTargetsNoGeoJSON)
      }

      const segments = this.$store.getters['audience/getEnvironmentsForecastApiFormat']
      if (segments.groups && segments.groups.length > 0) {
        options.segments = segments
      }

      return options
    },

    updateForecast () {
      const currentForecastOptions = this.getCommonForecastOptions()
      const isTargetingEqual = this.forecastOptionsLastVersion
        ? _.isEqual(this.forecastOptionsLastVersion, currentForecastOptions)
        : false

      if (!isTargetingEqual) {
        // we do a check on "forecastOptionsLastVersion" to bypass inital loading
        // BUT, when re-opening the Dialog, updateForecast() is NOT called on load,
        // hence, if User changes targeting, we won't raise the "confirmationFlag"
        if (this.forecastOptionsLastVersion) {
          this.confirmationFlag = true
        }

        this.$store.dispatch('audience/updateForecast', { ...currentForecastOptions })
        this.forecastOptionsLastVersion = JSON.parse(JSON.stringify(currentForecastOptions))
      }

      // INVENTORY
      this.updateForecastInventory()

      // Venues
      if (this.audiencePanelTab === 1 && this.filterAsImove) {
        this.updateForecastVenues()
      }
    },

    updateForecastInventory () {
      this.$store.dispatch('audience/updateForecastInventory', this.getCommonForecastOptions())
    },

    updateForecastVenues () {
      this.$store.dispatch('audience/updateForecastVenues', this.getCommonForecastOptions())
    },

    setFocusedGeoInfo (geoTarget) {
      const storeIndex = this.selectedGeoTargets.findIndex(selectedGeoTarget => this.isSameGeoTarget(selectedGeoTarget, geoTarget))
      this.$store.commit('audience/setFocusedGeoInfo', { index: storeIndex, centerMap: false })
    },

    unsetFocusedGeoInfo () {
      this.$store.commit('audience/setFocusedGeoInfo', { index: -1, centerMap: false })
    },

    async mapBoundsUpdated (mapApiPoco) {
      this.$store.commit('audience/setMapBounds', mapApiPoco)

      this.$store.commit('audience/setMapViewPort', mapApiPoco)
      await this.updateGeoTargetsJson(true)

      const pagination = {}
      if (this.filterAsImove) { pagination.page = 1 }
      if (this.pagination.filtering) { pagination.filtering = false }
      if (Object.keys(pagination).length) {
        this.$store.commit('audience/setPagination', pagination)
      }

      this.updateForecast()
    },

    switchPanelAudienceTab (tab) {
      this.audiencePanelTab = tab
      this.isFilterOnMoveVisible = tab === 1
      this.updateForecastVenues()
    },

    toggleView () {
      this.mapExists = false
      setTimeout(() => {
        this.mapExists = true
        this.showMapOnly = !this.showMapOnly
      }, 70)
    },

    updateMapToTableSync (seeDetails = false) {
      if (this.audiencePanelTab === 1 && seeDetails) return
      if (this.audiencePanelTab === 0) {
        this.updateForecastVenues()

        this.audiencePanelTab = 1
        if (!this.panelsStateController.includes(0)) this.panelsStateController.push(0)
        const elem = document.querySelector('#audience-panel')
        window.scrollTo(0, elem.offsetTop)

        this.isFilterOnMoveVisible = true
      } else {
        this.$store.commit('audience/setFilterAsImove', !this.filterAsImove)
      }
    },

    updateTableToMapSync (pagination) {
      pagination.filtering = this.pagination.query !== pagination.query
      this.$store.commit('audience/setPagination', pagination)
      this.updateForecastVenues()

      if (this.pagination.filtering && !this.$vuetify.breakpoint.xsOnly) {
        this.updateForecastInventory()
      }
    },

    downloadInventory (scope) {
      this.downloadingInventory = true

      const options = this.getCommonForecastOptions()
      audienceAPI.getListTargetedInventory(options, scope)
        .then(res => {
          const name = this.$store.getters['createCampaign/getLineName'] || 'audience-inventory'
          csvService.validateAndExportCsv(res, name)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.downloadingInventory = false
        })
    },

    addAudience () {
      this.saving = true

      const audienceName = this.$store.getters['createCampaign/getLineName'] || 'New audience'
      const forecast = this.$store.getters['audience/forecast']

      const audience = {
        name: audienceName,
        exchangeId: this.exchange.id,
        organizationId: this.$store.getters['user/getOrganization'].id,
        audienceSegments: this.$store.getters['audience/selectedTargets'],
        numberOfFaces: forecast.inventory.numberOfScreens,
        numberOfVenues: forecast.inventory.numberOfVenues,
        reachAvailability: forecast.impressions.value,
        startDate: this.$store.getters['audience/getFullStartDate'],
        endDate: this.$store.getters['audience/getFullEndDate']
      }

      const geography = this.$store.getters['audience/geography']
      if (geography && Object.keys(geography).length > 0) {
        audience.geography = geography
      }

      const segments = this.$store.getters['audience/getEnvironmentsApiFormat']
      if (segments && Object.keys(segments).length > 0) {
        audience.segments = segments
      }

      this.$store.dispatch('audience/addAudience', audience)
        .then(resp => {
          // process demographic report each time
          audienceAPI.processAudience(resp.data.id)
            .finally(() => {
              this.$store.commit('snackbar/setSnackbar', {
                type: 'success',
                msg: `Audience ${audienceName} has been ${this.pageType}ed`
              })

              this.saving = false

              // redirect to this audience's page
              this.$router.push({ name: 'Audience Page', params: { audienceId: resp.data.id } })
            })
        })
        .catch(error => {
          let msg = error.data.errors[0].errorCode
          if (msg === 'unexpected') {
            msg = 'Unexpected: ' + error.data.errors[0].id
          }

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })

          this.saving = false
        })
    },
    hideDetails () {
      this.isFilterOnMoveVisible = false
    },
    filterOnMoveChecked (isChecked) {
      this.$store.commit('audience/setFilterAsImove', isChecked)
    },
    getVenueDetails (venueId) {
      return venuesAPI.getVenueDetails(venueId)
    },
    geoTargetAdded (geoTarget) {
      this.geoTargetUpdatedFromMap = true
      const obj = this.convertGeoTargetToStoreFormat(geoTarget)
      this.$store.dispatch('audience/updateGeoTargetsAction', [...this.selectedGeoTargets, obj])
      this.updateForecast()
    },
    geoTargetUpdated (geoTarget, mapIndex) {
      this.geoTargetUpdatedFromMap = true

      const initialUpdatedGeoTarget = this.mapGeoTargets[mapIndex]
      const storeIndex = this.selectedGeoTargets.findIndex(selectedGeoTarget => this.isSameGeoTarget(selectedGeoTarget, initialUpdatedGeoTarget))

      const updatedGeoTargets = this.selectedGeoTargets
      updatedGeoTargets[storeIndex] = this.convertGeoTargetToStoreFormat(geoTarget)

      this.$store.dispatch('audience/updateGeoTargetsAction', updatedGeoTargets)
      this.updateForecast()
    },
    geoTargetRemoved (geoTarget) {
      this.geoTargetUpdatedFromMap = true

      const storeIndex = this.selectedGeoTargets.findIndex(selectedGeoTarget => this.isSameGeoTarget(selectedGeoTarget, geoTarget))
      const copy = [...this.selectedGeoTargets]
      copy.splice(storeIndex, 1)
      this.$store.dispatch('audience/updateGeoTargetsAction', copy)
      this.updateForecast()
    },
    convertGeoTargetToStoreFormat (geoTarget) {
      return {
        geography: geoTarget.geolocation.geography,
        isIncluded: geoTarget.isIncluded,
        label: geoTarget.geolocation.label,
        radius: geoTarget.radius,
        type: geoTarget.geolocation.type,
        value: geoTarget.geolocation.value
      }
    },
    convertGeoTargetToMapFormat (geoTarget) {
      return {
        isIncluded: geoTarget.isIncluded,
        radius: geoTarget.radius,
        isUnrendered: geoTarget.isOutsideOfViewPort,
        geolocation: {
          label: geoTarget.label,
          value: geoTarget.value,
          type: geoTarget.type,
          geography: {
            latitude: geoTarget.geography?.latitude,
            longitude: geoTarget.geography?.longitude,
            geoJSON: geoTarget.geography?.geoJSON
          }
        }
      }
    },
    isSameGeoTarget (geoTargetFromStore, geoTargetFromMap) {
      return geoTargetFromStore.value === geoTargetFromMap.geolocation.value &&
        geoTargetFromStore.label === geoTargetFromMap.geolocation.label &&
        geoTargetFromStore.radius === geoTargetFromMap.radius &&
        geoTargetFromStore.isIncluded === geoTargetFromMap.isIncluded
    },
    updateVenueInclusion (venue) {
      this.$store.dispatch('audience/updateVenueInclusion', venue)
    },
    async resolveGeoJson (geographyId, geoJSONFileUrl) {
      return await geoService.resolveGeoJson(geographyId, geoJSONFileUrl)
    }
  }
}
</script>

<style scoped lang="stylus">
.paddingXless >>> .v-expansion-panel-content__wrap
  padding-left: 0px
  padding-right: 0px
</style>
