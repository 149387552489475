import creativesApi from '@/services/creatives.api'
import campsiteConfig from '@/config/campsite.config'
import tracking from '@/services/tracking'

export default {
  namespaced: true,
  state: {
    loading: false,
    uploaded: [],
    // thumbs (?)

    advertisersList: [],
    industriesList: []
  },
  getters: {
    isLoading: state => {
      return state.loading
    },
    getUploadingCreative: state => fileId => {
      return state.uploaded.find(creative => creative.fileId === fileId)
    },
    getUploadingCreatives: state => {
      return state.uploaded
    },
    AddedCreatives: state => {
      return state.uploaded
    }
  },
  mutations: {
    setLoading: (state, loading) => {
      state.loading = loading
    },
    addUploadingCreative: (state, creative) => {
      state.uploaded.push(creative)
    },
    removeUploadingCreative: (state, creative) => {
      var { fileId } = creative

      var uploadedFileIndex = state.uploaded.findIndex(f => f.fileId === fileId)
      if (uploadedFileIndex > -1) {
        state.uploaded.splice(uploadedFileIndex, 1)
      }
    },
    setFileUploadingProgress: (state, file) => {
      var { fileId, progress } = file
      var uploadedFile = state.uploaded.find(f => f.fileId === fileId)
      if (uploadedFile) {
        uploadedFile.progress = progress
      }
    },
    setUploadingCreativeRes: (state, file) => {
      var { fileId, res } = file

      var uploadedFile = state.uploaded.find(f => f.fileId === fileId)
      Object.assign(uploadedFile, res)

      // var uploadedFileIndex = state.uploaded.findIndex(f => f.fileId === fileId)
      // if (uploadedFileIndex > -1) {
      //   state.uploaded[uploadedFileIndex] = { ...state.uploaded[uploadedFileIndex], ...res }
      // }
    },
    setCreative (state, creative) {
      var creativeIndex = state.uploaded.findIndex(c => c.id === creative.id)
      if (creativeIndex > -1) {
        Object.assign(state.uploaded[creativeIndex], creative)
      }
    },
    removeCreative (state, creativeId) {
      var creativeIndex = state.uploaded.findIndex(c => c.id === creativeId)
      if (creativeIndex > -1) {
        state.uploaded.splice(creativeIndex, 1)
      }
    },
    resetCreatives: (state) => {
      state.uploaded = []
    },
    stopProgress (state, fileId) {
      var creativeIndex = state.uploaded.findIndex(c => c.fileId === fileId)
      if (creativeIndex > -1) {
        delete state.uploaded[creativeIndex].progress
      }
    }
  },
  actions: {
    getCreativeThumbnail (context, [creativeId, dimensions]) {
      return creativesApi.getCreativeThumbnail(creativeId, dimensions)
    },
    uploadCreative ({ commit, getters }, formData) {
      const file = formData.get('file')
      const fileId = formData.get('fileId')
      const industry = formData.get('industry')

      // still need to "capitalize" it using Filter...
      const fileType = file.type.split('/')[0]
      const language = formData.get('language') ? formData.get('language') : 'EN'

      const uploadingCreative = {
        fileId,
        progress: 1,
        error: '',
        name: file.name,
        language,
        type: fileType,
        size: file.size
      }
      commit('addUploadingCreative', uploadingCreative)

      commit('setFileUploadingProgress', { fileId, progress: 1 })

      const onUploadProgressCallback = progressEvent => {
        var progressPercentage = Math.round(100.0 * progressEvent.loaded / progressEvent.total)
        commit('setFileUploadingProgress', { fileId, progress: progressPercentage })
      }

      // should we handle multiple creatives as param?
      return creativesApi.uploadCreative(formData, onUploadProgressCallback)
        .then(res => {
          // re-attach default values
          res.file = file
          res.fileId = fileId
          res.language = language
          res.industry = industry

          if (fileType === 'image') {
            res.duration = campsiteConfig.creatives.defaultDuration.image
          }

          // check that upload was not canceled by User
          if (getters.getUploadingCreative(fileId)) {
            commit('setUploadingCreativeRes', { fileId, res })
            commit('stopProgress', fileId)
            // we cannot PATCH Creative here because it would show up in the "existing" table
            // there is no "save" button, so if User does NOT edit "lang" and "duration", we need to be able to set it at some point
            // we can do it when assigning creatives to Line, if a "fileId" is present, we'll need to patch it first

            tracking.sendEvent(['ga'], 'addedCreative')
            return res
          } else {
            return {}
          }
        })
        .catch(err => {
          commit('setUploadingCreativeRes', { fileId, res: err })
          commit('stopProgress', fileId)
          return err
        })
    },
    editCreative (context, creative) {
      return creativesApi.editCreative(creative)
    },
    saveAddedCreatives ({ dispatch, commit, getters, rootState }, requiredInfos) {
      var newAdvPromise
      if (!requiredInfos.advertiser.id) {
        commit('createCampaign/setAdvertiserAndIndustry', requiredInfos, { root: true })
        newAdvPromise = dispatch('createCampaign/createAdvertiser', {}, { root: true })
      } else {
        newAdvPromise = new Promise((resolve, reject) => {
          resolve(requiredInfos.advertiser)
        })
      }

      return newAdvPromise
        .then(advertiser => {
          if (!requiredInfos.advertiser.id) {
            commit('createCampaign/clearAdvertiserAndIndustry', {}, { root: true })
          }
          if (!advertiser.id) {
            throw new Error('Account not created.')
          }

          var creativePromises
          var creatives = getters.getUploadingCreatives.filter(x => !x.error)
          if (creatives.length) {
            creativePromises = creatives.map(creative => {
              creative.accountId = advertiser.id
              creative.industry = requiredInfos.industry.name
              return creativesApi.editCreative(creative)
            })
            return Promise.all(creativePromises)
          } else {
            return new Promise((resolve, reject) => {
              resolve([])
            })
          }
        })
    }
  }
}
