<template lang="pug">
  v-app#app
    navbar(
      v-if='!canSeeNewUi && isLoggedIn'
      :app-ready='appReady && themeLoaded'
      @toggleDrawer='isDrawerOpen = !isDrawerOpen'
      :user-display-name='userDisplayName'
      :user-profile-link='userProfileLink'
      :user-organization='userOrganization'
      )
    navbarNew(
      v-if='canSeeNewUi && isLoggedIn'
      :app-ready='appReady && themeLoaded'
      @toggleDrawer='toggleDrawer'
      :user-display-name='userDisplayName'
      :user-profile-link='userProfileLink'
      :user-organization='userOrganization'
      )
    navbarPublic(
      v-if="!isLoggedIn && !['signup', 'login'].includes($route.name)"
      )
    drawer(
      v-if='!canSeeNewUi'
      :drawer='isDrawerOpen'
      @drawerClosed='isDrawerOpen = !isDrawerOpen'
      :user-display-name='userDisplayName'
      :user-profile-link='userProfileLink'
      :user-organization='userOrganization'
      )
    drawerNew(
      v-if='isLoggedIn && canSeeNewUi && appIsLoaded'
      :app-ready='appReady && themeLoaded'
      :drawer='isDrawerOpen'
      :user-display-name='userDisplayName'
      :user-profile-link='userProfileLink'
      :user-organization='userOrganization'
      )

    v-main
      //- SVG Logo Loader
      v-container(fluid style='height: 100%;' v-if='!appReady || mustAcceptTerms || (!themeLoaded && !isSharedWithToken)')
        loadingBroadsignLogo

      //-  MAIN content
      router-view(v-if='appReady && !mustAcceptTerms && (themeLoaded || isSharedWithToken)')

      //- TERMS
      termsAcceptDialog(v-if='appReady && mustAcceptTerms')

    footerApp(v-if='(isLoggedIn || isSharedPage) && showFooter')
    snackbar
    inAppChangeNotificationSnackbar.multi-tab-snackbar(
      v-if='multiTabEvent'
      :multiTabEvent='multiTabEvent'
    )
</template>

<script>
import navbar from '@/components/navbar.vue'
import navbarNew from '@/components/navbarNew.vue'
import navbarPublic from '@/components/navbarPublic.vue'
import drawer from '@/components/drawer.vue'
import drawerNew from '@/components/drawerNew.vue'
import footerApp from '@/components/footerApp.vue'
import snackbar from '@/components/snackbar.vue'
import termsAcceptDialog from '@/components/termsAcceptDialog.vue'
import loadingBroadsignLogo from '@/components/loadingBroadsignLogo.vue'
import inAppChangeNotificationSnackbar from '@/components/inAppChangeNotificationSnackbar.vue'

import helpers from '@/services/helpers.service'
import multiTabService from '@/services/multiTabNotifications'

export default {
  name: 'App',

  components: {
    navbar,
    navbarNew,
    navbarPublic,
    drawer,
    drawerNew,
    footerApp,
    snackbar,
    termsAcceptDialog,
    loadingBroadsignLogo,
    inAppChangeNotificationSnackbar
  },

  data: () => ({
    isDrawerOpen: false,
    multiTabEvent: null
  }),
  computed: {
    isLoggedIn () {
      return this.$store.getters['auth/isLoggedIn']
    },
    userProfileLink () {
      const user = this.$store.getters['user/getProfile']
      if (user) {
        return /users/ + user.id
      } else {
        return '#'
      }
    },
    userDisplayName () {
      return this.$store.getters['user/getUserDisplayName']
    },
    userOrganization () {
      return this.$store.getters['user/getOrganization']
    },
    appReady () {
      return this.$store.getters['user/getAppReady'] && this.$flagsPlugin.loaded
    },
    mustAcceptTerms () {
      return this.$store.getters['user/getProfile'] && !this.$store.getters['user/termsAccepted'] && this.userOrganization.type === 'Default'
    },
    isSharedPage () {
      return this.$route.name === 'Proposal' || this.$route.name === 'Plan' || this.$route.name === 'Auction Package Page'
    },
    advertiser () {
      return this.$store.getters['proposals/advertiser'] || this.$store.getters['auctionPackage/getAdvertiser']
    },
    isSharedWithToken () {
      return this.isSharedPage && !this.isLoggedIn
    },
    themeLoaded () {
      return this.$store.getters['general/getIsThemeLoaded']
    },
    showFooter () {
      return !['create-campaign', 'Audience Page', 'Auction Package Page', 'Create Auction Package', 'Add Audience', 'edit-line-order', 'General Editor'].includes(this.$route.name)
    },
    appIsLoaded () {
      return this.appReady && !this.mustAcceptTerms && (this.themeLoaded || this.isSharedWithToken)
    },
    canSeeNewUi () {
      return this.$flagsPlugin.loaded && this.$flags.canSeeUiRebrand.isEnabled()
    }
  },
  watch: {
    userOrganization (newV) {
      if (newV && newV.id) this.getTheme(newV.id)
    },
    advertiser (newV) {
      if (newV && !this.themeLoaded) this.getTheme(newV.buyerId)
    },
    isLoggedIn (newV) {
      if (newV) this.$store.commit('general/setIsThemeLoaded', false)
      else {
        if (this.$route.name === 'logout') this.clearTheme()
      }
    },
    isSharedWithToken (newV) {
      if (newV) {
        this.$store.commit('general/setIsThemeLoaded', false)
      }
    }
  },
  methods: {
    getTheme (orgId) {
      const obj = { orgId }
      if (this.isSharedWithToken) obj.scopedToken = this.$route.query.token
      this.$store.dispatch('general/fetchTheme', obj).then(theme => {
        if (theme && theme.PrimaryColour) {
          this.$vuetify.theme.themes.light.primary = theme.PrimaryColour
        }
      })
    },
    clearTheme () {
      this.$store.commit('general/clearTheme')
      this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.light.csblue
      this.$vuetify.theme.themes.light.preview = this.$vuetify.theme.themes.light.csblue
      this.$store.commit('general/setIsThemeLoaded', true)
    },
    initializeDrawer () {
      if (helpers.isAppDrawerOpen(this.$vuetify.breakpoint.xsOnly, this.canSeeNewUi)) {
        this.isDrawerOpen = true
      }
    },
    toggleDrawer () {
      this.isDrawerOpen = !this.isDrawerOpen
      localStorage.setItem('isAppDrawerOpen', this.isDrawerOpen)
    }
  },
  created () {
    this.$logger.info('app.created')

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          if (registrations && registrations.length) {
            this.$logger.info('SW.registrations.found', { num: registrations.length })

            for (const registration of registrations) {
              registration.unregister()
                .then(unregistered => {
                  this.$logger.info('SW.unregistration.result', { unregistered })
                })
                .catch(err => {
                  this.$logger.info('SW.unregistration.error', { err })
                })
            }
          }
        })
        .catch(err => {
          this.$logger.info('SW.getRegistrations.error', { err })
        })
    }

    multiTabService.clearNotifications()
    window.addEventListener('storage', (e) => {
      const notif = multiTabService.extractNotification(e)
      if (notif) this.multiTabEvent = notif
    })

    window.addEventListener('roxIsReady', () => {
      this.initializeDrawer()
    })
  },
  mounted () {
    if (!this.isLoggedIn) this.$store.commit('general/setIsThemeLoaded', true)
  }
}
</script>
