<template lang="pug">
div(v-if="availableItems")
  div(v-if="!isTargetOpen()")
    v-btn.ma-0.d-block(text color='primary' @click='isManuallyOpened = true')
      v-icon(left) mdi-plus
      | {{ info.label }}
  v-card.d-block.mb-3(color='grey lighten-4' v-else)
    v-card-title.pt-2 {{ info.label }}
      v-spacer
      v-btn(icon small @click="closeTarget()")
        v-icon(color='grey') mdi-close
    .treeview-container.px-4.pt-0.pb-3
      v-treeview(
        v-for="g in groups" :key='g'
        selectable dense
        :open-all='showOpenTree || !!selectedSubGroup(g).length'
        color='primary' selected-color='primary'
        open-on-click transition
        item-key='value'
        :items="availableItems.grouped[g]"
        :value="selectedSubGroup(g)"
        @input="groupSelectedTargets(g, ...arguments)"
        )
        template(v-slot:label='{ item, selected }')
          .treeview-item(:class='{ "treeview-item-selected" : selected }') {{ item.name }}
</template>

<script>
import audienceService from '@/services/audience.service'
import tracking from '@/services/tracking'

export default {
  props: ['targetRef'],
  data () {
    return {
      isManuallyOpened: false,
      targetsMetadata: [
        {
          ref: 'CreativeFormats',
          listGetter: 'creativeFormatsList',
          gaEvent: 'CreativeFormat',
          label: 'Creative Formats'
        },
        {
          ref: 'CreativeSizes',
          listGetter: 'creativeSizesList',
          gaEvent: 'CreativeSize',
          label: 'Orientation & Creative Sizes'
        }
      ]
    }
  },
  computed: {
    info () {
      return this.targetsMetadata.find(x => x.ref === this.targetRef)
    },
    showOpenTree () {
      return this.info.ref !== 'CreativeSizes'
    },
    availableItems () {
      return this.$store.getters['audience/' + this.info.listGetter]
    },
    groups () {
      return Object.keys(this.availableItems.grouped)
    },
    selectedItems () {
      return this.$store.getters['audience/selected' + this.info.ref]
    }
  },
  watch: {
    selectedItems (newV, oldV) {
      const diffs = audienceService.getAddedAndRemovedItemsOfList(oldV, newV)
      for (const checked of audienceService.getTargetValueLabels(diffs.checked, this.availableItems.ungrouped)) {
        tracking.sendEvent(['ga'], `added${this.info.gaEvent}`, { label: checked })
      }
      for (const unchecked of audienceService.getTargetValueLabels(diffs.unchecked, this.availableItems.ungrouped)) {
        tracking.sendEvent(['ga'], `removed${this.info.gaEvent}`, { label: unchecked })
      }
    }
  },
  mounted () {
    if (this.selectedItems.length) this.isManuallyOpened = true
  },
  methods: {
    isTargetOpen () {
      return this.selectedItems.length || this.isManuallyOpened
    },
    selectedSubGroup (subgroup) {
      return this.info.ref === 'CreativeSizes'
        ? this.selectedItems.filter(x => audienceService.getOrientation(x) === subgroup)
        : this.selectedItems
    },
    groupSelectedTargets (subgroup, vals) {
      const allVals = [...vals]
      for (const g of this.groups.filter(x => x !== subgroup)) {
        allVals.push(...this.selectedSubGroup(g))
      }
      this.updateTargeting(allVals)
    },
    updateTargeting (vals) {
      const obj = audienceService.convertToApiFormatScreenTarget(this.info.ref, vals)
      if (obj) this.$store.commit('audience/updateTargetingTarget', obj)
      this.$emit('targetChanged')
    },
    closeTarget () {
      this.updateTargeting([])
      this.isManuallyOpened = false
    }
  }
}
</script>
