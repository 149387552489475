
/**
 * @param {string} itemName
 * @param {boolean} isSelected
 * @param {string} prefix
 */
function buildTreeItemCssClasses (itemName, isSelected, prefix) {
  const cls = ['treeview-item']
  if (isSelected) cls.push('treeview-item-selected')
  if (prefix) cls.push(prefix.toLowerCase())
  if (itemName) {
    const label = itemName === 'Advertiser' ? 'advrt' : itemName.toLowerCase().replace(' ', '-')
    cls.push(label)
  }
  return cls.join(' ')
}

export default { buildTreeItemCssClasses }
