<template lang="pug">
v-dialog(v-model='dialog' width='500' :fullscreen='$vuetify.breakpoint.smAndDown')
  template(v-slot:activator='{on}')
    div(v-on='on')
      slot(name='customActivator')

  v-card
    v-card-title
      .text-h5 Switch Organization
      v-spacer
      v-btn(small icon)
        v-icon(color='grey' @click='dialog = false') mdi-close

    v-list(two-line dense)
      v-list-item
        v-list-item-avatar
          v-icon(color='success' large) mdi-check-circle
        v-list-item-content
          v-list-item-title
            .text-h6 {{ userOrganization.name }}
          v-list-item-subtitle {{ formatOrganizationAddress(userOrganization.address) }}

    v-list(two-line subheader dense)
      .body-1.pl-4
        strong Available organizations

      v-list-item(v-show='!availableOrganizations.length')
        v-list-item-content
          v-list-item-title No other organization found.

      v-list-item.available-org(v-for='organization in availableOrganizations' :key='organization.id' @click='switchActiveOrganization(organization)')
        v-list-item-content
          v-list-item-title
            span {{ organization.name }}
            span.grey--text.text--lighten-1  - {{ organization.currency ? organization.currency.code : 'CAD' }}
            span.grey--text.text--lighten-1 &nbsp;&num;{{ organization.id }}
          v-list-item-subtitle {{ formatOrganizationAddress(organization.address) }}
</template>

<script>
import multiTabService from '@/services/multiTabNotifications'

export default {
  data () {
    return {
      dialog: false,
      fetchedAvailables: false
    }
  },
  watch: {
    dialog: function (newVal, oldVal) {
      if (newVal && !this.fetchedAvailables) {
        this.$store.dispatch('user/getUserAvailableOrganizations')
        this.fetchedAvailables = true
      }
    }
  },
  computed: {
    userOrganization () {
      return this.$store.getters['user/getOrganization']
    },
    availableOrganizations () {
      return this.$store.getters['user/getAvailableOrganizations'].filter(organization => organization.id !== this.userOrganization.id)
    }
  },
  methods: {
    switchActiveOrganization (organization) {
      this.$store.dispatch('user/setUserActiveOrganization', organization.id)
        .then(async user => {
          this.dialog = false
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: `Successfully switched to organization ${organization.name}`
          })

          multiTabService.pushNotification('switchedOrganization')
          await this.$store.dispatch('auth/refreshToken')
          this.$router.push({ name: 'Campaigns Overview' })
          this.$router.go()
        })
    },
    formatOrganizationAddress (address) {
      if (!address) { return '' }

      const addressFieldsDisplay = ['addressLine1', 'addressLine2', 'postalCode', 'city', 'state']
      const providedFields = addressFieldsDisplay.filter(af => !!address[af])
      const providedValues = providedFields.map(pf => {
        const providedValue = address[pf]
        return typeof providedValue === 'string'
          ? providedValue
          : providedValue.name
      })

      return providedValues.join(', ')
    }
  }
}
</script>
