<template lang="pug">
  v-card(:tile='$vuetify.breakpoint.xsOnly')
    v-card-title(:class="{'mb-12': $vuetify.breakpoint.xsOnly}")
      .text-h5.text-capitalize Pay invoice {{ invoiceNumber }}
    v-card-text
      v-container
        v-layout(row wrap)
          v-flex.mt-2(xs12)
            p.mb-0
              | I confirm that the invoice
              b  &#35;{{ invoiceNumber }}
              |  of
              b  {{ this.invoice.amountIncludingTaxes | currency }}
              |  will be paid with the selected credit card :
          v-flex(xs12)
            //- Loading...
            p.loading.mt-4(fill-height v-if='cardsLoading')
              v-progress-circular.mr-2(indeterminate :width=2 size='18')
              | Loading Credit Card(s)

            v-list(v-if='!cardsLoading' dense)
              v-list-item-group.cards(v-model='selectedCardId' mandatory active-class='card-selected')
                //- Available ccard(s)
                v-list-item.card-existing(v-for='card in cards' :key='card.id' :value='card.id' color='primary')
                  v-list-item-avatar.my-0.mr-3(v-if='$vuetify.breakpoint.smAndUp')
                    v-icon mdi-credit-card
                  v-list-item-content
                    v-list-item-title {{ cardName(card) }}
                    v-list-item-subtitle
                      | Expires on {{ card.exp_month + '/' + card.exp_year }}

                //- New ccard
                v-list-item.card-new(value='new' color='primary')
                  v-list-item-avatar.my-0.mr-3(v-if='$vuetify.breakpoint.smAndUp')
                    v-icon mdi-plus
                  v-list-item-content
                    v-list-item-title Add Credit Card

          v-flex(v-show='isNewCard')
            v-form(ref='cardForm')
              v-container
                v-layout(row wrap)
                  v-flex(sm12)
                    .stripe-container.pb-2.mb-3
                      card(
                        class='stripe-card'
                        :stripe='stripeKey'
                        :options='{style: stripeStyling}'
                        @change='stripeErrorHandling'
                      )
                    .error--text.text-caption.mt-1(v-if='stripeErrorMsg') {{ stripeErrorMsg }}

                  //- v-flex
                    v-checkbox(label='Save Credit Card' v-model='saveNewCard' color='primary')

    v-card-actions
      v-flex(xs12)
        v-img(:src="require('@/assets/powered_by_stripe.png')" width=119 height=26)
      v-spacer
      v-btn.cancel(text @click='closeDialog()') cancel
      v-btn.proceed(text color='primary' @click='processPayment' :loading='processingPayment' :disabled='disabledBtn') Proceed to payment
</template>

<script>
import billingService from '@/services/billing.service'

import { Card, createToken } from 'vue-stripe-elements-plus'

export default {
  components: {
    Card
  },
  props: ['invoice'],
  data () {
    return {
      selectedCardId: null,
      saveNewCard: true,
      processingPayment: false,
      cardInfoCompleted: false,

      stripeErrorMsg: null,
      stripeStyling: {
        base: {
          color: '#32325d',
          fontFamily: 'Open Sans, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: 'error',
          iconColor: 'error'
        }
      }
    }
  },
  created () {
    if (!this.cards) {
      this.getPaymentMethods()
    } else {
      this.selectDefaultCard()
    }
  },
  computed: {
    organization () {
      return this.$store.getters['user/getOrganization']
    },
    disabledBtn () {
      return this.cardsLoading || this.processingPayment || (this.isNewCard && !this.cardInfoCompleted)
    },
    invoiceNumber () {
      return this.invoice.invoiceNumber
    },
    cards () {
      return this.$store.getters['billing/cards']
    },
    isNewCard () {
      return this.selectedCardId === 'new'
    },
    cardsLoading () {
      return this.$store.getters['billing/fetchingCards']
    },
    stripeKey () {
      const keyName = 'VUE_APP_STRIPE_KEY'
      return process.env[keyName]
    }
  },
  watch: {
    cards (newV) {
      this.selectDefaultCard()
    }
  },
  methods: {
    getPaymentMethods () {
      this.$store.dispatch('billing/getActivePaymentMethods', this.organization.id)
    },
    selectDefaultCard () {
      this.selectedCardId = this.cards.length ? this.cards[0].id : 'new'
    },
    closeDialog (successfulCharge = null) {
      this.$emit('closeDialog', successfulCharge)
    },
    cardName (card) {
      return card.brand + ' *' + card.last4
    },
    stripeErrorHandling (event) {
      this.cardInfoCompleted = event.complete
      this.stripeErrorMsg = event.error ? event.error.message : null
    },

    /**
     * Go through all steps of processing a payment.
     * Since (Stripe Element's) token can only be used once, use it to addCard and we'll use the (resulting) card.id to pay invoice
     */
    processPayment () {
      this.processingPayment = true

      return this.validateCreditCard()
        .then(this.saveCreditCard)
        .then(this.payInvoice)
        .then(this.closeDialog)
        .catch(this.handleErrors)
        .finally(() => {
          this.processingPayment = false
        })
    },

    validateCreditCard () {
      var validated = this.isNewCard
        ? this.$refs.cardForm.validate() && this.cardInfoCompleted
        : true

      return validated ? Promise.resolve() : Promise.reject(new Error('Form not validated'))
    },

    /**
     * Checks if user wants to save its credit card for future use.
     * If yes, use (Stripe Element's) token to addCard() and then use resulting "card.id" to payInvoice()
     * If not, use (Stripe Element's) token to payInvoice() we cannot link the "charge" in Stripe to the customer
     */
    saveCreditCard () {
      if (!this.isNewCard) return Promise.resolve(this.selectedCardId)

      return this.createStripeToken()
        .then(tokenId => {
          this.token = tokenId

          var addCardPromise = this.saveNewCard ? this.createStripeCard(tokenId) : Promise.resolve({ id: null })
          return addCardPromise
            .then(card => card.id)
        })
    },

    createStripeToken () {
      return createToken()
        .then(result => {
          var { token } = result
          return token.id
        })
    },

    createStripeCard (tokenId) {
      return billingService.addCard(tokenId)
        .then(resp => resp.data)
    },

    /**
     * Pay Invoice
     * Pays a given invoice, with a given card (existing or new), via Billing service.
     * Billing service will call CLIENT API, which handles new/existing card, i.e. ONLY ONE OF cardId/token param must be set.
     * @see https://stackoverflow.com/questions/34415987/stripe-payment-getting-error-as-customer-cus-does-not-have-a-linked-card
     */
    payInvoice (cardId) {
      var tokenId = cardId ? null : this.token
      return billingService.payInvoice(this.invoice, cardId, tokenId)
        .then(resp => resp.data)
    },

    handleErrors (err) {
      this.$store.commit('snackbar/setSnackbar', {
        type: 'error',
        msg: err.response.data.message
      })

      this.processingPayment = false
    }
  }
}
</script>

<style scoped>
.stripe-container {
  border-bottom: 1px solid rgba(0,0,0,0.42);
}
.stripe-container:hover {
  border-bottom: 1px solid rgba(0,0,0,0.87);
}
</style>
