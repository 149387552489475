<template lang="pug">
div
  //- Summary
  v-container.pt-1(fluid, grid-list-lg, text-left )
    //- 1) Campaign
    v-layout(row, wrap v-if="currentInstance === 'campaign'")
      v-flex.pt-4.pb-0(xs12)
        reportSummaryCampaign(
          v-if='currentInstance'
          :instance-type='currentInstance'
          :instance-data='currentData'
          :is-loading='currentLoadingState'
          @campaignUpdated='updateCampaign'
          @revertCampaign='revertCampaign'
          @duplicateCampaign='duplicateCampaign'
          @archiveCampaign='archiveCampaign'
        )

    //- 2) Line
    v-layout(row, wrap fill-height v-if="currentInstance === 'line'")
      v-flex(xs12)
        //breadcrumbs(:instance-type='currentInstance')
        reportSummaryLine(
          :instance-data='currentData'
          :campaign-data="assembleDataGetter('campaign')"
          :totals-data='totalsData'
          :is-loading='currentLoadingState'
          :line-assignments='lineAssignments'
          :line-forecast='lineForecast'
          :lineCreativesLoading='lineCreativesLoading'
          @lineUpdated='updateLine'
          @duplicateLine='duplicateLines'
          @archiveLine='archiveLines'
          @openEditLineDialog='openEditLineDialog'
        )

    //- 3) Venue
    v-layout(row, wrap fill-height v-if="currentInstance === 'venue'")
      v-flex(xs12)
        reportSummaryVenue(
          :venue-data='currentData'
          :line-data="assembleDataGetter('line')"
          :is-loading='currentLoadingState'
          instance-type='venue'
          @venueUpdated='updateLine'
        )

    //- 4) Screen
    v-layout(row, wrap fill-height v-if="currentInstance === 'screen'")
      v-flex(xs12)
        reportSummaryScreen(
          :screen-data='currentData'
          :venue-data="assembleDataGetter('venue')"
          :line-data="assembleDataGetter('line')"
          :is-loading='currentLoadingState'
          instance-type='screen'
          @screenUpdated='updateLine'
        )

  //- Tabs
  v-card(color='transparent' flat)
    .tabs-wrapper.px-4(class='transparent')
      v-tabs(background-color='transparent' slider-color='primary' :value='currentTabId' @change='changeTab')
        v-tab.main-tab(v-for='tab in currentTabsDisplayed' :key='tab.id' ripple color='primary lighten-4')
          | {{ tab.name }}

    //- Demographic report
    v-divider
    v-tabs-items(v-if="currentTabName === 'demographics'")
      div.pa-3
        demographicSection(
          v-if='!currentLoadingState'
          mode='rows'
          :compareTo='compareTo'
          :startDate='instanceDates[0]'
          :endDate='instanceDates[1]'
          processDate=''
        )

    div(v-if="currentTabName !== 'demographics'")
      v-tabs-items(:value='currentTabId')
        v-container.pa-3(grid-list-sm fluid)

          //- Dates & timeUnits
          timeControlRow(
            :inventory='totalsData'
            :chart-loading='chartLoading'
            :totals-loading='totalsLoading'
            :time-unit='currentTimeUnit'
            @timeUnitUpdated='updateTimeUnit'
            @startEndDatesChange='updatestartEndDates'
            :dates-loading='datesLoading'
            :from-to-dates='datesForDisplay'
            :instance-dates='instanceFullDates'
          )

          //- Graph
          v-layout(wrap row)
            v-flex.pt-0.pb-1.px-0
              //-v-container(v-if='chartLoading' fill-height grid-list-md text-xs-center style='height: 200px')
                v-layout(row wrap align-center)
                  v-flex.text-center

              //-div(style='overflow:hidden; margin-left: -10px; margin-right:-10px;')
              div(style='height: 200px; position:relative;')
                v-fade-transition()
                  div(v-if='!chartLoading')
                    lineChart(
                      ref='chart'
                      :currentMetric='currentMetric'
                      :data='chartData'
                      :time-unit='currentTimeUnit'
                      :color='$vuetify.theme.themes.light.primary'
                      style='height: 200px;'
                      )
                v-fade-transition()
                  div(v-if='chartLoading' style='display: flex; z-index: 100; position: absolute; height: 200px; width: 100%; top: 0px;')
                    v-progress-circular.mx-auto.my-auto(:size='40' width=3 indeterminate)

          //- Metrics
          statsButtons(
            :is-loading='totalsLoading'
            :data='totalsData'
            :current-metric='currentMetric'
            @metricChanged='updateMetric'
          )

      //- Dimensions
      .tabs-wrapper.px-3.d-flex(style="background-color:white")
        v-tabs(v-if='showTabs' slider-color='primary' :value='currentDimensionId' @change='changeDimension')
          v-tab.dimensions-tab(v-for='dimension in currentDimensions' :key='dimension.id' ripple)
            | {{ dimension.name }}

        //-v-text-field(v-model='search' append-icon='search' label='Search' single-line hide-details style='max-width:300px; height:20px;')
        //-v-btn.d-block(flat icon right color='grey')
          v-icon() search
        v-spacer

        v-btn.pb-0.mt-2.hidden-xs-only(
          medium
          text
          color='primary'
          :bottom="$vuetify.breakpoint.smAndDown"
          :fixed="$vuetify.breakpoint.smAndDown"
          v-show="currentInstance === 'campaign' && !hideAddLineBtn"
          @click="openEditLineDialog"
        )
          v-icon() mdi-plus
          | Line Order
        v-tooltip(bottom color='secondary')
          template(v-slot:activator="{on}")
            v-btn.mt-0.d-sm-and-down-flex.hidden-md-and-up(
              medium
              fab
              absolute
              right
              style="z-index: 1;"
              color='primary'
              v-on="on"
              :bottom="$vuetify.breakpoint.smAndDown"
              :fixed="$vuetify.breakpoint.smAndDown"
              v-show="currentInstance === 'campaign' && !hideAddLineBtn"
              @click="openEditLineDialog"
            )
              v-icon(dark large) mdi-plus
          span Add line order to your campaign

      v-divider
      v-card(color='grey lighten-4' flat)
        reportDataTables(
          :instance='currentInstance'
          :tab-name='currentTabName'
          :dimension-name='currentDimensionName'
          :table-data='tableData'
          :total-items='tableTotals'
          :table-loading='tableLoading'
          :search='search'
          @lineStatusChanged='updateLine'
          @venueStatusChanged='updateLine'
          @screenStatusChanged='updateLine'
          @exportCsvBtnClicked='openCsvFiltersDialog'
          @lineUpdated='updateLine'
          @duplicateLines='duplicateLines'
          @archiveLines='archiveLines'
          @paginationUpdated='paginationUpdated'
          @openEditLineDialog='openEditLineDialog'
          @openTroubleshootingTool='openTroubleshootingTool'
        )
        v-divider

      advancedCsvExport(
        instance-type='venue'
        :current-dimension='currentDimension'
        :current-dimensions='currentDimensions'
        @selectedCsvFilters='exportCsv'
      )
      loadingDialog(:is-loading='csvLoading' :progress='csvProcessCompletionPecentage')

  editLineDialog.d-inline-block(
    :src="editLineSource"
    :data='lineToBeEdited'
    :dialogOpen="editLineDialogOpen"
    :campaign='campaignData'
    @close="closeEditLineDialog"
    )

  v-navigation-drawer.campaign-report-drawer(v-model='drawerOpen' fixed right temporary width='60%')
    troubleshooting-tool(v-if='troubleshootingToolOpen' :line='troubleshootLine')

</template>

<style lang="stylus" scoped>
>>> .v-btn--fab.v-btn--bottom{
  bottom: 16px !important;
}
</style>

<script>
import breadcrumbs from '@/components/reportBreadcrumbs.vue'
import reportSummaryCampaign from '@/components/reportSummaryCampaign.vue'
import reportSummaryLine from '@/components/reportSummaryLine.vue'
import reportSummaryVenue from '@/components/reportSummaryVenue.vue'
import reportSummaryScreen from '@/components/reportSummaryScreen.vue'
import lineChart from '@/components/chartLine.vue'
import statsButtons from '@/components/reportStatsButtons.vue'
import timeControlRow from '@/components/reportTimeControlRow.vue'
import reportDataTables from '@/components/reportDataTables.vue'
import advancedCsvExport from '@/components/csvAdvancedExport.vue'
import loadingDialog from '@/components/loadingDialog.vue'
import demographicSection from '@/components/demographic.vue'
import editLineDialog from '@/components/editLineDialog.vue'
import troubleshootingTool from '@/components/troubleshootingTool.vue'

import geoService from '@/services/geo.service'
import reportingService from '@/services/reporting.service'
import reportingApi from '@/services/reporting.api'
import csvService from '@/services/csv.service'
import tracking from '@/services/tracking'

import moment from 'moment'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    breadcrumbs,
    reportSummaryCampaign,
    reportSummaryLine,
    reportSummaryVenue,
    reportSummaryScreen,
    lineChart,
    statsButtons,
    timeControlRow,
    reportDataTables,
    advancedCsvExport,
    loadingDialog,
    demographicSection,
    editLineDialog,
    troubleshootingTool
  },
  watch: {
    $route (to, from) {
      // (!) NOT called on created (!)
      // will be called when changing "tab", "dimension", "timeUnit", "dates" & "metric",
      // but these are handled by click functions

      // check if "instance" has changed, i.e. going from one report to another
      const fromInstance = from.name.replace('report-', '')
      const toInstance = to.name.replace('report-', '')
      if (fromInstance !== toInstance) {
        // empty DataTable here, to avoid errors caused between the time we set instance, tab, dimension & navigatorData
        // and the time getChartData() & getTableData() are dispatched
        this.$store.commit('campaignReport/setTableData', [])
        this.$store.commit('campaignReport/setLoading', { key: 'assignedCreatives', value: true })

        this.$store.commit('campaignReport/setInstance', this.$route.name.replace('report-', ''))

        // reset "currentTab" to default
        this.$store.commit('campaignReport/setCurrentTab', this.currentTabs[0])

        // reset "currentDimension" to default, if any
        this.$store.commit('campaignReport/setDimension', this.currentDimensions && this.currentDimensions.length ? this.currentDimensions[0] : { id: 0, name: '' })

        this.$store.dispatch('campaignReport/getNavigatorData')
          .then(success => {
            if (success) {
              // if (this.currentTabName !== 'demographics') {
              if (!['demographics'].includes(this.currentTabName)) {
                this.$store.dispatch('campaignReport/getChartData')
                this.$store.dispatch('campaignReport/getTotals')
                this.$store.dispatch('campaignReport/getTableData')
              }
            }
          })
      } else {
        // handle case where same "instance" (Line), but with different ID
        if (toInstance === 'line') {
          if (from.params.lineId !== to.params.lineId) {
            // empty DataTable here, so we don't see previous Line's tableData while waiting for getNavigatorData()
            // also set various loading state for same reason
            this.$store.commit('campaignReport/setTableData', [])
            this.$store.commit('campaignReport/setLoading', { key: 'chart', value: true })
            this.$store.commit('campaignReport/setLoading', { key: 'totals', value: true })
            this.$store.commit('campaignReport/setLoading', { key: 'assignedCreatives', value: true })

            this.$store.dispatch('campaignReport/getNavigatorData')
              .then(success => {
                if (success) {
                  if (this.currentTabName !== 'demographics') {
                    this.$store.dispatch('campaignReport/getChartData')
                    this.$store.dispatch('campaignReport/getTotals')
                    this.$store.dispatch('campaignReport/getTableData')
                  }
                }
              })
          }
        }
      }
    }
  },
  created: function () {
    // set current "instance" of Report we're viewing (campaign, line, venue, screen)
    this.$store.commit('campaignReport/setInstance', this.$route.name.replace('report-', ''))
    // set current "tab" from URL
    var pathPieces = this.$route.path.split('/')
    var tabNameFromUrl = pathPieces[pathPieces.length - 1]
    var tab = this.currentTabs.find(t => t.name === tabNameFromUrl)
    this.$store.commit('campaignReport/setCurrentTab', tab)

    // set current "dimension" from URL, if present
    if (this.$route.query.dimension) {
      var dimension = this.currentDimensions.find(d => d.name === this.$route.query.dimension)
      this.$store.commit('campaignReport/setDimension', dimension)
    } else {
      if (this.currentDimensions && this.currentDimensions.length) {
        // default to 1st "dimension" if none OR not found (as query string could have been modified)
        this.$store.commit('campaignReport/setDimension', this.currentDimensions[0])
      }
    }

    // set current "metric" from URL, if present
    if (this.$route.query.metric) {
      var validatedMetric = this.metrics.find(m => m === this.$route.query.metric)
      if (validatedMetric) {
        this.$store.commit('campaignReport/setMetric', validatedMetric)
      }
    }

    // set current "timeUnit" from URL, if present
    if (this.$route.query.timeUnit) {
      var validatedUnit = this.timeUnits.find(u => u === this.$route.query.timeUnit)
      if (validatedUnit) {
        this.$store.commit('campaignReport/setTimeUnit', validatedUnit)
      }
    }

    // set current "startDate" from URL, if present
    if (this.$route.query.startDate) {
      this.$store.commit('campaignReport/setFromDate', moment.unix(this.$route.query.startDate))
    }

    // set current "endDate" from URL, if present
    if (this.$route.query.endDate) {
      this.$store.commit('campaignReport/setToDate', moment.unix(this.$route.query.endDate))
    }

    this.$store.dispatch('general/getExchangesTargeting').then(() => {
      // load instance data (campaign, line, venue, screen) from URL
      this.$store.dispatch('campaignReport/getNavigatorData')
        .then(success => {
          if (success) {
            if (!['demographics'].includes(this.currentTabName)) {
              this.$store.dispatch('campaignReport/getChartData')
              this.$store.dispatch('campaignReport/getTotals')
              this.$store.dispatch('campaignReport/getTableData')
            }
          }
        })
    })
  },
  mounted () {
    this.$root.$on('selectionFromTable', (isNotNull) => {
      this.hideAddLineBtn = isNotNull
    })
  },
  beforeDestroy: function () {
    this.$store.commit('campaignReport/resetStore')
  },
  data () {
    return {
      search: '',
      showTabs: true,
      hideAddLineBtn: false,

      // should following be define in Store ..?
      timeUnits: ['hour', 'day', 'week', 'month'],
      metrics: ['impressions', 'avgCPM', 'spent', 'adsServed'],

      selectedListFromDatatable: null,
      csvLoading: false,
      csvProcessCompletionPecentage: null,

      editLineDialogOpen: false,
      editLineSource: '',
      lineToBeEdited: {},

      drawerOpen: false,
      troubleshootingToolOpen: false,
      troubleshootLine: null
    }
  },
  computed: {
    currentInstance () {
      return this.$store.getters['campaignReport/getInstance']
    },
    currentTabs () {
      return this.$store.getters['campaignReport/getTabs']
    },
    currentTabsDisplayed () {
      return this.currentTabs.filter(tab => !tab.hidden)
    },
    currentTab () {
      return this.$store.getters['campaignReport/getTab']
    },
    currentTabId () {
      return this.currentTab.id
    },
    currentTabName () {
      return this.currentTab.name
    },
    currentDimensions () {
      return this.$store.getters['campaignReport/getDimensions']
    },
    currentDimension () {
      return this.$store.getters['campaignReport/getDimension']
    },
    currentDimensionId () {
      return this.currentDimension.id
    },
    currentDimensionName () {
      return this.currentDimension.name
    },
    currentLoadingState () {
      return this.$store.getters[reportingService.assembleLoadingGetter(this.currentInstance)]
    },
    lineCreativesLoading () {
      return this.$store.getters['campaignReport/getLineForecastLoading'] || this.$store.getters['campaignReport/getCampaignAssignmentsLoading']
    },
    currentData () {
      return this.assembleDataGetter(this.currentInstance)
    },
    lineAssignments () {
      var lineId = this.currentData ? this.currentData.id : 0
      return this.campaignAssignments.filter(a => a.lineId === lineId)
    },
    campaignAssignments () {
      return this.$store.getters['campaignReport/getAssignments']
    },
    lineForecast () {
      return this.$store.getters['campaignReport/getLineForecast']
    },
    chartData () {
      return this.$store.getters['campaignReport/getChartData']
    },
    chartLoading () {
      return this.$store.getters['campaignReport/getChartLoading']
    },
    tableData () {
      return this.$store.getters['campaignReport/getTableData']
    },
    tableTotals () {
      return this.totalsData.count
    },
    tableLoading () {
      return this.$store.getters['campaignReport/getTableLoading']
    },
    totalsData () {
      return this.$store.getters['campaignReport/getTotals']
    },
    totalsLoading () {
      return this.$store.getters['campaignReport/getTotalsLoading']
    },
    currentTimeUnit () {
      return this.$store.getters['campaignReport/getTimeUnit']
    },
    currentMetric () {
      return this.$store.getters['campaignReport/getMetric']
    },
    datesLoading () {
      return this.$store.getters['campaignReport/getDatesLoading']
    },
    datesForDisplay () {
      const from = this.$store.getters['campaignReport/getFromDate']
      const to = this.$store.getters['campaignReport/getToDate']
      if (!from || !to) {
        return [moment().subtract(31, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      } else {
        return [moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD')]
      }
    },
    instanceDates () {
      // use Line's dates for Venue/Screen Report
      var instanceToGetDatesFrom = ['venue', 'screen'].includes(this.currentInstance) ? 'line' : this.currentInstance
      var instance = this.assembleDataGetter(instanceToGetDatesFrom)
      if (instance) {
        return [moment(instance.startDate).format('YYYY-MM-DD'), moment(instance.endDate).format('YYYY-MM-DD')]
      } else {
        return [moment().subtract(31, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
      }
    },
    instanceFullDates () {
      // use Line's dates for Line/Venue/Screen Report
      var instanceToGetDatesFrom = ['venue', 'screen'].includes(this.currentInstance) ? 'line' : this.currentInstance
      var instance = this.assembleDataGetter(instanceToGetDatesFrom)
      if (instance) {
        return [moment(instance.startDate).toISOString(), moment(instance.endDate).toISOString()]
      } else {
        return [moment().subtract(31, 'days').format(), moment().format()]
      }
    },
    compareTo () {
      var instance = this.currentInstance
      var instanceId = this.$route.params[instance + 'Id']
      var prefix = instance === 'campaign' ? 'order' : instance
      return prefix + '_' + instanceId
    },
    campaignData () {
      return this.$store.getters['campaignReport/getCampaign']
    },
    distanceUnit () {
      return this.$store.getters['general/marketDefaultValues'].distanceUnit
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    changeTab (tabId) {
      // console.log('changing tab...', tabId)

      this.$store.commit('campaignReport/setTableData', [])

      // called onClick of "tab" tabs, i.e. no need to validate new "tab"
      var newTab = this.currentTabs.find(t => t.id === tabId)

      // replace "old" tab name in URL with "new" one
      var oldTab = this.$store.getters['campaignReport/getTab']

      var location = this.$router.currentRoute.path
      var index = location.lastIndexOf(oldTab.name)
      var locationPieces = location.split('')
      locationPieces.splice(index, index + oldTab.name.length, newTab.name)
      location = locationPieces.join('')
      // console.log(location)

      this.$store.commit('campaignReport/setCurrentTab', newTab)

      // remove "dimension" from URL
      var newQuery = Object.assign({}, this.$route.query)
      if (newQuery.dimension) {
        delete newQuery.dimension
      }
      this.$router.push({ path: location, query: newQuery })

      // hack so that "dimension" tabs' slider is recalculated even if same position
      this.showTabs = false
      setTimeout(() => { this.showTabs = true }, 1)

      // set default "dimension" for selected "tab"
      this.$store.commit('campaignReport/setDimension', this.currentDimensions.length ? this.currentDimensions[0] : { id: 0, name: '' })

      // fetch Data
      if (newTab.name !== 'demographics') {
        this.$store.dispatch('campaignReport/getTableData')
        this.$store.dispatch('campaignReport/getTotals', true)

        // no need to reload Chart when switching Tab,
        // unless Report was first loaded with "demographics" tab,
        // in which case "chartData" will be empty
        if (!this.chartData.length) {
          this.$store.dispatch('campaignReport/getChartData')
        }
      }
    },

    changeDimension (dimensionId) {
      // console.log('changing dimension...', dimensionId)

      this.$store.commit('campaignReport/setTableData', [])

      // called onClick of "dimension" tabs, i.e. no need to validate new "dimension"
      var dimension = this.currentDimensions.find(d => d.id === dimensionId)

      var newQuery = Object.assign({}, this.$route.query, { dimension: dimension.name })
      this.$router.push({ path: this.$route.path, query: newQuery })

      this.$store.commit('campaignReport/setDimension', dimension)
      this.$store.dispatch('campaignReport/getTableData')
      this.$store.dispatch('campaignReport/getTotals', true)
    },

    assembleDataGetter (instance) {
      let getterName = 'campaignReport/get'
      getterName += instance[0].toUpperCase() + instance.slice(1)
      var data = this.$store.getters[getterName]
      if (data && this.totalsData) {
        data.spent = this.totalsData.spent
      }
      return data
    },

    updateCampaign (changedKeyValueObj) {
      this.$store.dispatch('campaignReport/updateCampaign', changedKeyValueObj)
        .then(res => {
          // when pausing/unpausing Campaign, any Line's effectiveStatus might be reflecting this, so we need to refresh Line(s)
          if (changedKeyValueObj.status) {
            this.$store.dispatch('campaignReport/getCampaignLines')
              .then(lines => {
                this.$store.dispatch('campaignReport/getTableData')
              })
          }

          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: 'Campaign successfully updated'
          })
        })
        .catch(error => {
          let msg = error.data.errors[0].errorCode
          if (msg === 'unexpected') { msg = 'Unexpected: ' + error.data.errors[0].id }
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    archiveCampaign () {
      const modifiedFields = {
        id: this.currentData.id,
        status: 'Archived'
      }
      this.$store.dispatch('campaignReport/updateCampaign', modifiedFields)
        .then(removed => {
          if (removed) {
            const msg = this.currentData.name + ' successfully archived'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            this.$router.push({ name: 'Campaigns Overview' })
          } else {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'Something went wrong while archiving campaign.'
            })
          }
        })
        .catch(error => {
          const msg = error.response.data.errors[0].message
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    revertCampaign () {
      const label = this.$flags.canSeeUiRebrand.isEnabled() ? 'Plan' : 'Proposal'
      const modifiedFields = {
        id: this.currentData.id,
        status: 'Proposal'
      }
      this.$store.dispatch('campaignReport/updateCampaign', modifiedFields)
        .then(proposal => {
          if (proposal) {
            const msg = `${proposal.name} successfully reverted to ${label.toLowerCase()}`
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            this.$router.push({ name: label, params: { id: proposal.id } })
          } else {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `Something went wrong while reverting to ${label.toLowerCase()}.`
            })
          }
        })
        .catch(error => {
          const msg = error.response.data.errors[0].message
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    duplicateCampaign (params) {
      this.$store.dispatch('campaignReport/duplicateCampaign', params.keepCreatives)
        .then(copy => {
          if (copy) {
            const msg = this.currentData.name + ' successfully duplicated'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            window.open(`${window.location.origin}/campaigns/${copy.id}`, '_blank')
          } else {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: 'Something went wrong while duplicating campaign.'
            })
          }
        })
        .catch(error => {
          const msg = error.response.data.errors[0].message
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    /**
     * @param linePatch {Object} Object containing:
     *           - key(s) & value(s) of fields to update (via PATCH)
     *           - id @required ID of Line to update
     * @param toDelete {Array} Array of "target" to DELETE before PATCH*
     *
     * (*) PATCH will NOT delete previous "targetValues" not found in PATCH's request Body
     *     ex: initial = [1,2]
     *         PATCH with [1,2,3] will return [1,2,3], BUT
     *         PATCH with [1,3] will also return [1,2,3]
     */
    updateLine (linePatch, toDelete = []) {
      // DELETE "targets" before applying PATCH
      var deletePromise
      if (toDelete.length) {
        var params = {
          lineId: linePatch.id,
          targets: toDelete
        }
        deletePromise = this.$store.dispatch('campaignReport/deleteLineTargets', params)
      } else {
        deletePromise = new Promise((resolve, reject) => {
          resolve(true)
        })
      }

      deletePromise.then(allDeleted => {
        if (allDeleted) {
          this.$store.dispatch('campaignReport/updateLine', linePatch)
            .then(res => {
              const msg = this.currentInstance[0].toUpperCase() + this.currentInstance.slice(1) + ' successfully updated'
              this.$store.commit('snackbar/setSnackbar', {
                type: 'success',
                msg: msg
              })
            })
            .catch(error => {
              const msg = error.response.data.errors[0].message
              this.$store.commit('snackbar/setSnackbar', {
                type: 'error',
                msg: `${msg}`
              })
            })
        }
      })
    },

    screenStatusChanged (screen) {
      var line = this.$store.getters['campaignReport/getLine']

      var isActive = reportingService.getStatusFromGeography(line.geography, 'screen', screen.id, this.distanceUnit)

      const toStatus = isActive ? 'exclude' : 'include'
      const geo = geoService.reconstructGeography(line.geography, screen, { toStatus: toStatus, type: 'screen' }, this.distanceUnit)

      this.updateLine({
        id: line.id,
        geography: geo
      })
    },

    venueStatusChanged (venue) {
      var line = this.currentData

      var isActive = reportingService.getStatusFromGeography(line.geography, 'venue', venue.id, this.distanceUnit)

      const toStatus = isActive ? 'exclude' : 'include'
      const geo = geoService.reconstructGeography(line.geography, venue, { toStatus: toStatus, type: 'venue' }, this.distanceUnit)

      this.updateLine({
        id: line.id,
        geography: geo
      })
    },

    updateTimeUnit (value) {
      const newQuery = Object.assign({}, this.$route.query, { timeUnit: value })
      this.$router.push({ path: this.$route.path, query: newQuery })

      this.$store.commit('campaignReport/setTimeUnit', value)
      this.$store.dispatch('campaignReport/getChartData')
    },

    updateMetric (value) {
      var newQuery = Object.assign({}, this.$route.query, { metric: value })
      this.$router.push({ path: this.$route.path, query: newQuery })

      this.$store.commit('campaignReport/setMetric', value)
      this.$store.dispatch('campaignReport/getChartData')
    },

    /**
     * called on start/end dates update
     * @param dates {Object} dates in the form of { start: '', end: '' }
     */
    updatestartEndDates (dates) {
      this.$store.commit('campaignReport/setFromDate', dates.start)
      this.$store.commit('campaignReport/setToDate', dates.end)

      // add/update URL with selected dates
      var newQuery = Object.assign({}, this.$route.query, { startDate: moment(dates.start).unix(), endDate: moment(dates.end).unix() })
      this.$router.push({ path: this.$route.path, query: newQuery })

      // empty Table here, before we fetch all stats
      this.$store.commit('campaignReport/setTableData', [])

      this.$store.dispatch('campaignReport/getChartData')
      this.$store.dispatch('campaignReport/getTableData')
      this.$store.dispatch('campaignReport/getTotals')
    },

    openCsvFiltersDialog (params) {
      this.selectedListFromDatatable = params
      this.$root.$emit('openAdvancedCsvDialog')
      tracking.sendEvent(['ga'], 'openedAdvancedCsvExportDialog')
    },

    exportCsv (filters) {
      this.csvLoading = true
      this.$root.$emit('clearSelection')
      this.$root.$emit('closeAdvancedCsvDialog')

      const options = {
        metric: this.currentMetric,
        groupby: [filters.groupBy],
        filter: {
          from: this.$store.getters['campaignReport/getFromDate'],
          to: this.$store.getters['campaignReport/getToDate'],
          orders: [],
          lines: [],
          venues: [],
          screens: []
        }
      }
      options.filter[this.$store.getters['campaignReport/getInstance'] + 's'] = [this.assembleDataGetter(this.$store.getters['campaignReport/getInstance']).id]
      if (filters.timeBreakdown !== 'None') { options.groupby.push(filters.timeBreakdown) }
      if (this.selectedListFromDatatable) { options.filter[this.currentDimension.name] = this.selectedListFromDatatable }
      if (filters.columns.length) { options.select = filters.columns }

      if (options.filter.campaigns && options.filter.campaigns.length) { options.filter.orders = options.filter.campaigns }
      if (options.filter.screens.length) { options.filter.faces = options.filter.screens }

      reportingApi.getAsyncUrl(options).then(tempUrl => {
        this.updateProgress(tempUrl).then(x => {})
      })

      tracking.sendEvent(['ga'], 'exportedToCsv')
    },

    updateProgress (url) {
      return reportingApi.getAsyncProgress(url).then(x => {
        if (x.status === 'Error') {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Something went wrong, please try again'
          })
          this.csvLoading = false
        } else if (x.status === 'Ready') {
          reportingApi.getAsyncFinalResponse(url).then(res => {
            csvService.validateAndExportCsv(res, 'report', 'No data available')
            this.csvLoading = false
          })
        } else {
          this.csvProcessCompletionPecentage = x.progress * 100
          let delay = null
          const delayArray = x.nextUpdateIn.split(':')
          if (Number.isInteger(parseFloat(delayArray[delayArray.length - 1]))) {
            delay = moment(x.nextUpdateIn, 'HH:mm:ss').seconds() * 1000
          } else {
            delay = moment(x.nextUpdateIn, 'HH:mm:ss.SSS').milliseconds()
          }

          return setTimeout(() => this.updateProgress(url), delay)
        }
      })
    },

    duplicateLines (options) {
      // options is in the form {selected: [], keepCreatives: boolean}

      const threshold = 10
      const picked = options.selected.length > threshold ? options.selected.slice(0, threshold) : options.selected
      const isPlural = options.selected.length > 1

      picked.forEach(line => {
        const idsObj = { accountId: this.assembleDataGetter('campaign').accountId, orderId: this.assembleDataGetter('campaign').id, lineId: line.id }
        reportingApi.duplicateLine(idsObj, options.keepCreatives).then(updatedCampaign => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: isPlural ? 'Lines successfully duplicated' : line.name + ' successfully duplicated'
          })

          if (this.currentInstance === 'line') {
            window.open(`${window.location.originL}/campaigns/${updatedCampaign.data.orderId}/lines/${updatedCampaign.data.id}`, '_blank')
          }
          if (this.currentInstance === 'campaign') this.$store.dispatch('campaignReport/refreshLinesDataTable')
        })
          .catch(error => {
            let msg = error.data.errors[0].errorCode
            if (msg === 'unexpected') {
              msg = 'Unexpected: ' + error.data.errors[0].id
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
      })
    },

    archiveLines (listOfChangedFields) {
      listOfChangedFields.forEach(lineChanges => {
        this.$store.dispatch('campaignReport/updateLine', lineChanges)
          .then(res => {
            const msg = this.currentInstance[0].toUpperCase() + this.currentInstance.slice(1) + ' successfully archived'
            this.$store.dispatch('campaignReport/refreshLinesDataTable')
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: msg
            })
            if (this.currentInstance === 'line') {
              this.$router.push({ name: 'report-campaign', params: { campaignId: this.currentData.orderId } })
            }
          })
          .catch(error => {
            const msg = error.response.data.errors[0].message
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
      })
    },

    paginationUpdated (pagination) {
      this.$store.commit('campaignReport/setPagination', pagination)
      this.$store.dispatch('campaignReport/getTableData')
    },

    openEditLineDialog (obj) {
      this.lineToBeEdited = obj ? obj.line : null
      this.editLineSource = obj ? obj.src : null
      this.editLineDialogOpen = true
    },
    closeEditLineDialog (callback = null) {
      if (callback && callback.name) {
        if (callback.name === 'refreshLinesDataTable' && this.currentInstance === 'campaign') {
          this.$store.dispatch('campaignReport/refreshLinesDataTable')
        }

        if (callback.name === 'refreshWeatherMoment' && this.currentInstance === 'line') {
          this.$root.$emit('refreshWeatherMoment', callback.args)
        }
      }

      this.editLineDialogOpen = false
    },

    openTroubleshootingTool (line) {
      this.troubleshootLine = line
      this.troubleshootingToolOpen = true
      this.drawerOpen = true
    }
  }
}
</script>
