<template lang="pug">
div
  .body-2.no-filters(v-if='!filters.length') None
  div.caption.mb-2.filter(v-else v-for='filter in filters')
    div.font-weight-bold {{ toLabel(filter) }}
    span {{ toLabelValue(filter) }}
</template>

<script>
import reportsService from '@/services/reports'

export default {
  props: ['report'],
  data () {
    return {
      filters: []
    }
  },
  async created () {
    this.filters = await reportsService.decodeTags(this.report)
  },
  methods: {
    toLabelValue (filter) {
      return reportsService.extractFilterLabel(filter, true)
    },
    toLabel (filter) {
      return reportsService.extractFilterValue(filter).length || filter.operator !== 'is none of' ? filter.column.name + ` (${filter.operator}):` : filter.column.name + ' (has any value)'
    }
  }
}
</script>
