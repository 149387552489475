const supportedNotificationTypes = [
  {
    name: 'Creative Uploaded',
    body: 'The creative [creativeName] for the advertiser [advertiserName] has been uploaded',
    key: 'CreativeUploaded'
  },
  {
    name: 'Creative Status Changed',
    body: 'The creative [creativeName] status for the advertiser [advertiserName] has been updated to [creativeStatus]',
    key: 'CreativeStatusChanged'
  },
  {
    name: 'Creative Assigned',
    body: 'The creative [creativeName] for the advertiser [advertiserName] has been assigned to the line order [lineOrderName]',
    key: 'CreativeAssigned'
  },
  {
    name: 'Order Created',
    body: 'The campaign [campaignName] has been created',
    key: 'OrderCreated'
  },
  {
    name: 'Order Status Changed',
    body: 'The campaign [campaignName] status has been updated to [campaignStatus]',
    key: 'OrderStatusChanged'
  },
  {
    name: 'Order Name Changed',
    body: 'Campaign name changed from [campaignNameOld] to [campaignName]',
    key: 'OrderNameChanged'
  },
  {
    name: 'Line Created',
    body: 'The line order [lineOrderName] has been created in the campaign [campaignName]',
    key: 'LineCreated'
  },
  {
    name: 'Line Status Changed',
    body: 'The line order [lineOrderName] status has been updated to [lineOrderStatus]',
    key: 'LineStatusChanged'
  },
  {
    name: 'Line Start Date Changed',
    body: 'Start Date changed from [lineOrderStartDateOld] to [lineOrderStartDate] in the line order [lineOrderName]',
    key: 'LineStartDateChanged'
  },
  {
    name: 'Line End Date Changed',
    body: 'End Date changed from [lineOrderEndDateOld] to [lineOrderEndDate] in the line order [lineOrderName]',
    key: 'LineEndDateChanged'
  },
  {
    name: 'Line Name Changed',
    body: 'Line order name changed from [lineOrderNameOld] to [lineOrderName]',
    key: 'LineNameChanged'
  },
  {
    name: 'Line Budget Changed',
    body: 'Budget changes from [lineOrderBudgetOld] to [lineOrderBudget] in the line order [lineOrderName]',
    key: 'LineBudgetChanged'
  },
  {
    name: 'Line Targeting Changed',
    body: 'Targeting in line order [lineOrderName] has changed',
    key: 'LineTargetingChanged'
  },
  {
    name: 'Venue Status Changed',
    body: 'The venue [venueName] status in the line order [lineOrderName] has been updated to [venueStatus]',
    key: 'VenueStatusChanged'
  },
  {
    name: 'Screen Status Changed',
    body: 'The screen [screenName] status from the venue [venueName] in the line order [lineOrderName] has been updated to [screenStatus]',
    key: 'ScreenStatusChanged'
  },
  {
    name: 'User Invitation Sent',
    body: 'Invitation sent to [userName] at [userEmail] to be part of [organizationName]',
    key: 'SendUserInvitation'
  },
  {
    name: 'User Invitation Accepted',
    body: 'The user [userName] has accepted his invitation from the organization [organizationName]',
    key: 'UserInvitationAccepted'
  },
  {
    name: 'User Role Updated',
    body: 'The user [userName] role has been updated to [userRole]',
    key: 'UserRoleUpdated'
  },
  {
    name: 'Audience Created',
    body: 'The audience [audienceName] has been created',
    key: 'AudienceCreated'
  },
  {
    name: 'Audience Targeting Changed',
    body: 'The audience [audienceName] has been updated',
    key: 'AudienceTargetingChanged'
  },
  {
    name: 'Line Starts',
    body: 'Line order [lineOrderName], in campaign [campaignName], has reached its starting date. Its status is [lineOrderStatus].',
    key: 'LineStarts'
  },
  {
    name: 'Line Ends',
    body: 'Line order [lineOrderName] has ended in campaign [campaignName]',
    key: 'LineEnds'
  },
  {
    name: 'Organization Infos Changed',
    body: 'Infos changed for Organization [organizationName]',
    key: 'OrganizationInfosChanged'
  },
  {
    name: 'Invoice Created',
    body: 'Invoice [invoiceId] has been generated for [organizationName]',
    key: 'InvoiceCreated'
  },
  {
    name: 'Invoice Payment Failed',
    body: 'Credit card payment for invoice [invoiceId] has failed',
    key: 'InvoicePaymentFailed'
  },
  {
    name: 'Invoice Payment Succeeded',
    body: 'Credit card payment for invoice [invoiceId] has succeeded',
    key: 'InvoicePaymentSucceeded'
  },
  {
    name: 'Organization Contact Changed',
    body: 'Contact infos changed for Organization [organizationName]',
    key: 'OrganizationContactChanged'
  },
  {
    name: 'Organization Payment Method Created',
    body: 'Payment method added',
    key: 'OrganizationPaymentMethodCreated'
  },
  {
    name: 'Credit Created',
    body: 'A credit of $250 was added',
    key: 'CreditCreated'
  },
  {
    name: 'Creative Approval Required',
    body: 'The creative [creativeName] for the advertiser [advertiserName] requires approval',
    key: 'CreativeApprovalRequired'
  },
  {
    name: 'Invoice Payment Due In Seven Days',
    body: 'A payment for invoice [invoiceId] is due in seven (7) days',
    key: 'InvoicePaymentDueInSevenDays'
  },
  {
    name: 'Invoice Payment Past Due',
    body: 'A payment for invoice [invoiceId] is past due',
    key: 'InvoicePaymentPastDue'
  },
  {
    name: 'Creative Delivering In Supplier Network',
    body: 'The creative [creativeName] is now delivering in [supplierName]\u0026#039;s [supplierNetworkName] network.',
    key: 'CreativeDeliveringInSupplierNetwork'
  },
  {
    name: 'Auction Package Start Date Changed',
    body: 'Start Date changed from [auctionPackageStartDateOld] to [auctionPackageStartDate] for auction package [auctionPackageName]',
    key: 'AuctionPackageStartDateChanged'
  },
  {
    name: 'Auction Package End Date Changed',
    body: 'End Date changed from [auctionPackageEndDateOld] to [auctionPackageEndDate] for auction package [auctionPackageName]',
    key: 'AuctionPackageEndDateChanged'
  },
  {
    name: 'Auction Package Set to Always-On',
    body: 'The schedule for auction package [auctionPackageName] has been set to Always-On',
    key: 'AuctionPackageSetToAlwaysOn'
  },
  {
    name: 'Line Priority Has Been Updated',
    body: 'The priority of the line order [lineOrderName] has been updated to [updatedPriority]',
    key: 'LinePriorityChanged'
  }
]

export default { supportedNotificationTypes }
