const defaultPerms = {
  user: {
    read: {
      default: true,
      allUsers: false
    },
    create: false,
    update: {
      default: false,
      own: true
    }
  },
  organization: {
    read: {
      default: true,
      allOrgs: false,
      buyerSeat: false
    },
    create: { default: false },
    update: {
      default: false,
      buyerSeat: false,
      paymentTerm: false,
      paymentType: false,
      campaignExecution: false,
      fees: false,
      apiKey: false
    },
    delete: { default: false }
  },
  creative: {
    read: {
      default: true,
      orgInfo: false
    },
    create: { default: false },
    update: {
      default: false,
      adQualityStatus: false,
      language: false,
      industry: false,
      externalId: false
    },
    delete: false
  },
  invoice: {
    read: true,
    create: false,
    update: {
      default: false,
      paidRefNumber: false,
      paidPaymentMethod: false
    },
    delete: false
  },
  campaign: {
    read: {
      default: true,
      orgInfo: false
    },
    create: {
      default: false,
      newLine: false
    },
    update: false
  },
  proposal: {
    read: {
      default: true,
      orgInfo: false
    },
    create: {
      default: false,
      shareLink: false,
      newLine: false
    },
    update: false
  },
  audience: {
    read: {
      default: true,
      orgInfo: false
    },
    create: {
      default: true
    }
  },
  notification: {
    read: {
      default: true,
      perOrg: false
    }
  },
  terms: {
    update: false
  },
  report: {
    read: {
      default: true,
      all: false
    }
  },
  auctionPackage: {
    read: {
      default: false
    },
    create: {
      default: false,
      shareLink: false
    },
    update: {
      fees: false
    }
  }
}

export default [
  {
    key: 'default',
    permissions: defaultPerms
  },
  {
    key: 'globaladmin',
    permissions: {
      user: {
        read: {
          default: true,
          allUsers: true
        },
        create: true,
        update: {
          default: true,
          own: true
        }
      },
      organization: {
        read: {
          default: true,
          allOrgs: true,
          buyerSeat: true
        },
        create: { default: true },
        update: {
          default: true,
          buyerSeat: true,
          paymentTerm: true,
          paymentType: true,
          campaignExecution: true,
          fees: true,
          apiKey: true
        },
        delete: { default: false }
      },
      creative: {
        read: {
          default: true,
          orgInfo: true
        },
        create: { default: true },
        update: {
          default: true,
          adQualityStatus: true,
          language: true,
          industry: true,
          externalId: true
        },
        delete: false
      },
      invoice: {
        read: true,
        create: false,
        update: {
          default: false,
          paidRefNumber: true,
          paidPaymentMethod: false
        },
        delete: false
      },
      campaign: {
        read: {
          default: true,
          orgInfo: true
        },
        create: {
          default: true,
          newLine: true
        },
        update: true
      },
      proposal: {
        read: {
          default: true,
          orgInfo: true
        },
        create: {
          default: true,
          shareLink: true,
          newLine: true
        },
        update: true
      },
      audience: {
        read: {
          default: true,
          orgInfo: true
        },
        create: {
          default: true
        }
      },
      notification: {
        read: {
          default: true,
          perOrg: true
        }
      },
      terms: {
        update: true
      },
      report: {
        read: {
          default: true,
          all: true
        }
      },
      auctionPackage: {
        read: {
          default: true
        },
        create: {
          default: true,
          shareLink: true
        },
        update: {
          fees: true
        }
      }
    }
  },
  {
    key: 'globalreadonly',
    permissions: {
      user: {
        read: {
          default: true,
          allUsers: true
        },
        create: false,
        update: {
          default: false,
          own: false
        }
      },
      organization: {
        read: {
          default: true,
          allOrgs: true,
          buyerSeat: true
        },
        create: { default: false },
        update: {
          default: false,
          buyerSeat: false,
          paymentTerm: false,
          paymentType: false,
          campaignExecution: false,
          fees: false,
          apiKey: false
        },
        delete: { default: false }
      },
      creative: {
        read: {
          default: true,
          orgInfo: true
        },
        create: { default: false },
        update: {
          default: false,
          adQualityStatus: false,
          language: false,
          industry: false,
          externalId: false
        },
        delete: false
      },
      invoice: {
        read: true,
        create: false,
        update: {
          default: false,
          paidRefNumber: false,
          paidPaymentMethod: false
        },
        delete: false
      },
      campaign: {
        read: {
          default: true,
          orgInfo: true
        },
        create: {
          default: false,
          newLine: false
        },
        update: false
      },
      proposal: {
        read: {
          default: true,
          orgInfo: true
        },
        create: {
          default: false,
          shareLink: false,
          newLine: false
        },
        update: false
      },
      audience: {
        read: {
          default: true,
          orgInfo: true
        },
        create: {
          default: false
        }
      },
      notification: {
        read: {
          default: true,
          perOrg: true
        }
      },
      terms: {
        update: false
      },
      report: {
        read: {
          default: true,
          all: true
        }
      },
      auctionPackage: {
        read: {
          default: false
        },
        create: {
          default: false,
          shareLink: false
        },
        update: {
          fees: false
        }
      }
    }
  },

  {
    key: 'owner',
    permissions: {
      user: {
        read: {
          default: true,
          allUsers: false
        },
        create: true,
        update: {
          default: true,
          own: true
        }
      },
      organization: {
        read: {
          default: true,
          allOrgs: false,
          buyerSeat: true
        },
        create: { default: false },
        update: {
          default: true,
          buyerSeat: false,
          paymentTerm: false,
          paymentType: false,
          campaignExecution: false,
          fees: false,
          apiKey: false
        },
        delete: false
      },
      creative: {
        read: {
          default: true,
          orgInfo: false
        },
        create: { default: true },
        update: {
          default: true,
          adQualityStatus: false,
          language: false,
          advertiser: false,
          industry: false,
          externalId: true
        },
        delete: false
      },
      invoice: {
        read: true,
        create: false,
        update: {
          default: false,
          paidRefNumber: false,
          paidPaymentMethod: true
        },
        delete: false
      },
      campaign: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true,
          newLine: true
        },
        update: true
      },
      proposal: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true,
          shareLink: true,
          newLine: true
        },
        update: true
      },
      audience: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true
        }
      },
      notification: {
        read: {
          default: true,
          perOrg: false
        }
      },
      terms: {
        update: true
      },
      report: {
        read: {
          default: true,
          all: false
        }
      },
      auctionPackage: {
        read: {
          default: false
        },
        create: {
          default: false,
          shareLink: false
        },
        update: {
          fees: false
        }
      }
    }
  },

  {
    key: 'administrator',
    permissions: {
      user: {
        read: {
          default: true,
          allUsers: false
        },
        create: false,
        update: {
          default: true,
          own: true
        }
      },
      organization: {
        read: {
          default: true,
          allOrgs: false,
          buyerSeat: true
        },
        create: { default: false },
        update: {
          default: true,
          buyerSeat: false,
          paymentTerm: false,
          paymentType: false,
          campaignExecution: false,
          fees: false,
          apiKey: false
        },
        delete: false
      },
      creative: {
        read: {
          default: true,
          orgInfo: false
        },
        create: { default: true },
        update: {
          default: true,
          adQualityStatus: false,
          language: false,
          industry: false,
          externalId: true
        },
        delete: false
      },
      invoice: {
        read: true,
        create: false,
        update: {
          default: false,
          paidRefNumber: false,
          paidPaymentMethod: true
        },
        delete: false
      },
      campaign: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true,
          newLine: true
        },
        update: true
      },
      proposal: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true,
          shareLink: true,
          newLine: true
        },
        update: true
      },
      audience: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true
        }
      },
      notification: {
        read: {
          default: true,
          perOrg: false
        }
      },
      terms: {
        update: true
      },
      report: {
        read: {
          default: true,
          all: false
        }
      },
      auctionPackage: {
        read: {
          default: false
        },
        create: {
          default: false,
          shareLink: false
        },
        update: {
          fees: false
        }
      }
    }
  },

  {
    key: 'manager',
    permissions: {
      user: {
        read: {
          default: true,
          allUsers: false
        },
        create: false,
        update: {
          default: false,
          own: true
        }
      },
      organization: {
        read: {
          default: true,
          allOrgs: false,
          buyerSeat: true
        },
        create: { default: false },
        update: {
          default: false,
          buyerSeat: false,
          paymentTerm: false,
          paymentType: false,
          campaignExecution: false,
          fees: false,
          apiKey: false
        },
        delete: { default: false }
      },
      creative: {
        read: {
          default: true,
          orgInfo: false
        },
        create: { default: true },
        update: {
          default: true,
          adQualityStatus: false,
          language: false,
          industry: false,
          externalId: true
        },
        delete: false
      },
      invoice: {
        read: true,
        create: false,
        update: {
          default: false,
          paidRefNumber: false,
          paidPaymentMethod: false
        },
        delete: false
      },
      campaign: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true,
          newLine: true
        },
        update: true
      },
      proposal: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true,
          shareLink: true,
          newLine: true
        },
        update: true
      },
      audience: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true
        }
      },
      notification: {
        read: {
          default: true,
          perOrg: false
        }
      },
      terms: {
        update: false
      },
      report: {
        read: {
          default: true,
          all: false
        }
      },
      auctionPackage: {
        read: {
          default: false
        },
        create: {
          default: false,
          shareLink: false
        },
        update: {
          fees: false
        }
      }
    }
  },

  {
    key: 'reporter',
    permissions: {
      user: {
        read: {
          default: true,
          allUsers: false
        },
        create: false,
        update: {
          default: false,
          own: true
        }
      },
      organization: {
        read: {
          default: true,
          allOrgs: false,
          buyerSeat: false
        },
        create: { default: false },
        update: {
          default: false,
          buyerSeat: false,
          paymentTerm: false,
          paymentType: false,
          campaignExecution: false,
          fees: false,
          apiKey: false
        },
        delete: { default: false }
      },
      creative: {
        read: {
          default: true,
          orgInfo: false
        },
        create: { default: false },
        update: {
          default: false,
          adQualityStatus: false,
          language: false,
          industry: false,
          externalId: false
        },
        delete: false
      },
      invoice: {
        read: true,
        create: false,
        update: {
          default: false,
          paidRefNumber: false,
          paidPaymentMethod: false
        },
        delete: false
      },
      campaign: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: false,
          newLine: false
        },
        update: false
      },
      proposal: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: false,
          shareLink: false,
          newLine: false
        },
        update: false
      },
      audience: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true
        }
      },
      notification: {
        read: {
          default: true,
          perOrg: false
        }
      },
      terms: {
        update: false
      },
      report: {
        read: {
          default: true,
          all: false
        }
      },
      auctionPackage: {
        read: {
          default: false
        },
        create: {
          default: false,
          shareLink: false
        },
        update: {
          fees: false
        }
      }
    }
  },

  {
    key: 'proposalscheduler',
    permissions: {
      user: {
        read: {
          default: true,
          allUsers: false
        },
        create: false,
        update: {
          default: false,
          own: true
        }
      },
      organization: {
        read: {
          default: true,
          allOrgs: false,
          buyerSeat: false
        },
        create: { default: false },
        update: {
          default: false,
          buyerSeat: false,
          paymentTerm: false,
          paymentType: false,
          campaignExecution: false,
          fees: false,
          apiKey: false
        },
        delete: { default: false }
      },
      creative: {
        read: {
          default: false,
          orgInfo: false
        },
        create: { default: false },
        update: {
          default: false,
          adQualityStatus: false,
          language: false,
          industry: false,
          externalId: false
        },
        delete: false
      },
      invoice: {
        read: false,
        create: false,
        update: {
          default: false,
          paidRefNumber: false,
          paidPaymentMethod: false
        },
        delete: false
      },
      campaign: {
        read: {
          default: false,
          orgInfo: false
        },
        create: {
          default: false,
          newLine: false
        },
        update: false
      },
      proposal: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true,
          shareLink: true,
          newLine: true
        },
        update: true
      },
      audience: {
        read: {
          default: true,
          orgInfo: false
        },
        create: {
          default: true
        }
      },
      notification: {
        read: {
          default: true,
          perOrg: false
        }
      },
      terms: {
        update: false
      },
      report: {
        read: {
          default: true,
          all: false
        }
      },
      auctionPackage: {
        read: {
          default: false
        },
        create: {
          default: false,
          shareLink: false
        },
        update: {
          fees: false
        }
      }
    }
  }
]
