<template lang="pug">
  div
    vue-headful(:title="componentConfig.branding.title(pageTitle)")
    v-container(v-if='isLoading' style='min-height:240px;')
      v-row.my-0.pt-0(align='start')
        v-col.pt-0.pb-0(cols='12' xl='8' offset-xl='2')
          v-skeleton-loader.mt-2(type='text' width='20%')
          v-skeleton-loader.mt-1.mb-3(type='image' height=42 width='80%')
          v-skeleton-loader.mt-2.mb-1.d-flex.mr-2(type='chip@3')
        v-col.pt-2.pb-0(cols='12' lg='5')
      v-row.mb-0.mt-2()
        v-col(cols='12' sm='6' lg='2' offset-xl='2')
          v-skeleton-loader(type='image' height=60)
        v-col(cols='12' sm='6' lg='3')
          v-skeleton-loader(type='image' height=60)
        v-col(cols='6' sm='6' lg='3' )
          v-skeleton-loader(type='image' height=60)

    v-container(v-if='!isLoading')
      v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
        v-col.pb-0(cols='12' xl='8' offset-xl='2')
          .text-overline {{ organizationBillingName }}
          .text-h4.text-truncate() {{ pageTitle }}

          v-toolbar.my-2.pl-0(flat dense color='transparent' style='border-top: 1px solid rgba(0,0,0,.12) !important; border-bottom: 1px solid rgba(0,0,0,.12) !important;')
            v-chip.ma-1.text-uppercase.xs-small(:color='statusColor' small outlined)
              v-icon.mr-1.ml-0(:color='statusColor' small) mdi-circle
              small(class='text--primary') {{ statusLabel }}

            .text-body-2(v-if='isPaid')
              | &nbsp; {{ invoice.paymentMethodLabel }}
            span.hidden-sm-and-down.text-caption.grey--text.ml-4(v-if='can.update.paidRefNumber && isPaid') {{ paidRefNumberFormatted }}
            v-dialog(v-if='!isLoading && can.update.paidRefNumber && !isTransaction' v-model='isEditPaymentDialogOpen' max-width='600')
              template(v-slot:activator='{on}')
                v-btn(text icon v-on='on')
                  v-icon.edit(style='transform: inherit;' small title='Edit Invoice Payment Status') mdi-pencil
              v-card(flat)
                v-card-title
                  .text-h5 Edit Invoice &num;{{ invoice.invoiceNumber }}
                  v-spacer
                  v-btn.ma-0(icon @click='isEditPaymentDialogOpen = false')
                    v-icon(medium color='grey') mdi-close
                v-card-text
                  v-container(grid-list-lg)
                    v-form(ref='editInvoiceForm' v-model='formIsValid')
                      v-layout(row wrap)
                        v-flex(sm12 md6)
                          v-select(v-model='status' label='Status' :items='statuses' :rules='[rules.required]')
                        v-flex(sm12 md6)
                          v-text-field(v-model='paidRefNumber' label='Payment ref #' :rules='status === "Paid" ? [rules.required] : []')
                v-divider
                v-card-actions
                  v-spacer
                  v-btn(color='secondary' text @click='isEditPaymentDialogOpen = false') Close
                  v-btn(color='primary' text @click='saveChanges()' :disabled='!formIsValid' :loading='editingPayment') Save

            v-btn(icon  depressed :disabled='isLoading' :loading='downloadingPDF' @click='downloadPDF()' title='Download Invoice in PDF')
              v-icon(style='transform: inherit;') mdi-file-pdf-box
            v-spacer

            v-dialog(v-if='!isPaid' :fullscreen='$vuetify.breakpoint.xsOnly' max-width=600 v-model='isMakePaymentDialogOpen')
              template(v-slot:activator='{ on }')
                v-btn(:disabled='!can.update.paidPaymentMethod' color='primary' text v-on='on') Make Payment
              makePaymentModal(
                :invoice='invoice'
                @closeDialog='closeDialog')

      v-row.my-0(:dense='$vuetify.breakpoint.xsOnly')
        v-col(cols='12' sm='6' lg='2' xl='2' offset-xl='2')
          v-card(flat height='100%' style='background-color:transparent;')
            .text-overline Amount in {{ currencyCode }}
              span(v-if='isTransaction')  Billed
            .text-h6.font-weight-regular {{ invoice.amountIncludingTaxes | currency }}
        v-col(cols='12' sm='6' lg='3' xl='2' v-if='!isTransaction')
          v-card(flat height='100%' style='background-color:transparent;')
            .text-overline Invoice Date
            .text-h6.font-weight-regular {{ invoice.createdOn | date('MMM Do, YYYY hh:mm a') }}
        v-col(cols='6' sm='6' lg='3' xl='2')
          v-card(flat height='100%' style='background-color:transparent;')
            .text-overline Billing Period
            .text-h6.font-weight-regular(v-if='!isTransaction') {{ invoice.startDate | date('MMMM, YYYY') }}
            .text-body-2(v-if='isTransaction')
              p.mb-1
                strong From:
                |  {{ invoice.startDate | date('MMM Do, YYYY hh:mm a') }}
              p.mb-0
                strong To:
                |  {{ invoice.endDate | date('MMM Do, YYYY hh:mm a') }}
        v-col(cols='6' sm='6' lg='2' v-if='!isTransaction && !isPaid')
          v-card(flat height='100%' style='background-color:transparent;')
            .text-overline Due Date
            .text-h6.font-weight-regular(:class="{'error--text': invoice.dueDateReached}") {{ invoice.dueDate | date('MMM Do, YYYY') }}
        v-col(cols='12' sm='6' lg='2' v-if='isPaid')
          v-card(flat height='100%' style='background-color:transparent;')
            .text-overline Paid On
            .text-h6.font-weight-regular {{ invoice.paidOn | date('MMM Do, YYYY') || 'N/A' }}

    //- Details
    v-container.pt-1.pb-4(grid-list-lg text-left class='')
      v-row.my-0(fill-height)
        v-col(cols='12' sm='12' xl='8' offset-xl='2')
          v-expansion-panels#details-panels(multiple v-model='panelsExpanded' v-if='!isLoading')

            //- Campain(s)
            v-expansion-panel(v-if='!invoice.isInterestsInvoice' v-for='order in invoice.orders' :key='order.id')
              v-expansion-panel-header(v-slot='{ open }')
                v-row.my-0(no-gutters)
                  v-col(cols='8')
                    span.text-body-1 {{ order.account }}: {{ order.name }}
                  v-col(cols='4' v-if='$vuetify.breakpoint.mdAndUp')
                    v-fade-transition
                      v-row.my-0.align-center(no-gutters justify='end' v-if='!open' style='width: 100%')
                        v-col(cols='4')
                          v-icon.text--secondary(left) mdi-account-multiple
                          span {{ order.quantity | numberWithCommas }}
                        v-col(cols='4')

                          span ${{ order.amount | numberWithCommas(2) }}

              v-expansion-panel-content
                v-data-table(
                  :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
                  :headers='campaignTableHeaders'
                  :items='order.lines'
                  :loading='isLoading'
                  hide-default-footer
                  must-sort
                  :options.sync='campaignTableOptions'
                  ref='linesTable'
                )

                  template(v-slot:item.name='{ item }')
                    div(:style='$vuetify.breakpoint.xsOnly ? "max-width: 250px;" : ""')
                      .text-truncate {{ item.name }}

                  template(v-slot:item.exchange.name='{ item }')
                    | {{ item.exchange.name | capitalize }}

                  template(v-slot:item.quantity='{ item }')
                    | {{ item.quantity | numberWithCommas }}

                  template(v-slot:item.mediaCost='{ item }')
                    | {{ item.mediaCost | currency }}

                  template(v-slot:item.fees='{ item }')
                    | {{ item.fees | currency }}

                  template(v-slot:item.amountBeforeCredit='{ item }')
                    | {{ item.amountBeforeCredit | currency }}

            //- Interests
            v-expansion-panel(v-if='invoice.isInterestsInvoice')
              v-expansion-panel-header(v-slot='{ open }')
                v-row.my-0(no-gutters)
                  v-col(cols='8')
                    span.text-body-1 Invoice Details

              v-expansion-panel-content
                v-data-table(
                  :dense='$vuetify.breakpoint.xsOnly'
                  :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
                  :headers='interestsTableHeaders'
                  :items='invoice.invoiceItems'
                  :loading='isLoading'
                  hide-default-footer
                  must-sort
                  :options.sync='interestsTableOptions'
                )

                  template(v-slot:item.appliedToInvoiceNumber='{ item }')
                    router-link.info--text(:to='{ name: "Invoice Page", params: { invoiceId: item.appliedToInvoiceId } }') {{ item.appliedToInvoiceNumber }}

                  template(v-slot:item.appliedToInvoiceAmountIncludingTaxes='{ item }')
                    | {{ item.appliedToInvoiceAmountIncludingTaxes | currency }}

                  template(v-slot:item.amountBeforeCredit='{ item }')
                    | {{ item.amountBeforeCredit | currency }}

                  template(v-slot:item.appliedToInvoiceRate='{ item }')
                    | {{ item.appliedToInvoiceRate | numberWithCommas(4) }}&#37;
                    span.text-caption  ({{item.daysInMonth}} days @ 18% per annum)

    //- Footer
    v-container.pt-1.pb-4.px-6(grid-list-lg text-left)
      div(width='100%' v-if='!isLoading')
        v-row.my-0(fill-height)
          v-col(cols='12' sm='12' xl='8' offset-xl='2')

            v-row.my-0.subtotal.align-center.border-top(v-if='gotCredits || invoice.tax1 || invoice.tax2')
              v-col.text-right.text-caption(cols='7' md='9') Subtotal
              v-col.text-right(cols='5' md='3')
                strong {{ invoice.amountBeforeCredit || 0 | currency }}

            v-row.my-0.align-center.border-top(v-if='gotCredits')
              v-col.text-right.text-caption(cols='7' md='9') Credits
              v-col.text-right(cols='5' md='3')
                strong {{ invoice.credits | currency }}
            v-row.my-0.align-center.border-top(v-if='gotCredits')
              v-col.text-right.text-caption(cols='7' md='9') After Credits
              v-col.text-right(cols='5' md='3')
                strong {{ invoice.amountExcludingTaxes | currency }}

            v-row.my-0.align-center.border-top(v-if='invoice.tax1')
              v-col.text-right.text-caption(cols='7' md='9') {{ invoice.tax1 || 'Tax 1' }}
              v-col.text-right(cols='5' md='3')
                strong {{ invoice.tax1Amount | currency }}
            v-row.my-0.align-center.border-top(v-if='invoice.tax2')
              v-col.text-right.text-caption(cols='7' md='9') {{ invoice.tax2 || 'Tax 2' }}
              v-col.text-right(cols='5' md='3')
                strong {{ invoice.tax2Amount | currency }}

            v-row.my-0.align-center.border-top
              v-col.text-right.text-caption(cols='7' md='9') Total
              v-col.text-right(cols='5' md='3')
                strong {{ invoice.amountIncludingTaxes | currency }}
</template>

<style lang="stylus" scoped>
  >>> .v-expansion-panel-header {
    padding-left: 14px;
    padding-right: 14px;
  }
  >>> .v-expansion-panel-content__wrap {
    padding-left: 0px;
    padding-right: 0px;
  }
  >>> .v-toolbar__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  >>> .v-skeleton-loader__chip {
    margin-right: 6px;
  }
  .border-top {
    border-top: 1px solid rgba(0,0,0,0.12);
  }
</style>

<script>
import makePaymentModal from '@/components/makePaymentModal.vue'

import billingService from '@/services/billing.service'
import componentConfigService from '@/services/componentConfig'

import moment from 'moment'

export default {
  components: {
    makePaymentModal
  },
  watch: {
    invoiceId (newVal, oldVal) {
      this.getInvoice(newVal)
    }
  },
  data () {
    return {
      panelsExpanded: [],
      isLoading: true,
      invoice: null,
      campaignTableHeaders: [
        { text: 'Line Order', value: 'name' },
        { text: 'Impressions', value: 'quantity', align: 'right' },
        { text: 'Total', value: 'amountBeforeCredit', align: 'right' }
      ],
      campaignTableOptions: {
        itemsPerPage: -1,
        sortBy: ['name'],
        sortDesc: [false]
      },
      interestsTableHeaders: [
        { text: 'Invoice #', value: 'appliedToInvoiceNumber' },
        { text: 'Invoice Amount', value: 'appliedToInvoiceAmountIncludingTaxes', align: 'right' },
        { text: 'Interest Rate', value: 'appliedToInvoiceRate', sortable: false },
        { text: 'Interests', value: 'amountBeforeCredit', align: 'right' }
      ],
      interestsTableOptions: {
        sortBy: ['appliedToInvoiceNumber'],
        sortDesc: [false]
      },
      downloadingPDF: false,
      isMakePaymentDialogOpen: false,

      // Edit Invoice payment status
      isEditPaymentDialogOpen: false,
      editingPayment: false,
      formIsValid: false,
      rules: {
        required: value => !!value || 'This field is required'
      },
      paidRefNumber: null,
      status: null,
      statuses: ['Pending', 'Paid', 'Failed', 'Resolved']
    }
  },
  created: function () {
    this.getInvoice()
  },
  computed: {
    invoiceId () {
      return this.$route.params.invoiceId
    },
    can () {
      return this.$store.getters['user/permissions']('invoice')
    },
    paymentType () {
      // returns "Automatic" / "Manual"
      return this.invoice && this.invoice.paymentMethod ? this.invoice.paymentMethod.paymentType : ''
    },
    isTransaction () {
      return this.invoice ? this.paymentType === 'Automatic' : false
    },
    invoiceType () {
      return this.isTransaction ? 'Transaction' : 'Invoice'
    },
    pageTitle () {
      return this.invoice ? this.invoiceType + ': ' + this.invoice.invoiceNumber : ''
    },
    organization () {
      return this.$store.getters['user/getOrganization']
    },
    organizationBillingName () {
      return this.invoice.billingAddress.name || this.invoice.organization.name
    },
    currencyCode () {
      return this.organization.currency ? this.organization.currency.code : ''
    },
    isPaid () {
      return this.invoice ? ['Paid', 'Resolved'].includes(this.invoice.status) : false
    },
    statusColor () {
      if (!this.invoice) { return 'grey' } else {
        switch (this.invoice.status) {
          case 'Paid': return 'success'
          case 'Pending': return 'warning'
          case 'Failed': return 'error'
          case 'Resolved': return 'success'
          default: return 'success'
        }
      }
    },
    statusLabel () {
      if (!this.invoice) { return 'loading...' } else {
        return this.isTransaction ? this.invoice.status : (this.invoice.status === 'Pending' ? 'To Be Paid' : this.invoice.status)
      }
    },
    gotCredits () {
      return this.invoice ? this.invoice.credits && this.invoice.credits > 0 : false
    },
    paidRefNumberFormatted () {
      var ref = this.invoice.paidRefNumber ? this.invoice.paidRefNumber : ''
      return ref.indexOf('chargeId') > -1 ? JSON.parse(ref).chargeId : ref
    },
    isOldCAD () {
      return this.invoice
        ? moment(this.invoice.createdOn).isBefore(moment('2020-04-02'))
        : false
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    getInvoice () {
      this.invoice = null
      this.isLoading = true

      billingService.getInvoice(this.invoiceId)
        .then(invoice => {
          this.invoice = invoice

          // set models
          var { status, paidRefNumber } = invoice
          this.status = status
          this.paidRefNumber = paidRefNumber

          this.isLoading = false
        })
        .catch(() => { this.$router.push({ name: 'Access Denied' }) })
    },
    downloadPDF () {
      this.downloadingPDF = true
      return billingService.downloadPDF(this.invoice)
        .then(() => {
          this.downloadingPDF = false
        })
    },
    closeDialog (successfulCharge = null) {
      this.isMakePaymentDialogOpen = false

      if (successfulCharge) {
        this.$store.commit('snackbar/setSnackbar', {
          type: 'success',
          msg: `Payment for Invoice ${this.invoice.invoiceNumber} successful`
        })

        this.getInvoice()
      }
    },

    /**
     * Updates invoice's "paidRefNumber" with some "ref #" (FKA "ACCPAC #") for manual payments
     * NOTE: can only be set once, i.e. cannot update "ref #" after, nor change "paymentStatus"
     * So, "status" select is kinda useless for now...
     */
    saveChanges () {
      // any changes made ..?
      if (this.invoice.status !== this.status || this.invoice.paidRefNumber !== this.paidRefNumber) {
        this.editingPayment = true

        billingService.setInvoicePayment(this.invoice, this.paidRefNumber)
          .then(resp => {
            // update Invoice upon success
            this.invoice.status = this.status
            this.invoice.paidRefNumber = this.paidRefNumber

            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: `Payment status for Invoice ${this.invoiceNumber} successfully updated`
            })

            this.isEditPaymentDialogOpen = false
          })
          .catch(error => {
            var msg = 'Error updating payment status'

            // Bad Request
            if (error.response && error.response.status === 400) {
              msg = error.response.data.errors[0].message
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg
            })
          })
          .finally(() => {
            this.editingPayment = false
          })
      } else {
        this.isEditPaymentDialogOpen = false
      }
    }
  }
}
</script>
