<template lang='pug'>
div(style='min-height: 100vh; background:linear-gradient(-45deg, #011866, #17d1a8);')
  vue-headful(:title="componentConfig.branding.title('Signup')")
  v-layout.align-center(fill-height)
    v-flex
      v-stepper.elevation-0(:value='currentStep' style='background-color: transparent;')
        v-stepper-items
          //- S T E P  1
          v-stepper-content.pt-0(step='1' color='transparent' :class="{'px-0': $vuetify.breakpoint.xsOnly}")
            v-form(ref='step1')
              v-container(grid-list-lg text-xs-left)
                v-layout(row wrap)
                  v-flex.ma-auto(xs12 md6 xl4 style='max-width: 430px;')
                    div.mx-auto.text-center(style='height: 32px;')
                      v-avatar.elevation-0(size=60 color='white' style='z-index:10;')
                        img(src='img/icons/logo-192x192.png' alt='avatar' style='padding: 4px 0 0 4px')

                    v-card.elevation-16.mb-12
                      v-card-title.text-center.d-block
                        .text-h6.mt-6 Let's create your Broadsign Ads account
                        div.text-caption.grey--text
                          | Already have a Broadsign Ads account?
                          router-link.info--text(to='login')  Please login
                      v-container(grid-list-lg)
                        v-layout(row wrap)
                          v-flex(xs12)
                            v-text-field#firstName(v-model='user.firstName' color='csblue' label='First Name' :rules='[rules.required]')
                            v-text-field#lastName(v-model='user.lastName' color='csblue' label='Last Name' :rules='[rules.required]')
                            .email-field-wrapper
                              v-text-field#email(v-model='user.email' color='csblue' label='Work Email' type='email' prepend-inner-icon='mdi-email' :rules='[rules.required, rules.validEmail, rules.acceptedEmailDomain]')
                            v-text-field#password(
                              v-model='user.password'
                              color='csblue'
                              :rules='[rules.required, rules.min]'
                              counter
                              type='password'
                              label='Password'
                              hint='At least 8 characters'
                              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                              :type="showPassword ? 'text' : 'password'"
                              @click:append='showPassword = !showPassword'
                            )
                      v-card-actions
                        v-btn.mb-2.mx-auto#signup-step1(rounded large dark color='csblue' @click='step1To2()' min-width='200') Continue

          //- S T E P  2
          v-stepper-content.pt-0(step='2' :class="{'px-0': $vuetify.breakpoint.xsOnly}")
            v-form(ref='step2')
              v-container(grid-list-lg text-xs-left)
                v-layout(row wrap)
                  v-flex.ma-auto(xs12 md6 xl4 style='max-width: 550px;')
                    div.mx-auto.text-center(style='height: 32px;')
                      v-avatar.elevation-0(size=60 color='white' style='z-index:10;')
                        img(src='img/icons/logo-192x192.png' alt='avatar' style='padding: 4px 0 0 4px')

                    v-card.elevation-16.mb-12
                      v-card-title.pb-0.px-3
                        .text-h6.mt-6 Where do you want to buy media?
                      v-container(grid-list-lg)
                        v-layout(row wrap)
                          v-flex#currency-wrapper(sm12 md6)
                            v-select#currency(
                              ref='currencyRef'
                              v-model='orgInfo.currency'
                              :items='currencies'
                              item-text='label'
                              return-object
                              label='Country'
                              :rules='[rules.required]'
                              persistent-hint
                              hint='Cannot be changed later.'
                              :menu-props={ contentClass: 'currency-options', bottom: true }
                              attach='#currency-wrapper')
                          v-flex(sm12 md6)
                            v-autocomplete#timezone(v-model='orgInfo.timeZone' color='csblue' :items='timeZones' label='Time Zone' item-text='label' item-value='value' :rules='[rules.required]' persistent-hint hint='Cannot be changed later.')

                      v-container(grid-list-lg)
                        v-layout
                          v-flex
                            .text-h6 Organization Information

                        v-layout(row wrap)
                          v-flex(sm12 md12)
                            v-text-field#organization-name(v-model='orgInfo.name' color='csblue' label='Organization or Business Name' :rules='[rules.required]')

                        v-layout(row wrap)
                          v-flex(sm12 md9)
                            v-text-field#organization-addressLine1(label='Address Line 1' color='csblue' :rules='[rules.required]' v-model='orgAddress.addressLine1')
                          v-flex(sm12 md3)
                            v-text-field#organization-addressLine2(label='Suite / Office Number' color='csblue' v-model='orgAddress.addressLine2')

                        v-layout(row wrap)
                          v-flex(sm12 md6)
                            v-text-field#organization-city(label='City' :rules='[rules.required]' color='csblue' v-model='orgAddress.city')
                          v-flex(sm12 md6)
                            v-text-field#organization-postalCode(:label='market.postcodeLabel' color='csblue' :rules='[rules.required]' v-model='orgAddress.postalCode')

                        v-layout(row wrap)
                          v-flex(sm12 md6)
                            v-autocomplete#organization-country(
                              ref='organizationCountryRef'
                              v-model='orgAddress.country'
                              color='csblue'
                              label='Country'
                              return-object
                              :items='countriesAndStates'
                              item-text='name'
                              :rules='[rules.required]'
                            )
                          v-flex(sm12 md6)
                            v-select#organization-state(
                              ref='organizationStateRef'
                              v-model='orgAddress.state'
                              color='csblue'
                              :label='market.stateLabel'
                              :disabled='!orgAddress.country'
                              return-object
                              :items='availableStatesOrg'
                              item-text='name'
                              :rules='[rules.required]'
                            )

                      v-container(grid-list-lg)
                        v-layout(row wrap)
                          v-flex(xs2 sm1 align-self-center)
                            v-checkbox#terms.ml-2(v-model='allowCommercialCommunication' color='csblue')
                          v-flex(xs10 sm11 align-self-center)
                            .text-caption.grey--text I agree to receive news, product updates, event announcements and other communications from Broadsign Ads. You can unsubscribe anytime.
                      v-card-actions.text-center(style='display:block;')
                        v-btn.mx-auto.mt-3#signup-step2(rounded large :dark='!createUserBtnLoading' color='csblue' @click='createAccount()' :loading='createUserBtnLoading' :disabled='createUserBtnLoading' min-width='200')
                          span#signup-step2-text Sign Up
                        .text-caption.grey--text.text-center.py-1.mt-3
                          | By signing up, you agree to Campsite's
                          termsDialog.d-block
                          |  and
                          a.info--text(href='https://broadsign.com/privacy-policy' target='_blank')  Privacy Policy

</template>

<script>
import geoService from '@/services/geo.service'
import helpers from '@/services/helpers.service'
import userApi from '@/services/user.api'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import userService from '@/services/user'
import componentConfigService from '@/services/componentConfig'

import campsiteConfig from '@/config/campsite.config'

import termsDialog from '@/components/termsDialog'

export default {
  components: {
    termsDialog
  },
  data () {
    return {
      currentStep: 1,
      createUserBtnLoading: false,

      ipAddress: null,
      allowCommercialCommunication: false,

      // Form controlers and validation rules
      showPassword: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => (v && v.length >= 8) || 'Min 8 characters',
        validEmail: v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
        acceptedEmailDomain: v => campsiteConfig.rejectedEmailDomains.indexOf(v.substr(v.indexOf('@') + 1).toLowerCase()) === -1 || 'Please use a business email address',
        passwordsMatch: v => v === this.user.password || 'The passwords you entered don\'t match'
      },

      // Static lists
      currenciesFromApi: [],
      countryFromIp: '',
      countriesAndStates: [],

      // ===================
      // New account objects
      // ===================
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },

      orgInfo: {
        name: '',
        timeZone: '',
        currency: null
      },

      orgAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: '',
        country: null,
        state: null
      }
    }
  },
  created () {
    geoService.getCountriesAndStates().then(res => {
      const markets = defaultExchangeValues.getAllDefaultValues()

      const marketsCountriesIds = markets
        .filter(x => x.isPublic)
        .map(x => x.countryId)

      const marketsCountries = res.filter(c => marketsCountriesIds.includes(c.id))
      const otherCountries = res.filter(c => !marketsCountriesIds.includes(c.id))
      const divider = { divider: true }

      this.countriesAndStates = marketsCountries.concat(divider).concat(otherCountries)
    })

    userApi.getCurrencies().then(res => {
      this.currenciesFromApi = res
      this.setDefaults()
    })
  },
  computed: {
    currencies () {
      return this.currenciesFromApi
        .filter(x => {
          return defaultExchangeValues.getDefaultValuesByCurrency(x.code)?.isPublic
        })
        .map(x => {
          const market = defaultExchangeValues.getDefaultValuesByCurrency(x.code)
          return {
            label: `${market.countryName} (${x.code})`,
            code: x.code,
            countryCode: market.countryCode
          }
        })
    },
    market () {
      const currency = !this.orgInfo.currency ? 'USD' : this.orgInfo.currency.code
      return defaultExchangeValues.getDefaultValuesByCurrency(currency)
    },
    availableStatesOrg () {
      return this.orgAddress.country ? this.orgAddress.country.states : []
    },
    timeZones () {
      const zones = [this.market.countryCode]
      if (this.countryFromIp && this.countryFromIp !== zones[0]) zones.push(this.countryFromIp)
      return helpers.generateTimezones(zones)
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  watch: {
    timeZones (newV) {
      const tz = newV.find(x => x.value === this.orgInfo.timeZone)
      if (!tz) this.orgInfo.timeZone = this.market.timezone
    }
  },
  methods: {
    setDefaults () {
      // Market default values
      this.countryFromIp = this.market.countryCode
      this.orgInfo.currency = this.currencies.find(x => x.code === this.market.currency)
      this.orgInfo.timeZone = this.market.timezone

      // User default values
      userApi.getIp()
        .then(res => {
          this.ipAddress = res.ip
          const currencyFromIp = this.currencies.find(x => x.code === res.currency)
          this.orgInfo.currency = currencyFromIp || this.currencies.find(x => x.code === 'USD')

          this.countryFromIp = res.country
          const userTimezone = helpers.getUserTimezone()
          if (userTimezone && this.timeZones.find(x => x.value === userTimezone)) {
            this.orgInfo.timeZone = userTimezone
          }
        })
    },

    step1To2 () {
      if (this.$refs.step1 && this.$refs.step1.validate()) { this.currentStep = 2; window.scrollTo(0, 0) }
    },

    createAccount () {
      if (this.$refs.step2 && this.$refs.step2.validate()) {
        this.createUserBtnLoading = true

        const data = this.assembleData()
        userApi.signup(data)
          .then(createdOrganization => {
            // create contact in Hubspot
            userService.createNewHubspotContact({
              user: this.user,
              orgInfo: this.orgInfo,
              allowCommercialCommunication: this.allowCommercialCommunication,
              routeQuery: this.$route.query
            }, document.cookie)

            // login user
            const payload = {
              username: this.user.email.toLowerCase(),
              password: this.user.password,
              dontSetAppReady: true
            }

            this.$store.dispatch('user/login', payload).then(res => {
              // update Terms & Conditions
              const termsPayload = {
                id: createdOrganization.id,
                termsAcceptedBy: createdOrganization.users[0].id,
                termsVersion: '4'
              }
              userApi.editOrganization(termsPayload)
                .then(editedOrganization => {
                  this.updateAndRedirect()
                })
            })
          })
          .catch(error => {
            const msg = error.response.data.errors[0].message || 'Could not create your account. Please try again'
            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: msg
            })
            this.createUserBtnLoading = false
          })
      }
    },

    assembleData () {
      return {
        address: {
          addressLine1: this.orgAddress.addressLine1,
          addressLine2: this.orgAddress.addressLine2,
          city: this.orgAddress.city,
          country: this.orgAddress.country,
          state: this.orgAddress.state,
          postalCode: this.orgAddress.postalCode
        },
        contacts: [this.prefillPrimaryContact()],
        currency: this.currenciesFromApi.find(x => x.code === this.orgInfo.currency.code),
        type: 'Business',
        name: this.orgInfo.name,
        timezone: this.orgInfo.timeZone,
        users: [Object.assign({}, this.user, { allowCommercialCommunication: this.allowCommercialCommunication })]
      }
    },

    prefillPrimaryContact () {
      return {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email
      }
    },

    async updateAndRedirect () {
      await this.$store.dispatch('general/initializeApp')

      // change for setAppReady only? (already has profile when login)
      this.$store.dispatch('user/getUserProfile').then(res => {
        this.createUserBtnLoading = false
        this.$router.push({
          path: '/campaigns/all',
          query: { utm_form: 'sign_up_form' }
        })
      })
    }
  }
}
</script>
