<template lang="pug">
div
  vue-headful(:title="componentConfig.branding.title('Spending Report')")
  v-container.pa-0(fluid)
    //- METRICS
    v-row.ma-0(align='center' justify='center')
      v-col.pb-0(lg=6 md=8)
        v-row.ma-0
          v-hover(v-slot:default='{ hover }' v-for='metric in summaryMetrics' :key='metric.key')
            v-col(style="min-height: 80px; min-width: 190px; position: relative;")
              .text-center.metric-total(:class='metric.key')
                .text-overline
                  v-icon.mr-2(small :color='metric.isIncluded ? metric.color : "grey"') mdi-circle
                  span.metric-total-name {{ metric.name }}
                v-scroll-y-reverse-transition
                  .d-block.metric-total-value(v-if='!totalsLoading' :class='{ "text-h4": $vuetify.breakpoint.mdAndUp, "text-h6": $vuetify.breakpoint.smAndDown }') {{ formattedAs(metric.total, metric.type) }}
              v-checkbox.mt-1(dense color='grey' v-if='hover' v-model='metric.isIncluded' @change='toggleMetricDisplay(metric.key)' style='position:absolute; right:0; top: 0;')

    //- CHART
    div(:id='"wrapper-" + chartId("main")' style='height: 200px; position:relative;')
      v-fade-transition
        metricsChart(
          v-if='metricsChartData'
          ref='metrics-chart-main'
          :chart-id='chartId("main")'
          :data='metricsChartData'
          :date-format='chartDateFormat'
          style='height: 200px')
      v-fade-transition
        div(v-if='!metricsChartData' style='display: flex; z-index: 4; position: absolute; height: 200px; width: 100%; top: 0px;')
          v-progress-circular.mx-auto.my-auto(:size='40' width=3 indeterminate color='primary')
    //- v-divider

    //- TIME
    v-row.ma-0(justify='center' align='center')
      //- v-col(md=2)
      v-col(lg=8)
        v-row.ma-0(align='center' justify='center')
          v-btn.mx-1.time-interval-option(
            v-for='option in timeIntervalOptions'
            :key='option.key'
            small
            rounded
            :text='!selectedTimeInterval || selectedTimeInterval.key !== option.key'
            :depressed='selectedTimeInterval && selectedTimeInterval.key === option.key'
            :color="selectedTimeInterval && selectedTimeInterval.key === option.key ? 'primary' : null"
            @click='timeIntervalSelected(option)'
            ref='timeIntervalOption'
            ) {{ option.label }}

          dateRange(
            :full-dates='[startDate, endDate]'
            @updated='dateRangeUpdated')
            template(v-slot:customActivator='{}')
              v-btn.date-range-picker-activator(
                small
                rounded
                :text='selectedTimeInterval !== null'
                :depressed='selectedTimeInterval === null'
                :color="selectedTimeInterval === null ? 'primary' : null"
                ref='dateRangePickerActivator'
              )
                v-icon.mr-1(small left) mdi-calendar-range
                | {{ datesForDisplay }}

      //- COMMENTED TIMEZONES SECTION =================================
      //- v-col.text-right(md=2)
        v-menu
          template(v-slot:activator='{ on }')
            v-btn.mx-3(color='grey' small text rounded v-on='on')
              | {{ $store.getters['dashboard/timezone'] }}
              v-icon(small right) mdi-menu-down
          v-list(two-line dense)
            v-list-item(@click='')
              v-list-item-content
                v-list-item-subtitle.overline Organization Timezone
                v-list-item-title EST UTC-5:00
            v-list-item(@click='')
              v-list-item-content
                v-list-item-subtitle.overline Invoice Timezone
                v-list-item-title EST UTC-5:00
      //- =============================================================

    v-container.mt-4
      v-row.ma-0
        v-col(offset-xl=2 xl=8 cols=12)
          //- MAIN TABLE
          v-data-table(
            :headers='mainTableHeaders'
            :items='mainTableDataFiltered'
            item-key='key'
            class='transparent'
            hide-default-header
            disable-sort
            :search='search'
            :custom-filter="customSearch"
            :footer-props='footerProps'
            :items-per-page='itemsPerPage'
            :options.sync='paginationOptions')

            //- HEADERS
            template(v-slot:header='{ props: { headers } }')
              thead
                v-row.my-0(no-gutters style="width: 100%;" align="center")
                  v-col(v-for='(h,i) in headers' :key='h.value' :class='metricClasses(h, $vuetify.breakpoint.xsOnly)' :style='metricStyles(h, true)')

                    div(v-if='i === 0')
                      v-scroll-y-reverse-transition(leave-absolute)
                        v-text-field(
                          class='search-text-field'
                          v-if='searching'
                          v-model='search'
                          prepend-inner-icon='mdi-magnify'
                          :label='"Search " + h.text + "s"'
                          dense
                          autofocus
                          hide-details
                          clearable
                          color='primary'
                          @blur='searching = false')
                      v-scroll-y-transition(leave-absolute hide-on-leave)
                        v-card(flat color='transparent' v-if='!searching')
                          .d-flex.align-center
                            v-menu.table-groupby-tag-menu(internal-activator)
                              template(v-slot:activator='{ on }')
                                v-btn.table-groupby-tag-menu-activator(small text v-on='on')
                                  b {{ tableGroupByTag.name }}
                                  v-icon(small right) mdi-chevron-down
                              v-list(dense)
                                v-list-item-group(:value='tableGroupByTag.key')
                                  v-list-item.table-groupby-tag-menu-item(v-for='tag in tableGroupByTags' :key='tag.key' :value='tag.key' @click='changeTableGroupByTag(tag)')
                                    v-list-item-title {{ tag.name }}

                            v-btn.search-icon(icon @click.stop='searching = true')
                              v-icon mdi-magnify

                    .font-weight-bold.text-caption.text-uppercase(v-if='i !== 0' v-show='h.sortable && !$vuetify.breakpoint.xsOnly' @click='sortUpdated(h)' :class='{ "table-sort-col" : h.sortable }')
                      .clickable
                        | {{ h.text }}
                        v-icon(small right v-show='h.key === currentlySortedMetric.key') {{ currentlySortedMetric.isSortedAsc ? 'mdi-sort-descending' : 'mdi-sort-ascending' }}

                  v-col.text-right(:class='{ "pl-12" : !$vuetify.breakpoint.xsOnly }' style='width:100px; min-width:100px;')
                    v-menu.table-main-menu(bottom left nudge-bottom)
                      template(v-slot:activator='{ on }')
                        v-btn.table-main-menu-activator(icon v-on='on')
                          v-icon mdi-dots-vertical
                      v-list(dense)
                        //- v-list-item(@click='')
                        //-   v-list-item-icon.mr-4
                        //-     v-icon mdi-download
                        //-   v-list-item-title Export CSV
                        v-list-item.table-main-menu-item(@click='createReport()')
                          v-list-item-icon.mr-4
                            v-icon mdi-table-clock
                          v-list-item-title Run Report

            template(v-slot:body='{ items, headers }')
              v-row.ma-0(no-gutters v-if='!items.length && !tableLoading')
                v-col.text-center.pa-4
                  .body-2.grey--text {{ tableLoading ? 'Loading results...' : 'No Results Found' }}

              //- PANELS
              v-expansion-panels.py-2(v-model='expandedPanels' multiple)

                //- PROGRESS v2
                v-expansion-panel(v-for='i in 10' :key='i' v-if='tableLoading && !items.length')
                  v-expansion-panel-header
                    v-row.ma-0(no-gutters style="width: 100%;" align="center")
                      v-col(v-for='(h, hi) in headers' :key='h.value' :class='metricClasses(h)' :style='metricStyles(h)')
                        v-skeleton-loader(v-if='hi === 0' type='text' width='70%')
                        v-skeleton-loader.ml-auto(v-else v-show='!h.component' type='text' width='40%')
                      v-col.text-right.justify-end.align-center.d-sm-flex(style='width:100px; min-width:100px;')

                v-expansion-panel(v-for='item in items' :key='item.value' @click='togglePanel(item)')

                  v-expansion-panel-header
                    template(v-slot:default='{ open }')
                      v-hover(v-slot:default="{ hover }" ref='rowHover')
                        v-row.ma-0(no-gutters style="width: 100%;" align="center")
                          v-col.table-secondary-header(v-for='h in headers' :key='h.value' :class='metricClasses(h, open)' :style='metricStyles(h)')

                            v-scroll-y-reverse-transition
                              .body-2(
                                v-if='!h.component && (!open || h.leaveWhenOpened)'
                                :class='{ "text-truncate" : h.truncate && !open }'
                                :style="h.truncate && !open ? 'max-width: ' + h.truncate + 'px;' : ''") {{ formattedAs(item[h.value], h.type) }}

                            groupByLabel(
                              v-if='h.component === "groupByLabel"'
                              :groupByValue='formattedAs(item[h.value], h.type)'
                              :groupByParents='item.parentKeys'
                              :labelClass='{ "text-truncate" : h.truncate && !open }'
                              :labelStyle="h.truncate && !open ? 'max-width: ' + h.truncate + 'px;' : ''")

                            v-lazy
                              sparkline(
                                v-if='$vuetify.breakpoint.mdAndUp && h.component === "v-sparkline" && !open'
                                :item-key='item.key'
                                :data='sparklinesData[item.key]'
                                @getSparklineData='getSparklineData')

                          v-col.text-right.justify-end.align-center.d-sm-flex(style='width:100px; min-width:100px;')
                            v-menu.table-secondary-menu(bottom left attach='' v-if='(hover && !$vuetify.breakpoint.xsOnly) || open')
                              template(v-slot:activator='{ on }')
                                v-btn.table-secondary-menu-activator(icon v-on='on' @click.stop='')
                                  v-icon mdi-dots-vertical
                              v-list(dense)
                                v-list-item.table-secondary-menu-item(@click.stop='createReport(item.key)')
                                  v-list-item-icon.mr-4
                                    v-icon mdi-table-clock
                                  v-list-item-title Run Report

                          .caption.grey--text(v-if='open && !$vuetify.breakpoint.xsOnly' style='position: absolute; right: 6rem') {{ datesForDisplay }}

                  v-expansion-panel-content
                    div(:id='"wrapper-" + chartId(item.key)' style='height: 200px; position:relative;')
                      v-fade-transition
                        metricsChart(
                          v-if='secondaryChartDataFiltered(item.key)'
                          :data='secondaryChartDataFiltered(item.key)'
                          :chart-id='chartId(item.key)'
                          :date-format='chartDateFormat'
                          style='height: 200px')
                      v-fade-transition
                        div(v-if='secondaryChartLoading[item.key]' style='display: flex; z-index: 4; position: absolute; height: 200px; width: 100%; top: 0px;')
                          v-progress-circular.mx-auto.my-auto(:size='40' width=3 indeterminate color='primary')
                    //- v-divider
                    v-data-table(
                      :headers='secondaryTableHeaders'
                      :items='secondaryTableDataFiltered(item.key)'
                      :loading='secondaryTableLoading[item.key]'
                      item-key='key'
                      must-sort
                      :sort-by='currentlySortedMetric.key'
                      :sort-desc='!currentlySortedMetric.isSortedAsc'
                      dense
                      hide-default-footer
                      :items-per-page='100')

                      template(v-slot:body.prepend='{ headers }' v-if='!$vuetify.breakpoint.xsOnly')
                        tr
                          td(v-for='h in headers' :key='h.value' :class='metricClasses(h)' :style='metricStyles(h)')
                            strong {{ secondaryTotal(item, h) }}

                      template(v-slot:item='{ item, headers }')
                        tr(v-if='!$vuetify.breakpoint.xsOnly')
                          td(v-for='h in headers' :key='h.value' :class='metricClasses(h)' :style='metricStyles(h)')
                            .py-1.caption {{ formattedAs(item[h.value], h.type) }}
                        div(v-else)
                          v-row.my-0.px-4
                            v-col(v-for='h in headers' :key='h.value' :cols='h.value === "key" ? 12 : 6')
                              .overline {{ h.text }}
                              .py-1.body-2 {{ formattedAs(item[h.value], h.type) }}
                          v-divider

            template(v-slot:footer.page-text='{ pageStart, pageStop, itemsLength }')
              div
                //- pre {{ pageStart }}
                //- pre {{ pageStop }}
                //- pre {{ itemsLength }}

    v-navigation-drawer.dashboard-drawer(
      v-model='isDrawerOpen'
      fixed right temporary
      :width="$vuetify.breakpoint.xsOnly ? '100%' : '75%'"
      )
      v-container.px-0.pb-0.drawer-content(
        fluid grid-list-lg text-left
        v-if='isDrawerOpen'
        )
        .sticky-action-bar.sticky-top.mt-n3
          v-row.mx-0(align='center')
            v-col(cols='auto')
              v-btn.close-drawer-icon-btn(icon @click='close')
                v-icon mdi-close
            v-col(cols='auto')
              .text-h6.d-inline-block
                | {{ reportTemplate.reportName }}

        v-card(color='#f8f9fa')
          reportEditor(
            :template='reportTemplate'
            @changedReport='updateReportPayload'
          )

        .sticky-action-bar.sticky-bottom.pa-3
          v-btn.save-and-view-btn(
            color='primary' depressed
            :loading='savingReport'
            @click='saveAndView'
            ) Save and View
          v-btn.save-btn.ml-3(
            color='primary' text
            :loading='savingReport'
            @click='save'
            ) Save
          v-btn.cancel-btn.ml-3(
            text
            :loading='savingReport'
            @click='close'
            ) cancel
</template>
<style scoped lang="stylus">
>>> .v-data-table__wrapper {
  overflow: inherit
}
>>> .v-expansion-panel-header{
  padding: 6px 24px
}
>>> .theme--light.v-data-table .v-data-footer
  border-top: none

>>> .v-expansion-panel-content__wrap
  padding: 0 !important

.clickable
  cursor: pointer

.v-data-table thead .row .col:first-child
  margin-right: 24px

.sticky-action-bar {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.sticky-top {
  top: 0;
  border-bottom: 1px solid lightgray;
  z-index: 1;
}

.sticky-bottom {
  bottom: 0;
  border-top: 1px solid lightgray;
}
</style>

<script>
import Vue from 'vue'
import moment from 'moment'
import momentz from 'moment-timezone'

import metricsChart from '@/components/reportingMetricsChart.vue'
import dateRange from '@/components/customReport.dateRange'
import groupByLabel from '@/components/groupByLabel'
import sparkline from '@/components/sparkline'
import reportEditor from '@/views/reportEditor'

import dashboardService from '@/services/dashboard'
import helpers from '@/services/helpers.service'
import reportsApi from '@/services/reports.api'
import auctionPackageApi from '@/services/auctionPackage.api'
import reportsService from '@/services/reports'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    metricsChart,
    dateRange,
    groupByLabel,
    sparkline,
    reportEditor
  },
  data () {
    return {
      timeIntervalOptions: [
        {
          key: 'today',
          label: 'Today',
          start: moment(),
          end: moment(),
          reportTemplateDateRange: {
            range: 'dynamic',
            dynamicRange: 'Today',
            dates: null
          }
        },
        {
          key: 'last7days',
          label: 'last 7 days',
          start: moment().subtract(7, 'days'),
          end: moment().subtract(1, 'days'),
          reportTemplateDateRange: {
            range: 'dynamic',
            dynamicRange: 'Last 7 days',
            dates: null
          }
        },
        {
          key: 'last30days',
          label: 'last 30 days',
          start: moment().subtract(30, 'days'),
          end: moment().subtract(1, 'days'),
          reportTemplateDateRange: {
            range: 'dynamic',
            dynamicRange: 'Last 30 days',
            dates: null
          }
        },
        {
          key: 'mtd',
          label: 'mtd',
          start: moment().startOf('month'),
          end: moment(),
          reportTemplateDateRange: {
            range: 'dynamic',
            dynamicRange: 'Month to date',
            dates: null
          }
        },
        {
          key: 'qtd',
          label: 'qtd',
          start: moment().startOf('quarter'),
          end: moment(),
          reportTemplateDateRange: {
            range: 'dynamic',
            dynamicRange: 'Quarter to date',
            dates: null
          }
        },
        {
          key: 'ytd',
          label: 'ytd',
          start: moment().startOf('year'),
          end: moment(),
          reportTemplateDateRange: {
            range: 'dynamic',
            dynamicRange: 'Year to date',
            dates: null
          }
        }
      ],
      selectedTimeInterval: null,
      startDate: null,
      endDate: null,
      totalsLoading: true,
      totals: [],
      chartLoading: true,
      chartData: [],

      tableLoading: true,
      tableData: [],

      currentlySortedMetric: {
        key: 'totalMediaCost',
        isSortedAsc: false
      },

      // should we get those from API vs hardcoded?
      tableGroupByTag: {},
      tableGroupByTags: [
        { key: 'advertiser', name: 'Advertiser' },
        { key: 'campaign', name: 'Campaign' },
        { key: 'line', name: 'Line' }
      ],

      itemsPerPage: 50,
      footerProps: {
        itemsPerPageOptions: [10, 50, 100, 200]
      },
      page: 1,
      paginationOptions: null,
      expandedPanels: [],

      searching: false,
      search: '',

      secondaryChartData: {},
      secondaryChartLoading: {},
      secondaryTableData: {},
      secondaryTableLoading: {},

      sparklinesLoading: true,
      sparklinesData: {},

      metrics: [],

      isDrawerOpen: false,
      reportTemplate: {},
      reportPayload: null,
      savingReport: false
    }
  },
  watch: {
    paginationOptions: function (newVal, oldVal) {
      if (oldVal) {
        this.page = newVal.page
        this.itemsPerPage = newVal.itemsPerPage
        this.updateTable()
      }
    }
  },
  created () {
    momentz.tz.setDefault(this.organization.timezone)

    this.selectedTimeInterval = this.timeIntervalOptions.find(o => o.key === 'last30days')
    this.startDate = this.selectedTimeInterval.start
    this.endDate = this.selectedTimeInterval.end

    if (this.canReadAllOrgs) {
      this.tableGroupByTags.unshift({ key: 'organization', name: 'Organization' })
    }
    this.tableGroupByTag = this.tableGroupByTags[0]

    if (this.canSeeDspPartnerOptions) {
      this.tableGroupByTags.push({ key: 'dsp', name: 'DSP Partner' })
      this.tableGroupByTags.push({ key: 'auctionPackageCode', name: 'Auction Package' })
    }

    this.getMetrics()
      .then(metrics => {
        this.metrics = metrics
        this.updateDashboard()
      })
  },
  computed: {
    userToken () {
      return this.$store.getters['auth/idToken']
    },
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    canSeeDspPartnerOptions () {
      return this.$store.getters['general/isDspPartnerDashboardVisible']
    },
    organization () {
      return this.$store.getters['user/getOrganization']
    },
    timezone () {
      return 'UTC ' + momentz().tz(this.organization.timezone).format('Z')
    },
    canReadAllOrgs () {
      const orgPerms = this.$store.getters['user/permissions']('organization')
      return orgPerms ? orgPerms.read.allOrgs : false
    },
    datesForDisplay () {
      return this.startDate.format('MMM Do, YYYY') + ' - ' + this.endDate.format('MMM Do, YYYY')
    },
    numDaysSelected () {
      return this.dateDiff(this.startDate, this.endDate, 'days')
    },
    chartDateBreakdown () {
      return this.numDaysSelected <= 1
        ? 'hour'
        : this.numDaysSelected <= 31
          ? 'day'
          : this.numDaysSelected <= 92
            ? 'week'
            : this.numDaysSelected <= 366
              ? 'month'
              : 'year'
    },
    chartDateFormat () {
      return this.numDaysSelected <= 1
        ? 'MMM Do, YYYY h:mm A | z'
        : this.numDaysSelected <= 31
          ? 'MMM Do, YYYY'
          : this.numDaysSelected <= 92
            ? 'MMM Do, YYYY'
            : this.numDaysSelected <= 366
              ? 'MMM YYYY'
              : 'YYYY'
    },
    chartHeight () {
      return this.$vuetify.breakpoint.xsOnly ? 50 : 200
    },
    metaInfo () {
      return [
        {
          key: 'totalMediaCost',
          type: 'currency',
          color: this.$vuetify.theme.themes.light.primary,
          isIncluded: true,
          isBackground: true,
          isMain: true,
          align: 'right',
          width: this.$vuetify.breakpoint.smAndDown ? '' : 180
        },
        {
          key: 'totalMediaCostEcpm',
          type: 'currency',
          color: this.$vuetify.theme.themes.light.accent,
          isIncluded: true,
          isBackground: false,
          align: 'right',
          width: this.$vuetify.breakpoint.smAndDown ? '' : 200
        }
      ].map(m => {
        // API returns metrics as key/name
        // v-data-table needs value/text
        const metric = this.metrics.find(metric => metric.key === m.key)
        m.value = m.key
        m.name = metric ? metric.name : ''
        m.text = metric ? metric.name : ''
        return m
      })
    },
    metricsKeys () {
      return this.metaInfo.map(metric => metric.key)
    },
    filters () {
      return {
        from: this.startDate.format('YYYY-MM-DDT00:00:00'),
        to: this.endDate.format('YYYY-MM-DDT23:59:59'),
        tags: [
        ]
      }
    },
    summaryMetrics () {
      return this.metaInfo.map(metric => {
        const total = this.totals.length
          ? this.totals.find(t => t.key === metric.key).total
          : 0
        return { ...metric, total }
      })
    },
    metricsChartData () {
      if (!this.chartData.length) return
      return {
        dates: this.chartData.map(cd => cd.groups[0].value),
        metrics: this.metaInfo
          .filter(metric => metric.isIncluded)
          .map(metric => {
            return { ...metric, data: this.chartData.map(cd => cd.metrics.find(m => m.key === metric.key)?.total || 0) }
          })
      }
    },
    mainTableHeaders () {
      var newHeaders = [...this.metaInfo]
      newHeaders.unshift({ text: '', value: 'sparkline', component: 'v-sparkline', width: 160, align: 'right', sortable: false })
      newHeaders.unshift({
        text: this.tableGroupByTag.name,
        value: 'label',
        component: 'groupByLabel',
        truncate: this.$vuetify.breakpoint.smAndDown ? 140 : 390,
        width: this.$vuetify.breakpoint.smAndDown ? 150 : 400,
        leaveWhenOpened: true,
        sortable: false
      })

      return newHeaders.map(h => {
        if (h.sortable !== false) {
          h.sortable = true
        }
        return h
      })
    },
    mainTableDataFiltered () {
      return this.tableData.map(result => {
        const { groups, metrics } = result
        const obj = {}

        const groupsLength = groups.length
        obj.key = groups[groupsLength - 1].value
        obj.label = groups[groupsLength - 1].label || groups[groupsLength - 1].value
        obj.parentKeys = groups
          .filter((group, index) => {
            return index < groupsLength - 1
          })
          .map(group => group.value)

        metrics.map(metric => {
          obj[metric.key] = metric.total
          return obj
        })

        return obj
      })
    },
    secondaryChartDataFiltered: () => function (itemKey) {
      const chartData = this.secondaryChartData[itemKey] || []
      if (!chartData.length) return
      return {
        dates: chartData.map(cd => cd.groups[0].value),
        metrics: this.metaInfo
          .filter(metric => metric.isIncluded)
          .map(metric => {
            return { ...metric, data: chartData.map(cd => cd.metrics.find(m => m.key === metric.key).total) }
          })
      }
    },
    secondaryTableHeaders () {
      var newHeaders = [...this.metaInfo]
      newHeaders.unshift({ text: this.secondaryTableGroupByTag.name, value: 'label', truncate: 290, width: 300, leaveWhenOpened: true, hideTotal: true })

      return newHeaders.map(h => {
        if (h.sortable !== false) {
          h.sortable = true
        }
        return h
      })
    },
    secondaryTotal: () => function (item, header) {
      const itemKey = item.key

      if (header.hideTotal || this.secondaryTableLoading[itemKey]) {
        return ''
      }
      const value = item[header.value] || 0
      return this.formattedAs(value, header.type)
    },
    secondaryTableDataFiltered: () => function (itemKey) {
      const tableData = this.secondaryTableData[itemKey] || []

      if (!itemKey || !tableData) {
        return []
      }

      return tableData
        .map(result => {
          const { groups, metrics } = result
          const obj = {}

          obj.key = groups[0].value
          obj.label = groups[0].label || groups[0].value

          metrics.map(metric => {
            obj[metric.key] = metric.total
            return obj
          })

          return obj
        })
    },
    secondaryTableGroupByTag () {
      const key = this.tableGroupByTag.key
      const mapping = {
        organization: { key: 'advertiser', name: 'Advertiser' },
        advertiser: { key: 'campaign', name: 'Campaign' },
        campaign: { key: 'line', name: 'Line' },
        line: { key: 'venue', name: 'Venue' },
        dsp: { key: 'auctionPackageCode', name: 'Auction Package' },
        auctionPackageCode: { key: 'venue', name: 'Venue' }
      }
      return mapping[key]
    },
    parentDimensions () {
      const key = this.tableGroupByTag.key

      if (key === 'auctionPackageCode' || key === 'dsp') {
        return ['auctionPackageName', 'dsp', 'auctionPackageCode']
      }
      const mapping = this.tableGroupByTags.map(t => t.key)
      return mapping.slice(0, mapping.indexOf(key) + 1)
    },
    secondaryFilters: () => function (itemKey) {
      const filters = JSON.parse(JSON.stringify(this.filters))
      filters.tags.push(
        {
          key: this.tableGroupByTag.key,
          operation: 'include',
          values: [
            itemKey
          ]
        }
      )
      return filters
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    chartId (id) {
      return 'chart-' + id.toString()
    },
    formattedAs (val, type) {
      return helpers.dataDisplayFormatting(val, type, this.marketVals.currencySymbolString)
    },
    dateRangeUpdated (datesArr) {
      this.selectedTimeInterval = null
      this.startDate = moment(datesArr[0])
      this.endDate = moment(datesArr[1])
      this.updateDashboard()
    },
    timeIntervalSelected (option) {
      this.selectedTimeInterval = option
      this.startDate = option.start
      this.endDate = option.end
      this.updateDashboard()
    },
    getMetrics () {
      return reportsApi.getMetrics(this.userToken).then(resp => resp.result)
    },
    updateDashboard () {
      this.updateTotals()
      this.updateChart()
      this.updateTable()
    },
    updateTotals (paginating = false) {
      this.totalsLoading = true

      const options = {
        metrics: this.metricsKeys,
        filters: this.filters,
        groupBy: [],
        timezone: this.organization.timezone
      }

      dashboardService.getTotals(options, this.userToken)
        .then(data => {
          if (data.result && data.result[0].metrics) {
            this.totals = data.result[0].metrics
          }
        })
        .finally(() => {
          this.totalsLoading = false
        })
    },
    updateChart (paginating = false) {
      this.chartLoading = true
      this.chartData = []

      const options = {
        metrics: this.metricsKeys,
        filters: this.filters,
        groupBy: ['date:' + this.chartDateBreakdown],
        paging: {
          orderBy: [
            {
              key: 'date:' + this.chartDateBreakdown,
              ascending: true
            }
          ]
        },
        timezone: this.organization.timezone
      }

      dashboardService.getChartData(options, this.userToken)
        .then(data => {
          if (data.result) {
            this.chartData = this.zeroFillMissingDates(data.result, this.filters.from, this.filters.to, this.chartDateBreakdown)
          }
        })
        .finally(() => {
          this.chartLoading = false
        })
    },
    dateDiff (from, to, timeKey = 'days') {
      const momentFrom = moment(from)
      const momentTo = moment(to)

      const diff = momentTo.diff(momentFrom, timeKey, true)
      return Math.round(diff)
    },
    zeroFillMissingDates (data, timeFrom, timeTo, dateKey = 'day') {
      const dateKeyPlural = dateKey + 's'
      const diff = this.dateDiff(timeFrom, timeTo, dateKeyPlural)

      return Array(diff).fill({}).map((d, i) => {
        const date = moment(timeFrom).add(i, dateKeyPlural)

        const thisData = data.find(data => moment(data.groups[0].value).isSame(date, dateKey))
        return thisData || {
          groups: [{
            key: 'date:' + dateKey,
            value: date
          }],
          metrics: this.metricsKeys.map(m => {
            return {
              key: m,
              total: 0
            }
          })
        }
      })
    },
    getParentTableGroupTags (tableGroupTagIndex) {
      if (this.tableGroupByTag.key === 'dsp') return []
      if (tableGroupTagIndex >= 0) {
        return this.tableGroupByTag.key === 'auctionPackageCode'
          ? [{ key: 'dsp' }] : this.tableGroupByTags.slice(0, tableGroupTagIndex)
      }
    },
    updateTable () {
      this.tableLoading = true
      this.tableData = []
      this.expandedPanels = []
      this.sparklinesData = {}
      this.secondaryChartData = {}
      this.secondaryChartLoading = {}
      this.secondaryTableData = {}
      this.secondaryTableLoading = {}

      const tableGroupTagIndex = this.tableGroupByTags.findIndex(tag => tag.key === this.tableGroupByTag.key)
      const parentTableGroupTags = this.getParentTableGroupTags(tableGroupTagIndex)
      const parentTableGroupTagsKeys = parentTableGroupTags.map(tag => tag.key)

      const options = {
        metrics: this.metricsKeys,
        filters: this.filters,
        groupBy: [...parentTableGroupTagsKeys, this.tableGroupByTag.key],
        paging: {
          orderBy: [
            {
              key: this.currentlySortedMetric.key,
              ascending: this.currentlySortedMetric.isSortedAsc
            }
          ],
          skip: this.page - 1,
          take: this.itemsPerPage
        },
        timezone: this.organization.timezone
      }

      dashboardService.getTableData(options, this.userToken)
        .then(async data => {
          if (data.result) {
            if (this.tableGroupByTag.key === 'auctionPackageCode') data = await this.getAuctionPackageNamesFromCode(data, 1)
            var filteredTableData = data.result.filter(r => {
              const itemKey = r.groups[r.groups.length - 1].value
              if (itemKey && itemKey.length) {
                Vue.set(this.sparklinesData, itemKey, [])
                return true
              }
              return false
            })
            this.tableData = filteredTableData
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
        .catch(e => {
          console.log(e)
        })
    },
    getSparklineData (itemKey) {
      const filters = Object.assign({}, this.filters, {
        tags: [
          {
            key: this.tableGroupByTag.key,
            operation: 'include',
            values: [itemKey]
          }
        ]
      })

      const options = {
        metrics: ['totalMediaCost'],
        filters,
        groupBy: ['date:' + this.chartDateBreakdown],
        paging: {
          orderBy: [
            {
              key: 'date:' + this.chartDateBreakdown,
              ascending: true
            }
          ]
        },
        timezone: this.organization.timezone
      }

      dashboardService.getSparklinesData(options, this.userToken)
        .then(data => {
          if (data.result) {
            const filterValueData = this.zeroFillMissingDates(
              data.result,
              filters.from,
              filters.to,
              this.chartDateBreakdown
            )
              .map(r => Math.round(r.metrics[0].total))

            this.sparklinesData[itemKey] = filterValueData
          }
        })
        .finally(() => {
          this.sparklinesLoading = false
        })
        .catch(e => {
          console.log(e)
        })
    },
    toggleMetricDisplay (metricKey) {
      // reload graph without querying API
      const chartData = [...this.chartData]
      this.chartData = []

      const secondaryChartData = Object.assign({}, this.secondaryChartData)
      const secondaryKeys = Object.keys(this.secondaryChartData)
      if (secondaryKeys.length) {
        secondaryKeys.map(sk => {
          this.secondaryChartData[sk] = []
        })
      }

      const metric = this.metaInfo.find(metric => metric.key === metricKey)
      metric.isIncluded = !metric.isIncluded

      setTimeout(() => {
        this.chartData = chartData

        if (secondaryKeys.length) {
          secondaryKeys.map(sk => {
            this.secondaryChartData[sk] = secondaryChartData[sk]
          })
        }
      }, 100)
    },
    metricClasses (metric, forceHide = false) {
      const classes = []

      const align = metric.align || 'left'
      classes.push('text-' + align)

      if (metric.value !== 'label') {
        if (forceHide) {
          classes.push('d-none')
        }

        if (this.$vuetify.breakpoint.smAndDown && !metric.isMain) {
          classes.push('d-none')
        }
      }

      if (metric.sortable !== false) {
        classes.push('sortable')
      }

      return classes
    },
    metricStyles (metric, inHeaders = false) {
      var style = ''
      if (metric.width) {
        if (!this.$vuetify.breakpoint.smAndDown || !inHeaders) {
          style += 'width: ' + metric.width + 'px; min-width: ' + metric.width + 'px;'
        }
      }
      return style
    },
    // should be _debounced
    sortUpdated (metric) {
      if (metric.key === this.currentlySortedMetric.key) {
        this.currentlySortedMetric.isSortedAsc = !this.currentlySortedMetric.isSortedAsc
      } else {
        this.currentlySortedMetric.key = metric.key
      }

      this.updateTable()
    },
    togglePanel (item) {
      this.initSecondaryItem(item)
    },
    initSecondaryItem (item) {
      const itemKey = item.key

      if (!this.secondaryChartData[itemKey]) {
        Vue.set(this.secondaryChartData, itemKey, [])
        Vue.set(this.secondaryChartLoading, itemKey, true)

        Vue.set(this.secondaryTableData, itemKey, [])
        Vue.set(this.secondaryTableLoading, itemKey, true)

        this.getSecondaryChartData(itemKey)
        this.getSecondaryTableData(itemKey)
      }
    },
    getSecondaryChartData (itemKey) {
      this.secondaryChartLoading[itemKey] = true
      this.secondaryChartData[itemKey] = []

      const options = {
        metrics: this.metricsKeys,
        filters: this.secondaryFilters(itemKey),
        groupBy: ['date:' + this.chartDateBreakdown],
        paging: {
          orderBy: [
            {
              key: 'date:' + this.chartDateBreakdown,
              ascending: true
            }
          ]
        },
        timezone: this.organization.timezone
      }

      reportsApi.liveQueries(options, this.userToken)
        .then(data => {
          if (data.result) {
            this.secondaryChartData[itemKey] = this.zeroFillMissingDates(data.result, this.secondaryFilters(itemKey).from, this.secondaryFilters(itemKey).to, this.chartDateBreakdown)
          }
        })
        .finally(() => {
          this.secondaryChartLoading[itemKey] = false
        })
    },
    getSecondaryTableData (itemKey) {
      this.secondaryTableData[itemKey] = []
      this.secondaryTableLoading[itemKey] = true

      const options = {
        metrics: this.metricsKeys,
        filters: this.secondaryFilters(itemKey),
        groupBy: [this.secondaryTableGroupByTag.key],
        paging: {
          orderBy: [
            {
              key: this.currentlySortedMetric.key,
              ascending: this.currentlySortedMetric.isSortedAsc
            }
          ],
          skip: this.page - 1,
          take: this.itemsPerPage
        },
        timezone: this.organization.timezone
      }
      reportsApi.liveQueries(options, this.userToken)
        .then(async data => {
          if (data.result) {
            if (this.tableGroupByTag.key === 'dsp') data = await this.getAuctionPackageNamesFromCode(data, 0)
            this.secondaryTableData[itemKey] = data.result
          }
        })
        .finally(() => {
          this.secondaryTableLoading[itemKey] = false
        })
    },
    async getAuctionPackageNamesFromCode (data, codeIndex = 0) {
      var apCodes = data.result.map(x => x.groups).map(x => x[codeIndex]).map(x => x.value)
      var filters = [{ name: 'multiValuesSearch', values: apCodes.map(x => 'AP (' + x + ')'), field: 'code', operator: 'is any of' }]
      while (filters[0].values.length > 0) {
        const filtersBatch = filters.map(a => Object.assign({}, a))
        filtersBatch[0].values = filters[0].values.splice(0, 20)
        await auctionPackageApi.getAuctionPackages(0, 0, 'id', 'desc', filtersBatch, true)
          .then(aps => {
            for (const key of aps) {
              for (const item of data.result) {
                if (item.groups[codeIndex].value === key.code) {
                  item.groups[codeIndex].label = key.code + ' - ' + key.name
                }
              }
            }
          })
      }
      return data
    },
    changeTableGroupByTag (tag) {
      this.tableGroupByTag = tag
      this.updateTable()
    },
    async createReport (secondaryKey = null) {
      await this.generateReportTemplate(secondaryKey)
      this.isDrawerOpen = true
    },
    async generateReportTemplate (secondaryKey = null) {
      const filters = secondaryKey
        ? this.secondaryFilters(secondaryKey)
        : this.filters

      let { from, to, tags } = filters
      tags = tags.filter(t => t.key !== 'dsp')

      let adjustedTags = null
      if (secondaryKey) {
        const filterDetails = reportsService.findInTreeList(this.tableGroupByTag.name, reportsService.getItems('filters'), 'name')
        if (filterDetails) {
          const matches = await reportsApi.searchCampsiteResources(filterDetails.id, secondaryKey, 1)
          if (matches.length > 0 && matches[0].name === secondaryKey) {
            adjustedTags = [{
              Key: filterDetails.serviceId,
              Operation: 'include',
              Values: [{
                Id: 99999,
                Value: matches[0].id
              }]
            }]
          } else {
            adjustedTags = []
            this.$store.commit('snackbar/setSnackbar', {
              type: 'info',
              msg: `${this.tableGroupByTag.name} ${secondaryKey} cannot be found`
            })
          }
        }
      }

      this.reportTemplate = {
        reportName: secondaryKey
          ? 'Spending Report - ' + secondaryKey
          : 'Spending Report',
        schedule: null,
        metrics: ['totalMediaCost', 'totalMediaCostEcpm'],
        dimensions: secondaryKey
          ? this.parentDimensions.concat([this.secondaryTableGroupByTag.key])
          : this.parentDimensions,
        timeBreakdown: 'none',
        dateRange: this.selectedTimeInterval
          ? this.selectedTimeInterval.reportTemplateDateRange
          : {
            range: 'fixed',
            dates: [moment(from), moment(to)]
          },
        filters: {
          from: this.selectedTimeInterval
            ? null
            : from,
          to: this.selectedTimeInterval
            ? null
            : to,
          tags: adjustedTags || tags.map(t => {
            return {
              Key: t.key,
              Operation: t.operation,
              Values: t.values.map(v => {
                return {
                  Id: 99999,
                  Value: v
                }
              })
            }
          })
        }
      }
    },
    close () {
      this.isDrawerOpen = false
      this.reportTemplate = {}
      this.reportPayload = null
      this.savingReport = false
    },
    async save () {
      await this.saveReport()
      this.close()
    },
    async saveAndView () {
      const report = await this.saveReport()
      if (this.reportPayload?.schedule?.frequency === 'OnDemand') this.$store.dispatch('reports/generateReport', report.id)
      this.$router.push({ name: 'Report', params: { id: report.id } })
    },
    async saveReport () {
      const report = await this.$store.dispatch('reports/createReport', this.reportPayload)
      // if (report) { popSuccessSnacbar() ..? }
      return report
    },
    updateReportPayload (obj) {
      this.reportPayload = { ...obj }
    },
    customSearch (_, search, item) {
      if (item && item.parentKeys && item.parentKeys.length) {
        if (item.parentKeys.some(v => v && v.toString().toLowerCase().includes(search.toLowerCase()))) return true
      }
      if (item && item.label) return item.label.toString().toLowerCase().includes(search.toLowerCase())
    }
  }
}
</script>
