<template lang="pug">
  v-menu(v-if='deals.length' v-model='dealsMenuOpened' :close-on-content-click='false' :close-on-click='false' offset-y top open-on-hover open-delay='500')
    template(v-slot:activator='{on}')
      .primary--text(v-on='on' @click="$emit('openEditLineDialog')" style='cursor: pointer;') {{ deals.length }} deal
        span(v-show='deals.length > 1') s

    v-card.elevation-24()
      v-card-text.px-0(style='')
        v-layout(justify-space-between column fill-height)
          dealsTable(:deals="deals")

      v-card-actions
        v-btn(text color='primary' block @click="$emit('openEditLineDialog')") Add and Manage Deals
</template>
<script>
import dealsTable from './dealsTable'

export default {
  components: {
    dealsTable
  },
  props: ['deals', 'lineId', 'openEditLineDialog'],
  data () {
    return {
      dealsMenuOpened: false
    }
  }
}
</script>
