<template lang="pug">
  .d-block(style="width: 100%;")
    .d-inline-block(style="width: 100%;")
      campaignProgressBar.budget-progress-bar(
        ref='budget-progress-bar'
        :target="budget"
        :actual="spent"
        :progressPercentage="deliveringPercentage"
        :pacingPercentage="pacingPercentage"
        :color= "getProgressColor()"
        :formatting= "'currency'"
        :min-width="170"
        :warningMsg="minLinePacingWarning"
        :isProposal="isProposal"
      )
</template>

<script>
import campaignProgressBar from './campaignProgressBar.vue'
import lineStatusService from '../services/lineStatus.service'

export default {
  components: {
    campaignProgressBar
  },
  props: ['item', 'minWidth', 'warningMsg'],
  data () {
    return {
      width: this.minWidth ? this.minWidth : 0
    }
  },
  computed: {
    budget () {
      return this.item.budget ?? 0
    },
    spent () {
      return this.item.spent ?? 0
    },
    isProposal () {
      return this.item.status === 'Proposal'
    },
    deliveringPercentage () {
      if (this.item && this.item.budget) {
        return Math.round((this.item.spent / this.item.budget) * 100)
      }
      return 0
    },
    pacingPercentage () {
      if (this.item) {
        return Math.round(this.item.pacing * 100)
      }
      return 0
    },
    minLinePacingWarning () {
      return this.item.minLinePacing >= 0.2 && !this.isProposal ? 'This Line Order requires your attention' : ''
    }
  },
  methods: {
    getProgressColor () {
      return lineStatusService.pacingDelta(this.item, this.deliveringPercentage)
    }
  }
}
</script>

<style scoped>
.progress-mark{
  position: absolute;
  border-right: 2px solid blue;
  height: 100%;
  width: 1px;
  display: block;
  top: 0;
  z-index: 1;
}
</style>
