<template lang="pug">
div
  vue-headful(:title="componentConfig.branding.title('Reports Overview')")
  v-container(fluid)
    v-row.my-0(align-stretch)
      v-col(cols='6' md='4' )
        v-card.template.elevation-2.pb-2
          v-card-text.text--primary.pb-0(style='min-height:130px;')
            .overline Performance Report
            .title Campaign Performance
            div Pull all your campaign performance data to feed out any consolidated report services on a daily basis.
          v-card-actions.py-0
            v-btn(block text color='primary' @click="goToCreateReportWithTemplate('campaign-performance')") Use Template
      v-col(cols='6' md='4' )
        v-card.template.elevation-2.pb-2
          v-card-text.text--primary.pb-0(style='min-height:130px;')
            .overline Invoice Report
            .title Last Month Spent
            div Pull details from your last month invoice and send it to a contacts list every first day of the month.
          v-card-actions.py-0
            v-btn(block text color='primary' @click="goToCreateReportWithTemplate('last-month-spent')") Use Template
      v-col(cols='6' md='4' )
        v-card.template.elevation-2.pb-2
          v-card-text.text--primary.pb-0(style='min-height:130px;')
            .overline Spending Report
            .title Weekly Advertisers Spent
            div Track advertisers spend and automatically send report to a contacts list on a weekly basis.
          v-card-actions.py-0
            v-btn(block text color='primary' @click="goToCreateReportWithTemplate('weekly-advertisers-spent')") Use Template
      //-v-col(cols='6' md='3' )
        v-card.template.elevation-2.pb-2
          v-card-text.text--primary.pb-0(style='min-height:130px;')
            .overline Mobile Retargeting Report
            .title Campaign Venues Locations
            div Pull polygons and GPS coordinates where your campaign is currently delivering on a daily basis.
          v-card-actions.py-0
            v-btn(block text color='primary' @click="goToCreateReportWithTemplate('campaign-venues-locations')" disabled) Use Template

  div.pt-6
    v-toolbar.elevation-0(color="transparent")
      .text-h5.hidden-sm-and-down Reports
      v-text-field(label='Find Report' prepend-inner-icon='mdi-magnify' solo hide-details v-model='search' clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")
      v-btn.mt-0.hidden-sm-and-down(large rounded color='primary' :to="{ name: 'Create Report' }")
        v-icon(left) mdi-plus
        span New Report
    v-tabs(background-color='transparent' slider-color='primary' slot='extension' v-model='currentTab')
      v-tab(ripple color='primary lighten-4')
        | All
      //- v-tab(ripple)
        | Created by me
      //- v-tab(ripple)
        | Archived
    v-divider

    v-scroll-y-reverse-transition
      v-toolbar.action-bar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
        span.hidden-sm-and-down.desktop-action-bar-summary
          v-toolbar-title.text-subtitle-1(v-if='selected.length < reports.length') {{ selected.length }} reports selected
          v-toolbar-title.text-subtitle-1(v-else) All {{ reports.length }} reports are selected

        span.hidden-md-and-up
          v-chip.ma-1(v-if='selected.length < reports.length' dark outlined color='white' small)
            | {{ selected.length }}
          v-chip.ma-1(v-else dark color='white' class='secondary--text' small)
            | {{ reports.length }}

        v-btn.ml-2(text outlined small dark v-if='selected.length < reports.length' :disabled='isDeletingReports' @click='selectAll') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ reports.length }}&nbsp;
            | reports

        v-toolbar-items.ml-3
          confirmationDialog(v-if='!isDeletingReports' @confirmAction='deleteReports' type='report' action='delete' :numberOfEntries='selected.length')
          v-btn(v-else text :loading='isDeletingReports')
        v-spacer
        v-btn.ml-3(icon @click='selected = []')
            v-icon mdi-close

    v-data-table.cs-data-table(
      v-model='selected'
      :headers='headers'
      :expanded.sync='expandedReports'
      :items='reports'
      item-key='id'
      :search='search'
      :loading='isFetching'
      loading-text='Loading reports...'
      :items-per-page='50'
      :footer-props='{itemsPerPageOptions: [50, 100, 250]}'
      sort-by='lastModifiedOn'
      sort-desc
      show-select
      show-expand
      )
      template(v-slot:expanded-item='{ headers, item }')
        td
        td
        td(colspan='1' style='vertical-align:top;')
          div.py-4
            .font-weight-bold Filters
            filtersSummary(:report='item')
        td(colspan='1' style='vertical-align:top;')
          div.py-4
            div.font-weight-bold Time Breakdown
            div.caption.mb-2
              div.report-time-breakdown {{ extractTimeBreakdown(item) }}
        td(colspan='1' style='vertical-align:top;')
          div.py-4
            div.font-weight-bold Dimensions
            div.caption.mb-2
              div.report-row(v-for='row in extractRows(item)' :key='row') {{ row }}
        td(colspan='1' style='vertical-align:top;')
          div.py-4
            div.font-weight-bold Metrics
            div.caption
              div.report-column(v-for='column in extractColumns(item)' :key='column') {{ column }}
        td(colspan='1' style='vertical-align:top;')
          div.py-4
            div.font-weight-bold Recipients
            div.caption
              recipientsSummary(:report='item')

      template(v-slot:item.name='{ item }')
          router-link.info--text.report-name(:to="{ name: 'Report', params: { id: item.id } }")
            | {{ extractName(item) }}

      template(v-slot:item.schedule='{ item }')
        scheduleSummary(:report='item' :isReportPage='false')

      template(v-slot:item.dateRange='{ item }')
        dateRangeSummary(:report='item')

      //- template(v-slot:item.createdby='{ item }')
        div Maxime Chouinard
        div.caption.grey--text CA - Broadsign Ads

      template(v-slot:item.lastModifiedOn='{ item }')
        div.modified-date {{ formatDate(item.lastModifiedOn).date }}
        div.modified-time.caption.grey--text {{ formatDate(item.lastModifiedOn).time }}

      template(v-slot:item.lastrun='{ item }')
        .last-run
          div(v-if='item.latestExport')
            .d-flex(v-if="getLastRunStatus(item) === 'Done'")
              div
                div.run-date {{ getLastRunDate(item) }}
                div.run-time.caption.grey--text {{ getLastRunTime(item) }}
              v-btn.ml-4.mt-1.export-btn.align-center(
                @click='downloadCsv(item)'
                :loading='item.id === isDownloadingCsvForId'
                text icon
                )
                v-icon mdi-download
            div(v-else) {{ getLastRunStatus(item) }}
          div(v-else) None

      template(v-slot:item.action='{ item }')
        v-btn.run-report-btn(
          v-if='isReportOnDemand(item)'
          text color='primary'
          :disabled='!canRunReport(item)'
          icon
          @click='runReport(item)'
          )
          v-icon() mdi-refresh

    v-divider
</template>

<script>
import { mapState } from 'vuex'
import mtz from 'moment-timezone'
import reportsService from '@/services/reports'
import tracking from '@/services/tracking'
import csv from '@/services/csv.service'
import componentConfigService from '@/services/componentConfig'

import scheduleSummary from '@/components/report.scheduleSummary.vue'
import recipientsSummary from '@/components/report.recipientsSummary.vue'
import dateRangeSummary from '@/components/report.dateRangeSummary.vue'
import filtersSummary from '@/components/report.filtersSummary.vue'
import confirmationDialog from '@/components/actionConfirmationDialog.vue'

export default {
  components: {
    scheduleSummary,
    recipientsSummary,
    dateRangeSummary,
    filtersSummary,
    confirmationDialog
  },
  async created () {
    await this.$store.dispatch('reports/getMetricsAndDimensions')
  },
  mounted () {
    if (this.$store.getters['user/isForAdServer']) {
      this.$router.push({ name: 'Access Denied' })
    }

    this.$store.dispatch('reports/getReports')
  },
  data () {
    return {
      search: null,
      selected: [],
      currentTab: 0,
      headers: [
        { text: '', value: 'data-table-expand' },
        { text: 'Last Modified On', align: 'left', sortable: true, value: 'lastModifiedOn', width: '145px' },
        { text: 'Created By', align: 'left', value: 'createdBy' },
        { text: 'Report Name', align: 'left', value: 'name' },
        { text: 'Date Range', align: 'left', value: 'dateRange' },
        { text: 'Schedule', align: 'left', value: 'schedule' },
        { text: 'Last Run', align: 'left', value: 'lastrun', width: '165px' }
        // { text: 'Action', align: 'center', value: 'action' }
      ],
      expandedReports: [],
      isDownloadingCsvForId: null,
      isDeletingReports: false
    }
  },
  computed: {
    ...mapState('reports', {
      isFetching: state => state.fetchingReports,
      reports: state => state.reports
    }),
    organizationTimezone () {
      const org = this.$store.getters['user/getOrganization']
      return org ? org.timezone : 'UTC'
    },
    dimensionsAvailable () {
      return this.$store.getters['reports/dimentions']
    },
    metricsAvailable () {
      return this.$store.getters['reports/metrics']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    extractName (report) {
      return reportsService.decodeName(report)
    },
    extractColumns (report) {
      return reportsService.decodeMetricsPayload(report, this.metricsAvailable).map(c => c.name)
    },
    extractRows (report) {
      return reportsService.decodeGroupByPayload(report, this.dimensionsAvailable).dimensions.filter(d => !!d).map(c => c.name)
    },
    extractTimeBreakdown (report) {
      return reportsService.decodeGroupByPayload(report).timeBreakdown.label
    },
    goToCreateReportWithTemplate (template) {
      tracking.sendEvent(['ga'], 'customReportUsedTemplate', { label: `click button: use template ${template}` })
      this.$router.push({ name: 'Create Report', query: { template } })
    },
    formatDate (date) {
      return {
        date: mtz.tz(date, this.organizationTimezone).format('MMM Do, YYYY'),
        time: mtz.tz(date, this.organizationTimezone).format('h:mm A | z')
      }
    },
    getLastRunStatus (report) {
      return report?.latestExport?.status
    },
    getLastRunDate (report) {
      const entry = report?.latestExport
      return entry && this.formatDate(entry.generatedOn)?.date
    },
    getLastRunTime (report) {
      const entry = report?.latestExport
      return entry && this.formatDate(entry.generatedOn)?.time
    },
    downloadCsv (report) {
      if (report) {
        const reportId = report.id
        const runId = report?.latestExport?.id

        this.isDownloadingCsvForId = reportId
        this.$store.dispatch('reports/getRunFile', { reportId, runId }).then((data) => {
          tracking.sendEvent(['ga'], 'customReportDownloadedReportExportReportsOverview')
          csv.csvExport(data, `${report.name} - ${this.getLastRunDate(report)} ${this.getLastRunTime(report)}`)
        }).finally(() => { this.isDownloadingCsvForId = null })
      }
    },
    isReportOnDemand (report) {
      return report?.schedule?.frequency === 'OnDemand'
    },
    canRunReport (report) {
      return this.isReportOnDemand(report) && report?.latestExport?.status !== 'Processing'
    },
    runReport (report) {
      this.$store.dispatch('reports/generateReport', report.id)
      tracking.sendEvent(['ga'], 'customReportRanReportReportsOverview')
      this.$router.push({ name: 'Report', params: { id: report.id } })
    },
    selectAll () {
      this.selected = [...this.reports]
    },
    async deleteReports () {
      this.isDeletingReports = true
      for (const r of this.selected) {
        await this.$store.dispatch('reports/deleteReport', r.id)
        tracking.sendEvent(['ga'], 'customReportDeletedReportReportsOverview')
      }
      this.isDeletingReports = false
      this.selected = []
    }
  }
}
</script>
