import axios from 'axios'

import auctionPackageService from './auctionPackage.service'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  createAuctionPackage,
  getAuctionPackages,
  getAssignments,
  getAssignmentsCount,
  getAuctionPackageCount,
  getTotalAuctionPackageCount,
  getAuctionPackage,
  editAuctionPackage,
  duplicateAuctionPackage,
  bulkAction,
  getAuctionPackageListForCSV,
  getToken,
  generateToken
}

async function createAuctionPackage (auctionPackage, organizationId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages'

  if (organizationId) {
    endpoint += '?organizationid=' + organizationId
  }

  return await axios.post(endpoint, auctionPackage)
    .then(response => response.data)
}

var getAuctionPackagesRequest
function getAuctionPackages (skip = 0, take = 5, orderBy = 'id', order = 'desc', filters = []) {
  if (getAuctionPackagesRequest) {
    getAuctionPackagesRequest.cancel('Previous get AUCTION PACKAGES request canceled.')
  }
  getAuctionPackagesRequest = axios.CancelToken.source()

  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages?skip=' + skip + '&take=' + take + '&$orderby=' + orderBy + ' ' + order

  var filtersString = auctionPackageService.buildApiFiltersString(filters)
  if (filtersString) {
    endpoint += '&' + filtersString
  }

  return axios.get(endpoint, { cancelToken: getAuctionPackagesRequest.token }).then(response => response.data)
}

function getAssignments (skip = 0, take = 5, orderBy = 'creativeid', order, auctionPackageId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages/' + auctionPackageId + '/assignments?skip=' + skip + '&take=' + take + '&$orderby=' + orderBy + ' ' + order
  return axios.get(endpoint).then(response => response.data)
}

function getAssignmentsCount (filters = [], auctionPackageId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages/' + auctionPackageId + '/assignments'

  var filtersString = auctionPackageService.buildApiFiltersString(filters)
  if (filtersString) {
    endpoint += '?' + filtersString
  }
  return axios.head(endpoint).then(response => parseInt(response.headers['x-count']) || 0)
}

var countAuctionPackageRequest
function getAuctionPackageCount (filters = []) {
  if (countAuctionPackageRequest) {
    countAuctionPackageRequest.cancel('Previous count AUCTION PACKAGES request canceled.')
  }
  countAuctionPackageRequest = axios.CancelToken.source()

  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages'

  var filtersString = auctionPackageService.buildApiFiltersString(filters)
  if (filtersString) {
    endpoint += '?' + filtersString
  }
  return axios.head(endpoint, { cancelToken: countAuctionPackageRequest.token }).then(response => parseInt(response.headers['x-count']) || 0)
}

/** getTotalAuctionPackageCount
 * getTotalCampaignsCount()
 * @returns total number of auction packages created by (current logged in) user's organization
 * NON-cancelable request VS getAuctionPackagesCount()
 */
function getTotalAuctionPackageCount () {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages'
  return axios.head(endpoint).then(response => parseInt(response.headers['x-count']) || 0)
}

function getAuctionPackage (auctionPackageId) {
  const url = VUE_APP_CAMPSITE_API_URL + '/auctionPackages/' + auctionPackageId
  return axios.get(url)
}

function editAuctionPackage (auctionPackageId, modifiedFields) {
  const url = VUE_APP_CAMPSITE_API_URL + '/auctionpackages/' + auctionPackageId
  return axios.patch(url, modifiedFields)
}

function duplicateAuctionPackage (auctionPackageId, organizationId) {
  let url = VUE_APP_CAMPSITE_API_URL + '/auctionpackages/' + auctionPackageId + '/clone'

  if (organizationId) {
    url += '?organizationId=' + organizationId
  }

  return axios.post(url, {}).then(res => res.data)
}

function bulkAction (params, modifs) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages?'
  var filtersString = auctionPackageService.buildApiFiltersString(params.filters)

  var urlPieces = []
  if (filtersString) { urlPieces.push(filtersString) }
  if (params.take) { urlPieces.push(`take=${params.take}`) }
  if (params.skip) { urlPieces.push(`skip=${params.skip}`) }

  return axios.patch(endpoint + urlPieces.join('&'), modifs).then(response => response.data)
}

function getAuctionPackageListForCSV (params = {}) {
  const config = {
    responseType: 'text/csv'
  }

  var endpoint = VUE_APP_CAMPSITE_API_URL + '/auctionpackages/export'

  var filtersString = ''
  if (params.ids?.length) {
    filtersString = params.ids.map(x => 'id eq ' + x).join(' or ')

    filtersString = '$filter=' + filtersString
  } else {
    filtersString = auctionPackageService.buildApiFiltersString(params.filters, true)
  }

  if (filtersString) { endpoint += '?' + filtersString }

  return axios.get(endpoint, config).then(response => response.data)
}

function getToken (auctionPackageId) {
  return axios.get(`${VUE_APP_CAMPSITE_API_URL}/auctionpackages/${auctionPackageId}/tokens`).then(response => response.data)
}

function generateToken (auctionPackageId) {
  return axios.post(`${VUE_APP_CAMPSITE_API_URL}/auctionpackages/${auctionPackageId}/tokens`).then(response => response.data)
}
