<template lang="pug">
  v-footer.hidden-sm-and-down(height='auto' style='background-color:#f8f9fa;')
    .d-flex.my-4.align-center
      img.mr-4(src='@/assets/logo-mobile.svg' width='auto' height='33')
      div(text-center text-md-left)
        a.mx-2.text-caption.info--text(href='https://broadsign.com/programmatic-privacy-policy/' target='_blank')
          | Platform Policy
        a.mx-2.text-caption.info--text(href='https://broadsign.com/privacy-policy/' target='_blank')
          | Privacy Policy
        span.mx-2.text-caption
          router-link.info--text(to='/terms-conditions' target='_blank') Terms
        .copyright.mx-2.text-caption(v-if='componentConfig.branding.isCopyrightShown')
          | {{ "© Campsite Project Inc. " + year }}
</template>
<script>
import componentConfigService from '@/services/componentConfig'

export default {
  name: 'footerApp',
  computed: {
    year () {
      const date = new Date()
      return date.getFullYear()
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  }
}
</script>
