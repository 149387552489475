<template lang="pug">
  v-snackbar(
    :value='multiTabEvent'
    color='secondary'
    :timeout='-1'
    top center
    )
    | Changes have been detected. Please refresh to stay up to date.
    v-btn.ml-2.refresh-btn(text @click='refresh') refresh
</template>

<script>
export default {
  props: ['multiTabEvent'],
  methods: {
    refresh () {
      if (this.multiTabEvent.redirect) {
        this.$router.push({ name: this.multiTabEvent.redirect })
      }
      window.location.reload()
    }
  }
}
</script>
