<template lang="pug">
div
  v-card.pt-3(color="transparent" flat)
    vue-headful(:title="componentConfig.branding.title((canSeeNewUi ? 'Packages' : 'Audiences') + ' Overview')")
    v-toolbar#package-title.elevation-0(color="transparent")
      .text-h5.hidden-sm-and-down.text-capitalize {{ packageLabel }}s

      v-text-field#package-find-field(:label='"Find " + (canSeeNewUi ? "Packages" : "Audiences")' prepend-inner-icon='mdi-magnify' solo hide-details v-model='search' clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")
      v-btn#new-package-btn.mt-0.hidden-sm-and-down(large rounded color='primary' router-link :to='"/" + packageLabel + "s/add"')
        v-icon(left) mdi-plus
        span New {{ packageLabel }}

      v-tabs#packages-tabs(background-color='transparent' slider-color='primary' slot='extension' v-model='currentTab')
        v-tab(ripple color='primary lighten-4')
          | All
        //- v-tab(ripple)
          | Archived
    v-divider
    v-scroll-y-reverse-transition
      v-toolbar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selected.length < shownData.length') {{ selected.length }} {{ packageLabel }}s selected
          v-toolbar-title.text-subtitle-1(v-if='!exportAll && selected.length === shownData.length && shownData.length < totalItems') The {{ shownData.length }} {{ packageLabel }}s on this page are selected.
          v-toolbar-title.text-subtitle-1(v-if='exportAll || selected.length === totalItems') All {{ totalItems }} {{ packageLabel }}s are selected.

        span.hidden-md-and-up
          v-chip.ma-1(v-if='shownData.length < totalItems && !exportAll' outlined color='white' small)
            | {{ selected.length }}
          v-chip.ma-1(v-if='selected.length === totalItems || exportAll' dark color='white' class="secondary--text" small)
            | {{ totalItems }}

        v-btn.ml-2(text outlined small dark v-if='!exportAll && selected.length === shownData.length && shownData.length < totalItems' @click='exportAll = true') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ totalItems }}&nbsp;
            | {{ packageLabel }}s

        v-toolbar-items.ml-3
          v-btn.mx-2(:icon="$vuetify.breakpoint.smAndDown" :text="$vuetify.breakpoint.mdAndUp" @click="exportCsv()")
            v-icon(:small="$vuetify.breakpoint.mdAndUp") mdi-download
            span.ml-2.hidden-sm-and-down Export CSV

        v-spacer
        v-btn.ml-3(icon @click="selected = []")
            v-icon mdi-close

    v-data-table.cs-data-table(
      :loading='tableLoading'
      :headers='headers'
      :items='shownData'
      :server-items-length='totalItems'
      :options.sync='pagination'
      v-model='selected'
      show-select
      :dense="$vuetify.breakpoint.xsOnly"
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      :no-data-text='noDataText'
      :footer-props="{itemsPerPageOptions: [50, 100, 250]}"
    )

      template.text-left(v-slot:item="{ item, isSelected, select }" v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-layout.my-2
            v-flex.text-left.d-flex(xs8)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              router-link.info--text.text-left(:to='"/" + packageLabel + "s/" + item.id')
                span.text-body-1(style='word-break: break-word;') {{ item.name }}
            v-flex.text-right(xs4)
              .text-caption.grey--text
                | {{ item.updatedOn | date('MMM Do, YYYY') }}
          .my-2
            v-icon.text--secondary(medium) mdi-account-multiple
            |  {{ item.reachAvailability | numberWithCommas }}
            v-icon.text--secondary.ml-3(medium) mdi-map-marker
            | {{ item.numberOfVenues | numberWithCommas }}
            v-icon.text--secondary.ml-3.mr-1(medium) mdi-television
            | {{ item.numberOfFaces | numberWithCommas }}

      template(v-slot:item.currency="{ item }")
        .d-flex.align-center
          span.package-organization  {{ organizationLabel(item) | capitalize }}

      template(v-slot:item.ownerEmail="{ item }")
        .d-flex.align-center
          span.package-owner-email  {{ item.ownerEmail }}

      template(v-slot:item.name="{ item }")
        router-link.package-name.info--text.text-left(width='300px' :to='"/" + packageLabel + "s/" + item.id') {{ item.name }}

      template(v-slot:item.reachAvailability="{ item }")
        .d-flex.align-center
          v-icon.text--secondary.mr-1(small) mdi-account-multiple
          span.package-reach {{ item.reachAvailability | numberWithCommas }}

      template(v-slot:item.numberOfVenues="{ item }")
        .d-flex.align-center
          v-icon.text--secondary(small) mdi-map-marker
          span.package-number-venues {{ item.numberOfVenues | numberWithCommas }}

      template(v-slot:item.numberOfFaces="{ item }")
        .d-flex.align-center
          v-icon.text--secondary.mr-1(small) mdi-television
          span.package-number-faces {{ item.numberOfFaces | numberWithCommas }}

      template(v-slot:item.updatedOn="{ item }")
        span.package-date {{ item.updatedOn | date('MMM Do, YYYY') }}
    v-divider
  v-btn.hidden-md-and-up(medium fab color='primary' router-link :to='"/" + packageLabel + "s/add"' fixed bottom right v-show='!selected.length')
    v-icon(dark large) mdi-plus
</template>

<script>
import _ from 'lodash'
import audienceApi from '../services/audience.api'
import csv from '../services/csv.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import componentConfigService from '@/services/componentConfig'

export default {
  created: function () {
    this.getAudiences()
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
    if (this.canSeeNewUi) {
      this.packageLabel = 'package'
      this.headers = this.headers.map(obj =>
        obj.value === 'name' ? { ...obj, text: 'Package' } : obj
      )
    }
    if (!this.can.read.orgInfo) {
      this.headers = this.headers.filter(obj => obj.value !== 'currency')
    }
  },
  computed: {
    can () {
      return this.$store.getters['user/permissions']('audience')
    },
    shownData () {
      if (this.currentTab === 1) { return this.audiences.filter(x => x.status === 'archived') }
      return this.audiences
    },
    organizationId () {
      return this.$store.getters['user/getOrganization'].id
    },
    tableLoading () {
      return !this.organizationId || this.fetchingAudiences
    },
    noDataText () {
      if (!this.audiences.length && this.search.length) {
        return 'No ' + this.packageLabel + ' matches your search'
      } else {
        return 'No ' + this.packageLabel + ' found'
      }
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  data () {
    return {
      pagination: {
        sortBy: ['updatedOn'],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },
      headers: [
        { text: 'Organization', value: 'currency' },
        { text: 'Created By', value: 'ownerEmail' },
        { text: 'Audience', value: 'name' },
        { text: 'Impressions', value: 'reachAvailability' },
        { text: 'Venues', value: 'numberOfVenues' },
        { text: 'Screens', value: 'numberOfFaces' },
        { text: 'Last Updated', value: 'updatedOn' }
      ],
      audiences: [],
      selected: [],
      search: '',
      lastSearch: '',
      currentTab: 0,
      totalItems: 0,
      fetchingAudiences: true,
      exportAll: false,
      packageLabel: 'audience'
    }
  },
  watch: {
    search: _.debounce(function () {
      this.getAudiences()
    }, 450),
    selected: function (newVal, oldVal) {
      // console.log('selected changed', oldVal.length, '=>', newVal.length)
      if (newVal.length !== this.shownData.length) {
        this.exportAll = false
      }
    },
    pagination: function (newVal, oldVal) {
      if (this.organizationId) {
        this.getAudiences()
      }
    }
  },
  methods: {
    organizationLabel (item) {
      const exchange = defaultExchangeValues.getDefaultValuesByCurrency(item.currency)?.countryCode
      return exchange ? exchange + ' - ' + item.organizationName : item.organizationName
    },
    getAudiences () {
      this.fetchingAudiences = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination
      // console.log(sortBy, sortDesc, page, itemsPerPage, totalItems)

      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'
      // console.log('page', page, '[' + skip.toString() + ', ' + take.toString() + ']', sortBy, sort, this.search)

      // Filters
      var filters = []

      if (this.search) {
        filters.push({ name: 'search', value: this.search })
      }

      // get total count only if NEW search
      if (!this.totalItems || this.lastSearch !== this.search) {
        this.lastSearch = this.search

        audienceApi.getAudiencesCount(filters)
          .then(count => {
            this.totalItems = count
          })
      }

      audienceApi.getAudiences(skip, take, sortBy[0], sort, filters, true)
        .then(audiences => {
          this.fetchingAudiences = false
          this.audiences = audiences

          if (this.exportAll) {
            this.selected = audiences
          }
        })
    },
    duplicateAudiences () {
      // BE API endpoint missing
    },
    exportCsv () {
      const formattedList = this.selected.map(x => {
        return {
          [this.canSeeNewUi ? 'Package' : 'Audience']: x.name,
          Impressions: x.reachAvailability,
          Venues: x.numberOfVenues,
          Screens: x.numberOfFaces,
          'Last Updated': this.$options.filters.date(x.updatedOn, 'MMM Do, YYYY')
        }
      })
      csv.csvExport(formattedList, this.packageLabel + 's')
    }
  }
}
</script>
