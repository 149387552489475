<template lang="pug">
  v-expansion-panels
    v-expansion-panel
      v-expansion-panel-header.px-0
        .primary--text See user roles and their permissions
      v-expansion-panel-content.px-0
        v-data-table(:headers='headers' :items='permissions' hide-default-footer :hide-default-header='$vuetify.breakpoint.xsOnly')
          template(v-slot:item.role='{item}')
            .font-weight-bold {{ item.role }}
          template(v-slot:item.owner='{item}')
            .text-caption {{ item.owner }}
          template(v-slot:item.administrator='{item}')
            .text-caption {{ item.administrator }}
          template(v-slot:item.manager='{item}')
            .text-caption {{ item.manager }}
          template(v-slot:item.reporter='{item}')
            .text-caption {{ item.reporter }}
</template>

<script>
export default {
  data () {
    return {
      headers: [
        { text: '', sortable: false, value: 'role' },
        { text: 'Owner', sortable: false, value: 'owner' },
        { text: 'Administrator', sortable: false, value: 'administrator' },
        { text: 'Manager', sortable: false, value: 'manager' },
        { text: 'Reporter', sortable: false, value: 'reporter' }
      ]
    }
  },
  computed: {
    permissions () {
      const full = ['Create', 'Modify', 'View'].join(' / ')
      const view = 'View'

      return ['Audience', 'Campaign', 'Creative', 'User', 'Billing'].map(ressource => {
        return {
          role: ressource,
          owner: full,
          administrator: full,
          manager: ['Campaign', 'Audience', 'Creative'].includes(ressource) ? full : view,
          reporter: ['Audience'].includes(ressource) ? full : view
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  >>> .v-expansion-panel::before {
    box-shadow: none;
  }

  >>> .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }
</style>
