<template lang="pug">
  v-dialog(max-width='850px' v-model="isDialogOpen")
    v-card
      v-card-title.text-h5.share-link-title Share {{ label }}
      v-card-text.share-link-subtitle
        | {{shareLinkSubtitleText}}
        v-text-field#sharebleLink(
          :value="conditionalShareLink"
          append-outer-icon='mdi-content-copy'
          @click:append-outer="copySharebleLink"
          :loading="generatingToken"
          readonly
          )
      v-card-actions
        v-spacer
        v-btn.close-share-dialog-btn(text color='primary' @click="isDialogOpen = false") Close

</template>
<script>

import tracking from '@/services/tracking'

export default {
  props: ['parentComponent', 'label', 'isForAdServer', 'shareDialogOpen'],
  data () {
    return {
      isDialogOpen: false
    }
  },
  watch: {
    isDialogOpen () {
      this.$emit('dialogOpenChanged', this.isDialogOpen)
    },
    shareDialogOpen () {
      this.isDialogOpen = this.shareDialogOpen
    }
  },
  computed: {
    conditionalShareLink () {
      return this.$flags.canSeeUiRebrand.isEnabled()
        ? this.shareLink.replace('/proposals', '/plans')
        : this.shareLink
    },
    shareLinkSubtitleText () {
      let subtitle = `Anyone with the link can view the ${this.label.toLowerCase()}, `
      subtitle += this.isForAdServer ? 'even if they do not use or have access to the Broadsign Platform.' : 'even without a Broadsign Ads account.'
      return subtitle
    },
    shareLink () {
      return this.$store.getters[this.parentComponent + '/shareLink']
    },
    generatingToken () {
      return this.$store.getters[this.parentComponent + '/getLoadingToken']
    }
  },
  methods: {
    copySharebleLink () {
      const copyText = document.getElementById('sharebleLink')

      copyText.select()
      copyText.setSelectionRange(0, 99999) // For mobile devices
      document.execCommand('copy')
      this.$store.commit('snackbar/setSnackbar', {
        type: 'success',
        msg: 'Share link successfully copied'
      })
      tracking.sendEvent(['ga'], 'copiedShareProposalLink')
    }
  }
}
</script>

<style scoped>
</style>
