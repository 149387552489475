<template lang="pug">
div
  .subtitle.pl-0.pt-1.pb-3
    .text-body-2 Sync the line delivery with financial market conditions

  finance-item(
    v-for='itemType in itemTypes'
    :key='itemType'
    :id='itemType'
    :item='itemObj(itemType)'
    :authToken='authToken'
    :financeType='itemType'
    @onEvent='onEvent'
    )
</template>
<script>
import financeItem from '@/web-components/moments/financeItem.vue'
import services from './services'

export default {
  components: {
    financeItem
  },
  props: {
    finance: {
      type: Object
    },
    authToken: {
      type: String
    }
  },
  data () {
    return {
      itemTypes: services.financeTypes,
      modifiedFinance: {}
    }
  },
  mounted () {
    this.itemTypes.map(t => {
      this.modifiedFinance[t] = null
    })
  },
  methods: {
    itemObj (itemType) {
      return this.finance && this.finance[itemType]
    },
    onEvent (event) {
      if (event && event.action === 'dataChanged') {
        this.modifiedFinance[event.subCategory] = event.value

        if (Object.values(this.modifiedFinance).every(x => x === null)) {
          this.modifiedFinance = null
        }

        this.$emit('onEvent', {
          category: 'finance',
          subCategory: event.subCategory,
          action: 'dataChanged',
          value: this.modifiedFinance
        })
      } else {
        this.$emit('onEvent', event)
      }
    }
  }
}
</script>
