<template lang="pug">
v-menu(
  v-model='isFilterMenuOpen'
  :close-on-content-click='false'
  transition='scale-transition'
  min-width=380
  max-width=880
  )
  template(v-slot:activator="{on, attrs}")
    .d-inline-block.slot-opener-wrapper(v-on='on')
      slot(name="opener")

  v-window.filter-set-window(v-model='step' style='background-color: white;')
    v-window-item.pick-filter-step
      v-card
        v-text-field.filter-search-treeview.py-2.mt-0(
          v-model='searchFilters'
          placeholder='Search filters'
          hide-details
          clearable
        )
          template(v-slot:prepend-inner='')
            v-icon.ml-3() mdi-magnify

        .treeview-container.overflowed
          v-treeview.mt-2(
            :items='filterOptions'
            :open='openItems'
            :search='searchFilters'
            selected-color='primary'
            selection-type='leaf'
            v-model='selection'
            return-object
            hoverable
            active-class='treeview-item-selected'
            ref='treeviewRef'
            expand-icon=''
          )
            template(v-slot:label='{ item, selected }')
              div(
                style='cursor: pointer;'
                :class='getCssClasses(item.name, selected)'
                @click='pickFilter(item)'
                ) {{ item.name }}

    v-window-item.pick-operator-and-value
      v-card
        v-card-title
          v-btn.mr-2.back-to-step1-btn(v-if='!isEdit' icon @click='goBackToColumnSelection')
            v-icon mdi-chevron-left
          span.filter-label {{ pickedColumn ? pickedColumn.name : '' }}
        v-card-text
          v-select.operators(label='Operator' :items="availableOperators()" v-model='pickedOperator')
          v-text-field#filter-value(v-if="valueType === 'text' && pickedOperator != 'has any value'" label='Value' v-model='pickedValue')
          v-combobox#filter-search(
            @focus="onSearchFocus"
            v-if="valueType === 'autocomplete' || valueType === 'combobox' || valueType === 'multiSelect'"
            v-model="pickedFetchedValues"
            :items="valueType === 'multiSelect' ? pickedColumn.options : possibleValues"
            :loading="fetchingValues"
            :search-input.sync="valueType === 'multiSelect' ? null : search"
            :item-text="valueType === 'combobox' ? 'externalId' : 'label'"
            label="Value"
            placeholder="Start typing to Search"
            color="primary"
            deletable-chips
            multiple
            chips
            clearable
            return-object
            :menu-props="{ maxWidth: '75%' }"
          )
        v-card-actions
          v-spacer
          v-btn.add-btn(color='primary' text @click='setFilter' :disabled='!canSetFilter') {{ isEdit ? 'apply' : 'add' }}
</template>

<script>
import _ from 'lodash'
import treeSelectionService from '@/services/treeSelection.service'

export default {
  props: {
    filterOptions: {
      type: Array,
      default: () => []
    },
    filterToEdit: {
      type: Object,
      default: () => null
    },
    searchMethod: {
      type: Function,
      default: () => []
    }
  },
  data () {
    return {
      isFilterMenuOpen: false,
      step: 0,
      operators: ['contains', 'does not contain', 'is any of', 'is none of', 'has any value'],
      searchFilters: '',
      selection: [],

      pickedColumn: null,
      pickedOperator: 'is any of',
      pickedValue: null,

      search: null,
      possibleValues: [],
      fetchingValues: false,
      pickedFetchedValues: null
    }
  },
  computed: {
    isEdit () {
      return !!this.filterToEdit
    },
    openItems () {
      return this.filterOptions.map(item => item)
    },
    valueType () {
      if (['is any of', 'is none of'].includes(this.pickedOperator)) {
        if (this.pickedColumn?.options) return 'multiSelect'
        return this.pickedColumn?.canAddCustom ? 'combobox' : 'autocomplete'
      } else return 'text'
    },
    canSetFilter () {
      if (this.pickedOperator === 'has any value') {
        return true
      }
      if (this.pickedColumn) {
        if (this.valueType === 'text') return this.pickedOperator && this.pickedValue
        if (['autocomplete', 'combobox', 'multiSelect'].includes(this.valueType)) return this.pickedOperator && this.pickedFetchedValues && this.pickedFetchedValues.length
        else return false
      } else return false
    }
  },
  created () {
    if (this.isEdit) {
      this.pickedColumn = this.filterToEdit.column
      this.pickedOperator = this.filterToEdit.operator
      if (this.valueType !== 'text') this.pickedFetchedValues = this.filterToEdit.value
      else this.pickedValue = this.filterToEdit.value
      this.step = 1
    }
  },
  watch: {
    search: _.debounce(function (val) {
      this.getSearchResults(val)
    }, 400),
    pickedFetchedValues () {
      if (this.valueType === 'autocomplete') {
        this.search = null
      }
    }
  },
  methods: {
    pickFilter (column) {
      if (!column.children || !column.children.length) {
        this.pickedColumn = { ...column }
        this.step = 1
      }
    },
    availableOperators () {
      if (this.pickedColumn?.operators) {
        const operators = this.pickedColumn.operators.filter(o => this.operators.includes(o))
        if (!operators.includes(this.pickedOperator)) this.pickedOperator = operators[0]
        return operators
      } else {
        return this.operators.filter(o => !['contains', 'does not contain'].includes(o))
      }
    },
    goBackToColumnSelection () {
      this.step = 0
      this.pickedColumn = null
      this.pickedValue = null
      this.search = ''
      this.possibleValues = []
      this.fetchingValues = false
      this.pickedFetchedValues = []
    },
    getValue () {
      if (this.pickedOperator === 'has any value') return ''

      switch (this.valueType) {
        case 'text':
          return this.pickedValue
        case 'autocomplete':
          return this.pickedFetchedValues.filter(v => typeof v === 'object').length ? this.pickedFetchedValues.filter(v => typeof v === 'object') : this.pickedFetchedValues
        case 'combobox':
          return this.pickedFetchedValues.map(item => {
            return typeof item === 'string' ? { externalId: item, name: item } : item
          })
        case 'multiSelect':
          return this.pickedFetchedValues
      }

      return null
    },
    setFilter () {
      const action = `${this.isEdit ? 'updated' : 'added'}Filter`

      const value = this.getValue()
      if (value || this.pickedOperator === 'has any value') {
        this.$emit(action, {
          column: this.pickedColumn,
          operator: this.pickedOperator,
          value
        })
      }
      if (!this.isEdit) this.goBackToColumnSelection()
      this.isFilterMenuOpen = false
    },
    getCssClasses (itemName, isSelected) {
      return treeSelectionService.buildTreeItemCssClasses(itemName, isSelected, 'filter')
    },
    onSearchFocus () {
      this.getSearchResults(this.search)
    },
    getSearchResults (searchValue) {
      if (this.pickedColumn) {
        this.fetchingValues = true
        this.searchMethod(this.pickedColumn.id, searchValue ?? '')
          .then(res => {
            this.possibleValues = res
          })
          .finally(() => {
            this.fetchingValues = false
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-treeview-node__root{
  min-height: 34px;
}
>>> .v-list-item{
  height:34px;
  min-height: 34px;
}
>>> .v-list-item__action {
  margin-right: 12px !important;
}
.overflowed {
  height:500px;
  overflow-y: auto;
}
.handle {
  cursor: move;
}
.selection-item {
  background-color: white;
}
</style>
