<template lang="pug">
  v-expansion-panel#inventory-source-panel
    v-expansion-panel-header
      template(v-slot:default='{ open }')
        v-row.my-0(no-gutters align='center')
          v-col.text-h6#panel-title(cols='4' style='white-space: nowrap;') {{ 'Inventory Source' }}
          v-col.text--secondary(cols='8')
            div
              v-scroll-y-reverse-transition.d-block(leave-absolute)
                div(v-if='open' key='0') &nbsp;
                div(v-else key='1') {{ deals.length && !isOnAuctionPackagePage ? deals.length + ' Deals set' : 'No deal set - Optional' }}
    v-expansion-panel-content(eager)
      marketSection.mb-1.px-6(v-if='canSeeAuctionPackageFields' @storeUpdated='$emit("updateForecast")' :market='initMarket' :parent='parent')
      currencySection.mb-2.px-6(v-if='canSeeAuctionPackageFields && canSeeAuctionPackageCurrencySelection && !loadingLine' @storeUpdated='$emit("updateForecast")')
      publicExchangeToggle.mb-2.px-6(v-if='canSeeAuctionPackageFields' :initState='initUsePublicExchange'
        @toggled='publicExchangeToggled')
      inventory-deals-new(
        :deals='deals'
        :deals-loading='loadingLine'
        :parentOrganizationId='organizationId'
        @dealAdded='dealAdded'
        @dealRemoved='dealRemoved')

</template>

<script>

import inventoryDealsNew from '@/components/inventoryDealsNew.vue'
import marketSection from '@/components/auctionPackageMarketForm.vue'
import currencySection from '@/components/auctionPackageCurrencyForm.vue'
import publicExchangeToggle from '@/components/auctionPackagePublicExchangeToggle.vue'

export default {
  components: {
    inventoryDealsNew,
    marketSection,
    publicExchangeToggle,
    currencySection
  },
  props: {
    loadingLine: {
      type: Boolean,
      default: () => false
    },
    parentOrganizationId: {
      type: Number,
      default: () => undefined
    },
    isOnAuctionPackagePage: {
      type: Boolean,
      default: () => false
    },
    initUsePublicExchange: {
      type: Boolean,
      default: () => true
    },
    initMarket: {
      type: String,
      default: () => 'united-state'
    },
    instance: {
      type: String,
      default: () => 'united-state'
    },
    canSeeDspPartnerFields: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    deals () {
      return this.$store.getters['createCampaign/deals']
    },
    parent () {
      return this.isOnAuctionPackagePage ? 'auctionPackage' : 'createCampaign'
    },
    canSeeAuctionPackageFields () {
      return this.isOnAuctionPackagePage || this.canSeeDspPartnerFields
    },
    organizationId () {
      return this.parentOrganizationId || this.$store.getters['user/getProfile'].organizationId
    },
    canSeeAuctionPackageCurrencySelection () {
      return this.$flags.canSeeAuctionPackageCurrencySelection.isEnabled()
    }
  },
  methods: {
    dealAdded (deal) {
      this.$store.commit('createCampaign/addDeal', deal)
      this.$emit('updateForecast')
    },
    dealRemoved (deal) {
      this.$store.commit('createCampaign/removeDeal', deal)
      this.$emit('updateForecast')
    },
    publicExchangeToggled () {
      this.$emit('updateForecast')
    }
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-expansion-panel-content__wrap
  padding-left: 0px
  padding-right: 0px
  padding-bottom: 0px
</style>
