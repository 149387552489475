<template lang="pug">
div(v-if="availableItems.length")
  div(v-if="!isTargetOpen()")
    v-btn.ma-0.d-block(text color='primary' @click='isManuallyOpened = true')
      v-icon(left) mdi-plus
      | {{ info.label }}
  v-card.d-block.mb-3(color='grey lighten-4' v-else)
    v-card-title.pt-2 {{ info.label }}
      v-spacer
      v-btn(icon small @click="closeTarget()")
        v-icon(color='grey') mdi-close
    div.px-4.pt-0
      v-autocomplete.pt-1(
        multiple small-chips deletable-chips single-line
        :label="info.placeholder"
        item-text='name'
        item-value='value'
        :items="availableItems"
        :value="selectedItems"
        @input="updateTargeting"
        )
      .text-caption.grey--text.pb-3(v-if="info.ref === 'Publishers'" style='line-height: 1.25em;')
        | Some publishers transact only in a private marketplace. If the publisher inventory you are searching for is unavailable, send us a message via the badge at the bottom of your screen.

</template>

<script>
import audienceService from '@/services/audience.service'
import tracking from '@/services/tracking'

export default {
  props: ['targetRef'],
  data () {
    return {
      isManuallyOpened: false,
      targetsMetadata: [
        {
          ref: 'Publishers',
          listGetter: 'publishersList',
          gaEvent: 'Publisher',
          label: 'Publishers',
          placeholder: 'Select one or many publishers'
        },
        {
          ref: 'MeasurementPartners',
          listGetter: 'measurementPartnersList',
          gaEvent: 'MeasurementPartner',
          label: 'Measurement Partners',
          placeholder: 'Select one or many measurement partners'
        }
      ]
    }
  },
  computed: {
    info () {
      return this.targetsMetadata.find(x => x.ref === this.targetRef)
    },
    availableItems () {
      return this.$store.getters['audience/' + this.info.listGetter]
    },
    selectedItems () {
      return this.$store.getters['audience/selected' + this.info.ref]
    }
  },
  watch: {
    selectedItems (newV, oldV) {
      const diffs = audienceService.getAddedAndRemovedItemsOfList(oldV, newV)
      for (const checked of audienceService.getTargetValueLabels(diffs.checked, this.availableItems)) {
        tracking.sendEvent(['ga'], `added${this.info.gaEvent}`, { label: checked })
      }
      for (const unchecked of audienceService.getTargetValueLabels(diffs.unchecked, this.availableItems)) {
        tracking.sendEvent(['ga'], `removed${this.info.gaEvent}`, { label: unchecked })
      }
    }
  },
  mounted () {
    if (this.selectedItems.length) this.isManuallyOpened = true
  },
  methods: {
    isTargetOpen () {
      return this.$store.getters[`audience/selected${this.targetRef}`].length || this.isManuallyOpened
    },
    updateTargeting (vals) {
      const obj = audienceService.convertToApiFormatScreenTarget(this.targetRef, vals)
      if (obj) this.$store.commit('audience/updateTargetingTarget', obj)
      this.$emit('targetChanged')
    },
    closeTarget () {
      this.updateTargeting([])
      this.isManuallyOpened = false
    }
  }
}
</script>
