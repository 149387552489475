<template lang="pug">
div
  .loading-section(v-if='isLoading')
    v-row.my-0.pt-0(align='start' style="min-height:160px;" :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
      v-col.pt-0.pb-0(cols='12' lg='7')
        v-skeleton-loader.mt-2(type='text' width='20%')
        v-skeleton-loader.mt-1.mb-3(type='image' height=42 width='80%')
        v-skeleton-loader.mt-2(type='text' width='40%')
        //-v-skeleton-loader.mb-1(type='divider' )
        v-skeleton-loader.mt-2.mb-1.d-flex.mr-2(type='chip@3' )
        //-v-skeleton-loader.mb-1(type='divider' )
      v-col.pt-2.pb-0(cols='12' lg='5' )
  .active-section(v-if='!isLoading')
    v-row.my-0.py-2(align='center' no-gutters :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
      v-col(cols='12' )
        .text-overline.d-block(style='height:20px;') {{ instanceData.owner.organizationName }}
        .text-h4.text-truncate {{ instanceData.name }}
        .text-caption.text--secondary
          | {{ campaignDuration }}

        v-toolbar.my-2.pl-0(flat dense color='transparent' style='border-top: 1px solid rgba(0,0,0,.12) !important; border-bottom: 1px solid rgba(0,0,0,.12) !important;')
          v-switch.xs-small.py-0.my-0.ml-0(:input-value="isInstanceStatusActive" hide-details color='primary' :disabled="isLoading" @change="updateStatus()")
          v-chip.text-uppercase.mr-4(:color="statusColor" small outlined)
            v-icon.mr-1.ml-0(:color="statusColor"  small) mdi-circle
            small.text--primary(style='white-space: nowrap;') {{ effectiveStatusLabel }}
          editCampaignDialog(
            @edited="saveCampaign"
            :campaign='instanceData'
            )
            template(v-slot:customActivator='')
              v-btn(icon @click="$root.$emit('openEditCampaignDialog', 'editCampaignName')")
                v-icon mdi-pencil

          v-menu(offset-y :close-on-content-click="false" v-model="actionsMenuOpen")
            template(v-slot:activator='{ on }')
              v-btn.other-menu-opener-btn(icon v-on='on' @click.stop='')
                v-icon mdi-dots-vertical
            v-list(dense)
              confirmationDialog(
                v-if="canRevertToProposal"
                @confirmed="confirmAction('revert')"
                :details="{...plansText, btnLabel: 'Revert campaign'}"
                )
                template(v-slot:customActivator='')
                  v-list-item#restore-btn(@click="$root.$emit('openConfirmDialog', plansText.title)")
                    v-list-item-icon.mr-4
                      v-icon mdi-restore
                    v-list-item-title {{ plansText.title }}
              confirmationDialog#duplicate-dialog(
                @confirmed="confirmAction('duplicate', ...arguments)"
                :details="{title: 'Duplicate Campaign', msg: 'Are you sure you want to duplicate this campaign?', btnLabel: 'Duplicate campaign'}"
                )
                template(v-slot:customActivator='')
                  v-list-item#duplicate-btn(@click="$root.$emit('openConfirmDialog', 'Duplicate Campaign')")
                    v-list-item-icon.mr-4
                      v-icon mdi-content-copy
                    v-list-item-title Duplicate
                template(v-slot:customOptions='')
                  v-checkbox.creatives-checkbox(v-model="keepCreativesOnDuplicate" label="Keep assigned creatives?" color="primary")
              v-divider(v-if="instanceData && instanceData.status !== 'Archived'")
              confirmationDialog(
                v-if="instanceData && instanceData.status !== 'Archived'"
                @confirmed="confirmAction('archive')"
                :details="{title: 'Archive Campaign', msg: 'Are you sure you want to archive this campaign?', btnLabel: 'Archive campaign'}"
                )
                template(v-slot:customActivator='')
                  v-list-item#archive-btn(@click="$root.$emit('openConfirmDialog', 'Archive Campaign')")
                    v-list-item-icon.mr-4
                      v-icon mdi-delete
                    v-list-item-title Archive

</template>

<style scoped>
>>> .v-toolbar__content {
  padding-left: 0px;
}
>>> .v-skeleton-loader__chip {
  margin-right: 4px;
}

</style>

<script>
import helpersService from '@/services/helpers.service'
import confirmationDialog from '@/components/confirmationDialog.vue'
import editCampaignDialog from '@/components/editCampaignDialog.vue'

export default {
  components: {
    confirmationDialog,
    editCampaignDialog
  },
  props: ['instanceData', 'isLoading'],
  created: function () {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.isPanelExpanded = 0
    }
  },
  computed: {
    isInstanceStatusActive () {
      return !!(this.instanceData && this.instanceData.status === 'Active')
    },
    statusColor () {
      switch (this.instanceData.effectiveStatus) {
        // GREEN
        case 'Active':
        case 'Delivering':
        case 'ReadyToDeliver':
          return 'success'
        // ORANGE
        case 'PendingReview':
          return 'warning'
        // RED
        case 'MissingCreative':
        case 'OrganizationSuspended':
          return 'error'
        // GREY
        case 'Paused':
        case 'Archived':
        case 'OrganizationInactive':
          return 'grey'
        default :
          return 'grey'
      }
    },
    effectiveStatusLabel () {
      switch (this.instanceData.effectiveStatus) {
        case 'OrganizationInactive':
          return 'Account Inactive'
        case 'OrganizationSuspended':
          return 'Account Suspended'
        case 'MissingCreative':
          return 'Missing Creatives'
        default:
          return this.instanceData.effectiveStatus
      }
    },
    canRevertToProposal () {
      return this.instanceData &&
      ['Upcoming', 'Ongoing'].includes(this.instanceData.timeStatus) &&
      this.instanceData.status !== 'Proposal' &&
      this.instanceData.spent === 0
    },
    switchDisabled () {
      return this.isLoading || (this.instanceData && this.instanceData.status === 'Archived')
    },
    campaignDuration () {
      return `From ${helpersService.dataDisplayFormatting(this.instanceData.startDate, 'date')} to ${helpersService.dataDisplayFormatting(this.instanceData.endDate, 'date')}`
    },
    plansText () {
      const label = this.$flags.canSeeUiRebrand.isEnabled() ? 'Plan' : 'Proposal'
      return {
        title: `Revert to ${label}`,
        msg: `Are you sure you want to revert this campaign to a ${label.toLowerCase()}?`
      }
    }

  },
  data () {
    return {
      isPanelExpanded: [],
      actionsMenuOpen: false,
      keepCreativesOnDuplicate: true
    }
  },
  methods: {
    panelExpandCtrl () {
      this.isPanelExpanded === 0 ? this.isPanelExpanded = null : this.isPanelExpanded = 0
    },

    updateStatus () {
      this.$emit('campaignUpdated', { status: this.isInstanceStatusActive ? 'Paused' : 'Active' })
    },

    saveCampaign (newCampaign) {
      this.$emit('campaignUpdated', newCampaign)
      this.actionsMenuOpen = false
    },

    confirmAction (action, params) {
      if (action === 'duplicate') {
        params = { keepCreatives: this.keepCreativesOnDuplicate }
      }

      this.$emit(action + 'Campaign', params)
      this.actionsMenuOpen = false
    }
  }
}
</script>
