<template lang='pug'>
  v-card.my-6(:loading='pageLoading')
    v-card-title.text-h5 Fees

    v-form(ref='feesForm' v-model='formValid')
      v-simple-table
        thead
          tr
            th(style='width:210px;') Fee
            th.text-right(style='width:155px;') Value
            th Description
        tbody
          tr.fee-transaction(v-if='canEditFees')
            td.text-body-1.py-6
              strong Transaction Cost
            td
              v-text-field#fee-transaction-input(
                dense
                filled
                hide-details
                rounded
                single-line
                suffix='%'
                type='number'
                step='1'
                min='0'
                max='50'
                placeholder='0.00'
                :rules='[rules.required]'
                v-model.number='feeTransaction'
                @blur='truncateDigits("feeTransaction")')
            td.text-caption.py-2 A standard fee is applied directly on the transacted media value to cover Broadsign Ads operational cost.

          tr.fee-bidding
            td.text-body-1.py-6
              strong Bidding Fees
            td.text-right
              v-text-field#fee-bidding-input(
                v-if='canEditFees'
                dense
                filled
                hide-details
                rounded
                single-line
                suffix='%'
                type='number'
                step='1'
                min='0'
                max='50'
                placeholder='0.00'
                :rules='[rules.required]'
                v-model.number='feeBidding'
                @blur='truncateDigits("feeBidding")')

              strong.fee-bidding-value(v-if='!canEditFees') {{ feeBidding }}%
            td.text-caption.py-2 The standard or negotiated fee applied to all advertisers.

          tr.fee-managed
            td.text-body-1
              strong Managed Service Fees
            td.text-right
              v-text-field#fee-managed-input(
                v-if='canEditFees'
                dense
                filled
                hide-details
                rounded
                single-line
                suffix='%'
                type='number'
                step='1'
                min='0'
                max='50'
                placeholder='0.00'
                :rules='[rules.required]'
                v-model.number='feeManaged'
                @blur='truncateDigits("feeManaged")')

              strong.fee-managed-value(v-if='!canEditFees') {{ feeManaged }}%
            td.text-caption.py-2
              strong.mb-2 Managed Service Agreement Signed
              div This fee covers an additional managed service offered by Broadsign Ads team. It includes the account setup as well as the time to plan, execute, manage and report all aspects related to campaigns.

          tr.fees-total(v-if='canEditFees')
            td.text-body-1
              strong Total Service Fees
            td.fees-total-value.text-right
              strong +{{ feesTotal }}%
            td.text-caption.py-2 All fees are compounded

    v-card-actions(v-if='canEditFees')
      v-spacer
      v-btn.save-fees(color='primary' text :disabled='!anyFeesUpdated || !formValid' :loading='saving' @click='save') Save Changes
</template>
<script>
import userApi from '@/services/user.api'

export default {
  props: {
    pageLoading: {
      type: Boolean,
      default: () => false
    },
    organizationId: {
      type: Number,
      default: () => 0
    },
    canEditFees: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      fees: [],
      feeTransaction: 0,
      feeBidding: 0,
      feeManaged: 0,
      saving: false,
      formValid: false,
      rules: {
        required: value => parseFloat(value) >= 0 || 'Required.'
      }
    }
  },
  mounted () {
    this.loadFees()
  },
  computed: {
    compoundedFees () {
      const fees = [parseFloat(this.feeTransaction), parseFloat(this.feeBidding), parseFloat(this.feeManaged)]
      return fees.reduce((sum, fee) => {
        return sum + sum * (fee / 100)
      }, 1)
    },
    feesTotal () {
      return this.percent((this.compoundedFees - 1) * 100)
    },
    feesUpdated () {
      const feesEditable = [
        { key: 'transaction_cost' },
        { key: 'bidding' },
        { key: 'managed_service' }
      ]

      return feesEditable
        .filter(f => {
          const model = this.feeModelName(f)
          const modelValue = this[model]
            ? this[model]
            : 0

          const oldModel = this.fees.find(fee => fee.key === f.key)
          const oldValue = oldModel
            ? this.percent(oldModel.value * 100)
            : 0

          return modelValue !== oldValue
        })
    },
    anyFeesUpdated () {
      return this.feesUpdated.length > 0
    }
  },
  methods: {
    loadFees () {
      this.getOrganizationFees(this.organizationId)
        .then(fees => {
          this.fees = fees
          this.setModels()
        })
    },
    /**
     * @param {String} organizationId
     * @return {Array} list of fee objects
     */
    getOrganizationFees (organizationId) {
      return userApi.getOrganizationFees(organizationId)
    },
    setModels () {
      const feeTransaction = this.fees.find(f => f.key === 'transaction_cost')
      if (feeTransaction) {
        this.feeTransaction = this.percent(feeTransaction.value * 100)
      }

      const feeBidding = this.fees.find(f => f.key === 'bidding')
      if (feeBidding) {
        this.feeBidding = this.percent(feeBidding.value * 100)
      }

      const feeManaged = this.fees.find(f => f.key === 'managed_service')
      if (feeManaged) {
        this.feeManaged = this.percent(feeManaged.value * 100)
      }
    },
    feeModelName (fee) {
      const keyPieces = fee.key.split('_')
      const shortKey = keyPieces[0]
      const capitalizedKey = shortKey.charAt(0).toUpperCase() + shortKey.slice(1)
      return 'fee' + capitalizedKey
    },

    percent (val) {
      return parseFloat(val.toFixed(2))
    },
    truncateDigits (model) {
      this[model] = this.percent(this[model])
    },

    save () {
      this.saving = true

      this.saveFees(this.feesUpdated)
        .then(this.saveSuccess)
        .catch(this.saveError)
        .finally(() => {
          this.saving = false
        })
    },
    saveFees (fees) {
      const feesInDecimals = fees.map(fee => {
        const model = this.feeModelName(fee)
        return {
          key: fee.key,
          value: parseFloat(this[model]) / 100
        }
      })

      return this.setOrganizationFees(feesInDecimals)
    },

    saveSuccess (resp) {
      this.loadFees()
      this.popSnackbar('Fees successfully updated.')
    },
    saveError (err) {
      let msg = 'Cannot update Fee(s)'
      if (err.response && err.response.data && err.response.data.Message) {
        msg = err.response.data.Message
      }
      this.popSnackbar(msg, 'error')
    },
    popSnackbar (msg, type) {
      if (!msg) return
      if (!type) type = 'success'
      this.$store.commit('snackbar/setSnackbar', {
        type,
        msg: `${msg}`
      })
    },

    /**
     * @param {Array} list of fee objects to update with value between [0.0000, 1.0000]
     * @return {empty} PUT requests have no response
     */
    setOrganizationFees (fees) {
      return userApi.setOrganizationFees(this.organizationId, fees)
    }
  }
}
</script>
