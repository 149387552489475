<template lang="pug">
  div
    div(v-if='isLoading' style="min-height:270px;")
      v-row.my-0(no-gutters)
        v-col.pt-0(:class="{'px-4': $vuetify.breakpoint.mdAndUp}")
          v-skeleton-loader.mt-2(type='text' width='50%')
      v-row.my-0.pt-0(align='start' :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
        v-col.pt-0.pb-0(cols='12' lg='7')
          v-skeleton-loader.mt-1.mb-1(type='image' height=40 width='80%')
          v-skeleton-loader.mt-4.mb-1.d-flex.mr-2(type='chip@3' )
          v-row.my-0.pt-2()
            v-col(cols='12' md='4')
              v-skeleton-loader(type='image' height=60)
            v-col(cols='12' md='4')
              v-skeleton-loader(type='image' height=60)
            v-col(cols='12' md='4')
              v-skeleton-loader(type='image' height=60)
        v-col.pt-1.pb-0(cols='12' lg='5' )
          v-skeleton-loader.mb-4(type='image' height=174)
          v-skeleton-loader(type='image' height=45)
    div(v-if='!isLoading')
      v-row.my-0(no-gutters)
        v-col.pt-0(style="min-height:36px;" :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
          breadcrumbs(instance-type='line')
      v-row.my-0.pt-0(align='start' style="min-height:234px;" :class="{'px-4': $vuetify.breakpoint.mdAndUp}")
        v-col.pt-0.pb-0(cols='12' lg='7')
          .text-h4.hidden-sm-and-down.name(:class="{'': $vuetify.breakpoint.mdAndUp}" style="word-break: break-all;") {{ instanceData.name }}
          v-toolbar.my-2.pl-0(flat dense color='transparent' style='border-top: 1px solid rgba(0,0,0,.12) !important; border-bottom: 1px solid rgba(0,0,0,.12) !important;')
            v-switch.xs-small.py-0.my-0.ml-0(:input-value="isInstanceStatusActive" hide-details color='primary' :disabled="isLoading" @change="updateStatus()")
            v-chip-group
              v-chip.text-uppercase.mr-2(:color='lineStatusColor' small outlined)
                v-icon.mr-1.ml-0(:color='lineStatusColor' small) mdi-circle
                small.text--primary(style='white-space: nowrap;') {{ lineStatusLabel }}
              v-chip.text-uppercase.mr-2(color='primary' small outlined v-if="$vuetify.breakpoint.mdAndUp && componentConfig.campaigns.isCPMFixed")
                small.text--primary.fixed-cpm(style='white-space: nowrap;') Fixed CPM: {{ formattedMaxBid }}
              v-chip.text-uppercase.mr-2(color='primary' small outlined v-if="$vuetify.breakpoint.mdAndUp && componentConfig.campaigns.isGoalsShown")
                small.text--primary.goal-type(style='white-space: nowrap;') Impressions Based
              v-chip.text-uppercase.mr-2(color='primary' small outlined v-if="$vuetify.breakpoint.mdAndUp && componentConfig.campaigns.isLinePriorityShown && usableData.priority")
                small.text--primary.priority(style='white-space: nowrap;') {{ usableData.priority.name }} Priority

            //- Deals
            v-chip-group.ml-2(show-arrows v-if='$vuetify.breakpoint.mdAndUp && componentConfig.campaigns.isDealsShown' style='max-width: 400px')
              v-chip(label small v-for='(deal, index) in instanceData.deals' :key='index' @click="openEditLineDialog('deals')")
                v-icon.mr-1(v-if='deal.deal' :color='deal.deal.status === "Active" ? "primary" : "grey"' small) mdi-tag
                span(v-if='deal.deal')
                  strong  {{ deal.code }}
                  | : {{ deal.deal.name }}
                span.deals(v-if='!deal.deal') {{ deal.code }}

            v-spacer
            v-btn.mx-1(icon @click="openEditLineDialog('budget')")
              v-icon mdi-pencil
            v-menu(offset-y :close-on-content-click="false" v-model="actionsMenuOpen")
              template(v-slot:activator='{ on }')
                v-btn.other-menu-opener-btn(icon v-on='on' @click.stop='')
                  v-icon mdi-dots-vertical
              v-list(dense)
                confirmationDialog#duplicate-dialog(
                  @confirmed="confirmAction('duplicate', ...arguments)"
                  :details="{title: 'Duplicate Line', msg: 'Are you sure you want to duplicate this line?', btnLabel: 'Duplicate Line'}"
                )
                  template(v-slot:customActivator='')
                    v-list-item#duplicate-btn(@click="$root.$emit('openConfirmDialog', 'Duplicate Line')")
                      v-list-item-icon.mr-4
                        v-icon mdi-content-copy
                      v-list-item-title Duplicate
                  template(v-slot:customOptions='')
                    v-checkbox.creatives-checkbox(v-model="keepCreativesOnDuplicate" label="Keep assigned creatives?" color="primary")
                v-divider(v-if="instanceData && instanceData.status !== 'Archived'")
                confirmationDialog(
                  v-if="instanceData && instanceData.status !== 'Archived'"
                  @confirmed="confirmAction('archive')"
                  :details="{title: 'Archive Line', msg: 'Are you sure you want to archive this line?', btnLabel: 'Archive Line'}"
                )
                  template(v-slot:customActivator='')
                    v-list-item#archive-btn(@click="$root.$emit('openConfirmDialog', 'Archive Line')")
                      v-list-item-icon.mr-4
                        v-icon mdi-delete
                      v-list-item-title Archive

          v-row.my-0.mt-2
            //- Deals - Mobile
            v-col(v-if='componentConfig.campaigns.isDealsShown').hidden-md-and-up.py-0
              .text-overline.deals  Deals
              v-chip-group(v-if='$vuetify.breakpoint.smAndDown')
                v-chip(label small v-for='(deal, index) in instanceData.deals' :key='index' @click="openEditLineDialog('deals')")
                  v-icon.mr-1(v-if='deal.deal' :color='deal.deal.status === "Active" ? "primary" : "grey"' small) mdi-tag
                  span(v-if='deal.deal')
                    strong  {{ deal.code }}
                    | : {{ deal.deal.name }}
                  span(v-if='!deal.deal') {{ deal.code }}

            v-col(cols='12' md='4')
              v-card(flat height='100%' style='background-color:transparent;')
                .text-overline Schedule
                .text-body-1.font-weight-bold.schedule {{ formattedDates }}
                .text-caption.grey--text {{ formattedSchedule }}
            v-col.hidden-md-and-up.py-0(cols='12' v-if="componentConfig.campaigns.isCPMFixed")
              .text-overline Fixed CPM
              .text-body-1.fixed-cpm {{ formattedMaxBid }}
            v-col.hidden-md-and-up(cols='12' v-if="componentConfig.campaigns.isGoalsShown")
              .text-overline Line Goal Type
              .text-body-1.goal-type Impressions Based
            v-col.hidden-md-and-up.pt-0(cols='12' v-if="componentConfig.campaigns.isLinePriorityShown && usableData.priority")
              .text-overline Priority
              .text-body-1.priority-mobile {{ usableData.priority.name }}
            v-col(cols='12' md='3' v-if="!componentConfig.campaigns.isCPMFixed")
              v-card(flat  height='100%' style='background-color:transparent;')
                .text-overline Max Bid
                .text-body-1.font-weight-bold.max-bid {{ formattedMaxBid }} CPM
            v-col(cols='12' :md='progressBarColSize')
              v-card.mr-1(flat  height='100%' style='background-color:transparent;')
                .text-overline.mb-1 Spent / Budget
                spentVsBudgetProgressBar.budget-progress-bar(
                  :item="usableData"
                  :min-width="170"
                  warning-msg="This Line Order requires your attention"
                )
            v-col(cols='12' :md='progressBarColSize' v-if="componentConfig.campaigns.isGoalsShown")
              v-card.mr-1(flat  height='100%' style='background-color:transparent;')
                .text-overline.mb-1 Impressions
                deliveredVsTargetProgressBar.impressions-progress-bar(
                  :item="usableData"
                  :totals-data="totalsData"
                  :min-width="170"
                )

        v-col.pt-0.pb-0(cols='12' lg='5' )
          v-expansion-panels(accordion flat mandatory)
            v-hover(v-slot:default="{ hover }")
              v-expansion-panel()
                v-expansion-panel-header.py-0.text-body-1 Audience
                  template(v-slot:actions='')
                    transition(:duration='2000' name='scale-transition')
                      v-btn(text small icon :disabled='isLoading' v-if='hover' @click="openEditLineDialog('audience')")
                        v-icon.edit(small style='transform: inherit;' ) mdi-pencil
                    v-btn.ma-0(text small icon )
                      v-icon $vuetify.icons.expand
                v-expansion-panel-content
                  v-row.my-0(no-gutters)
                    v-col.targeting-summary.location(v-if='visibleSections.location' cols='12' md='6')
                      v-card.pr-2.pb-2(flat height='100%' style='background-color:transparent;')
                        .text-overline Location
                        .two-lines-truncate
                          .text-body-2.text-overflow-hidden(
                            v-for='(item, index) in targetingSummary.location.listToDisplay'
                            :key='index'
                            :class="{'wrapIt': $vuetify.breakpoint.xsOnly} "
                          ) {{ item }}
                        .text-body-2.text-truncate.info--text(
                          @click="openEditLineDialog('audience')"
                          style='cursor: pointer;'
                          v-if='targetingSummary.location.hidden.length > 0'
                          :class="{'wrapIt': $vuetify.breakpoint.xsOnly} "
                        ) {{ '+ ' + targetingSummary.location.hidden.length + ' more' }}

                    v-col.targeting-summary.environment(v-if='visibleSections.environment' cols='12' md='6')
                      v-card.pr-2.pb-2(flat  height='100%' style='background-color:transparent;')
                        .text-overline Environment
                        .two-lines-truncate.text-body-2.text-overflow-hidden {{ targetingSummary.environment }}
                    v-col.targeting-summary.demographic(cols='12' md='6' v-if="visibleSections.demographic")
                      v-card.pr-2.pb-2(flat  height='100%' style='background-color:transparent;')
                        .text-overline Demographic
                        .two-lines-truncate.text-body-2.text-overflow-hidden {{ targetingSummary.demographic }}
                    v-col.targeting-summary.screen(
                      v-if="visibleSections.screen"
                      v-for='s in targetingSummary.screen'
                      :key='s.key' cols='12' md='6'
                    )
                      v-card.pr-2.pb-2(flat  height='100%' style='background-color:transparent;')
                        .text-overline.screen-targeting-type {{ s.key }}
                        .one-line-truncate.text-body-2.text-overflow-hidden.screen-targeting-value {{ s.value }}

            v-hover(v-slot:default="{ hover }")
              v-expansion-panel.moments-panel
                v-expansion-panel-header.py-0
                  template(v-slot:default='{ open }')
                    v-row.my-0(no-gutters)
                      v-col.pr-2(cols=4 md=3)
                        .text-body-1 Moments
                      v-col.pr-2.d-flex.align-center(cols=8 md=8 v-if='instanceData.momentId')
                        v-fade-transition(leave-absolute)
                          span(v-if='open' key='0')
                          div.d-flex.align-center(v-else='' key='1')
                            v-scroll-y-reverse-transition
                              div.text-body-2.collapsed-moment-summary {{ momentsSummaryHeadline }}

                  template(v-slot:actions='')
                    div(style='width:28px; height:28px;')
                      transition(name='scale-transition')
                        v-btn(
                          text small icon
                          :disabled='isLoading'
                          v-if='hover'
                          @click="openEditLineDialog('moments')"
                        )
                          v-icon.edit(small style='transform: inherit;' ) mdi-pencil

                    v-btn.ma-0(text small icon )
                      v-icon() $vuetify.icons.expand
                v-expansion-panel-content(eager)
                  moments-read(
                    v-if='instanceData.momentId'
                    :temperature-scale='temperatureScale'
                    :moment-id='instanceData.momentId'
                    :postEvent='momentsPostEvent'
                    :authToken="$store.getters['user/getToken']"
                    @momentLoaded='setMoment'
                  )
                  v-row.my-0(v-else justify='center')
                    v-col
                      v-btn(color='primary' text block @click="openEditLineDialog('moments')") Add Moments

            v-hover(v-slot:default="{ hover }")
              v-expansion-panel()
                v-expansion-panel-header.py-0
                  template(v-slot:default='{ open }')
                    v-row.my-0(no-gutters)
                      v-col.pr-2(cols=4 md=4)
                        .text-body-1 Creatives
                      v-col.pr-2(cols=8 md=7)
                        v-fade-transition.d-inline(leave-absolute)
                          span(v-if='open' key='0')
                          span.d-flex(v-else='' key='1')
                            v-scroll-y-reverse-transition
                              v-chip.text-uppercase.mr-4(color='success' small outlined v-if="!isCreativeLoading && assigned.length")
                                small.text--primary {{ assigned.length }}
                                small.ml-1.text--primary.hidden-sm-and-down(style='white-space: nowrap;')  Assigned
                            v-scroll-y-reverse-transition
                              v-chip.text-uppercase.mr-4.d-flex.justify-center(color='error' small outlined v-if="!isCreativeLoading && missing.length")
                                small.text--primary {{ missing.length }}
                                small.ml-1.text--primary.hidden-sm-and-down(style='white-space: nowrap;') Missing Formats
                  template(v-slot:actions='')
                    div(style='width:28px; height:28px;')
                      transition(name='scale-transition')
                        v-btn(
                          text small icon
                          :disabled='isLoading'
                          v-if='hover'
                          @click="openEditLineDialog('creatives')"
                        )
                          v-icon.edit(small style='transform: inherit;' ) mdi-pencil

                    v-btn.ma-0(text small icon )
                      v-icon() $vuetify.icons.expand
                v-expansion-panel-content
                  div
                    v-progress-linear(indeterminate color='primary' height='2' v-if='isCreativeLoading' style='margin:0px 0px -2px 0px;')
                    .pa-0
                      v-layout(row wrap fill-height v-if='!isCreativeLoading')
                        v-flex(xs12 sm6 md6 v-if='assigned.length')
                          .text-overline Assigned Creatives
                          ul.pl-0(style='list-style:none;')
                            li(v-for='item in assigned')
                              span.text-subtitle-1 {{ item.count }} x
                              v-chip.ma-1.text-uppercase(color='success' text-color='secondary' small outlined)
                                v-icon.mr-1(left small color='success') mdi-check-circle
                                | {{ item.format }}

                        v-flex(xs12 sm6 md6 v-if='missing.length')
                          .text-overline Missing Creatives
                          ul.pl-0(style='list-style:none;')
                            li(v-for='item in missing')
                              v-chip.ma-1.text-uppercase(color='error' text-color='secondary' small outlined)
                                v-icon.mr-1(left small color='error') mdi-alert-circle
                                | {{ item.minWidth }} x {{ item.minHeight }}

                    //- Empty State
                    v-container(v-if='!isCreativeLoading && (!assigned.length && !missing.length)')
                      v-layout(row wrap fill-height)
                        v-flex
                          v-btn(color='primary' text block @click="openEditLineDialog('creatives')") Add Creatives

</template>

<script>
import breadcrumbs from '@/components/reportBreadcrumbs.vue'
import audienceService from '@/services/audience.service'
import helpersService from '@/services/helpers.service'
import reportingService from '@/services/reporting.service'
import componentConfigService from '@/services/componentConfig'

import confirmationDialog from '@/components/confirmationDialog.vue'
import spentVsBudgetProgressBar from '@/components/spentVsBudgetProgressBar.vue'
import deliveredVsTargetProgressBar from '@/components/deliveredVsTargetProgressBar.vue'

import momentsRead from '@/web-components/moments/momentsRead.vue'

import { mapState } from 'vuex'

export default {
  components: {
    breadcrumbs,
    spentVsBudgetProgressBar,
    deliveredVsTargetProgressBar,
    confirmationDialog,
    momentsRead
  },
  props: ['instanceData', 'isLoading', 'campaignData', 'totalsData', 'lineCreativesLoading', 'lineForecast', 'lineAssignments'],
  mounted () {
    this.$root.$on('refreshWeatherMoment', (lineId) => {
      if (lineId === this.instanceData.id) {
        this.momentsPostEvent = { name: 'refresh moment' }
      }
    })
  },
  watch: {
    // wait for "props" to finish loading before setting assigned Creatives for this Line
    lineCreativesLoading: function (newVal, oldVal) {
      if (newVal === false) {
        this.$store.dispatch('campaignReport/getAssignedCreatives', this.lineAssignments)
      }
    }
  },
  computed: {
    ...mapState('audience', {
      initialTargeting: state => state.initialTargeting
    }),
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    temperatureScale () {
      return this.marketVals.temperatureScale
    },
    isCreativeLoading () {
      return this.lineCreativesLoading || this.$store.getters['campaignReport/getLineCreativesLoading']
    },
    isInstanceStatusActive () {
      return !!(this.instanceData && this.instanceData.bookingStatus === 'Active')
    },
    statusColor () {
      return this.isInstanceStatusActive ? 'success' : 'grey'
    },
    usableData () {
      const data = JSON.parse(JSON.stringify(this.instanceData))
      if (data && !data.audienceSegments) {
        data.audienceSegments = data.targeting
      }
      return data
    },

    lineStatusLabel () {
      return reportingService.lineStatusLabel(this.usableData)
    },
    lineStatusColor () {
      return reportingService.lineStatusColor(this.usableData)
    },
    lineStatusIcon () {
      return reportingService.lineStatusIcon(this.usableData)
    },

    targetingSummary () {
      return {
        location: this.usableData
          ? audienceService.formatGeoTargetsFull(this.usableData.geography, 2, this.marketVals.distanceUnit)
          : null,
        environment: this.usableData && this.usableData.segments
          ? audienceService.formatEnvironments(this.usableData.segments, this.lineExchangeTaxonomies)
          : null,
        demographic: this.usableData
          ? audienceService.formatMobileTargets(this.usableData.audienceSegments, this.$store.getters['campaignReport/getTargetGroups'], this.marketVals.currencySymbolString)
          : null,
        screen: this.usableData
          ? audienceService.formatScreenIntoKeyValueList(this.usableData.audienceSegments, this.initialTargeting, this.$store.getters['campaignReport/getTargets'])
          : null
      }
    },
    visibleSections () {
      return {
        location: !!this.targetingSummary.location,
        environment: !!this.targetingSummary.environment,
        demographic: this.marketVals.canSeeMobileTargeting && !!this.targetingSummary.demographic,
        screen: this.marketVals.canSeeScreens && !!this.targetingSummary.screen
      }
    },

    formattedDates () {
      if (!this.usableData) return 'N/A'
      return audienceService.formatDates(this.usableData)
    },
    formattedSchedule () {
      if (!this.usableData) return 'N/A'
      return audienceService.formatSchedule(this.usableData)
    },
    formattedMaxBid () {
      if (!this.usableData) return 'N/A'
      const maxCpm = this.usableData.maxCpm
      return helpersService.dataDisplayFormatting(maxCpm, 'currency', this.marketVals.currencySymbolString)
    },

    assignedCreatives () {
      return this.$store.getters['campaignReport/getAssignedCreatives']
    },
    uniquesAvailableFormats () {
      const uniquesAvailableFormats = this.lineForecast.formats
        .reduce((accumulator, currentValue) => {
          if (accumulator.findIndex(a => a.key === currentValue.key) === -1) {
            accumulator.push(currentValue)
          }
          return accumulator
        }, [])
      return uniquesAvailableFormats

      // const uniquesRequiredFormatsKeys = this.lineForecast.formats.map(x => { return x.key })
      // return [...new Set(uniquesRequiredFormatsKeys)]
    },
    assigned () {
      var outputObj = []

      // list ALL assigned creatives, not only the ones matching required formats
      this.assignedCreatives.forEach(creative => {
        var format = creative.creativeFormat.minWidth + ' x ' + creative.creativeFormat.minHeight
        var assignedIndex = outputObj.findIndex(assignedFormat => assignedFormat.format === format)
        if (assignedIndex > -1) {
          outputObj[assignedIndex].count++
        } else {
          outputObj.push({
            format,
            count: 1
          })
        }
      })

      return outputObj
    },
    missing () {
      return this.uniquesAvailableFormats.filter(creativeFormat => {
        return !this.assignedCreatives.some(creative => creative.creativeFormat.key === creativeFormat.key)
      })
    },
    lineExchangeTaxonomies () {
      const lineExchangeId = this.usableData.exchangeId || 0
      const allExchanges = this.$store.getters['general/getExchanges']
      const lineExchange = allExchanges.find(exchange => exchange.id === lineExchangeId)
      const allTaxonomies = this.$store.getters['general/allTaxonomies']
      return allTaxonomies[lineExchange.key]
    },
    momentsSummaryHeadline () {
      const summary = []
      const moments = this.moment && this.moment.definition
      if (moments) {
        if (moments.weather) summary.push('1 weather moment')
        if (moments.finance && Object.keys(moments.finance).length) {
          const pickedItems = Object.keys(moments.finance).filter(f => !!moments.finance[f])
          if (pickedItems.length > 1) summary.push(`${pickedItems.length} finance moments`)
          else if (pickedItems.length === 1) summary.push('1 finance moment')
        }
      }
      return summary.join(', ')
    },

    progressBarColSize () {
      return this.componentConfig.campaigns.isCPMFixed ? 4 : 5
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  data () {
    return {
      actionsMenuOpen: false,
      momentsPostEvent: null,
      moment: null,
      keepCreativesOnDuplicate: true
    }
  },
  methods: {
    updateStatus () {
      this.$emit('lineUpdated', {
        id: this.instanceData.id,
        bookingStatus: this.isInstanceStatusActive ? 'Paused' : 'Active'
      })
    },

    editLine (changedFieldsObj, toDelete) {
      this.$emit('lineUpdated', changedFieldsObj, toDelete)
    },

    confirmAction (action, params) {
      let res
      if (action === 'duplicate') res = { selected: [this.instanceData], keepCreatives: this.keepCreativesOnDuplicate }
      if (action === 'archive') res = [{ id: this.instanceData.id, bookingStatus: 'Archived' }]

      if (res) this.$emit(action + 'Line', res)
      this.actionsMenuOpen = false
    },

    openEditLineDialog (src) {
      this.$emit('openEditLineDialog', { src, line: this.usableData })
    },

    setMoment (m) {
      this.moment = m
    }
  }
}
</script>

<style scoped lang="stylus">
>>> .v-expansion-panel-header {
  padding-left: 12px;
  padding-right: 12px;
}

>>> .v-expansion-panel-content__wrap {
  padding-top: 0px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 136px;
}

.v-expansion-panel {
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: -1px;
}

>>> .v-skeleton-loader__chip {
  margin-right: 4px;
}

>>> .v-toolbar__content {
  padding-left: 0px;
}

.v-expansion-panel-header, .v-expansion-panel-header--active {
  min-height: 48px;
}

>>> .v-skeleton-loader .v-skeleton-loader__card-heading {
  background-color: transparent !important;
}

>>> .v-skeleton-loader .v-skeleton-loader__heading {
  margin-left: 0px !important;
}
</style>
