<template lang="pug">
  v-snackbar(
    ref='appSnackbarRef'
    v-model="model"
    :color="color"
    :timeout="snackbar.timeout"
    left
  )
    v-icon(
      :dark="snackbar.type !== 'warning'"
      class="mr-2"
    ) {{icon}}
    span(
      :class="snackbar.type === 'warning' ? 'black--text' : 'white--text'"
      v-text="snackbar.msg"
    )
</template>

<script>
import {
  mapState
} from 'vuex'

const ICON_MAP = {
  error: 'mdi-alert-circle',
  info: 'mdi-information',
  success: 'mdi-check-circle',
  warning: 'mdi-alert'
}

const COLOR_MAP = {
  info: 'primary',
  success: 'secondary'
}

export default {
  name: 'snackbar',
  data: () => ({
    model: false
  }),

  computed: {
    ...mapState('snackbar', ['snackbar']),
    icon () {
      return ICON_MAP[this.snackbar.type] || 'mdi-check-circle'
    },
    color () {
      return COLOR_MAP[this.snackbar.type] || this.snackbar.type
    }
  },

  watch: {
    snackbar () {
      this.model = true
    }
  }
}
</script>
