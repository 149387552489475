<template lang="pug">
.home
  vue-headful(:title="componentConfig.branding.title('Manage Organizations')")
  v-card.pt-3(color='transparent' flat)
    v-toolbar.elevation-0(color='transparent')
      .text-h5.hidden-sm-and-down Organizations
      v-text-field.search-organizations(label='Find Organizations' v-model='search' hide-details prepend-inner-icon='mdi-magnify' solo clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")

      v-btn.mt-0.hidden-sm-and-down(large rounded color='primary' router-link :to='{ name: "Edit Organization", params: {organizationId: "add"} }')
        v-icon(left) mdi-plus
        span New Organization
      template(v-slot:extension='')
        v-tabs(color='primary' background-color='transparent' slider-color='primary' :value='currentTab')
          v-tab(ripple color='primary lighten-4' v-for='tab, i in tabs' :key='i' @change='changeTab(i)')
            | {{ tab }}
    v-divider

    v-scroll-y-reverse-transition
      v-toolbar.action-bar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selected.length < organizations.length') {{ selected.length }} {{ selected.length === 1? 'organization' : 'organizations' }} selected
          v-toolbar-title.text-subtitle-1(v-if='selected.length === organizations.length && organizations.length < totalItems && !selectAll')
            | The {{ organizations.length }} organizations on this page are selected
          v-toolbar-title.text-subtitle-1(v-if='selected.length === totalItems || selectAll') All {{ totalItems }} organizations are selected

        span.hidden-md-and-up
          v-chip.ma-1(v-if='selected.length < totalItems && !selectAll' dark outlined color='white' small)
            | {{ selected.length }}
          v-chip.ma-1(v-if='selected.length === totalItems || selectAll' dark color='white' class='secondary--text' small)
            | {{ totalItems }}

        v-btn.ml-2(text outlined small dark v-if='selected.length === organizations.length && organizations.length < totalItems && !selectAll' @click='selectAll = true') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ totalItems }}&nbsp;
            | organizations

        v-toolbar-items.ml-3
          v-btn(:icon='$vuetify.breakpoint.smAndDown' :text='$vuetify.breakpoint.mdAndUp' :loading='csvBtnLoading' @click='exportOrganizationsToCsv()')
            v-icon(:small='$vuetify.breakpoint.mdAndUp') mdi-download
            span.ml-2.hidden-sm-and-down Export CSV

        v-spacer
        v-btn.ml-3(icon @click='selected = []')
            v-icon mdi-close

    v-data-table.cs-data-table(
      :headers='headers'
      :items='organizations'
      :loading='isTableLoading'
      :options.sync='pagination'
      :page='pagination.page'
      v-model='selected'
      :footer-props='{itemsPerPageOptions: [10, 25, 50]}'
      show-select
      :dense='$vuetify.breakpoint.xsOnly'
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      :no-data-text='noDataText'
      :server-items-length='totalItems'
      )

      template.text-left(v-slot:item="{ item, select, isSelected }" v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-layout.my-2.align-center
            v-flex.text-left.d-flex(xs8)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              v-chip.ma-1.mt-0.text-uppercase.xs-small(:color='statusColor(item.paymentStatus)' small outlined)
                v-icon.mr-1.ml-0(:color='statusColor(item.paymentStatus)' small) mdi-circle
                small(class='text--primary' style='white-space: nowrap;') {{ statusLabel(item.paymentStatus) }}

            v-flex.text-right(xs6)
              .text-caption.grey--text
                | {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}
          v-layout.mt-2.align-start
            v-flex(xs12)
              div
                .text-overline {{ item.industry }}
                router-link.info--text(:to='{ name: "Organization Settings", params: { organizationId: item.id } }') {{ item.name }}
                .text-caption.grey--text {{ fullAddress(item.address) }}
              div.my-2
                v-chip.mr-3(small)
                  | Terms: {{ item.termsVersion? 'v' + item.termsVersion : '-' }}
                v-chip(small)
                  | Payment Method: {{ paymentMethodLabel(item) }}
          v-divider.my-2
          v-layout.mt-0.mb-2.align-start
            v-flex(xs12)
              v-list-item.px-0(two-line)
                v-avatar.mr-3(color='white' size='36')
                  v-icon(large) mdi-account-circle
                v-list-item-content.py-0
                  v-list-item-title {{ fullName(item.contacts) }}
                  v-list-item-subtitle
                    a.d-block.my-1(v-if='item.contacts[0] && item.contacts[0].email' icon small :href="'mailto:' + item.contacts[0].email" :title='item.contacts[0].email')
                      v-icon(small ) mdi-email
                      |  {{ item.contacts[0].email }}
                    a.d-block.my-1(v-if='item.contacts[0] && item.contacts[0].phone' icon small :href="'tel:' + item.contacts[0].phone" :title='item.contacts[0].phone')
                      v-icon(small ) mdi-phone
                      |  {{ item.contacts[0].phone }}

      template(v-slot:item.paymentStatus='{ item }')
        v-chip.ma-1.text-uppercase.xs-small(:color='statusColor(item.paymentStatus)' small outlined)
          v-icon.mr-1.ml-0(:color='statusColor(item.paymentStatus)' small) mdi-circle
          small(class='text--primary' style='white-space: nowrap;') {{ statusLabel(item.paymentStatus) }}

      template.text-left(v-slot:item.name='{ item }')
        router-link.info--text(:to='{ name: "Organization Settings", params: { organizationId: item.id } }') {{ item.name }}

      template.text-left.widths(v-slot:item.address='{ item }')
        div(style='max-width: 120px;')
          .d-block.text-truncate.address {{ fullAddress(item.address) }}

      template.text-left.widths(v-slot:item.city='{ item }')
        div(style='max-width: 120px;')
          .d-block.text-truncate.city {{ item.address.city || '-' }}

      template.text-left.widths(v-slot:item.type='{ item }')
        div(style='max-width: 120px;')
          .d-block.text-truncate.type {{ formatType(item) }}

      template.text-left.widths(v-slot:item.currency='{ item }')
        div
          .d-block.text-truncate.currency {{ item.currency? item.currency.code : '-' }}

      template.text-left.widths(v-slot:item.createdOn='{ item }')
        div(style='max-width: 160px;')
          .d-block.text-truncate.createdOn {{ item.createdOn | date('MMM Do, YYYY hh:mm a') }}

      template.text-left.widths(v-slot:item.industry='{ item }')
        div(style='max-width: 110px;')
          .d-block.text-truncate.industry {{ item.industry }}

      template.text-left(v-slot:item.contacts='{ item }')
        div(style='min-width:90px; word-break: break-word;').contact
          | {{ fullName(item.contacts) }}

      template.text-left(v-slot:item.contactBtns='{ item }')
        div(style='width:85px;')
          .d-inline-block
            v-btn(v-if='item.contacts[0] && item.contacts[0].phone' icon small :href="'tel:' + item.contacts[0].phone" :title='item.contacts[0].phone')
              v-icon mdi-phone
          .d-inline-block
            v-btn.mr-1(v-if='item.contacts[0] && item.contacts[0].email' icon small :href="'mailto:' + item.contacts[0].email" :title='item.contacts[0].email')
              v-icon mdi-email

      template(v-slot:item.termsVersion='{ item }')
        div {{ item.termsVersion? 'v' + item.termsVersion : '-' }}

      template.text-right(v-slot:item.paymentType='{ item }')
        div(style='min-width:75px;')
          | {{ paymentMethodLabel(item) }}

</template>

<script>
import csvService from '@/services/csv.service'
import userApi from '@/services/user.api'
import componentConfigService from '@/services/componentConfig'

import _ from 'lodash'

export default {
  created: function () {
    // organizationsApi.getOrganizationsCount().then(res => { this.totalOrganizations = res })
  },
  watch: {
    search: _.debounce(function (newVal, oldVal) {
      this.getOrganizations()
    }, 450),

    currentTab: function (newVal, oldVal) {
      // bypass initial set from URL
      if (oldVal > -1) {
        // reset Paging
        this.$set(this.pagination, 'page', 1)

        this.getOrganizations()
      }
    },

    pagination: function (newVal, oldVal) {
      this.getOrganizations()
    },

    selected: function () {
      if (this.selected < this.organizations) {
        this.selectAll = false
      }
    }
  },
  data () {
    return {
      totalItems: 0,
      selectAll: false,

      currentTab: 0,
      tabs: ['All', 'Active', 'Suspended', 'Inactive'],
      selected: [],
      search: '',
      lastSearch: '',
      organizations: [],
      isTableLoading: false,
      csvBtnLoading: false,

      pagination: {
        sortBy: ['createdOn'],
        sortDesc: [true],
        itemsPerPage: 25,
        page: 1,
        totalItems: 0
      },
      headers: [
        { text: 'Status', align: 'left', value: 'paymentStatus', sortable: false },
        { text: 'Type', align: 'left', value: 'type' },
        { text: 'ID', align: 'left', value: 'id' },
        { text: 'Name', align: 'left', value: 'name' },
        { text: 'Address', align: 'left', value: 'address', sortable: false },
        { text: 'City', align: 'left', value: 'city' },
        { text: 'Country', align: 'left', value: 'currency' },
        { text: 'Industry', align: 'left', value: 'industry' },
        { text: 'Terms', align: 'center', value: 'termsVersion' },
        { text: 'PM', align: 'center', value: 'paymentType' },
        { text: 'Contact', align: 'left', value: 'contacts', sortable: false },
        { text: '', align: 'right', value: 'contactBtns', sortable: false },
        { text: 'Created On', align: 'left', value: 'createdOn' }
      ]
    }
  },
  computed: {
    noDataText () {
      const adjs = ['', 'active ', 'inactive ']
      if (!this.organizations.length && this.search && this.search.length) {
        return 'No organization matches your search'
      } else {
        return 'No ' + adjs[this.currentTab] + 'organization found'
      }
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    statusColor (paymentStatus) {
      if (paymentStatus === 'Valid') { return 'success' }
      if (paymentStatus === 'Suspended') { return 'error' }
      if (paymentStatus === 'Unspecified') { return 'grey' }
    },
    statusLabel (paymentStatus) {
      if (paymentStatus === 'Valid') { return 'Active' }
      if (paymentStatus === 'Suspended') { return 'Suspended' }
      if (paymentStatus === 'Unspecified') { return 'Inactive' }
    },

    getOrganizations () {
      this.isTableLoading = true
      this.selected = []
      this.selectAll = false

      var filters = this.formatRequestParams()

      userApi.getOrganizationsCount(filters).then(count => {
        this.totalItems = count
      })

      userApi.getOrganizationsAdv(filters)
        .then(organizations => {
          this.organizations = organizations
          this.isTableLoading = false
          // if (this.selectAll) {
          //   this.selected = organizations
          // }
        })
        .catch(() => {
          this.isTableLoading = false
          const msg = 'Could not load organizations'
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    exportOrganizationsToCsv () {
      this.csvBtnLoading = true
      const obj = this.formatRequestParams(true)

      if (this.selectAll) {
        obj.selectAll = true
      }

      userApi.getOrganizationsCsv(obj).then(res => {
        this.csvBtnLoading = false
        csvService.csvExport(res, 'organizations')
        this.selected = []
      })
        .catch(() => {
          this.csvBtnLoading = false
          const msg = 'CSV file could not be downloaded'
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
    },

    // ================
    // HELPER FUNCTIONS
    // ================
    formatRequestParams (isCsv = false) {
      const params = {
        filters: []
      }

      // CSV
      if (isCsv && this.selected.length && this.selected.length < this.pagination.itemsPerPage) {
        if (this.selected.length <= 15) {
          params.filters.push('(' + this.selected.map(x => 'id eq ' + x.id).join(' or ') + ')')
        } else {
          params.filters.push('(' + this.organizations.filter(y => !this.selected.map(w => w.id).includes(y.id)).map(x => 'id ne ' + x.id).join(' and ') + ')')
        }
      }

      // Status
      if (this.currentTab === 1) { params.filters.push("paymentStatus eq 'Valid'") }
      if (this.currentTab === 2) { params.filters.push("paymentStatus eq 'Suspended'") }
      if (this.currentTab === 3) { params.filters.push("paymentStatus eq 'Unspecified'") }

      // Pagination (skip & take)
      if (this.pagination.itemsPerPage > 0) { params.take = this.pagination.itemsPerPage }
      params.skip = (this.pagination.page - 1) * this.pagination.itemsPerPage

      // Pagination (sort)
      if (this.pagination.sortBy[0] === 'city') params.sort = 'address/city'
      else if (this.pagination.sortBy[0] === 'currency') params.sort = 'currency/code'
      else params.sort = this.pagination.sortBy[0]
      params.sort += this.pagination.sortDesc[0] ? ' desc' : ' asc'

      // Search
      if (this.search) {
        // reset "skip" param when searching something new
        if (this.search !== this.lastSearch) {
          params.skip = 0
          this.pagination.page = 1
        }

        this.lastSearch = this.search
        params.filters.push(this.buildSearchQuery(isCsv))
      } else {
        // "search" cleared?
        if (this.lastSearch) {
          params.skip = 0
          this.pagination.page = 1
        }
      }
      return params
    },

    buildSearchQuery (isCsv) {
      return isCsv
        ? `contains(dspExternalId, '${this.search}') or contains(name, '${this.search}') or contains(contacts_firstName,'${this.search}') or contains(contacts_lastName,'${this.search}')`
        : `contains(dspExternalId, '${this.search}') or contains(name, '${this.search}') or contacts/any(it:contains(it/firstName,'${this.search}')) or contacts/any(it:contains(it/lastName,'${this.search}'))`
    },

    changeTab (newVal) {
      this.currentTab = newVal
    },

    fullAddress (address) {
      let addr = ''
      if (address.addressLine1) { addr += address.addressLine1 }
      if (address.addressLine2) { addr += ' ' + address.addressLine2 }
      if (address.postalCode) { addr += ', ' + address.postalCode }
      return addr.length ? addr : '-'
    },

    fullName (contacts) {
      if (!contacts.length) { return '-' } else {
        return contacts[0].firstName + ' ' + contacts[0].lastName
      }
    },
    formatType (item) {
      return item.type === 'Default' ? 'Broadsign Ads' : 'DSP Partner (' + item.dspExternalId + ')'
    },
    paymentMethodLabel (item) {
      return item.paymentStatus === 'Valid' ? (item.paymentType === 'Automatic' ? 'ccard' : 'manual') : 'None'
      // CATCH organization having [paymentStatus = "Valid"] BUT WITHOUT paymentMethod
      // o.paymentMethod = 'E'
    }
  }
}
</script>
