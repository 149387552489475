<template lang="pug">
div
  div(v-if='initialized' style='min-height: 100px' fill-height align-center justify-center)
    chartDoughnut(
      :obj='chartData'
      :isLoading='loading'
      :style="`height: 100px; width: ${width};`"
    )
  div.mt-1.mb-1(v-else)
    v-skeleton-loader.ml-4.d-inline-block(
      style='border-radius: 100px; -webkit-mask-image: -webkit-radial-gradient(white, black);'
      height='100'
      width='100'
      type='image'
      )
    .d-inline-block.pl-4.pt-3
      v-skeleton-loader.mt-1(
        width='110'
        type='paragraph'
        )
</template>

<script>
import chartDoughnut from '@/components/chartDoughnut.vue'

export default {
  components: {
    chartDoughnut
  },
  props: ['environments', 'taxonomy', 'initialized', 'loading'],
  data () {
    return {
      maxVisibleEnvs: 8
    }
  },
  computed: {
    width () {
      if (!this.chartData || !this.chartData.chartdata.labels) return '100px'
      else {
        return this.chartData.chartdata.labels.length <= 4 ? '230px' : '100%'
      }
    },
    envsTaxonomy () {
      return this.taxonomy ? this.taxonomy : this.$store.getters['audience/envsTaxonomy']
    },
    chartData () {
      var chartdata = {
        datacollection: {
          labels: null,
          datasets: [{
            label: 'environments breakdown',
            backgroundColor: ['#d3d3d3'],
            data: [100]
          }]
        }
      }

      var envsBreakdown = {
        labels: [],
        data: []
      }

      if (this.chartHasData(this.environments)) {
        const nonNullList = this.environments.filter(x => x.percent > 0)
        if (this.envsTaxonomy.length) {
          this.sortAndGroup(nonNullList).forEach(env => {
            let envLabel = ''
            const [parentKey, childKey] = env.key.split('.')

            if (env.key !== 'other') {
              const prefix = 'l.'

              const envTaxonomy = this.envsTaxonomy.find(x => x.key === prefix.concat(parentKey))
              if (envTaxonomy) {
                if (childKey && envTaxonomy.children) {
                  const childTaxonomy = envTaxonomy.children.find(c => c.key === env.key)
                  if (childTaxonomy) envLabel = childTaxonomy.label
                } else {
                  envLabel = envTaxonomy.label
                }
              }
            } else {
              envLabel = 'Other'
            }

            if (!envsBreakdown.labels.includes(envLabel)) {
              envsBreakdown.labels.push(envLabel)
              envsBreakdown.data.push((env.percent * 100).toFixed(2))
            }
          })
        } else {
          this.sortAndGroup(nonNullList).forEach(env => {
            const envLabel = this.$options.filters.capitalize(env.key.replace('-', ' '))
            if (!envsBreakdown.labels.includes(envLabel)) {
              envsBreakdown.labels.push(envLabel)
              envsBreakdown.data.push((env.percent * 100).toFixed(2))
            }
          })
        }
        chartdata.datacollection.datasets[0].backgroundColor = ['#FFCD56', '#4BC0C0', '#36A2EB', '#FF6384', '#FF9F40', '#7E57C2', '#D32F2F', '#d3d3d3']
      } else {
        envsBreakdown.labels = ['no results'.toUpperCase()]
        envsBreakdown.data = [100]
        chartdata.datacollection.datasets[0].backgroundColor = ['#d3d3d3']
      }

      chartdata.datacollection.labels = envsBreakdown.labels
      chartdata.datacollection.datasets[0].data = envsBreakdown.data

      return { chartdata: chartdata.datacollection }
    }
  },
  methods: {
    sortAndGroup (list) {
      if (list.length <= this.maxVisibleEnvs - 1) {
        return list
      } else {
        const sortedEnvs = list.sort(function (a, b) { return b.percent - a.percent })
        const groupedEnvs = [
          ...sortedEnvs.slice(0, this.maxVisibleEnvs - 1),
          {
            key: 'other',
            percent: sortedEnvs.slice(this.maxVisibleEnvs - 1).map(x => x.percent).reduce(function (a, b) { return a + b }, 0)
          }
        ]
        return groupedEnvs
      }
    },
    chartHasData (envs) {
      if (!envs || !envs.length) return false
      else {
        const percentagesSum = envs.map(x => x.percent).reduce((sum, current) => sum + current)
        return percentagesSum > 0
      }
    }
  }
}
</script>
