import SubdomainService from '@/services/subdomain.service'

export default componentConfig

function componentConfig (isForAdServer = false) {
  const isAdServerDomain = SubdomainService.isAdServerDomain()
  return {
    branding: {
      defaultLogo: isAdServerDomain
        ? null
        : require('@/assets/logo_rgb_color.png'),
      isCopyrightShown: !isAdServerDomain,
      title: (pageName) => `${pageName}${pageName ? ' | ' : ''}${!isAdServerDomain ? 'Broadsign Ads' : 'Broadsign'}`
    },
    userSettings: {
      isUserProfileShown: !isForAdServer,
      isSwitchOrganizationShown: !isAdServerDomain,
      isSettingsShown: !isForAdServer
    },
    campaigns: {
      isLineOrderNamePrefilled: !isForAdServer,
      isDealsShown: !isForAdServer,
      isGoalsShown: isForAdServer,
      isLinePriorityShown: isForAdServer,
      isECPMShown: !isForAdServer,
      isCPMFixed: isForAdServer,
      isVenuesShown: !isForAdServer,
      isDemographicsTabShown: !isForAdServer,
      isTransferProposalShown: !isForAdServer,
      impressions: {
        useEstimated: !isForAdServer,
        isDistributionShown: !isForAdServer
      },
      boundInitialForecastCall: isForAdServer
    },
    audiences: {
      canSaveLoadAudience: !isForAdServer,
      isSupportShown: !isForAdServer,
      isMeasurementPartnersShown: !isForAdServer
    },
    creatives: {
      isCroppingDisclaimerShown: !isForAdServer,
      isCreativeApprovalNeeded: !isForAdServer,
      submitButtonText: isForAdServer ? 'Confirm Upload' : 'Submit For Approval'
    },
    isBillingShown: !isForAdServer,
    isReportsShown: !isForAdServer,
    links: {
      navbarHelp: isForAdServer
        ? 'https://docs.broadsign.com/broadsign-platform/Documentation_Aud_Campaigns/aud-campaign.html'
        : 'https://intercom.help/broadsign-ads/en/collections/3304790-broadsign-ads-for-buyers',
      firstCampaignStepByStep: isForAdServer
        ? 'https://docs.broadsign.com/broadsign-platform/Documentation_Aud_Campaigns/aud-campaign-create.html'
        : 'https://intercom.help/broadsign-ads/en/articles/5705280-creating-my-first-campaign',
      understandingTargeting: isForAdServer
        ? null
        : 'https://intercom.help/broadsign-ads/en/articles/6037530-using-demographic-segments-based-on-mobile-data-for-screen-targeting',
      exploreEnvironments: isForAdServer
        ? null
        : 'https://www.campsiteproject.com/inventory',
      understandingDemographicReport: isForAdServer
        ? null
        : 'https://intercom.help/broadsign-ads/en/articles/6041442-understanding-the-demographic-report',
      uploadCreatives: isForAdServer
        ? 'https://docs.broadsign.com/broadsign-platform/Documentation_Aud_Campaigns/aud-campaign-creative-upload.html'
        : 'https://intercom.help/broadsign-ads/en/articles/6036866-creative-upload-specifications-and-best-practices',
      creativeSpecs: isForAdServer
        ? null
        : 'https://www.campsiteproject.com/inventory#specs',
      platformPolicy: isForAdServer
        ? null
        : 'https://broadsign.com/programmatic-privacy-policy'
    }
  }
}
