import billingService from '@/services/billing.service'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    // Loading controls
    fetchingPaymentMethods: false,
    fetchingCards: false,
    fetchingInvoices: false,
    fetchingTransactions: false,
    summariesLoading: false,
    makingPayment: false,

    invoices: [],
    invoicesTotalCount: 0,
    invoicesCurrentTab: 0,

    transactions: [],
    transactionsTotalCount: 0,
    transactionsCurrentTab: 0,

    lastBill: null,
    spendingCap: null,
    nextBill: null,
    paymentMethods: null,
    cards: null,
    pendingAmount: null
  },
  getters: {
    invoicesCurrentTab: state => {
      return state.invoicesCurrentTab
    },
    transactionsCurrentTab: state => {
      return state.transactionsCurrentTab
    },
    fetchingPaymentMethods: state => {
      return state.fetchingPaymentMethods
    },
    fetchingCards: state => {
      return state.fetchingCards
    },
    paymentMethods: state => {
      return state.paymentMethods
    },
    cards: state => {
      return state.cards
    },
    pendingAmount: state => {
      return state.pendingAmount
    },
    lastBill: state => {
      return state.lastBill
    },
    nextBill: state => {
      return state.nextBill
    },
    summariesLoading: state => {
      return state.summariesLoading
    },

    fetchingTransactions: state => {
      return state.fetchingTransactions
    },
    transactions: state => {
      return state.transactions
    },
    transactionsTotalCount: state => {
      return state.transactionsTotalCount
    },
    fetchingInvoices: state => {
      return state.fetchingInvoices
    },
    invoices: state => {
      return state.invoices
    },
    invoicesTotalCount: state => {
      return state.invoicesTotalCount
    }
  },
  mutations: {
    setInvoicesCurrentTab: (state, val) => {
      state.invoicesCurrentTab = val
    },
    setTransactionsCurrentTab: (state, val) => {
      state.transactionsCurrentTab = val
    },
    setFetchingPaymentMethods: (state, bool) => {
      state.fetchingPaymentMethods = bool
    },
    setFetchingCards: (state, bool) => {
      state.fetchingCards = bool
    },
    setLastBill: (state, invoices) => {
      state.lastBill = invoices
    },
    setPaymentMethods: (state, paymentMethods) => {
      state.paymentMethods = paymentMethods
    },
    setCards: (state, cards) => {
      state.cards = cards
    },
    setSpendingCap: (state, spendingCap) => {
      state.spendingCap = spendingCap
    },
    setNextBill: (state, nextBill) => {
      state.nextBill = nextBill
    },
    setPaymentTriggers: (state, paymentTriggers) => {
      state.paymentTriggers = paymentTriggers
    },
    setPendingAmount: (state, pendingAmount) => {
      state.pendingAmount = pendingAmount
    },
    setSummariesLoading: (state, val) => {
      state.summariesLoading = val
    },

    setFetchingInvoices: (state, bool) => {
      state.fetchingInvoices = bool
    },
    setInvoices: (state, invoices) => {
      state.invoices = invoices
    },
    setInvoicesTotalCount: (state, count) => {
      state.invoicesTotalCount = count
    },
    setFetchingTransactions: (state, bool) => {
      state.fetchingTransactions = bool
    },
    setTransactions: (state, transactions) => {
      state.transactions = transactions
    },
    setTransactionsTotalCount: (state, count) => {
      state.transactionsTotalCount = count
    }

  },
  actions: {
    getActivePaymentMethods: ({ commit, dispatch }, orgId) => {
      commit('setFetchingPaymentMethods', true)
      commit('setFetchingCards', true)

      return billingService.getActivePaymentMethods(orgId)
        .then(paymentMethods => {
          commit('setPaymentMethods', paymentMethods)

          // var paymentMethods = res.data.filter(pm => pm.status === 'Active' && ['stripe', 'stripe-manual'].indexOf(pm.paymentMethod.key) > -1)
          var shouldFetchStripe = paymentMethods.some(pm => ['stripe', 'stripe-manual'].indexOf(pm.paymentMethod.key) > -1)
          if (shouldFetchStripe) {
            dispatch('getStripeCards')
          } else {
            commit('setFetchingCards', false)
          }

          commit('setFetchingPaymentMethods', false)
          return paymentMethods
        })
    },

    getStripeCards: ({ commit }) => {
      billingService.getStripeCards().then(cards => {
        commit('setCards', cards)
        commit('setFetchingCards', false)
      })
    },

    getTransactionsTotalCount: ({ commit, state }, userInfo) => {
      const params = formatInvoicesParams(userInfo, 'transactions', state.transactionsCurrentTab)
      billingService.getInvoicesTotalCount(params).then(count => {
        commit('setTransactionsTotalCount', count)
      })
    },
    getTransactions: ({ commit, dispatch, state }, userInfo) => {
      commit('setFetchingTransactions', true)
      dispatch('getTransactionsTotalCount', userInfo)
      const params = formatInvoicesParams(userInfo, 'transactions', state.transactionsCurrentTab)
      billingService.getInvoices(params).then(transactions => {
        commit('setTransactions', transactions)
        commit('setFetchingTransactions', false)
      })
    },

    getInvoicesTotalCount: ({ commit, state }, userInfo) => {
      const params = formatInvoicesParams(userInfo, 'invoices', state.invoicesCurrentTab)
      billingService.getInvoicesTotalCount(params).then(count => {
        commit('setInvoicesTotalCount', count)
      })
    },
    getInvoices: ({ commit, dispatch, state }, userInfo) => {
      commit('setFetchingInvoices', true)
      dispatch('getInvoicesTotalCount', userInfo)
      const params = formatInvoicesParams(userInfo, 'invoices', state.invoicesCurrentTab)
      billingService.getInvoices(params).then(transactions => {
        commit('setInvoices', transactions)
        commit('setFetchingInvoices', false)
      })
    },

    getSummaryDetails: ({ commit, dispatch, state }, userInfo) => {
      commit('setSummariesLoading', true)

      const { organizationId } = userInfo
      dispatch('getActivePaymentMethods', organizationId)

      // when getting the "last Bill", we really want it for User's organization,
      // regardless if he can view all organization
      const params = {
        organizationId,
        take: 1
      }

      billingService.getInvoices(params)
        .then(lastBill => {
          commit('setLastBill', lastBill[0])
          return billingService.getPaymentTriggers(organizationId)
        }).then(paymentTriggers => {
          let spendingCap = paymentTriggers.find(t => t.type === 'amount')
          spendingCap = spendingCap ? spendingCap.value.amount : null
          commit('setSpendingCap', spendingCap)
          commit('setNextBill', moment().startOf('month').add(1, 'months').format())
          commit('setPaymentTriggers', paymentTriggers)
          return billingService.getPendingInvoiceItems()
        }).then(pendingInvoiceItems => {
          const sumPending = state.transactions
            .filter(t => {
              return (
                t.organizationId === organizationId &&
                          (t.status === 'Pending' || t.status === 'Failed')
              )
            })
            .map(t => t.amountIncludingTaxes)
            .reduce((a, b) => a + b, 0)

          const sumFloatingItems = pendingInvoiceItems
            .filter(t => t.organizationId === organizationId)
            .map(t => t.amount)
            .reduce((a, b) => a + b, 0)

          commit('setPendingAmount', sumPending + sumFloatingItems)
          commit('setSummariesLoading', false)
        })
    },

    downloadCsv: ({ commit, dispatch, state }, userInfo) => {
      const params = formatInvoicesParams(userInfo, userInfo.type, state[userInfo.type + 'CurrentTab'])
      return billingService.getInvoicesCsv(params).then(transactions => transactions)
    }
  }
}

// Helper functions

function formatInvoicesParams (userInfo, type, tabIndex = 0) {
  const params = { ...userInfo }
  if (!params.filters) { params.filters = [] }

  if (type === 'transactions') {
    params.filters.push("(paidPaymentMethod/key eq 'stripe' or paymentMethod/key eq 'stripe' or paymentFailures/any(f: f/paymentMethod eq 'stripe') or contains(paidRefNumber, 'chargeId') or paymentMethod/key eq null)")
    if (tabIndex > 0) { params.filters.push("status eq '" + tabMapper(type, tabIndex) + "'") }
  }

  if (type === 'invoices') {
    params.filters.push("(paidPaymentMethod/key eq 'manual' or (paymentMethod/key eq 'manual' and paidPaymentMethod/key ne 'stripe' and paidPaymentMethod/key ne 'stripe-manual'))")
    if (tabIndex > 0) { params.filters.push("status eq '" + tabMapper(type, tabIndex) + "'") }
  }

  if (userInfo.pagination.itemsPerPage > 0) { params.take = userInfo.pagination.itemsPerPage }
  params.skip = (userInfo.pagination.page - 1) * userInfo.pagination.itemsPerPage
  params.sort = userInfo.pagination.sortBy[0]
  params.sort += userInfo.pagination.sortDesc[0] ? ' desc' : ' asc'
  if (params.search) { params.filters.push(`(contains(invoiceNumber,'${params.search}') or contains(organization/name, '${params.search}'))`) }

  return params
}

function tabMapper (type, index) {
  const filters = {
    invoices: ['', 'Paid', 'Pending', 'Resolved'],
    transactions: ['', 'Paid', 'Pending', 'Failed']
  }
  return filters[type][index]
}
