<template lang="pug">
  div(style='color: rgba(0, 0, 0, 0.87)')
    v-layout.py-0(row wrap)
      v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
        .text-body-1.font-weight-bold.mr-3 Schedule
      v-flex(xs12 lg9 xl8 text-md-left)
        v-radio-group.mt-0.schedule-option-group(v-if="isOnAuctionPackagePage" v-model='scheduleOption' hide-details)
          v-radio.schedule-option.mb-0(ref="alwaysOption" label='Always-on' value='always' color='primary')
          .text-caption.text--disabled.mb-3.pl-8.always-on-label Will start when manually activated and only stop when manually deactivated.
          v-radio.schedule-option(ref="scheduleOption" label='Set Start and End Date' value='schedule' color='primary')
        v-flex(xs12 lg9 xl8 text-md-left v-if="scheduleOption === 'schedule'")
          v-layout(row wrap)
            v-flex.pr-6.pl-0(xs7)
              v-menu.d-flex(:close-on-content-click='false' v-model='startDateMenu' min-width='290px')
                template(v-slot:activator="{on}")
                  v-text-field#start-date-picker(ref="startDate" v-on="on" v-model='startDate' :disabled="!canEditStartDateAndTime" label='Start Date' readonly prepend-icon='mdi-calendar')
                v-date-picker(ref="startDatePicker" :value='startDate' @input='startDateSelected' color='primary'  no-title :min="currentDate")
            v-flex(xs5)
              time-picker#start-time-picker(
                label='Start Time'
                :default-value='startTimeRounded'
                :error-messages='errorMessages.startTime'
                :disabled="!canEditStartDateAndTime"
                @timeSelected='startTimeSelected'
              )

          v-layout(row wrap)
            v-flex.pr-6.pl-0(xs7)
              v-menu.d-flex(:close-on-content-click='false' v-model='endDateMenu' min-width='290px')
                template(v-slot:activator="{on}")
                  v-text-field#end-date-picker(ref="endDate" v-on="on" v-model='endDate' label='End Date' readonly prepend-icon='mdi-calendar')
                v-date-picker(ref="endDatePicker" :value='endDate' @input='endDateSelected' color='primary' :min='startDate' no-title)
            v-flex(xs5)
              time-picker#end-time-picker(
                label='End Time'
                :default-value='endTime'
                :error-messages='errorMessages.endTime'
                @timeSelected='endTimeSelected')

    v-layout.py-0(row wrap)
      v-flex.pb-0(xs12 lg3 xl4 text-xl-right)
        .text-body-1.font-weight-bold.mr-3 Dayparting
      v-flex(xs12 lg9 xl8 text-md-left)
        dayparting.pb-6(
          @onUpdate='daypartingUpdated'
          :initialDaypartValue='initialDaypartValue'
          :initialWeekpartValue='initialWeekpartValue'
        )
</template>

<script>
import moment from 'moment'
import timePicker from '@/components/timePicker.vue'
import dayparting from '@/components/dayparting.vue'
import { DatetimeFormattingEnum } from '@/enums'

export default {
  components: {
    timePicker,
    dayparting
  },
  props: ['host', 'parent', 'isProposalLine', 'instance', 'isOnAuctionPackagePage'],
  data () {
    return {
      // Schedule
      currentDate: moment().format(),
      startDateMenu: false,
      endDateMenu: false,
      scheduleOption: this.isOnAuctionPackagePage ? this.$store.getters['auctionPackage/getScheduleOption'] : 'schedule',
      errorMessages: {
        startTime: '',
        endDate: '',
        endTime: ''
      },
      originalFullStartDate: null
    }
  },
  computed: {
    canEditStartDateAndTime () {
      let can = true

      // restrict Edit Line only
      if (this.parent === 'editLine' && !this.isProposalLine && this.instance !== 'createCampaign' && !this.isOnAuctionPackagePage) {
        can = moment().isBefore(this.originalFullStartDate)
      }
      return can
    },
    startDate () {
      return this.$store.getters[this.host.name + '/getStartDate']
    },
    startTime () {
      return this.$store.getters[this.host.name + '/getStartTime']
    },

    startTimeRounded () {
      return moment(this.startTime, DatetimeFormattingEnum.TIME_ONLY).format(DatetimeFormattingEnum.ROUNDED_HOUR)
    },
    endDate () {
      return this.$store.getters[this.host.name + '/getEndDate']
    },
    endTime () {
      return this.$store.getters[this.host.name + '/getEndTime']
    },
    audienceTargeting () {
      return this.$store.getters['audience/selectedTargets']
    },
    initialDaypartValue () {
      const existingDaypartTargetIndex = this.audienceTargeting.find(t => t.target === 'daypart')
      return existingDaypartTargetIndex ? existingDaypartTargetIndex.targetValues : null
    },
    initialWeekpartValue () {
      const existingWeekpartTargetIndex = this.audienceTargeting.find(t => t.target === 'weekpart')
      return existingWeekpartTargetIndex ? existingWeekpartTargetIndex.targetValues : null
    }
  },
  mounted () {
    this.originalFullStartDate = null
    this.$root.$on('validateSchedule', () => {
      return this.validateSchedule()
    })
  },
  beforeUpdate () {
    if (!this.originalFullStartDate) this.originalFullStartDate = moment(this.startDate + ' ' + this.startTime, DatetimeFormattingEnum.DATETIME)
  },
  watch: {
    scheduleOption (newV, oldV) {
      if (newV !== oldV && this.isOnAuctionPackagePage) {
        this.$store.commit('auctionPackage/setScheduleOption', newV)
      }
    }
  },
  methods: {
    validateSchedule (schedulePartName, value) {
      // reset ALL error messages
      this.errorMessages = {
        startTime: '',
        endDate: '',
        endTime: ''
      }

      let validated = true

      if (this.scheduleOption === 'schedule') {
        const startDate = schedulePartName === 'startDate' ? value : this.startDate
        const startTime = schedulePartName === 'startTime' ? value : this.startTime
        const endDate = schedulePartName === 'endDate' ? value : this.endDate
        const endTime = schedulePartName === 'endTime' ? value : this.endTime

        // use moment to compare
        const momentStartDate = this.$moment(startDate, DatetimeFormattingEnum.DATE_ONLY)
        const momentStartTime = this.$moment(startDate + ' ' + startTime, DatetimeFormattingEnum.DATETIME)
        const momentEndDate = this.$moment(endDate, DatetimeFormattingEnum.DATE_ONLY)
        const momentEndTime = this.$moment(endDate + ' ' + endTime, DatetimeFormattingEnum.DATETIME_ROUNDED_HOUR)
        const now = this.$moment()

        // check that startDate < endDate
        if (momentStartDate.isAfter(momentEndDate, 'day')) {
          // we'll never see this message, because we adjust endDate in startDateSelected()
          this.errorMessages.endDate = 'End date must be after Start date'
          validated = false
        } else {
          // if same Day, check that startTime < endTime
          if (momentStartDate.isSame(momentEndDate, 'day')) {
            if (!momentEndTime.isAfter(momentStartTime, 'hour')) {
              this.errorMessages.endTime = 'Ends before even started'
              validated = false
            }
          }

          // check that startTime is not already reached
          if (momentStartTime.isBefore(now)) {
            this.errorMessages.startTime = 'Start Time already reached'
            validated = false
          }
        }
      }

      this.$store.commit('createCampaign/setScheduleValidated', validated)
    },
    startDateSelected (d) {
      this.validateSchedule('startDate', d)
      this.$store.commit(this.host.name + '/setStartDate', d)
      this.startDateMenu = false
      this.$store.commit('createCampaign/setCanOverwriteDealDates', false)

      // adjust END date if necessary
      const momentStart = this.$moment(d, DatetimeFormattingEnum.DATE_ONLY)
      const momentEnd = this.$moment(this.endDate, DatetimeFormattingEnum.DATE_ONLY)
      if (momentStart.isAfter(momentEnd, 'day')) {
        const newEndDate = momentStart.add(4, 'weeks').format(DatetimeFormattingEnum.DATE_ONLY)

        // update Campaign's END date
        this.endDateSelected(newEndDate)
      }

      if (!this.isOnAuctionPackagePage) {
        this.$emit('storeUpdated')
      }
    },
    startTimeSelected (t) {
      const fullStartTime = this.getFullTimeFromRoundedHours(t)
      this.validateSchedule('startTime', fullStartTime)
      this.$store.commit(this.host.name + '/setStartTime', fullStartTime)
      if (!this.isOnAuctionPackagePage) {
        this.$emit('storeUpdated')
      }
    },
    endDateSelected (d) {
      this.validateSchedule('endDate', d)
      this.$store.commit(this.host.name + '/setEndDate', d)
      this.endDateMenu = false
      this.$store.commit('createCampaign/setCanOverwriteDealDates', false)
      if (!this.isOnAuctionPackagePage) {
        this.$emit('storeUpdated')
      }
    },
    endTimeSelected (t) {
      this.validateSchedule('endTime', t)
      this.$store.commit(this.host.name + '/setEndTime', t)
      if (!this.isOnAuctionPackagePage) {
        this.$emit('storeUpdated')
      }
    },

    daypartingUpdated (dayparting) {
      this.$store.commit('audience/updateDaypart', dayparting.daypart)
      this.$store.commit('audience/updateWeekpart', dayparting.weekpart)
      this.$emit('storeUpdated')
    },

    getFullTimeFromRoundedHours (roundedHours) {
      return this.$moment(roundedHours, DatetimeFormattingEnum.ROUNDED_HOUR).format(DatetimeFormattingEnum.TIME_ONLY)
    }
  }
}
</script>
