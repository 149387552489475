import Vue from 'vue'
import Vuex from 'vuex'

// Store modules
import audienceStore from '@/stores/audience.store'
import authStore from '@/stores/auth.store'
import billingStore from '@/stores/billing.store'
import cognitoStore from '@/stores/cognito.store'
import campaignReportStore from '@/stores/campaignReport.store'
import createCampaignStore from '@/stores/createCampaign.store'
import auctionPackagesStore from '@/stores/auctionPackage.store'
import creativesStore from '@/stores/creatives.store'
import generalStore from '@/stores/general.store'
import notificationsStore from '@/stores/notifications.store'
import proposalsStore from '@/stores/proposals.store'
import reportsStore from '@/stores/reports.store'
import snackbarStore from '@/stores/snackbar.store'
import userStore from '@/stores/user.store'
import venuesStore from '@/stores/venues.store'

Vue.use(Vuex)

var globalStore = new Vuex.Store({
  modules: {
    audience: audienceStore,
    auth: authStore,
    billing: billingStore,
    campaignReport: campaignReportStore,
    cognito: cognitoStore,
    createCampaign: createCampaignStore,
    auctionPackage: auctionPackagesStore,
    creatives: creativesStore,
    general: generalStore,
    notifications: notificationsStore,
    proposals: proposalsStore,
    reports: reportsStore,
    snackbar: snackbarStore,
    user: userStore,
    venues: venuesStore
  }
})

export default globalStore
