<template lang="pug">
v-menu(v-model='menu' :close-on-content-click='false' offset-y top :open-on-hover='true' open-delay='300')
  template(v-slot:activator="{on}")
    a.info--text(v-on="on") {{ venueDetails.name }}

  v-card.elevation-24.pb-0(width='400')
    v-img(:src='venueImage' height='200px')
      v-layout(slot='placeholder' fill-height align-center justify-center ma-0 height='200px')
        v-progress-circular(indeterminate color='primary')

    v-card-title(style='min-height:68px')
      div
        .text-h6 {{ venueDetails.name }}
        .text-caption
          span.grey--text {{ venueDetails.environment.replace('-', ' ') | capitalize }} -&nbsp;
          span.grey--text {{ venueDetails.address + ', ' + venueDetails.city }}
</template>

<script>
export default {
  props: ['venueDetails'],
  watch: {
    menu: function (newVal, oldVal) {
      if (newVal) {
        this.$store.dispatch('venues/getVenue', this.venueDetails.id)
      }
    }
  },
  computed: {
    venueImage () {
      var venue = this.$store.getters['venues/getVenue'](this.venueDetails.id)
      return venue ? venue.image : ''
    }
  },
  data () {
    return {
      menu: false
    }
  }
}
</script>
