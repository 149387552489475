import moment from 'moment'
import momentz from 'moment-timezone'
import accounting from 'accounting-js'

export default {
  capitalizeString,
  arrayFlat,
  replaceSpecialCharacters,
  assembleFullDate,
  customDateFormatting,
  dataDisplayFormatting,
  isDateXBeforeY,
  avatarBackgroundColor,
  initials,
  shortenNumber,
  sortByName,
  formatMoney,
  readableDateDuration,
  numberWithCommasFormatting,
  prefillWithDealDates,
  generateUnknownDealObj,
  formatTimezones,
  generateTimezones,
  getUserTimezone,
  hexToRgba,
  financeConditionChanges,
  waitFor,
  appendItemToOrderedList,
  timeout,
  isPrivateLaunchFeatureVisible,
  isAppDrawerOpen,
  matchCaseInsensitiveStringInList,
  trimNumber
}

function capitalizeString (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function arrayFlat (input) {
  const stack = [...input]
  const res = []
  while (stack.length) {
    // pop value from stack
    const next = stack.pop()
    if (Array.isArray(next)) {
      // push back array items, won't modify the original input
      stack.push(...next)
    } else {
      res.push(next)
    }
  }
  // reverse to restore input order
  return res.reverse()
}

function replaceSpecialCharacters (str) {
  str = str.replace(/'/g, "''")
  str = str.replace(/"+"/g, '%2B')
  str = str.replace(/\//g, '%2F')
  str = str.replace(/"?"/g, '%3F')
  str = str.replace(/%/g, '%25')
  str = str.replace(/#/g, '%23')
  str = str.replace(/&/g, '%26')
  return str
}

function assembleFullDate (date, time) {
  // date comes as YYYY-MM-DD string   ex. 2019-03-21
  // time comes as h A string          ex. 5 PM
  return moment(date + ' ' + time, 'YYYY-MM-DD h A').format()
}

function customDateFormatting (val, format = 'YYYY-MM-DD') {
  return moment(val).format(format)
}

function dataDisplayFormatting (val, outputFormat, currency = '$') {
  if (outputFormat === 'number') {
    return accounting.formatNumber(val, { precision: 0 })
  } else if (outputFormat === 'currency') {
    return formatMoney(val, 2, currency)
  } else if (outputFormat === 'date') {
    return customDateFormatting(val, 'MMM Do, YYYY')
  } else { return val }
}

function isDateXBeforeY (dateX, dateY, timeUnit) {
  // X and Y are dates (string)
  // timeUnit is ['days', 'hours', ...] used to compare the two dates

  if (!timeUnit) timeUnit = 'hours'
  const momentX = moment(dateX)
  const momentY = moment(dateY)
  return momentX.isBefore(momentY, timeUnit)
}

function avatarBackgroundColor (user) {
  // Services Users
  const campsiteServiceUserIds = [
    1, // Admin
    14, // SspService
    468, // Stripe Service [sandbox]
    479 // Stripe Service [prod]
  ]

  const userId = user.id || user.userId || 1

  // Services User - use Campsite color
  if (campsiteServiceUserIds.indexOf(userId) > -1) {
    return 'primary'
  }

  const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

  const rCoeff = alphabet.indexOf(user.lastName.substring(0, 1).toLowerCase()) // [0,26]
  const gCoeff = alphabet.indexOf(user.firstName.substring(0, 1).toLowerCase()) // [0,26]
  const bCoeff = rCoeff + gCoeff // [0,52]

  const r = userId * rCoeff % 255
  const g = userId * gCoeff % 255
  const b = userId * bCoeff % 255

  const color = 'rgb(' + r + ', ' + g + ', ' + b + ')'

  return color
}

function initials (user) {
  return user.firstName.substring(0, 1).toUpperCase() + user.lastName.substring(0, 1).toUpperCase()
}

function numberWithCommasFormatting (nbr) {
  return nbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function shortenNumber (nbr, kThreshold = 100, decimals = 1) {
  if (!nbr || !parseInt(nbr)) return '0'
  else {
    const int = parseInt(nbr)
    if ((int / 1000000000) >= 1) return (int / 1000000000).toFixed(decimals).toString() + 'B'
    else if ((int / 1000000) >= 1) return (int / 1000000).toFixed(decimals).toString() + 'M'
    else if ((int / 1000) >= kThreshold) return (int / 1000).toFixed(decimals).toString() + 'K'
    else return numberWithCommasFormatting(int)
  }
}

function sortByName (a, b) {
  if (a.name < b.name) { return -1 }
  if (a.name > b.name) { return 1 }
  return 0
}

function formatMoney (amount, precision = 2, currrency = '$') {
  return accounting.formatMoney(amount, { symbol: currrency, precision: precision })
}

function readableDateDuration (startDate, endDate) {
  const start = dataDisplayFormatting(startDate, 'date')
  const end = dataDisplayFormatting(endDate, 'date')
  return `${start} - ${end}`
}

function sortByDate (a, b) {
  if (isDateXBeforeY(a, b, 'hours')) { return -1 } else if (moment(a).isSame(b, 'hours')) { return 0 } else return 1
}

function prefillWithDealDates (deals = []) {
  if (!deals.length) return
  const minDate = deals.map(x => x.startDate).sort(sortByDate)[0]
  const sortedEndDates = deals.map(x => x.endDate).sort(sortByDate)
  const maxDate = sortedEndDates[sortedEndDates.length - 1]

  // let minDate = "2019-12-24T23:00:00-05:00"
  // let maxDate = "2020-01-30T23:00:00-05:00"

  if (isDateXBeforeY(new Date(), minDate, 'hours') && isDateXBeforeY(new Date(), maxDate, 'hours')) {
    const durationInMonths = moment.duration(moment(maxDate).diff(moment(minDate))).as('months')
    if (durationInMonths <= 1) {
      return {
        from: customDateFormatting(minDate),
        to: customDateFormatting(maxDate),
        fromTime: customDateFormatting(minDate, 'h A'),
        toTime: customDateFormatting(maxDate, 'h A')
      }
    } else {
      return {
        from: customDateFormatting(minDate),
        to: customDateFormatting(moment(minDate).add(1, 'months'), 'YYYY-MM-DD'),
        fromTime: customDateFormatting(minDate, 'h A')
      }
    }
  } else if (isDateXBeforeY(minDate, new Date(), 'hours') && isDateXBeforeY(new Date(), maxDate, 'hours')) {
    const durationInMonths = moment.duration(moment(maxDate).diff(moment())).as('months')
    if (durationInMonths <= 1) {
      return {
        from: customDateFormatting(new Date()),
        to: customDateFormatting(maxDate)
      }
    }
  }
}

function generateUnknownDealObj (code) {
  return {
    auctionType: null,
    code: code,
    name: code,
    source: null,
    status: null,
    startDate: null,
    endDate: null,
    floorCPM: null
  }
}

function generateTimezones (zones) {
  if (!zones) zones = []

  const countries = zones.filter(z => z !== 'EU')
  const countryZones = countries.map(x => momentz.tz.zonesForCountry(x)).flat()
  const euZones = zones.includes('EU') ? moment.tz.names().filter(tz => tz.includes('Europe/')) : []
  return formatTimezones([...countryZones, ...euZones])
}

function formatTimezones (list) {
  return list.filter(x => x.search('/') >= 0)
    .map(x => {
      const city = x.split('/')[x.split('/').length - 1]
      const offset = momentz(new Date()).tz(x).format('Z')
      return {
        value: x,
        offset,
        label: `UTC${offset} ${city.replace('_', ' ')} Time`
      }
    })
    .sort(sortTimezones)
}

function getUserTimezone () {
  return momentz.tz.guess()
}

function sortTimezones (a, b) {
  const aDecomposed = {
    isNegative: a.offset.split(':')[0].search('-') > -1,
    hours: parseFloat(a.offset.split(':')[0]),
    minutes: parseFloat(a.offset.split(':')[1])
  }
  const bDecomposed = {
    isNegative: b.offset.split(':')[0].search('-') > -1,
    hours: parseFloat(b.offset.split(':')[0]),
    minutes: parseFloat(b.offset.split(':')[1])
  }
  let aInHours = aDecomposed.hours + aDecomposed.minutes / 60
  aInHours *= aDecomposed.isNegative ? -1 : 1
  let bInHours = bDecomposed.hours + bDecomposed.minutes / 60
  bInHours *= bDecomposed.isNegative ? -1 : 1

  if (aInHours < bInHours) { return -1 }
  if (aInHours > bInHours) { return 1 }
  return 0
}

function hexToRgba (hex, opacity) {
  if (opacity > 1 || opacity < 0) return null
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  const color = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null
  if (!color) return null
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity})`
}

function financeConditionChanges (beforeObj, afterObj) {
  if (!afterObj || !afterObj.financeEvaluationCondition || (!beforeObj && afterObj)) return []
  const changes = []
  if (afterObj.financeEvaluationCondition.conditionType !== beforeObj.financeEvaluationCondition.conditionType) {
    changes.push({ eventName: 'momentsStockConditionSetMetricOperator', val: afterObj.financeEvaluationCondition.conditionType })
  }
  if (afterObj.financeEvaluationCondition.comparisonType !== beforeObj.financeEvaluationCondition.comparisonType) {
    changes.push({ eventName: 'momentsStockConditionSetConditionOperator', val: afterObj.financeEvaluationCondition.comparisonType })
  }
  if (afterObj.financeEvaluationCondition.targetValue !== beforeObj.financeEvaluationCondition.targetValue) {
    changes.push({ eventName: 'momentsStockConditionSetConditionValue', val: afterObj.financeEvaluationCondition.targetValue })
  }
  return changes
}

function waitFor (seconds) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    })
  }, seconds * 1000)
}

function appendItemToOrderedList (item, list, orderBy) {
  if (!list) list = []
  list.push(item)
  return list.sort((item1, item2) => {
    if (item1[orderBy] < item2[orderBy]) { return -1 }
    if (item1[orderBy] > item2[orderBy]) { return 1 }
    return 0
  })
}

/**
 * @param {number} secs A number of seconds to wait
 */
function timeout (secs) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, secs * 1000)
  })
}

/**
 * @param {boolean} isTestEnv
 * @param {boolean} isGlobalAdmin
 */
function isPrivateLaunchFeatureVisible (isTestEnv, isGlobalAdmin) {
  return isTestEnv || isGlobalAdmin
}

/**
 * @param {boolean} isMobile
 * @param {boolean} canSeeNewUi
 */
function isAppDrawerOpen (isMobile, canSeeNewUi) {
  if (isMobile) return false
  const savedValueFromLocalStorage = localStorage.getItem('isAppDrawerOpen')
  const shouldBeOpen = !savedValueFromLocalStorage || savedValueFromLocalStorage === 'true'
  return canSeeNewUi && shouldBeOpen
}

/**
 * @param {string} str
 * @param {{name: string}[]} list
 */
function matchCaseInsensitiveStringInList (str, list) {
  if (!str || typeof str !== 'string') return undefined
  return list.find(x => x.name.toLowerCase().trim() === str.toLowerCase().trim())
}

/**
 * @param {string | number} stringNum
 */
function trimNumber (stringNum, precision = 2) {
  if (!stringNum) return null

  if (typeof stringNum === 'number') stringNum = stringNum.toString()
  const splitNum = stringNum.split('.')
  const decimals = splitNum[1] && splitNum[1].length > 2 ? splitNum[1].slice(0, precision) : splitNum[1]
  return parseFloat(`${splitNum[0]}.${decimals}`)
}
