import flags from '@/plugins/rox/flags'

export default {
  csvFormatter
}

function csvFormatter (line) {
  const bidRangeLabel = flags.canSeeAuctionPackageCurrencySelection.isEnabled() ? 'Price Range' : 'Bid Range'
  const minBidRangeColumnName = 'Min ' + bidRangeLabel
  const maxBidRangeColumnName = 'Max ' + bidRangeLabel

  const formattedLine = {
    'Line Name': line.name,
    'Line External Id': line.externalId,
    'Est. Impressions': line.estimatedImpressions,
    'Number of Screens': line.numberOfFaces,
    'Number of Venues': line.numberOfVenues,
    'Start Date': line.startDate,
    'End Date': line.endDate,
    'Creative Type': line.targetedCreativeType,
    'Creative Format': line.targetedCreativeFormat,
    Publishers: line.targetedSuppliers,
    Currency: line.currency,
    [minBidRangeColumnName]: line.minBidRange,
    [maxBidRangeColumnName]: line.maxBidRange,
    'Max Bid': line.maxBid,
    Budget: line.budget
  }

  if (!flags.canSeeAuctionPackageCurrencySelection.isEnabled()) {
    delete formattedLine.Currency
  }

  return formattedLine
}
