import axios from 'axios'
import store from '@/stores/auth.store.js'

const { VUE_APP_TROUBLESHOOTING_API_URL } = process.env

export default {
  getLineFaces,
  getLineFacesCsv,
  getLineStats
}

function getLineFaces (lineId, pagination = {}, search = '', period) {
  if (!lineId || !parseInt(lineId)) {
    return Promise.reject(new Error('Invalid lineId param.'))
  }
  const pageNumber = pagination.pageNumber || 1
  const pageSize = pagination.pageSize || 10

  var endpoint = VUE_APP_TROUBLESHOOTING_API_URL + '/faces?lineId=' + lineId + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize

  if (search) {
    endpoint += '&search=' + encodeURIComponent(search)
  }

  if (period) {
    endpoint += '&periodInHours=' + period
  }

  return axios.get(endpoint, getHeaders()).then(response => response.data)
}

function getLineFacesCsv (lineId, search = '', period) {
  if (!lineId || !parseInt(lineId)) {
    return Promise.reject(new Error('Invalid lineId param.'))
  }
  const pageNumber = 1
  const pageSize = 3000

  var endpoint = VUE_APP_TROUBLESHOOTING_API_URL + '/faces/csv?lineId=' + lineId + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize

  if (search) {
    endpoint += '&search=' + encodeURIComponent(search)
  }

  if (period) {
    endpoint += '&periodInHours=' + period
  }

  return axios.get(endpoint, getHeaders())
    .then((response) => {
      const fileName = extractFileName(response)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
}

function getLineStats (lineId, period) {
  if (!lineId || !parseInt(lineId)) {
    return Promise.reject(new Error('Invalid lineId param.'))
  }

  var endpoint = VUE_APP_TROUBLESHOOTING_API_URL + '/stats?lineId=' + lineId

  if (period) {
    endpoint += '&periodInHours=' + period
  }

  return axios.get(endpoint, getHeaders()).then(response => response.data)
}

function extractFileName (response) {
  const FILENAME_START_DELIMITER = 'filename='
  const FILENAME_END_DELIMITER = ';'

  const contentDisposition = response.headers['content-disposition']
  const fileNameWithTrailingText = contentDisposition.substring(contentDisposition.indexOf(FILENAME_START_DELIMITER) + FILENAME_START_DELIMITER.length)
  const filename = fileNameWithTrailingText.substring(0, fileNameWithTrailingText.indexOf(FILENAME_END_DELIMITER))
  return filename
}

function getHeaders () {
  return { headers: { Authorization: 'Bearer ' + store.getters.idToken(store.state) } }
}
