<template lang="pug">
  v-dialog.terms-accept-dialog(v-model='isDialogOpen' scrollable persistent width='600px' :fullscreen='$vuetify.breakpoint.xsOnly')
    v-card(flat :tile='$vuetify.breakpoint.xsOnly')
      v-card-title(:class='{ "text-h5" : $vuetify.breakpoint.smAndUp }') Campsite's Terms &amp; Conditions
      v-card-text
        .text-subtitle-1
          b(v-if='newTermsVersion') Our Terms &amp; Conditions have changed, you must accept them to continue using Campsite
          b(v-if='!newTermsVersion') To start using Campsite, you must first accept our Terms &amp; Conditions
        v-divider.mt-2.mb-4
        div(v-html='termsAsHtml')
      v-divider
      v-card-actions
        v-row.my-0(no-gutters align='center')
          v-col(cols="10" offset="1" align="center")
            .terms-accept-warning.warning--text.pl-2(v-if='!canAcceptTerms') Only owners and administrators can accept these terms.
            v-btn.terms-accept-btn(v-if='canAcceptTerms' text color='primary' :loading='accepting' @click='acceptTerms') Accept

          v-col(cols="1")
            switchOrganization(v-if='canSwitchOrg')
              template(v-slot:customActivator='')
                v-tooltip(top dark color='secondary')
                  template(v-slot:activator='{on}')
                    span(v-on='on')
                      v-btn.terms-switch-btn(icon)
                        v-icon mdi-account-switch
                  .caption Switch Organization
</template>

<script>
import supportAPI from '@/services/support.api'
import userAPI from '@/services/user.api'
import tracking from '@/services/tracking'

import switchOrganization from '@/components/switchOrganization.vue'

export default {
  components: {
    switchOrganization
  },
  data () {
    return {
      isDialogOpen: false,
      termsAsHtml: '',
      accepting: false
    }
  },
  created () {
    // Check if a polaris user was prompted with the accept terms dialog
    const isForAdServer = this.$store.getters['user/isForAdServer']
    if (isForAdServer) this.$logger.error(`Platform user prompted to accept terms. user.id=${this.userProfile.id}`)

    supportAPI.getLegalTerms().then(x => {
      this.termsAsHtml = x.body
      this.isDialogOpen = true
    })

    this.$store.dispatch('user/getUserAvailableOrganizations')
  },
  computed: {
    newTermsVersion () {
      const termsVersion = this.$store.getters['user/termsVersion']
      return termsVersion ? termsVersion !== '4' : false
    },
    canAcceptTerms () {
      return this.$store.getters['user/permissions']('terms').update
    },
    userProfile () {
      return this.$store.getters['user/getProfile']
    },
    availableOrganizations () {
      return this.$store.getters['user/getAvailableOrganizations'].filter(organization => organization.id !== this.userProfile.organizationId)
    },
    canSwitchOrg () {
      return this.availableOrganizations.length > 0
    }
  },
  methods: {
    acceptTerms () {
      if (!this.canAcceptTerms) return
      this.accepting = true

      const data = {
        id: this.userProfile.organizationId,
        termsAcceptedBy: this.userProfile.id,
        termsVersion: '4'
      }

      userAPI.editOrganization(data)
        .then(editedOrganization => {
          this.$store.commit('user/setOrganization', editedOrganization)
          tracking.sendEvent(['ga'], 'termsAccepted')
          this.isDialogOpen = false
        })
    }
  }
}
</script>
