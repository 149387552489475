// import moment from 'moment'
import moment from 'moment'
import _ from 'lodash'
import reportsApi from '@/services/reports.api'

const dateFormat = 'MMM Do, YYYY'

const items = {
  timeBreakdown: [
    { key: 'none', label: 'None' },
    { key: 'hour', label: 'Hourly', secondaryKey: 'short_date_hour' },
    { key: 'day', label: 'Daily', secondaryKey: 'short_date' },
    { key: 'week', label: 'Weekly', secondaryKey: 'short_week' },
    { key: 'month', label: 'Monthly', secondaryKey: 'short_month' },
    { key: 'year', label: 'Yearly', secondaryKey: 'short_year' }
  ],

  scheduleFrequency: [
    { key: 'OnDemand', label: 'Unscheduled' },
    { key: 'Daily', label: 'Daily' },
    { key: 'Weekly', label: 'Weekly' },
    { key: 'Monthly', label: 'First day of the month' }
  ],

  schedule: {
    frequency: { key: 'OnDemand', label: 'Unscheduled' },
    pickedDaysOfTheWeek: [],
    startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    endDate: null
  },

  weekDays: [
    { key: 'Mon', label: 'Mon', full: 'Monday' },
    { key: 'Tue', label: 'Tue', full: 'Tuesday' },
    { key: 'Wed', label: 'Wed', full: 'Wednesday' },
    { key: 'Thu', label: 'Thu', full: 'Thursday' },
    { key: 'Fri', label: 'Fri', full: 'Friday' },
    { key: 'Sat', label: 'Sat', full: 'Saturday' },
    { key: 'Sun', label: 'Sun', full: 'Sunday' }
  ],

  dynamicRangeOptions: [
    { label: 'Today', value: { duration: 1, unit: 'day', strategy: 'to-date' } },
    { label: 'Yesterday', value: { duration: 1, unit: 'day', strategy: 'full' } },
    { label: 'Last 7 days', value: { duration: 7, unit: 'day', strategy: 'full' } },
    { label: 'Last 30 days', value: { duration: 30, unit: 'day', strategy: 'full' } },
    { label: 'Last 3 months', value: { duration: 3, unit: 'month', strategy: 'full' } },
    // { label: 'Last week', value: { duration: 1, unit: 'week', strategy: 'full' } },
    { label: 'Last month', value: { duration: 1, unit: 'month', strategy: 'full' } },
    { label: 'Last quarter', value: { duration: 1, unit: 'quarter', strategy: 'full' } },
    { label: 'Last year', value: { duration: 1, unit: 'year', strategy: 'full' } },
    // { label: 'Week to date', value: { duration: 1, unit: 'week', strategy: 'to-date' } },
    { label: 'Month to date', value: { duration: 1, unit: 'month', strategy: 'to-date' } },
    { label: 'Quarter to date', value: { duration: 1, unit: 'quarter', strategy: 'to-date' } },
    { label: 'Year to date', value: { duration: 1, unit: 'year', strategy: 'to-date' } }
  ],

  dimensions: [
    {
      id: 'Delivery-g',
      name: 'Delivery',
      children: [
        {
          id: 'Organization-g',
          name: 'Organization',
          children: [
            { id: 'organization', name: 'Organization Name' },
            { id: 'campsiteOrganizationId', name: 'Organization ID' }
          ]
        },
        {
          id: 'Advertiser-g',
          name: 'Advertiser',
          children: [
            { id: 'advertiser', name: 'Advertiser Name' },
            { id: 'advertiserExternalId', name: 'Advertiser External ID' }
          ]
        },
        {
          id: 'Campaign-g',
          name: 'Campaign',
          children: [
            { id: 'campaign', name: 'Campaign Name' },
            { id: 'campaignExternalId', name: 'Campaign External ID' }
          ]
        },
        {
          id: 'Line-g',
          name: 'Line',
          children: [
            { id: 'line', name: 'Line Name' },
            { id: 'lineExternalId', name: 'Line External ID' }
          ]
        },
        {
          id: 'Programmatic Deal-g',
          name: 'Programmatic Deal',
          children: [
            { id: 'campsiteDealId', name: 'Deal ID' },
            { id: 'deal', name: 'Deal Name' },
            { id: 'dealPublisher', name: 'Deal Publisher' }
          ]
        },
        {
          id: 'Creatives-g',
          name: 'Creatives',
          children: [
            // { id: 1061, name: 'Creative ID' },
            { id: 'creative', name: 'Creative Name' },
            { id: 'creativeType', name: 'Creative Type' },
            { id: 'creativeResolution', name: 'Creative Size' },
            { id: 'creativeDuration', name: 'Creative Duration' },
            { id: 'creativeExternalId', name: 'Creative External ID' }
          ]
        }
      ]
    },
    {
      id: 'Inventory-g',
      name: 'Inventory',
      children: [
        {
          id: 'ssp',
          name: 'SSP'
        },
        {
          id: 'Publisher',
          name: 'Publisher',
          children: [
            { id: 'publisherId', name: 'Publisher ID' },
            { id: 'publisher', name: 'Publisher Name' }
          ]
        },
        {
          id: 'Venue-g',
          name: 'Venue',
          children: [
            // { id: 2031, name: 'Venue ID' },
            { id: 'venue', name: 'Venue Name' },
            { id: 'venueTypes', name: 'Venues Types' },
            { id: 'venueAddress', name: 'Venue Address' },
            { id: 'venueLatLon', name: 'Venue Lat / Long' }
            // { id: 'venueShape', name: 'Venue Shape File' }
          ]
        },
        {
          id: 'Screen-g',
          name: 'Screen',
          children: [
            { id: 'campsiteFaceId', name: 'Screen ID' },
            { id: 'deviceId', name: 'Device ID' },
            { id: 'screen', name: 'Screen Name' },
            { id: 'screenFormats', name: 'Screen Supported Formats' },
            // { id: 'screenResolution', name: 'Screen Creative Resolution' },
            { id: 'screenLatLon', name: 'Screen Lat / Long' },
            { id: 'screenMeasurementPartners', name: 'Screen Measurement Partners' }
          ]
        }
      ]
    },
    {
      id: 'Geography-g',
      name: 'Geography',
      children: [
        { id: 'country', name: 'Country' },
        { id: 'state', name: 'State' },
        { id: 'city', name: 'City' },
        { id: 'venueZipcode', name: 'Zipcode' }
      ]
    },
    {
      id: 'AuctionPackage-g',
      name: 'Auction Package',
      children: [
        { id: 'auctionPackageCode', name: 'Auction package ID' },
        { id: 'auctionPackageName', name: 'Auction package name' },
        { id: 'auctionPackageExternalId', name: 'Auction package external Id' },
        { id: 'advertiserDomain', name: 'Advertiser domain' },
        {
          id: 'AuctionPackageDsp-g',
          name: 'Dsp',
          children: [
            { id: 'campsiteDspId', name: 'DSP ID' },
            { id: 'dsp', name: 'DSP Name' }
          ]
        },
        { id: 'campsiteSeatId', name: 'Seat ID' },
        { id: 'campsiteCurrency', name: 'Currency' },
        {
          id: 'AuctionPackageMediaFee-g',
          name: 'Media fee',
          children: [
            { id: 'mediaFee1Label', name: 'Media fee 1 Label' },
            { id: 'mediaFee1Value', name: 'Media fee 1 Value' },

            { id: 'mediaFee2Label', name: 'Media fee 2 Label' },
            { id: 'mediaFee2Value', name: 'Media fee 2 Value' },

            { id: 'mediaFee3Label', name: 'Media fee 3 Label' },
            { id: 'mediaFee3Value', name: 'Media fee 3 Value' },

            { id: 'mediaFee4Label', name: 'Media fee 4 Label' },
            { id: 'mediaFee4Value', name: 'Media fee 4 Value' },

            { id: 'mediaFee5Label', name: 'Media fee 5 Label' },
            { id: 'mediaFee5Value', name: 'Media fee 5 Value' }
          ]
        },
        { id: 'campsiteAdId', name: 'Ad Id' }
      ]
    }
  ],

  metrics: [
    {
      id: 'metrics',
      name: 'Delivery',
      children: [
        { id: 'impressions', name: 'Impressions' },
        { id: 'adsPlayed', name: 'Ads Played' },
        { id: 'totalMediaCost', name: 'Total Media Cost' },
        { id: 'mediaCost', name: 'Media Cost' },
        { id: 'platformFee', name: 'Platform Fee' },
        { id: 'mediaCostEcpm', name: 'Media Cost eCPM' },
        { id: 'totalMediaCostEcpm', name: 'Total media cost eCPM' }
      ]
    }
  ],

  filters: [
    {
      id: 1,
      name: 'Delivery',
      children: [
        { id: 'buyer', name: 'Organization', serviceId: 'campsiteOrganizationId' },
        { id: 'org-advertiser', name: 'Advertiser', serviceId: 'advertiserId' },
        { id: 'campaign', name: 'Campaign', serviceId: 'campaignId' },
        { id: 'line', name: 'Line', serviceId: 'lineId' },
        { id: 'deal', name: 'Deal ID', serviceId: 'campsiteDealId', canAddCustom: true },
        { id: 'creative', name: 'Creative', serviceId: 'creativeId' }
      ]
    },
    {
      id: 2,
      name: 'Auction Package',
      children: [
        { id: 'auction-package', name: 'Auction Package', serviceId: 'auctionPackageCode' },
        { id: 'auction-package-external-id', name: 'Auction Package External Id', serviceId: 'auctionPackageExternalId' },
        { id: 'adomain', name: 'Advertiser Domain', serviceId: 'advertiserDomain' }
      ]
    },
    {
      id: 3,
      name: 'Inventory',
      children: [
        { id: 'publisher', name: 'Publisher', serviceId: 'publisherId' },
        // { id: 'environment', name: 'Environment', serviceId: 'TBD' },
        { id: 'venue', name: 'Venue', serviceId: 'venueId' },
        { id: 'screen', name: 'Screen', serviceId: 'campsiteFaceId' }
      ]
    },
    {
      id: 4,
      name: 'Geography',
      children: [
        // { id: 301, name: 'Country' },
        { id: 'state', name: 'State', serviceId: 'stateId' },
        { id: 'city', name: 'City', serviceId: 'cityId' },
        { id: 'zipcode', name: 'Zipcode', serviceId: 'venueZipcode' }
      ]
    }
  ]
}

const filtersById = [
  'advertiserId',
  'campaignId',
  'lineId',
  'campsiteOrganizationId',
  'creativeId',
  'publisherId',
  'venueId',
  'campsiteFaceId',
  'stateId',
  'cityId'
]

const filtersByKey = [
  'auctionPackageCode'
]

const operatorMapping = {
  contains: 'contains',
  'is any of': 'include',
  'is none of': 'exclude',
  'has any value': 'exclude'
}

const templates = [
  {
    key: 'campaign-performance',
    reportName: 'Campaign Performance',
    schedule: {
      frequency: 'Daily',
      pickedDaysOfTheWeek: [],
      startDate: null,
      endDate: null
    },
    emailRecipients: null,
    addedFilters: null,
    metrics: ['impressions', 'adsPlayed', 'totalMediaCost', 'totalMediaCostEcpm', 'mediaCost', 'mediaCostEcpm', 'platformFee'],
    dimensions: ['advertiser', 'campaign', 'line'],
    timeBreakdown: 'none',
    dateRange: {
      range: 'dynamic',
      dynamicRange: 'Yesterday',
      dates: null
    }
  },
  {
    key: 'last-month-spent',
    reportName: 'Last Month Spent',
    schedule: {
      frequency: 'Monthly',
      pickedDaysOfTheWeek: [],
      startDate: null,
      endDate: null
    },
    emailRecipients: null,
    addedFilters: null,
    metrics: ['impressions', 'totalMediaCost', 'totalMediaCostEcpm'],
    dimensions: ['advertiser'],
    timeBreakdown: 'none',
    dateRange: {
      range: 'dynamic',
      dynamicRange: 'Last month',
      dates: null
    }
  },
  {
    key: 'weekly-advertisers-spent',
    reportName: 'Weekly Advertisers Spent',
    schedule: {
      frequency: 'Weekly',
      pickedDaysOfTheWeek: ['Mon'],
      startDate: null,
      endDate: null
    },
    emailRecipients: null,
    addedFilters: null,
    metrics: ['impressions', 'totalMediaCost', 'totalMediaCostEcpm'],
    // dimensions: ['advertiser', 1022],
    dimensions: ['advertiser'],
    timeBreakdown: 'none',
    dateRange: {
      range: 'dynamic',
      dynamicRange: 'Last 7 days',
      dates: null
    }
  },
  {
    key: 'campaign-venues-locations',
    reportName: 'Campaign Venues Locations',
    schedule: {
      frequency: 'Weekly',
      pickedDaysOfTheWeek: ['Mon'],
      startDate: null,
      endDate: null
    },
    emailRecipients: null,
    addedFilters: null,
    metrics: ['impressions', 'adsPlayed'],
    // dimensions: [2031, 'venue', 'venueType', 'venueAddress', 'city', 'venueZipcode', 'state', 'venueLatLon'],
    dimensions: ['venue', 'venueType', 'venueAddress', 'city', 'venueZipcode', 'state', 'venueLatLon'],
    timeBreakdown: 'day',
    dateRange: {
      range: 'dynamic',
      dynamicRange: 'Last week',
      dates: null
    }
  }
]

/**
 * @param {string} instance
 * @return {Array}
 */
function getItems (instance) {
  if (!Object.keys(items).includes(instance)) return null
  return JSON.parse(JSON.stringify(items[instance]))
}

/**
 * @param {string} instance
 * @param {{seeAll: boolean}} options
 * @return {Array}
 */
function getPartialItems (instance, options) {
  if (instance !== 'filters') return null
  if (!options) options = {}
  const filters = JSON.parse(JSON.stringify(getItems(instance)))
  if (!options.seeAll) {
    if (filters[0].children[0].name === 'Organization') filters[0].children.splice(0, 1)
    if (filters[1].name === 'Auction Package') filters.splice(1, 1)
  }
  return filters
}

function getAllMetrics (refList) {
  const preSetMetrics = getItems('metrics')
  if (refList && refList.length) {
    const adjustedMetrics = [...preSetMetrics]
    adjustedMetrics[0].children = refList.map(m => { return { id: m.key, name: m.name } })
    return adjustedMetrics
  } else return preSetMetrics
}

function getAllDimensions (refList) {
  const feDimensions = getItems('dimensions')
  if (refList && refList.length) {
    const finalList = []
    for (const group of feDimensions) {
      const children = group.children ? group.children.map(g => renameTreeItem(g, refList)) : []
      finalList.push({ ...group, children })
    }
    return finalList
  } else return feDimensions
}

function renameTreeItem (treeObject, nameReferences) {
  const item = nameReferences.find(x => x.key === treeObject.id)
  if (item) treeObject.name = item.name
  const children = treeObject.children || []
  return {
    ...treeObject,
    children: children.map(c => {
      const match = nameReferences.find(x => x.key === c.id)
      if (!match && !isTestEnv) console.log(`warning: cannot find dimension ${c.id}`)
      return match ? { id: c.id, name: match.name } : c
    }) || []
  }
}

function isTestEnv () {
  return process.env.NODE_ENV === 'test'
}

/**
 * @param {{range: string, dates: string[], dynamicRange: string}} params
 * @return {{from: string, to: string} | { duration: number, unit: string, strategy: string } }
 */
function encodeDateRangePayload (params = {}) {
  if (params.range === 'fixed') {
    return {
      from: params.dates[0].startOf('day').format('YYYY-MM-DD[T]HH:mm:ss'),
      to: params.dates[1].endOf('day').format('YYYY-MM-DD[T]HH:mm:ss')
    }
  } else if (params.range === 'dynamic') {
    const dynamicRange = getItems('dynamicRangeOptions').find(dr => dr.label === params.dynamicRange)
    return dynamicRange && dynamicRange.value
  } else return null
}

/**
 * @param {ScheduleUiFormat} params {@link ScheduleUiFormat}
 * @param {string[]} emails
 * @param {string} timezone
 * @return {{startDate: string, endDate?: string, configuration: {timezone: string, timeOfDay: string, daysOfWeek?: string[], daysOfMonth?: number[]}, delivery: {emails: string[]}} | null}
 */
function encodeSchedulePayload (params = {}, emails = [], timezone = 'UTC') {
  const freqs = getItems('scheduleFrequency').map(f => f.key)
  const weekDays = getItems('weekDays')
  const unknownFreq = !params || !params.frequency || !freqs.includes(params.frequency.key)

  const configuration = {
    timeZone: timezone,
    timeOfDay: '06:00',
    daysOfWeek: []
    // daysOfMonth: null
  }
  if (!unknownFreq) {
    if (params.frequency.key === 'Daily') configuration.daysOfWeek = weekDays.map(wd => wd.full)
    if (params.frequency.key === 'Weekly') configuration.daysOfWeek = params.pickedDaysOfTheWeek.map(wd => wd.full)
  }

  return {
    frequency: unknownFreq ? 'OnDemand' : params.frequency.key === 'Daily' ? 'Weekly' : params.frequency.key,
    start: unknownFreq || !params.startDate ? moment().add(1, 'days').format('YYYY-MM-DD') : params.startDate,
    end: params?.endDate || null,
    configuration,
    emails
  }
}

/**
 * @param {{id: number, name: string}[]} dimensions
 * @param {{key: string, label: string}[]} timeBreakdown
 */
function encodeGroupByPayload (dimensions = [], timeBreakdown) {
  if (!dimensions) dimensions = []
  const vals = dimensions.map(d => d.id)

  const options = getItems('timeBreakdown')
  const tb = timeBreakdown ? options.find(t => t.key === timeBreakdown.key) : null
  if (timeBreakdown && tb && tb.key !== 'none') {
    vals.push(`date:${tb.secondaryKey || tb.key}`)
  }
  return vals
}

/**
 * @param {{column: {id: string}, operator: string, value: string}[]} filters
 */
function encodeTagsPayload (filters = []) {
  if (!filters) filters = []
  return filters.map(f => {
    const operation = operatorMapping[f.operator]
    const key = f.column.serviceId
    const useId = filtersById.includes(key)
    const useKey = filtersByKey.includes(key)

    let values
    if (f.operator === 'has any value') values = ['', null]
    else if (typeof f.value === 'string') {
      values = [f.value]
    } else {
      values = f.value.map(v => {
        if (v.externalId) return v.externalId
        if (useId) return v.id ? v.id.toString() : ''
        if (useKey && v.key) return v.key.toString()
        return v.name
      })
    }
    if (values && values.length) return { key, operation, values }
  }).filter(f => f)
}

/**
 * @param {{id: number, name: string}[]} metrics
 */
function encodeMetricsPayload (metrics = []) {
  if (!metrics) metrics = []
  return metrics.map(d => d.id)
}

function bundleReportPayload (data) {
  if (!data) return null
  const dateRange = encodeDateRangePayload(data.dateRange)
  const isFixedDateRange = dateRange && dateRange.from && dateRange.to
  const filtersRange = isFixedDateRange ? dateRange : { from: null, to: null }
  const metrics = encodeMetricsPayload(data.metrics)
  const groupBy = encodeGroupByPayload(data.dimensions, data.timeBreakdown)

  return {
    description: JSON.stringify({
      filters: {
        ...filtersRange,
        tags: encodeTagsPayload(data.filters)
      },
      timeZone: data.timezone,
      metrics,
      groupBy
    }),
    projection: [...groupBy, ...metrics],
    name: data.name,
    dateRange: isFixedDateRange ? null : dateRange,
    schedule: encodeSchedulePayload(data.schedule, data.emails, data.timezone)
  }
}

/**
 * @param {Object} report
 * @return {string}
 */
function decodeName (report) {
  return report && report.name
}

/**
 * @param {Object} report
 * @return {{ schedule: ScheduleUiFormat, emails: string[] }}
 */
function decodeSchedule (report) {
  let frequency = getItems('scheduleFrequency').find(sf => sf.key === 'OnDemand')
  let pickedDaysOfTheWeek = []
  let emails = []
  let startDate = null
  let endDate = null

  if (report && report.schedule) {
    const daysOfWeek = report.schedule.configuration.daysOfWeek || []

    if (daysOfWeek.length === 7) {
      frequency = getItems('scheduleFrequency').find(sf => sf.key === 'Daily')
    } else if (daysOfWeek.length < 7 && daysOfWeek.length >= 1) {
      frequency = getItems('scheduleFrequency').find(sf => sf.key === 'Weekly')
      pickedDaysOfTheWeek = getItems('weekDays').filter(wd => daysOfWeek.includes(wd.full))
    } else if (report.schedule.frequency === 'Monthly') {
      frequency = getItems('scheduleFrequency').find(sf => sf.key === 'Monthly')
    }

    emails = report.schedule.emails
    startDate = report.schedule.start.split('T')[0]
    if (report.schedule.end) endDate = report.schedule.end.split('T')[0]
  }
  return {
    schedule: {
      frequency,
      pickedDaysOfTheWeek,
      startDate,
      endDate
    },
    emails
  }
}

/**
 * @param {number} i An index [0-7] mapping to [Mon-Sun]
 */
function convertDayIndexToMomentIndex (i) {
  return i === 6 ? 0 : i + 1
}

/**
 * @param {ScheduleUiFormat} schedule
 */
function getNextRun (schedule, format = 'YYYY-MM-DD') {
  if (!schedule || schedule.frequency.key === 'OnDemand') return null

  let next
  const start = moment().isBefore(schedule.startDate)
    ? moment(schedule.startDate)
    : moment()

  if (schedule.frequency.key === 'Daily') next = start.add(moment().isBefore(schedule.startDate) ? 0 : 1, 'days')
  if (schedule.frequency.key === 'Monthly') next = start.add(1, 'M').startOf('month')
  if (schedule.frequency.key === 'Weekly') {
    const weekDays = getItems('weekDays')
    const startIndex = weekDays.findIndex(x => x.full === start.format('dddd'))
    const dayIndexes = schedule.pickedDaysOfTheWeek.map(d => weekDays.findIndex(x => x.full === d.full)).sort()
    const remainingDays = dayIndexes.filter(i => i > startIndex)
    next = remainingDays.length
      ? start.day(convertDayIndexToMomentIndex(remainingDays[0]))
      : start.day(convertDayIndexToMomentIndex(dayIndexes[0]) + 7)
  }

  if (!next) return null
  return !schedule.endDate || next.isBefore(schedule.endDate)
    ? next.format(format)
    : null
}

/**
 * @param {string | number} value
 * @return {{id: string, name: string, serviceId?: string}}
 */
function findInTreeList (value, list = [], keyName = 'id') {
  if (!list || !list.length) return null
  const el = list.find(x => x[keyName] === value)

  if (el) return el
  else {
    let match = null
    for (const g of list) {
      if (g.children && g.children.length) {
        const childMatched = findInTreeList(value, g.children, keyName)
        if (childMatched) {
          match = childMatched
          break
        }
      }
    }
    return match
  }
}

/**
 * @param {{Object}} report
 * @return {Promise{column: {id: string, name: string}, operator: string, value: string}[]}
 */
async function decodeTags (report) {
  if (!report || !report.description) return []
  const description = JSON.parse(report.description)
  if (!description.Parameters || !description.Parameters.Filters || !description.Parameters.Filters.Tags) return []
  const tags = description.Parameters.Filters.Tags || []

  return buildFiltersTags(tags)
}

async function buildFiltersTags (tags) {
  const filters = []
  for (const tag of tags) {
    const column = findInTreeList(tag.Key, getItems('filters'), 'serviceId')
    const operator = Object.keys(operatorMapping).find(o => operatorMapping[o] === tag.Operation)

    let value
    if (['contains', 'does not contain'].includes(operator)) value = tag.Values.length ? tag.Values[0].Value : null
    else {
      value = []
      const vals = tag.Values && tag.Values.length ? tag.Values : []
      for (const val of vals) {
        if (val.Id || val.Value) {
          const res = await reportsApi.getCampsiteResourceById(column.id, val.Value, filtersById.includes(column.serviceId))
          value.push(buildFilterLabel(res) || { name: val.Value, externalId: val.Value, label: val.Value })
        }
      }
    }

    filters.push({ column, operator, value })
  }
  return filters
}

/**
 * @return {{range: string, dates: string[], dynamicRange: string}}
 */
function decodeDateRangePayload (report) {
  if (!report) return null
  if (!report.dateRange) {
    const description = JSON.parse(report.description)
    if (!description.Parameters || !description.Parameters.Filters || !description.Parameters.Filters) return null
    const { From, To } = description.Parameters.Filters

    return {
      range: 'fixed',
      dates: [moment(From), moment(To)],
      dynamicRange: null
    }
  } else if (report.dateRange.duration && report.dateRange.unit && report.dateRange.strategy) {
    const dynamicRange = getItems('dynamicRangeOptions').find(x => _.isEqual(x.value, report.dateRange))
    return {
      range: 'dynamic',
      dates: [],
      dynamicRange: dynamicRange && dynamicRange.label
    }
  } else return null
}

/**
 * @return {{id: number, name: string}[]}
 */
function decodeMetricsPayload (report, allMetrics) {
  if (!report || !report.description) return []
  const description = JSON.parse(report.description)
  if (!description.Parameters || !description.Parameters.Metrics) return []
  const metrics = description.Parameters.Metrics

  return metrics.map(m => findInTreeList(m, allMetrics || getItems('metrics'), 'id')).filter(m => !!m)
}

/**
 * @return {{dimensions: {id: number, name: string}, timeBreakdown: {key: string, label: string}}}
 */
function decodeGroupByPayload (report, allDimensions) {
  allDimensions = allDimensions || getItems('dimensions')
  const timeBreakdowns = getItems('timeBreakdown')
  let timeBreakdown = timeBreakdowns.find(x => x.key === 'none')
  const emptyRes = { dimensions: [], timeBreakdown }

  if (!report || !report.description) return emptyRes
  const description = JSON.parse(report.description)
  if (!description.Parameters || !description.Parameters.GroupBy) return emptyRes
  const groupbys = description.Parameters.GroupBy

  const dateGroup = groupbys.find(x => x.includes('date:'))
  if (dateGroup) {
    const tb = dateGroup.split(':')[1]
    timeBreakdown = timeBreakdowns.find(x => x.secondaryKey === tb || x.key === tb)
  }

  return {
    dimensions: groupbys.filter(g => !g.includes('date:')).map(g => findInTreeList(g, allDimensions, 'id')).filter(g => g !== null),
    timeBreakdown
  }
}

/**
 * @param {Array} oldV
 * @param {Array} newV
 * @param {boolean} isEdit
 * @param {string} type
 * @return {{event: string, details: {label: string}}}
 */
function extractAnalyticsEventsForTree (oldV, newV, isEdit, type) {
  if (!oldV) oldV = []
  if (!newV) newV = []
  if (isEdit && !oldV.length && newV.length) return []
  const events = []

  const added = newV.filter(x => !oldV.map(y => y.id).includes(x.id))
  if (added.length) {
    events.push(...added.map(x => {
      return { event: 'customReportAdded' + type, details: { label: x.name } }
    }))
  }

  const deleted = oldV.filter(x => !newV.map(y => y.id).includes(x.id))
  if (deleted.length) {
    events.push(...deleted.map(x => {
      return { event: 'customReportDeleted' + type, details: { label: x.name } }
    }))
  }
  return events
}

/**
 * @param {{ frequency: {label: string}, startDate: string, endDate?: string, pickedDaysOfTheWeek: {full: string}[] }} oldV
 * @param {{ frequency: {label: string}, startDate: string, endDate?: string, pickedDaysOfTheWeek: {full: string}[] }} newV
 * @return {{event: string, details: {label: string}}}
 */
function extractAnalyticsEventsForSchedule (oldV, newV) {
  if (!oldV && newV) return []
  const events = []

  if (newV.frequency.label !== oldV.frequency.label) {
    events.push({ event: 'customReportSelectedScheduleFrequency', details: { label: `select option: ${newV.frequency.label}` } })
  }

  if (newV.startDate !== oldV.startDate) events.push({ event: 'customReportChangedScheduleStartDate', details: { label: newV.startDate } })
  if (newV.endDate !== oldV.endDate) events.push({ event: 'customReportChangedScheduleEndDate', details: { label: newV.endDate } })

  if (oldV.pickedDaysOfTheWeek.map(x => x.full).sort().join('-') !== newV.pickedDaysOfTheWeek.map(x => x.full).sort().join('-')) {
    events.push({ event: 'customReportChangedDaysOfTheWeekSelection', details: { label: newV.pickedDaysOfTheWeek.map(x => x.full).join(', ') } })
  }

  return events
}

/**
 * @param {string[]} oldV
 * @param {string[]} newV
 * @param {boolean} isEdit
 * @return {{event: string, details: {label: string}}}
 */
function extractAnalyticsEventsForEmails (oldV, newV) {
  if (!oldV) oldV = []
  if (!newV) newV = []
  const events = []

  const added = newV.filter(x => !oldV.includes(x))
  if (added.length) {
    events.push(...added.map(x => {
      return { event: 'customReportAddedEmail' }
    }))
  }

  const deleted = oldV.filter(x => !newV.includes(x))
  if (deleted.length) {
    events.push(...deleted.map(x => {
      return { event: 'customReportDeletedEmail' }
    }))
  }

  return events
}

/**
 * @param {{ value: {name: string}[] | string[], column: { name: string }, operator: string }} filter
 */
function extractFilterValue (filter) {
  return typeof filter.value === 'object'
    ? filter.value.map(x => x.externalId ? x.externalId : x.label).join(', ')
    : filter.value
}

/**
 * @param {{ value: {name: string}[] | string[], column: { name: string }, operator: string }} filter
 */
function extractFilterLabel (filter, valueOnly = false) {
  const val = extractFilterValue(filter)
  const operator = filter.operator === 'has any value' || (filter.operator === 'is none of' && val === '') ? 'has any value'
    : `${filter.operator}: ${val}`

  return valueOnly
    ? val
    : `${filter.column.name} ` + operator
}

/**
 * @param {string} key
 */
function getTemplate (key) {
  const baseTemplate = templates.find(t => t.key === key)
  if (!baseTemplate) return null

  const template = JSON.parse(JSON.stringify(baseTemplate))
  return decodeTemplate(template)
}

/**
 * @param {object} template
 */
function decodeTemplate (template) {
  template.timeBreakdown = getItems('timeBreakdown').find(t => t.key === template.timeBreakdown)
  template.dimensions = template.dimensions.map(d => findInTreeList(d, getItems('dimensions'), 'id'))
  template.metrics = template.metrics.map(m => findInTreeList(m, getItems('metrics'), 'id'))
  if (template.schedule) {
    template.schedule.startDate = moment().add(1, 'days').format('YYYY-MM-DD')
    template.schedule.frequency = getItems('scheduleFrequency').find(f => f.key === template.schedule.frequency)
    template.schedule.pickedDaysOfTheWeek = template.schedule.pickedDaysOfTheWeek.map(d => {
      return getItems('weekDays').find(wd => wd.key === d)
    })
  }

  return template
}

/**
 * @param {{ id: number, generatedOn: string, status: string }[]} exports
 */
function formatRuns (exports) {
  if (!exports) exports = []
  const augmented = exports.map(e => {
    const label = moment(e.generatedOn).format('MMM D, YYYY h:mm:ss A')
    const timestamp = moment(e.generatedOn).unix()
    return { ...e, label, timestamp }
  }).sort((a, b) => b.timestamp - a.timestamp)

  for (let i = 0; i < augmented.length; i++) {
    if (i === 0 && augmented[i].status === 'Done') augmented[i].label = augmented[i].label + ' (latest)'
    else {
      if (augmented[i].status !== 'Done') augmented[i].label = `${augmented[i].label} (${augmented[i].status.toLowerCase()})`
    }
  }
  return augmented
}

/**
 * @param {id: number} reportId
 * @param {{ id: number, generatedOn: string }[]} exports
 */
function extractLastRun (reportId, exports) {
  if (!exports || typeof exports !== 'object' || !exports.length) return { reportId, lastRun: null }
  const lastRun = exports.map(e => {
    return { ...e, timestamp: moment(e.generatedOn).unix() }
  }).sort((a, b) => b.timestamp - a.timestamp)[0]
  return { reportId, lastRun }
}

/**
 * @param {{duration: number, unit: string, strategy: string}} definition
 */
function extractDatesFromDynamicDateRange (definition) {
  let start, end
  if (definition.strategy === 'to-date') {
    end = moment()
    start = moment().startOf(definition.unit)
  } else {
    if (definition.unit === 'day') {
      end = moment().subtract(1, 'days')
      start = moment().subtract(definition.duration, 'days')
    } else {
      end = moment().subtract(1, definition.unit + 's').endOf(definition.unit)
      start = moment().subtract(1, definition.unit + 's').subtract(definition.duration - 1, definition.unit + 's').startOf(definition.unit)
    }
  }

  return [start, end].map(d => d.format(dateFormat))
}

function generateDateRangeLabel (report) {
  const dateRange = decodeDateRangePayload(report)

  if (report.dateRange) {
    return { type: dateRange.dynamicRange, dates: convertDatesToLabel(extractDatesFromDynamicDateRange(report.dateRange)) }
  } else {
    return { type: 'fixed', dates: convertDatesToLabel(dateRange.dates.map(m => m.format(dateFormat))) }
  }
}

function convertDatesToLabel (dates) {
  if (!dates || dates.length !== 2) return null
  if (dates[0] === dates[1]) return dates[0]
  else return dates.join(' - ')
}

/**
 * @param {{name: string, id: number, externalId?: string}} filter
 */
function buildFilterLabel (filter) {
  if (!filter?.name || !filter?.key) return filter
  var keyValue = filter.externalId || filter.key
  return {
    ...filter,
    label: `${filter.name + (keyValue === '0' ? '' : ' ' + '(' + (keyValue) + ')')}`
  }
}

export default {
  getItems,
  getPartialItems,
  getAllMetrics,
  getAllDimensions,
  renameTreeItem,

  encodeDateRangePayload,
  encodeSchedulePayload,
  encodeGroupByPayload,
  encodeMetricsPayload,
  encodeTagsPayload,
  bundleReportPayload,

  decodeName,
  decodeSchedule,
  decodeTags,
  buildFiltersTags,
  decodeDateRangePayload,
  decodeMetricsPayload,
  decodeGroupByPayload,

  getNextRun,
  findInTreeList,

  convertDayIndexToMomentIndex,

  extractAnalyticsEventsForTree,
  extractAnalyticsEventsForSchedule,
  extractAnalyticsEventsForEmails,

  extractFilterLabel,
  extractFilterValue,
  getTemplate,
  decodeTemplate,
  formatRuns,
  extractLastRun,
  extractDatesFromDynamicDateRange,
  generateDateRangeLabel,
  convertDatesToLabel,
  buildFilterLabel
}

/**
 * @typedef {Object} ScheduleUiFormat
 * @property {string} startDate
 * @property {string} [endDate]
 * @property {{key: string, label: string}} frequency
 * @property {{full: string, key: string, label: string}[]} pickedDaysOfTheWeek
 */
