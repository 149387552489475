import userPermissions from '@/services/user.permissions'
import userApi from '@/services/user.api'

function buildUtmList (routeQuery) {
  const fieldsFull = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term']
  const list = []
  if (routeQuery && routeQuery.utm_source) {
    fieldsFull.forEach(field => {
      if (routeQuery[field]) {
        list.push({ name: field, value: routeQuery[field] })
      }
    })
  }
  return list
}

function formatDataForHubspot (data) {
  const { user, orgInfo, allowCommercialCommunication, gclid, routeQuery } = data
  const keys = ['firstName', 'lastName', 'email']
  const formattedUser = []
  keys.forEach(key => {
    formattedUser.push({ name: key.toLowerCase(), value: user[key] })
  })

  formattedUser.push({ name: 'consent_to_privacy_policy', value: allowCommercialCommunication })
  formattedUser.push({ name: 'company', value: orgInfo.name })
  if (gclid) formattedUser.push({ name: 'gclid', value: gclid })
  const utmList = buildUtmList(routeQuery)
  if (utmList && utmList.length) { formattedUser.push(...utmList) }
  return formattedUser
}

function getGclidFromCookies (cookies) {
  if (!cookies) return null
  const gclidCookie = cookies.split('; ').find(x => x.includes('gclid='))
  return gclidCookie ? gclidCookie.split('=')[1] : null
}

/**
 * @param {string} cookies
 * @return {string | null}
 */
function getUtkFromCookies (cookies) {
  if (!cookies) return null
  const hubspotutkFromCookie = cookies.split(';').find(x => x.includes('hubspotutk'))
  return hubspotutkFromCookie ? hubspotutkFromCookie.split('=')[1] : null
}

/**
 * @param {{ user: any, orgInfo: any, allowCommercialCommunication: boolean, routeQuery: any }} data
 * @param {string} cookies
 */
function createNewHubspotContact (data, cookies) {
  const gclid = getGclidFromCookies(cookies)
  const contactObj = formatDataForHubspot({ ...data, gclid })

  return userApi.hubspotNewContact(contactObj, getUtkFromCookies(cookies))
}

function getOrganizationSettingsSection (entries, routeHash, initialScrollDone) {
  if (entries && entries.length && entries[0].isIntersecting) {
    const obj = { visibleSection: entries[0].target.id }
    if (routeHash && !initialScrollDone) {
      obj.updateUrlHash = true
    }
    return obj
  } else return null
}

function organizationSettingsScrollToSection (destination, currentSection, routeHash) {
  const goTo = destination || currentSection
  return routeHash !== `#${goTo}` ? goTo : null
}

function extractUserOrgId (routeParams, org) {
  const orgId1 = routeParams ? parseInt(routeParams.organizationId) : null
  const orgId2 = org ? org.id : null
  return orgId1 || orgId2
}

/**
 * @param {string[]} roles
 * @param {string} resource
 */
function getPermissions (roles, resource) {
  if (!roles) roles = []

  const dspRolesConfigured = [
    'GlobalAdmin',
    'GlobalReadOnly',
    'Owner',
    'Administrator',
    'Manager',
    'ProposalScheduler',
    'Reporter'
  ]
  let dspRoles = roles.filter(x => dspRolesConfigured.includes(x))
  if (!dspRoles.length) {
    dspRoles = ['default']
  }

  dspRoles = dspRoles.slice().sort((a, b) => dspRolesConfigured.indexOf(a) - dspRolesConfigured.indexOf(b))
  const perms = userPermissions.find(x => x.key === dspRoles[0].toLowerCase())
  return perms.permissions[resource]
}

export default {
  buildUtmList,
  formatDataForHubspot,
  getGclidFromCookies,
  getUtkFromCookies,
  createNewHubspotContact,
  getOrganizationSettingsSection,
  organizationSettingsScrollToSection,
  extractUserOrgId,
  getPermissions
}
