import store from '@/stores/auth.store.js'

export default {
  setAuthProvider,
  isAdServerDomain
}

function setAuthProvider () {
  if (isAdServerDomain()) {
    store.mutations.setProvider(store.state, 'auth0')
  }
}

function isAdServerDomain () {
  return getFirstHostPart() === 'campaigns'
}

function getFirstHostPart () {
  const hostname = window.location.hostname
  const hostParts = hostname.split('.')

  return hostParts[0]
}
