import axios from 'axios'
import campsiteConfig from '@/config/campsite.config'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  getVenue,
  getVenueImage,
  getVenueDetails,
  getVenuesByIds,
  getScreen,
  getScreens
}

function getVenue (venueId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/venues/' + venueId
  return axios.get(endpoint).then(response => response.data)
}

function getVenueImage (venueId, size = null) {
  if (!size) {
    size = campsiteConfig.venuePopover.width + 'x' + campsiteConfig.venuePopover.height
  }

  var endpoint = VUE_APP_CAMPSITE_API_URL + '/venues/' + venueId + '/img/' + size + '?policy=cover'

  return axios({
    method: 'get',
    url: endpoint,
    responseType: 'arraybuffer'
  })
    .then(response => {
      // Decode image as array buffer
      var bytes = new Uint8Array(response.data)
      var binary = bytes.reduce((data, b) => {
        data += String.fromCharCode(b)
        return data
      }, '')
      return 'data:image/jpeg;base64,' + btoa(binary)
    })
}

function getVenueDetails (venueId) {
  return getVenue(venueId).then(venue => {
    return getVenueImage(venueId)
      .then(image => {
        venue.image = image
        return venue
      })
      .catch(() => {
        venue.image = null
        return venue
      })
  })
}

function getVenuesByIds (venueIds = []) {
  var baseURL = VUE_APP_CAMPSITE_API_URL + '/venues?$select=id,city,network'

  const chunkedVenueIds = chunkArray(venueIds, 10)

  var venuesPromises = chunkedVenueIds.map(chunk => {
    var endpoint = baseURL + '&$filter=' + chunk.map(c => 'id eq ' + c).join(' or ')
    return axios.get(endpoint).then(response => response.data)
  })

  return Promise.all(venuesPromises)
    .then(venues => {
      return venues.reduce((a, b) => {
        return a.concat(b)
      }, [])
    })
}

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
function chunkArray (myArray, chunkSize) {
  var results = []

  while (myArray.length) {
    results.push(myArray.splice(0, chunkSize))
  }

  return results
}

function getScreen (venueId, screenId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/venues/' + venueId + '/faces/' + screenId + '?$expand=taxonomy'
  return axios.get(endpoint).then(response => response.data)
}

function getScreens (faceIds) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/faces?$filter=' + faceIds.map(f => `id eq ${f}`).join(' or ')
  return axios.get(endpoint).then(response => response.data)
}
