<template lang="pug">
div
  vue-headful(:title="componentConfig.branding.title(title)")
  v-container(fluid grid-list-lg text-xs-left)
    v-layout(justify-center row fill-height)
      v-flex(xs12 md8)
        v-card(light height='100%' :loading='supportLoading')
          v-card-title {{ title }}
          v-divider
          v-card-text
            div(v-html='pageHTML')

    footerApp(v-if="!isLoggedIn")
</template>

<script>
import supportAPI from '@/services/support.api.js'
import footerApp from '@/components/footerApp.vue'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    footerApp
  },
  created: function () {
    this.title = this.$route.name

    supportAPI.getLegalTerms()
      .then(terms => {
        this.pageHTML = terms.body
        this.supportLoading = false
      })
  },
  data () {
    return {
      pageHTML: '',
      supportLoading: true,
      title: ''
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters['auth/isLoggedIn']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  }
}
</script>
