<template lang='pug'>
  v-card.my-6(id='theme')
    v-card-title.text-h5 Theme
    v-card-text.text--primary
      .text-body-1 Customize Broadsign Ads user interface to match your organization branding.
      .text-caption.font-weight-bold Settings are applied globally to all users.

      v-row.my-0
        v-col
          v-row.my-0
            v-col(xs='12' md='4')
              .text-h6 Logo
              .caption.grey--text Shows up in the main navigation bar
              v-file-input(small-chips label="Upload your logo" accept="image/*" single-line hide-details v-model='logo')
            v-col(xs='12' md='4')
              .text-h6 Brand Color
              .caption.grey--text Affects buttons, line chart, map, etc
              v-menu(:close-on-content-click='false' offset-y max-width='300' v-model='isColorPickerOpen')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field#brand-color-input(placeholder="Brand Color" v-bind='attrs' v-on='on' :value='previewColor' @input='updatePreviewColor' single-line hide-details)
                    template(v-slot:prepend-inner='')
                      v-icon(:color='previewColor') mdi-circle
                v-color-picker(:value='previewColor' @input='updatePreviewColor' hide-inputs)

      v-card.px-4.pb-2.align-center.justify-center(outlined)
        v-row.my-0.align-center.justify-center
          v-col(cols=12 md=6)
            .overline.text-center Report Preview
            div.mt-2.elevation-2#preview
              v-app-bar.v-bar--underline(color='#FFFFFF' dense flat)
                img.cs-logo(v-if="!previewLogo" src='@/assets/logo-mobile.svg' height='29' width='42')
                img.custom-logo(v-else :src="previewLogo" height='27')
                v-spacer
              div.pa-4(style='background-color:#f8f9fa;')
                v-skeleton-loader.mb-1(type='text' boilerplate width='80')
                v-skeleton-loader.ske-heading(type='heading' boilerplate  width='100%')
              v-divider
              v-card.my-4(tile flat)
                v-sparkline#line-chart(:value='sparklinePoints' :color='previewColor' stroke-linecap="round" smooth )
              v-divider
              div.px-4.py-2(style='background-color:#f8f9fa;')
                .d-flex.mb-4
                  v-card.mr-1(dark :color='previewColor' height='40' width='25%')
                  v-card.mx-1(dark flat color='grey lighten-2' height='40' width='25%')
                  v-card.mx-1(dark flat color='grey lighten-2' height='40' width='25%')
                  v-card.ml-1(dark flat color='grey lighten-2' height='40' width='25%')

                v-skeleton-loader.ske-heading.mb-2(type='paragraph' boilerplate  width='100%')

          v-col(cols=12 md=6)
            .overline.text-center Proposal Preview
            div.mt-2.elevation-2#preview
              v-app-bar.v-bar--underline(color='#FFFFFF' dense flat)
                img(v-if="!previewLogo" src='@/assets/logo-mobile.svg' height='29' width='42')
                img(v-else :src="previewLogo" height='27')
                v-spacer
              div.bgcolor.px-4.py-6
                v-card(dark flat color='rgba(0, 0, 0, .2)' height='100%')
                  v-card-text.white--text
                    v-skeleton-loader.ske-white.mb-1(type='text' boilerplate width='80')
                    v-skeleton-loader.ske-white(type='heading' boilerplate  width='100%')

              div.pa-4(style='background-color:#f8f9fa;')
                v-card.mb-2
                  v-card-text
                    v-skeleton-loader.ske-heading(type='heading' boilerplate  width='100%')
                v-card.mb-4
                  v-card-text
                    v-skeleton-loader.ske-heading(type='heading' boilerplate  width='100%')

                v-card.ske-button(style='border-radius: 60px;' :color='previewColor' height=45)
    v-card-actions
      v-spacer
      v-btn#reset-btn(text :loading='resetting' @click='resetTheme') Reset to default
      v-btn#save-btn(text color='primary' :loading='saving' @click='saveChanges') Save Changes
</template>

<script>
import _ from 'lodash'
import tracking from '@/services/tracking'

export default {
  props: ['organizationId'],
  data () {
    return {
      sparklinePoints: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      saving: false,
      resetting: false,
      isColorPickerOpen: false,
      logo: null
    }
  },
  computed: {
    isManagedService () {
      const permissions = this.$store.getters['user/permissions']('organization')
      const canEditAnyOrg = permissions.update.default && permissions.read.allOrgs
      const isClientOrg = this.$route.params.organizationId && this.$store.getters['user/getProfile'].organizationId !== parseInt(this.$route.params.organizationId)
      return canEditAnyOrg && isClientOrg
    },
    defaultColor () {
      return this.$vuetify.theme.themes.light.csblue.toLowerCase()
    },
    previewColor () {
      return this.$vuetify.theme.themes.light.preview.toLowerCase()
    },
    noNewColor () {
      return this.defaultColor === this.previewColor
    },
    previewLogo () {
      return this.logo ? URL.createObjectURL(this.logo) : null
    }
  },
  watch: {
    isColorPickerOpen (newV) {
      if (newV) tracking.sendEvent(['ga'], 'openedColorPicker')
    }
  },
  async created () {
    let theme
    if (this.isManagedService) {
      theme = await this.$store.dispatch('general/fetchTheme', { orgId: this.organizationId, isPreviewOnly: true })
    } else {
      theme = this.$store.getters['general/fullTheme']
    }

    this.$vuetify.theme.themes.light.preview = theme && theme.PrimaryColour ? theme.PrimaryColour : this.defaultColor
    if (theme && theme.logo) this.logo = new File([theme.logo], 'logo')
  },
  methods: {
    updatePreviewColor: _.debounce(function (color) {
      this.$vuetify.theme.themes.light.preview = color
      tracking.sendEvent(['ga'], 'changeThemeColor', { label: color })
    }, 250),
    saveChanges () {
      this.saving = true

      this.$store.dispatch('general/saveTheme', {
        orgId: this.organizationId,
        isPreviewOnly: this.isManagedService,
        theme: this.noNewColor ? null : { PrimaryColour: this.previewColor },
        logo: this.logo
      }).then(() => {
        if (!this.isManagedService) this.$vuetify.theme.themes.light.primary = this.previewColor
        this.saving = false
        tracking.sendEvent(['ga'], 'savedTheme')
        if (this.logo) tracking.sendEvent(['ga'], 'uploadedThemeLogo')
        this.$store.commit('snackbar/setSnackbar', { type: 'success', msg: 'Theme successfully updated' })
      })
    },
    resetTheme () {
      this.resetting = true
      this.$store.dispatch('general/deleteTheme', { orgId: this.organizationId, isPreviewOnly: this.isManagedService })
        .then(() => {
          if (!this.isManagedService) this.$vuetify.theme.themes.light.primary = this.defaultColor
          this.$vuetify.theme.themes.light.preview = this.defaultColor
          this.logo = null
          tracking.sendEvent(['ga'], 'resetTheme')
          this.$store.commit('snackbar/setSnackbar', { type: 'success', msg: 'Theme successfully reset' })
        })
        .finally(() => {
          this.resetting = false
        })
    }
  }
}
</script>

<style scoped>
  .v-bar--underline{
    border-width: 0 0 thin;
    border-style: solid;
    border-bottom-color: rgba(0,0,0,.12)!important;
  }
  .bgcolor {
    background: linear-gradient(45deg, var(--v-preview-darken1) 10%, var(--v-preview-lighten1) 96%);
  }
  #preview >>> .ske-white .v-skeleton-loader__bone{
    background: rgba(255, 255, 255, 0.85);
    width: 100%;
  }
  #preview .ske-heading  >>>  .v-skeleton-loader__bone{
    width: 100%;
  }
  #preview .ske-button {
    cursor: initial;
  }
</style>
