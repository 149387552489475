<template lang="pug">
  v-dialog(v-model='isDialogOpen' max-width='500' style='height: inherit;')
    template(v-slot:activator='{on}')
      slot(name='customActivator')
    v-card
      v-card-title.text-h5.dialog-title Edit {{ campaignType }}
      v-card-text(v-if='isDialogOpen')
        campaign-form(
          :campaign-type='campaignType'
          :campaign-id='campaign.id'
          :campaign-name='campaign.name'
          :campaign-external-id='campaign.externalId'
          :advertisers='advertisers'
          :advertiser-id='campaign.accountId'
          @inputMissing = 'setFormValidationState'
        )
      v-divider
      v-card-actions
        v-spacer
        v-btn(color='secondary' text @click='closeDialog') Cancel
        v-btn.save-btn(text color='primary' :loading='saving' :disabled='!editCampaignFormValid' @click='save') save
</template>

<script>
import campaignsApi from '@/services/campaigns.api'
import helpers from '@/services/helpers.service'
import tracking from '@/services/tracking'

import campaignForm from '@/components/campaignForm.vue'

export default {
  components: {
    campaignForm
  },
  props: ['campaign'],
  data () {
    return {
      isDialogOpen: false,
      editCampaignFormValid: false,
      saving: false,
      advertisers: [],
      advertiser: { id: null, name: '' },
      advertiserExternalId: '',
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  mounted () {
    this.$root.$on('openEditCampaignDialog', () => {
      this.isDialogOpen = true
      tracking.sendEvent(['ga'], 'openedEditProposalDialog')
    })
  },
  watch: {
    isDialogOpen (newV) {
      if (newV) {
        this.getOrganizationAdvertisers(this.campaign.buyerId)
          .then(advertisers => {
            this.advertisers = advertisers.sort(helpers.sortByName)
          })
      }
    }
  },
  computed: {
    isProposal () {
      return this.campaign && this.campaign.status === 'Proposal'
    },
    proposalLabel  () {
      return this.$flags.canSeeUiRebrand.isEnabled() ? 'Plan' : 'Proposal'
    },
    campaignType () {
      return this.isProposal ? this.proposalLabel : 'Campaign'
    }
  },
  methods: {
    setFormValidationState (inputMissing) {
      this.editCampaignFormValid = !inputMissing
    },
    closeDialog () {
      this.isDialogOpen = false
      this.saving = false
    },
    getOrganizationAdvertisers (organizationId) {
      return campaignsApi.getAdvertisers(organizationId)
        .then(res => res.data)
    },
    save () {
      this.saving = true

      const data = {}

      if (this.name !== this.campaign.name) {
        data.name = this.name
      }

      const newName = this.$store.getters['createCampaign/getCampaignName']
      if (newName !== this.campaign.name) {
        data.name = newName
      }

      const newExternalId = this.$store.getters['createCampaign/getCampaignExternalId']
      if (newExternalId !== this.campaign.externalId) {
        data.externalId = newExternalId
      }

      this.$store.dispatch('createCampaign/handleAdvertiser')
        .then(advertiser => {
          data.accountId = advertiser.id

          this.$emit('edited', data)
          this.closeDialog()
        })
    }
  }
}
</script>
