import reportsApi from '@/services/reports.api'
import reportService from '@/services/reports'

export default {
  namespaced: true,
  state: {
    fetchingReports: false,
    fetchingReport: false,
    fetchingRuns: false,
    savingReport: false,
    reportRuns: { id: null, runs: [] },
    reports: [],
    metricsRef: [],
    dimensionsRef: []
  },
  getters: {
    formattedReports: state => {
      return state.reports
    },
    csAuthToken: (state, getters, rootState, rootGetters) => {
      return rootGetters['auth/idToken']
    },
    metrics: state => {
      return reportService.getAllMetrics(state.metricsRef) || []
    },
    dimensions: state => {
      return reportService.getAllDimensions(state.dimensionsRef) || []
    }
  },
  mutations: {
    toggleFetchingReports: (state, bool) => {
      state.fetchingReports = bool
    },
    toggleFetchingReport: (state, bool) => {
      state.fetchingReport = bool
    },
    toggleFetchingRuns: (state, bool) => {
      state.fetchingRuns = bool
    },
    toggleSavingReport: (state, bool) => {
      state.savingReport = bool
    },
    addReport: (state, newReport) => {
      state.reports = [...state.reports, newReport]
    },
    setReports: (state, reports) => {
      state.reports = reports
    },
    updateReport: (state, report) => {
      const copy = [...state.reports]
      const idx = copy.findIndex(x => x.id === report.id)
      if (idx >= 0) copy[idx] = report
      state.reports = copy
    },
    saveRuns: (state, runs) => {
      state.reportRuns = { ...runs }
    },
    clearReport: (state) => {
      if (state.reports.length === 1) {
        state.reports = []
      }
    },
    saveMetrics: (state, metricsRef) => {
      state.metricsRef = metricsRef
    },
    saveDimensions: (state, dimensionsRef) => {
      state.dimensionsRef = dimensionsRef
    },
    removeReport: (state, reportId) => {
      state.reports = state.reports.filter(r => r.id !== reportId)
    }
  },
  actions: {
    getReports ({ state, commit, getters }) {
      if (!state.reports.length) {
        commit('toggleFetchingReports', true)
        return reportsApi.getReports(getters.csAuthToken)
          .then(res => {
            commit('setReports', res)
            return res
          })
          .finally(() => {
            commit('toggleFetchingReports', false)
          })
      }
    },
    getReport ({ state, commit, getters }, id) {
      commit('toggleFetchingReport', true)
      return reportsApi.getReport(id, getters.csAuthToken)
        .then(res => {
          if (state.reports.findIndex(x => x.id === res.data.id) < 0) { commit('addReport', res.data) }
        })
        .finally(() => {
          commit('toggleFetchingReport', false)
        })
    },
    getRuns ({ commit, getters }, id) {
      commit('toggleFetchingRuns', true)
      return reportsApi.getReportRuns(id, getters.csAuthToken)
        .then(res => {
          commit('saveRuns', { id, runs: reportService.formatRuns(res) })
        }).finally(() => {
          commit('toggleFetchingRuns', false)
        })
    },
    getRunFile ({ commit, getters }, ids) {
      const { reportId, runId } = ids
      return reportsApi.getRunFile(reportId, runId, getters.csAuthToken)
        .then(res => res)
        .catch(() => {
          commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Cannot download the CSV file'
          }, { root: true })
        })
    },
    getRunData ({ getters }, options) {
      return reportsApi.getRunData(options, getters.csAuthToken)
    },
    createReport ({ commit, getters }, payload) {
      commit('toggleSavingReport', true)
      return reportsApi.createReport(payload, getters.csAuthToken)
        .then(res => {
          commit('addReport', res.data)
          commit('snackbar/setSnackbar', {
            type: 'success',
            msg: 'Report successfully created'
          }, { root: true })
          return res.data
        })
        .catch((err) => {
          commit('snackbar/setSnackbar', {
            type: 'error',
            msg: (err && err.response && err.response.data && err.response.data.message) || 'Unable to create this report. Please try again'
          }, { root: true })
        })
        .finally(() => {
          commit('toggleSavingReport', false)
        })
    },
    updateReport ({ commit, getters }, obj) {
      const { payload, id } = obj
      commit('toggleSavingReport', true)
      return reportsApi.updateReport(id, payload, getters.csAuthToken)
        .then(res => {
          commit('updateReport', res.data)
          commit('snackbar/setSnackbar', {
            type: 'success',
            msg: 'Report successfully updated'
          }, { root: true })
          return res
        })
        .catch(() => {
          commit('snackbar/setSnackbar', {
            type: 'error',
            msg: 'Unable to update this report. Please try again'
          }, { root: true })
        })
        .finally(() => {
          commit('toggleSavingReport', false)
        })
    },
    async getMetricsAndDimensions ({ state, commit, getters }) {
      let metricsRef, dimensionsRef
      try {
        if (!state.metricsRef.length) {
          metricsRef = await reportsApi.getMetrics(getters.csAuthToken)
          if (metricsRef) commit('saveMetrics', metricsRef.result)
        }
        if (!state.dimensionsRef.length) {
          dimensionsRef = await reportsApi.getDimensions(getters.csAuthToken)
          if (dimensionsRef) commit('saveDimensions', dimensionsRef.result)
        }
      } catch {}
    },
    generateReport ({ getters }, reportId) {
      return reportsApi.generateReport(reportId, getters.csAuthToken)
    },
    deleteReport ({ state, getters, commit }, reportId) {
      return reportsApi.deleteReport(reportId, getters.csAuthToken).then(() => {
        commit('removeReport', reportId)
      })
    }
  }
}

// // TEMP SCRIPT (assumes metrics & dimensions have been fetched already)
// // 1. Add => import fr from '@/services/fixReports'
// // 2. Insert in getReports action right before returning "res"

// const toUpdate = await fr.fixReports(res, getters.metrics, getters.dimensions)
// console.log(toUpdate.length)
// for (const r of toUpdate) {
//   await dispatch('updateReport', r)
//   const newR = await reportsApi.getReport(r.id, getters.csAuthToken)
//   const oldR = res.find(rep => rep.id === r.id)
//   const preserved = fr.compareReports(oldR, newR.data)
//   if (!preserved) {
//     console.log('ATTENTION BROKEN REPORT')
//     console.log(oldR)
//     console.log(newR.data)
//   }
//   console.log(`updated report with ID: ${r.id}`)
// }

// // ====================================================================
