export default {
  combineGeoTargets,
  filterGeoTargetsByType,
  getOriginalGeosMatchingGeosWithinViewPort
}

function getOriginalGeosMatchingGeosWithinViewPort (originalGeoTargets, loadedGeosIds) {
  return originalGeoTargets.filter((geo) => loadedGeosIds.has(geo.index))
}

function filterGeoTargetsByType (originalGeoTargets, types, match = true) {
  return originalGeoTargets.filter(obj => match ? types.includes(obj.type) : !types.includes(obj.type))
}

function combineGeoTargets (...geoTargets) {
  return [].concat(...geoTargets)
}
