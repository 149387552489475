<template lang="pug">
div
  //-v-row.my-0.align-center
    v-col.text-center.pt-8(cols='12')
      v-item-group.d-flex.justify-center(v-model='type')
        v-item(style='width:50%; border-top-right-radius:0px; border-bottom-right-radius:0px;')
          v-card.text-center.d-flex.align-center.justify-center(
            outlined
            :dark="marketplace==='Public'"
            :color="marketplace==='Public'? 'primary' : 'unset'"
            @click='marketplace="Public"'
            max-width=180
            )
            v-icon.mr-2() mdi-earth
            .body-1.my-2 Explore
        v-item(style='width:50%; border-top-left-radius:0px; border-bottom-left-radius:0px;')
          v-card.text-center.d-flex.align-center.justify-center(
            outlined
            :dark="marketplace==='Private'"
            :color="marketplace==='Private'? 'primary' : 'unset'"
            @click='marketplace="Private"'
            max-width=180
            )
            v-icon.mr-2() mdi-tag
            .body-1.my-2 Private Deals

  v-card.pt-3(color="transparent" flat)
    vue-headful(:title="componentConfig.branding.title((canSeeNewUi ? 'Deals' : 'Markeplace') + ' Overview')")
    dealsOverviewScreen(:isOverview='true')
</template>

<script>

import dealsOverviewScreen from '@/components/dealsOverviewScreen.vue'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    dealsOverviewScreen
  },
  data () {
    return {
      canSeeNewUi: false
    }
  },
  created () {
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
  },
  computed: {
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  }
}
</script>
