<template lang='pug'>
  div(style='color: rgba(0, 0, 0, 0.87)')
    v-simple-table
      thead
        tr
          th Deal ID
          th Publisher
          th Deal Name
          th Price
          th
      tbody
        tr(v-if='dealsLoading')
          td(colspan='100%' align='center')
            span Loading deals...

        tr(v-if='!dealsLoading && !deals.length')
          td(colspan='100%' align='center') No deals found

        tr(v-for='deal in deals' @mouseover='dealRowHover(deal)' @mouseleave='dealRowLeave(deal)')
          td
            v-chip(label small)
              v-icon.mr-1(small :color='deal.status === "Active" ? "primary" : "grey"') mdi-tag
              strong {{ deal.code }}

          td(v-if='!deal.source' colspan='2')
            .grey--text Deal not recognized

          td.dealKey(v-if='deal.source')
            | {{ deal.source.key }}

          td.dealName(v-if='deal.source')
            | {{ deal.name }}
            .text-caption.grey--text {{ deal.startDate | date }} - {{ deal.endDate | date }}
          td.dealFloorCpm
            span(v-if='deal.floorCPM') {{ deal.floorCPM | currency }} CPM
            .text-caption.grey--text(v-if='deal.auctionType') {{ deal.auctionType.replace('_', ' ') }}
          td.text-right
            v-btn.remove-deal(v-if='parent==="editLine"' icon small @click='dealRemoved(deal)')
              v-icon(v-if='isDealHovered(deal)') mdi-close
</template>

<script>

export default {
  props: {
    deals: {
      type: Array,
      default: () => []
    },
    dealsLoading: {
      type: Boolean,
      default: () => true
    },
    parent: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      dealHovered: null
    }
  },
  methods: {
    dealRowHover (deal) {
      this.dealHovered = deal.code
    },
    dealRowLeave (deal) {
      this.dealHovered = null
    },
    isDealHovered (deal) {
      return this.dealHovered === deal.code
    },
    dealRemoved (deal) {
      this.$emit('dealRemoved', deal)
    }
  }
}
</script>
