import geoService from '@/services/geo.service'

export default {
  formatCurrentGeoTargets,
  setGeoJsonOnGeosWithinViewPort,
  getGeoTargetCopyWithIndex,
  getLoadedGeosIds
}

function formatCurrentGeoTargets (geos) {
  return geos
    .map((geo) => {
      const newGeo = {
        id: geo.index,
        index: geo.index,
        lat: geo.geography?.latitude,
        long: geo.geography?.longitude,
        value: geo.value,
        label: geo.label,
        radius: geoService.radiusFormatting(geo.radius, 'toNumber')
      }
      if (geo.geoJSONFileUrl) {
        newGeo.geoJSONFileUrl = geo.geoJSONFileUrl
      } else {
        newGeo.geoJSON = geo.geography.geoJSON
      }
      return newGeo
    })
}

function setGeoJsonOnGeosWithinViewPort (originalGeosToShow, loadedGeos) {
  originalGeosToShow.forEach(geo => {
    const jsonObject = loadedGeos.find(x => x.value === geo.value && x.label === geo.label)
    if (jsonObject) {
      geo.isOutsideOfViewPort = false
    } else {
      geo.isOutsideOfViewPort = true
    }

    if (jsonObject?.geoJson) {
      const geoJsonString = typeof jsonObject.geoJson === 'string'
        ? jsonObject.geoJson
        : JSON.stringify(jsonObject.geoJson.geometry || jsonObject.geoJson)
      if (geo.geography) {
        geo.geography.geoJSON = geoJsonString
      } else {
        geo.geoJSON = geoJsonString
      }
    }
  })
}

function getGeoTargetCopyWithIndex (originalGeoTargets) {
  return JSON.parse(JSON.stringify(originalGeoTargets)).map((g, index) => ({ ...g, index }))
}

function getLoadedGeosIds (loadedGeos) {
  return new Set(loadedGeos.map(geo => geo.index))
}
