<template lang="pug">
div
  .pb-0.creatives-table-title(v-if='showTitle')
    .text-body-1.font-weight-bold Assigned Creatives

  v-data-table.tablecreatives(
    ref='creativesTableRef'
    v-if='showTable'
    :headers='headers'
    :server-items-length='totalItems'
    :items='assignedCreatives'
    :footer-props='rowsPerPageItems'
    :options.sync='pagination'
    :hide-default-footer='fullTable'
    :hide-default-header='$vuetify.breakpoint.xsOnly'
    no-data-text='No creative assigned'
  )

    //- Mobile
    template(v-slot:item='{ item }' v-if='$vuetify.breakpoint.xsOnly')
      v-card.ma-3.px-3.py-1
        v-layout.align-center
          v-flex.text-left.d-flex.pb-0(xs8)
          v-flex.text-right.pb-0(xs4 v-if='!isParentViewAuctionPackage')
            //- Action
            v-tooltip(top dark color='secondary' v-if='item.id')
              template(v-slot:activator='{ on }')
                v-btn.mr-0(icon small text color='error' v-on='on' @click='removeCreative(item)')
                  v-icon(color='grey') mdi-delete
              span Remove this creative
            v-tooltip(top dark color='secondary' v-if='!item.id')
              template(v-slot:activator='{ on }')
                v-btn.mr-0(icon text small color='error' v-on='on' @click='stopUpload(item)')
                  v-icon(color='grey') mdi-close-circle
              span Stop Upload
        v-layout.align-start
          v-flex(xs4)
            creativeImage(:creative='item' :parent='parent')
          v-flex.pl-3(xs8)
            //- Title
            div(v-if='isParentCreativesHover || isParentViewAuctionPackage' style='word-break: break-word;') {{ item.name }}
            creative-name-edit(v-else :creative='item' @creativeNameUpdated='creativeNameUpdated')

            v-progress-linear.loadprogress.my-1(
              v-if='item.progress && !item.error'
              :value='item.progress'
              :indeterminate='isProgressIndeterminate(item.progress)'
              height='8'
              color='primary'
              :query='true'
            )

            .d-flex.mt-2(v-if='item.error')
              //- v-icon(color='error') mdi-alert
              span.text-caption.pl-2.error--text
                | {{ item.error }}
                br
                | Won't be submitted for approval

            //- Info
            div
              v-layout(row wrap)
                v-flex(xs12)
                  .d-flex
                    v-chip.mr-2.creative-format(small v-if='item.creativeFormat')
                      .text-caption {{ item.creativeFormat.minWidth }} x {{ item.creativeFormat.minHeight }}

                    v-chip.mr-2.creative-duration(small v-if='item.type === "Video"')
                      v-icon(small) mdi-clock-outline
                      .text-caption {{ durationDisplay(item) }}

    //- Desktop
    template(v-slot:no-data='')
      .text-center.pa-3(colspan='100%') No creative added

    template(v-slot:item.organization='{ item }')
      div {{ item.buyer.name }}

    template(v-slot:item.preview='{ item }')
      creativeImage(:creative='item' :parent='parent')

    template(v-slot:item.name='{ item }')
      .ml-0(v-if='isParentCreativesHover || isParentViewAuctionPackage' style='word-break: break-word;') {{ item.name }}
      .d-flex(v-else)
        v-icon.mx-1(
          v-if='item.fileId && !item.error'
          color='success'
          small
        ) mdi-check-circle
        creative-name-edit(:creative='item' @creativeNameUpdated='creativeNameUpdated')

      div(v-if='item.id && !fullTable && isParentViewAuctionPackage') {{ item.id }}
      .d-flex.justify-start.creative-infos-summary(v-if='!fullTable')
        v-chip.mr-2.creative-language(small v-if='!item.fileId')
          | {{ item.language }}

        v-edit-dialog(
          v-if='item.fileId'
          ref='editLanguageDialog'
          large
          :return-value.sync='item.language'
          @save='updateLang(item)'
        )
          v-chip.mr-2.creative-language(small)
            | {{ item.language }}
          .mt-3.text-h6(slot='input' style='width:200px;') Update Language
          v-select(slot='input' :items='langOptions' v-model='item.language' label='Edit Language' single-line autofocus)

        v-chip.mr-2.creative-format(small v-if='item.creativeFormat')
          | {{ item.creativeFormat.minWidth }} x {{ item.creativeFormat.minHeight }}

        v-chip.mr-2.creative-duration(small v-if='item.type === "Video"')
          v-icon(small) mdi-clock-outline
          .text-caption {{ durationDisplay(item) }}

      v-progress-linear.loadprogress.my-1(
        v-if='item.progress && !item.error'
        :value='item.progress'
        :indeterminate='isProgressIndeterminate(item.progress)'
        height='8'
        color='primary'
        :query='true'
      )

      .error-upload.d-flex.mt-2(v-if='item.error')
        span.text-caption.error--text
          | {{ item.error }}.
          | Won't be submitted for approval

    template(v-slot:item.language='{ item }')
      span.creative-language(v-if='!item.fileId') {{ item.language }}

      v-edit-dialog(
        v-if='item.fileId && !isParentViewAuctionPackage'
        ref='editLanguageDialog'
        large
        :return-value.sync='item.language'
        @save='updateLang(item)'
      )
        .d-flex
          span.creative-language {{ item.language }}
          v-icon.mx-1(color='grey' small) mdi-pencil
        .mt-3.text-h6(slot='input' style='width:200px;') Update Language
        v-select(slot='input' :items='langOptions' v-model='item.language' label='Edit Language' single-line autofocus)

    template(v-slot:item.type='{ item }')
      | {{ item.type | capitalize }}

    template(v-slot:item.creativeFormat.key='{ item }')
      span.creative-format(v-if='item.creativeFormat') {{ item.creativeFormat.minWidth }} x {{ item.creativeFormat.minHeight }}

    template(v-slot:item.duration='{ item }')
      .creative-duration
        span(v-if='item.type === "Video"') {{ durationDisplay(item) }}
        span(v-else) N/A

    template.text-right(v-slot:item.action='{ item }' v-if='!isParentViewAuctionPackage')
      v-tooltip(top dark color='secondary' v-if='item.id')
        template(v-slot:activator='{on}')
          v-btn.mr-0.delete-btn(icon text color='error' v-on='on' @click='removeCreative(item)')
            v-icon(color='grey') mdi-delete
        span Remove this creative
      v-tooltip(top dark color='secondary' v-if='!item.id')
        template(v-slot:activator='{on}')
          v-btn.mr-0.stop-btn(icon text color='error' v-on='on' @click='stopUpload(item)')
            v-icon(color='grey') mdi-close-circle
        span Stop Upload
</template>

<style scoped lang="stylus">
  .tablecreatives td
    height: unset
  .loadprogress
    position: relative
    width: 100%
    height: 8px
  .tablecreatives .v-small-dialog a
    display: block
    width: 100%
  .tablecreatives .v-small-dialog a > *
    width: unset
</style>

<script>
import campsiteConfig from '@/config/campsite.config'
import creativeImage from '@/components/creativeImage.vue'
import creativeNameEdit from '@/components/creativeNameEdit.vue'
import auctionPackageApi from '@/services/auctionPackage.api'

export default {
  components: {
    creativeImage,
    creativeNameEdit
  },
  props: ['parent', 'fullTable'],
  created: function () {
    if (this.parent !== 'viewAuctionPackage') {
      this.loadCreatives()
    }
  },
  data () {
    return {
      rowsPerPageItems: {
        itemsPerPageOptions: [10, 25, 50]
      },
      pagination: {
        rowPerPage: 10,
        page: 1,
        sortBy: ['creativeid'],
        sortDesc: [true]
      },
      langOptions: ['EN', 'FR'],
      durationOptions: campsiteConfig.creatives.durationOptions,
      rules: {
        required: value => !!value || 'Required.'
      },
      saving: false,
      uploadedCreatives: []
    }
  },
  watch: {
    pagination: function (newVal, oldVal) {
      if (this.isParentViewAuctionPackage) {
        this.loadCreativesFromPagination()
      }
    },
    isCreativesLoading: function (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.loadCreativesFromPagination()
      }
    }
  },
  computed: {
    uploadingCreatives () {
      return this.$store.getters['creatives/getUploadingCreatives']
    },
    totalItems () {
      return this.isParentViewAuctionPackage
        ? this.$store.getters['auctionPackage/getCreativeCount'] : this.assignedCreatives.length
    },
    assignedCreatives () {
      return [...this.uploadingCreatives, ...this.uploadedCreatives]
    },
    isParentAddCreative () {
      return this.parent === 'addCreatives'
    },
    isParentCreativesPanel () {
      return this.parent === 'panelCreatives'
    },
    isParentViewAuctionPackage () {
      return this.parent === 'viewAuctionPackage'
    },
    isParentCreativesHover () {
      return this.parent === 'reportCreativesHover'
    },
    editNameFormValidated () {
      return this.$refs.editNameForm.validate()
    },
    showTitle () {
      return this.isParentCreativesPanel && this.assignedCreatives.length
    },
    showTable () {
      return this.isParentCreativesPanel
        ? this.assignedCreatives.length
        : true
    },
    headers () {
      const headers = [
        { text: 'Preview', align: 'left', value: 'preview', width: '130px', sortable: false },
        { text: this.fullTable ? 'Filename' : 'Information', align: 'left', value: 'name' },
        { text: 'Action', align: 'right', value: 'action', width: '30px', sortable: false }
      ]

      var spliceAt = 2
      if (this.isParentViewAuctionPackage) {
        headers.unshift({ text: 'Organization', align: 'left', value: 'organization', sortable: false })
        headers.pop()
        spliceAt = 3
      }

      if (this.fullTable) {
        headers.splice(spliceAt, 0,
          { text: 'Language', align: 'left', value: 'language', width: '130px' },
          { text: 'Type', align: 'left', value: 'type', width: '85px' },
          { text: 'Size', align: 'left', value: 'creativeFormat.key', width: '130px', sortable: false },
          { text: 'Duration', align: 'left', value: 'duration', sortable: false }
        )
      }

      return headers
    },
    isCreativesLoading () {
      return this.$store.getters['campaignReport/getLineCreativesLoading']
    }
  },
  methods: {
    loadCreativesFromPagination () {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination

      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'

      this.loadCreatives(skip, take, sortBy[0], sort)
    },
    loadCreatives (skip = 0, take = 10, sortBy = 'creativeid', sort = 'desc') {
      if (this.isParentViewAuctionPackage) {
        auctionPackageApi.getAssignments(skip, take, sortBy === 'name' ? 'creativeid' : sortBy, sort, this.$route.params.itemId)
          .then(assignments => {
            this.uploadedCreatives = assignments.map(a => ({ ...a.creative, id: a.creativeId }))
          })
      } else {
        this.uploadedCreatives = this.isParentCreativesHover
          ? this.$store.getters['campaignReport/getAssignedCreatives']
          : this.$store.getters['createCampaign/getCreatives']
      }
    },
    stopUpload (creative) {
      this.$store.commit('creatives/removeUploadingCreative', creative)
    },
    removeCreative (creative) {
      this.$emit('removeCreative', creative)
    },
    creativeNameUpdated (creativeNamePatch) {
      const mutation = this.isParentCreativesPanel
        ? 'createCampaign/setCreative'
        : 'creatives/setCreative'

      this.$store.commit(mutation, creativeNamePatch)
    },
    updateLang (creative) {
      const creativePatch = {
        id: creative.id,
        language: creative.language
      }

      const mutation = this.isParentCreativesPanel
        ? 'createCampaign/setCreative'
        : 'creatives/setCreative'

      this.$store.commit(mutation, creativePatch)
    },
    durationDisplay (creative) {
      return Math.round(creative.duration || 0)
    },
    isProgressIndeterminate (progress) {
      return progress === 100
    }
  }
}
</script>
